/**
 * ************************************
 *
 * @module  ActivityService.js
 * @author  Matt P
 * @date    02/01/2021
 * @description File to prepare Activity form
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import {
  chooseItemizedDescription,
  getProcessedNeighborhoods,
  isValidURL,
  mapDetailedCategories,
} from 'utils/utils';

// ----------------------------------------------------------------------------|
//                             Activity Service
// ----------------------------------------------------------------------------|
const ActivityService = {
  /**
   * @description processes the vertical specific data that is incoming from
   * the backend to populate an edit form.
   *
   * @param {Object} dataObj - of date data
   * @param {Object} additionalOptions - any additional data that may be
   * required to process incoming data
   *
   * @returns {Object}
   */
  processVerticalSpecificData(dataObj = {}, additionalOptions = {}) {
    const {
      description,
      itemized_description,
      links_to_activity,
      price_tier,
      phrase,
      main_categories,
      name,
      neighborhoods,
    } = dataObj;

    return {
      name: name || '',
      price_tier:
        price_tier === undefined || price_tier === null ? '' : price_tier,
      phrase: phrase || '',
      links_to_activity: links_to_activity || [], // website link
      itemized_description: chooseItemizedDescription(
        itemized_description,
        description,
        ActivityService.defaultDescriptions
      ),
      main_categories: main_categories
        ? mapDetailedCategories(main_categories, additionalOptions.categoryMap)
        : [],
      neighborhoods: neighborhoods
        ? getProcessedNeighborhoods(neighborhoods)
        : [],
    };
  },

  /**
   * @description preps the vertical specific form data to be passed in the
   * request body for a form update
   *
   * @param {Object} formData - formData
   * @param {Object} additionalOptions - any additional data that may be
   * required to process outgoing data
   *
   * @returns {Object} for requestBody
   */
  prepareVerticalSpecificFormData(formData = {}, additionalOptions = {}) {
    const { price_tier, phrase, links_to_activity } = formData;

    return {
      price_tier:
        price_tier === '' || price_tier === undefined ? undefined : price_tier,
      phrase: phrase ? phrase.trim() : '',
      links_to_activity:
        typeof links_to_activity === 'string'
          ? [links_to_activity]
          : links_to_activity,
    };
  },

  /**
   * @description error checking for 'publish' form submission
   *
   * @param {Object} data - formData
   *
   * @returns {Object} of errors
   */
  validate(data) {
    const { links_to_activity, price_tier } = data;

    const errorsObj = {};

    if (
      (typeof links_to_activity === 'string' &&
        !isValidURL(links_to_activity)) ||
      (Array.isArray(links_to_activity) &&
        links_to_activity.length &&
        !isValidURL(links_to_activity[0]))
    ) {
      errorsObj.links_to_activity = 'Please enter a valid URL';
    }

    if (price_tier === undefined || price_tier === '') {
      errorsObj.price_tier = 'Price range is required';
    }

    return errorsObj;
  },

  /**
   * @description default descriptions for the itemized_descriptions
   * form field
   */
  defaultDescriptions: [
    {
      header: 'What it is',
      body: '',
    },
    {
      header: 'Why it\'s unique',
      body: '',
    },
  ],
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default ActivityService;
