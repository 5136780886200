/**
 * ************************************
 *
 * @module  DropdownPanel.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Dropdown for Date Range picker leveraging React Hooks
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DateRangePicker from 'react-daterange-picker';

import moment from 'moment';
import cx from 'classnames';

import { Button } from 'components';

import { DATE_SELECTOR_POPUP } from 'constants.js';

import './DropdownPanel.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
/**
 * @description This is a functional component for the dropdown date range
 * component
 *
 * @param {Object} props
 *
 * @argument {Object} selectedDateRange: Object for start and end dates
 * @argument {Function} onSelect: function to fire on date selection
 *
 * @returns {JSX} HTML components to be rendered to the DOM
 */
const DropdownPanel = ({ selectedDateRange, onSelect }) => {
  /**
   * @description - component state
   */
  const [startDateSelector, setStartDateSelector] = useState(true);
  const [dates, setDates] = useState(selectedDateRange);

  const { DATE_FORMAT: format } = DATE_SELECTOR_POPUP;

  /**
   * @description - dynamic classNames
   */
  const fromDateCss = cx(
    'datepicker--from-date',
    dates.start ? 'show' : 'hide'
  );
  const positiveButtonCss = cx('btn', { next: startDateSelector });
  const buttonContainerCss = cx('datepicker--button-panel', {
    next: startDateSelector,
  });

  /**
   * @description Updates state (via useState hook) with selected date
   *
   * @param {String} date: Selected Date
   */
  const onDateSelected = (date) => {
    if (startDateSelector) {
      setDates({ start: date, end: dates.end });
    } else {
      setDates({ start: dates.start, end: date });
    }
  };

  /**
   * @description Updates state (via useState hook) with boolean
   */
  const toggleStartOrEndDateSelector = () =>
    setStartDateSelector(!startDateSelector);

  /**
   * @description Passes date and invokes onSelect func
   */
  const onDateRangeSelected = () => onSelect(dates);

  /**
   * @description toggles toggleStartOrEndDateSelector() or passes date
   * and invokes onSelect func
   */
  const positiveButtonAction = () => {
    if (startDateSelector) {
      toggleStartOrEndDateSelector();
    } else {
      onDateRangeSelected();
    }
  };

  /**
   * @description Checks if button should be disabled or not
   *
   * @returns {Boolean}
   */
  const isPositiveButtonEnabled = () =>
    (startDateSelector && !!dates.start) ||
    (startDateSelector === false &&
      !!dates.start &&
      !!dates.end &&
      moment(dates.start).isBefore(moment(dates.end)));

  return (
    <div className="dropdown-panel">
      <h2 className="datepicker-panel--header">
        {/* eslint-disable-next-line quotes */}
        {startDateSelector ? "Select 'From' Date" : "Select 'To' Date"}
      </h2>
      <div className="datepicker--calendar">
        <DateRangePicker
          singleDateRange
          selectionType="single"
          value={startDateSelector ? dates.start : dates.end}
          onSelect={onDateSelected}
        />
      </div>
      <label className={fromDateCss}>
        From: {dates.start && <span>{dates.start.format(format)}</span>}
      </label>

      <div className={buttonContainerCss}>
        {!startDateSelector && (
          <Button
            className="btn btn-inverse back"
            onClick={toggleStartOrEndDateSelector}
          >
            BACK
          </Button>
        )}
        <Button
          className={positiveButtonCss}
          onClick={positiveButtonAction}
          disabled={!isPositiveButtonEnabled()}
        >
          {startDateSelector ? 'NEXT' : 'DONE'}
        </Button>
      </div>
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
DropdownPanel.propTypes = {
  selectedDateRange: PropTypes.shape({
    start: PropTypes.object,
    end: PropTypes.object,
  }),
  onSelect: PropTypes.func.isRequired,
};

DropdownPanel.defaultProps = {
  selectedDateRange: {
    start: moment(),
    end: moment(),
  },
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default DropdownPanel;
