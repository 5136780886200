/**
 * ************************************
 *
 * @module  CardFooter.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Renders the CardFooter functional component,
 * located under the CardHeader comp.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { GENERIC } from 'constants.js';

import deleteIcon from 'assets/images/icn_delete.png';
import deleteActiveIcon from 'assets/images/icn_delete_hover.png';

import 'components/CardFooter/CardFooter.scss';

// ----------------------------------------------------------------------------|
//                    React Function Component - CardFooter
// ----------------------------------------------------------------------------|
const CardFooter = ({ label, date, lastReviewDate, onMenuClick, hideMenu }) => {
  const [isDeleteActive, setIsDeleteActive] = useState(false);

  /**
   * @description Renders delete icon for CardFooter
   *
   * @returns {JSX} HTML components to be rendered to the DOM
   */
  const renderDeleteIcon = () => {
    const {
      SUB_MENU: { DELETE },
    } = GENERIC;

    const imageSource = isDeleteActive ? deleteActiveIcon : deleteIcon;

    return (
      <img
        src={imageSource}
        alt="delete icon"
        onClick={() => onMenuClick(DELETE)}
        data-name="delete"
        onMouseOver={() => setIsDeleteActive(true)}
        onMouseOut={() => setIsDeleteActive(false)}
        onFocus={() => setIsDeleteActive(true)}
        onBlur={() => setIsDeleteActive(false)}
      />
    );
  };

  return (
    <div className="card-footer">
      <div className="card-footer-content">
        <ul>
          <li>
            <span className="card-footer-label">{label}</span>
            <span className="card-footer-date">{date}</span>
          </li>
          <li>
            <span className="card-footer-label">Last Reviewed: </span>
            <span className="card-footer-date">{lastReviewDate || ''}</span>
          </li>
        </ul>
      </div>
      {!hideMenu && renderDeleteIcon()}
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
CardFooter.propTypes = {
  label: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  hideMenu: PropTypes.bool,
  lastReviewDate: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
CardFooter.defaultProps = {
  hideMenu: false,
  lastReviewDate: null,
};

// ----------------------------------------------------------------------------|
//                                 Export
// ----------------------------------------------------------------------------|
export default CardFooter;
