/**
 * ************************************
 *
 * @module  WarningModal.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description modal that warns of a severe action
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Backdrop, Button } from 'components';

import '../DeleteModal/DeleteModal.scss';
import './WarningModal.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const DeleteModal = ({ onSubmit, onCancel, title, message }) => (
  <Fragment>
    <Backdrop onClick={() => null} />
    <div className="warning-modal delete-modal">
      <h1 className="modal-header">{title}</h1>
      <p className="modal-content">{message}</p>
      <div className="btn-container">
        <Button className="btn-inverse" onClick={onCancel}>
          CANCEL
        </Button>
        <Button onClick={onSubmit}>OK</Button>
      </div>
    </div>
  </Fragment>
);

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
DeleteModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
DeleteModal.defaultProps = {
  title: 'Warning',
  message: 'Warning',
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default DeleteModal;
