/**
 * ************************************
 *
 * @module  Activity.reducer.js
 * @author  Matt P
 * @date    01/19/2021
 * @description redux reducer file for Activity component
 *
 * ************************************
 */

// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                               Initial State
// ----------------------------------------------------------------------------|
const initialState = {
  // Contains images that are to be auto-filled into the image upload component
  autofilledImages: [],
  images: [],
  formData: {},
  // Contains list of fileIds for whom upload failed
  failedUploads: [],
  isDropZonePreviewRequired: false,
  isBtnDisabled: false,
};

// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|
// identifier can be the source url of the image in case of auto-filled images
// For images that were not auto-filled, it is the file id
// If an image's source url or its ID matches the identifier param,
// delete it from the array
const getFilteredImages = (identifier, images = []) =>
  images.filter(
    (image) => image.url !== identifier && image.fileId !== identifier
  );

// ----------------------------------------------------------------------------|
//                            Activity Reducer
// ----------------------------------------------------------------------------|
const Activity = (state = initialState, action = null) => {
  const { type, data } = action;

  switch (type) {
    case ACTIONS.ACTIVITY.FETCH_ACTIVITY_SUCCESS: {
      return {
        ...state,
        fetchedActivityData: data,
      };
    }

    case ACTIONS.ACTIVITY.POPULATE_IMAGES: {
      return {
        ...state,
        autofilledImages: data || [],
      };
    }

    case ACTIONS.ACTIVITY.SAVE_DATA_TO_REDUX: {
      return {
        ...state,
        formData: { ...state.formData, ...data },
      };
    }

    // Clear any errors for image being uploaded
    case ACTIONS.ACTIVITY.UPLOAD_IMAGE: {
      return {
        ...state,
        isDropZonePreviewRequired: true,
        failedUploads: state.failedUploads.filter(
          (fileId) => fileId !== data.fileId
        ),
      };
    }

    case ACTIONS.ACTIVITY.UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        autofilledImages: [
          ...state.autofilledImages,
          { ...data, fileId: data.fileId, photo_metadata: data.photo_metadata },
        ],
        isDropZonePreviewRequired: false,
      };
    }

    case ACTIONS.ACTIVITY.UPLOAD_IMAGE_FAIL: {
      return { ...state, failedUploads: [...state.failedUploads, data.fileId] };
    }

    case ACTIONS.ACTIVITY.DELETE_IMAGE: {
      return {
        ...state,
        autofilledImages: getFilteredImages(data, state.autofilledImages),
      };
    }

    case ACTIONS.ACTIVITY.CLEAR_IMAGES: {
      return { ...state, images: [] };
    }

    case ACTIONS.ACTIVITY.RE_ORDER_IMAGES: {
      return { ...state, autofilledImages: data };
    }

    case ACTIONS.ACTIVITY.BUTTON_DISBALING: {
      return {
        ...state,
        isBtnDisabled: data.isBtnDisabled,
      };
    }

    default: {
      return state;
    }
  }
};

// ----------------------------------------------------------------------------|
//                          Export - Activity Reducer
// ----------------------------------------------------------------------------|
export default Activity;
