/**
 * ************************************
 *
 * @module  Cities.saga.js
 * @author  Matt P
 * @date    04/25/2022
 * @description Cities saga file
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import { call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import API from 'API';

import ACTIONS from 'store/actions/actionTypes';

import { CITIES } from 'constants.js';

import { reportAPIError } from 'utils/ErrorHandlingUtils';

// ----------------------------------------------------------------------------|
//                            Sagas - Cities
// ----------------------------------------------------------------------------|
/**
 * @description gets Cities list http request
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* getCitiesList() {
  const { getData } = window.axios;

  try {
    const URL = API.cities;
    const response = yield call(getData, URL);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.CITIES.GET_LIST_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: ACTIONS.CITIES.GET_LIST_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.CITIES.GET_LIST_FAIL });
  }
}

/**
 * @description creates city http request
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* createCity(action) {
  const { putData } = window.axios;
  const { data, parentCityId } = action;

  const { CREATE_CITY_SUCCESS, CREATE_CITY_FAILURE } = CITIES.TOAST;

  try {
    const URL = API.cities;
    const response = yield call(putData, URL, data);
    const responseData = response.data;

    if (response.status === 200) {
      yield put({
        type: ACTIONS.CITIES.CREATE_CITY_SUCCESS,
        responseData,
        parentCityId,
      });

      toast.success(CREATE_CITY_SUCCESS);
    } else {
      reportAPIError('Filter Create - HTTP', response.data);

      yield put({ type: ACTIONS.CITIES.AJAX_FAIL });

      toast.error(responseData.message || CREATE_CITY_FAILURE);
    }
  } catch (error) {
    reportAPIError('Overall Cities Create Catch', error);

    yield put({ type: ACTIONS.CITIES.AJAX_FAIL });

    toast.error(CREATE_CITY_FAILURE);
  }
}

/**
 * @description edits city http request
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* editCity(action) {
  const { patchData } = window.axios;
  const { name, status, id } = action.data;
  const { parentCityId } = action;

  const { EDIT_CITY_SUCCESS, EDIT_CITY_FAILURE } = CITIES.TOAST;

  // backend can't accept all fields for this request
  // wash it
  const washedPutData = {
    name,
    status,
  };

  try {
    const URL = `${API.cities}/${id}`;
    const response = yield call(patchData, URL, washedPutData);
    const responseData = response.data;

    if (response.status === 200) {
      yield put({
        type: ACTIONS.CITIES.EDIT_CITY_SUCCESS,
        data: responseData,
        parentCityId,
      });

      toast.success(EDIT_CITY_SUCCESS);
    } else {
      reportAPIError('City Edit - HTTP', response.data);

      yield put({ type: ACTIONS.CITIES.AJAX_FAIL });

      toast.error(EDIT_CITY_FAILURE);
    }
  } catch (error) {
    reportAPIError('Overall Cities Edit Catch', error);

    yield put({ type: ACTIONS.CITIES.AJAX_FAIL });

    toast.error(EDIT_CITY_FAILURE);
  }
}

/**
 * @description delete city http request
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* deleteCity(action) {
  const { deleteData } = window.axios;
  const { DELETE_SUCCESS_MESSAGE, DELETE_FAILURE_MESSAGE } = CITIES.TOAST;
  const { parentCityId, data } = action;
  const { city, apiData } = data;
  const { id } = apiData;

  try {
    const URL = `${API.cities}/${id}`;
    const response = yield call(deleteData, URL);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.CITIES.DELETE_CITY_SUCCESS,
        data: city,
        parentCityId,
      });

      toast.success(DELETE_SUCCESS_MESSAGE);
    } else {
      reportAPIError('Filter Delete - HTTP', response.data);

      yield put({ type: ACTIONS.CITIES.AJAX_FAIL });

      toast.error(DELETE_FAILURE_MESSAGE);
    }
  } catch (error) {
    reportAPIError('Overall Cities Delete Catch', error);

    yield put({ type: ACTIONS.CITIES.AJAX_FAIL });

    toast.error(DELETE_FAILURE_MESSAGE);
  }
}

/**
 * @description toggles enable/disable city http request
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* toggleEnable(action) {
  const { patchData } = window.axios;
  const {
    ENABLE_SUCCESS_MESSAGE,
    ENABLE_FAILURE_MESSAGE,
    DISABLE_SUCCESS_MESSAGE,
    DISABLE_FAILURE_MESSAGE,
  } = CITIES.TOAST;
  const { parentCityId, data } = action;
  const { name, status, id } = data;

  // backend can't accept all fields for this request
  // wash it
  const washedPutData = {
    status,
    name,
  };

  try {
    const URL = `${API.cities}/${id}`;
    const response = yield call(patchData, URL, washedPutData);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.CITIES.TOGGLE_ENABLE_SUCCESS,
        data: response.data,
        parentCityId,
      });

      toast.success(
        status === CITIES.AVAILABLE
          ? ENABLE_SUCCESS_MESSAGE
          : DISABLE_SUCCESS_MESSAGE
      );
    } else {
      yield put({ type: ACTIONS.CITIES.AJAX_FAIL });

      toast.error(
        status === CITIES.AVAILABLE
          ? ENABLE_FAILURE_MESSAGE
          : DISABLE_FAILURE_MESSAGE
      );
    }
  } catch (error) {
    yield put({ type: ACTIONS.CITIES.AJAX_FAIL });

    toast.error(
      status === CITIES.AVAILABLE
        ? ENABLE_FAILURE_MESSAGE
        : DISABLE_FAILURE_MESSAGE
    );
  }
}

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export { getCitiesList, createCity, editCity, deleteCity, toggleEnable };
