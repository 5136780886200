/**
 * ************************************
 *
 * @module  Label.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description label for the card component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { capitalize } from 'utils/utils';

import './Label.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const Label = ({ className, type, count, ...rest }) => {
  const classNames = cx([
    'label',
    `${type}`,
    `${className.split(' ').join(',')}`,
  ]);

  let cardLabel;

  switch (type) {
    case 'draft': {
      cardLabel = 'drafts';
      break;
    }
    case 'flagged': {
      cardLabel = `${type} (${count || ''})`;
      break;
    }
    default: {
      cardLabel = type;
    }
  }

  return (
    <div className={classNames} aria-label={cardLabel} {...rest}>
      {cardLabel && capitalize(cardLabel)}
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
Label.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  count: PropTypes.number,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
Label.defaultProps = {
  className: '',
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default Label;
