/**
 * ************************************
 *
 * @module  CuratedCardList.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description CuratedCardList component file which
 * displays curated card cards
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Card, InfiniteScroller } from 'components';

import { getImageURL, getDateBasedOnStatus } from 'utils/utils';

import { GENERIC } from 'constants.js';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const CuratedCardList = (props) => {
  const {
    data,
    hasMore,
    getMoreData,
    onMenuClicked,
    onCheckBoxSelected,
    className,
    isChecked,
    shouldDisplayImageInspector,
  } = props;

  const {
    SUB_MENU: { EDIT },
  } = GENERIC;

  const containerClass = cx(['curated-card-list', `${className}`]);

  /**
   * @description generates card component
   *
   * @param {Object[]} data - Array of place objects
   *
   * @returns {JSX[]} - with Card components
   */
  const generateData = () => {
    let res = null;
    if (Array.isArray(data)) {
      res = data.map((card) => {
        const {
          id,
          modified: modifiedDate,
          published_date: publishedDate,
          num_units: locationCount,
          status,
          images,
          name,
          phrase,
          is_expired: isExpired,
        } = card;
        const imageURL = getImageURL(images);
        return (
          <Card
            key={id}
            id={id}
            className="card"
            onMenuClick={(value) => onMenuClicked(value, card)}
            onClick={() => onMenuClicked(EDIT, card)}
            onCheck={onCheckBoxSelected}
            isChecked={isChecked}
            date={getDateBasedOnStatus(status, modifiedDate, publishedDate)}
            status={status}
            imageUrl={imageURL}
            images={images}
            title={name}
            locationCount={locationCount}
            phrase={phrase}
            isExpired={isExpired}
            shouldDisplayImageInspector={shouldDisplayImageInspector}
          />
        );
      });
    }
    return <div className={`card-container ${shouldDisplayImageInspector ? "image-inspector-container" : ""}`}>{res}</div>;
  };

  const content = generateData();

  return (
    <div className={containerClass}>
      <InfiniteScroller hasMore={hasMore} loadMore={getMoreData}>
        {content}
      </InfiniteScroller>
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                       PropTypes Check - PlacesList
// ----------------------------------------------------------------------------|
CuratedCardList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasMore: PropTypes.bool.isRequired,
  getMoreData: PropTypes.func.isRequired,
  onMenuClicked: PropTypes.func.isRequired,
  onCheckBoxSelected: PropTypes.func.isRequired,
  className: PropTypes.string,
  isChecked: PropTypes.bool,
};

// ----------------------------------------------------------------------------|
//                          Default Props - PlacesList
// ----------------------------------------------------------------------------|
CuratedCardList.defaultProps = {
  className: '',
  isChecked: null,
};

// ----------------------------------------------------------------------------|
//                              PlacesList Export
// ----------------------------------------------------------------------------|
export default CuratedCardList;
