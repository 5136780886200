/**
 * ************************************
 *
 * @module  Recipe.js
 * @author  Matt P
 * @date    02/02/2021
 * @description Container for editing a 'Recipe.' Currently renders within
 * the CardViewModal is shown renders when a card is clicked in the
 * RecipesList component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { deleteRecipeAction } from 'store/actions/~depreciated/Recipes.action';
import {
  clearImagesAction,
  deleteImageAction,
  populateAutofillImagesAction,
  saveDataToRedux as saveDataToReduxAction,
  saveData,
  uploadImageAction,
  reOrderImagesAction,
  fetchRecipeAction,
} from 'store/actions/~depreciated/Recipe.action';

import { toast } from 'react-toastify';

import RecipeService from 'containers/~depreciated/Recipe/RecipeService';
import RecipeData from 'containers/~depreciated/Recipe/RecipeData.constants';

import {
  Form,
  FileUploader,
  WarningModal,
  Loader,
  FormActions,
  Overlay,
} from 'components';

import { RECIPE } from 'constants.js';

import {
  applyDrag,
  chooseItemizedDescription,
  getDurationTiers,
  deepCopy,
  hasUniqueSlug,
  mapDetailedCategories,
} from 'utils/utils';
import { extractCategoryTypes } from 'utils/CategoryUtils';

import CommonService from 'services/CommonService';

import './Recipe.scss';

// ----------------------------------------------------------------------------|
//                        Redux - Property Mapping
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  // Fetch Data Actions
  fetchRecipe: (data) => dispatch(fetchRecipeAction(data)),
  uploadImage: (data) => dispatch(uploadImageAction(data)),
  deleteImage: (data) => dispatch(deleteImageAction(data)),
  populateAutofillImages: (data) =>
    dispatch(populateAutofillImagesAction(data)),
  clearImages: () => dispatch(clearImagesAction()),
  reOrderImages: (data) => dispatch(reOrderImagesAction(data)),
  // Data Save Actions
  saveData: (data, id, successCB) => dispatch(saveData(data, id, successCB)),
  saveDataToRedux: (data) => dispatch(saveDataToReduxAction(data)),
  deleteRecipe: (data) => dispatch(deleteRecipeAction(data)),
});

const mapStateToProps = (state) => ({
  recipe: state.recipe,
  categoryMap: state.places.categoryMap,
  recipeCategories: state.places.recipeCategories,
  adminCategories: state.places.adminCategories,
  // neighborhoods: state.places.neighborhoodLocations,
});

// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|
const commonService = CommonService();

const { WARNING_ON_DRAFT } = RECIPE;

// ----------------------------------------------------------------------------|
//                    React Class PureComponent - Recipe
// ----------------------------------------------------------------------------|
class Recipe extends PureComponent {
  // --------------------------------------------------------------------------|
  //                            PropTypes Check
  // --------------------------------------------------------------------------|
  static propTypes = {
    recipe: PropTypes.shape({
      autofilledImages: PropTypes.arrayOf(PropTypes.object),
      failedUploads: PropTypes.arrayOf(PropTypes.object),
      formData: PropTypes.shape({
        cta_title_long: PropTypes.string,
        cta_title_short: PropTypes.string,
        description: PropTypes.string,
        name: PropTypes.string,
        images: PropTypes.arrayOf(PropTypes.object),
        main_categories: PropTypes.arrayOf(PropTypes.object),
        nested_categories: PropTypes.arrayOf(PropTypes.object),
        categories: PropTypes.arrayOf(PropTypes.object),
        neighborhoods: PropTypes.arrayOf(PropTypes.object),
      }),
      images: PropTypes.arrayOf(PropTypes.object),
      isBtnDisabled: PropTypes.bool.isRequired,
      isDropZonePreviewRequired: PropTypes.bool.isRequired,
      fetchedRecipeData: PropTypes.shape({
        id: PropTypes.string.isRequired,
        cta_title_long: PropTypes.string,
        cta_title_short: PropTypes.string,
        nested_categories: PropTypes.arrayOf(PropTypes.object),
        main_categories: PropTypes.arrayOf(PropTypes.object),
        title: PropTypes.string,
        description: PropTypes.string,
        images: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      cta_title_long: PropTypes.string,
      cta_title_short: PropTypes.string,
      nested_categories: PropTypes.arrayOf(PropTypes.object),
      main_categories: PropTypes.arrayOf(PropTypes.object),
      title: PropTypes.string,
      description: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.object),
      shouldFetchActivity: PropTypes.bool,
      shouldDisplayLoader: PropTypes.bool,
    }).isRequired,
    currentTab: PropTypes.string.isRequired,
    adminCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchRecipe: PropTypes.func.isRequired,
    saveData: PropTypes.func.isRequired,
    saveDataToRedux: PropTypes.func.isRequired,
    clearImages: PropTypes.func.isRequired,
    deleteImage: PropTypes.func.isRequired,
    populateAutofillImages: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    uploadImage: PropTypes.func.isRequired,
    reOrderImages: PropTypes.func.isRequired,
  };

  // --------------------------------------------------------------------------|
  //                            Default Props
  // --------------------------------------------------------------------------|
  static defaultProps = {};

  // --------------------------------------------------------------------------|
  //                                State
  // --------------------------------------------------------------------------|
  constructor(props) {
    super(props);

    const { data, saveDataToRedux } = this.props;
    const initialRecipeState = this.handleRecipeData(data);

    this.state = {
      recipeId: data.id,
      errors: {},
      imageErrors: '',
      showEditHours: false,
      shouldFetchRecipe: data.shouldFetchRecipe,
      shouldDisplayLoader: false,
      ...initialRecipeState,
    };
    // initially keep the data in the redux store
    saveDataToRedux(this.getToBeProcessedData(data));
  }

  // --------------------------------------------------------------------------|
  //                          Lifecycle Methods
  // --------------------------------------------------------------------------|
  componentDidMount() {
    const { populateAutofillImages, data, adminCategories } = this.props;
    const { shouldFetchRecipe } = this.state;

    populateAutofillImages(data.images);

    if (shouldFetchRecipe) {
      this.fetchRecipeFromServer();
      this.setState({ shouldFetchRecipe: false, shouldDisplayLoader: true });
    }

    if (adminCategories.length === 0) {
      this.setState({ shouldDisplayLoader: true });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      adminCategories,
      data,
      recipe,
      saveDataToRedux,
      populateAutofillImages,
    } = this.props;

    const { recipeId, shouldDisplayLoader } = this.state;

    // used for updating and rendering a fetched place
    // also checks if the async calls have come back and will flip
    // the loaderDisplay flag when loaded.
    if (data.shouldFetchRecipe) {
      if (
        adminCategories.length !== 0 &&
        recipe.fetchedRecipeData &&
        recipeId === recipe.fetchedRecipeData.id &&
        shouldDisplayLoader
      ) {
        this.setState({
          shouldDisplayLoader: false,
          ...this.handleRecipeData(recipe.fetchedRecipeData),
        });

        saveDataToRedux(this.getToBeProcessedData(recipe.fetchedRecipeData));
        populateAutofillImages(recipe.fetchedRecipeData.images);
      }
    } else if (adminCategories.length !== 0 && shouldDisplayLoader) {
      this.setState({
        shouldDisplayLoader: false,
        ...this.handleRecipeData(data),
      });

      saveDataToRedux(this.getToBeProcessedData(data));
    }
  }

  componentWillUnmount() {
    const { clearImages, recipe } = this.props;

    clearImages(recipe);
  }

  // --------------------------------------------------------------------------|
  //                      Component Methods - Recipe
  // --------------------------------------------------------------------------|
  /**
   * @description Fetch Recipe object from server using ID
   */
  fetchRecipeFromServer = () => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.fetchRecipe({ recipeId: this.state.recipeId });
  };

  /**
   * @description Handle recipe object data: prepare form, display, etc..
   *
   * @param {Object} recipeDataToProcess - Object of recipe data
   */
  handleRecipeData = (recipeDataToProcess) => {
    const { adminCategories } = this.props;

    const toBeProcessedObj = this.getToBeProcessedData(recipeDataToProcess);
    const processedData = this.getProcessedData(
      toBeProcessedObj,
      adminCategories
    );

    return { data: processedData };
  };

  /**
   * @description fires to reorders images
   *
   * @param {Object} dropData
   */
  onReOrderImages = (dropData) => {
    const { recipe, reOrderImages } = this.props;
    const { autofilledImages, images } = recipe;
    const reOrderedImages = applyDrag(
      [...autofilledImages, ...images],
      dropData
    );

    reOrderImages(reOrderedImages);
  };

  /**
   * @description fires to saveData. Runs several
   * validation checks found in RecipeService file
   *
   * @param {Object} e - Event Object.
   */
  saveData = (e) => {
    const { recipe, data, closeModal, adminCategories, saveData } = this.props;

    const { autofilledImages } = recipe;

    const formattedImages = recipe.autofilledImages.map((image) => {
      const imageTemp = { ...image };

      // // fixed error where is photo_metadata || photo_credit === null
      // // will wash to empty string for DB PUT/PATCH
      if (imageTemp.photo_credits === null) imageTemp.photo_credits = '';
      if (imageTemp.photo_metadata === null) imageTemp.photo_metadata = '';

      if (image.source === 'cobble') {
        delete imageTemp.fileId;

        return imageTemp;
      }

      return imageTemp;
    });

    const formData = RecipeService.getPreparedFormData(
      recipe.formData,
      [...formattedImages],
      e.target.name
    );

    formData.categories = formData.categories.map((cat) => cat.unique_slug);

    formData.main_categories = recipe.formData.main_categories.map(
      (cat) => cat.unique_slug
    );

    /** Perform validation only on published */
    if (e.target && e.target.name === 'published') {
      const errors = RecipeService.validate(recipe.formData);

      // errors = commonService.validateLinks(recipe.formData, errors);

      const imageErrors = commonService.validateImages(
        recipe.images,
        autofilledImages
      )
        ? null
        : RECIPE.IMAGE_VALIDATION_MESSAGE;

      if (Object.keys(errors).length === 0 && imageErrors === null) {
        // dispatch to save
        saveData(formData, data.id, closeModal);
      } else {
        toast.error('There are some errors in the form');
      }

      this.setState({
        errors,
        imageErrors,
      });
    } else if (e.target && e.target.name === 'draft') {
      const errors = {
        ...RecipeService.validateRecipeTitle(recipe.formData.title),
      };

      if (
        !commonService.validatePrimaryCategories(
          recipe.formData.main_categories,
          recipe.formData.categories
        )
      ) {
        errors.categories =
          'Primary categories must be included in the selected categories';
      }

      const imageErrors = '';

      if (Object.keys(errors).length === 0) {
        // dispatch to save
        saveData(formData, data.id, closeModal);
      } else {
        toast.error('There are some errors in the form');
      }

      this.setState({ errors, imageErrors });
    }
  };

  /**
   * @description returns an object with the correct
   * key/value pairs for editing
   *
   * @param {Object} obj
   */
  getToBeProcessedData = (obj) => {
    const { categoryMap } = this.props;

    return {
      source_name: obj.source_name || '',
      phrase: obj.phrase || '',
      categories: obj.categories || [],
      duration: obj.duration || -1,
      prep_time: obj.prep_time || -1,
      images: obj.images || [],
      main_categories: obj.main_categories
        ? mapDetailedCategories(obj.main_categories, categoryMap)
        : [],
      cta_title_long: obj.cta_title_long || '',
      description: obj.description || '',
      links_to_recipe: obj.links_to_recipe || [],
      title: obj.title || '',
      cta_title_short: obj.cta_title_short || '',
      last_admin_review: obj.last_admin_review,
      unit_history: obj.unit_history,
      // special case where we want to put the detailed description from
      // old card details into the itemized one
      itemized_description: chooseItemizedDescription(
        obj.itemized_description,
        obj.description,
        RecipeService.defaultDescriptions
      ),
      // converts Ingredients from array of obj to what the form expects
      // array of strings only, targeting the name propery on the object
      ingredients:
        obj.ingredients && Array.isArray(obj.ingredients)
          ? obj.ingredients.map((ing) => ing.name)
          : [],
      meal_type: obj.meals || [],
      diet: obj.diets || [],
    };
  };

  /**
   * @description processes data of objects into an array of objects
   * for our form component to iterate and render
   *
   * @param {Array} data - data being passed || element name
   * @param {Array} categories - name of that input
   *
   * @returns {Array}
   */
  getProcessedData = (data = [], categories = []) => {
    const {
      recipes: recipeCategories,
      mealTypes,
      diet,
    } = extractCategoryTypes(categories);

    return RecipeData.map((inputObj) => {
      let input = {};

      switch (inputObj.name) {
        case 'categories':
          input = {
            ...inputObj,
            main_categories: data.main_categories,
            tags: data.categories,
            suggestions: recipeCategories || [],
          };
          break;
        case 'checkbox_row':
          input = {
            ...inputObj,
            onChange: this.handleMiscOptionToggle,
            options: diet.map((cat) => ({
              displayText: cat.name,
              uniqueSlug: cat.unique_slug,
              id: cat.id,
              type: 'category',
            })),
            selectedOptions: data.diet,
          };
          break;
        case 'last_admin_review':
          input = {
            ...inputObj,
            value: data.last_admin_review
              ? data.last_admin_review.review_status
              : null,
            reviewDate: data.last_admin_review
              ? data.last_admin_review.review_datetime
              : null,
            history: data.unit_history || [],
          };
          break;
        case 'recipe_prep_&_duration':
          input = {
            ...inputObj,
            leftValue:
              data.prep_time !== undefined && data.prep_time !== null
                ? getDurationTiers(data.prep_time).pop()
                : getDurationTiers().pop(),
            rightValue:
              data.duration !== undefined && data.duration !== null
                ? getDurationTiers(data.duration).pop()
                : getDurationTiers().pop(),
          };
          break;
        case 'meal_type':
          input = {
            ...inputObj,
            tags: data.meal_type,
            suggestions: mealTypes || [],
          };
          break;

        default:
          input = {
            ...inputObj,
            value: data[inputObj.name],
          };
          break;
      }
      return input;
    });
  };

  /**
   * @description toggles preview modal
   *
   * @param {Boolean} isShow
   */
  onShowPreviewModal = (isShow) => {
    this.setState({ showPreviewModal: isShow });
  };

  /**
   * @description fires on an 'event' which is any input or
   * change to the form. Updates redux
   *
   * @param {Any} event - data being passed || element name
   * @param {String} elemName - name of that input
   */
  eventHandler = (event, elemName) => {
    const { saveDataToRedux } = this.props;
    const data = RecipeService.getDataToBeSaved(event, elemName);

    saveDataToRedux(data);
  };

  /**
   * @description fires when any of the form action buttons (bottom of form)
   * are clicked - ie: "MOVE TO DRAFTS" / "PUBLISHED" / "PREVIEW" and passes
   * data to be validated for a DB PUT/PATCH
   *
   * @argument {Object} event - Event Object.
   */
  onFormAction = (event) => {
    const { currentTab, data } = this.props;
    const { name } = event.target;

    if (name === 'preview') {
      this.onShowPreviewModal(true);
    } else if (
      name === 'draft' &&
      currentTab === 'published' &&
      data &&
      data.id
    ) {
      event.persist();

      this.setState({
        showWarningModal: true,
        onWarningClose: () => {
          this.saveData(event);
          this.setState({ showWarningModal: false, onWarningClose: () => {} });
        },
      });
    } else if (name === 'published' || name === 'draft') {
      this.saveData(event);
    } else if (name === 'delete') {
      event.persist();

      this.setState({
        showWarningModal: true,
        onWarningClose: () => {
          const { deleteRecipe, closeModal, data } = this.props;

          // we can't call closeModal until the delete is resolved,
          // as closeModal resets the list state to [] which will not
          // allow us to filter the deleted card. So we close it after the
          // delete async function is complete
          const promiseMe = new Promise((resolve, reject) => {
            resolve(deleteRecipe(data.id));
          });

          promiseMe
            .then(() => {
              closeModal();
            })
            .then(() => {
              this.setState({
                showWarningModal: false,
                onWarningClose: () => {},
              });
            });
        },
      });
    }
  };

  handleMiscOptionToggle = (checked, uniqueSlug, type) => {
    if (type === 'category') {
      const { recipe } = this.props;
      const { formData } = recipe;
      const { diet } = formData;

      const optionsCopy = deepCopy(diet);

      const hasSlug = hasUniqueSlug(diet, uniqueSlug);

      if (checked && !hasSlug) {
        const { categoryMap } = this.props;
        const category = categoryMap[uniqueSlug];

        // prevents undefined from being pushed to the array
        if (category) optionsCopy.push(category);
      }

      if (!checked && hasSlug) {
        for (let i = 0; i < optionsCopy.length; i += 1) {
          if (optionsCopy[i].unique_slug === uniqueSlug) {
            optionsCopy.splice(i, 1);
          }
        }
      }

      this.eventHandler(optionsCopy, 'diet');
    }
  };

  // --------------------------------------------------------------------------|
  //                             Recipe - Render
  // --------------------------------------------------------------------------|
  render() {
    const {
      uploadImage,
      deleteImage,
      recipe,
      populateAutofillImages,
      currentTab,
    } = this.props;
    const {
      data,
      errors,
      showWarningModal,
      onWarningClose,
      shouldDisplayLoader,
      imageErrors,
      showPreviewModal,
    } = this.state;

    const { formData } = recipe;
    const updatedData = commonService.getUpdatedData(formData, [...data]);

    updatedData.event_venue_name = data.event_venue_name;

    return (
      <div className="recipe">
        {shouldDisplayLoader === true ? (
          <div className="overlay-wrapper">
            <Overlay show>
              <Loader />
            </Overlay>
          </div>
        ) : (
          <Fragment>
            <div className="form-content">
              {showWarningModal && (
                <WarningModal
                  message={WARNING_ON_DRAFT}
                  onSubmit={onWarningClose}
                  onCancel={() => {
                    this.setState({ showWarningModal: false });
                  }}
                />
              )}
              <FileUploader
                isDropZonePreviewRequired={recipe.isDropZonePreviewRequired}
                data={recipe.autofilledImages}
                onEvent={populateAutofillImages}
                failedUploads={recipe.failedUploads}
                onAddImage={uploadImage}
                onDeleteImage={deleteImage}
                error={imageErrors}
                onDrop={this.onReOrderImages}
              />
              <div className="form">
                <Form
                  data={updatedData}
                  onEvent={this.eventHandler}
                  errors={errors}
                />
              </div>
            </div>
            <FormActions
              verticalType="Recipe"
              onAction={this.onFormAction}
              currentTab={currentTab}
              cardId={this.props.data.id}
              // eslint-disable-next-line react/destructuring-assignment
              isNewCard={Object.keys(this.props.data).length > 0}
              isBtnDisabled={recipe.isBtnDisabled}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

// ----------------------------------------------------------------------------|
//                     Recipe Export with Redux Connect
// ----------------------------------------------------------------------------|
export default connect(mapStateToProps, mapDispatchToProps)(Recipe);
