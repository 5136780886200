/**
 * ************************************
 *
 * @module  CardHeader.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description CardHeader component which includes card image, checkbox, label
 * (published/expired/etc..) and location count
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
// React imports
import React from 'react';
import PropTypes from 'prop-types';

// App imports
import { Checkbox, Label, LocationCount } from 'components';
import { sanitizeUrl } from 'utils/utils';
import { GENERIC } from 'constants.js';
import CommonService from 'services/CommonService';
import { getEndpointTypeByUnitType } from 'containers/VerticalDisplay/VerticalForm/VerticalFormUtils';
import removeIcon from 'assets/images/remove.svg';
import cameraIcon from 'assets/images/camera.svg';
import contentIcon from 'assets/images/content.svg';
import dataImage from 'assets/images/link-black.svg';
import openIcon from 'assets/images/open-icon.svg';
import pinnedIcon from 'assets/images/pinned.svg';
import notPinnedIcon from 'assets/images/pinned-empty.svg';
import 'components/CardHeader/CardHeader.scss';

// ----------------------------------------------------------------------------|
//                              Utilities
// ----------------------------------------------------------------------------|
const commonService = CommonService();

// ----------------------------------------------------------------------------|
//                  React Function Component - CardHeader
// ----------------------------------------------------------------------------|
const CardHeader = ({ verticalType, cardType, imageUrl, onCheck, status, locationCount, id, isChecked, showDeleteButton, onDelete, hideCheckBox, reviewStatus, shouldDisplayNewTabOpenCTA, isPinned, onMenuClick, count }) => {
    /**
     * @description - Conditional render for the card review icons based on
     * card review status and vertical type
     *
     * @param {String} currStatus
     *
     * @returns {JSX}
     */
    const chooseHeaderIcons = (currStatus) => {
        const { SUB_MENU: { PIN } } = GENERIC;
        const nullOrPending = (revStatus) => {
            return revStatus === null || revStatus === 'pending_review';
        }
        const imagesIconJSX = (
            <div className="card-header-icons" key={`img-review-icon-${id}`}>
                <img className="card-review-icons__camera" src={cameraIcon} alt="camera-review-pending" />
            </div>
        );
        const contentIconJSX = (
            <div className="card-header-icons" key={`content-review-icon-${id}`}>
                <img className="card-review-icons__content" src={contentIcon} alt="content-review-pending" />
            </div>
        );
        const dataIconJSX = (
            <div className="card-header-icons" key={`data-review-icon-${id}`}>
                <img className="card-review-icons__data" src={dataImage} alt="data-review-pending" />
            </div>
        );
        const pinIconJSX = (
            <div className="card-header-icons" key={`collection-pin-icon-${id}`}>
                <img className="card-pin-icon__content" src={isPinned ? pinnedIcon : notPinnedIcon} alt="collection-pinned" onClick={() => onMenuClick(PIN)} data-name="pin" />
            </div>
        );
        const openIconJSX = (
            <div className="card-header-icons open" key={`open-icon-${id}`}>
                <img className="card-review-icons__open" src={openIcon} alt="open" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    var urlToRedirect = null;
                    if (verticalType === 'universalSearch' && status) {
                        const formattedCardStatus = status === 'draft' ? 'drafts' : status;
                        const formattedCardType = getEndpointTypeByUnitType(cardType);
                        urlToRedirect = `/${formattedCardType}/${formattedCardStatus}/${id}`;
                    } else {
                        const { tab } = commonService.pathnameHelper(window.location.pathname);
                        urlToRedirect = `${tab}/${id}`;
                    }
                    if (urlToRedirect == null) { return; }
                    window.open(urlToRedirect, '_blank');
                }} />
            </div>
        );
        const iconArray = [];
        // collections only
        if (verticalType === 'collection') iconArray.push(pinIconJSX);
        // review status icons
        if (nullOrPending(currStatus) || currStatus.includes('images')) {
            iconArray.push(imagesIconJSX);
        }
        if (nullOrPending(currStatus) || currStatus.includes('data')) {
            iconArray.push(dataIconJSX);
        }
        if (nullOrPending(currStatus) || currStatus.includes('content')) {
            iconArray.push(contentIconJSX);
        }
        if (shouldDisplayNewTabOpenCTA) {
            iconArray.push(openIconJSX);
        }
        return <div className="card-header-icons-container">{iconArray}</div>;
    };

    /**
     * @description - Conditional render for either checkbox or delete button
     */
    const showCheckboxOrButton = showDeleteButton ? (
        <img className="delete-btn" id={id} src={removeIcon} alt="delete" data-name="delete" onClick={onDelete} />
    ) : (
        <Checkbox className="card-checkbox" label={id} onClick={onCheck} toBeChecked={isChecked} />
    );

    return (
        <div className="card-header">
            <img className="card-image" src={sanitizeUrl(imageUrl)} alt="card" />
            {chooseHeaderIcons(reviewStatus)}
            {!hideCheckBox && showCheckboxOrButton}
            <Label className="card-label" type={status} count={count} />
            {verticalType === 'universalSearch' && cardType && (
                <Label className="card-type-label" type={cardType} />
            )}
            {Boolean(locationCount) && <LocationCount count={locationCount} />}
        </div>
    );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
CardHeader.propTypes = {
    verticalType: PropTypes.string.isRequired,
    cardType: PropTypes.string,
    imageUrl: PropTypes.string.isRequired,
    id: PropTypes.string,
    status: PropTypes.string.isRequired,
    onCheck: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    locationCount: PropTypes.number,
    isChecked: PropTypes.bool,
    showDeleteButton: PropTypes.bool,
    hideCheckBox: PropTypes.bool,
    reviewStatus: PropTypes.string,
    isPinned: PropTypes.bool,
    onMenuClick: PropTypes.func,
    shouldDisplayNewTabOpenCTA: PropTypes.bool,
    count: PropTypes.number,
};

CardHeader.defaultProps = {
    locationCount: 0,
    isChecked: null,
    showDeleteButton: false,
    hideCheckBox: false,
    onDelete: () => { },
    reviewStatus: null,
    shouldDisplayNewTabOpenCTA: true,
};

// ----------------------------------------------------------------------------|
//                              CardHeader Export
// ----------------------------------------------------------------------------|
export default CardHeader;
