/**
 * ************************************
 *
 * @module  Card.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Card functional component.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
// React imports
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// App imports
import { DATE_FORMATS, VERTICAL_TYPE } from 'constants.js';
import { qualifyArrayRendering, formatISODate, sanitizeUrl } from 'utils/utils';
import { CardFooter, CardHeader, CardInfo } from 'components';
import 'components/Card/Card.scss';

// ----------------------------------------------------------------------------|
//                    Component Specific Helper Functions
// ----------------------------------------------------------------------------|
/**
 * @description Label generating switch case for a cards status
 *
 * @param {String} status: Shortened string for card status
 *
 * @returns {String} Descriptive string for card status
 */
const getLabelFromStatus = (status) => {
    switch (status) {
        case 'published':
            return 'Published on';
        case 'draft':
            return 'Drafted on';
        case 'expired':
            return 'Expired on';
        case 'archived':
            return 'Archived on';
        case 'inactive':
            return 'Deactivated on';
        case 'flagged':
            return 'Published on';
        default:
            return '';
    }
};

// ----------------------------------------------------------------------------|
//                      React Function Component - Card
// ----------------------------------------------------------------------------|
const Card = ({ verticalType, currentTab, cardType, className, id, imageUrl, images, title, phrase, date, status, onCheck, locationCount, onMenuClick, isChecked, hideMenu, showDeleteButton, onDelete, isExpired, onClick, hideCheckBox, adminReview, isPinned, shouldDisplayImageInspector, shouldDisplayNewTabOpenCTA, reports }) => {
    
    const containerClass = cx(['card-content', `${className}`]);
    const stringifiedDate = formatISODate(date, DATE_FORMATS.stringWithYear);

    /**
     * @description Card click event handler which forwards to Card Edit Modal
     * as long as the event targetDataName variable is not 'delete' or 'checkbox'
     *
     * @param {Object} $event: "event object" for div element card click
     */
    const cardClickHandler = ($event) => {
        const targetDataName = $event.target.getAttribute('data-name');
        if (targetDataName !== 'delete' && targetDataName !== 'checkbox' && targetDataName !== 'pin') {
            onClick(id, $event, shouldDisplayImageInspector);
        }
    };

    /**
     * @description helps choose status text
     *
     * @param {String} cardStatus
     *
     * @returns {String}
     */
    const chooseStatusText = (cardStatus) => {
        // no way to tell if a card is archived from what's returned from
        // backend, so if we are on the archived tab, populate text based on that
        if (currentTab === 'archived') return 'archived';
        if (verticalType === VERTICAL_TYPE.FLAGGED) return 'flagged';
        if (verticalType === VERTICAL_TYPE.PLACE && isExpired) return 'inactive';
        if (verticalType === VERTICAL_TYPE.EVENT && isExpired) return 'expired';
        return cardStatus;
    };

    const cardStatus = chooseStatusText(status);

    return (
        <div className={containerClass} role="button" onClick={($event) => cardClickHandler($event)} tabIndex={0}>
            {shouldDisplayImageInspector ? (
                <div className="card-inner-wrapper">
                    {Array.isArray(images)
                        ? images.map((image) => {
                            if (image.url != null) {
                                return (
                                    <div className="card-header">
                                        {!image.url.startsWith('https://cobble') && (
                                            <div className="external-label">EXTERNAL IMAGE</div>
                                        )}
                                        <img className="card-image card-image-inspector" src={sanitizeUrl(image.url)} />
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })
                        : null
                    }
                    <div className="card-body">
                        <CardInfo title={title} phrase={phrase} />
                    </div>
                </div>
            ) : (
                <div>
                    <CardHeader
                        verticalType={verticalType}
                        cardType={cardType}
                        imageUrl={imageUrl}
                        status={cardStatus}
                        locationCount={locationCount}
                        onCheck={onCheck}
                        isChecked={isChecked}
                        showDeleteButton={showDeleteButton}
                        onDelete={onDelete}
                        id={id}
                        hideCheckBox={hideCheckBox}
                        reviewStatus={adminReview ? adminReview.review_status : null}
                        // Collections Specific
                        isPinned={isPinned}
                        onMenuClick={onMenuClick}
                        shouldDisplayNewTabOpenCTA={shouldDisplayNewTabOpenCTA}
                        // Flagged content specific
                        count={qualifyArrayRendering(reports) && reports.length}
                    />
                    <div className="card-body">
                        <CardInfo title={title} phrase={phrase} />
                    </div>
                    <CardFooter
                        label={`${getLabelFromStatus(cardStatus)}: `}
                        date={stringifiedDate}
                        onMenuClick={onMenuClick}
                        hideMenu={hideMenu}
                        labelClass={status}
                        lastReviewDate={adminReview ? formatISODate(adminReview.review_datetime, DATE_FORMATS.stringWithYear) : null}
                    />
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------|
//                             PropTypes Check
// ----------------------------------------------------------------------------|
Card.propTypes = {
    verticalType: PropTypes.string.isRequired,
    cardType: PropTypes.string.isRequired,
    onMenuClick: PropTypes.func,
    onCheck: PropTypes.func,
    onDelete: PropTypes.func,
    // id: PropTypes.string.isRequired,
    id: PropTypes.string,
    className: PropTypes.string,
    date: PropTypes.string,
    status: PropTypes.string,
    imageUrl: PropTypes.string,
    images: PropTypes.array,
    isChecked: PropTypes.any,
    title: PropTypes.string,
    phrase: PropTypes.string,
    locationCount: PropTypes.number,
    hideMenu: PropTypes.bool,
    showDeleteButton: PropTypes.bool,
    isExpired: PropTypes.bool,
    onClick: PropTypes.func,
    hideCheckBox: PropTypes.bool,
    shouldDisplayNewTabOpenCTA: PropTypes.bool,
    adminReview: PropTypes.shape({
        review_datetime: PropTypes.string,
        reviewer: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
        }),
        review_status: PropTypes.string,
        comments: PropTypes.string,
    }),
    isPinned: PropTypes.bool,
    reports: PropTypes.arrayOf(PropTypes.shape({})),
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|

Card.defaultProps = {
    className: '',
    title: '',
    date: '',
    phrase: '',
    status: '',
    imageUrl: '',
    images: [],
    locationCount: 0,
    isChecked: null,
    hideMenu: false,
    showDeleteButton: false,
    isExpired: false,
    onDelete: () => { },
    onMenuClick: () => { },
    onCheck: () => { },
    onClick: () => { },
    hideCheckBox: false,
    adminReview: null,
    isPinned: null,
    shouldDisplayNewTabOpenCTA: true,
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default Card;
