/**
 * ************************************
 *
 * @module  PreviewCard.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description part of the PreviewCardModal which is used to display a preview
 * of what a card would look like in the apps
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import PlaceholderImage from 'assets/images/placeholder.png';

import { sanitizeUrl } from 'utils/utils';

import './PreviewCard.scss';

const PreviewCard = ({ data }) => {
  const {
    previewImage,
    name,
    category,
    neighborhood,
    price,
    date,
    startTime,
    endTime,
  } = data;

  const categoryList = () => {
    if (Array.isArray(category)) {
      return category.map((item) => item.name).join(', ');
    }
    return '';
  };

  const neighborhoodList = () => {
    if (Array.isArray(neighborhood)) {
      return neighborhood.map((item) => item.name).join(', ');
    }
    return '';
  };

  return (
    <div className="preview-card">
      <div className="image-section">
        <img src={sanitizeUrl(previewImage)} alt="places" />
      </div>
      <div className="content">
        <h1 className="name">{name}</h1>
        <p className="category">{categoryList()}</p>
        <div className="place-details">
          <p className="neighborhood">{neighborhoodList()}</p>
          <span className="dot">.</span>
          <p className="price">{price}</p>
        </div>
        <div className="timings">
          {date !== '' && (
            <Fragment>
              <span className="date">{date}</span>
              <span className="dot">.</span>
            </Fragment>
          )}
          <span className="time">
            {startTime} {endTime !== '' && `-${endTime}`}
          </span>
        </div>
      </div>
    </div>
  );
};

PreviewCard.propTypes = {
  data: PropTypes.shape({
    previewImage: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.array,
    neighborhood: PropTypes.array,
    price: PropTypes.string,
    date: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
};

PreviewCard.defaultProps = {
  data: {
    previewImage: `${PlaceholderImage}`,
    name: '',
    category: [],
    neighborhood: [],
    price: '',
    date: '',
    startTime: '',
    endTime: '',
  },
};

export default PreviewCard;
