/**
 * ************************************
 *
 * @module  Button.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description InfiniteScroller component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';

// ----------------------------------------------------------------------------|
//                           React Function Component
// ----------------------------------------------------------------------------|
const InfiniteScroller = ({
  children,
  loadMore,
  hasMore,
  useWindow,
  ...rest
}) => (
  <InfiniteScroll
    loadMore={loadMore}
    hasMore={hasMore}
    useWindow={useWindow}
    {...rest}
  >
    {children}
  </InfiniteScroll>
);

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
InfiniteScroller.propTypes = {
  children: PropTypes.any.isRequired,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  useWindow: PropTypes.bool,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
InfiniteScroller.defaultProps = {
  useWindow: true,
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default InfiniteScroller;
