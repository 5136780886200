/**
 * ************************************
 *
 * @module  Activity.action.js
 * @author  Matt P
 * @date    01/19/2021
 * @description redux action for Activity component
 *
 * ************************************
 */

// ----------------------------------------------------------------------------|
//                            Import ActionTypes
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|

// ------------------------- Fetch Data Actions -------------------------------|
export const fetchActivityAction = (data) => ({
  type: ACTIONS.ACTIVITY.FETCH_ACTIVITY,
  data,
});

// -------------------------- Data Save Actions -------------------------------|
export const saveData = (data, id, successCB) => ({
  type: ACTIONS.ACTIVITY.SAVE_DATA,
  data,
  id,
  successCB,
});

export const saveDataToRedux = (data) => ({
  type: ACTIONS.ACTIVITY.SAVE_DATA_TO_REDUX,
  data,
});

// ---------------------------- Image Actions ---------------------------------|
export const clearImagesAction = (data) => ({
  type: ACTIONS.ACTIVITY.CLEAR_IMAGES,
  data,
});

export const deleteImageAction = (data) => ({
  type: ACTIONS.ACTIVITY.DELETE_IMAGE,
  data,
});

export const populateAutofillImagesAction = (data) => ({
  type: ACTIONS.ACTIVITY.POPULATE_IMAGES,
  data,
});

export const reOrderImagesAction = (data) => ({
  type: ACTIONS.ACTIVITY.RE_ORDER_IMAGES,
  data,
});

export const uploadImageAction = (data) => ({
  type: ACTIONS.ACTIVITY.UPLOAD_IMAGE,
  data,
});
