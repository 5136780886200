/**
 * ************************************
 *
 * @module  HoursRange.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description leverages two HourPicker/rc-time-picker to create an hours range
 * dropdown selector
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { HourPicker } from 'components';

import './HoursRange.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const HoursRange = ({ from, to, errors, onChange, label, placeholder }) => (
  <div className="hours-range">
    <label className="hours-range-label">{label}</label>
    <div className="timer">
      <HourPicker
        defaultValue={from}
        name="from_time"
        onChange={onChange}
        placeholder={placeholder}
      />
      <span className="timer-separator">To</span>
      <HourPicker
        defaultValue={to}
        name="to_time"
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
    <div>
      <Link>Edit hours</Link>
    </div>
    <div>
      <Link>Edit hours</Link>
    </div>
    <div className="error">{errors}</div>
  </div>
);

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
HoursRange.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
HoursRange.defaultProps = {
  errors: '',
  from: '',
  to: '',
  label: 'Hours',
  placeholder: '',
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default HoursRange;
