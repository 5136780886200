/**
 * ************************************
 *
 * @module  VerticalForm.action.js
 * @author  Matt P
 * @date   07/02/2021
 * @description redux action file for the VerticalForm container.
 * Controls editing of the form fields
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                               Actions
// ----------------------------------------------------------------------------|
const clearImagesAction = (data) => ({
  type: ACTIONS.VERTICAL_FORM.CLEAR_IMAGES,
  data,
});

const clearFormStateAction = () => ({
  type: ACTIONS.VERTICAL_FORM.CLEAR_FORM_STATE,
});

const deleteImageAction = (data) => ({
  type: ACTIONS.VERTICAL_FORM.DELETE_IMAGE,
  data,
});

const fetchVerticalFormDetailAction = (data, verticalType) => ({
  type: ACTIONS.VERTICAL_FORM.FETCH_VERTICAL_FORM_DETAIL,
  data,
  verticalType,
});

const populateAutofillImagesAction = (data) => ({
  type: ACTIONS.VERTICAL_FORM.POPULATE_IMAGES,
  data,
});

const reinstateUnitAction = (data) => ({
  type: ACTIONS.VERTICAL_FORM.REINSTATE_UNIT,
  data,
});

const reOrderImagesAction = (data) => ({
  type: ACTIONS.VERTICAL_FORM.RE_ORDER_IMAGES,
  data,
});

const saveDataAction = (data, id, successCB, verticalType) => ({
  type: ACTIONS.VERTICAL_FORM.SAVE_DATA,
  data,
  id,
  successCB,
  verticalType,
});

const saveDataToReduxAction = (data) => ({
  type: ACTIONS.VERTICAL_FORM.SAVE_DATA_TO_REDUX,
  data,
});

const uploadImageAction = (data, verticalType) => ({
  type: ACTIONS.VERTICAL_FORM.UPLOAD_IMAGE,
  data,
  verticalType,
});

// ------------------------ Collections Specific ---------------------------- //

const clearCardSelectionVerticalListAction = (subType) => ({
  type: ACTIONS.VERTICAL_FORM.CLEAR_CARD_SELECTION_SEARCH_LIST,
  subType,
});

const deselectCardListAction = (data) => ({
  type: ACTIONS.VERTICAL_FORM.DESELECT_CARD_LIST,
  data,
});

const fetchCardSelectionVerticalListAction = (data, verticalType, subType) => ({
  // use the VerticalDisplay action then use a flag to update the
  // modal searched cards
  type: ACTIONS.VERTICAL_FORM.GET_VERTICAL_CARD_SELECTION_CARDS,
  data, // data will have sub_type flags from CardList comp
  verticalType, // collections
  subType,
});

const populateCollectionCardsAction = (data) => ({
  type: ACTIONS.VERTICAL_FORM.POPULATE_COLLECTION_CARD_LIST,
  data,
});

const updateCollectionCardsAction = (data) => ({
  type: ACTIONS.VERTICAL_FORM.UPDATE_COLLECTION_CARD_LIST,
  data,
});

const resetCardSelectionStateAction = () => ({
  type: ACTIONS.VERTICAL_FORM.RESET_CARD_SELECTION_STATE,
});

const selectCardListAction = (data) => ({
  type: ACTIONS.VERTICAL_FORM.SELECT_CARD_LIST,
  data,
});

// ----------------------------------------------------------------------------|
//                               Exports
// ----------------------------------------------------------------------------|
export {
  clearImagesAction,
  clearFormStateAction,
  deleteImageAction,
  fetchVerticalFormDetailAction,
  populateAutofillImagesAction,
  reinstateUnitAction,
  reOrderImagesAction,
  saveDataAction,
  saveDataToReduxAction,
  uploadImageAction,
  clearCardSelectionVerticalListAction,
  deselectCardListAction,
  fetchCardSelectionVerticalListAction,
  populateCollectionCardsAction,
  updateCollectionCardsAction,
  resetCardSelectionStateAction,
  selectCardListAction,
};
