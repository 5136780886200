/**
 * ************************************
 *
 * @module  DeleteModal.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description warns user to confirm delete
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Backdrop, Button } from 'components';
import { GENERIC } from 'constants.js';

import './DeleteModal.scss';

const { DELETE_WARNING_MESSAGE } = GENERIC;

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|

const DeleteModal = ({
  cancelClick,
  deleteClick,
  additionalMessage,
  showHeader,
}) => (
  <Fragment>
    <Backdrop onClick={() => null} />
    <div className="delete-modal">
      {showHeader ? <h1 className="modal-header">Delete</h1> : ''}
      <p className="modal-content">
        {additionalMessage}
        <span>{DELETE_WARNING_MESSAGE}</span>
      </p>
      <div className="btn-container">
        <Button onClick={cancelClick} className="btn-inverse">
          CANCEL
        </Button>
        <Button onClick={deleteClick}>DELETE</Button>
      </div>
    </div>
  </Fragment>
);

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
DeleteModal.propTypes = {
  cancelClick: PropTypes.func.isRequired,
  deleteClick: PropTypes.func.isRequired,
  additionalMessage: PropTypes.string,
  showHeader: PropTypes.bool,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
DeleteModal.defaultProps = {
  additionalMessage: '',
  showHeader: true,
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default DeleteModal;
