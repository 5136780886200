/**
 * ************************************
 *
 * @module  CuratedCard.action.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description CuratedCard redux actions
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import ACTIONS from './actionTypes';

// ----------------------------------------------------------------------------|
//                                Exports
// ----------------------------------------------------------------------------|
export const populateAutofillImagesAction = (data) => ({
  type: ACTIONS.CURATED_CARD.POPULATE_IMAGES,
  data,
});

export const uploadImageAction = (data) => ({
  type: ACTIONS.CURATED_CARD.UPLOAD_IMAGE,
  data,
});

export const deleteImageAction = (data) => ({
  type: ACTIONS.CURATED_CARD.DELETE_IMAGE,
  data,
});

export const saveDataToReduxAction = (data) => ({
  type: ACTIONS.CURATED_CARD.SAVE_DATA_TO_REDUX,
  data,
});

export const saveDataAction = (data, id, successCB) => ({
  type: ACTIONS.CURATED_CARD.SAVE_DATA,
  data,
  id,
  successCB,
});

export const clearData = () => ({
  type: ACTIONS.CURATED_CARD.CLEAR_DATA,
});

export const fetchCuratedCardAction = (id) => ({
  type: ACTIONS.CURATED_CARD.FETCH_CURATED_CARD,
  data: id,
});

export const fetchCardListAction = (data) => ({
  type: ACTIONS.CURATED_CARD.GET_CARD_LIST,
  data: { ...data, status: 'published' },
});

export const clearCardListAction = () => ({
  type: ACTIONS.CURATED_CARD.CLEAR_CARD_LIST,
});

export const addSelectedCardAction = (data) => ({
  type: ACTIONS.CURATED_CARD.SELECT_CARD_LIST,
  data,
});

export const deSelectedCardAction = (data) => ({
  type: ACTIONS.CURATED_CARD.DESELECT_CARD_LIST,
  data,
});

export const reOrderImagesAction = (data) => ({
  type: ACTIONS.CURATED_CARD.RE_ORDER_IMAGES,
  data,
});
