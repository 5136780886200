/**
 * ************************************
 *
 * @module  CobInteractiveTable.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description DEPRECIATED - Interactive table for the Categories Tab
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import CobTableRow from './CobTableRow/CobTableRow';

// import 'react-toggle-switch/dist/css/switch.min.css';
import './CobInteractiveTable.scss';

const CobInteractiveTable = ({
  rows,
  cols,
  className,
  toggleFeatured,
  toggleEnable,
  deleteCategory,
  showEditModal,
  expandRow,
}) => {
  /**
   * @description - loop over to extract parent key names
   */
  const parentRowsKeys = Object.keys(rows);

  /**
   * @description - dynamic classNames
   */
  const classNames = cx('cob-interactive-table', className);

  /**
   * @description - Builds the table section headers.
   *
   * @param {Object} col - column object
   * @param {Number} index
   *
   * @returns {JSX}
   */
  const buildTableHead = (col, index) => (
    <th key={index}>{col.label.toUpperCase()}</th>
  );

  /**
   * @description - handled the toggle comp selection
   *
   * @param {Object} row - of data
   * @param {String} column - column name
   *
   * @returns {void}
   */
  const onToggleSwitch = (row, column) => {
    if (column === 'is_featured') {
      toggleFeatured(row);
    } else if (column === 'is_enabled') {
      toggleEnable(row);
    }
  };

  /**
   * @description - builds a table row
   *
   * @param {Object} row - of data
   * @param {Object[]} columns - array of column data
   * @param {Boolean} isParent
   * @param {Boolean} hasChildren
   *
   * @returns {JSX}
   */
  const injectTableRow = (
    row,
    columns,
    isParent,
    hasChildren,
    parentRow = {}
  ) => {
    const classes = isParent ? 'parentrow-container' : 'childrow-container';
    const siblingsCount = (parentRow.children || []).length;

    return (
      <CobTableRow
        rowClasses={classes}
        key={row.unique_slug}
        row={row}
        cols={columns}
        hasChildren={hasChildren}
        onToggleSwitch={onToggleSwitch}
        showEditModal={showEditModal}
        onDeleteCategory={deleteCategory}
        onExpandRow={isParent ? expandRow : () => {}}
        isDeleteAllowed={isParent || siblingsCount > 1}
        deleteDisabledTooltip="At least one child category is mandatory!"
      />
    );
  };

  /**
   * @description - Makes columns based on level of the rows
   *
   * @param {Number} level
   *
   * @returns {JSX}
   */
  const makeColumns = (level) => {
    const resultColumns = [];
    cols.forEach((col) => {
      const newCol = { ...col };
      if (
        newCol.type === 'Options' &&
        !['all', level].includes(newCol.visibility)
      ) {
        newCol.value = [];
      }
      resultColumns.push(newCol);
    });
    return resultColumns;
  };

  /**
   * @description - builds children rows
   *
   * @param {Number} row
   *
   * @returns {JSX}
   */
  const buildChildRows = (row) => {
    const childColumns = makeColumns('child');
    return (
      <React.Fragment>
        {row.children.map((child) =>
          injectTableRow(child, childColumns, false, false, row)
        )}
      </React.Fragment>
    );
  };

  const buildRows = () => {
    const parentColumns = makeColumns('parent');
    return parentRowsKeys.map((key) => {
      const row = rows[key];
      return (
        <React.Fragment key={row.unique_slug}>
          {injectTableRow(row, parentColumns, true, row.children.length > 0)}
          {!row.hidden && row.children.length > 0 ? buildChildRows(row) : null}
        </React.Fragment>
      );
    });
  };

  return (
    <div className={classNames}>
      {parentRowsKeys && (
        <table className="cob-table">
          <thead>
            <tr>{cols.map((col, index) => buildTableHead(col, index))}</tr>
          </thead>
          <tbody>{buildRows()}</tbody>
        </table>
      )}
    </div>
  );
};

CobInteractiveTable.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.object.isRequired,
  cols: PropTypes.array.isRequired,
  toggleFeatured: PropTypes.func.isRequired,
  toggleEnable: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  showEditModal: PropTypes.func,
  expandRow: PropTypes.func,
};

CobInteractiveTable.defaultProps = {
  className: null,
  showEditModal: () => {},
  expandRow: () => {},
};

export default CobInteractiveTable;
