/**
 * ************************************
 *
 * @module  Movies.reducer.js
 * @author  Matt P
 * @date    12/23/2020
 * @description redux reducer file for Movies component.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                    Imports
// ----------------------------------------------------------------------------|

import { toast } from 'react-toastify';

import { GENERIC } from 'constants.js';
import { splitArray } from 'utils/utils';

import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                                  Initial State
// ----------------------------------------------------------------------------|
const initialState = {
  movies: [],
  selectedMovies: [],
  cardCategories: [],
  adminCategories: [],
  totalCount: 0,
  bulkActionSuccess: false,
  isInitialDataLoaded: false,
  pageNum: 1,
  tmdbPage: -1,
  fetchMore: true,
};

// ----------------------------------------------------------------------------|
//                                 Movies Reducer
// ----------------------------------------------------------------------------|
const Movies = (state = initialState, action = null) => {
  const {
    DELETE_SUCCESS_MESSAGE,
    BULK_ACTION_SUCCESS_MESSAGE,
    DELETE_FAILURE_MESSAGE,
    BULK_ACTION_FAILURE_MESSAGE,
  } = GENERIC;

  switch (action.type) {
    case ACTIONS.MOVIES.GET_MOVIES_SUCCESS: {
      // response from POST MovieList query
      const { movies, count, queried_tmdb_page } = action.data;

      // concats present list with new data via spread
      const movieList = [...state.movies, ...movies];

      // This subtracts selected movies from the movie list.
      // Selected movies should not be shown in search results in CardListModal
      const updatedMovieList = movieList.filter(
        ({ id: movieId }) =>
          !state.selectedMovies.some(
            ({ id: selectedMovieId }) => selectedMovieId === movieId
          )
      );

      // if we do an additional fetch request and nothing is returned
      // flip the fetchMore flag to true to prevent any more calls
      if (movies.length === 0) {
        const totalCount = updatedMovieList.length;

        return {
          ...state,
          movies: updatedMovieList,
          totalCount,
          isInitialDataLoaded: true,
          fetchMore: false,
        };
      }

      // if we query tmdb, increase
      if (queried_tmdb_page > -1) {
        const updatedTMDBPageNum = queried_tmdb_page + 1;
        const totalCount = updatedMovieList.length;

        return {
          ...state,
          movies: updatedMovieList,
          totalCount,
          isInitialDataLoaded: true,
          searchedMovies: [],
          tmdbPage: updatedTMDBPageNum,
          fetchMore: true,
        };
      }

      // if we just look to our DB and tmdbPage remains -1
      const updatedPageNum = state.pageNum + 1;

      return {
        ...state,
        movies: updatedMovieList,
        totalCount: count,
        isInitialDataLoaded: true,
        pageNum: updatedPageNum,
        searchedMovies: [],
        tmdbPage: -1,
        fetchMore: true,
      };
    }

    case ACTIONS.MOVIES.GET_MOVIES_FAIL: {
      return { ...state, isInitialDataLoaded: true };
    }

    case ACTIONS.MOVIES.DELETE_MOVIE_SUCCESS: {
      const updatedMovieList = state.movies.filter(
        (movie) => movie.id !== action.data
      );
      const updatedTotalCount = state.totalCount - 1;

      return {
        ...state,
        movies: updatedMovieList,
        totalCount: updatedTotalCount,
      };
    }

    case ACTIONS.MOVIES.DELETE_MOVIE_FAIL: {
      return { ...state };
    }

    case ACTIONS.MOVIES.BULK_ACTION: {
      return { ...state, bulkActionSuccess: false };
    }

    case ACTIONS.MOVIES.BULK_ACTION_SUCCESS: {
      const updatedMovieList = state.movies.filter(
        (movie) => action.data.id_list.indexOf(movie.id) < 0
      );
      const updatedTotalCount = state.totalCount - action.data.id_list.length;
      const message = action.data.delete
        ? DELETE_SUCCESS_MESSAGE
        : BULK_ACTION_SUCCESS_MESSAGE;

      toast.success(message);

      return {
        ...state,
        movies: updatedMovieList,
        totalCount: updatedTotalCount,
        bulkActionSuccess: true,
      };
    }

    case ACTIONS.MOVIES.BULK_ACTION_FAIL: {
      const message = action.data.delete
        ? DELETE_FAILURE_MESSAGE
        : BULK_ACTION_FAILURE_MESSAGE;

      toast.error(message);

      return { ...state };
    }

    case ACTIONS.MOVIES.CLEAR_MOVIES: {
      const { movies, totalCount, isInitialDataLoaded, pageNum } = initialState;

      return { ...state, movies, totalCount, isInitialDataLoaded, pageNum };
    }

    case ACTIONS.MOVIES.CLEAR_SELECTED_MOVIES: {
      return { ...state, selectedMovies: [] };
    }

    /* ------------ Reducer for CardSelector component ------------- */

    case ACTIONS.MOVIES.SELECT_MOVIE: {
      const firstNumSelected = action.data;
      // Unselected movies should not be shown in the modal. Hence removed
      // from main movie list
      const { pass: selectedMovies, fail: unselectedMovies } = splitArray(
        state.movies,
        (movie) => firstNumSelected.indexOf(movie.id) >= 0
      );

      return {
        ...state,
        selectedMovies: [...state.selectedMovies, ...selectedMovies],
        movies: [...unselectedMovies],
      };
    }

    case ACTIONS.MOVIES.DESELECT_MOVIE: {
      // Deselected movies are pushed back to the main movies list
      const { pass: deselectedMovies, fail: selectedMovies } = splitArray(
        state.selectedMovies,
        (movie) => action.data === movie.id
      );

      return {
        ...state,
        selectedMovies: [...selectedMovies],
        movies: [...state.movies, ...deselectedMovies],
      };
    }

    /* ----------------------------------------------------------------- */
    case ACTIONS.MOVIES.MOVE_TO_TOP: {
      const { id, data: modifiedData } = action.data;
      const dataWithID = state.movies.find((el) => el.id === id);
      const updatedDataWithoutID = state.movies.filter((el) => el.id !== id);
      const updatedData = [
        { ...dataWithID, ...modifiedData },
        ...updatedDataWithoutID,
      ];

      return { ...state, movies: [...updatedData] };
    }

    case ACTIONS.MOVIES.REMOVE_MOVIE: {
      const { id } = action.data;
      const updatedData = state.movies.filter((el) => el.id !== id);
      const updatedCount = state.totalCount - 1;

      return { ...state, movies: [...updatedData], totalCount: updatedCount };
    }

    default: {
      return state;
    }
  }
};

// ----------------------------------------------------------------------------|
//                           Movies Reducer Export
// ----------------------------------------------------------------------------|
export default Movies;
