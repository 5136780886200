/**
 * ************************************
 *
 * @module  FiltersUtils.js
 * @author  Matt P
 * @date    03/22/2021
 * @description Filters related utility functions
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import { CITIES } from 'constants.js';

/**
 * @description Filter updates : Toggle enable, toggle featured, delete
 * If delete, no key passed to API
 */
const updateMapper = {
  DELETE: null,
  ENABLE: 'is_enabled',
};

/**
 * @description Table columns for filters
 */
const cityCols = [
  {
    type: 'String',
    label: 'City Name',
    value: 'name',
    visibility: 'all',
  },
  {
    type: 'Switch',
    label: 'Enabled',
    value: 'status',
    visibility: 'all',
  },
  {
    type: 'String',
    label: 'Neighborhoods',
    value: 'subareas',
    visibility: 'all',
  },
  {
    type: 'Options',
    label: '',
    value: ['options'],
    visibility: 'all',
  },
];

const citiesCols = [
  {
    type: 'String',
    label: 'Neighborhood Name',
    value: 'name',
    visibility: 'all',
  },
  {
    type: 'Switch',
    label: 'Enabled',
    value: 'status',
    visibility: 'all',
  },
  {
    type: 'Options',
    label: '',
    value: ['options'],
    visibility: 'all',
  },
];

/**
 * @description form fields for City edit form
 */
const cityFormFields = [
  {
    label: 'Name of City',
    type: 'text',
    placeholder: 'Enter name',
    value: 'name',
  },
  {
    label: 'Enabled',
    type: 'switch',
    placeholder: '',
    value: 'status',
  },
];

/**
 * @description  maps over city array and replaces the successful
 * change with the changes
 *
 * @param {Array} cities
 * @param {Array} city
 *
 * @returns {Array}
 */
const mapAndReplace = (cities, city) =>
  cities.map((cit) => {
    if (cit.id === city.id) return city;
    return cit;
  });

/**
 * @description styling for <Switch> component
 */
const switchStyling = () => ({
  offColor: '#A8A4A0',
  onColor: '#F7BC25',
  handleDiameter: 20,
  uncheckedIcon: false,
  checkedIcon: false,
  height: 10,
  width: 35,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.25)',
});

const determineToggleAvailablity = (status) => {
  switch (status) {
    case CITIES.COMING_SOON:
    case CITIES.UNAVAILABLE: {
      return CITIES.AVAILABLE;
    }
    case CITIES.AVAILABLE: {
      return CITIES.UNAVAILABLE;
    }
    default:
      return CITIES.UNAVAILABLE;
  }
};

export {
  cityCols,
  citiesCols,
  determineToggleAvailablity,
  updateMapper,
  mapAndReplace,
  cityFormFields,
  switchStyling,
};
