/**
 * ************************************
 *
 * @module  EventData.constants.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Container for editing an 'Event' Currently renders within
 * the CardViewModal is shown renders when a card is clicked in the
 * EventContent component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import {
  getPriceRange,
  getReservationTiers,
  getDurationTiers,
  getForOptions,
} from 'utils/utils';
import { LONG_DESC_ROWS } from 'constants.js';

import {
  AddressInput,
  AmountFor,
  Button,
  DetailedDescription,
  CardSelection,
  EventDateSelector,
  MultiDropdownSelect,
  MultiTagSelect,
  ReviewStatus,
} from 'components';

// ----------------------------------------------------------------------------|
//                        EventData - Array of Objects
// ----------------------------------------------------------------------------|
const EventData = [
  {
    type: 'text',
    name: 'name',
    label: 'Name of Event',
    value: '',
    errorMsg: 'Name should be min 1 and max 100 characters long',
  },
  {
    type: 'component',
    name: 'address_input',
    label: 'Location of Event',
    // eslint-disable-next-line react/prop-types
    component: ({
      onPlaceSelected,
      value,
      errors,
      additionalComponent,
      disabled,
    }) => (
      <div className="form-field-wrapper">
        <AddressInput
          label="Address"
          id="addressInput"
          name="address_input"
          onPlaceSelected={onPlaceSelected}
          value={value}
          errors={errors}
          fromPlace
          checkboxComponent={additionalComponent}
          disabled={disabled}
        />
      </div>
    ),
  },
  {
    type: 'component',
    name: 'neighborhoods',
    component: ({ tags, suggestions, onChange, onButtonClick, error }) => (
      <div className="form-field-wrapper form-field-multi-comp">
        <div className="form-input">
          <label>Neighborhoods</label>
          <div className="form-input__input-wrapper">
            <MultiTagSelect
              name="neighborhoods"
              tags={tags}
              suggestions={suggestions}
              onChange={onChange}
              groupSuggestions
            />
            <Button
              className="btn-inverse"
              onClick={onButtonClick}
              preventDefault
            >
              Auto Tag
            </Button>
          </div>
        </div>
        <div className="error">{error}</div>
      </div>
    ),
  },
  {
    type: 'component',
    label: 'Main Category',
    name: 'main_categories',
    component: ({ options, values, onChange, disabled, error }) => (
      <div className="form-field-wrapper">
        <MultiDropdownSelect
          options={options}
          values={values}
          name="main_categories"
          onChange={onChange}
          disabled={disabled}
          error={error}
        />
      </div>
    ),
    errorMsg: 'Main Category cannot be empty',
  },
  {
    type: 'tag',
    label: 'Noteworthy',
    name: 'noteworthy_categories',
    values: [],
    value: '',
    placeholder: '',
    errorMsg: 'Noteworthy Category cannot be empty',
  },
  {
    type: 'tag',
    label: '“If you’re...”',
    name: 'perfect_for',
    placeholder: '',
    errorMsg: '“If you’re...” cannot be empty',
  },
  {
    type: 'tag',
    label: 'Vibes',
    name: 'vibes',
    placeholder: 'Select Vibes',
    errorMsg: 'Please Select Vibes',
  },
  {
    type: 'dropdown',
    label: 'Price Range',
    name: 'price_tier',
    values: getPriceRange(),
    value: '',
    placeholder: 'Select Price Range',
    errorMsg: 'Price Range cannot be empty',
  },
  {
    type: 'component',
    name: 'amount_per',
    // eslint-disable-next-line react/prop-types
    component: ({
      amount,
      amount_max,
      per,
      onChange,
      errors,
      isPriceDisabled,
    }) => (
      <div
        className={`form-field-wrapper ${isPriceDisabled ? 'disabled' : ''}`}
      >
        <AmountFor
          forOptions={getForOptions()}
          amount={amount}
          amount_max={amount_max}
          per={per}
          onChange={onChange}
          errors={errors}
        />
      </div>
    ),
    amount: '',
    amount_max: '',
    per: '',
  },
  // {
  //   type: 'textarea',
  //   name: 'description',
  //   label: 'Description',
  //   value: '',
  //   errorMsg: 'Long Description should be min 5 and max 50 characters long',
  //   rows: LONG_DESC_ROWS,
  // },
  {
    type: 'component',
    name: 'itemized_description',
    label: '',
    // eslint-disable-next-line react/prop-types
    component: ({ onChange, value, errors }) => (
      <div className="form-field-wrapper">
        <DetailedDescription
          name="itemized_description"
          onChange={onChange}
          value={value}
          errors={errors}
        />
      </div>
    ),
  },
  {
    type: 'component',
    name: 'pairings',
    label: '',
    // eslint-disable-next-line react/prop-types
    component: ({
      pairingReasonsOptions,
      title,
      verticalTypes,
      verticalType,
      errors,
    }) => (
      <div className="form-field-wrapper">
        <CardSelection
          pairingReasonsOptions={pairingReasonsOptions}
          title={title}
          verticalTypes={verticalTypes}
          verticalType={verticalType}
          errors={errors}
        />
      </div>
    ),
  },
  {
    type: 'component',
    name: 'section_header', // not a form field
    component: () => (
      <div className="form-field-header">
        <h2>Event Details</h2>
      </div>
    ),
  },
  {
    type: 'text',
    name: 'website_link',
    label: 'Website Link',
    value: '',
    errorMsg: 'Invalid link',
  },
  {
    type: 'multiinput',
    name: 'booking_links',
    label: 'Reservation Links',
    value: [],
    buttonTitle: 'Link',
    errorMsg: 'Invalid link',
  },
  {
    type: 'dropdown',
    label: 'How hard is it to book?',
    name: 'reservation_tier',
    // remove walk_in_only as that's toggled by checkbox
    values: getReservationTiers().slice(0, -1),
    value: '',
    errorMsg: '',
  },
  // {
  //   type: 'dropdown',
  //   label: 'Duration',
  //   name: 'duration',
  //   values: getDurationTiers(),
  //   placeholder: 'Choose duration',
  //   value: '',
  //   errorMsg: '',
  // },
  {
    type: 'component',
    name: 'event_hours',
    // eslint-disable-next-line react/prop-types
    component: ({ dates, onDatesChange, errors }) => (
      <div className="form-field-wrapper">
        <EventDateSelector
          dates={dates}
          onDatesUpdated={onDatesChange}
          errorMsg={errors}
        />
      </div>
    ),
  },
  {
    type: 'text',
    name: 'phone_number',
    label: 'Phone Number',
    value: '',
    errorMsg: 'Enter valid phone number',
    placeholder: '123-456-7890',
  },
  {
    type: 'component',
    name: 'last_admin_review',
    label: '',
    component: ({ value, history, onChange, reviewDate, errors }) => (
      <div className="form-field-wrapper">
        <ReviewStatus
          value={value}
          reviewDate={reviewDate}
          history={history}
          errors={errors}
          onChange={onChange}
        />
      </div>
    ),
  }
];

// ----------------------------------------------------------------------------|
//                               PropTypes Check
// ----------------------------------------------------------------------------|
EventData.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onPlaceSelected: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  errors: PropTypes.string.isRequired,
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default EventData;
