/**
 * ************************************
 *
 * @module  ThirdPartySearchModal.js
 * @author  Matt P
 * @date    06/22/2021
 * @description Modal to search against third parties;
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

import { Button, DropDown, Input, Modal } from 'components';

import { CITIES, GENERIC, PLACE, MOVIE } from 'constants.js';

import {
  constructQueryParams,
  deepCopy,
  returnDropdownValue,
} from 'utils/utils';

import ThirdPartySearch from './ThirdPartySearch/ThirdPartySearch';

import {
  chooseBlankForm,
  chooseApi,
  dropdownOptions,
  findResponseData,
  populateForms,
  verticalFormFields,
  clearFormsBySource,
  shouldHideOrDisable,
  hasUniqueIdByServiceName,
} from './ThirdPartySearchModalUtils';

import './ThirdPartySearchModal.scss';

// ----------------------------------------------------------------------------|
//              React Function Component - ThirdPartySearchModal
// ----------------------------------------------------------------------------|
const ThirdPartySearchModal = ({
  showModal,
  closeModal,
  verticalType, // place || movie || city || neighborhood
  onSubmit,
  cardData,
  noDataCallback,
}) => {
  /**
   * @description - sets existing data on edit or blank if new from the
   * template function
   */
  const [formState, setFormData] = useState(
    cardData || chooseBlankForm(verticalType)
  );

  const [fetchedDetailError, setFetchedDetailError] = useState('');
  const [fetchedDetailIsLoading, setFetchedDetailIsLoading] = useState(false);

  /**
   * @description - fetches the card detail once user selects an option from the
   * search
   *
   * @param {String} source - service to query
   * @param {String} id - id of item to query at said service
   * @param {Object} addtlFields - for special fields. ie: instance_type
   * for movie
   *
   * @returns {void}
   */
  const fetchThirdPartyDetail = (source, id, addtlFields = {}) => {
    const { searchData } = window.axios;
    const { THIRD_PARTY_SEARCH_FAIL } = GENERIC;

    setFetchedDetailIsLoading(true);

    searchData(
      'get',
      `${chooseApi[verticalType].details}?${constructQueryParams({
        source, // service to query
        third_party_id: id,
        ...addtlFields, // got special fields like instance_type for movie
      })}`
    )
      .then((response) => {
        const { data } = response;

        setFormData(
          populateForms[verticalType](
            formState,
            findResponseData(data, source),
            source
          )
        );
      })
      .catch((err) => {
        setFetchedDetailError(err);
        toast.error(THIRD_PARTY_SEARCH_FAIL);
      })
      .finally(() => {
        setFetchedDetailIsLoading(false);
      });
  };

  /**
   * @description - triggers when a user selects an option from the
   * ThirdPartySearch dropdown
   *
   * @param {Object} selectedValue - option selected from the dropdown
   * @param {String} field - which form field to update
   *
   * @returns {void}
   */
  const dropdownSelectionHandler = (selectedValue, field) => {
    // Created a new form and clears data on dropdown selection
    // need a blank slate to prevent bugs (ie: tv show ID mixed with mov
    const newFormState = chooseBlankForm(verticalType);
    const { value } = selectedValue;

    newFormState[field] = value;

    setFormData({ ...newFormState });
  };

  const textInputHandler = (input, field) => {
    const newForm = deepCopy(formState);

    newForm[field] = input.target.value;

    setFormData({ ...newForm });
  };

  const closeAndCleanup = () => {
    setFormData({});
    closeModal();
  };

  /**
   * @description submits form to redux, checks if this is a new cat or edit
   */
  const submitForm = () => {
    onSubmit(formState);
    closeAndCleanup();
  };

  const isCardVerticalType =
    verticalType === 'place' || verticalType === 'movie';

  /**
   * @description - fires when the "Could not find" button is clicked
   * fires noDataCallback prop which will FWD to card edit form
   *
   * @returns {void}
   */
  const couldNotFindButton = () => {
    noDataCallback({});
    closeAndCleanup();
  };

  /**
   * @description - clears form by source (google/foursquare/etc...)
   *
   * @param {String} searchSource - what service you're searching
   *
   * @returns {void}
   */
  const clearForm = (searchSource) => {
    setFormData(clearFormsBySource[verticalType](formState, searchSource));
  };

  /**
   * @description - returns footer jsx
   *
   * @returns {JSX}
   */
  const designFooter = () => (
    <div className="modal-actions">
      <Button
        name="SAVE"
        type="button"
        className="btn action-btn"
        onClick={submitForm}
        disabled={!shouldHideOrDisable[verticalType](formState)}
      >
        SAVE
      </Button>
      {isCardVerticalType && !shouldHideOrDisable[verticalType](formState) ? (
        <Button
          name="no-options"
          type="button"
          className="btn btn-clear"
          onClick={couldNotFindButton}
        >
          I Couldn't Find Connections
        </Button>
      ) : (
        ''
      )}
    </div>
  );

  /**
   * @description - returns proper header text
   *
   * @param {String} modalType
   * @param {Boolean} isEdit
   *
   * @returns {String}
   */
  const chooseHeader = (modalType, isEdit) => {
    if (modalType === 'place') {
      return isEdit ? PLACE.CARD_VIEW_TITLE_EDIT : PLACE.CARD_VIEW_TITLE;
    }

    if (modalType === 'movie') {
      return isEdit ? MOVIE.CARD_VIEW_TITLE_EDIT : MOVIE.CARD_VIEW_TITLE;
    }

    if (modalType === 'city') {
      return CITIES.NEW_CITY_TITLE;
    }

    if (modalType === 'neighborhood') {
      return isEdit.isEdit
        ? CITIES.EDIT_NEIGHBORHOOD_TITLE
        : CITIES.NEW_NEIGHBORHOOD_TITLE;
    }

    return '';
  };

  /**
   * @description - returns proper header text
   *
   * @param {String} modalType
   *
   * @returns {String}
   */
  const chooseSubHeader = (modalType) => {
    if (modalType === 'place' || modalType === 'movie') {
      return `First, check each API for the ${
        modalType === 'place' ? 'place' : 'movie/show'
      } and connect`;
    }
    return '';
  };

  const onOptionClick = (source, id, addtlFields = {}) => {
    if (verticalType === 'place' || verticalType === 'movie') {
      fetchThirdPartyDetail(source, id, addtlFields);
    }

    if (verticalType === 'city') {
      setFormData(
        populateForms[verticalType](
          formState,
          { place_id: id, ...addtlFields },
          source
        )
      );
    }

    if (verticalType === 'neighborhood') {
      setFormData(
        populateForms[verticalType](
          formState,
          { place_id: id, ...addtlFields },
          source
        )
      );
    }
  };

  return (
    <Modal
      title={chooseHeader(verticalType, cardData)}
      customClasses="third-party-modal"
      show={showModal}
      modalClosed={() => closeAndCleanup()}
      modalFooter={designFooter()}
      customStyles={{
        overflow: 'visible', // modal selection dropdown need to overflow;
        overflowY: 'visible', //
      }}
    >
      <span>{chooseSubHeader(verticalType)}</span>
      <form name="third-party-search" className="third-party-search-form">
        {verticalFormFields[verticalType].map((field) => {
          const { label, value, type, placeholder } = field;

          const { dependencyCheck, extractDependency } = field;

          // checks dependencies and disables inputs if needed
          const shouldDisable = dependencyCheck
            ? !dependencyCheck(formState)
            : false;

          const hasDependency =
            dependencyCheck && dependencyCheck(formState)
              ? extractDependency(formState)
              : false;

          if (type === 'dropdown') {
            return (
              <div
                className="third-party-search-input-wrapper"
                key={`${value}-3rd-party-search`}
              >
                <label id={`${label}-third-party-search`}>{label}</label>
                <DropDown
                  name={value}
                  onSelect={dropdownSelectionHandler}
                  options={dropdownOptions[verticalType]}
                  value={
                    hasDependency
                      ? returnDropdownValue(
                          dropdownOptions[verticalType],
                          extractDependency(formState)[value]
                        )
                      : ''
                  }
                  placeholder={
                    verticalType === 'movie' ? 'Select media type' : ''
                  }
                  className="third-party-dropdown"
                  controlClassName="third-party-dropdown-control"
                  placeholderClassName="third-party-dropdown-placeholder"
                  menuClassName="third-party-dropdown-menu"
                />
              </div>
            );
          }

          if (type === 'search') {
            let thirdPartySearchValue = null;

            if (isCardVerticalType) {
              thirdPartySearchValue = hasUniqueIdByServiceName[value](formState)
                ? formState.name || formState.title
                : null;
            }

            return (
              <div
                className="third-party-search-input-wrapper"
                key={`${value}-3rd-party-search`}
              >
                <ThirdPartySearch
                  // if dependencies aren't there clear the field of a value
                  value={thirdPartySearchValue}
                  label={label}
                  serviceName={value}
                  onOptionClick={onOptionClick}
                  disabled={shouldDisable}
                  searchDependencies={hasDependency}
                  verticalType={verticalType}
                  onClear={clearForm}
                  placeholder={placeholder}
                />
              </div>
            );
          }

          if (type === 'text') {
            return (
              <div
                className="third-party-search-input-wrapper"
                key={`${value}-3rd-party-search`}
              >
                <label id={`${label}-third-party-search`}>{label}</label>
                <Input
                  type={type}
                  name={value}
                  value={formState.name}
                  onChange={(input) => {
                    textInputHandler(input, value);
                  }}
                  placeholder={placeholder}
                />
              </div>
            );
          }

          return '';
        })}
      </form>
    </Modal>
  );
};

// --------------------------------------------------------------------------|
//                             PropTypes Check
// --------------------------------------------------------------------------|
ThirdPartySearchModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  verticalType: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  cardData: PropTypes.arrayOf(PropTypes.shape({})),
  noDataCallback: PropTypes.func,
};

// --------------------------------------------------------------------------|
//                              Default Props
// --------------------------------------------------------------------------|
ThirdPartySearchModal.defaultProps = {
  noDataCallback: () => {},
};

// ----------------------------------------------------------------------------|
//                        Export with Redux Connect
// ----------------------------------------------------------------------------|
export default ThirdPartySearchModal;
