/**
 * ************************************
 *
 * @module  ThirdPartySearchModalUtils.js
 * @author  Matt P
 * @date    06/24/2021
 * @description helper utils for the ThirdPartySearchModal
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';

import API from 'API';

import moment from 'moment';

import {
  deepCopy,
  getMovieInstanceType,
  qualifyArrayRendering,
  removeLastChars,
} from 'utils/utils';

import LanguageCodes from 'utils/LanguageCodes';
import placeholderImage from 'assets/images/placeholder.png';

// ----------------------------------------------------------------------------|
//                                  Utils
// ----------------------------------------------------------------------------|
const STATE_LEVEL_KEY = 'administrative_area_level_1';

/**
 * @description - chooses correct API to hit based on verticalType
 */
const chooseApi = {
  place: {
    search: API.placeThirdPartySearch,
    details: API.placeThirdPartyDetails,
  },
  movie: {
    search: API.movieThirdPartySearch,
    details: API.movieThirdPartyDetails,
  },
  city: {
    search: API.citiesThirdPartySearch,
    details: null, // no detail needed
  },
  neighborhood: {
    search: API.citiesThirdPartySearch,
    details: null, // no detail needed
  },
};

/**
 * @description - base URL for a TMDB image URL (which the response does not
 * provide)
 */
const tmdbImageBaseURL = 'https://image.tmdb.org/t/p/w92/'; // w92 is width

/**
 * @description - checks if response data has a lat, lng
 *
 * @param {Object} formData
 *
 * @returns {Boolean}
 */
const hasLatLng = (formData) => {
  const { lat, lng } = formData;

  return lat !== null || lng !== null;
};

/**
 * @description - checks if response data instance type
 *
 * @param {Object} formData
 *
 * @returns {Boolean}
 */
const hasInstanceType = (formData) => {
  const { instance_type } = formData;

  return instance_type === 'movie' || instance_type === 'show';
};

const hasSuperLocationName = (formData) => {
  const { super_location_name } = formData;

  return Boolean(super_location_name);
};

const isNeighborhoodEdit = (formData) => {
  const { isEdit } = formData;

  return isEdit;
};

const neighborhoodEditDependencies = (formData) =>
  !isNeighborhoodEdit(formData) && hasSuperLocationName(formData);

/**
 * @description - takes yelp response and formats address for
 * ThirdPartySearch dropdown
 *
 * @param {Object} yelpLocationProp - yelp response data
 *
 * @returns {String}
 */
const chooseAndRenderYelpSearchAddress = (yelpLocationProp = {}) => {
  const { display_address, address1, city, zip_code } = yelpLocationProp;
  let returnedLocationString = '';

  if (qualifyArrayRendering(display_address)) {
    display_address.forEach((addr) => {
      returnedLocationString += `${addr}, `;
    });
  } else if (typeof address1 === 'string' && address1.length) {
    returnedLocationString += `${address1}, `;

    if (typeof city === 'string' && city.length) {
      returnedLocationString += `${city}, `;
    }

    if (typeof zip_code === 'string' && zip_code.length) {
      returnedLocationString += `${zip_code}, `;
    }
  }

  return returnedLocationString.length > 0
    ? removeLastChars(returnedLocationString, 2)
    : '';
};

/**
 * @description - some searches have dependencies that need to be
 * fulfilled before a user can search. This object and methods return those
 */
const extractDependencies = {
  latLng(formData) {
    const { lat, lng } = formData;

    return {
      latitude: lat,
      longitude: lng,
    };
  },
  instanceType(formData) {
    const { instance_type } = formData;

    return {
      instance_type,
    };
  },
  superLocationName(formData) {
    const { super_location_name } = formData;

    return {
      super_location_name,
    };
  },
};

/**
 * @description - array to map through the generated search fields by vertical
 */
const verticalFormFields = {
  place: [
    {
      label: 'Google',
      value: 'google',
      type: 'search',
    },
    {
      label: 'Yelp',
      value: 'yelp',
      type: 'search',
      dependencyCheck: hasLatLng,
      extractDependency: extractDependencies.latLng,
    },
    {
      label: 'Foursquare',
      value: 'foursquare',
      type: 'search',
      dependencyCheck: hasLatLng,
      extractDependency: extractDependencies.latLng,
    },
  ],
  movie: [
    {
      label: 'Search Type',
      value: 'instance_type',
      type: 'dropdown',
      dependencyCheck: hasInstanceType,
      extractDependency: extractDependencies.instanceType,
    },
    {
      label: 'TBDb',
      value: 'tmdb',
      type: 'search',
      dependencyCheck: hasInstanceType,
      extractDependency: extractDependencies.instanceType,
    },
  ],
  city: [
    {
      label: 'Find City',
      value: 'google',
      type: 'search',
      placeholder: 'Search for city',
    },
  ],
  neighborhood: [
    {
      label: 'Neighborhood Name',
      value: 'name',
      type: 'text',
      placeholder: 'Enter Neighborhood name',
    },
    {
      label: 'Find Neighborhood',
      value: 'google',
      type: 'search',
      placeholder: 'Search for neighborhood',
      dependencyCheck: neighborhoodEditDependencies,
      extractDependency: neighborhoodEditDependencies,
    },
  ],
};

/**
 * @description - modal with dropdown selections require options
 */
const dropdownOptions = {
  movie: getMovieInstanceType(),
};

/**
 * @description - each ThirdPartySearch dropdown returns different data,
 * this object and methods choose the correct one and format it
 */
const searchDropdownByService = {
  foursquare(option, serviceName, onClick) {
    const { name, location, id } = option;
    const { formattedAddress } = location;

    if (name && qualifyArrayRendering(formattedAddress) && id) {
      const spanAddress = [];

      formattedAddress.forEach((addr) => {
        spanAddress.push(<span key={`${id}-${addr}`}>{addr}</span>);
      });

      // pop off address Country if length is 3
      if (spanAddress.length === 3) {
        spanAddress.pop();
      }

      return (
        <div
          className="third-party-search-option-wrapper"
          key={id}
          role="button"
          onClick={() => onClick(serviceName, id, name)}
          tabIndex={0}
        >
          <div className="third-party-search-option">
            <h3>{name}</h3>
            <h4>{spanAddress}</h4>
          </div>
        </div>
      );
    }

    return '';
  },
  google(option, serviceName, onClick) {
    const { name, formatted_address, place_id, geometry, address_components } =
      option;

    if (name && formatted_address && place_id) {
      return (
        <div
          className="third-party-search-option-wrapper"
          key={place_id}
          role="button"
          onClick={() => onClick(serviceName, place_id, name)}
          tabIndex={0}
        >
          <div className="third-party-search-option">
            <h3>{name}</h3>
            <h4>{formatted_address}</h4>
          </div>
        </div>
      );
    }

    if ((geometry || address_components) && formatted_address && place_id) {
      return (
        <div
          className="third-party-search-option-wrapper"
          key={place_id}
          role="button"
          onClick={() =>
            onClick(serviceName, place_id, formatted_address, {
              formatted_address,
              address_components,
            })
          }
          tabIndex={0}
        >
          <div className="third-party-search-option">
            <h3>{formatted_address}</h3>
          </div>
        </div>
      );
    }

    return '';
  },
  yelp(option, serviceName, onClick) {
    const { name, location, id } = option;
    const formattedLocationString = chooseAndRenderYelpSearchAddress(location);

    if (name && id) {
      return (
        <div
          className="third-party-search-option-wrapper"
          key={id}
          role="button"
          onClick={() => onClick(serviceName, id, name)}
          tabIndex={0}
        >
          <div className="third-party-search-option">
            <h3>{name}</h3>
            <h4>{formattedLocationString}</h4>
          </div>
        </div>
      );
    }

    return '';
  },
  tmdb(option, serviceName, onClick, { instance_type }) {
    const {
      id,
      title,
      name,
      poster_path,
      first_air_date,
      release_date,
      original_language,
    } = option;
    const formattedOriginalLanguage = LanguageCodes[original_language]
      ? LanguageCodes[original_language].name
      : '';

    const formattedReleaseDate =
      release_date || first_air_date
        ? `Released: ${moment(
            release_date || first_air_date,
            'YYYY-MM-DD'
          ).format('MMMM D, YYYY')}`
        : '';

    if ((title || name) && id) {
      return (
        <div
          className="third-party-search-option-wrapper"
          key={id}
          role="button"
          onClick={() =>
            onClick(serviceName, id, title || name, { instance_type })
          }
          tabIndex={0}
        >
          {poster_path ? (
            <img
              className="third-party-search-poster"
              src={`${tmdbImageBaseURL}${poster_path}`}
              onError={(event) => {
                event.target.src = placeholderImage;
              }}
              alt="poster-thumb"
            />
          ) : (
            <img
              className="third-party-search-poster "
              src={placeholderImage}
              alt="poster-thumb"
            />
          )}
          <div className="third-party-search-option">
            <h3>{title || name}</h3>
            <h4>
              {formattedOriginalLanguage}
              {formattedOriginalLanguage && formattedReleaseDate ? ' - ' : ''}
              {formattedReleaseDate}
            </h4>
          </div>
        </div>
      );
    }

    return '';
  },
};

/**
 * @description used to build form fields via verticalType
 */
const blankThirdPartyForms = {
  place: {
    name: null,
    address: null,
    phone_number: null,
    lat: null,
    lng: null,
    website_link: null,
    related_gmaps_id: '',
    related_yelp_id: '',
    related_foursquare_id: '',
  },
  movie: {
    title: null,
    instance_type: null,
    release_date: null,
    phrase: null,
    description: null,
    related_tmdb_id: '',
    // needed for prepping form data
    rating: [],
    categories: [],
  },
  city: {
    super_location_name: null, // example New York is super to Manhattan
    related_gmaps_place_id: '',
    location_type: 'city',
    name: null, // of location
  },
  neighborhood: {
    super_location_name: null,
    related_gmaps_place_id: '',
    location_type: 'neighborhood',
    name: '', // of location
    isEdit: false,
  },
};

/**
 * @description returns a blankThird Party form for the
 * modal searches to populate
 *
 * @param {String} verticalType
 *
 * @returns {Object} for desired vertical form
 */
const chooseBlankForm = (verticalType) => {
  if (!verticalType) return {};

  return deepCopy(blankThirdPartyForms[verticalType]);
};

/**
 * @description each service response data may be different. This function
 * accesses the correct nested data
 *
 * @param {Object} nakedResponse - of response
 * @param {String} source - source of response
 *
 * @returns {Object}
 */
const findResponseData = (nakedResponse, source) => {
  switch (source) {
    case 'foursquare':
      return nakedResponse.extended_details.venue;
    case 'google':
    case 'yelp':
    case 'tmdb':
      return nakedResponse.extended_details;
    default:
      return {};
  }
};

const extractGoogleAddressComponents = (addressComponents) => {
  let name;
  let superLocationName;

  if (
    qualifyArrayRendering(addressComponents) &&
    addressComponents[0].long_name
  ) {
    name = addressComponents[0].long_name;
  }

  if (qualifyArrayRendering(addressComponents)) {
    addressComponents.forEach((address) => {
      const { types } = address;

      if (qualifyArrayRendering(types)) {
        types.forEach((nameType) => {
          if (nameType === STATE_LEVEL_KEY) {
            const { long_name } = address;
            superLocationName = long_name;
          }
        });
      }
    });
  }

  if (name !== undefined && superLocationName !== undefined) {
    return {
      name,
      super_location_name: superLocationName,
    };
  }

  if (name !== undefined && superLocationName === undefined) {
    return {
      name,
    };
  }

  return null;
};

/**
 * @description populates the form data via responses by vertical type
 */
const populateForms = {
  place(currForm, responseDetail, source) {
    let formCopy = deepCopy(currForm);

    if (source === 'foursquare') {
      const { contact, location, id, name, url } = responseDetail;
      const { phone } = contact;
      const { lat, lng, formattedAddress } = location;

      const mergedObj = {
        related_foursquare_id: id,
        // phone_number: phone || '',
        // lat,
        // lng,
        // address:
        //   formattedAddress && formattedAddress.length
        //     ? formattedAddress.join(', ')
        //     : null,
        // name,
        website_link: url,
      };

      formCopy = {
        ...formCopy,
        ...mergedObj,
      };
    }

    if (source === 'google') {
      const { place_id, geometry, formatted_address, name, phone } = responseDetail;
      const { location } = geometry;
      const { lat, lng } = location;
      const mergedObj = {
        related_gmaps_id: place_id,
        phone_number: phone || '',
        lat,
        lng,
        address: formatted_address,
        name,
      }

      formCopy = {
        ...formCopy,
        ...mergedObj,
      };
    }

    if (source === 'yelp') {
      const { id } = responseDetail;

      formCopy = {
        ...formCopy,
        related_yelp_id: id,
      };
    }

    return formCopy;
  },
  movie(currForm, responseDetail, source) {
    let formCopy = deepCopy(currForm);

    if (source === 'tmdb') {
      const {
        id,
        title,
        name,
        release_date,
        first_air_date,
        tagline,
        overview,
      } = responseDetail;

      formCopy = {
        ...formCopy,
        title: title || name,
        release_date: release_date || first_air_date,
        phrase: tagline,
        description: overview,
        related_tmdb_id: id,
      };
    }

    return formCopy;
  },
  city(currForm, responseDetail, source) {
    let formCopy = deepCopy(currForm);

    if (source === 'google') {
      const { place_id, formatted_address, address_components } =
        responseDetail;
      const nameExtraction = extractGoogleAddressComponents(address_components);

      formCopy = {
        ...formCopy,
        related_gmaps_place_id: place_id,
        name: nameExtraction.name
          ? nameExtraction.name
          : formatted_address.split(',')[0],
        super_location_name: nameExtraction.super_location_name || '',
      };
    }

    return formCopy;
  },
  neighborhood(currForm, responseDetail, source) {
    let formCopy = deepCopy(currForm);

    if (source === 'google') {
      const { place_id } = responseDetail;
      const chooseNameField = () => {
        if (formCopy.name === '') {
          const { address_components, formatted_address } = responseDetail;

          const nameExtraction =
            extractGoogleAddressComponents(address_components);

          return nameExtraction.name
            ? nameExtraction.name
            : formatted_address.split(',')[0];
        }

        return formCopy.name;
      };

      formCopy = {
        ...formCopy,
        name: chooseNameField(),
        related_gmaps_place_id: place_id,
      };
    }

    return formCopy;
  },
};

/**
 * @description clears specific data by search type if a user hits the
 * input clear (X) button
 */
const clearFormsBySource = {
  place(currForm, source) {
    let formCopy = deepCopy(currForm);

    if (source === 'google') {
      const mergedObj = {
        related_gmaps_id: '',
        phone_number: null,
        lat: null,
        lng: null,
        address: null,
        name: null,
        website_link: null,
      };

      formCopy = {
        ...formCopy,
        ...mergedObj,
      };
    }

    if (source === 'foursquare') {
      formCopy = {
        ...formCopy,
        related_foursquare_id: '',
      };
    }

    if (source === 'yelp') {
      formCopy = {
        ...formCopy,
        related_yelp_id: '',
      };
    }

    return formCopy;
  },
  movie(currForm, source) {
    let formCopy = deepCopy(currForm);

    if (source === 'tmdb') {
      formCopy = {
        ...formCopy,
        title: null,
        release_date: null,
        phrase: null,
        description: null,
        related_tmdb_id: '',
      };
    }

    return formCopy;
  },
  city(currForm, source) {
    let formCopy = deepCopy(currForm);

    if (source === 'google') {
      formCopy = {
        ...formCopy,
        related_gmaps_place_id: '',
      };
    }

    return formCopy;
  },
  neighborhood(currForm, source) {
    let formCopy = deepCopy(currForm);

    if (source === 'google') {
      formCopy = {
        ...formCopy,
        related_gmaps_place_id: '',
      };
    }

    return formCopy;
  },
};

/**
 * @description checks dependencies and disables input if not present
 */
const shouldHideOrDisable = {
  place: (formData) => hasLatLng(formData),
  movie: (formData) => Boolean(formData.related_tmdb_id),
  city: (formData) =>
    Boolean(formData.name) && Boolean(formData.related_gmaps_place_id),
  neighborhood: (formData) => {
    if (formData.isEdit) return Boolean(formData.name);
    return Boolean(formData.name) && Boolean(formData.related_gmaps_place_id);
  },
};

/**
 * @description checks if we've obtained the ID by service
 */
const hasUniqueIdByServiceName = {
  foursquare: (formData) => Boolean(formData.related_foursquare_id),
  google: (formData) =>
    Boolean(formData.related_gmaps_id) ||
    Boolean(formData.related_gmaps_place_id),
  yelp: (formData) => Boolean(formData.related_yelp_id),
  tmdb: (formData) => Boolean(formData.related_tmdb_id),
};

/**
 * @description handle response data depending on source
 *
 * @param {String} service
 * @param {Object[]} priorData - existing data
 * @param {Object[]} responseData
 *
 * @returns {Object[]}
 */
const handleResponseDataByServiceName = (
  service,
  priorData = [],
  responseData = []
) => {
  switch (service) {
    case 'foursquare':
    case 'google':
      return responseData;
    case 'yelp':
    case 'tmdb':
      return [...priorData, ...responseData];
    default:
      return responseData;
  }
};

// ----------------------------------------------------------------------------|
//                                 Exports
// ----------------------------------------------------------------------------|
export {
  chooseBlankForm,
  chooseApi,
  hasLatLng,
  searchDropdownByService,
  dropdownOptions,
  findResponseData,
  populateForms,
  verticalFormFields,
  clearFormsBySource,
  shouldHideOrDisable,
  hasUniqueIdByServiceName,
  handleResponseDataByServiceName,
};
