/**
 * ************************************
 *
 * @module  CuratedCard.reducer.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description CuratedCard reducer file
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import { toast } from 'react-toastify';

import ACTIONS from 'store/actions/actionTypes';

import { splitArray } from 'utils/utils';

import { CURATED } from 'constants.js';

// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|
// identifier can be the source url of the image in case of autofilled images
// For images that were not autofilled, it is the file id
// If an image's source url or its ID matches the identifier param, delete
// it from the array
const getFilteredImages = (identifier, images = []) =>
  images.filter(
    (image) => image.url !== identifier && image.fileId !== identifier
  );

// ----------------------------------------------------------------------------|
//                               Initial State
// ----------------------------------------------------------------------------|
const initialState = {
  // Contains images that are to be autofilled into the image upload component
  autofilledImages: [],
  images: [],
  formData: {},
  isBtnDisabled: false,

  // Contains list of fileIds for whom photo upload failed
  failedUploads: [],
  isDropZonePreviewRequired: false,

  // Card list data
  cardListData: [],
  cardListDataCount: 0,
  isCardListDataFetched: false,
  cardListPageNum: 1,
  selectedCards: [],
};

// ----------------------------------------------------------------------------|
//                            CuratedCard Reducer
// ----------------------------------------------------------------------------|
const CuratedCard = (state = initialState, action = null) => {
  const { type, data } = action;
  /**
   * @description - populates autofilled form images
   */
  switch (type) {
    case ACTIONS.CURATED_CARD.POPULATE_IMAGES: {
      return {
        ...state,
        autofilledImages: data || [],
      };
    }

    /**
     * @description Any local state state saving
     */
    case ACTIONS.CURATED_CARD.SAVE_DATA_TO_REDUX: {
      return {
        ...state,
        formData: { ...state.formData, ...data },
      };
    }

    /**
     * @description Clear any errors for image being uploaded
     */
    case ACTIONS.CURATED_CARD.UPLOAD_IMAGE: {
      return {
        ...state,
        isDropZonePreviewRequired: true,
        failedUploads: state.failedUploads.filter(
          (fileId) => fileId !== data.fileId
        ),
      };
    }

    /**
     * @description upon successful image upload
     */
    case ACTIONS.CURATED_CARD.UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        autofilledImages: [
          ...state.autofilledImages,
          { ...data, fileId: data.fileId },
        ],
        isDropZonePreviewRequired: false,
      };
    }

    /**
     * @description upon unsuccessful image upload
     */
    case ACTIONS.CURATED_CARD.UPLOAD_IMAGE_FAIL: {
      return { ...state, failedUploads: [...state.failedUploads, data.fileId] };
    }

    /**
     * @description deletes an image, no success or fail as this is not
     * http - data is saved upon form submission
     */
    case ACTIONS.CURATED_CARD.DELETE_IMAGE: {
      return {
        ...state,
        autofilledImages: getFilteredImages(data, state.autofilledImages),
      };
    }

    /**
     * @description resets state
     */
    case ACTIONS.CURATED_CARD.CLEAR_DATA: {
      return {
        ...initialState,
      };
    }

    /**
     * @description selects a card for the card selector components
     */
    case ACTIONS.CURATED_CARD.SELECT_CARD_LIST: {
      const selectedFromCardListId = action.data;
      const { pass: selectedCards, fail: unselectedCards } = splitArray(
        state.cardListData,
        (card) => selectedFromCardListId.indexOf(card.id) >= 0
      );

      return {
        ...state,
        selectedCards: [...state.selectedCards, ...selectedCards],
        cardListData: [...unselectedCards],
      };
    }

    /**
     * @description deselects a card for the card selector components
     */
    case ACTIONS.CURATED_CARD.DESELECT_CARD_LIST: {
      const deselectedCard = action.data;
      const updatedSelectedCards = state.selectedCards.filter(
        (card) => card.id !== deselectedCard.id
      );

      return {
        ...state,
        cardListData: [...state.cardListData, deselectedCard],
        selectedCards: [...updatedSelectedCards],
      };
    }

    /**
     * @description clears that shit
     */
    case ACTIONS.CURATED_CARD.CLEAR_CARD_LIST: {
      return {
        ...state,
        cardListData: [],
        cardListDataCount: 0,
        isCardListDataFetched: false,
        cardListPageNum: 1,
        selectedFromCardListId: [],
      };
    }

    /**
     * @description upon successful fetch of card data for the card selections
     * component
     */
    case ACTIONS.CURATED_CARD.GET_CARD_LIST_SUCCESS: {
      const fetchedData =
        data.places || data.movies || data.recipes || data.activities;

      const updatedCardListData = [
        ...state.cardListData,
        ...fetchedData,
      ].filter(
        ({ id: cardId }) =>
          !state.selectedCards.some(
            ({ id: selectedCardId }) => selectedCardId === cardId
          )
      );
      const updatedCardListCount = action.data.count;
      const updatedCardPageNum = state.cardListPageNum + 1;

      return {
        ...state,
        cardListData: updatedCardListData,
        cardListPageNum: updatedCardPageNum,
        cardListDataCount: updatedCardListCount,
        isCardListDataFetched: true,
      };
    }

    /**
     * @description upon successful fetch a curated card URL
     */
    case ACTIONS.CURATED_CARD.FETCH_CURATED_CARD_SUCCESS: {
      return {
        ...state,
        selectedCards: [...data.places, ...data.movies],
        fetchedCardData: data,
      };
    }

    /**
     * @description upon unsuccessful fetch a curated card URL
     */
    case ACTIONS.CURATED_CARD.FETCH_CURATED_CARD_FAIL: {
      const {
        TOAST: { FETCH_FAILURE_MESSAGE },
      } = CURATED;

      toast.error(FETCH_FAILURE_MESSAGE);

      return { ...state };
    }

    /**
     * @description upon unsuccessful fetch of card data for the card selections
     * component
     */
    case ACTIONS.CURATED_CARD.GET_CARD_LIST_FAIL: {
      return { ...state, isCardListDataFetched: true };
    }

    /**
     * @description reorders form images
     */
    case ACTIONS.CURATED_CARD.RE_ORDER_IMAGES: {
      return { ...state, autofilledImages: data };
    }

    /**
     * @description disables form submission buttons after submission
     */
    case ACTIONS.CURATED_CARD.BUTTON_DISBALING: {
      return {
        ...state,
        isBtnDisabled: data.isBtnDisabled,
      };
    }

    default: {
      return state;
    }
  }
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default CuratedCard;
