/**
 * ************************************
 *
 * @module  Filters.saga.js
 * @author  Matt P
 * @date    03/11/2021
 * @description Filters saga file
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import { call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import API from 'API';

import ACTIONS from 'store/actions/actionTypes';
import { clearAdminCategories } from 'store/actions/Places.action';

import { FILTER } from 'constants.js';

import { reportAPIError } from 'utils/ErrorHandlingUtils';

// ----------------------------------------------------------------------------|
//                            Sagas - Filters
// ----------------------------------------------------------------------------|
/**
 * @description gets filters list http request
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* getFilterList(action) {
  const { getData } = window.axios;

  try {
    const URL = API.filtersList;
    const response = yield call(getData, URL);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.FILTERS.GET_LIST_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: ACTIONS.FILTERS.GET_LIST_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.FILTERS.GET_LIST_FAIL });
  }
}

/**
 * @description gets filter rules list http request
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* getFilterRulesList(action) {
  const { getData } = window.axios;

  try {
    const URL = API.filtersRules;
    const response = yield call(getData, URL);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.FILTERS.GET_RULES_LIST_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: ACTIONS.FILTERS.GET_RULES_LIST_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.FILTERS.GET_RULES_LIST_FAIL });
  }
}

/**
 * @description creates filter http request
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* createFilter(action) {
  const { putData } = window.axios;
  const { data } = action;

  const { CREATE_FILTER_SUCCESS, CREATE_FILTER_FAILURE } = FILTER.TOAST;

  try {
    const URL = API.filtersList;
    const response = yield call(putData, URL, data);
    const responseData = response.data;

    if (response.status === 200) {
      yield put({
        type: ACTIONS.FILTERS.CREATE_FILTER_SUCCESS,
        responseData,
      });

      yield put(clearAdminCategories());

      toast.success(CREATE_FILTER_SUCCESS);
    } else {
      reportAPIError('Filter Create - HTTP', response.data);

      yield put({ type: ACTIONS.FILTERS.AJAX_FAIL });

      toast.error(CREATE_FILTER_FAILURE);
    }
  } catch (error) {
    reportAPIError('Overall Filter Create Catch', error);

    yield put({ type: ACTIONS.FILTERS.AJAX_FAIL });

    toast.error(CREATE_FILTER_FAILURE);
  }
}

/**
 * @description edits filter http request
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* editFilter(action) {
  const { putData } = window.axios;
  const { passedFilter, apiData } = action.data;

  const { EDIT_FILTER_SUCCESS, EDIT_FILTER_FAILURE } = FILTER.TOAST;

  const {
    name,
    is_enabled,
    filter_types,
    is_featured,
    advanced_options,
    emoji_icon,
    categories_in_filter,
    id,
    required_neighborhoods,
    excluded_neighborhoods,
  } = apiData;

  // backend can't accept all fields for this request
  // wash it
  const washedPutData = {
    name,
    is_enabled,
    filter_types,
    is_featured,
    advanced_options,
    emoji_icon,
    categories_in_filter,
    required_neighborhoods,
    excluded_neighborhoods,
  };

  advanced_options.forEach((option) => {
    if (
      option.option_key &&
      (option.option_key === 'location' || option.option_key === 'price') &&
      option.option_rule === null
    ) {
      delete option.option_rule;
    }

    if (
      option.option_key &&
      option.option_key === 'rule' &&
      option.option_rule &&
      option.option_rule.name
    ) {
      delete option.option_rule.name;
    }

    if (option.categories_in_rule) {
      delete option.categories_in_rule;
    }
  });

  try {
    const URL = `${API.filters}${id}`;
    const response = yield call(putData, URL, washedPutData);
    const responseData = response.data;

    if (response.status === 200) {
      yield put({
        type: ACTIONS.FILTERS.EDIT_FILTER_SUCCESS,
        data: responseData,
      });

      yield put(clearAdminCategories());

      toast.success(EDIT_FILTER_SUCCESS);
    } else {
      reportAPIError('Filter Edit - HTTP', response.data);

      yield put({ type: ACTIONS.FILTERS.AJAX_FAIL });

      toast.error(EDIT_FILTER_FAILURE);
    }
  } catch (error) {
    reportAPIError('Overall Filter Create Catch', error);

    yield put({ type: ACTIONS.FILTERS.AJAX_FAIL });

    toast.error(EDIT_FILTER_FAILURE);
  }
}

/**
 * @description delete filter http request
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* deleteFilter(action) {
  const { deleteData } = window.axios;
  const { DELETE_SUCCESS_MESSAGE, DELETE_FAILURE_MESSAGE } = FILTER.TOAST;
  const { filter, apiData } = action.data;

  try {
    const URL = `${API.filters}${apiData.id}`;
    const response = yield call(deleteData, URL);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.FILTERS.DELETE_FILTER_SUCCESS,
        data: filter,
      });

      yield put(clearAdminCategories());

      toast.success(DELETE_SUCCESS_MESSAGE);
    } else {
      reportAPIError('Filter Delete - HTTP', response.data);

      yield put({ type: ACTIONS.FILTERS.AJAX_FAIL });

      toast.error(DELETE_FAILURE_MESSAGE);
    }
  } catch (error) {
    reportAPIError('Overall Filter Delete Catch', error);

    yield put({ type: ACTIONS.FILTERS.AJAX_FAIL });

    toast.error(DELETE_FAILURE_MESSAGE);
  }
}

/**
 * @description toggles feature/un-feature filter http request
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* toggleFeature(action) {
  const { putData } = window.axios;
  const {
    FEATURE_SUCCESS_MESSAGE,
    FEATURE_FAILURE_MESSAGE,
    UNFEATURE_SUCCESS_MESSAGE,
    UNFEATURE_FAILURE_MESSAGE,
  } = FILTER.TOAST;
  const { filter, apiData } = action.data;

  // backend can't accept all fields for this request
  // wash it
  const washedPutData = {
    is_featured: apiData.is_featured,
    name: apiData.name,
  };

  try {
    const URL = `${API.filters}${apiData.id}`;
    const response = yield call(putData, URL, washedPutData);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.FILTERS.TOGGLE_FEATURE_SUCCESS,
        data: response.data,
      });

      yield put(clearAdminCategories());

      toast.success(
        apiData.is_featured
          ? FEATURE_SUCCESS_MESSAGE
          : UNFEATURE_SUCCESS_MESSAGE
      );
    } else {
      yield put({ type: ACTIONS.FILTERS.AJAX_FAIL });

      toast.error(
        apiData.is_featured
          ? FEATURE_FAILURE_MESSAGE
          : UNFEATURE_FAILURE_MESSAGE
      );
    }
  } catch (error) {
    yield put({ type: ACTIONS.FILTERS.AJAX_FAIL });

    toast.error(
      apiData.is_featured ? FEATURE_FAILURE_MESSAGE : UNFEATURE_FAILURE_MESSAGE
    );
  }
}

/**
 * @description toggles enable/disable filter http request
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* toggleEnable(action) {
  const { putData } = window.axios;
  const {
    ENABLE_SUCCESS_MESSAGE,
    ENABLE_FAILURE_MESSAGE,
    DISABLE_SUCCESS_MESSAGE,
    DISABLE_FAILURE_MESSAGE,
  } = FILTER.TOAST;
  const { filter, apiData } = action.data;

  // backend can't accept all fields for this request
  // wash it
  const washedPutData = {
    is_enabled: apiData.is_enabled,
    name: apiData.name,
  };

  try {
    const URL = `${API.filters}${apiData.id}`;
    const response = yield call(putData, URL, washedPutData);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.FILTERS.TOGGLE_ENABLE_SUCCESS,
        data: response.data,
      });

      yield put(clearAdminCategories());

      toast.success(
        apiData.is_enabled ? ENABLE_SUCCESS_MESSAGE : DISABLE_SUCCESS_MESSAGE
      );
    } else {
      yield put({ type: ACTIONS.FILTERS.AJAX_FAIL });

      toast.error(
        apiData.is_enabled ? ENABLE_FAILURE_MESSAGE : DISABLE_FAILURE_MESSAGE
      );
    }
  } catch (error) {
    yield put({ type: ACTIONS.FILTERS.AJAX_FAIL });

    toast.error(
      apiData.is_enabled ? ENABLE_FAILURE_MESSAGE : DISABLE_FAILURE_MESSAGE
    );
  }
}

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export {
  getFilterList,
  getFilterRulesList,
  createFilter,
  editFilter,
  deleteFilter,
  toggleFeature,
  toggleEnable,
};
