/**
 * ************************************
 *
 * @module  index.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description redux store index file.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducers from 'store/rootReducer';
import rootSaga from 'store/rootSaga';

const sagaMiddleware = createSagaMiddleware();

// Dev Tools for development and enhancement.
const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : null || compose;

// Store object
const store = createStore(
  rootReducers,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(function* () {
  /* eslint func-names: [0] */
  yield rootSaga();
});

// ----------------------------------------------------------------------------|
//                                Store Export
// ----------------------------------------------------------------------------|
export default store;
