/**
 * ************************************
 *
 * @module  Views.js
 * @author  Matt P
 * @date    02/08/21
 * @description Component that shows view count
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                  Imports
// ---------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import ViewCount from 'assets/images/views.svg';

import './Views.scss';

// ---------------------------------------------------------------------------|
//                     React Function Component - Views
// ---------------------------------------------------------------------------|
const Views = ({ data, className }) => {
  const viewsClass = cx(['views-count'], className);

  return (
    <div className={`${viewsClass}-container`}>
      <div className={viewsClass}>
        <img src={ViewCount} alt="views-count-icon"></img>
        <span>{data || ''}</span>
      </div>
    </div>
  );
};

// ---------------------------------------------------------------------------|
//                             PropTypes Check
// ---------------------------------------------------------------------------|
Views.propTypes = {
  data: PropTypes.number,
  className: PropTypes.string,
};
// ---------------------------------------------------------------------------|
//                              Default Props
// ---------------------------------------------------------------------------|
Views.defaultProps = {
  data: 0,
  className: '',
};
// ---------------------------------------------------------------------------|
//                                  Export
// ---------------------------------------------------------------------------|
export default Views;
