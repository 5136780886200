/**
 * ************************************
 *
 * @module  VerticalDisplay.action.js
 * @author  Matt P
 * @date   07/02/2021
 * @description redux action file for the VerticalDisplay container.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import moment from 'moment';

import {
  isArrayEmpty,
  isValidNumber,
  trimItemizedDescription,
} from 'utils/FormFunc';

import CommonService from 'services/CommonService';

import { VERTICAL_TYPE } from 'constants.js';

import {
  ActivityData,
  CollectionData,
  EventData,
  MovieData,
  PlaceData,
  RecipeData,
} from './VerticalFormServices/index';

import { chooseVerticalService } from '../VerticalDisplayUtils';

// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|
const commonService = CommonService();

// ----------------------------------------------------------------------------|
//                        Component Helper Variables
// ----------------------------------------------------------------------------|
const newCardDesignVerticalSet = new Set([
  VERTICAL_TYPE.PLACE,
  VERTICAL_TYPE.EVENT,
]);

const alwaysFetchVerticalSet = new Set([
  VERTICAL_TYPE.COLLECTION,
  VERTICAL_TYPE.PLACE,
  VERTICAL_TYPE.EVENT,
]);

/**
 * @description values for the card selector vertical types
 */
const cardSelectorVerticalTypes = [
  { label: 'Places', value: 'PLACES' },
  { label: 'Events', value: 'EVENTS' },
  { label: 'Movies & Television', value: 'MOVIES' },
  { label: 'Recipes', value: 'RECIPES' },
  { label: 'Recreation ', value: 'ACTIVITIES' },
];

/**
 * @description combines all cards from an http response
 *
 * @param {Object} cardData - http response
 *
 * @returns {Object[]} of card objects
 */
const combineCollectionCards = (cardData) => {
  const { activities, cards, movies, places, recipes, pairings } = cardData;
  const returnedArray = [];

  if (activities && Array.isArray(activities))
    returnedArray.push(...activities);
  if (cards && Array.isArray(cards)) returnedArray.push(...cards);
  if (movies && Array.isArray(movies)) returnedArray.push(...movies);
  if (places && Array.isArray(places)) returnedArray.push(...places);
  if (recipes && Array.isArray(recipes)) returnedArray.push(...recipes);
  if (pairings && Array.isArray(pairings)) {
    pairings.forEach((pair, index) => {
      returnedArray.push(...combineCollectionCards(pair));
      // PLACE and EVENT pairings have a place reason, attach it to the pairings
      // data to be associated with the pairing card.
      if (returnedArray[index]) {
        const { pairing_reason } = pair;
        returnedArray[index].pairing_reason = pairing_reason;
      }
    });
  }

  return returnedArray;
};

/**
 * @description chooses which form data array based on
 * vertical type
 *
 * @param {String} verticalType
 *
 * @returns {Object[]} of form properties
 */
const chooseFormData = (verticalType) => {
  switch (verticalType) {
    case VERTICAL_TYPE.ACTIVITY:
      return ActivityData;
    case VERTICAL_TYPE.COLLECTION:
      return CollectionData;
    case VERTICAL_TYPE.EVENT:
      return EventData;
    case VERTICAL_TYPE.MOVIE:
      return MovieData;
    case VERTICAL_TYPE.PLACE:
      return PlaceData;
    case VERTICAL_TYPE.RECIPE:
      return RecipeData;
    default:
      return [];
  }
};

/**
 * @description finds and returns a card object in an array
 * of cards by ID
 *
 * @param {String} verticalType
 * @param {Object[]} cardArray
 *
 * @returns {Object}
 */
const findCardById = (cardId, cardArray) => {
  for (let i = 0; i < cardArray.length; i += 1) {
    if (cardId === cardArray[i].id) return cardArray[i];
  }
};

/**
 * @description returns the type of card by checking object properties
 *
 * @param {Object} cardObj
 *
 * @returns {Object}
 */
const getCardTypeByPrettyId = (cardObj = {}) => {
  const { pretty_id, is_event } = cardObj;

  // no pretty ID? likely a collection
  const type = pretty_id ? pretty_id.split('_')[0] : '';

  switch (type) {
    case VERTICAL_TYPE.ACTIVITY: {
      return VERTICAL_TYPE.ACTIVITY;
    }
    case 'card': {
      return VERTICAL_TYPE.CURATED;
    }
    case VERTICAL_TYPE.MOVIE: {
      return VERTICAL_TYPE.MOVIE;
    }
    case VERTICAL_TYPE.PLACE: {
      if (is_event) return VERTICAL_TYPE.EVENT;
      return VERTICAL_TYPE.PLACE;
    }
    case VERTICAL_TYPE.RECIPE: {
      return VERTICAL_TYPE.RECIPE;
    }
    default: {
      return 'multi-card';
    }
  }
};

/**
 * @description returns proper vertical type string
 * card object
 *
 * @param {Object} cardObj
 *
 * @returns {String}
 */
const getEndpointTypeByPrettyId = (cardObj) => {
  const { pretty_id, is_event } = cardObj;

  const type = pretty_id.split('_')[0];

  switch (type) {
    case VERTICAL_TYPE.ACTIVITY: {
      return 'recreation';
    }
    case 'card': {
      return VERTICAL_TYPE.CURATED;
    }
    case VERTICAL_TYPE.MOVIE: {
      return 'movies';
    }
    case VERTICAL_TYPE.PLACE: {
      if (is_event) return 'events';
      return 'places';
    }
    case VERTICAL_TYPE.RECIPE: {
      return 'recipes';
    }
    default:
  }
};

/**
 * @description returns proper vertical type string
 *
 * @param {String} unitType
 *
 * @returns {String}
 */
const getEndpointTypeByUnitType = (unitType) => {
  switch (unitType) {
    case VERTICAL_TYPE.ACTIVITY: {
      return 'recreation';
    }
    case 'card': {
      return VERTICAL_TYPE.CURATED;
    }
    case VERTICAL_TYPE.MOVIE: {
      return 'movies';
    }
    case VERTICAL_TYPE.PLACE: {
      return 'places';
    }
    case VERTICAL_TYPE.EVENT: {
      return 'events';
    }
    case VERTICAL_TYPE.RECIPE: {
      return 'recipes';
    }
    default:
  }
};

/**
 * @description processes common form data to be prepped for the update http
 * requests. Processes common properties then hits the vertical specific
 * methods based on verticalType string
 *
 * @param {Object} formData
 * @param {Object[]} uploadedImages - of image objects
 * @param {String} cardStatus
 * @param {String} verticalType
 * @param {Object} additionalOptions - additional properties that may be needed
 *
 * @returns {Object}
 */
const getPreparedFormData = (
  formData,
  uploadedImages,
  cardStatus,
  verticalType,
  additionalOptions = {}
) => {
  // common card properties across all verticals
  const {
    admin_review,
    description,
    itemized_description,
    main_categories,
    title,
    name,
  } = formData;

  const mapUniqueSlug = (catArray = []) =>
    catArray.filter((cat) => cat).map((cat) => cat.unique_slug);

  // Some verticals require the combination of category form
  // properties (ie: Place & Vibes) upon submission. This helper function
  // will do the trick
  // Also, some verticals have different properties for categories,
  // they also require the unique slugs and not the full object
  const handleCategories = () => {
    const { categories } = formData;
    const returnedArray = [];

    switch (verticalType) {
      case VERTICAL_TYPE.COLLECTION:
      case VERTICAL_TYPE.ACTIVITY: {
        returnedArray.push(...categories);
        break;
      }
      case VERTICAL_TYPE.PLACE:
      case VERTICAL_TYPE.EVENT: {
        const { vibes, misc_options, noteworthy_categories, perfect_for } =
          formData;
        returnedArray.push(
          // ...categories,
          ...main_categories,
          ...vibes,
          ...noteworthy_categories,
          ...perfect_for,
          ...misc_options
        );
        break;
      }
      case VERTICAL_TYPE.MOVIE: {
        const { rating } = formData;
        returnedArray.push(...categories, ...rating);
        break;
      }
      case VERTICAL_TYPE.RECIPE: {
        const { diet, meal_type } = formData;
        returnedArray.push(...categories, ...meal_type, ...diet);
        break;
      }
      default:
        break;
    }

    return {
      [verticalType === VERTICAL_TYPE.PLACE ||
      verticalType === VERTICAL_TYPE.EVENT
        ? 'set_categories'
        : 'categories']: mapUniqueSlug(returnedArray),
    };
  };

  return {
    admin_review,
    description: description ? description.trim() : '',
    status: cardStatus,
    ...(verticalType === VERTICAL_TYPE.EVENT ||
    verticalType === VERTICAL_TYPE.PLACE
      ? { custom_images: uploadedImages } // event and place have a diff prop
      : { images: uploadedImages }),
    ...handleCategories(),
    ...(main_categories
      ? { main_categories: mapUniqueSlug(main_categories) }
      : {}),
    // some verticals have a name property, some title
    ...(name ? { name } : { title }),
    ...(itemized_description
      ? {
          itemized_description: trimItemizedDescription(
            itemized_description,
            verticalType
          ),
        }
      : {}),
    // spread vertical specific data for submission
    ...chooseVerticalService(verticalType).prepareVerticalSpecificFormData(
      formData,
      {
        ...additionalOptions,
      }
    ),
  };
};

/**
 * @description some forms have a checkbox row that toggles
 * specific categories. This helper function facilitates that
 *
 * @param {Object} cardData
 * @param {String} verticalType
 *
 * @returns {Object}
 */
const handleCheckboxRow = (cardData = {}, verticalType) => {
  switch (verticalType) {
    case VERTICAL_TYPE.PLACE: {
      return { selectedOptions: cardData.misc_options };
    }
    case VERTICAL_TYPE.RECIPE: {
      return {
        selectedOptions: cardData.diet,
      };
    }
    default:
      return {};
  }
};

/**
 * @description used to open a new window to a specific card detail
 * An example being looking at a card detail on the cardCollection
 * form
 *
 * @param {Object} cardData
 *
 * @returns {void}
 */
const newWindowToCardDetail = (cardData) => {
  const { id, status } = cardData;
  const { origin } = window.location;

  const cardEndpointType = getEndpointTypeByPrettyId(cardData);

  window.open(`${origin}/${cardEndpointType}/${status}/${id}`, '_blank');
};

/**
 * @description processes common form values to be displayed to the edit forms.
 * Processes common values before hitting the vertical specific ones by
 * verticalType
 *
 * @param {Object} incomingCardObj
 * @param {Object} categoryMap - key of unique ID's value the category object
 * @param {String} verticalType
 *
 * @returns {Object}
 */
const processIncomingVerticalFormData = (incomingCardObj = {}, categoryMap = {}, verticalType) => {
  // deconstruct 'common-ish' properties
  const { categories, description, images, last_admin_review, unit_history } = incomingCardObj;

  return {
    // common properties among all
    categories: categories || [],
    description: description || '',
    images: images || [],
    last_admin_review,
    unit_history,
    // vertical specific
    ...chooseVerticalService(verticalType).processVerticalSpecificData(
      incomingCardObj,
      {
        // additional options
        categoryMap,
      }
    ),
  };
};

/**
 * @description validates common values on form submission before
 * calling teh vertical specific form validations
 *
 * @param {Object} formData
 * @param {String} submissionType - "draft" "publish"
 * @param {String} verticalType
 * @param {Object} additionalOptions - additional properties that may be needed
 *
 * @returns {Object}
 */
const validateForm = (
  formData,
  submissionType,
  verticalType,
  additionalOptions = {}
) => {
  const commonConstraints = {
    title: { label: 'Title', min: 1, max: 100 },
    name: { label: 'Name', min: 1, max: 100 },
    categories: { msg: 'At Least One Category is Required' },
    description: { label: 'Synopsis', min: 10, max: 2500 },
  };

  const { title, name, main_categories, categories } = formData;
  const errorsObj = {};

  /**
   * @description created reusable message for form errors
   *
   * @param {String} elem
   * @param {String} min
   * @param {String} max
   *
   * @returns {String}
   */
  const createMessage = (elem, min, max) =>
    `${elem} should be min ${min} and max ${max} characters`;

  // common/basic error checking for both DRAFTS and PUBLISHED

  // TITLE
  if (title !== undefined && (title.length < 1 || title.length > 100)) {
    const { label, min, max } = commonConstraints.title;

    errorsObj.title = createMessage(label, min, max);
  }

  // NAME
  if (name !== undefined && (name.length < 1 || name.length > 100)) {
    const { label, min, max } = commonConstraints.name;

    errorsObj.name = createMessage(label, min, max);
  }

  // MAIN CATEGORIES
  if (
    !newCardDesignVerticalSet.has(verticalType) &&
    main_categories !== undefined &&
    !commonService.validatePrimaryCategories(main_categories, categories)
  ) {
    errorsObj.categories =
      'Primary categories must be included in the selected categories';
  }

  // VERTICAL SPECIFIC DRAFT CHECKS
  switch (verticalType) {
    case VERTICAL_TYPE.EVENT: {
      const { event_amount, event_amount_max, price_tier } = formData;
      if (
        price_tier > 0 &&
        (event_amount === '' ||
          !isValidNumber(event_amount) ||
          (event_amount_max !== '' && !isValidNumber(event_amount_max)))
      ) {
        errorsObj.event_amount = 'Please enter a valid amount';
      }

      if (
        event_amount_max !== '' &&
        (event_amount === '' || !isValidNumber(event_amount))
      ) {
        errorsObj.event_amount = 'Please enter a valid amount';
      }

      if (
        event_amount_max !== '' &&
        isValidNumber(event_amount_max) &&
        event_amount !== '' &&
        isValidNumber(event_amount) &&
        parseFloat(event_amount_max) <= parseFloat(event_amount)
      ) {
        errorsObj.event_amount = 'Max amount must be greater than amount';
      }

      break;
    }
    case VERTICAL_TYPE.MOVIE: {
      const { instance_type, release_date } = formData;

      if (instance_type !== 'movie' && instance_type !== 'show') {
        errorsObj.instance_type =
          'You must choose a media type (Movie or TV Show)';
      }

      if (
        release_date !== undefined &&
        (release_date === 'Invalid date' ||
          !moment(release_date, 'YYYY-MM-DD').isValid())
      ) {
        errorsObj.release_date = 'A valid release year is required';
      }

      break;
    }

    default: {
      // ;)
    }
  }

  // PUBLISH ERROR CHECKING ONLY
  if (submissionType === 'published') {
    const { description, itemized_description, neighborhoods, phrase } =
      formData;

    const publishErrorsObj = {
      ...errorsObj,
      ...(!newCardDesignVerticalSet.has(verticalType) && itemized_description
        ? commonService.validateItemizedDescription(itemized_description)
        : {}),
    };

    // COMMON-ISH FORM VALIDATION ACROSS DIFF VERTICALS
    // *More specific in the services files per vertical
    // MAIN CATEGORIES
    if (
      !newCardDesignVerticalSet.has(verticalType) &&
      main_categories !== undefined &&
      isArrayEmpty(main_categories)
    ) {
      publishErrorsObj.categories = 'At Least One Main Category is Required';
    }

    if (
      newCardDesignVerticalSet.has(verticalType) &&
      main_categories !== undefined &&
      isArrayEmpty(main_categories)
    ) {
      publishErrorsObj.main_categories = 'One main category is required';
    }
    // NEIGHBORHOODS
    if (
      // activity no longer support neighborhood
      verticalType !== VERTICAL_TYPE.ACTIVITY &&
      verticalType !== VERTICAL_TYPE.EVENT &&
      neighborhoods &&
      Array.isArray(neighborhoods) &&
      !neighborhoods.length
    ) {
      publishErrorsObj.neighborhoods = 'You must select at least one location';
    }

    // no longer fields for updated card vertical forms
    if (!newCardDesignVerticalSet.has(verticalType)) {
      // CATEGORIES
      if (isArrayEmpty(categories)) {
        publishErrorsObj.categories = commonConstraints.categories.msg;
      }
      // PHRASE / ONE-LINER DESCRIPTION
      if (phrase !== undefined && (phrase.length < 10 || phrase.length > 250)) {
        publishErrorsObj.phrase =
          'One-liner description should be min 10 and max 250 characters';
      }
      // DESCRIPTION
      if (
        itemized_description === undefined &&
        description &&
        (description.length < 1 || description.length > 2500)
      ) {
        const { label, min, max } = commonConstraints.description;

        publishErrorsObj.description = createMessage(label, min, max);
      }
    }

    return {
      ...publishErrorsObj,
      // vertical specific props error check
      ...chooseVerticalService(verticalType).validate(
        formData,
        additionalOptions
      ),
    };
  }

  return {
    ...errorsObj,
  };
};

// ----------------------------------------------------------------------------|
//                                Exports
// ----------------------------------------------------------------------------|
export {
  alwaysFetchVerticalSet,
  cardSelectorVerticalTypes,
  chooseFormData,
  combineCollectionCards,
  findCardById,
  getCardTypeByPrettyId,
  getEndpointTypeByPrettyId,
  getEndpointTypeByUnitType,
  getPreparedFormData,
  handleCheckboxRow,
  newCardDesignVerticalSet,
  newWindowToCardDetail,
  processIncomingVerticalFormData,
  validateForm,
};
