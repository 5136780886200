/**
 * ************************************
 *
 * @module  Event.action.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Event redux actions
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import ACTIONS from '../actionTypes';

// ----------------------------------------------------------------------------|
//                                Exports
// ----------------------------------------------------------------------------|
export const populateAutofillImagesAction = (data) => ({
  type: ACTIONS.EVENT.POPULATE_IMAGES,
  data,
});

export const uploadImageAction = (data) => ({
  type: ACTIONS.EVENT.UPLOAD_IMAGE,
  data,
});

export const deleteImageAction = (data) => ({
  type: ACTIONS.EVENT.DELETE_IMAGE,
  data,
});

export const clearImagesAction = (data) => ({
  type: ACTIONS.EVENT.CLEAR_IMAGES,
  data,
});

export const saveDataToReduxAction = (data) => ({
  type: ACTIONS.EVENT.SAVE_DATA_TO_REDUX,
  data,
});
export const reOrderImagesAction = (data) => ({
  type: ACTIONS.EVENT.RE_ORDER_IMAGES,
  data,
});
export const saveDataAction = (data, id, successCB) => ({
  type: ACTIONS.EVENT.SAVE_DATA,
  data,
  id,
  successCB,
});
