/**
 * ************************************
 *
 * @module  FilterSection.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Filter section with includes SearchBar
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonSearchBar, SearchBar } from 'components';

import cx from 'classnames';

import filterIcon from 'assets/images/filter.svg';

import FiltersFactory, { FilterType } from './FiltersFactory';

import Switch from 'react-switch';

import { switchStyling } from 'containers/Categories/CategoriesUtils';

import './FilterSection.scss';

// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|
const shouldShowClearButton = (filters) =>
  filters.some(
    ({ value, parentValue, childValue }) =>
      (Array.isArray(value) && value.length > 0) ||
      (Array.isArray(parentValue) && parentValue.length > 0) ||
      (Array.isArray(childValue) && childValue.length > 0) ||
      (value && !!value.start && !!value.end) ||
      (typeof value === 'string' && value)
  );
// ----------------------------------------------------------------------------|
//                  React Function Component - FilterSection
// ----------------------------------------------------------------------------|
const FilterSection = ({
  searchValue,
  onSearchInput,
  clearFilters,
  hideExtras,
  filters: dropdownConfig,
  singleRow,
  buttonSearch,
  shouldDisplayImageInspectorSwitch,
  shouldDisplayImageInspector,
  imageInspectSwitchHandler,
}) => {
  /**
   * @description - boolean to show clear button
   */
  const showClearBtn = shouldShowClearButton(dropdownConfig);

  const filterSectionClass = cx([
    'filter-section',
    { 'single-row-filters': singleRow },
  ]);

  return (
    <div className={filterSectionClass}>
      <div className="search-bar-section">
        {buttonSearch ? (
          <ButtonSearchBar
            name="searchInput"
            value={searchValue}
            onSubmission={onSearchInput}
            placeholder="Search by keyword(s)"
          />
        ) : (
          <SearchBar
            name="searchInput"
            value={searchValue}
            onSubmit={onSearchInput}
            placeholder="Search"
          />
        )}
        {!hideExtras && showClearBtn && !singleRow && (
          <Button
            className="clear-filter-btn"
            onClick={clearFilters}
            preventDefault
          >
            Reset Filters
          </Button>
        )}
      </div>
      {!hideExtras && (
        <div className="filter-bar">
          {dropdownConfig.length !== 0 && !singleRow ? (
            <img
              src={filterIcon}
              alt="Filters"
              className="filter-icon"
              height="36"
            />
          ) : null}
          {dropdownConfig.map(FiltersFactory.createFilter)}
          {!hideExtras && showClearBtn && singleRow && (
            <Button
              className="clear-filter-btn"
              onClick={clearFilters}
              preventDefault
            >
              Reset Filters
            </Button>
          )}
          {shouldDisplayImageInspectorSwitch && (
            <div className="vertical-mode-switch">
              <div className="switch-container">
                <Switch
                  onChange={(value) => imageInspectSwitchHandler(value)}
                  checked={shouldDisplayImageInspector}
                  {...switchStyling()}
                />
                <label>Image Inspector</label>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                    PropTypes Check - FilterSection
// ----------------------------------------------------------------------------|
FilterSection.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      options: PropTypes.array,
      name: PropTypes.string.isRequired,
      returnType: PropTypes.string.isRequired,
      filterType: PropTypes.oneOf([
        FilterType.SELECT,
        FilterType.MULTI_SELECT,
        FilterType.DATE_RANGE_PICKER,
      ]),
      // value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
      onFilterSelect: PropTypes.func.isRequired,
    })
  ).isRequired,
  searchValue: PropTypes.string,
  onSearchInput: PropTypes.func,
  clearFilters: PropTypes.func,
  hideExtras: PropTypes.bool,
  singleRow: PropTypes.bool,
  buttonSearch: PropTypes.bool,
  shouldDisplayImageInspectorSwitch: PropTypes.bool.isRequired,
  imageInspectSwitchHandler: PropTypes.func,
  shouldDisplayImageInspector: PropTypes.bool,
};

// ----------------------------------------------------------------------------|
//                        Default Props - FilterSection
// ----------------------------------------------------------------------------|
FilterSection.defaultProps = {
  hideExtras: false,
  searchValue: null,
  onSearchInput: null,
  clearFilters: null,
  singleRow: false,
  buttonSearch: false,
  shouldDisplayImageInspectorSwitch: true,
  imageInspectSwitchHandler: () => {},
  shouldDisplayImageInspector: false,
};

// ----------------------------------------------------------------------------|
//                            FilterSection Export
// ----------------------------------------------------------------------------|
export default FilterSection;
