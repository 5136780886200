/**
 * ************************************
 *
 * @module  NativeClickListener.js
 * @author  Matt P
 * @date    02/09/2021
 * @description Reusable component for clicking outside a dropdown
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                  Imports
// ---------------------------------------------------------------------------|
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// -------------------------------------------------------------------------|
//                       React Function Component
// -------------------------------------------------------------------------|
const NativeClickListener = ({ children, onClick }) => {
  const nativeClickRef = useRef(null);

  /**
   * @description - puts the callback on a click listener to fire when a
   * user clicks somewhere outside the wrapped component
   *
   * @param {Object} nativeEvent
   *
   * @returns {void}
   */
  const globalClickHandler = (nativeEvent) => {
    if (
      nativeClickRef.current &&
      nativeClickRef.current.contains(nativeEvent.target)
    )
      return;
    onClick(nativeEvent);
  };

  useEffect(() => {
    document.addEventListener('click', globalClickHandler);

    return () => {
      document.removeEventListener('click', globalClickHandler);
    };
  }, []);

  return (
    <div className="native-click-listener" ref={nativeClickRef}>
      {children}
    </div>
  );
};

// -------------------------------------------------------------------------|
//                            PropTypes Check
// -------------------------------------------------------------------------|
NativeClickListener.propsType = {
  onClick: PropTypes.func.isRequired,
};

// ---------------------------------------------------------------------------|
//                                Export
// ---------------------------------------------------------------------------|
export default NativeClickListener;
