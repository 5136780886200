/**
 * ************************************
 *
 * @module  AuthService.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description helper functions for authorization
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                 Imports
// ---------------------------------------------------------------------------|
import { adminSession } from 'constants.js';
import { setCookie, getCookie, deleteCookie } from 'utils/CookieFunc';

// ---------------------------------------------------------------------------|
//                                Utilities
// ---------------------------------------------------------------------------|
/**
 * @description sets a cookie leveraging the setCookie util function
 * adds cookie to the document object
 *
 * @param {Object} data - Session data
 * @param {String} cookieName - default: 'adminSession'
 *
 * @returns {void}
 */
const setSession = (data, cookieName = adminSession) => {
  setCookie(cookieName, JSON.stringify(data), 7); // store token for 7 days
};

/**
 * @description retrieves a session cookie leveraging the getCookie util
 * function returns session data object
 *
 * @param {String} cookieName - default: 'adminSession'
 *
 * @return {Object} of session data
 */
const getSession = (cookieName = adminSession) => {
  let sessionData;

  try {
    sessionData = JSON.parse(getCookie(cookieName)) || {};
  } catch (e) {
    sessionData = {};
    setSession(sessionData); // set it empty
  }

  return sessionData;
};

/**
 * @description deletes a session cookie by leveraging the deleteCookie
 * util function by setting the expiration date to 1970
 *
 * @param {String} cookieName - default: 'adminSession'
 *
 * @returns {void}
 */
const deleteSession = (cookieName = adminSession) => {
  deleteCookie(cookieName);
};

/**
 * @description forces redirect to login
 *
 * @returns {void}
 */
const redirectToLogin = () => {
  const urlParts = window.location.pathname.split('/');

  window.location.assign(`/${urlParts[1]}`);
};

/**
 * @description checks status of user login
 *
 * @param {String} cookieName - default: 'adminSession'
 *
 * @returns {Boolean}
 */
const isUserLoggedIn = (cookieName = adminSession) => {
  const { token } = getSession(cookieName);

  if (token) return true;

  return false;
};

/**
 * @description logout for user then redirects to login
 *
 * @param {String} cookieName - default: 'adminSession'
 *
 * @returns {void}
 */
const logout = (cookieName = adminSession) => {
  deleteSession(cookieName);

  redirectToLogin();
};

// ---------------------------------------------------------------------------|
//                                  Exports
// ---------------------------------------------------------------------------|
export {
  setSession,
  getSession,
  deleteSession,
  isUserLoggedIn,
  logout,
  redirectToLogin,
};
