/**
 * ************************************
 *
 * @module  CookieFunc.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description functions related to session cookies
 *
 * ************************************
 */

/**
 * @description sets a cookie client side
 *
 * @param {String} name - cookieName
 * @param {String} value - stringified data
 * @param {Number} days - number of days until cookie expiration
 */
const setCookie = (name, value, days) => {
  let expires = '';

  if (days) {
    const date = new Date();

    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

/**
 * @description gets a cookie if one is present with
 * the given name by looping through document.cookie
 *
 * @param {String} name - cookieName
 *
 * @return {String || null}
 */
const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];

    while (c.charAt(0) === ' ') c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
};

/**
 * @description deletes a cookie by setting an old expiration date
 *
 * @param {String} name - cookieName
 */
const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`;
};

export { setCookie, getCookie, deleteCookie };
