/**
 * ************************************
 *
 * @module  Checkbox.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Checkbox specific for EditHours
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import '../Checkbox/Checkbox.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const Checkbox = ({ toBeChecked, onClick, label, children, className }) => {
  // Round is default classname, pass custom class names to overwrite the styles
  const CheckboxClass = cx(['round', `${className}`]);

  /**
   * @description - handles button click interaction
   *
   * @param {Object} event - DOM event object
   *
   * @returns {void}
   */
  const onClickHandler = (e) => {
    const { checked } = e.target;
    onClick(checked, label, e);
  };

  return (
    <div className="cob-checkbox">
      <div className={CheckboxClass}>
        <input
          id={label}
          data-name="checkbox"
          type="checkbox"
          onChange={onClickHandler}
          checked={toBeChecked}
        />
        <label htmlFor={label} data-name="checkbox">
          <span className="label-text">{children}</span>
        </label>
      </div>
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
Checkbox.propTypes = {
  onClick: PropTypes.func.isRequired,
  toBeChecked: PropTypes.any,
  label: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
Checkbox.defaultProps = {
  toBeChecked: null,
  label: '',
  children: null,
  className: null,
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default Checkbox;
