import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button, PreviewCard } from 'components';

import mapPlaceHolder from 'assets/images/map-placeholder.png';
import mobileIcon from 'assets/images/call.png';

import { formatISODate, formatTimeString, sanitizeUrl } from 'utils/utils';
import { GENERIC, DATE_FORMATS, TIME_FORMATS } from 'constants.js';

import './PreviewModal.scss';

const getListFromArray = (list) => {
  if (Array.isArray(list)) {
    return list.map((el, index) => {
      const value = typeof el === 'object' ? el.name : el;
      return <div key={index}>{value}</div>;
    });
  }
  return [];
};

const getCategoryItem = (category) => {
  if (Array.isArray(category) && category.length !== 0) {
    return typeof category[0] === 'object' ? category[0].name : category[0];
  }
  return ' ';
};

const generateDesc = (desc, showMore, setShowMore) => {
  const { VALID_READ_MORE_LENGTH } = GENERIC;
  const spanLabel = showMore ? 'Read More' : 'Close';
  if (showMore) {
    return (
      <Fragment>
        {desc.substring(0, VALID_READ_MORE_LENGTH)}
        <span className="read-more-btn" role="button" onClick={() => setShowMore(false)}>
          {spanLabel}
        </span>
      </Fragment>
    );
  }
  return (
    <Fragment>
      {desc}
      <span className="read-more-btn" role="button" onClick={() => setShowMore(true)}>
        {spanLabel}
      </span>
    </Fragment>
  );
};

const PreviewModal = (props) => {
  const { data, className, fromEvent, fromPlace } = props;

  const { VALID_READ_MORE_LENGTH } = GENERIC;

  const {
    previewImage,
    title,
    category,
    neighborhood,
    price,
    desc,
    address,
    previewCardList,
    startDate,
    fromTime,
    eventAmount,
    eventAmountSuffix,
    phone,
  } = data;

  const containerClass = cx(['preview-modal', `${className}`]);
  const [showMore, setShowMore] = useState(true);

  const description =
    desc && desc.length > VALID_READ_MORE_LENGTH ? generateDesc(desc, showMore, setShowMore) : desc;

  const dummyFnCall = () => {};

  const categoryItem = getCategoryItem(category);
  const neighborhoodList = getListFromArray(neighborhood);

  const buttonList = [
    { id: 1, label: 'MAYBE LATER', className: 'round white-bg', disabled: true },
    { id: 2, label: 'ADD TO LIST', className: 'round', disabled: true },
  ];

  const dots = [];
  for (let i = 0; i < 4; i += 1) {
    dots.push(<span className="dot" key={i} />);
  }
  const numberCheck = (amt) => {
    if (!isNaN(amt)) {
      return Number(amt).toFixed(2);
    }
    return amt;
  };

  return (
    <div className={containerClass}>
      <div className="image-carousel">
        <img src={sanitizeUrl(previewImage)} alt="preview" />
        <div className="dots">{dots.map((dot) => dot)}</div>
      </div>
      <div className="modal-detail">
        <h1 className="title">{title}</h1>
        <div className="details">
          {fromEvent ? (
            <Fragment>
              <div className="split-screen">
                <span className="category">{categoryItem}</span>
                <span>
                  {startDate && formatISODate(startDate, DATE_FORMATS.stringWithoutYear)},
                  {fromTime && formatTimeString(fromTime, TIME_FORMATS.time)}
                </span>
              </div>
              <div className="split-screen">
                <span className="neighborhood">{neighborhoodList}</span>
                <span className="amount">
                  ${numberCheck(eventAmount)}/{eventAmountSuffix}
                </span>
              </div>
              {phone && (
                <div className="contact">
                  <img src={mobileIcon} alt="contact" />
                  <span className="phone">{phone}</span>
                </div>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <h4 className="category">{categoryItem}</h4>
              <div className="pricing">
                <span className="neighborhood">{neighborhoodList}</span>
                <span className="separator">.</span>
                <span className="price">{price}</span>
              </div>
            </Fragment>
          )}
        </div>
        {(fromEvent || fromPlace) && <div className="address event">{address}</div>}
        <p className="desc">{description}</p>
        {fromEvent && <div className="website">Visit Website</div>}
        <div className="btn-container">
          {buttonList.map((btn, index) => (
            <Button
              className={btn.className}
              onClick={dummyFnCall}
              key={index}
              disabled={btn.disabled}
            >
              {btn.label}
            </Button>
          ))}
        </div>
      </div>
      <img src={mapPlaceHolder} alt="map" className="map-placeholder" />
      {Array.isArray(previewCardList) && previewCardList.length !== 0 ? (
        <Fragment>
          {previewCardList.map((card, index) => (
            <PreviewCard key={index} data={card} />
          ))}
        </Fragment>
      ) : (
        <p className="address">{address}</p>
      )}
    </div>
  );
};

PreviewModal.propTypes = {
  data: PropTypes.shape({
    previewImage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.array.isRequired,
    neighborhood: PropTypes.array.isRequired,
    price: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    address: PropTypes.string,
    previewCardList: PropTypes.array,
    startDate: PropTypes.string,
    fromTime: PropTypes.string,
    eventAmount: PropTypes.number,
    eventAmountSuffix: PropTypes.string,
    phone: PropTypes.string,
  }),
  className: PropTypes.string,
  fromEvent: PropTypes.bool,
  fromPlace: PropTypes.bool,
};

PreviewModal.defaultProps = {
  data: {
    address: '',
    previewCardList: [],
    startDate: '',
    fromTime: '',
    eventAmount: 0,
    eventAmountSuffix: '',
    phone: '',
  },
  className: '',
  fromEvent: false,
  fromPlace: false,
};

export default PreviewModal;
