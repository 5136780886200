/**
 * ************************************
 *
 * @module  Recipe.action.js
 * @author  Matt P
 * @date    01/19/2021
 * @description redux action for Recipe component
 *
 * ************************************
 */

// ----------------------------------------------------------------------------|
//                             Import ActionTypes
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                                Exports
// ----------------------------------------------------------------------------|

// ------------------------- Fetch Data Actions -------------------------------|
export const fetchRecipeAction = (data) => ({
  type: ACTIONS.RECIPE.FETCH_RECIPE,
  data,
});

// ------------------------- Data Save Actions --------------------------------|
export const saveData = (data, id, successCB) => ({
  type: ACTIONS.RECIPE.SAVE_DATA,
  data,
  id,
  successCB,
});

export const saveDataToRedux = (data) => ({
  type: ACTIONS.RECIPE.SAVE_DATA_TO_REDUX,
  data,
});

// ----------------------- Image Actions --------------------------------------|
export const clearImagesAction = (data) => ({
  type: ACTIONS.RECIPE.CLEAR_IMAGES,
  data,
});

export const deleteImageAction = (data) => ({
  type: ACTIONS.RECIPE.DELETE_IMAGE,
  data,
});

export const populateAutofillImagesAction = (data) => ({
  type: ACTIONS.RECIPE.POPULATE_IMAGES,
  data,
});

export const reOrderImagesAction = (data) => ({
  type: ACTIONS.RECIPE.RE_ORDER_IMAGES,
  data,
});

export const uploadImageAction = (data) => ({
  type: ACTIONS.RECIPE.UPLOAD_IMAGE,
  data,
});
