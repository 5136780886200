/**
 * ************************************
 *
 * @module  MultiInput.js
 * @author  Matt P
 * @date    01/03/2021
 * @description Component for rendering multiple inputs. Additional
 * inputs are rendered via clicking on the +Add More
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import deleteIcon from 'assets/images/icn_delete.png';
import './MultiInput.scss';

// ----------------------------------------------------------------------------|
//                    React Function Component - MultiInput
// ----------------------------------------------------------------------------|
const MultiInput = forwardRef((props, ref) => {
  const {
    type,
    value,
    name,
    onChange,
    checkboxComponent,
    disabled,
    buttonTitle,
    className = '',
    ...rest
  } = props;
  const [multiInputState, setMultiInputState] = useState(
    !value.length ? [''] : value
  );

  const classNames = cx([
    'input-bar',
    'multi-input',
    `${className.split(' ').join(',')}`,
  ]);

  /**
   * @description Fires when any of the input fields change
   * updates state and fires onChange prop function
   *
   * @argument {Object} e - event object
   */
  const onChangeHandler = (e) => {
    const newMultiInputStateValues = [...multiInputState];
    const inputIndex = e.target.name.split('-');

    newMultiInputStateValues[inputIndex[inputIndex.length - 1]] =
      e.target.value;

    // Sets new state via hooks
    setMultiInputState(newMultiInputStateValues);
    // fires the onChange function passed to comp
    onChange(newMultiInputStateValues, name);
  };

  /**
   * @description Adds input field on button click
   */
  const addInput = () => {
    const newMultiInputState = [...multiInputState];

    newMultiInputState.push('');

    setMultiInputState(newMultiInputState);
  };

  /**
   * @description Deletes an input row then updates state and fires
   * the onChange prop function
   *
   * @argument {Object} e - event object
   */
  const deleteLinks = (e) => {
    const newMultiInputStateValues = [...multiInputState];
    const inputIndex = e.target.name.split('-');

    newMultiInputStateValues.splice(inputIndex[inputIndex.length - 1], 1);

    // Sets new state via hooks
    setMultiInputState(newMultiInputStateValues);
    // fires the onChange function passed to comp
    onChange(newMultiInputStateValues, name);
  };

  /**
   * @description Renders input fields based on array length
   *
   * @returns {JSX} HTML components to be rendered to the DOM
   */
  const buildInputs = () =>
    multiInputState.map((field, index) => (
      <div className="multi-input__input-wrapper" key={`multi-input-${index}`}>
        <input
          ref={ref}
          className={classNames}
          type={type}
          value={field}
          name={`${name}-${index}`}
          placeholder={field}
          onChange={onChangeHandler}
          disabled={disabled}
          {...rest}
        />
        {index !== 0 && (
          <div className="multi-input__input-delete">
            <img
              src={deleteIcon}
              alt="remove icon"
              onClick={(e) => {
                if (!disabled) deleteLinks(e);
              }}
              data-name="delete"
              name={`${name}-delete-${index}`}
            />
          </div>
        )}
        {index === 0 && checkboxComponent && (
          <div className="multi-input__input-checkbox-component">
            {checkboxComponent}
          </div>
        )}
      </div>
    ));

  return (
    <div className="multi-input-wrapper">
      {buildInputs()}
      <button
        className="multi-input__add-link"
        type="button"
        onClick={() => {
          addInput();
        }}
      >
        {disabled ||
          (multiInputState.length >= 4 && name !== 'ingredients') ||
          `+ Add ${buttonTitle}`}
      </button>
    </div>
  );
});

// ----------------------------------------------------------------------------|
//                         PropTypes Check - MultiInput
// ----------------------------------------------------------------------------|
MultiInput.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.array,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  checkboxComponent: PropTypes.elementType,
  disabled: PropTypes.bool,
  buttonTitle: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                          Default Props - MultiInput
// ----------------------------------------------------------------------------|
MultiInput.defaultProps = {
  value: [],
  className: '',
  checkboxComponent: false,
  disabled: false,
  buttonTitle: 'Links',
};

// ----------------------------------------------------------------------------|
//                            Export - MultiInput
// ----------------------------------------------------------------------------|
export default React.memo(MultiInput);
