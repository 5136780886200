/**
 * ************************************
 *
 * @module  index.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description index.js file for src directory. Renders application while
 * wrapping components in redux store <Provider> HOC. Also attaches Axios
 * functionality to window object
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                    Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';

import App from 'App';

import { Toast } from 'components';

import AxiosWrapper from 'utils/AxiosWrapper';

import { adminSession } from 'constants.js';

import store from 'store';

// ----------------------------------------------------------------------------|
//                          Wrapper for Redux Store
// ----------------------------------------------------------------------------|
const AppTemplate = (
  <Provider store={store}>
    <Toast />
    <App />
  </Provider>
);

// ----------------------------------------------------------------------------|
//                           ReactDOM Rendering
// ----------------------------------------------------------------------------|
ReactDOM.render(AppTemplate, document.getElementById('root'));

// ----------------------------------------------------------------------------|
//                    Axios Constructor - Async Requests
// ----------------------------------------------------------------------------|
window.axios = new AxiosWrapper(adminSession);
window.loadedExternalResources = [];
