/**
 * ************************************
 *
 * @module VerticalDisplay.js
 * @author  Matt P
 * @date    07/02/2021
 * @description VerticalDisplay container that renders content based on
 * verticalType prop (place/event/movie/etc..) passed
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { PureComponent, Fragment } from 'react';

import PropTypes from 'prop-types';
import {
  categoryPropsDataType,
  historyDataType,
  locationDataType,
  neighborhoodDataType,
} from 'utils/typeUtils';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import {
  getCategoriesListAction,
  clearListAction as clearCatagoriesListAction,
} from 'store/actions/Categories.actions';
import {
  clearCardsAction,
  deleteCardAction,
  fetchNeighborhoodLocationsAction,
  filterVerticalListAction,
  getVerticalDisplayCardsAction,
  updateSingleCardPropertyAction,
} from 'store/actions/VerticalDisplay.action';
import { saveDataAction } from 'store/actions/VerticalForm.action';

import {
  clearListAction as clearCitiesListAction,
  getCitiesListAction,
} from 'store/actions/Cities.action';

import { VerticalForm } from 'containers';

import { FILTER_DROP_DOWN, GENERIC, VERTICAL_TYPE } from 'constants.js';

import {
  CardBanner,
  CardViewModal,
  DeleteModal,
  FilterSection,
  Loader,
  NoDataFound,
  Tabs,
  ThirdPartySearchModal,
  TotalCount,
} from 'components';

import {
  getPartsFromUrl,
  getCardReviewStatus,
  getCardPrettyIds,
  checkHasMore,
  createDropdownOptions,
  askForCloseConfirmation,
  getPriceRange as formPriceOptions,
  deepCopy,
} from 'utils/utils';

import {
  flaggedReasonOptions,
  formRatingOptions,
  FilterType,
  formGroupOptions,
  orderByOptions,
} from 'components/FilterSection/FiltersFactory';

import CommonService from 'services/CommonService';

import {
  chooseConstant,
  chooseRequestObject,
  chooseVerticalHeader,
} from './VerticalDisplayUtils';

import {
  alwaysFetchVerticalSet,
  getEndpointTypeByPrettyId,
  getPreparedFormData,
} from './VerticalForm/VerticalFormUtils';

import VerticalCardList from './VerticalCardList/VerticalCardList';

import './VerticalDisplay.scss';

// ----------------------------------------------------------------------------|
//                            Property Mapping
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  clearCardsList: () => dispatch(clearCardsAction()),
  deleteCard: (data, verticalType) =>
    dispatch(deleteCardAction(data, verticalType)),
  fetchNeighborhoodLocations: () =>
    dispatch(fetchNeighborhoodLocationsAction()),
  getCitiesList: () => dispatch(getCitiesListAction()),
  clearCitiesList: () => dispatch(clearCitiesListAction()),
  filterCardsList: (data, verticalType) =>
    dispatch(filterVerticalListAction(data, verticalType)),
  getCardsList: (data, verticalType) =>
    dispatch(getVerticalDisplayCardsAction(data, verticalType)),
  getCategoriesList: (data) => dispatch(getCategoriesListAction(data)),
  clearCategoriesList: () => dispatch(clearCatagoriesListAction()),
  saveData: (data, id, successCB, verticalType) =>
    dispatch(saveDataAction(data, id, successCB, verticalType)),
  updateSingleCardProperty: (data, id, verticalType) =>
    dispatch(updateSingleCardPropertyAction(data, id, verticalType)),
});

const mapStateToProps = (state) => ({
  categories: state.categories,
  cities: state.cities,
  neighborhoods: state.verticalDisplay.neighborhoodLocations,
  verticalDisplay: state.verticalDisplay,
  shouldDisplayImageInspector: state.shouldDisplayImageInspector,
});

// ----------------------------------------------------------------------------|
//                              Utilities
// ----------------------------------------------------------------------------|
const commonService = CommonService();

// ----------------------------------------------------------------------------|
//                 React Class Component - VerticalDisplay
// ----------------------------------------------------------------------------|
class VerticalDisplay extends PureComponent {
  // --------------------------------------------------------------------------|
  //                          PropTypes Check
  // --------------------------------------------------------------------------|
  static propTypes = {
    verticalType: PropTypes.string.isRequired,
    getCardsList: PropTypes.func.isRequired,
    deleteCard: PropTypes.func.isRequired,
    clearCardsList: PropTypes.func.isRequired,
    getCategoriesList: PropTypes.func.isRequired,
    fetchNeighborhoodLocations: PropTypes.func.isRequired,
    updateSingleCardProperty: PropTypes.func.isRequired,
    saveData: PropTypes.func.isRequired,
    verticalDisplay: PropTypes.shape({
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        })
      ).isRequired,
      totalCount: PropTypes.number,
      isInitialDataLoaded: PropTypes.bool.isRequired,
      pageNum: PropTypes.number.isRequired,
      neighborhoodLocations: PropTypes.arrayOf(neighborhoodDataType).isRequired,
      neighborhoodsLoaded: PropTypes.bool.isRequired,
      fetchMore: PropTypes.bool.isRequired,
      tmdbPage: PropTypes.number.isRequired,
    }).isRequired,
    filterCardsList: PropTypes.func.isRequired,
    location: locationDataType.isRequired,
    history: historyDataType.isRequired,
    categories: categoryPropsDataType.isRequired,
    cities: PropTypes.shape({
      initialDataLoaded: PropTypes.bool,
      cities: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    getCitiesList: PropTypes.func.isRequired,
    shouldDisplayImageInspector: PropTypes.bool,
  };

  // --------------------------------------------------------------------------|
  //                                Default Props
  // --------------------------------------------------------------------------|
  static defaultProps = {};

  // --------------------------------------------------------------------------|
  //                              State - Places
  // --------------------------------------------------------------------------|
  constructor(props) {
    super();

    const { verticalType } = props;

    this.state = {
      // common state properties
      currentTab: null,
      deleteModalData: null,
      // do not have uniSearch auto fetch on mount, set hasMore to false
      hasMore: verticalType !== VERTICAL_TYPE.UNIVERSAL_SEARCH,
      newCardData: null,
      openCardID: '',
      fetchingData: false,
      fetchingNeighborhoods: false,
      fetchingCities: false,
      fetchingCategories: false,
      // flagged specific
      flaggedReports: null,
      // filters
      category: [],
      dateRange: undefined,
      flaggedReason: '',
      city: [],
      neighborhood: [],
      orderBy: '',
      price: [],
      query: '',
      rating: [],
      reviewStatus: '',
      // specific verticals that use 3rd party fetches (Place / Movies)
      newSearch: false,
      showThirdPartyModal: false,
      thirdPartyEdit: null,
      shouldDisplayImageInspector: false,
    };
  }

  // --------------------------------------------------------------------------|
  //                            LifeCycle Methods
  // --------------------------------------------------------------------------|
  componentDidMount() {
    const { history, verticalType } = this.props;

    // Universal Search and Flagged do not have tabs, nor should it
    // fetch data upon mount
    if (
      verticalType !== VERTICAL_TYPE.UNIVERSAL_SEARCH ||
      verticalType !== VERTICAL_TYPE.FLAGGED
    ) {
      this.setCurrentTab();
    }

    this.checkAndGrabData();

    this.unlisten = history.listen(() => {
      this.handleIDPathname();
    });
  }

  componentDidUpdate(prevProps) {
    const { location, verticalDisplay, verticalType } = this.props;
    const { flaggedReports } = this.state;

    this.checkAndGrabData();

    if (location.pathname !== prevProps.location.pathname) {
      this.setCurrentTab();

      // if flagged report with data set in state, reset it on modal close.
      if (
        flaggedReports !== null &&
        !prevProps.location.pathname.includes('flagged')
      ) {
        this.setState({ flaggedReports: null });
      }

      if (
        location.pathname.includes('fetched') ||
        prevProps.location.pathname.includes('fetched')
      ) {
        this.resetCategoriesFilter();
      }
    }

    if (verticalType !== prevProps.verticalType) {
      this.clearFilters(false);
    }

    if (
      verticalDisplay.cards.length !== prevProps.verticalDisplay.cards.length
    ) {
      const { fetchingData } = this.state;
      this.setHasMore();

      // will flip the fetching data flag so the loading card no longer
      // conditionally renders
      if (fetchingData) {
        this.setState({
          fetchingData: false,
        });
      }
    }

    if (prevProps.verticalDisplay.fetchMore && !verticalDisplay.fetchMore) {
      // flips for the loading card when we no longer have data to
      // fetch
      this.setState({
        fetchingData: false,
      });
    }

    if (verticalDisplay.isInitialDataLoaded) {
      this.handleIDPathname();
    }
  }

  componentWillUnmount() {
    const { clearCardsList, clearCategoriesList, clearCitiesList } = this.props;

    this.unlisten();
    clearCategoriesList();
    clearCitiesList();
    clearCardsList();
  }

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  VerticalDisplay methods
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  /**
   * @description Handle presence of DB id in URL: redirect user to relevant
   * content
   */
  handleIDPathname = () => {
    const { location, verticalDisplay, verticalType } = this.props;
    const { openCardID } = this.state;
    const { id } = commonService.pathnameHelper(location.pathname);

    if (id) {
      if (openCardID !== id) {
        if (openCardID) {
          this.setState({ newCardData: null, openCardID: '' });
        } else {
          this.setState({ openCardID: id });

          const { cards } = verticalDisplay;
          let cardData;

          // searches for already fetched cards
          for (let i = 0; i < cards.length; i += 1) {
            if (cards[i].id === id) {
              cardData = cards[i];
              break;
            }
          }

          // if it finds it, load it

          // also... collections and places will always be fetched
          // even if they are populated on the main vertical
          // initial fetch due to speed issues with sending all
          // card data for pairings/linked cards
          if (cardData && !alwaysFetchVerticalSet.has(verticalType)) {
            this.setState({ newCardData: cardData });
          } else {
            // if not, open a new modal and have it fetch the id
            this.setState({
              newCardData: {
                id,
                shouldFetchCard: true,
                shouldDisplayLoader: true,
              },
            });
          }
        }
      }
    } else if (openCardID) {
      this.setNewCardData(null);
    }
  };

  /**
   * @description sets state on if more cards are available
   * checkHasMore is imported from the common utils/utils file
   */
  setHasMore = () => {
    const { verticalDisplay, verticalType } = this.props;
    const { cards, totalCount, fetchMore, tmdbPage } = verticalDisplay;

    let hasMore;

    // for TMDB movie pagination for the 'fetched' tab
    if (verticalType === 'movie' && tmdbPage > -1) {
      // fetch more updates in the redux reducer file
      // checks if last fetch returned zero results
      hasMore = fetchMore;
    } else {
      hasMore = checkHasMore(cards.length, totalCount);
    }

    this.setState({ hasMore });
  };

  /**
   * @description constructs a request object which is sent to redux saga
   * and ultimately requests a response based on state
   *
   * @returns {Object} - request object
   */
  constructRequestObject = () => {
    const { verticalDisplay, verticalType } = this.props;
    const {
      category,
      city,
      currentTab,
      dateRange,
      flaggedReason,
      neighborhood,
      query,
      newSearch,
      orderBy,
      price,
      rating,
      reviewStatus,
      tmdbPage,
    } = this.state;
    const { pageNum: page, tmdbPage: tmdb_page } = verticalDisplay;

    const requestObject = chooseRequestObject(
      {
        cat_unique_slug_list: category.map(({ value }) => value),
        query,
        loaded_ids:
          verticalType === 'collection'
            ? verticalDisplay.cards.map((card) => card.id)
            : getCardPrettyIds(verticalDisplay.cards),
        required_neighborhoods_groups: [
          [...neighborhood.map(({ value }) => value)],
          [...city.map(({ value: { unique_slug } }) => unique_slug)],
        ],
        order_by_parameter: orderBy || undefined,
        report_types: flaggedReason,
        page,
        rating: rating.map(({ value: rate }) =>
          rate === '1' ? rate : rate * 2
        ),
        review_status: reviewStatus || undefined,
        // backend is looking for 'draft' while we name it drafts
        status: currentTab === 'drafts' ? 'draft' : currentTab,
        dateRange,
        neighborhood,
        price,
        // new search resets tmdb_page = -1
        tmdb_page: newSearch ? -1 : tmdb_page,
      },
      verticalType
    );

    if (verticalType === 'movie' && tmdbPage > 0 && currentTab === 'fetched') {
      requestObject.tmdb_page = tmdbPage;
    }

    // resets new search flag so we can reset tmdb_page to -1
    if (newSearch) {
      this.setState({
        newSearch: false,
      });
    }

    return requestObject;
  };

  /**
   * @description constructs a request object and invokes getCardsList
   * (passed from redux) which is sent to redux saga and ultimately
   * requests a response based on state
   */
  getVerticalData = () => {
    const { getCardsList, verticalType } = this.props;
    const request = this.constructRequestObject();

    this.setState(
      {
        fetchingData: true,
      },
      () => getCardsList(request, verticalType)
    );
  };

  /**
   * @description sets the current horizontal tab by invoking the
   * getPartsFromUrl function imported from utils/utils based on
   * the second part of the current route.
   */
  setCurrentTab = () => {
    const { location, clearCardsList } = this.props;

    // Active tab in horizontal nav is set based on the second
    // part of the current route
    const { secondPart: currentTab } = getPartsFromUrl(location.pathname);

    clearCardsList();

    this.setState(
      {
        currentTab,
        newCardData: null,
        openCardID: '',
        query: '',
      },
      this.getVerticalData
    );
  };

  /**
   * @description requests more data after setting states hasMore prop to false.
   */
  getMoreData = () => {
    this.setState({ hasMore: false }, this.getVerticalData);
  };

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  //                  Filter Methods
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  /**
   * @description Fires when filter options are selected
   *
   * @param {String} returnType - string for data type
   * @param {Array} data - of object  data to set to state
   * @param {Function} callback - to fire after state update
   */
  onFilterSelect = (returnType, data, callback = () => {}) => {
    const { clearCardsList } = this.props;
    let selectedData = data;
    // prevents a bug with null being passed when deleting filters
    if (
      (returnType === 'category' ||
        returnType === 'neighborhood' ||
        returnType === 'city' ||
        returnType === 'price' ||
        returnType === 'rating') &&
      selectedData === null
    )
      selectedData = [];

    // Note: dateRange updates differently

    if (returnType === 'orderBy' && data) {
      selectedData = data.value;
    }

    if (returnType === 'reviewStatus' || returnType === 'flaggedReason') {
      if (data.value) selectedData = data.value;
      else selectedData = '';
    }

    clearCardsList();

    // API call to filter cards based on value selected from dropdown
    this.setState({ [returnType]: selectedData }, callback);
  };

  /**
   * @description Fires when filter menu closes.
   * constructs a request object then invokes the redux action
   */
  onFilterMenuClosed = () => {
    const { filterCardsList, verticalType } = this.props;

    const request = this.constructRequestObject();

    filterCardsList(request, verticalType);
  };

  /**
   * @description clears away filters
   */
  clearFilters = (fetchVerticalData = true) => {
    const { clearCardsList } = this.props;

    clearCardsList();

    this.setState(
      {
        category: [],
        city: [],
        dateRange: undefined,
        neighborhood: [],
        orderBy: '',
        flaggedReason: '',
        price: [],
        rating: [],
        reviewStatus: '',
      },
      () => {
        if (fetchVerticalData) {
          this.getVerticalData();
        }
      }
    );
  };

  /**
   * @description clears away category and rating filters
   */
  resetCategoriesFilter = () => this.setState({ category: [], rating: [] });

  /**
   * @description sets state and fires a GET request for a search bar
   *
   * @param {String} query - string for the query search
   */
  onSearchInput = (query) => {
    const { clearCardsList, verticalType } = this.props;

    // don't call the search api if it's just a text input clear
    if (verticalType === VERTICAL_TYPE.UNIVERSAL_SEARCH && query === '') {
      clearCardsList();
    } else {
      // API call to filter cards based on query
      clearCardsList();
      this.setState({ query, newSearch: true }, this.getVerticalData);
    }
  };

  /**
   * @description fires when the modal window for editing a card
   * is closed. Used for pushing the correct history to Routers
   * history object, dismissing the widget then setting the tab.
   */
  onCloseModal = () => {
    const { location, history } = this.props;
    const { flaggedReports } = this.state;

    const { section, tab, id } = commonService.pathnameHelper(
      location.pathname
    );

    if (id) {
      history.push(`/${section}/${tab}`);
    }
    // if flagged report with data set in state, reset it on modal close.
    if (flaggedReports !== null) {
      this.setState({ flaggedReports: null });
    }

    this.setCurrentTab();
  };

  /**
   * @description sets the state with a new place data object for editing,
   * Fires on clicking of a card or the add new button
   * also forwards a URL which the component listens for
   * and renders based on ID 'endpoint'
   *
   * @param {Object} newCardData - Object of new place data to
   * be set to the state object
   */
  setNewCardData = (newCardData) => {
    const { location, history, verticalType } = this.props;
    const { section, tab, id } = commonService.pathnameHelper(
      location.pathname
    );

    // prevents infinite loop with id/endpoint rendering
    if (id) {
      history.push(`/${section}/${tab}`);
    } else if (newCardData && newCardData.id) {
      // if from the 3rd party modal control flow
      const { status } = newCardData;

      history.push(`${status || tab}/${newCardData.id}`);
    }

    this.setState({ newCardData, openCardID: '', thirdPartyEdit: null });
  };

  /**
   * @description fires when you click a card, type of action depends on the
   * string value passed.
   *
   * @param {String} value - String specifying the action (Edit/Delete/Etc..)
   * @param {Object} data - Object of activity data
   */
  onMenuClicked = (value, data) => {
    const { location, verticalType } = this.props;

    const {
      SUB_MENU: { EDIT, DELETE, PIN },
    } = GENERIC;

    const { tab } = commonService.pathnameHelper(location.pathname);
    const { id, status } = data;

    if (value === EDIT) {
      if (verticalType === VERTICAL_TYPE.UNIVERSAL_SEARCH && status) {
        const cardStatus = status === 'draft' ? 'drafts' : status;
        const cardType = getEndpointTypeByPrettyId(data);

        // history.push(`/${cardType}/${cardStatus}/${data.id || id}`);
        window.open(`/${cardType}/${cardStatus}/${data.id || id}`, '_self');
      } else {
        window.open(`${tab}/${id}`, '_self');
        // history.push(`${tab}/${id}`);
      }
    }

    if (value === DELETE) {
      this.setState({ deleteModalData: id });
    }

    // for collections
    if (value === PIN) {
      const { updateSingleCardProperty } = this.props;
      const { is_pinned } = data;

      updateSingleCardProperty({ is_pinned: !is_pinned }, id, verticalType);
    }
  };

  /**
   * @description fires when you click a card, type of action depends on the
   * string value passed.
   *
   * @param {Object} unit - Unit to open
   * @param {Boolean} withNewTab - Whether the link should open in a new tab
   */
  onOpenClick = (unit, withNewTab = false) => {
    const { location, history, verticalType } = this.props;
    const { tab } = commonService.pathnameHelper(location.pathname);
    const { id, status } = unit;
    let urlToRedirect = null;
    if (
      (verticalType === VERTICAL_TYPE.UNIVERSAL_SEARCH ||
        verticalType === VERTICAL_TYPE.FLAGGED) &&
      status
    ) {
      const cardStatus = status === 'draft' ? 'drafts' : status;
      const cardType = getEndpointTypeByPrettyId(unit);
      urlToRedirect = `/${cardType}/${cardStatus}/${unit.id || id}`;
    } else {
      urlToRedirect = `${tab}/${id}`;
    }
    if (urlToRedirect == null) {
      return;
    }
    // if flagged content, set flagged data in state and pass down to form
    if (verticalType === VERTICAL_TYPE.FLAGGED) {
      const { reports } = unit;
      this.setState({ flaggedReports: reports });
    }

    if (withNewTab) {
      window.open(urlToRedirect, '_blank');
    } else {
      history.push(urlToRedirect);
    }
  };

  /**
   * @description resets deleteModalData property to null
   */
  closeDeleteModalData = () => this.setState({ deleteModalData: null });

  /**
   * @description fires when deleting an event from the list
   */
  deleteCardFromList = () => {
    const { deleteCard, verticalType } = this.props;
    const { deleteModalData } = this.state;

    this.closeDeleteModalData();
    deleteCard(deleteModalData, verticalType);
  };

  /**
   * @description generates filter for verticalDisplay
   */
  getFilters = () => {
    const {
      categories,
      cities: { cities: citiesArray },
      verticalType,
    } = this.props;

    const {
      category,
      city,
      currentTab,
      dateRange,
      flaggedReason,
      neighborhood,
      orderBy,
      price,
      rating,
      reviewStatus,
    } = this.state;

    const {
      CITIES,
      DATE_RANGE,
      FLAGGED_REASON,
      LOCATION,
      ORDER,
      PRICE,
      RATING,
      REVIEW_STATUS,
      TYPE,
    } = FILTER_DROP_DOWN;

    const verticalSpecificFilters = [];

    // const neighborhoodFilter = {
    //   name: LOCATION.LABEL,
    //   returnType: LOCATION.RETURN_TYPE,
    //   options: formGroupOptions(neighborhoods, 'neighborhoods'),
    //   value: neighborhood,
    //   onFilterSelect: this.onFilterSelect,
    //   filterType: FilterType.MULTI_SELECT,
    //   onMenuClose: this.onFilterMenuClosed,
    //   isSearchable: true,
    // };

    const citiesFilter = {
      name: CITIES.LABEL,
      parentReturnType: CITIES.RETURN_TYPE,
      childReturnType: LOCATION.RETURN_TYPE,
      options: createDropdownOptions(
        citiesArray
      ),
      parentValue: city,
      childValue: neighborhood,
      onFilterSelect: this.onFilterSelect,
      filterType: FilterType.PARENT_CHILD_SELECT,
      onMenuClose: this.onFilterMenuClosed,
      parentPlaceholder: CITIES.LABEL,
      childPlaceholder: LOCATION.LABEL,
      isClearable: true,
      isSearchable: true,
    };

    const priceFilter = {
      name: PRICE.LABEL,
      returnType: PRICE.RETURN_TYPE,
      options: formPriceOptions(),
      value: price,
      onFilterSelect: this.onFilterSelect,
      filterType: FilterType.MULTI_SELECT,
      onMenuClose: this.onFilterMenuClosed,
    };

    const ratingFilter = {
      name: RATING.LABEL,
      returnType: RATING.RETURN_TYPE,
      options: formRatingOptions(),
      value: rating,
      onFilterSelect: this.onFilterSelect,
      filterType: FilterType.MULTI_SELECT,
      onMenuClose: this.onFilterMenuClosed,
    };

    switch (verticalType) {
      case VERTICAL_TYPE.COLLECTION:
        verticalSpecificFilters.push(...[
          citiesFilter,
        ]);
        break;
      case VERTICAL_TYPE.PLACE:
        verticalSpecificFilters.push(
          ...[
            citiesFilter,
            priceFilter,
          ]
        );

        if (currentTab === 'fetched') {
          verticalSpecificFilters.push(
            ...[
              ratingFilter,
              {
                name: TYPE.LABEL,
                returnType: TYPE.RETURN_TYPE,
                options: formGroupOptions(
                  categories.adminCategories,
                  'categories',
                  'unique_slug',
                  'name'
                ),
                // options: categories.cardCategories.map((option) => ({
                //   value: option,
                //   label: option,
                // })),
                value: category,
                onFilterSelect: this.onFilterSelect,
                filterType: FilterType.MULTI_SELECT,
                onMenuClose: this.onFilterMenuClosed,
              },
            ]
          );

          return verticalSpecificFilters;
        }

        if (currentTab === 'queue') {
          verticalSpecificFilters.push(
            ...[
              {
                name: TYPE.LABEL,
                returnType: TYPE.RETURN_TYPE,
                options: formGroupOptions(
                  categories.adminCategories,
                  'categories',
                  'unique_slug',
                  'name'
                ),
                // options: categories.cardCategories.map((option) => ({
                //   value: option,
                //   label: option,
                // })),
                value: category,
                onFilterSelect: this.onFilterSelect,
                filterType: FilterType.MULTI_SELECT,
                onMenuClose: this.onFilterMenuClosed,
              },
            ]
          );

          return verticalSpecificFilters;
        }

        if (currentTab !== 'queue' && currentTab !== 'fetched') {
          verticalSpecificFilters.push(
            ...[
              {
                name: TYPE.LABEL,
                returnType: TYPE.RETURN_TYPE,
                options: formGroupOptions(
                  categories.adminCategories,
                  'categories',
                  'unique_slug',
                  'name'
                ),
                value: category,
                onFilterSelect: this.onFilterSelect,
                filterType: FilterType.MULTI_SELECT,
                onMenuClose: this.onFilterMenuClosed,
                isSearchable: true,
              },
            ]
          );
        }
        break;
      case VERTICAL_TYPE.EVENT:
        verticalSpecificFilters.push(
          ...[
            citiesFilter,
            // neighborhoodFilter,
            {
              name: DATE_RANGE.LABEL,
              returnType: DATE_RANGE.RETURN_TYPE,
              value: dateRange,
              onFilterSelect: this.onFilterSelect,
              filterType: FilterType.DATE_RANGE_PICKER,
              onMenuClose: this.onFilterMenuClosed,
            },
            priceFilter,
            {
              name: TYPE.LABEL,
              returnType: TYPE.RETURN_TYPE,
              options: formGroupOptions(
                categories.adminCategories,
                'categories',
                'unique_slug',
                'name'
              ),
              value: category,
              onFilterSelect: this.onFilterSelect,
              filterType: FilterType.MULTI_SELECT,
              onMenuClose: this.onFilterMenuClosed,
              isSearchable: true,
            },
          ]
        );
        break;
      case VERTICAL_TYPE.FLAGGED:
        verticalSpecificFilters.push({
          name: FLAGGED_REASON.LABEL,
          returnType: FLAGGED_REASON.RETURN_TYPE,
          options: flaggedReasonOptions,
          value: flaggedReason,
          onFilterSelect: this.onFilterSelect,
          filterType: FilterType.SELECT,
          onMenuClose: this.onFilterMenuClosed,
        });
        break;
      case VERTICAL_TYPE.MOVIE:
        verticalSpecificFilters.push(...[ratingFilter]);
        // fetched wont have review status or oderBy filters
        if (currentTab === 'fetched') return verticalSpecificFilters;
        verticalSpecificFilters.push({
          name: TYPE.LABEL,
          returnType: TYPE.RETURN_TYPE,
          options: formGroupOptions(
            categories.movieCategories,
            'categories',
            'unique_slug',
            'name'
          ),
          value: category,
          onFilterSelect: this.onFilterSelect,
          filterType: FilterType.MULTI_SELECT,
          onMenuClose: this.onFilterMenuClosed,
          isSearchable: true,
        });

        break;
      case VERTICAL_TYPE.ACTIVITY:
        verticalSpecificFilters.push(
          ...[
            {
              name: TYPE.LABEL,
              returnType: TYPE.RETURN_TYPE,
              options: formGroupOptions(
                categories.adminCategories,
                'categories',
                'unique_slug',
                'name'
              ),
              value: category,
              onFilterSelect: this.onFilterSelect,
              filterType: FilterType.MULTI_SELECT,
              onMenuClose: this.onFilterMenuClosed,
              isSearchable: true,
            },
          ]
        );
        break;
      case VERTICAL_TYPE.RECIPE:
        verticalSpecificFilters.push(
          ...[
            {
              name: TYPE.LABEL,
              returnType: TYPE.RETURN_TYPE,
              options: formGroupOptions(
                categories.recipeCategories,
                'categories',
                'unique_slug',
                'name'
              ),
              value: category,
              onFilterSelect: this.onFilterSelect,
              filterType: FilterType.MULTI_SELECT,
              onMenuClose: this.onFilterMenuClosed,
              isSearchable: true,
            },
          ]
        );
        break;
      case VERTICAL_TYPE.UNIVERSAL_SEARCH:
        // universal search has no filters, so we'll return an
        // empty array
        return [];
      default:
        verticalSpecificFilters.push(...[]);
    }

    const commonFilters = [
      {
        name: ORDER.LABEL,
        returnType: ORDER.RETURN_TYPE,
        options: orderByOptions,
        value: orderBy,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.SELECT,
        onMenuClose: this.onFilterMenuClosed,
      },
      // {
      //   name: REVIEW_STATUS.LABEL,
      //   returnType: REVIEW_STATUS.RETURN_TYPE,
      //   options: getCardReviewStatus(),
      //   value: reviewStatus,
      //   onFilterSelect: this.onFilterSelect,
      //   filterType: FilterType.SELECT,
      //   onMenuClose: this.onFilterMenuClosed,
      //   isSearchable: false,
      // },
    ];

    if (verticalType === VERTICAL_TYPE.FLAGGED) return verticalSpecificFilters;

    return [...verticalSpecificFilters, ...commonFilters];
  };

  toggleThirdPartyModal = () => {
    const { showThirdPartyModal } = this.state;

    this.setState({
      showThirdPartyModal: !showThirdPartyModal,
      // clears the card data if the user has the modal open and closes it
      ...(showThirdPartyModal ? { thirdPartyEdit: null } : {}),
    });
  };

  /**
   * @description sets thirdPartySearch modal data in state
   * then toggled rendering of the modal
   *
   * @param {Object} data - of card being edited
   */
  editThirdParty = (data) => {
    this.setState(
      {
        thirdPartyEdit: data,
      },
      this.toggleThirdPartyModal
    );
  };

  /**
   * @description updates third party card search information
   *
   * @param {Object} data - of card being edited
   */
  submitThirdParty = (data = {}) => {
    const { saveData, verticalType } = this.props;
    const dataCopy = deepCopy(data);

    dataCopy.should_refetch_3rd_parties_content = true;

    if (verticalType === VERTICAL_TYPE.PLACE) {
      // if a fresh card (no queue or fetched)
      if (!data.id) {
        // place hours skeleton required for backend
        if (!dataCopy.place_hours) {
          dataCopy.place_hours = {
            monday: { slots: [], status: '' },
            tuesday: { slots: [], status: '' },
            wednesday: { slots: [], status: '' },
            thursday: { slots: [], status: '' },
            friday: { slots: [], status: '' },
            saturday: { slots: [], status: '' },
            sunday: { slots: [], status: '' },
          };
        }

        if (!dataCopy.status) {
          dataCopy.status = 'draft';
        }

        saveData(dataCopy, null, this.forwardToCardId, verticalType);
      } else {
        dataCopy.misc_options = [];

        const formData = getPreparedFormData(
          dataCopy,
          [],
          !dataCopy.status ||
            dataCopy.status === 'fetched' ||
            dataCopy.status === 'queue'
            ? 'draft'
            : dataCopy.status,
          verticalType
        );

        if (formData.categories && Array.isArray(formData.categories)) {
          formData.set_categories = formData.categories.map(
            (cat) => cat.unique_slug
          );

          delete formData.categories;
        }

        formData.set_neighborhoods = formData.set_neighborhoods.map(
          (n) => n.unique_slug
        );

        saveData(formData, dataCopy.id, this.forwardToCardId, verticalType);
      }
    }

    if (verticalType === VERTICAL_TYPE.MOVIE) {
      // if a fresh card (no queue or fetched)
      if (!data.id) {
        if (!dataCopy.status) {
          dataCopy.status = 'draft';
        }

        if (dataCopy.rating) {
          delete dataCopy.rating;
        }

        if (dataCopy.related_tmdb_id) {
          dataCopy.related_tmdb_id = dataCopy.related_tmdb_id.toString();
        }

        saveData(dataCopy, null, this.forwardToCardId, verticalType);
      } else {
        dataCopy.rating = [];

        const formData = getPreparedFormData(
          dataCopy,
          [],
          !dataCopy.status ||
            dataCopy.status === 'fetched' ||
            dataCopy.status === 'queue'
            ? 'draft'
            : dataCopy.status,
          verticalType
        );

        saveData(formData, dataCopy.id, this.forwardToCardId, verticalType);
      }
    }
  };

  /**
   * @description FWD's browser to card ID location
   */
  forwardToCardId = (cardId = '', defaultTab) => {
    const { location, history } = this.props;
    const { tab } = commonService.pathnameHelper(location.pathname);

    if (cardId) {
      history.push(`${defaultTab || tab}/${cardId}`);
    }
  };

  checkAndGrabData() {
    const {
      categories,
      cities,
      fetchNeighborhoodLocations,
      getCategoriesList,
      getCitiesList,
      verticalDisplay,
    } = this.props;
    const { fetchingNeighborhoods, fetchingCities, fetchingCategories } =
      this.state;

    if (!categories.initialDataLoaded && !fetchingCategories) {
      this.setState(
        {
          fetchingCategories: true,
        },
        getCategoriesList
      );
    } else if (categories.initialDataLoaded && fetchingCategories) {
      this.setState({
        fetchingCategories: false,
      });
    }

    if (!verticalDisplay.neighborhoodsLoaded && !fetchingNeighborhoods) {
      this.setState(
        {
          fetchingNeighborhoods: true,
        },
        fetchNeighborhoodLocations
      );
    } else if (verticalDisplay.neighborhoodsLoaded && fetchingNeighborhoods) {
      this.setState({
        fetchingNeighborhoods: false,
      });
    }

    if (!cities.initialDataLoaded && !fetchingCities) {
      this.setState(
        {
          fetchingCities: true,
        },
        getCitiesList
      );
    } else if (cities.initialDataLoaded && fetchingCities) {
      this.setState({
        fetchingCities: false,
      });
    }
  }

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\  VerticalDisplay rendering
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  render() {
    const { categories, cities, verticalDisplay, verticalType } = this.props;

    const {
      cards,
      isInitialDataLoaded,
      neighborhoodsLoaded,
      totalCount,
      tmdbPage,
    } = verticalDisplay;

    const {
      currentTab,
      deleteModalData,
      fetchingData,
      flaggedReports,
      hasMore,
      newCardData,
      query,
      showThirdPartyModal,
      thirdPartyEdit,
      shouldDisplayImageInspector,
    } = this.state;

    const {
      CARD_VIEW_TITLE,
      CARD_VIEW_TITLE_EDIT,
      TAB_DATA,
      WARNING_ON_DELETE,
    } = chooseConstant(verticalType);

    const pageTitle =
      newCardData && newCardData.id ? CARD_VIEW_TITLE_EDIT : CARD_VIEW_TITLE;

    const fromFetched = currentTab === 'fetched' || currentTab === 'queue';

    if (fromFetched && newCardData) {
      newCardData.categories = [];
    }

    // will be rendered to VerticalDisplay content section.
    // changes depending on vertical and loading status
    let verticalListContent;

    // different loading behavior depending on verticalType
    switch (verticalType) {
      case VERTICAL_TYPE.UNIVERSAL_SEARCH: {
        verticalListContent = (
          <Fragment>
            <TotalCount current={cards.length} total={totalCount || 0} />
            {cards.length === 0 && fetchingData && !isInitialDataLoaded && (
              <Loader />
            )}
            {cards.length === 0 && isInitialDataLoaded && <NoDataFound />}
            {cards.length !== 0 && (
              <VerticalCardList
                data={cards}
                hasMore={hasMore}
                onMoreData={this.getMoreData}
                onMenuClicked={(action, data) => {
                  if (currentTab === 'fetched' || currentTab === 'queue') {
                    this.editThirdParty(data);
                  } else {
                    this.onMenuClicked(action, data);
                  }
                }}
                onOpenClick={(unit) =>
                  this.onOpenClick(unit, shouldDisplayImageInspector)
                }
                fromFetched={fromFetched}
                fetchingData={fetchingData}
                currentTab={currentTab}
                verticalType={verticalType}
                shouldDisplayImageInspector={shouldDisplayImageInspector}
                hideMenu
              />
            )}
          </Fragment>
        );
        break;
      }
      default: {
        // Show loader until everything's loaded (duh.. :-P)
        verticalListContent = <Loader />;

        if (
          isInitialDataLoaded &&
          categories.initialDataLoaded &&
          neighborhoodsLoaded &&
          cities.initialDataLoaded
        ) {
          if (totalCount) {
            verticalListContent = (
              <Fragment>
                <TotalCount
                  current={cards.length}
                  total={totalCount || 0}
                  // so long as we're querying tmdb we don't know
                  // how many results we'll get, this prop will conditionally
                  // render the number of result counts displayed
                  outsideFetch={tmdbPage > -1 && hasMore}
                />
                <VerticalCardList
                  data={cards}
                  hasMore={hasMore}
                  onMoreData={this.getMoreData}
                  onMenuClicked={(action, data) => {
                    if (currentTab === 'fetched' || currentTab === 'queue') {
                      this.editThirdParty(data);
                    } else {
                      this.onMenuClicked(action, data);
                    }
                  }}
                  onOpenClick={(unit) => {
                    this.onOpenClick(unit, shouldDisplayImageInspector);
                  }}
                  fromFetched={fromFetched}
                  fetchingData={fetchingData}
                  currentTab={currentTab}
                  verticalType={verticalType}
                  hideMenu={
                    verticalType === VERTICAL_TYPE.FLAGGED ||
                    currentTab === 'archived' ||
                    currentTab === 'inactive'
                  }
                  shouldDisplayImageInspector={shouldDisplayImageInspector}
                />
              </Fragment>
            );
          } else {
            verticalListContent = <NoDataFound />;
          }
        }
      }
    }

    return (
      <div className="vertical-display-container">
        <div className="page-content">
          {newCardData !== null &&
          categories.initialDataLoaded &&
          neighborhoodsLoaded ? (
            <CardViewModal
              title={pageTitle}
              modalClosed={() => askForCloseConfirmation(this.onCloseModal)}
            >
              <VerticalForm
                data={newCardData}
                verticalType={verticalType}
                flaggedReports={flaggedReports}
                closeModal={() => this.onCloseModal()}
                currentTab={currentTab}
                cities={cities}
              />
            </CardViewModal>
          ) : (
            <Fragment>
              <CardBanner
                title={chooseVerticalHeader(verticalType)}
                newBtnClick={() => {
                  if (
                    verticalType === VERTICAL_TYPE.PLACE ||
                    verticalType === VERTICAL_TYPE.MOVIE
                  ) {
                    this.toggleThirdPartyModal();
                  } else {
                    this.setNewCardData({});
                  }
                }}
                isNewButtonEnabled={
                  verticalType !== VERTICAL_TYPE.UNIVERSAL_SEARCH &&
                  verticalType !== VERTICAL_TYPE.FLAGGED
                }
              />
              <Tabs data={TAB_DATA} active={currentTab} />
              <FilterSection
                filters={this.getFilters()}
                searchValue={query}
                onSearchInput={this.onSearchInput}
                clearFilters={this.clearFilters}
                buttonSearch={verticalType === VERTICAL_TYPE.UNIVERSAL_SEARCH}
                imageInspectSwitchHandler={(value) => {
                  this.setState({ shouldDisplayImageInspector: value });
                }}
                shouldDisplayImageInspectorSwitch={
                  verticalType !== VERTICAL_TYPE.UNIVERSAL_SEARCH
                }
                shouldDisplayImageInspector={shouldDisplayImageInspector}
                singleRow={verticalType === VERTICAL_TYPE.FLAGGED}
              />
              {verticalListContent}
              {deleteModalData !== null && (
                <DeleteModal
                  cancelClick={this.closeDeleteModalData}
                  deleteClick={this.deleteCardFromList}
                  additionalMessage={WARNING_ON_DELETE}
                />
              )}
              {showThirdPartyModal && (
                <ThirdPartySearchModal
                  verticalType={verticalType}
                  showModal={showThirdPartyModal}
                  closeModal={this.toggleThirdPartyModal}
                  onSubmit={this.submitThirdParty}
                  cardData={thirdPartyEdit}
                  noDataCallback={this.setNewCardData}
                />
              )}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerticalDisplay)
);
