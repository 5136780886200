/**
 * ************************************
 *
 * @module  RecurrenceSelector.js
 * @author  Matt P
 * @date    02/18/2021
 * @description Select Recurrence Values for Date Range.
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                  Imports
// ---------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import { formatDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

import { weekOfMonth } from 'utils/utils';

import { DATE_SELECTOR_POPUP as LABELS } from 'constants.js';

import FormInput from 'components/Form/FormInput/FormInput';

import {
  REPEAT_MODE,
  AVAILABILITY_OPTIONS,
  REPEAT_MODE_OPTIONS,
} from '../EventDateSelector.constants';

import WeekDaySelector from './WeekDaySelector/WeekDaySelector';

import './RecurrenceSelector.scss';

// ---------------------------------------------------------------------------|
//                                  Utilities
// ---------------------------------------------------------------------------|
const { WEEKLY, MONTHLY } = REPEAT_MODE;

const repeatOptions = {
  type: 'dropdown',
  name: 'repeatMode',
  label: LABELS.REPEAT,
  values: [REPEAT_MODE_OPTIONS.WEEKLY, REPEAT_MODE_OPTIONS.MONTHLY],
  value: null,
};

const endDateOptions = {
  type: 'datepicker',
  name: 'endDate',
  label: LABELS.ENDS_ON,
  value: null,
  dateFormat: 'dd MMM yyyy',
};

/**
 * @description gets the available dropdown options for
 *
 * @returns {JSX}
 */
const getAvailabilityDropdownOptions = (startDate, availability) => {
  const date = moment(startDate);

  const onWeekday = { ...AVAILABILITY_OPTIONS.MONTHLY_WEEKDAY };
  const weekIndex = weekOfMonth(date);
  onWeekday.label = onWeekday.label.replace(
    '%s',
    `${weekIndex} ${date.format('dddd')}`
  );

  const onNDate = { ...AVAILABILITY_OPTIONS.MONTHLY_ON_DATE };
  onNDate.label = onNDate.label.replace('%s', `${date.format('D')}`);

  const dropdownOptions = [onWeekday, onNDate];
  let selectedOption = null;

  if (availability) {
    selectedOption = dropdownOptions.find(
      (i) => i.value === availability.value
    );
  }

  return {
    type: 'dropdown',
    name: 'availability',
    label: LABELS.SELECT_AVAILABILITY,
    values: dropdownOptions,
    value: selectedOption,
  };
};

/**
 * @description used to display the end date format to our specs
 *
 * @returns {String}
 */
const parseDate = (str, format, locale) => {
  const parsed = dateFnsParse(str, format, new Date(), { locale });

  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
};

// ---------------------------------------------------------------------------|
//                React Function Component - Recurrence Selector
// ---------------------------------------------------------------------------|
const RecurrenceSelector = (props) => {
  const {
    startDate,
    selectedWeekDays,
    onWeekdaysSelected,
    repeatMode,
    endDate,
    availability,
    onRepeatModeSelected,
    onAvailabilitySelected,
    handleEndDateChange,
    errors,
  } = props;

  const availabilityOptions = getAvailabilityDropdownOptions(
    startDate,
    availability
  );

  repeatOptions.value = repeatMode;
  endDateOptions.value = endDate;
  endDateOptions.minimumDate = moment(startDate).add(1, 'd').toDate();

  return (
    <div className="recurrence-selector">
      <div className="repeat-mode">
        <div className="end-date-picker form-input">
          <label>End On</label>
          <DayPickerInput
            value={
              endDate ? dateFnsFormat(new Date(endDate), 'MMM DD YYYY') : null
            }
            formatDate={formatDate}
            parseDate={parseDate}
            onDayChange={handleEndDateChange}
            placeholder="Select end date"
          />
        </div>
        {endDate && !errors.dateRangeError ? (
          <FormInput
            options={repeatOptions}
            eventHandler={onRepeatModeSelected}
            placeholder="Select"
          />
        ) : (
          ''
        )}
      </div>
      {repeatMode && repeatMode.value === WEEKLY && (
        <div className="weekdays-selector">
          <label>{LABELS.SELECT_DAYS}</label>
          <WeekDaySelector
            className="recurring-weekdays-selector"
            selected={selectedWeekDays}
            onSelect={onWeekdaysSelected}
          />
        </div>
      )}
      <div className="error">
        {errors.weekDaysError ? errors.weekDaysError : ''}
      </div>
      {repeatMode && repeatMode.value === MONTHLY && (
        <div className="availability-mode">
          <FormInput
            options={availabilityOptions}
            eventHandler={onAvailabilitySelected}
            placeholder="Select"
          />
          <div className="error">
            {errors.selectedMonthError ? errors.selectedMonthError : ''}
          </div>
        </div>
      )}
    </div>
  );
};

// -------------------------------------------------------------------------|
//                             PropTypes Check
// -------------------------------------------------------------------------|
RecurrenceSelector.propTypes = {
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
  ]),
  selectedWeekDays: PropTypes.arrayOf(PropTypes.string),
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
  ]).isRequired,
  repeatMode: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  availability: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  onWeekdaysSelected: PropTypes.func.isRequired,
  onRepeatModeSelected: PropTypes.func.isRequired,
  onAvailabilitySelected: PropTypes.func.isRequired,
  handleEndDateChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    dateRangeError: PropTypes.string,
    weekDaysError: PropTypes.string,
    selectedMonthError: PropTypes.string,
    timeErrors: PropTypes.string,
  }),
};

// -------------------------------------------------------------------------|
//                              Default Props
// -------------------------------------------------------------------------|
RecurrenceSelector.defaultProps = {
  selectedWeekDays: [],
  endDate: '',
  availability: '',
  errors: {},
};
// ---------------------------------------------------------------------------|
//                                  Export
// ---------------------------------------------------------------------------|
export default RecurrenceSelector;
