/**
 * ************************************
 *
 * @module  Recipes.saga.js
 * @author  Matt P
 * @date    02/01/2021
 * @description redux saga file for the Recipes container.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                    Imports
// ----------------------------------------------------------------------------|
import { call, put, select } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import ACTIONS from 'store/actions/actionTypes';
import API from 'API';
import {
  clearRecipesAction,
  getRecipesListAction,
} from 'store/actions/~depreciated/Recipes.action';

import { GENERIC } from 'constants.js';

// ----------------------------------------------------------------------------|
//                                Sagas - Recipes
// ----------------------------------------------------------------------------|
function* getRecipesList(action) {
  const { searchData } = window.axios;

  try {
    const {
      status,
      title,
      loaded_ids,
      order_by_parameter,
      ratings,
      cat_unique_slug_list,
      review_status,
    } = action.data;

    const washedData = {
      status,
      title,
      loaded_ids,
      cat_unique_slug_list,
      review_status,
    };

    // backend gets strange when you pass these without selections/values
    if (ratings) washedData.ratings = ratings;
    if (order_by_parameter) washedData.order_by_parameter = order_by_parameter;

    const URL = `${API.recipeList}?page=${action.data.page}&page_size=10`;
    const response = yield call(searchData, 'post', URL, washedData);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.RECIPES.GET_RECIPES_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: ACTIONS.RECIPES.GET_RECIPES_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.RECIPES.GET_RECIPES_FAIL });
  }
}

function* deleteRecipe(action) {
  const { deleteData } = window.axios;
  const { DELETE_SUCCESS_MESSAGE, DELETE_FAILURE_MESSAGE } = GENERIC;

  try {
    const URL = `${API.recipeDetail}${action.data}`;
    const response = yield call(deleteData, URL);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.RECIPES.DELETE_RECIPE_SUCCESS,
        data: action.data,
      });
      toast.success(DELETE_SUCCESS_MESSAGE);
    } else {
      yield put({ type: ACTIONS.RECIPES.DELETE_RECIPE_FAIL });
      toast.error(DELETE_FAILURE_MESSAGE);
    }
  } catch (error) {
    yield put({ type: ACTIONS.RECIPES.DELETE_RECIPE_FAIL });
  }
}

function* performBulkOperationOnRecipe(action) {
  const { patchData } = window.axios;
  const { data } = action;
  try {
    const response = yield call(patchData, API.place, data);

    if (response.status === 200) {
      yield put({ type: ACTIONS.RECIPES.BULK_ACTION_SUCCESS, data });
    } else {
      yield put({ type: ACTIONS.RECIPES.BULK_ACTION_FAIL, data });
    }
  } catch (error) {
    yield put({ type: ACTIONS.RECIPES.BULK_ACTION_FAIL, data });
  }
}

function* filterRecipesList(action) {
  yield put(clearRecipesAction());

  const pageNum = yield select((state) => state.recipes.pageNum);
  const data = { ...action.data, page: pageNum };

  yield put(getRecipesListAction(data));
}

// ----------------------------------------------------------------------------|
//                            Recipes Saga Export
// ----------------------------------------------------------------------------|
export {
  getRecipesList,
  deleteRecipe,
  performBulkOperationOnRecipe,
  filterRecipesList,
};
