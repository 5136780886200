/**
 * ************************************
 *
 * @module  VerticalForm.reducer.js
 * @author  Matt P
 * @date    07/02/2021
 * @description redux reducer file for VerticalForm container. Used to keep
 * state for editing a cards detail.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import { deepCopy } from 'utils/utils';

import {
  combineCollectionCards,
  getCardTypeByPrettyId,
} from 'containers/VerticalDisplay/VerticalForm/VerticalFormUtils';

import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                               Initial State
// ----------------------------------------------------------------------------|
const initialState = {
  // Contains images that are to be auto-filled into the image upload component
  autofilledImages: [],
  images: [],
  formData: {},
  fetchedCardData: null,
  // Specific to collections card selection functionality
  selectedCards: [],
  cardListData: {
    activity: {
      cardList: [],
      cardListDataCount: 0,
      isCardListDataFetched: false,
      cardListPageNum: 1,
    },
    place: {
      cardList: [],
      cardListDataCount: 0,
      isCardListDataFetched: false,
      cardListPageNum: 1,
    },
    event: {
      cardList: [],
      cardListDataCount: 0,
      isCardListDataFetched: false,
      cardListPageNum: 1,
    },
    movie: {
      cardList: [],
      cardListDataCount: 0,
      isCardListDataFetched: false,
      cardListPageNum: 1,
    },
    recipe: {
      cardList: [],
      cardListDataCount: 0,
      isCardListDataFetched: false,
      cardListPageNum: 1,
    },
  },
  // Contains list of fileIds for whom photo upload failed
  failedUploads: [],
  isDropZonePreviewRequired: false,
  isBtnDisabled: false,
};

// ----------------------------------------------------------------------------|
//                                  Utilities
// ----------------------------------------------------------------------------|
// identifier can be the source url of the image in case of auto-filled images
// For images that were not auto-filled, it is the file id
// If an image's source url or its ID matches the identifier param, delete it
// from the array
const getFilteredImages = (identifier, images = []) =>
  images.filter(
    (image) => image.url !== identifier && image.fileId !== identifier
  );

// ----------------------------------------------------------------------------|
//                            VerticalForm Reducer
// ----------------------------------------------------------------------------|
const VerticalForm = (state = initialState, action = null) => {
  const { type, data } = action;

  switch (type) {
    /**
     * @description - success on single card fetch by ID and verticalType
     */
    case ACTIONS.VERTICAL_FORM.FETCH_VERTICAL_FORM_DETAIL_SUCCESS: {
      return {
        ...state,
        fetchedCardData: data,
      };
    }

    /**
     * @description - populates autofilled form images
     */
    case ACTIONS.VERTICAL_FORM.POPULATE_IMAGES: {
      return {
        ...state,
        autofilledImages: data || [],
      };
    }

    /**
     * @description - saves form data
     */
    case ACTIONS.VERTICAL_FORM.SAVE_DATA_TO_REDUX: {
      return {
        ...state,
        formData: { ...state.formData, ...data },
      };
    }

    /**
     * @description Clear any errors for image being uploaded
     */
    case ACTIONS.VERTICAL_FORM.UPLOAD_IMAGE: {
      return {
        ...state,
        isDropZonePreviewRequired: true,
        failedUploads: state.failedUploads.filter(
          (fileId) => fileId !== data.fileId
        ),
      };
    }

    /**
     * @description on successful image upload
     */
    case ACTIONS.VERTICAL_FORM.UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        autofilledImages: [
          ...state.autofilledImages,
          { ...data, fileId: data.fileId, photo_metadata: data.photo_metadata },
        ],
        isDropZonePreviewRequired: false,
      };
    }

    /**
     * @description on unsuccessful image upload
     */
    case ACTIONS.VERTICAL_FORM.UPLOAD_IMAGE_FAIL: {
      return { ...state, failedUploads: [...state.failedUploads, data.fileId] };
    }

    /**
     * @description on image delete
     */
    case ACTIONS.VERTICAL_FORM.DELETE_IMAGE: {
      return {
        ...state,
        autofilledImages: getFilteredImages(data, state.autofilledImages),
      };
    }

    /**
     * @description clears images array
     */
    case ACTIONS.VERTICAL_FORM.CLEAR_IMAGES: {
      return { ...state, images: [] };
    }

    /**
     * @description resets form state
     */
    case ACTIONS.VERTICAL_FORM.CLEAR_FORM_STATE: {
      return { ...initialState };
    }

    /**
     * @description reorders images array
     */
    case ACTIONS.VERTICAL_FORM.RE_ORDER_IMAGES: {
      return { ...state, autofilledImages: data };
    }

    /**
     * @description disables buttons upon form submission
     */
    case ACTIONS.VERTICAL_FORM.BUTTON_DISABLING: {
      return {
        ...state,
        isBtnDisabled: data.isBtnDisabled,
      };
    }

    /**
     * @description populates card selection form component
     */
    case ACTIONS.VERTICAL_FORM.POPULATE_COLLECTION_CARD_LIST: {
      return {
        ...state,
        selectedCards: combineCollectionCards(action.data),
      };
    }

    /**
     * @description updates card data in cardSelection component
     */
    case ACTIONS.VERTICAL_FORM.UPDATE_COLLECTION_CARD_LIST: {
      return {
        ...state,
        selectedCards: action.data,
      };
    }

    /**
     * @description upon successful vertical card list http request
     * for the card selection modal
     */
    case ACTIONS.VERTICAL_FORM.GET_VERTICAL_CARD_SELECTION_CARDS_SUCCESS: {
      const { subType } = action;
      const { cards, places, movies, recipes, activities, count } = data;

      const fetchedData =
        cards || places || movies || recipes || activities || [];

      const cardListDataDeepCopy = deepCopy(state.cardListData);

      const updatedCardListData = [
        ...cardListDataDeepCopy[subType].cardList,
        ...fetchedData,
      ].filter(
        // filters for cards already selected
        ({ id: cardId }) =>
          !state.selectedCards.some(
            ({ id: selectedCardId }) => selectedCardId === cardId
          )
      );

      cardListDataDeepCopy[subType].cardList = updatedCardListData;
      cardListDataDeepCopy[subType].cardListDataCount = count;
      cardListDataDeepCopy[subType].isCardListDataFetched = true;
      cardListDataDeepCopy[subType].cardListPageNum += 1;

      return {
        ...state,
        cardListData: cardListDataDeepCopy,
      };
    }

    /**
     * @description upon unsuccessful vertical card list http request
     * for the card selection modal
     */
    case ACTIONS.VERTICAL_FORM.GET_VERTICAL_CARD_SELECTION_CARDS_FAIL: {
      const { subType } = action;
      const cardListDataDeepCopy = deepCopy(state.cardListData);

      cardListDataDeepCopy[subType].isCardListDataFetched = true;

      return {
        ...state,
        cardListData: cardListDataDeepCopy,
      };
    }

    /**
     * @description reset the state
     */
    case ACTIONS.VERTICAL_FORM.RESET_CARD_SELECTION_STATE: {
      return {
        ...state,
        cardListData: deepCopy(initialState.cardListData),
      };
    }

    /**
     * @description clear card selection modal state
     */
    case ACTIONS.VERTICAL_FORM.CLEAR_CARD_SELECTION_SEARCH_LIST: {
      const { subType } = action;
      const cardListDataDeepCopy = deepCopy(state.cardListData);

      cardListDataDeepCopy[subType].cardList = [];
      cardListDataDeepCopy[subType].cardListDataCount = 0;
      cardListDataDeepCopy[subType].isCardListDataFetched = false;
      cardListDataDeepCopy[subType].cardListPageNum = 1;

      return {
        ...state,
        cardListData: cardListDataDeepCopy,
      };
    }

    /**
     * @description select a card! Any card!
     */
    case ACTIONS.VERTICAL_FORM.SELECT_CARD_LIST: {
      return {
        ...state,
        selectedCards: [...state.selectedCards, ...action.data],
      };
    }

    /**
     * @description now for my last trick.... I'll make a selected
     * card disappear
     */
    case ACTIONS.VERTICAL_FORM.DESELECT_CARD_LIST: {
      const deselectedCard = action.data;
      const updatedSelectedCards = state.selectedCards.filter(
        (card) => card.id !== deselectedCard.id
      );
      const cardListDataDeepCopy = deepCopy(state.cardListData);

      cardListDataDeepCopy[getCardTypeByPrettyId(deselectedCard)].cardList.push(
        deselectedCard
      );

      return {
        ...state,
        cardListData: cardListDataDeepCopy,
        selectedCards: [...updatedSelectedCards],
      };
    }

    default: {
      return state;
    }
  }
};

// ----------------------------------------------------------------------------|
//                        Export - VerticalForm Reducer
// ----------------------------------------------------------------------------|
export default VerticalForm;
