/**
 * ************************************
 *
 * @module  CobReactMultiSelect.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Dynamic/Reusable function Component for drop downs leveraging
 * React Hooks
 * Examples: Neighborhood / Price / Rating for filtering on
 * Places/Events/CuratedCards routes
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import cx from 'classnames';

import Option from 'components/CobReactMultiSelect/Option';
import ValueContainer from 'components/CobReactMultiSelect/ValueContainer';

import 'components/CobReactMultiSelect/CobReactMultiSelect.scss';

// ----------------------------------------------------------------------------|
//               React Function Component - CobReactMultiSelect
// ----------------------------------------------------------------------------|
const CobReactMultiSelect = (props) => {
  const {
    selectedItems,
    options,
    onSelect,
    isClearable,
    isSearchable,
    className,
    onMenuClose,
    onSelectionCleared,
    placeholder,
    ...rest
  } = props;

  const [touched, setTouched] = useState(false);
  const [placeholderText, setPlaceholderText] = useState(true);

  const css = cx(['cob-react-multiselect drop-down', className]);

  /**
   * @description - overridden component modifies the list items
   * of a dropdown. In this case, adds a check box
   */
  const overriddenComponents = {
    Option,
    ValueContainer,
    IndicatorSeparator: null,
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
    }),
  };

  return (
    <Select
      isMulti
      components={overriddenComponents}
      value={selectedItems}
      options={options}
      onChange={(returnType, updatedData) => {
        if (options !== updatedData) {
          setTouched(true);
        }
        onSelect(returnType, updatedData);
      }}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isClearable={isClearable}
      isSearchable={isSearchable}
      className={css}
      onMenuClose={() => {
        if (touched && onMenuClose) {
          onMenuClose();
          setTouched(false);
        }
      }}
      onFocus={() => {
        if (isSearchable) setPlaceholderText(false);
      }}
      onBlur={() => {
        if (isSearchable) setPlaceholderText(true);
      }}
      onInputChange={(_, action) => {
        if (action === 'input-blur') {
          onSelectionCleared();
        }
      }}
      placeholder={placeholderText ? placeholder : ''}
      styles={customStyles}
      {...rest}
    />
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
CobReactMultiSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  isClearable: PropTypes.bool,
  className: PropTypes.string,
  onMenuClose: PropTypes.func,
  onSelectionCleared: PropTypes.func,
};

CobReactMultiSelect.defaultProps = {
  selectedItems: [],
  isClearable: true,
  isSearchable: false,
  className: '',
  onMenuClose: null,
  onSelectionCleared: null,
};

// ----------------------------------------------------------------------------|
//                        CobReactMultiSelect Export
// ----------------------------------------------------------------------------|
export default CobReactMultiSelect;
