/**
 * ************************************
 *
 * @module  EventDateSelectorService.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Validation services for error handling for
 *  the EventDateSelector component
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                  Imports
// ---------------------------------------------------------------------------|
import moment from 'moment';

import { DATE_SELECTOR_POPUP } from 'constants.js';
import { weekIndexOfMonth, convertArrayToBooleanMap } from 'utils/utils';

import EventDateSelectorModel from './Models/EventDateSelectorModel';
import EventHoursModel from './Models/EventHoursModel';

import { AVAILABILITY_OPTIONS } from './EventDateSelector.constants';

import areDatesOverlapping from './EventDateSelectorUtils';

const { MONTHLY_WEEKDAY, MONTHLY_ON_DATE } = AVAILABILITY_OPTIONS;
const {
  TIME_FORMAT,
  TIME_RANGE_ERROR,
  DATE_RANGE_ERROR,
  WEEKDAY_RANGE_ERROR,
  MONTHLY_DATE_RANGE_ERROR,
  DATE_OVERLAP_ERROR,
} = DATE_SELECTOR_POPUP;

class EventDateSelectorService {
  static validateTimeRange(fromTime, toTime) {
    // if (
    //   // will allow user to set 12AM as an end time in case it's an event that
    //   // goes overnight. They can then start the following day at 12AM
    //   (moment(toTime, TIME_FORMAT).isBefore(moment(fromTime, TIME_FORMAT)) &&
    //     toTime !== '12:00 AM') ||
    //   moment(toTime, TIME_FORMAT).isSame(moment(fromTime, TIME_FORMAT))
    // ) {
    //   return TIME_RANGE_ERROR;
    // }

    return '';
  }

  static validateDateRange(fromDate, toDate) {
    if (moment(fromDate).isAfter(toDate)) {
      return DATE_RANGE_ERROR;
    }

    return '';
  }

  // static validateWeekRange(startDate, endDate) {
  //   if (endDate && endDate.diff(startDate, 'd') < 7) {
  //   }
  //   return '';
  // }

  static validateWeekDays(startDate, endDate, selectedWeekDays) {
    if (endDate && endDate.diff(startDate, 'd') < 7) {
      const map = convertArrayToBooleanMap(selectedWeekDays);

      for (
        let date = moment(startDate);
        date.startOf('day').isSameOrBefore(endDate.startOf('day'));
        date.add(1, 'd')
      ) {
        map[date.format('dddd').toLowerCase()] = true;
      }

      if (!selectedWeekDays.every((weekday) => map[weekday])) {
        return WEEKDAY_RANGE_ERROR;
      }
    }
    return '';
  }

  static validateSelectedMonth(startDate, endDate, availability) {
    if (availability && endDate) {
      if (availability.value === MONTHLY_ON_DATE.value) {
        const expectedDate = moment(startDate).add(1, 'M');

        if (endDate.startOf('day').isBefore(expectedDate.startOf('day'))) {
          return MONTHLY_DATE_RANGE_ERROR;
        }
      }

      if (availability.value === MONTHLY_WEEKDAY.value) {
        const weekIndex = weekIndexOfMonth(startDate);
        const weekday = startDate.format('dddd');
        let expectedDate = moment(startDate).date(1).add(1, 'M');

        while (expectedDate.format('dddd') !== weekday) {
          expectedDate.add(1, 'd');
        }

        expectedDate = expectedDate.add(weekIndex - 1, 'week');

        if (endDate.startOf('day').isBefore(expectedDate.startOf('day'))) {
          return MONTHLY_DATE_RANGE_ERROR;
        }
      }
    }

    return '';
  }

  static checkForOverlappingDates(dates) {
    for (let i = 0; i < dates.length; i += 1) {
      for (let j = i + 1; j < dates.length; j += 1) {
        if (areDatesOverlapping(dates[i], dates[j])) {
          return { value: DATE_OVERLAP_ERROR, overlap1: i, overlap2: j };
        }
      }
    }

    return { value: '', overlap1: null, overlap2: null };
  }

  static prepareDataForBackend(dates) {
    if (!Array.isArray(dates)) return [];

    return dates.map((date) => new EventHoursModel(date));
  }

  static prepareDataForUI(dates) {
    if (!Array.isArray(dates)) return [];

    return dates.map((date) => new EventDateSelectorModel(date));
  }
}

export default EventDateSelectorService;
