/**
 * ************************************
 *
 * @module  Navbar.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description top navbar of the CMS
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { logoutAction } from 'store/actions/Auth.action.js';

import { Button } from 'components';

import { COLLECTION } from 'constants.js';

import './Navbar.scss';

// ----------------------------------------------------------------------------|
//                             Redux Dispatch
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(logoutAction()),
});

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const Navbar = ({ onLogout }) => {
  const { TAB_DATA } = COLLECTION;
  const homeRoute = TAB_DATA[0].url;

  return (
    <nav className="navbar">
      <header className="nav-brand">
        <h1>
          <Link to={homeRoute}>
            Cobble <span>Admin panel</span>
          </Link>
        </h1>
      </header>
      {/* User profile section */}
      <div className="nav-actions">
        <Button onClick={onLogout} className="btn-inverse nav-btn">
          Logout
        </Button>
      </div>
    </nav>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
Navbar.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default connect(null, mapDispatchToProps)(Navbar);
