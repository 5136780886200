/**
 * ************************************
 *
 * @module  index.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description index file for the api endpoint constants
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import {
  ACTIVITY_BASE,
  ADMIN_BASE,
  ADMIN_UNITS_BASE,
  AUTH_BASE,
  CATEGORIES_BASE,
  CITIES_BASE,
  COLLECTION_BASE,
  CONTENT_REPORT_BASE,
  CURATED_CARD_BASE,
  FILTERS_BASE,
  MOVIE_BASE,
  RECIPE_BASE,
  PLACE_BASE,
  SEARCH_BASE,
  UNITS_BASE,
  UTILS_BASE,
} from 'API/endPoint';

// ----------------------------------------------------------------------------|
//                          URL API String Object
// ----------------------------------------------------------------------------|
const API = {
  activityDetail: `${ACTIVITY_BASE}/`,
  activityImage: `${ACTIVITY_BASE}/image`,
  activityList: `${ACTIVITY_BASE}/list`,
  appSetup: `${UTILS_BASE}/cms/setup`,
  categories: `${CATEGORIES_BASE}/`,
  cities: `${CITIES_BASE}`,
  citiesThirdPartySearch: `${CITIES_BASE}/3rd-parties/search`,
  collection: `${COLLECTION_BASE}/`,
  collectionImage: `${COLLECTION_BASE}/image`,
  contentReport: `${CONTENT_REPORT_BASE}/`,
  contentReportUpdate: `${CONTENT_REPORT_BASE}/update/`,
  curatedCard: `${CURATED_CARD_BASE}/`,
  curatedCardImage: `${CURATED_CARD_BASE}/image`,
  filters: `${FILTERS_BASE}/`,
  filtersList: `${FILTERS_BASE}/list`,
  filtersRules: `${FILTERS_BASE}/rules`,
  flagged: `${ADMIN_UNITS_BASE}/flagged`,
  login: `${AUTH_BASE}/login`,
  logout: `${AUTH_BASE}/logout`,
  neighborhoodLocations: `${ADMIN_BASE}/neighbourhood`,
  pairings: `${UNITS_BASE}/pairings`,
  place: `${PLACE_BASE}/`,
  placeImage: `${PLACE_BASE}/image`,
  placeUncurated: `${PLACE_BASE}/uncurated`,
  placeThirdPartySearch: `${PLACE_BASE}/3rd-parties/search`,
  placeThirdPartyDetails: `${PLACE_BASE}/3rd-parties/details`,
  movieDetail: `${MOVIE_BASE}/`,
  movieImage: `${MOVIE_BASE}/image`,
  movieList: `${MOVIE_BASE}/list`,
  movieSearch: `${MOVIE_BASE}/search`,
  movieThirdPartySearch: `${MOVIE_BASE}/3rd-parties/search`,
  movieThirdPartyDetails: `${MOVIE_BASE}/3rd-parties/details`,
  recipeDetail: `${RECIPE_BASE}/`,
  recipeImage: `${RECIPE_BASE}/image`,
  recipeList: `${RECIPE_BASE}/list`,
  reinstateUnit: `${ADMIN_UNITS_BASE}/reinstate/`,
  universalSearchList: `${SEARCH_BASE}`,
};

// ----------------------------------------------------------------------------|
//                        API String Constants Export
// ----------------------------------------------------------------------------|
export default API;
