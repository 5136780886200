/**
 * ************************************
 *
 * @module  ControledCheckbox.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Renders a reusable checkbox leveraging React-Hooks.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import 'components/ControledCheckbox/ControledCheckbox.scss';

// ----------------------------------------------------------------------------|
//                    React Function Component - ControledCheckbox
// ----------------------------------------------------------------------------|
/**
 * @description This is a functional component. Which is used to render a
 * checkbox.
 *
 * @param {Object} props
 *
 * @argument {Boolean} toBeChecked: true/false on if box should be checked
 * @argument {Function} onClick: function to fire onClick
 * @argument {String} label: label string for checkbox
 * @argument {String} children: optional text for checkbox
 * @argument {String} className: ClassName string for element
 * @argument {Boolean} fromRecurring: Bool used for firing useEffect() hook
 *
 * @returns {JSX} HTML components to be rendered to the DOM
 */

const ControledCheckbox = ({
  checkState,
  onClick,
  label,
  children,
  className,
}) => {

  /**
   * @description - dynamic classNames
   * Round is default classname, pass custom class names to overwrite the styles
   *
   */
  const CheckboxClass = cx(['round', `${className}`]);

  /**
   * @description - handles button click interaction
   *
   * @param {Object} e - DOM event object
   *
   * @returns {void}
   */
  const onClickHandler = (e) => {
    const { checked } = e.target;
    onClick(checked, label, e);
  };

  return (
    <div className="cob-checkbox">
      <div className={CheckboxClass}>
        <input
          id={label}
          data-name="checkbox"
          type="checkbox"
          onChange={onClickHandler}
          checked={checkState}
        />
        <label htmlFor={label} data-name="checkbox">
          <span className="label-text">{children}</span>
        </label>
      </div>
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                          PropTypes Check - ControledCheckbox
// ----------------------------------------------------------------------------|
ControledCheckbox.propTypes = {
  onClick: PropTypes.func.isRequired,
  toBeChecked: PropTypes.any,
  checkState: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

ControledCheckbox.defaultProps = {
  toBeChecked: null,
  checkState: false,
  label: '',
  children: null,
  className: null,
};

// ----------------------------------------------------------------------------|
//                              ControledCheckbox Export
// ----------------------------------------------------------------------------|
export default ControledCheckbox;
