/**
 * ************************************
 *
 * @module  EventDateSelectorModal.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description helps package up data for the backend
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                  Imports
// ---------------------------------------------------------------------------|
import moment from 'moment';

import { DATE_SELECTOR_POPUP } from 'constants.js';

import {
  REPEAT_MODE_OPTIONS as REPEAT_MODES,
  AVAILABILITY_OPTIONS,
} from '../EventDateSelector.constants';

const { DAILY } = REPEAT_MODES;
const { MONTHLY_ON_DATE, MONTHLY_WEEKDAY } = AVAILABILITY_OPTIONS;
const { TIME_FORMAT, TIME_FORMAT_FOR_BACKEND } = DATE_SELECTOR_POPUP;
export default class EventDateSelectorModel {
  constructor(_dataFromBackend = {}) {
    const {
      start_date,
      end_date,
      from_time,
      to_time,
      recurring_event,
      recurring_days,
      frequency_type,
      nth_day_in_month,
    } = _dataFromBackend;

    this.startDate = start_date ? moment(start_date) : moment();
    this.endDate = end_date ? moment(end_date) : null;
    this.fromTime = from_time
      ? moment(from_time, TIME_FORMAT_FOR_BACKEND).format(TIME_FORMAT)
      : '';
    this.toTime = to_time
      ? moment(to_time, TIME_FORMAT_FOR_BACKEND).format(TIME_FORMAT)
      : '';
    this.isRecurring = recurring_event || false;

    const frequencyKey = String(frequency_type).toUpperCase();
    this.repeatMode =
      frequencyKey in REPEAT_MODES ? REPEAT_MODES[frequencyKey] : DAILY;
    this.selectedWeekDays = recurring_days || [];

    if (frequency_type === 'monthly') {
      this.availability = nth_day_in_month ? MONTHLY_ON_DATE : MONTHLY_WEEKDAY;
    } else {
      this.availability = {};
    }
  }
}
