/**
 * ************************************
 *
 * @module  CuratedData.constants.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Data for the Curated Card card forms. Loops through and renders
 * the various form inputs and components
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import PropTypes from 'prop-types';
import { getPriceRange } from '../../utils/utils';
import { LONG_DESC_ROWS, ONE_LINER_DESC_ROWS } from '../../constants';

// ----------------------------------------------------------------------------|
//                        CuratedData - Array of Objects
// ----------------------------------------------------------------------------|
const CuratedData = [
  {
    type: 'text',
    name: 'name',
    label: 'Curated Card Title',
    value: '',
    errorMsg: 'Title should be min 1 and max 100 characters long',
  },
  {
    type: 'dropdown',
    label: 'Price Range',
    name: 'price_tier',
    values: getPriceRange(),
    value: '',
    errorMsg: 'Price Range cannot be empty',
  },
  {
    type: 'tag',
    label: 'Category',
    name: 'categories',
    tags: [],
    suggestions: [],
    errorMsg: 'Type cannot be empty',
  },
  {
    type: 'textarea',
    name: 'long_description',
    label: 'Long Description',
    value: '',
    errorMsg: 'Long Description should be min 5 and max 50 characters long',
    rows: LONG_DESC_ROWS,
  },
  {
    type: 'textarea',
    name: 'one_liner_description',
    label: 'One-liner Description',
    value: '',
    errorMsg:
      'One liner description should be min 5 and max 50 characters long',
    rows: ONE_LINER_DESC_ROWS,
  },
  {
    type: 'tag',
    label: 'Neighbourhood',
    name: 'neighborhoods',
    tags: [],
    suggestions: [],
    errorMsg: 'Neighborhood cannot be empty',
  },
];

// ----------------------------------------------------------------------------|
//                               PropTypes Check
// ----------------------------------------------------------------------------|
CuratedData.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default CuratedData;
