/**
 * ************************************
 *
 * @module Events.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Events container
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import moment from 'moment';

import { connect } from 'react-redux';
import {
  getEventsListAction,
  clearListAction,
  deleteEventAction,
  performBulkOperationAction,
  filterEventsListAction,
} from 'store/actions/~depreciated/Events.actions';

import {
  fetchAdminCategoriesAction,
  fetchNeighborhoodLocationsAction,
} from 'store/actions/Places.action';

import { Event } from 'containers';

import { EVENTS, GENERIC, PLACE, DATE_FORMATS } from 'constants.js';

import {
  CardBanner,
  CardViewModal,
  DeleteModal,
  Loader,
  NoDataFound,
  FilterSection,
  TotalCount,
  Tabs,
  WidgetBox,
} from 'components';

import {
  getPartsFromUrl,
  checkHasMore,
  getSelectedCardCount,
  getCardReviewStatus,
  askForCloseConfirmation,
  getPriceRange as formPriceOptions,
} from 'utils/utils';

import {
  FilterType,
  formGroupOptions,
} from 'components/FilterSection/FiltersFactory';

import CommonService from 'services/CommonService';
import EventContent from './EventContent/EventContent';

import './Events.scss';

// ----------------------------------------------------------------------------|
//                            Property Mapping
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  getEventsList: (data) => dispatch(getEventsListAction(data)),
  clearEventList: () => dispatch(clearListAction()),
  fetchAdminCategories: () => dispatch(fetchAdminCategoriesAction()),
  fetchNeighborhoodLocations: () =>
    dispatch(fetchNeighborhoodLocationsAction()),
  deleteEvent: (id) => dispatch(deleteEventAction(id)),
  performBulkOperation: (data) => dispatch(performBulkOperationAction(data)),
  filterEventsAction: (data) => dispatch(filterEventsListAction(data)),
});

const mapStateToProps = (state) => ({
  events: state.events,
  places: state.places,
});

// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|
const { WARNING_ON_DELETE } = EVENTS;

const commonService = CommonService();

// ----------------------------------------------------------------------------|
//                      Events React Component
// ----------------------------------------------------------------------------|
class Events extends Component {
  // --------------------------------------------------------------------------|
  //                          PropTypes Check
  // --------------------------------------------------------------------------|
  static propTypes = {
    getEventsList: PropTypes.func.isRequired,
    clearEventList: PropTypes.func.isRequired,
    deleteEvent: PropTypes.func.isRequired,
    events: PropTypes.object.isRequired,
    performBulkOperation: PropTypes.func.isRequired,
    fetchAdminCategories: PropTypes.func.isRequired,
    fetchNeighborhoodLocations: PropTypes.func.isRequired,
    status: PropTypes.string,
    dismissModal: PropTypes.func,
    hideExtras: PropTypes.bool,
    places: PropTypes.object.isRequired,
    filterEventsAction: PropTypes.func.isRequired,
    location: PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      action: PropTypes.string,
      block: PropTypes.func,
      createHref: PropTypes.func,
      go: PropTypes.func,
      goBack: PropTypes.func,
      goForward: PropTypes.func,
      length: PropTypes.number,
      listen: PropTypes.func,
      location: PropTypes.shape({
        hash: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
      }),
      push: PropTypes.func,
      replace: PropTypes.func,
    }).isRequired,
  };

  // --------------------------------------------------------------------------|
  //                                Default Props
  // --------------------------------------------------------------------------|
  static defaultProps = {
    status: '',
    hideExtras: false,
    dismissModal: () => {},
  };

  // --------------------------------------------------------------------------|
  //                              State - Events
  // --------------------------------------------------------------------------|
  constructor() {
    super();

    this.state = {
      currentTab: 'published',
      hasMore: true,
      newEventData: null,
      deleteModalData: null,
      bulkDeleteModal: false,
      category: [],
      rating: [],
      neighborhood: [],
      price: [],
      reviewStatus: '',
      dateRange: undefined,
      query: '',
      selectedCards: [],
      isChecked: null,
      openPlaceID: '',
    };
  }

  // --------------------------------------------------------------------------|
  //                            LifeCycle Methods
  // --------------------------------------------------------------------------|
  componentDidMount() {
    this.setCurrentTab();

    const {
      places,
      fetchAdminCategories,
      fetchNeighborhoodLocations,
      history,
    } = this.props;

    // Fetch adminCategories, neighborhoodLocations only if they are not present
    if (!places.adminCategories.length) {
      fetchAdminCategories();
    }

    if (!places.neighborhoodLocations.length) {
      fetchNeighborhoodLocations();
    }

    this.unlisten = history.listen((location, action) => {
      // this.handleIDAnchor();
      this.handleIDPathname();
    });
  }

  componentDidUpdate(prevProps) {
    const { location, events } = this.props;

    if (prevProps.location.pathname !== location.pathname) {
      this.setCurrentTab();
    }

    if (prevProps.events.data.length !== events.data.length) {
      this.setHasMore();
    }

    if (events.isInitialDataLoaded) {
      // this.handleIDAnchor();
      this.handleIDPathname();
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  // --------------------------------------------------------------------------|
  //                        Class Component Methods
  // --------------------------------------------------------------------------|
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  //                   DB Call Methods
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  /**
   * @description Handle presence of DB id in URL: redirect user to relevant
   * content
   */
  handleIDPathname = () => {
    const { location, events } = this.props;
    const { openPlaceID } = this.state;
    const { id } = commonService.pathnameHelper(location.pathname);

    if (id) {
      if (openPlaceID !== id) {
        if (openPlaceID) {
          this.setState({ newEventData: null, openPlaceID: '' });
        } else {
          this.setState({ openPlaceID: id });

          let eventData;

          // searches for already fetched cards
          for (let i = 0; i < events.data.length; i += 1) {
            if (events.data[i].id === id) {
              eventData = events.data[i];
              break;
            }
          }

          // if it finds it, load it
          if (eventData) {
            this.setState({ newEventData: eventData });
          } else {
            // if not, open a new modal and have it fetch the id
            this.setState({
              newEventData: {
                id,
                shouldFetchEvent: true,
                shouldDisplayLoader: true,
              },
            });
          }
        }
      }
    } else if (openPlaceID) {
      this.setNewEventData(null);
    }
  };

  /**
   * @description sets state on if more cards are available
   * checkHasMore is imported from the common utils/utils file
   */
  setHasMore = () => {
    const { events } = this.props;
    const { data, count } = events;
    const hasMore = checkHasMore(data.length, count);

    this.setState({ hasMore });
  };

  /**
   * @description constructs a request object which is sent to Events.saga
   * and ultimately requests a response based on state
   *
   * @returns {Object} - request object sent to Events.saga
   */
  constructRequestObject = () => {
    const { events } = this.props;
    const {
      currentTab,
      category,
      neighborhood,
      query,
      price,
      dateRange,
      rating,
      reviewStatus,
    } = this.state;
    const { pageNum: page } = events;

    // Rating doubled for temporary issue with mapping of 1-5 => 1-10
    // Need to be changed later with proper contract from BE.
    const ratings = rating.map(({ value: rating }) =>
      rating === '1' ? rating : rating * 2
    );

    const status = currentTab === 'drafts' ? 'draft' : currentTab;

    let datetime_min;
    let datetime_max;

    if (dateRange) {
      const { isoDateTime } = DATE_FORMATS;

      datetime_min = moment(dateRange.start).format(isoDateTime);
      datetime_max = moment(dateRange.end).endOf('day').format(isoDateTime);
    }

    return {
      status,
      page,
      query,
      review_status: reviewStatus,
      cat_unique_slug_list: category.map(({ value }) => value),
      rating: ratings,
      prices: price.map(({ value }) => value),
      nh_unique_slugs: neighborhood.map(({ value }) => value),
      datetime_min,
      datetime_max,
    };
  };

  /**
   * @description constructs a request object and invokes getEventsList
   * (passed from redux) which is sent to Events.saga and ultimately
   * requests a response based on state
   */
  getEventsData = () => {
    const { getEventsList } = this.props;
    const request = this.constructRequestObject();

    getEventsList(request);
  };

  /**
   * @description sets the current horizontal tab by invoking the
   * getPartsFromUrl function imported from utils/utils based on
   * the second part of the current route.
   */
  setCurrentTab = () => {
    const { location, clearEventList } = this.props;

    const { secondPart: currentTab } = getPartsFromUrl(location.pathname);

    clearEventList();
    this.dismissWidget();

    this.setState(
      { currentTab, newEventData: null, openPlaceID: '' },
      this.getEventsData
    );
  };

  /**
   * @description requests more data after setting states hasMore prop to false.
   */
  getMoreData = () => this.setState({ hasMore: false }, this.getEventsData);

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  //                  Filter Methods
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  /**
   * @description Fires when filter options are selected
   *
   * @param {String} returnType - string for data type
   * @param {Array} data - of object  data to set to state
   * @param {Function} callback - to fire after state update
   */
  onFilterSelect = (returnType, data, callback = () => {}) => {
    let selectedData = data;
    // prevents a bug with null being passed when deleting filter options
    if (
      (returnType === 'category' ||
        returnType === 'neighborhood' ||
        returnType === 'price') &&
      selectedData === null
    )
      selectedData = [];

    if (returnType === 'reviewStatus') {
      if (data.value) selectedData = data.value;
      else selectedData = '';
    }

    // API call to filter cards based on value selected from dropdown
    this.setState({ [returnType]: selectedData }, callback);
  };

  /**
   * @description Fires when filter menu closes.
   * constructs a request object then invokes the redux action
   */
  onFilterMenuClosed = () => {
    const { filterEventsAction } = this.props;

    const request = this.constructRequestObject();

    filterEventsAction(request);
  };

  /**
   * @description clears away filters by invoking the redux
   * clearEventList function, resetting the states 'filtering'
   * properties, then requests a fresh GET request from the
   * backend via getEventsData
   */
  clearFilters = () => {
    const { clearEventList } = this.props;

    clearEventList();

    this.setState(
      {
        category: [],
        neighborhood: [],
        dateRange: undefined,
        price: [],
        reviewStatus: '',
      },
      this.getEventsData
    );
  };

  /**
   * @description sets state and fires a GET request for a search bar
   * quest (aka: input). First clears the list, dismisses the widget,
   * then setsState with the query string before firing a getActivitiesData req
   *
   * @param {String} query - string for the query search
   */
  searchEvent = (query) => {
    const { clearEventList } = this.props;

    clearEventList();
    this.dismissWidget();

    this.setState({ query }, this.getEventsData);
  };

  /**
   * @description fires when the modal window for editing an event
   * is closed. Used for pushing the correct history to Routers
   * history object, dismissing the widget then setting the tab.
   */
  onCloseModal = () => {
    const { location, history } = this.props;

    const { section, tab, id } = commonService.pathnameHelper(
      location.pathname
    );

    if (id) {
      history.push(`/${section}/${tab}`);
    }

    this.dismissWidget();
    this.setCurrentTab();
  };

  /**
   * @description sets the state with a new event data object for editing,
   * Fires on clicking of a card or the add new button
   *
   * @param {Object} newEventData - Object of new event data to
   * be set to the state object
   */
  setNewEventData = (newEventData) => {
    const { location, history } = this.props;
    const { section, tab, id } = commonService.pathnameHelper(
      location.pathname
    );

    // prevents infinite loop with id/endpoint rendering
    if (id) {
      history.push(`/${section}/${tab}`);
    }

    this.setState({ newEventData, openPlaceID: '' }, this.onSaveEvents);
  };

  /**
   * @description fires when you click a card, type of action depends on the
   * string value passed.
   *
   * @param {String} value - String specifying the action (Edit/Delete/Etc..)
   * @param {Object} data - Object of activity data
   */
  onMenuClicked = (value, data) => {
    const { location, history } = this.props;
    const {
      SUB_MENU: { DELETE, EDIT },
    } = GENERIC;

    const { tab } = commonService.pathnameHelper(location.pathname);
    const { id } = data;

    if (value === EDIT) {
      history.push(`${tab}/${id}`);
    }

    if (value === DELETE) {
      this.setState({ deleteModalData: id });
    }
  };

  /**
   * @description fires when deleting an event from the list
   */
  deleteEventFromList = () => {
    const { deleteEvent } = this.props;
    const { deleteModalData } = this.state;

    this.closeDeleteModalData();
    deleteEvent(deleteModalData);
  };

  /**
   * @description resets deleteModalData property to null
   */
  closeDeleteModalData = () => this.setState({ deleteModalData: null });

  /**
   * @description sets bulkDeleteModal property to false
   */
  closeBulkDeleteModal = () => this.setState({ bulkDeleteModal: false });

  /**
   * @description triggers bulk delete from the List of cards
   */
  bulkDeleteFromList = () => {
    const {
      SUB_MENU: { DELETE },
    } = GENERIC;

    this.closeBulkDeleteModal();
    this.performBulkOperation(DELETE);
  };

  /**
   * @description updates selected cards based on toggle
   *
   * @param {Object} prevState
   * @param {Boolean} currentCheckValue - toggles if card should be included
   * @param {String} cardId- string with unique cardId
   */
  updateSelectCards = (prevState, currentCheckValue, cardId) => {
    let selectedCards = [...prevState.selectedCards];

    if (currentCheckValue) {
      selectedCards.push(cardId);
    } else {
      selectedCards = selectedCards.filter((id) => id !== cardId);
    }

    const tempState = { selectedCards };

    if (typeof prevState.isChecked === 'boolean') {
      tempState.isChecked = null;
    }
    return tempState;
  };

  /**
   * @description toggles what is included in the selectedCards state property
   *
   * @param {Boolean} currentCheckValue - toggles if card should be included
   * @param {String} cardId- string with unique cardId
   */
  onCheckBoxSelected = (currentCheckValue, cardId) => {
    this.setState((prevState) =>
      this.updateSelectCards(prevState, currentCheckValue, cardId)
    );
  };

  /**
   * @description deselects all cards by setting state property isChecked
   * to false
   */
  deselectAllCards = () => this.setState({ isChecked: false });

  /**
   * @description resets widget to initial state
   */
  dismissWidget = () => {
    this.setState({ selectedCards: [], isChecked: null });
  };

  /**
   * @description redux helper for correct action
   *
   * @argument {Object} actions - with action types
   */
  getRelevantActions = (actions) => {
    const { status } = this.props;
    const { currentTab } = this.state;

    if (status === 'published') {
      return [actions.SELECT];
    }

    if (currentTab) {
      switch (currentTab) {
        case 'published':
          return [actions.DELETE, actions.DRAFT];
        case 'drafts':
          return [actions.DELETE, actions.PUBLISH];
        case 'expired':
          return [actions.DELETE];
        default:
          return [];
      }
    }

    return [];
  };

  /**
   * @description performs a bulk operation depending on type
   * string passed
   *
   * @argument {String} type - type of bulk operation
   */
  performBulkOperation = (type) => {
    const { dismissModal, performBulkOperation } = this.props;
    const { selectedCards } = this.state;

    const {
      BULK_ACTIONS: { DELETE, PUBLISH, DRAFT, SELECT },
    } = GENERIC;
    if (type === SELECT) {
      // This saves selected cards to redux
      dismissModal();
      // check places to add event for events
    } else {
      const data = {
        delete: type === DELETE,
        id_list: selectedCards,
      };

      if (type === PUBLISH) {
        data.status = 'published';
      }

      if (type === DRAFT) {
        data.status = 'draft';
      }

      performBulkOperation(data);
    }
  };

  /**
   * @description gets filter for events
   */
  getFiltersForEvents = () => {
    const { places } = this.props;
    const {
      price,
      category,
      dateRange,
      neighborhood,
      reviewStatus,
    } = this.state;
    const { adminCategories, neighborhoodLocations, placeCategories } = places;
    const {
      FILTER_DROP_DOWN: { LOCATION, PRICE, TYPE, DATE_RANGE, REVIEW_STATUS },
    } = PLACE;

    return [
      {
        name: LOCATION.LABEL,
        returnType: LOCATION.RETURN_TYPE,
        options: formGroupOptions(neighborhoodLocations, 'neighborhoods'),
        value: neighborhood,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.onFilterMenuClosed,
        isSearchable: true,
      },
      {
        name: DATE_RANGE.LABEL,
        returnType: DATE_RANGE.RETURN_TYPE,
        value: dateRange,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.DATE_RANGE_PICKER,
        onMenuClose: this.onFilterMenuClosed,
      },
      {
        name: PRICE.LABEL,
        returnType: PRICE.RETURN_TYPE,
        options: formPriceOptions(),
        value: price,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.onFilterMenuClosed,
      },
      {
        name: TYPE.LABEL,
        returnType: TYPE.RETURN_TYPE,
        options: formGroupOptions(
          placeCategories,
          'categories',
          'unique_slug',
          'name'
        ),
        value: category,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.onFilterMenuClosed,
        isSearchable: true,
      },
      {
        name: REVIEW_STATUS.LABEL,
        returnType: REVIEW_STATUS.RETURN_TYPE,
        options: getCardReviewStatus(),
        value: reviewStatus,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.SELECT,
        onMenuClose: this.onFilterMenuClosed,
        isSearchable: false,
      },
    ];
  };

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  //                Events rendering
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  render() {
    const {
      hasMore,
      deleteModalData,
      query,
      newEventData,
      selectedCards,
      isChecked,
      bulkDeleteModal,
      currentTab,
    } = this.state;
    const { hideExtras } = this.props;
    const { data, count, isInitialDataLoaded } = this.props.events;
    const { TITLE, TAB_DATA, CARD_VIEW_TITLE, CARD_VIEW_TITLE_EDIT } = EVENTS;
    const {
      BULK_ACTIONS: { SELECT, DELETE, PUBLISH, DRAFT },
      CLOSE_CARD_WARNING_MESSAGE,
    } = GENERIC;

    const filterSectionUI = (
      <FilterSection
        filters={this.getFiltersForEvents()}
        searchValue={query}
        onSearchInput={this.searchEvent}
        clearFilters={this.clearFilters}
      />
    );

    let content = <Loader />;
    const pageTitle =
      newEventData && newEventData.id ? CARD_VIEW_TITLE_EDIT : CARD_VIEW_TITLE;
    if (isInitialDataLoaded) {
      if (count === 0) {
        content = <NoDataFound />;
      } else {
        content = (
          <Fragment>
            <TotalCount current={data.length} total={count} />
            <EventContent
              data={data}
              hasMore={hasMore}
              onMoreData={this.getMoreData}
              onMenuClicked={this.onMenuClicked}
              onCheckBoxSelected={this.onCheckBoxSelected}
              isChecked={isChecked}
              hideMenu={hideExtras}
            />
          </Fragment>
        );
      }
    }

    // Collection of all possible actions in bulk actions widget
    const widgetActions = {
      DELETE: {
        label: DELETE,
        onClick: () => {
          this.setState({ bulkDeleteModal: true });
        },
        className: 'btn-inverse',
      },
      PUBLISH: {
        label: PUBLISH,
        onClick: () => {
          this.performBulkOperation(PUBLISH);
        },
      },
      DRAFT: {
        label: DRAFT,
        onClick: () => {
          this.performBulkOperation(DRAFT);
        },
      },
      SELECT: {
        label: SELECT,
        onClick: () => {
          this.performBulkOperation(SELECT);
        },
      },
    };

    const selectionWidget = selectedCards.length !== 0 && (
      <WidgetBox
        className="events-widget"
        data={this.getRelevantActions(widgetActions)}
        onDeselectClicked={this.deselectAllCards}
        actionText={getSelectedCardCount(selectedCards)}
        showStatus
      />
    );
    return (
      <div className="events-container">
        <div className="events-content">
          {newEventData === null ? (
            <Fragment>
              {!hideExtras && (
                <Fragment>
                  <CardBanner
                    title={TITLE}
                    newBtnClick={() => this.setNewEventData({})}
                  />
                  <Tabs data={TAB_DATA} active="published" />
                </Fragment>
              )}
              {filterSectionUI}
              {content}
              {deleteModalData !== null && (
                <DeleteModal
                  cancelClick={this.closeDeleteModalData}
                  deleteClick={this.deleteEventFromList}
                  additionalMessage={WARNING_ON_DELETE}
                />
              )}
              {bulkDeleteModal && (
                <DeleteModal
                  cancelClick={this.closeBulkDeleteModal}
                  deleteClick={this.bulkDeleteFromList}
                />
              )}
            </Fragment>
          ) : (
            <CardViewModal
              title={pageTitle}
              modalClosed={() => askForCloseConfirmation(this.onCloseModal)}
            >
              {/* <Prompt when message={CLOSE_CARD_WARNING_MESSAGE} /> */}
              <Event
                data={newEventData}
                closeModal={() => this.onCloseModal()}
                currentTab={currentTab}
              />
            </CardViewModal>
          )}
        </div>
        {selectionWidget}
      </div>
    );
  }
}

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Events));
