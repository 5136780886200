/**
 * ************************************
 *
 * @module  CollectionService.js
 * @author  Matt P
 * @date    02/01/2021
 * @description File to prepare Collection form
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import { getProcessedNeighborhoods } from 'utils/utils';

// ----------------------------------------------------------------------------|
//                             Collection Service
// ----------------------------------------------------------------------------|
const CollectionService = {
  /**
   * @description processes the vertical specific data that is incoming from
   * the backend to populate an edit form.
   *
   * @param {Object} dataObj - of date data
   * @param {Object} additionalOptions - any additional data that may be
   * required to process incoming data
   *
   * @returns {Object}
   */
  processVerticalSpecificData(dataObj = {}, additionalOptions = {}) {
    const { cards, is_pinned, units_count, title, location_areas } = dataObj;

    return {
      title: title || '',
      cards: cards || [],
      is_pinned,
      units_count,
      neighborhoods: location_areas
        ? getProcessedNeighborhoods(location_areas)
        : [],
    };
  },

  /**
   * @description preps the vertical specific form data to be passed in the
   * request body for a form update
   *
   * @param {Object} formData - formData
   * @param {Object} additionalOptions - any additional data that may be
   * required to process outgoing data
   *
   * @returns {Object} for requestBody
   */
  prepareVerticalSpecificFormData(formData = {}, additionalOptions = {}) {
    const { selectedCards } = additionalOptions;
    let pinnedUnitsPrettyIDs = [];
    selectedCards.forEach((card) => {
      if (card.is_pinned) {
        pinnedUnitsPrettyIDs.push(card.pretty_id);
      }
    });
    return {
      collection_units_pretty_ids: selectedCards.map((card) => card.pretty_id),
      pinned_units_pretty_ids: pinnedUnitsPrettyIDs,
    };
  },

  /**
   * @description extra validation for the card selection modal.
   * Checks if any selected cards for a collection are either
   * expired or not published
   *
   * @param {Object[]} cards - formData
   *
   * @returns {Boolean}
   */
  validatePublishedCards(cards = []) {
    return cards.every(
      (card) => card.status === 'published' && !card.is_expired
    );
  },

  /**
   * @description extra validation for the card selection modal.
   * Checks if any selected cards for a collection are either
   * expired or not published and that the selected cards are more than
   * two
   *
   * @param {Object[]} cards - formData
   *
   * @returns {Boolean}
   */
  validateSelectedCards(cards = []) {
    return this.validatePublishedCards(cards) && cards.length >= 2;
  },

  /**
   * @description error checking for 'publish' form collections
   * specific submission
   *
   * @param {Object} formData - formData
   * @param {Object} additionalOptions - any additional data that may be
   * required to validate
   *
   * @returns {Object} of errors
   */
  validate(formData, additionalOptions = {}) {
    const { title } = formData;
    const { selectedCards } = additionalOptions;

    const errorsObj = {};

    const validateCards = (cards = []) => cards.length <= 1;

    const validatePublishedCards = (cards = []) =>
      cards.every((card) => card.status === 'published' && !card.is_expired);

    if (selectedCards && validateCards(selectedCards)) {
      errorsObj.selected_cards =
        'Please select at least two cards for this collection';
    }

    if (selectedCards && validatePublishedCards(selectedCards)) {
      errorsObj.selected_cards =
        'You can not include expired cards in a collection';
    }

    if (selectedCards && selectedCards.length <= 1) {
      errorsObj.selected_cards =
        'You must select at least 2 cards for a collection';
    }

    if (!title || title.length < 1 || title.length > 40) {
      errorsObj.title = 'Collection titles are limited to 40 characters';
    }

    return errorsObj;
  },
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default CollectionService;
