/**
 * ************************************
 *
 * @module  endPoint.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description api endpoint constants
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import CONFIG from 'config/config';

// ----------------------------------------------------------------------------|
//                               Base URL
// ----------------------------------------------------------------------------|
const { BASE_URL } = CONFIG;

// ----------------------------------------------------------------------------|
//                          API String Exports
// ----------------------------------------------------------------------------|
const API_VERSION = 'v1';

export const API_BASE = `${BASE_URL}/api/${API_VERSION}`;

export const ADMIN_BASE = `${API_BASE}/admin`;

export const ADMIN_UNITS_BASE = `${ADMIN_BASE}/units`;

export const ACTIVITY_BASE = `${ADMIN_BASE}/activity`;

export const AUTH_BASE = `${ADMIN_BASE}/auth`;

export const CATEGORIES_BASE = `${ADMIN_BASE}/category`;

export const CITIES_BASE = `${ADMIN_BASE}/neighborhood`;

export const COLLECTION_BASE = `${ADMIN_BASE}/collection`;

export const CONTENT_REPORT_BASE = `${API_BASE}/report`;

export const CURATED_CARD_BASE = `${ADMIN_BASE}/card`;

export const FILTERS_BASE = `${ADMIN_BASE}/filters`;

export const MOVIE_BASE = `${ADMIN_BASE}/movie`;

export const PLACE_BASE = `${ADMIN_BASE}/place`;

export const RECIPE_BASE = `${ADMIN_BASE}/recipe`;

export const SEARCH_BASE = `${ADMIN_BASE}/search`;

export const UNITS_BASE = `${API_BASE}/units`;

export const UTILS_BASE = `${API_BASE}/utils`;
