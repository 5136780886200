/**
 * ************************************
 *
 * @module  rootSaga.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description root file for redux sagas.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                    Imports
// ----------------------------------------------------------------------------|
import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import ACTIONS from 'store/actions/actionTypes';

import { login, logout } from 'store/sagas/Auth.saga';

import {
  createCategory,
  deleteCategory,
  editCategory,
  getCategoryList,
  toggleEnable as categoriesToggleEnable,
  toggleFeature as categoriesToggleFeature,
} from 'store/sagas/Categories.saga';

import {
  createCity,
  deleteCity,
  editCity,
  getCitiesList,
  toggleEnable as citiesToggleEnable,
} from 'store/sagas/Cities.saga';

import {
  fetchCuratedCardDetail,
  getCardListData,
  saveData as saveDataCard,
  uploadImage as uploadImageCard,
} from 'store/sagas/CuratedCard.saga';

import {
  deleteCuratedCard,
  filterCuratedCardsList,
  getCuratedList,
  performBulkOperationOnCurated,
} from 'store/sagas/CuratedCards.saga';

import {
  getFilterList,
  getFilterRulesList,
  createFilter,
  editFilter,
  deleteFilter,
  toggleEnable as filtersToggleEnable,
  toggleFeature as filtersToggleFeature,
} from 'store/sagas/Filters.saga';

import { fetchPlace, saveData, uploadImage } from 'store/sagas/Place.saga';

import {
  deletePlace,
  fetchAdminCategories,
  fetchNeighborhoodLocations,
  filterPlacesList,
  getPlaceList,
  performBulkOperationOnPlace,
} from 'store/sagas/Places.saga';

import {
  getVerticalDisplayCards,
  deleteCard,
  fetchNeighborhoodLocations as fetchVerticalNeighborhoodLocations,
  filterCardsList,
  updateSingleCardProperty,
} from 'store/sagas/VerticalDisplay.saga';

import {
  getCardSelectionModalCards,
  fetchVerticalFormDetail,
  reinstateCard,
  saveData as saveVerticalCardDetail,
  uploadImage as uploadCardDetailImage,
} from 'store/sagas/VerticalForm.saga';

// ----------------------------------------------------------------------------|
//                          DEPRECIATED IMPORTS
// ----------------------------------------------------------------------------|
import {
  fetchActivity,
  saveData as saveDataActivity,
  uploadImage as uploadImageActivity,
} from 'store/sagas/~depreciated/Activity.saga';

import {
  saveData as eventSaveData,
  uploadImage as eventUploadImage,
} from 'store/sagas/~depreciated/Event.saga';

import {
  deleteEvent,
  filterEventsList,
  getEventsList,
  performBulkOperationOnEvents,
} from 'store/sagas/~depreciated/Events.saga';

import {
  fetchMovie,
  saveData as movieSaveData,
  uploadImage as movieUploadImage,
} from 'store/sagas/~depreciated/Movie.saga';

import {
  deleteMovie,
  filterMoviesList,
  getMovieList,
  performBulkOperationOnMovie,
} from 'store/sagas/~depreciated/Movies.saga';

import {
  fetchRecipe,
  saveData as recipeSaveData,
  uploadImage as recipeUploadImage,
} from 'store/sagas/~depreciated/Recipe.saga';

import {
  deleteRecipe,
  filterRecipesList,
  getRecipesList,
  performBulkOperationOnRecipe,
} from 'store/sagas/~depreciated/Recipes.saga';

const rootSaga = () =>
  all([
    takeEvery(ACTIONS.AUTH.LOGIN, login),
    takeEvery(ACTIONS.AUTH.LOGOUT, logout),

    takeEvery(ACTIONS.CATEGORIES.CREATE_CATEGORY, createCategory),
    takeEvery(ACTIONS.CATEGORIES.DELETE_CATEGORY, deleteCategory),
    takeEvery(ACTIONS.CATEGORIES.EDIT_CATEGORY, editCategory),
    takeEvery(ACTIONS.CATEGORIES.GET_LIST, getCategoryList),
    takeEvery(ACTIONS.CATEGORIES.TOGGLE_ENABLE, categoriesToggleEnable),
    takeEvery(ACTIONS.CATEGORIES.TOGGLE_FEATURE, categoriesToggleFeature),

    takeEvery(ACTIONS.CITIES.CREATE_CITY, createCity),
    takeEvery(ACTIONS.CITIES.DELETE_CITY, deleteCity),
    takeEvery(ACTIONS.CITIES.EDIT_CITY, editCity),
    takeEvery(ACTIONS.CITIES.GET_LIST, getCitiesList),
    takeEvery(ACTIONS.CITIES.TOGGLE_ENABLE, citiesToggleEnable),

    takeEvery(ACTIONS.CURATED_CARD.FETCH_CURATED_CARD, fetchCuratedCardDetail),
    takeLatest(ACTIONS.CURATED_CARD.GET_CARD_LIST, getCardListData),
    takeEvery(ACTIONS.CURATED_CARD.SAVE_DATA, saveDataCard),
    takeEvery(ACTIONS.CURATED_CARD.UPLOAD_IMAGE, uploadImageCard),

    takeEvery(ACTIONS.CURATED_CARDS.BULK_ACTION, performBulkOperationOnCurated),
    takeEvery(ACTIONS.CURATED_CARDS.DELETE_CURATED_CARDS, deleteCuratedCard),
    takeEvery(ACTIONS.CURATED_CARDS.FILTER_LIST, filterCuratedCardsList),
    takeLatest(ACTIONS.CURATED_CARDS.GET_LIST, getCuratedList),

    takeEvery(ACTIONS.FILTERS.CREATE_FILTER, createFilter),
    takeEvery(ACTIONS.FILTERS.DELETE_FILTER, deleteFilter),
    takeEvery(ACTIONS.FILTERS.EDIT_FILTER, editFilter),
    takeEvery(ACTIONS.FILTERS.GET_LIST, getFilterList),
    takeEvery(ACTIONS.FILTERS.GET_RULE_LIST, getFilterRulesList),
    takeEvery(ACTIONS.FILTERS.TOGGLE_ENABLE, filtersToggleEnable),
    takeEvery(ACTIONS.FILTERS.TOGGLE_FEATURE, filtersToggleFeature),

    takeEvery(ACTIONS.PLACE.FETCH_PLACE, fetchPlace),
    takeEvery(ACTIONS.PLACE.SAVE_DATA, saveData),
    takeEvery(ACTIONS.PLACE.UPLOAD_IMAGE, uploadImage),

    takeEvery(ACTIONS.PLACES.BULK_ACTION, performBulkOperationOnPlace),
    takeEvery(ACTIONS.PLACES.DELETE_PLACE, deletePlace),
    takeEvery(ACTIONS.PLACES.FETCH_ADMIN_CATEGORIES, fetchAdminCategories),
    takeEvery(
      ACTIONS.PLACES.FETCH_NEIGHBORHOOD_LOCATIONS,
      fetchNeighborhoodLocations
    ),
    takeEvery(ACTIONS.PLACES.FILTER_LIST, filterPlacesList),
    takeLatest(ACTIONS.PLACES.GET_PLACES, getPlaceList),

    takeEvery(ACTIONS.VERTICAL_DISPLAY.DELETE_CARD, deleteCard),
    takeEvery(ACTIONS.VERTICAL_DISPLAY.FILTER_LIST, filterCardsList),
    takeEvery(
      ACTIONS.VERTICAL_DISPLAY.FETCH_NEIGHBORHOOD_LOCATIONS,
      fetchVerticalNeighborhoodLocations
    ),
    takeLatest(
      ACTIONS.VERTICAL_DISPLAY.GET_VERTICAL_DISPLAY_CARDS,
      getVerticalDisplayCards
    ),
    takeLatest(
      ACTIONS.VERTICAL_DISPLAY.UPDATE_SINGLE_CARD_PROPERTY,
      updateSingleCardProperty
    ),

    takeEvery(
      ACTIONS.VERTICAL_FORM.FETCH_VERTICAL_FORM_DETAIL,
      fetchVerticalFormDetail
    ),
    takeEvery(ACTIONS.VERTICAL_FORM.SAVE_DATA, saveVerticalCardDetail),
    takeEvery(ACTIONS.VERTICAL_FORM.UPLOAD_IMAGE, uploadCardDetailImage),
    takeEvery(ACTIONS.VERTICAL_FORM.REINSTATE_UNIT, reinstateCard),
    takeLatest(
      ACTIONS.VERTICAL_FORM.GET_VERTICAL_CARD_SELECTION_CARDS,
      getCardSelectionModalCards
    ),

    // ------------------------------------------------------------------------|
    //                          DEPRECIATED
    // ------------------------------------------------------------------------|
    takeEvery(ACTIONS.ACTIVITY.FETCH_ACTIVITY, fetchActivity),
    takeEvery(ACTIONS.ACTIVITY.SAVE_DATA, saveDataActivity),
    takeEvery(ACTIONS.ACTIVITY.UPLOAD_IMAGE, uploadImageActivity),

    takeEvery(ACTIONS.EVENT.SAVE_DATA, eventSaveData),
    takeEvery(ACTIONS.EVENT.UPLOAD_IMAGE, eventUploadImage),

    takeEvery(ACTIONS.EVENTS.BULK_ACTION, performBulkOperationOnEvents),
    takeEvery(ACTIONS.EVENTS.DELETE_EVENT, deleteEvent),
    takeEvery(ACTIONS.EVENTS.FILTER_LIST, filterEventsList),
    takeLatest(ACTIONS.EVENTS.GET_LIST, getEventsList),

    takeEvery(ACTIONS.MOVIE.FETCH_MOVIE, fetchMovie),
    takeEvery(ACTIONS.MOVIE.SAVE_DATA, movieSaveData),
    takeEvery(ACTIONS.MOVIE.UPLOAD_IMAGE, movieUploadImage),

    takeEvery(ACTIONS.MOVIES.BULK_ACTION, performBulkOperationOnMovie),
    takeEvery(ACTIONS.MOVIES.DELETE_MOVIE, deleteMovie),
    takeEvery(ACTIONS.MOVIES.FILTER_LIST, filterMoviesList),
    takeLatest(ACTIONS.MOVIES.GET_MOVIES_LIST, getMovieList),

    takeEvery(ACTIONS.RECIPE.FETCH_RECIPE, fetchRecipe),
    takeEvery(ACTIONS.RECIPE.SAVE_DATA, recipeSaveData),
    takeEvery(ACTIONS.RECIPE.UPLOAD_IMAGE, recipeUploadImage),

    takeEvery(ACTIONS.RECIPES.BULK_ACTION, performBulkOperationOnRecipe),
    takeEvery(ACTIONS.RECIPES.DELETE_RECIPE, deleteRecipe),
    takeEvery(ACTIONS.RECIPES.FILTER_LIST, filterRecipesList),
    takeLatest(ACTIONS.RECIPES.GET_RECIPES_LIST, getRecipesList),
  ]);

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default rootSaga;
