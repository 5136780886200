/**
 * ************************************
 *
 * @module  MovieService.js
 * @author  Matt P
 * @date    12/31/2020
 * @description File to prepare Movie form
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import CommonService from 'services/CommonService';

import moment from 'moment';

import { isValidURL } from 'utils/utils';

import { isArrayEmpty, trimItemizedDescription } from 'utils/FormFunc';

const commonService = CommonService();

// ----------------------------------------------------------------------------|
//                               Movie Service
// ----------------------------------------------------------------------------|
const MovieService = {
  /**
   * @description Object containing error messages and constraints
   */
  movieConstraints: {
    title: { label: 'Title', min: 1, max: 100 },
    original_title: { label: 'Original Title', min: 1, max: 100 },
    categories: { msg: 'At Least One Category is Required' },
    main_categories: { msg: 'At Least One Primary Category is Required' },
    movie_production_status: { msg: 'Production Status is Required' },
    instance_type: { msg: 'You must choose a media type (Movie or TV Show)' },
    release_date: { msg: 'A valid release year is required' },
    runtime: { msg: 'Please Enter a Valid Number for Runtime' },
    phrase: { label: 'One-liner', min: 10, max: 250 },
    description: { label: 'Synopsis', min: 10, max: 2500 },
    original_language: {
      msg: 'A Valid Language is Required. Please check spelling',
    },
    cta_title_long: { label: 'Long CTA Title', min: 1, max: 100 },
    cta_title_short: { label: 'Short CTA Title', min: 1, max: 100 },
  },

  /**
   * @description created reusable message for form errors
   *
   * @param {String} elem
   * @param {String} min
   * @param {String} max
   *
   * @returns {String}
   */
  createMessage(elem, min, max) {
    return `${elem} should be min ${min} and max ${max} characters`;
  },

  /**
   * @description validates form field inputs
   *
   * @param {Object} data
   *
   * @returns {Object} if errors
   */
  validate(data) {
    const errorsObj = {
      ...commonService.validateItemizedDescription(data.itemized_description),
      ...this.validateReleaseDate(data.release_date),
    };

    // TITLE
    if (data.title.length < 1 || data.title.length > 100) {
      const { label, min, max } = this.movieConstraints.title;

      errorsObj.title = this.createMessage(label, min, max);
    }

    // CATEGORIES CHECKS
    if (isArrayEmpty(data.categories)) {
      errorsObj.categories = this.movieConstraints.categories.msg;
    }

    if (isArrayEmpty(data.main_categories)) {
      errorsObj.categories = this.movieConstraints.main_categories.msg;
    }

    if (isArrayEmpty(data.categories) && isArrayEmpty(data.main_categories)) {
      errorsObj.categories =
        'One Category and One Primary Category are Required';
    }

    if (
      !commonService.validatePrimaryCategories(
        data.main_categories,
        data.categories
      )
    ) {
      errorsObj.categories =
        'Primary categories must be included in the selected categories';
    }

    if (data.instance_type !== 'movie' && data.instance_type !== 'show') {
      errorsObj.instance_type = this.movieConstraints.instance_type.msg;
    }

    // // RELEASE DATE
    // if (!data.release_date || data.release_date.length < 10)
    //   errorsObj.release_date = this.movieConstraints.release_date.msg;

    // PHRASE / SHORT DESCRIPTION
    if (!data.phrase || data.phrase.length < 10 || data.phrase.length > 250) {
      const { label, min, max } = this.movieConstraints.phrase;

      errorsObj.phrase = this.createMessage(label, min, max);
    }

    return errorsObj;
  },

  /**
   * @description validates title
   *
   * @param {String} title
   *
   * @returns {String || null}
   */
  validateTitle(formValue, formKey) {
    const { min, max, label } = this.movieConstraints[formKey];

    if (!formValue || formValue.length < min || formValue.length > max) {
      // eslint-disable-next-line max-len
      return { [formKey]: this.createMessage(label, min, max) };
    }

    return {};
  },

  validateReleaseDate(dateString) {
    if (
      dateString !== undefined &&
      (dateString === 'Invalid date' ||
        !moment(dateString, 'YYYY-MM-DD').isValid())
    ) {
      return { release_date: this.movieConstraints.release_date.msg };
    }

    return {};
  },

  /**
   * @description adds unformatted_thumb_url to thumb_url field
   * and unformatted_url to url field in photo object for the backend.
   *
   * This is only for fetched images from TMDB
   *
   * @param {Object} data - of strings/links
   *
   * @returns {Object} of converted key/value pairs
   */
  convertImageLinks: (imageData) =>
    imageData.map((data) => {
      if (data.source === 'tmdb') {
        const {
          photo_credits,
          source,
          unformatted_thumb_url,
          unformatted_url,
        } = data;

        return {
          photo_credits,
          source,
          url: unformatted_url,
          thumb_url: unformatted_thumb_url,
        };
      }

      return data;
    }),

  /**
   * @description preps the form data for submission
   *
   * @param {Object} data - of strings/links
   * @param {Array} uploadedImages - of object data
   * @param {String} movieStatus - current tab
   *
   * @returns {Object} to be sent to DB
   */
  getPreparedFormData: (data, uploadedImages, movieStatus) => {
    const {
      description,
      main_categories,
      instance_type,
      categories,
      phrase,
      release_date,
      title,
      admin_review,
      itemized_description,
      rating,
      related_tmdb_id,
      should_refetch_3rd_parties_content,
    } = data;

    return {
      phrase: phrase ? phrase.trim() : '',
      description: description ? description.trim() : '',
      main_categories: main_categories || [],
      categories: [...categories, ...rating.filter((cat) => cat)],
      title: title || '',
      instance_type,
      release_date:
        release_date === undefined ||
        release_date === null ||
        release_date === ''
          ? undefined
          : commonService.formatDate(release_date),
      images: uploadedImages,
      status: movieStatus,
      admin_review,
      itemized_description: trimItemizedDescription(itemized_description),
      related_tmdb_id: related_tmdb_id ? related_tmdb_id.toString() : '',
      should_refetch_3rd_parties_content:
        should_refetch_3rd_parties_content || false,
    };
  },

  movieDefaultDescriptions: [
    {
      header: 'What it is',
      body: '',
    },
    {
      header: 'Why it\'s unique',
      body: '',
    },
    {
      header: 'Rotten Tomatoes score',
      body: '',
    },
    {
      header: 'Big names',
      body: '',
    },
  ],
};

// ----------------------------------------------------------------------------|
//                          Export - MovieService
// ----------------------------------------------------------------------------|
export default MovieService;
