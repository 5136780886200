/**
 * ************************************
 *
 * @module  PlaceService.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description functionality used for the Place
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import CommonService from 'services/CommonService';

import currentEnv from 'utils/EnvironmentSpecificValues';

import {
    chooseItemizedDescription,
    mapDetailedCategories,
    getProcessedNeighborhoods,
    qualifyArrayRendering,
} from 'utils/utils';
import {
    extractCategoryTypes,
    createCategoryUniqueSlugSet,
} from 'utils/CategoryUtils';
import { isArrayEmpty, filterEmptyMultiLinks } from 'utils/FormFunc';

const commonService = CommonService();

const PlaceService = {
    /**
     * @description processes the vertical specific data that is incoming from
     * the backend to populate an edit form.
     *
     * @param {Object} dataObj - of date data
     * @param {Object} additionalOptions - any additional data that may be
     * required to process incoming data
     *
     * @returns {Object}
     */
    processVerticalSpecificData(dataObj = {}, additionalOptions = {}) {
        const {
            address,
            booking_links,
            categories,
            description,
            from_time,
            itemized_description,
            lat,
            lng,
            main_categories,
            menu_links,
            neighborhoods,
            name,
            noteworthy_categories,
            ordering_links,
            pairings,
            perfect_for,
            phrase,
            phone,
            phone_number,
            place_hours,
            price_tier,
            related_foursquare_id,
            related_gmaps_id,
            related_yelp_id,
            reservation_tier,
            to_time,
            vibes,
            website,
            website_link,
        } = dataObj;

        const { categoryMap } = additionalOptions;

        // since we are using categories for several different fields,
        // we need to delineate the categories to the specific fields
        // they will later upon submission be joined for the API POST request
        const { categoryTagOptions, misc } = extractCategoryTypes(
            mapDetailedCategories(noteworthy_categories, categoryMap)
        );

        // seating cats come in under noteworthy. Those need to be separated
        // out on fetch
        let filteredNoteworthy = noteworthy_categories;

        if (
            qualifyArrayRendering(noteworthy_categories) &&
            qualifyArrayRendering(misc)
        ) {
            const miscSet = createCategoryUniqueSlugSet(misc);

            filteredNoteworthy = noteworthy_categories.filter(
                (m) => !miscSet.has(m.unique_slug)
            );
        }

        return {
            name: name || '',
            address: address || '',
            phrase: phrase || '',
            description,
            // overwrites cats from form utils with ... to replace misc
            categories: categoryTagOptions || [],
            main_categories: main_categories
                ? mapDetailedCategories(main_categories, categoryMap)
                : [],
            noteworthy_categories: filteredNoteworthy
                ? mapDetailedCategories(filteredNoteworthy, categoryMap)
                : [],
            perfect_for: perfect_for
                ? mapDetailedCategories(perfect_for, categoryMap)
                : [],
            neighborhoods: neighborhoods
                ? getProcessedNeighborhoods(neighborhoods)
                : [],
            pairings: pairings || [],
            price_tier:
                price_tier === undefined || price_tier === null ? '' : price_tier,
            from_time,
            to_time,
            website_link: website || website_link,
            booking_links,
            ordering_links: ordering_links || [],
            phone_number: phone || phone_number,
            reservation_tier: reservation_tier || '',
            lat,
            lng,
            menu_links: menu_links || [],
            place_hours: place_hours || {
                monday: { slots: [], status: '' },
                tuesday: { slots: [], status: '' },
                wednesday: { slots: [], status: '' },
                thursday: { slots: [], status: '' },
                friday: { slots: [], status: '' },
                saturday: { slots: [], status: '' },
                sunday: { slots: [], status: '' },
            },
            vibes: vibes || [],
            // special case where we want to put the detailed description from
            // old card details into the itemized one
            itemized_description: chooseItemizedDescription(
                itemized_description,
                description,
                PlaceService.defaultDescriptions
            ),
            // used to keep track of the miscOptions that are also categories,
            // are joined together upon form submission
            misc_options: misc || [],
            // third party info
            related_gmaps_id: related_gmaps_id || '',
            related_yelp_id: related_yelp_id || '',
            related_foursquare_id: related_foursquare_id || '',
        };
    },

    /**
     * @description preps the vertical specific form data to be passed in the
     * request body for a form update
     *
     * @param {Object} formData - formData
     * @param {Object} additionalOptions - any additional data that may be
     * required to process outgoing data
     *
     * @returns {Object} for requestBody
     */
    prepareVerticalSpecificFormData(formData = {}, additionalOptions = {}) {
        const {
            address,
            from_time,
            lat,
            lng,
            menu_links,
            neighborhoods,
            ordering_links,
            phrase,
            phone_number,
            place_hours,
            price_tier,
            reservation_tier,
            related_gmaps_id,
            related_yelp_id,
            related_foursquare_id,
            should_refetch_3rd_parties_content,
            to_time,
            website_link,
        } = formData;

        var { selectedCards } = additionalOptions;

        if (selectedCards == null) {
            selectedCards = [];
        }

        let { booking_links } = formData;

        // checks if 'does not take reservations' is toggled. If so,
        // will pass an empty booking links array to the backend on save
        if (reservation_tier === 'walk_in_only') {
            booking_links = [];
        }

        return {
            address,
            phrase: phrase ? phrase.trim() : '',
            set_neighborhoods:
                (neighborhoods && getProcessedNeighborhoods(neighborhoods)) || [],
            phone: phone_number || '',
            booking_links: booking_links ? filterEmptyMultiLinks(booking_links) : [],
            website: website_link || '',
            menu_links: menu_links ? filterEmptyMultiLinks(menu_links) : [],
            ordering_links: ordering_links
                ? filterEmptyMultiLinks(ordering_links)
                : [],
            to_time,
            from_time,
            lat,
            lng,
            price_tier:
                price_tier === '' || price_tier === undefined ? undefined : price_tier,
            reservation_tier: reservation_tier || '',
            place_hours,
            related_foursquare_id: related_foursquare_id || '',
            related_gmaps_id: related_gmaps_id || '',
            related_yelp_id: related_yelp_id || '',
            should_refetch_3rd_parties_content:
                should_refetch_3rd_parties_content || false,
            pairings: selectedCards.map((card) => ({
                pairing_reason: card.pairing_reason,
                unit_pretty_id: card.pretty_id,
            })),
        };
    },

    /**
     * @description error checking for 'publish' form collections
     * specific submission
     *
     * @param {Object} formData - formData
     *
     * @returns {Object} of errors
     */
    validate(data) {
        const errorsObj = {
            ...commonService.validateMultiLinks(data.booking_links, 'booking_links'),
            ...commonService.validateMultiLinks(data.ordering_links, 'ordering_links'),
        };

        if (
            data.noteworthy_categories !== undefined &&
            isArrayEmpty(data.noteworthy_categories)
        ) {
            errorsObj.noteworthy_categories =
                'At least one noteworthy category is required';
        }

        let count = 0;
        const key = Object.keys(data.place_hours);
        for (let i = 0; i < key.length; i += 1) {
            if (!data.place_hours[key[i]].slots.length && data.place_hours[key[i]].status !== "VARY") {
                count += 1;
            }
        }

        if (count === 7) {
            errorsObj.place_hours = 'Place hours are required';
        }

        if (data.vibes.length < 1) {
            errorsObj.vibes = 'At least one vibe is required';
        }

        if (data.price_tier === undefined || data.price_tier === '') {
            errorsObj.price_tier = 'Price range is required';
        }

        if (typeof data.address === 'string' && data.address.length === 0) {
            errorsObj.address_input = 'Address is required';
        }

        return errorsObj;
    },

    defaultDescriptions: [
        {
            header: 'What it is',
            body: '',
        },
        {
            header: 'Why it\'s unique',
            body: '',
        },
        {
            header: 'Popular dishes',
            body: '',
        },
        {
            header: 'Popular drinks',
            body: '',
        },
    ],

    /**
     * @description default descriptions for the itemized_descriptions
     * form field
     */
    placeOptions: [
        {
            displayText: 'Has indoor seating',
            uniqueSlug: currentEnv.uniqueSlugs.indoor,
            id: currentEnv.uniqueIds.indoor,
            type: 'category',
        },
        {
            displayText: 'Has outdoor seating',
            uniqueSlug: currentEnv.uniqueSlugs.outdoor,
            id: currentEnv.uniqueIds.outdoor,
            type: 'category',
        },
    ],
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default PlaceService;
