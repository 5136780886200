/**
 * ************************************
 *
 * @module  App.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Application component that renders Routes.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';

import ContentManagementSystemRoutes from 'Routes';

import 'App.scss';

// ----------------------------------------------------------------------------|
//                      React Function Component - App
// ----------------------------------------------------------------------------|
const App = (): JSX.Element => <ContentManagementSystemRoutes />;

// ----------------------------------------------------------------------------|
//                                App Export
// ----------------------------------------------------------------------------|
export default App;
