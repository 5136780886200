/**
 * ************************************
 *
 * @module  CuratedCard.saga.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description redux saga file for the CuratedCard component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { constructQueryParams, getPartsFromUrl } from 'utils/utils';
import { reportAPIError } from 'utils/ErrorHandlingUtils';

import ACTIONS from '../actions/actionTypes';
import API from '../../API';
import { GENERIC } from '../../constants';

// ----------------------------------------------------------------------------|
//                              Sagas - CuratedCard
// ----------------------------------------------------------------------------|
/**
 * @description handles uploading an image
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* uploadImage(action) {
  const { IMAGE_UPLOAD_FAIL } = GENERIC;
  const { postData } = window.axios;
  const { file, fileId } = action.data;

  try {
    const response = yield call(postData, API.curatedCardImage, file, {
      'Content-Type': 'multipart/form-data',
    });

    if (response.status === 200) {
      yield put({
        type: ACTIONS.CURATED_CARD.UPLOAD_IMAGE_SUCCESS,
        data: { ...response.data, fileId },
      });
    } else {
      toast.error(IMAGE_UPLOAD_FAIL);
      yield put({ type: ACTIONS.CURATED_CARD.UPLOAD_IMAGE_FAIL });
    }
  } catch (error) {
    toast.error(IMAGE_UPLOAD_FAIL);
    yield put({ type: ACTIONS.CURATED_CARD.UPLOAD_IMAGE_FAIL });
  }
}

/**
 * @description rearranges card stack
 *
 * @param {Object} data - object data
 * @param {String} id - card id
 *
 * @returns {void}
 */
function* handleCardStack(data, id) {
  const { status: toBeStatus } = data;
  const { secondPart: currentTab } = getPartsFromUrl(window.location.pathname);

  const currentStatus = currentTab === 'drafts' ? 'draft' : currentTab;

  if (toBeStatus !== currentStatus) {
    // Remove from data.
    yield put({ type: ACTIONS.CURATED_CARDS.REMOVE_CARD, data: { id } });
  } else {
    // Move to top.
    yield put({ type: ACTIONS.CURATED_CARDS.MOVE_TO_TOP, data: { id, data } });
  }
}

/**
 * @description saves data to DB
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* saveData(action) {
  const { CURATED_CARD_SAVE_SUCCESS, CURATED_CARD_SAVE_FAIL } = GENERIC;
  const { postData, putData } = window.axios;
  const data = { ...action.data };
  // data.neighborhood = formatData(data.neighborhood);
  yield put({
    type: ACTIONS.CURATED_CARD.BUTTON_DISBALING,
    data: { isBtnDisabled: true },
  });
  try {
    let response;
    if (action.id) {
      // update
      response = yield call(putData, `${API.curatedCard}${action.id}`, data);
    } else {
      // create
      response = yield call(postData, API.curatedCard, data);
    }

    if (response.status === 200) {
      yield put({
        type: ACTIONS.CURATED_CARD.SAVE_DATA_SUCCESS,
        data: { ...response.data },
      });

      yield handleCardStack(response.data, action.id);
      action.successCB();
      toast.success(CURATED_CARD_SAVE_SUCCESS);
    } else {
      reportAPIError('Curated Card Save HTTP', response.data);

      yield put({ type: ACTIONS.CURATED_CARD.SAVE_DATA_FAIL });
      toast.error(CURATED_CARD_SAVE_FAIL);
    }
  } catch (error) {
    reportAPIError('Overall Curated Card Save Catch', error);

    yield put({ type: ACTIONS.CURATED_CARD.SAVE_DATA_FAIL });
    toast.error(CURATED_CARD_SAVE_FAIL);
  } finally {
    yield put({
      type: ACTIONS.CURATED_CARD.BUTTON_DISBALING,
      data: { isBtnDisabled: false },
    });
  }
}

/**
 * @description Fetch CuratedCard object using ID
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* fetchCuratedCardDetail(action) {
  const { getData } = window.axios;

  try {
    const { data: id } = action;
    const URL = `${API.curatedCard}${id}`;

    const response = yield call(getData, URL);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.CURATED_CARD.FETCH_CURATED_CARD_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: ACTIONS.CURATED_CARD.FETCH_CURATED_CARD_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.CURATED_CARD.FETCH_CURATED_CARD_FAIL });
  }
}

/**
 * @description Fetch CuratedCard selection modal data
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* getCardListData(action) {
  const { getData, postData } = window.axios;

  try {
    const { data } = action;
    const { currentTab } = data;
    let URL;
    let response;

    if (false && (currentTab === 'PLACES' || currentTab === 'EVENTS')) {
      URL = `${API.place}?${constructQueryParams(data)}`;

      response = yield call(getData, URL);
    } else {
      const { status, query, loaded_ids, page } = data;

      const postDataRequestBody = {
        status,
        loaded_ids,
      };

      const dbPage = `?page=${page}&page_size=10`;

      let apiEndpoint;

      if (currentTab === 'PLACES' || currentTab === 'EVENTS') {
        postDataRequestBody.title = query;
        apiEndpoint = API.place;
      }

      if (currentTab === 'MOVIES') {
        postDataRequestBody.title = query;
        apiEndpoint = API.movieList;
      }

      if (currentTab === 'RECIPES') {
        postDataRequestBody.title = query;
        apiEndpoint = API.recipeList;
      }

      if (currentTab === 'ACTIVITIES') {
        apiEndpoint = API.activityList;
        postDataRequestBody.name = query;
      }

      URL = `${apiEndpoint}${dbPage}`;

      response = yield call(postData, URL, postDataRequestBody);
    }

    if (response.status === 200) {
      yield put({
        type: ACTIONS.CURATED_CARD.GET_CARD_LIST_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: ACTIONS.CURATED_CARD.GET_CARD_LIST_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.CURATED_CARD.GET_CARD_LIST_FAIL });
  }
}

export { uploadImage, saveData, fetchCuratedCardDetail, getCardListData };
