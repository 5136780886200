/**
 * ************************************
 *
 * @module  PricingDropdown.js
 * @author  Matt P
 * @date    03/24/2021
 * @description Pricing dropdown and price input component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { convertUnderscoreCaseToCapitalCase } from 'utils/utils';

import deleteIcon from 'assets/images/icn_delete.png';

import { DropDown } from 'components';

import 'components/PricingDropdown/PricingDropdown.scss';

// ----------------------------------------------------------------------------|
//                React Function Component - PricingDropdown
// ----------------------------------------------------------------------------|
const PricingDropdown = (props) => {
  const { name, value, onChange } = props;
  const [pricingDropdownValues, setPricingDropdownValues] = useState(value);

  const purchaseOptions = [
    { value: 'rental', label: 'Rental' },
    { value: 'purchase', label: 'Purchase' },
  ];

  /**
   * @description Fires when any of the input fields change
   * updates state and fires onChange prop function
   *
   * @param {Object} e - event
   * @param {String} type
   * @param {Number} index
   */
  const onChangeHandler = (e, type, index) => {
    const newPricingDropdownValues = [...pricingDropdownValues];

    if (type === 'purchase_type') {
      newPricingDropdownValues[index][type] = e.value;
    } else {
      newPricingDropdownValues[index][type] = e.target.value;
    }

    // Sets new state via hooks
    setPricingDropdownValues(newPricingDropdownValues);
    // // fires the onChange function passed to comp
    onChange(newPricingDropdownValues, name);
  };

  /**
   * @description Adds input field on button click
   */
  const addInput = () => {
    const newPricingDropdownValues = [...pricingDropdownValues];

    newPricingDropdownValues.push({
      purchase_type: '',
      price: '$ 0.00',
    });

    setPricingDropdownValues(newPricingDropdownValues);
  };

  /**
   * @description Deletes an input row then updates state and fires
   * the onChange prop function
   *
   * @argument {Object} index - index of row
   */
  const deleteFieldPair = (index) => {
    const newPricingDropdownValues = [...pricingDropdownValues];

    newPricingDropdownValues.splice(index, 1);

    // Sets new state via hooks
    setPricingDropdownValues(newPricingDropdownValues);
    // fires the onChange function passed to comp
    onChange(newPricingDropdownValues, name);
  };

  const buildPurchaseTypeFields = () =>
    pricingDropdownValues.map((pricing, index) => (
      <DropDown
        name="purchase_type"
        className="purchase-type-container__dropdown"
        value={pricing.purchase_type || ''}
        options={purchaseOptions}
        onSelect={(event) => onChangeHandler(event, 'purchase_type', index)}
        placeholder="Select One"
      />
    ));

  const buildAmountFields = () =>
    pricingDropdownValues.map((pricing, index) => (
      <div className="amount-fields">
        <input
          className="input-bar amount-container__fields-input"
          type="text"
          value={pricing.price}
          name="price"
          placeholder="$ 0.00"
          onChange={(event) => onChangeHandler(event, 'price', index)}
        />
        <div className="purchase-amount__row-delete">
          <img
            src={deleteIcon}
            alt="remove icon"
            onClick={() => deleteFieldPair(index)}
            data-name="delete"
            name={`${name}-delete-${index}`}
          />
        </div>
      </div>
    ));

  const buildFields = () => (
    <React.Fragment>
      <div className="purchase-amount-container">
        <div className="purchase-type-container">
          {pricingDropdownValues.length > 0 ? (
            <label className="purchase-type-label">Purchase Type</label>
          ) : (
            ''
          )}
          {buildPurchaseTypeFields()}
        </div>
        <div className="amount-container">
          {pricingDropdownValues.length > 0 ? (
            <label className="purchase-type-label">Price</label>
          ) : (
            ''
          )}
          {buildAmountFields()}
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <div className="pricing-dropdown-wrapper">
      {buildFields()}
      <span
        className="purchase-amount__add-link"
        role="button"
        onClick={() => {
          addInput();
        }}
      >
        {`+ Add ${convertUnderscoreCaseToCapitalCase(name)}`}
      </span>
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                    PropTypes Check - PricingDropdown
// ----------------------------------------------------------------------------|
PricingDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

PricingDropdown.defaultProps = {
  value: [],
  errors: '',
};

// ----------------------------------------------------------------------------|
//                        PricingDropdown Export
// ----------------------------------------------------------------------------|
export default PricingDropdown;
