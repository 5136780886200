/**
 * ************************************
 *
 * @module  RecipesList.js
 * @author  Matt P
 * @date    02/02/2021
 * @description RecipesList functional component that displays cards
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                    Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import { Card, InfiniteScroller } from 'components';

import { getImageURL, getDateBasedOnStatus } from 'utils/utils';

import { GENERIC } from 'constants.js';

// ----------------------------------------------------------------------------|
//                    React Function Component - RecipesList
// ----------------------------------------------------------------------------|
const RecipesList = (props) => {
  const {
    data,
    hasMore,
    onMoreData,
    onViewData,
    onMenuClicked,
    onCheckBoxSelected,
    isChecked,
    fromFetched,
    hideMenu,
  } = props;

  const {
    SUB_MENU: { EDIT },
  } = GENERIC;

  /**
   * @description limits the characters for a movie
   * description to limit card height
   *
   * @param {String} desc - movie description string
   * @param {Number} limit - number of characters
   *
   * @returns {String} - limited by char limit
   */
  const descriptionLimiter = (str, limit) => {
    if (str === null) return '';
    if (str.length <= limit) return str;

    let slicedString = str.slice(0, limit);

    slicedString += '...';

    return slicedString;
  };

  /**
   * @description generates card component
   *
   * @param {Array} recipeListData - Array of recipe objects
   *
   * @returns {JSX} - with Card components
   */
  const generateCards = (recipeListData) => {
    let res = null;

    if (Array.isArray(recipeListData)) {
      res = recipeListData.map((recipe) => {
        const {
          id,
          published_date: publishedDate,
          modified: modifiedDate,
          status,
          images,
          title,
          phrase,
          description,
          is_expired: isExpired,
          last_admin_review,
        } = recipe;

        const imageURL = getImageURL(images);

        return (
          <Card
            key={id}
            id={id}
            className="card"
            onMenuClick={(value) => onMenuClicked(value, recipe)}
            onCheck={onCheckBoxSelected}
            onClick={() => onMenuClicked(EDIT, recipe)}
            isChecked={isChecked}
            hideMenu={hideMenu}
            date={getDateBasedOnStatus(status, modifiedDate, publishedDate)}
            status={status}
            imageUrl={imageURL}
            title={title}
            phrase={descriptionLimiter(phrase, 175)}
            isExpired={isExpired}
            adminReview={last_admin_review}
            hideCheckBox
          />
        );
      });
    }

    return <div className="card-container">{res}</div>;
  };

  return (
    <section className="recipe-list">
      <InfiniteScroller
        loadMore={onMoreData}
        hasMore={hasMore}
        useWindow={!hideMenu}
      >
        {generateCards(data)}
      </InfiniteScroller>
    </section>
  );
};

// ----------------------------------------------------------------------------|
//                         PropTypes Check - RecipesList
// ----------------------------------------------------------------------------|
RecipesList.propTypes = {
  data: PropTypes.array.isRequired,
  onMoreData: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  onViewData: PropTypes.func,
  onMenuClicked: PropTypes.func,
  onCheckBoxSelected: PropTypes.func,
  isChecked: PropTypes.any,
  fromFetched: PropTypes.bool,
  hideMenu: PropTypes.bool,
};

// ----------------------------------------------------------------------------|
//                          DefaultProps - RecipesList
// ----------------------------------------------------------------------------|
RecipesList.defaultProps = {
  onViewData: () => {},
  onMenuClicked: () => {},
  onCheckBoxSelected: () => {},
  fromFetched: true,
  isChecked: null,
  hideMenu: false,
};

// ----------------------------------------------------------------------------|
//                               RecipesList Export
// ----------------------------------------------------------------------------|
export default RecipesList;
