/**
 * ************************************
 *
 * @module  EventDateSelector.js
 * @author  Matt P
 * @date    02/16/2021
 * @description Curates dates for events.
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                  Imports
// ---------------------------------------------------------------------------|
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { DATE_SELECTOR_POPUP as LABELS } from 'constants.js';

import DateSummary from './DateSummary/DateSummary';
import EventDateSelectorPopup from './EventDateSelectorPopup/EventDateSelectorPopup';

import './EventDateSelector.scss';

// ---------------------------------------------------------------------------|
//                React Class PureComponent - EventDateSelector
// ---------------------------------------------------------------------------|
class EventDateSelector extends PureComponent {
  // -------------------------------------------------------------------------|
  //                             PropTypes Check
  // -------------------------------------------------------------------------|
  static propTypes = {
    dates: PropTypes.arrayOf(
      PropTypes.shape({
        startDate: PropTypes.shape(PropTypes.object).isRequired,
        fromTime: PropTypes.string.isRequired,
        toTime: PropTypes.string.isRequired,
        isRecurring: PropTypes.bool,
        endDate: PropTypes.shape(PropTypes.object),
        selectedWeekDays: PropTypes.arrayOf(PropTypes.string),
        repeatMode: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
        availability: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      })
    ).isRequired,
    onDatesUpdated: PropTypes.func.isRequired,
    errorMsg: PropTypes.string,
  };

  // -------------------------------------------------------------------------|
  //                              Default Props
  // -------------------------------------------------------------------------|
  static defaultProps = {
    errorMsg: '',
  };

  // -------------------------------------------------------------------------|
  //                          Constructor and State
  // -------------------------------------------------------------------------|
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  // -------------------------------------------------------------------------|
  //                          Class Component Methods
  // -------------------------------------------------------------------------|
  /**
   * @description Fires when a user clicks the "Add Date" || "Add Another Date"
   * Triggers the popup modal. Sets state to trigger add
   */
  onAdd = () => {
    this.setState({
      showModal: true,
    });
  };

  /**
   * @description Fires when a user clicks on a dates component. Allows
   * editing of already specified dates. Sets state to trigger edit
   */
  onEdit = () =>
    this.setState({
      showModal: true,
    });

  /**
   * @description deletes a date when the delete "X" button is clicked
   * on a date component. Then updates the form field on the parent container
   *
   * @param {Number} index
   */
  onDelete = (index) => {
    const { dates, onDatesUpdated } = this.props;
    const updatedDates = dates.filter((_, itemIndex) => itemIndex !== index);

    onDatesUpdated(updatedDates);
  };

  /**
   * @description toggles the modal
   */
  closeDateSelectorModal = () => {
    const { dates } = this.props;

    // we create an errors property on all date objects
    // when the modal is clicked, if the user exits out the modal
    // it'll delete these properties so any lingering errors do not
    // appear on the form. user cannot submit dates if there are
    // errors, so they shouldn't appear on the form
    dates.forEach((date) => {
      if (date.errors) delete date.errors;
    });

    this.setState({ showModal: false });
  };

  render() {
    const { showModal } = this.state;
    const { dates, onDatesUpdated, errorMsg } = this.props;
    const { $ADD_DATE, $ADD_ANOTHER_DATE, DATE } = LABELS;

    return (
      <div className="event-date-selector">
        {showModal && (
          <EventDateSelectorPopup
            dates={dates}
            closeModal={this.closeDateSelectorModal}
            onDateUpdated={(updatedDates) => {
              onDatesUpdated(updatedDates);
            }}
          />
        )}
        <label className="heading">{DATE}</label>
        {dates.length !== 0 &&
          dates.map((date, index) => (
            <DateSummary
              {...date}
              key={`date-form-summary-${index}`}
              className="date-summary"
              onEdit={() => this.onEdit(index)}
              onDelete={() => this.onDelete(index)}
              errors={
                date.errors && date.errors.overlapError
                  ? date.errors.overlapError
                  : ''
              }
            />
          ))}
        <span
          className="add-date"
          role="button"
          tabIndex="0"
          onClick={this.onAdd}
        >
          {dates.length === 0 ? $ADD_DATE : $ADD_ANOTHER_DATE}
        </span>
        <div className="error">{errorMsg}</div>
      </div>
    );
  }
}

// -------------------------------------------------------------------------|
//                                  Export
// -------------------------------------------------------------------------|
export default EventDateSelector;
