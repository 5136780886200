/**
 * ************************************
 *
 * @module  CobReactSelect.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Dynamic/Reusable function Component for drop downs
 * utilizing react-select npm package
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|

import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import cx from 'classnames';

import 'react-dropdown/style.css';

import 'components/DropDown/DropDown.scss';

// ----------------------------------------------------------------------------|
//                  React Function Component - CobReactSelect
// ----------------------------------------------------------------------------|
/**
 * @description This is a functional component. Which is renders a single
 * dropdown select input form. It will trigger onClick event which is connected
 * to it.
 *
 * @param {Object} props
 *
 * @returns {JSX} HTML <Select/>components to be rendered to the DOM
 */
const CobReactSelect = (props) => {
  const {
    name,
    options,
    onSelect,
    className,
    value,
    placeholder,
    isClearable,
    isSearchable,
    styles,
  } = props;

  const containerClass = cx(['cob-react-select drop-down', `${className}`]);

  /**
   * @description Formats selected value to be passed to <Select/>
   * via filters selected value
   *
   * @returns {Array} Array of filtered objects
   */
  const prepareSelectedValue = () =>
    options.filter((item) => item.value === value)[0] || null;

  /**
   * @description On change function passed to <Select>
   *
   * @returns {Array} Array of mapped objects
   */
  const onChange = (selectedValue) => {
    // On clearing filter, empty object is sent to back end
    const emptyObject = { value: undefined };
    const option = selectedValue || emptyObject;
    onSelect(option, name);
  };

  /**
   * @description Maps new array of options objects with className: 'object'
   *
   * @returns {Array} Array of mapped objects
   */
  const prepareOptionWithClassName = () =>
    options.map((option) => ({ ...option, className: 'option' }));

  const customStyles = {
    control: base => ({
      ...base,
      ...styles,
      height: 40,
      minHeight: 40
    })
  };

  return (
    <Select
      styles={customStyles}
      isMulti={false}
      className={containerClass}
      isSearchable={isSearchable}
      isClearable={isClearable}
      value={prepareSelectedValue()}
      options={prepareOptionWithClassName()}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

// ----------------------------------------------------------------------------|
//                     PropTypes Check - CobReactSelect
// ----------------------------------------------------------------------------|
CobReactSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  // name: PropTypes.string.isRequired,
  name: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
};

// ----------------------------------------------------------------------------|
//                        Default Props - CobReactSelect
// ----------------------------------------------------------------------------|
CobReactSelect.defaultProps = {
  className: '',
  placeholder: '',
  value: '',
  isClearable: true,
  isSearchable: true,
  styles: '',
};

// ----------------------------------------------------------------------------|
//                         CobReactSelect Export
// ----------------------------------------------------------------------------|
export default CobReactSelect;
