/**
 * ************************************
 *
 * @module  WeekDaySelector.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Displays a list of weekday buttons for selection
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                  Imports
// ---------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import { WEEKDAYS } from '../../EventDateSelector.constants';

import './WeekDaySelector.scss';

// ---------------------------------------------------------------------------|
//                React Function Component - WeekDaySelector
// ---------------------------------------------------------------------------|
const WeekDaySelector = (props) => {
  const { selected, onSelect, className } = props;
  const css = `week-day-selector ${className} ${
    onSelect ? 'editable' : ''
  }`.trimRight();
  const days = WEEKDAYS.map(({ name }) => {
    const lowerCasedName = name.toLowerCase();
    return {
      name: lowerCasedName,
      selected: selected.includes(lowerCasedName),
    };
  });

  const onDayClicked = (index) => {
    days[index].selected = !days[index].selected;
    if (onSelect) {
      onSelect(days.filter((day) => day.selected).map((day) => day.name));
    }
  };

  return (
    <div className={css}>
      {WEEKDAYS.map((day, index) => {
        const isSelected = selected.includes(day.name.toLowerCase());
        const dayCss = `week-day ${isSelected ? 'selected' : ''}`.trimRight();
        return (
          <span
            key={index}
            className={dayCss}
            role="button"
            onClick={() => onDayClicked(index)}
          >
            {day.name[0].toUpperCase()}
          </span>
        );
      })}
    </div>
  );
};

// -------------------------------------------------------------------------|
//                             PropTypes Check
// -------------------------------------------------------------------------|
WeekDaySelector.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
};

// -------------------------------------------------------------------------|
//                              Default Props
// -------------------------------------------------------------------------|
WeekDaySelector.defaultProps = {
  className: '',
  selected: [],
  onSelect: null,
};

// ---------------------------------------------------------------------------|
//                                  Export
// ---------------------------------------------------------------------------|
export default WeekDaySelector;
