/**
 * ************************************
 *
 * @module  EventDateTimeEdit.js
 * @author  Matt P
 * @date    02/17/2021
 * @description Edits times for a selected date.
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                  Imports
// ---------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import TimePicker from 'rc-time-picker';

import { DATE_SELECTOR_POPUP as LABELS } from 'constants.js';

import timeIcon from 'assets/images/time-icn.svg';

import './EventDateTimeEdit.scss';

// ---------------------------------------------------------------------------|
//                React Function Component - EventDateTimeEdit
// ---------------------------------------------------------------------------|
const EventDateTimeEdit = ({
  fromTime,
  toTime,
  index,
  updateTimeRange,
  errors,
  label,
}) => {
  const { SELECT_TIME, TIME_FORMAT } = LABELS;

  return (
    <div className="hours-range">
      <label className="hours-range-label">{label}</label>
      <div className="timer">
        <span className="time-picker--wrapper">
          <TimePicker
            popupStyle={{ width: '168px' }}
            showSecond={false}
            use12Hours
            allowEmpty={false}
            inputReadOnly
            minuteStep={15}
            value={fromTime ? moment(fromTime, TIME_FORMAT) : null}
            className="timePicker"
            onChange={(value) => updateTimeRange('fromTime', value, index)}
            placeholder={SELECT_TIME}
          />
          <img src={timeIcon} alt="Select time" className="time-picker-icon" />
        </span>
        <span className="timer-separator">to</span>
        <span className="time-picker--wrapper">
          <TimePicker
            popupStyle={{ width: '168px' }}
            showSecond={false}
            use12Hours
            allowEmpty={false}
            inputReadOnly
            minuteStep={15}
            value={toTime ? moment(toTime, TIME_FORMAT) : null}
            className="timePicker"
            onChange={(value) => updateTimeRange('toTime', value, index)}
            placeholder={SELECT_TIME}
          />
          <img src={timeIcon} alt="Select time" className="time-picker-icon" />
        </span>
      </div>
      <div className="error">{errors}</div>
    </div>
  );
};

// -------------------------------------------------------------------------|
//                             PropTypes Check
// -------------------------------------------------------------------------|
EventDateTimeEdit.propTypes = {
  fromTime: PropTypes.string,
  toTime: PropTypes.string,
  updateTimeRange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  label: PropTypes.string,
  errors: PropTypes.string,
};

// -------------------------------------------------------------------------|
//                              Default Props
// -------------------------------------------------------------------------|
EventDateTimeEdit.defaultProps = {
  fromTime: '',
  toTime: '',
  errors: '',
  label: '',
};

// ---------------------------------------------------------------------------|
//                                  Export
// ---------------------------------------------------------------------------|
export default EventDateTimeEdit;
