/**
 * ************************************
 *
 * @module  Cities.action.js
 * @author  Matt P
 * @date    04/25/2022
 * @description redux action for Cities
 *
 * ************************************
 */

// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                                  Interface
// ----------------------------------------------------------------------------|
export type ActionReturnType = {
  type: string;
  data?: any;
  responseData?: any;
  parentCityId?: string;
};

// ----------------------------------------------------------------------------|
//                                  Exports
// ----------------------------------------------------------------------------|
export const getCitiesListAction = (): ActionReturnType => ({
  type: ACTIONS.CITIES.GET_LIST,
});

export const createCitiesAction = (
  data: any,
  parentCityId?: string
): ActionReturnType => ({
  type: ACTIONS.CITIES.CREATE_CITY,
  data,
  parentCityId,
});

export const editCitiesAction = (
  data: any,
  parentCityId?: string
): ActionReturnType => ({
  type: ACTIONS.CITIES.EDIT_CITY,
  data,
  parentCityId,
});

export const clearListAction = (): ActionReturnType => ({
  type: ACTIONS.CITIES.CLEAR_LIST,
});

export const toggleEnable = (
  data: any,
  parentCityId?: string
): ActionReturnType => ({
  type: ACTIONS.CITIES.TOGGLE_ENABLE,
  data,
  parentCityId,
});

export const deleteCityAction = (
  id: string,
  parentCityId?: string
): ActionReturnType => ({
  type: ACTIONS.CITIES.DELETE_CITY,
  data: id,
  parentCityId,
});

export const saveDataToRedux = (data: any): ActionReturnType => ({
  type: ACTIONS.CITIES.SAVE_DATA_TO_REDUX,
  data,
});

export const setCityToEditAction = (data: any): ActionReturnType => ({
  type: ACTIONS.CITIES.SET_CITY_TO_EDIT,
  data,
});

export const clearCityToEditAction = (): ActionReturnType => ({
  type: ACTIONS.CITIES.CLEAR_CITY_TO_EDIT,
});
