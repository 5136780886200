/**
 * ************************************
 *
 * @module  Movie.reducer.js
 * @author  Matt P
 * @date    12/27/2020
 * @description redux reducer file for Movie component
 *
 * ************************************
 */

// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                               Initial State
// ----------------------------------------------------------------------------|
const initialState = {
  // Contains images that are to be auto-filled into the image upload component
  autofilledImages: [],
  images: [],
  formData: {},
  // Contains list of fileIds for whom upload failed
  failedUploads: [],
  isDropZonePreviewRequired: false,
  isBtnDisabled: false,
};

// ----------------------------------------------------------------------------|
//                                 Utilities
// ----------------------------------------------------------------------------|
// identifier can be the source url of the image in case of auto-filled images
// For images that were not auto-filled, it is the file id
// If an image's source url or its ID matches the identifier param, delete it
// from the array
const getFilteredImages = (identifier, images = []) =>
  images.filter(
    (image) => image.url !== identifier && image.fileId !== identifier
  );

// ----------------------------------------------------------------------------|
//                               Movie Reducer
// ----------------------------------------------------------------------------|
const Movie = (state = initialState, action = null) => {
  const { type, data } = action;

  switch (type) {
    case ACTIONS.MOVIE.FETCH_MOVIE_SUCCESS: {
      return {
        ...state,
        fetchedMovieData: data,
      };
    }

    case ACTIONS.MOVIE.POPULATE_IMAGES: {
      return {
        ...state,
        autofilledImages: data || [],
      };
    }

    case ACTIONS.MOVIE.SAVE_DATA_TO_REDUX: {
      return {
        ...state,
        formData: { ...state.formData, ...data },
      };
    }

    // Clear any errors for image being uploaded
    case ACTIONS.MOVIE.UPLOAD_IMAGE: {
      return {
        ...state,
        isDropZonePreviewRequired: true,
        failedUploads: state.failedUploads.filter(
          (fileId) => fileId !== data.fileId
        ),
      };
    }

    case ACTIONS.MOVIE.UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        autofilledImages: [
          ...state.autofilledImages,
          { ...data, fileId: data.fileId, photo_metadata: data.photo_metadata },
        ],
        isDropZonePreviewRequired: false,
      };
    }

    case ACTIONS.MOVIE.UPLOAD_IMAGE_FAIL: {
      return { ...state, failedUploads: [...state.failedUploads, data.fileId] };
    }

    case ACTIONS.MOVIE.DELETE_IMAGE: {
      return {
        ...state,
        autofilledImages: getFilteredImages(data, state.autofilledImages),
      };
    }

    case ACTIONS.MOVIE.CLEAR_IMAGES: {
      return { ...state, images: [] };
    }

    case ACTIONS.MOVIE.RE_ORDER_IMAGES: {
      return { ...state, autofilledImages: data };
    }

    case ACTIONS.MOVIE.BUTTON_DISBALING: {
      return {
        ...state,
        isBtnDisabled: data.isBtnDisabled,
      };
    }

    default: {
      return state;
    }
  }
};

// ----------------------------------------------------------------------------|
//                          Export - Movie Reducer
// ----------------------------------------------------------------------------|
export default Movie;
