/**
 * ************************************
 *
 * @module  CommoneService.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Common services for form validation
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import PropTypes from 'prop-types';

import { isAfter, isEqual } from 'date-fns';

import moment from 'moment';

import currentEnv from 'utils/EnvironmentSpecificValues';

import { isValidURL, hasUniqueSlug } from 'utils/utils';

import LanguageCodes from 'utils/LanguageCodes';

import { VERTICAL_TYPE } from 'constants.js';

import {
  filterEmptyMultiLinks,
  isValidPhone,
  isArrayEmpty,
} from 'utils/FormFunc';

// ----------------------------------------------------------------------------|
//                                  Utilities
// ----------------------------------------------------------------------------|
/**
 * @description returns a 'custom message'
 *
 * @argument {String} elem
 * @argument {Number} min
 * @argument {Number} max
 */
const _getMessage = (elem, min, max) => `${elem} should
     be min ${min} and max ${max} characters`;

/**
 * @description Object containing constraints
 */
const constraints = {
  name: {
    label: 'Name',
    min: 1,
    max: 100,
  },
  address: { label: 'Address', min: 10, max: 100 },
  phrase: { label: 'One Liner Description', min: 10, max: 250 },
  // long_description: { label: 'Long Description', min: 10, max: 2500 },
  neighborhoods: {},
  categories: {},
  price_tier: {},
  phone_number: { label: 'Phone Number' },
  website_link: { label: 'Website Link', min: 0 },
  booking_link: { label: 'Booking Link', min: 0 },
  menu_link: { label: 'Menu Link', min: 0 },
  event_venue_name: {},
};

// ----------------------------------------------------------------------------|
//                         CommonService Definition
// ----------------------------------------------------------------------------|
const CommonService = () => {
  /**
   * @description validates images
   *
   * @argument {Array} autoFilledImages
   * @argument {Array} uploadedImages
   *
   * @returns {Boolean}
   */
  const validateImages = (autoFilledImages = [], uploadedImages = []) =>
    autoFilledImages.length + uploadedImages.length > 0;

  /**
   * @description maps over and processes neighborhood objects
   *
   * @argument {Object[]} neighborhoods
   *
   * @returns {Object[]}
   */
  const getProcessedNeighborhoods = (neighborhoods = []) => {
    if (Object(neighborhoods[0]) === neighborhoods[0]) {
      return neighborhoods.map((neighborhood) => neighborhood);
    }
    return neighborhoods;
  };

  /**
   * @description formats updated data for presentation on form
   *
   * @argument {Object} formData - current form data
   * @argument {Array} data - of Objects
   *
   * @returns {Array} of Objects
   */
  const getUpdatedData = (formData, data = []) => {
    const tempData = [...data];

    tempData.map((each) => {
      // need to disable and toggle render inputs based
      // on if radio is checked or not.
      if (each.name === 'booking_links') {
        tempData.forEach((obj) => {
          if (obj.name === 'booking_links') {
            obj.disabled = formData.reservation_tier === 'walk_in_only';
          }

          if (obj.name === 'reservation_tier') {
            obj.value = formData.reservation_tier;
            obj.hide = formData.reservation_tier === 'walk_in_only';
          }
        });
      }

      if (each.name === 'amount_per') {
        tempData.forEach((obj) => {
          if (obj.name === 'amount_per') {
            obj.per = formData.event_amount_suffix;
          }
        });
      }

      if (each.name === 'address_input' || each.name === 'address') {
        tempData.forEach((obj) => {
          const { name } = obj;
          if (name === 'address_input' || name === 'address') {
            obj.value = formData.location;
            obj.disabled = hasUniqueSlug(
              formData.misc_options,
              currentEnv.uniqueSlugs.virtualEvent
            );
          }
        });
      }

      if (each.name === 'place_hours') {
        tempData.forEach((obj) => {
          if (obj.name === 'place_hours') {
            obj.from = formData.place_hours;
          }
        });
      }

      if (each.name === 'event_hours') each.dates = formData.event_hours || [];

      return false;
    });

    return tempData;
  };

  /**
   * @description Validate form fields for Places, Events, Curated Cards
   * and Movies
   *
   * @argument {Object} data - of Objects
   *
   * @returns {Object} of errors
   */
  const validate = (data, verticalType) => {
    const errorsObj = {};
    const keys = Object.keys(constraints);

    keys.forEach((elem) => {
      const { min, max, label } = constraints[elem];
      const errorCheck =
        !data[elem] || data[elem].length < min || data[elem].length > max;
      const phoneNumberCheck = data[elem] && !isValidPhone(data[elem]);
      const linkCheck =
        elem === 'website_link' ||
        elem === 'booking_link' ||
        elem === 'menu_link';
      const neighborhoodCheck =
        elem === 'neighborhoods' && isArrayEmpty(data[elem]);
      const categoriesCheck =
        verticalType !== VERTICAL_TYPE.EVENT &&
        elem === 'categories' &&
        isArrayEmpty(data[elem]);

      if (
        verticalType !== VERTICAL_TYPE.EVENT && // skip phrase for events
        elem === 'phrase' &&
        errorCheck
      ) {
        errorsObj[elem] = _getMessage(label, min, max);
      }

      delete errorsObj.price_tier;

      if (elem === 'place_hours') {
        delete errorsObj[elem];

        const key = Object.keys(data[elem]);
        let count = 0;

        for (let i = 0; i < key.length; i += 1) {
          if (!data[elem][key[i]].slots.length) {
            count += 1;
          }
        }

        if (count === 7) {
          errorsObj[elem] = 'Place Hours are required';
        }
      }

      if (elem === 'phone_number') {
        delete errorsObj[elem];

        if (phoneNumberCheck) {
          errorsObj[
            elem
          ] = `${label} should only be valid numbers of formats 1234567890, (123) 456-7890 or 123-456-7890`;
        }
      }

      if (linkCheck) {
        if (!isValidURL(data[elem])) {
          errorsObj[elem] = `${label} is not a valid url`;
        }
      }

      if (neighborhoodCheck) {
        // Making field no longer required
        // errorsObj[elem] = 'Neighbourhood is required';
      }

      if (categoriesCheck) {
        errorsObj[elem] = 'Category is required';
      }

      // Place : Address field validation
      if (elem === 'event_venue_name') {
        delete errorsObj.address;
        delete errorsObj.event_venue_name;
        if (!data.address) {
          errorsObj.event_venue_name = 'Address is required';
        }
      }
    });

    return errorsObj;
  };

  /**
   * @description Returns PropTypes for Place & Events
   *
   * @returns {Object} of PropTypes
   */
  const getProTypesForPlaceEvent = () => ({
    data: PropTypes.object.isRequired,
    adminCategories: PropTypes.array.isRequired,
    saveDataToRedux: PropTypes.func.isRequired,
    neighborhoods: PropTypes.array.isRequired,
    uploadImage: PropTypes.func.isRequired,
    deleteImage: PropTypes.func.isRequired,
    saveData: PropTypes.func.isRequired,
    clearImages: PropTypes.func.isRequired,
    populateAutofillImages: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    fetchPlaceOrEvent: PropTypes.func.isRequired,
  });

  /**
   * @description This is written for making sure only one of website or booking
   * link is mandatory
   *
   * @argument {Object} data
   * @argument {Object} errorsObj
   *
   * @returns {Object} of errors
   */
  const validateLinks = (data, errorsObj) => {
    const errorsObjCopy = { ...errorsObj };

    if (!data.website_link) {
      delete errorsObjCopy.website_link;
    }

    if (!data.booking_link || data.booking_link.length === 0) {
      delete errorsObjCopy.booking_link;
    }

    if (!data.menu_link) {
      delete errorsObjCopy.menu_link;

      // if (
      //   !data.website_link &&
      //   (!data.booking_link || data.booking_link.length === 0)
      // ) {
      //   errorsObjCopy.website_link = `Either Website Link or ${
      //     getPartsFromUrl(window.location.pathname).firstPart === 'places'
      //       ? 'Reservation Link'
      //       : 'Booking Link'
      //   } is mandatory`;

      //   delete errorsObjCopy.booking_link;
      // }
    }

    return errorsObjCopy;
  };

  /**
   * @description checks if dates are valid
   *
   * @argument {String} startDate
   * @argument {String} endDate
   *
   * @returns {String || null}
   */
  const areValidDates = (startDate, endDate) => {
    const today = moment(new Date()).format('YYYY-MM-DD');

    if (!startDate || !endDate) {
      return 'From and To Dates cannot be empty';
    }

    if (!isEqual(endDate, today) && !isAfter(endDate, today)) {
      return 'To Date cannot be less than today';
    }

    if (!isAfter(endDate, startDate) && !isEqual(endDate, startDate)) {
      return 'From Date cannot be greater than the To Date';
    }

    return null;
  };

  /**
   * @description validates name
   *
   * @argument {String} name
   *
   * @returns {String || null}
   */
  const validateName = (name) => {
    const key = constraints.name;

    if (!name || name.length < key.min || name.length > key.max) {
      return `Name should be min ${key.min} and max ${key.max} characters`;
    }

    return null;
  };

  /**
   * @description Get object ID from `prettyID` string
   *
   * @argument {String} prettyID
   *
   * @returns {String}
   */
  const getIDFromPrettyID = (prettyID) => {
    let returnedID = '';

    if (String(prettyID).indexOf('place_') > -1) {
      returnedID = String(prettyID).split('place_')[1];
    } else if (String(prettyID).indexOf('card_') > -1) {
      returnedID = String(prettyID).split('card_')[1];
    }
    return returnedID;
  };

  /**
   * @description returns the pathname as an object
   *
   * @argument {String} pathname = pathname string
   *
   * @returns {Object || String}
   */
  const pathnameHelper = (pathname) => {
    if (typeof pathname === 'string') {
      const [, section, tab, id] = pathname.split('/');

      return {
        section,
        tab,
        id,
      };
    }

    return pathname;
  };

  /**
   * @description returns short date
   *
   * @argument {String} date = dates tring
   *
   * @returns {String}
   */
  const formatDate = (date) => moment(date).format('YYYY-MM-DD');

  /**
   * @description checks length of arg string, if
   * length is 2, will return the full language name,
   * if length is longer than 2, will loop through
   * Language codes full name and return code
   *
   * @argument {String} lang = dates string
   *
   * @returns {String || null} either full name or language code
   */
  const languageCodeHelper = (lang) => {
    if (typeof lang !== 'string')
      throw Error('languageCodeHelper: Please input typeof: String');

    // if language code is provided, will return full language name;
    if (lang.length <= 2 && LanguageCodes[lang])
      return LanguageCodes[lang].name;

    // if full language name is provided, will search and return
    // the two char code
    if (lang.length > 2) {
      const languageArray = Object.entries(LanguageCodes);
      const capitalCase =
        lang.charAt(0).toUpperCase() + lang.slice(1).toLowerCase();

      for (let i = 0; i < languageArray.length; i += 1) {
        if (languageArray[i][1].name === capitalCase)
          return languageArray[i][0];
      }
    }

    return null;
  };

  /**
   * @description validates multi link comp inputs
   *
   * @param {Array} arr - of strings/links
   *
   * @returns {Object} of errors
   */
  const validateMultiLinks = (arr = [], elemName) => {
    const errorsObj = {};
    const errorIndexes = [];

    filterEmptyMultiLinks(arr).forEach((url, index) => {
      if (!isValidURL(url)) errorIndexes.push(index + 1);
    });

    if (errorIndexes.length === 1) {
      errorsObj[elemName] = 'Link is invalid. Please check again and resubmit';
    } else if (errorIndexes.length === 2) {
      errorsObj[
        elemName
      ] = `Links ${errorIndexes[0]} and ${errorIndexes[1]} are invalid. Please check again and resubmit`;
    } else if (errorIndexes.length > 2) {
      let errorString = '';

      errorIndexes.forEach((elem, index) => {
        if (!errorIndexes[index + 1]) errorString += `and ${elem}`;
        else errorString += `${elem}, `;
      });

      errorsObj[
        elemName
      ] = `Links ${errorString} are invalid. Please check again and resubmit`;
    }

    return errorsObj;
  };

  /**
   * @description error checking for itemized_description fields
   *
   * @param {Object[]} descriptionArray - array of itemized descs
   *
   * @returns {Object} of errors
   */
  const validateItemizedDescription = (descriptionArray = []) => {
    if (descriptionArray.length < 1) {
      return {
        itemized_description: 'At lease one description section is required',
      };
    }

    const errorsObj = {};
    const errorIndexes = [];

    descriptionArray.forEach((desc, index) => {
      if (desc.header.length === 0 || desc.body.length === 0) {
        errorIndexes.push(index + 1);
      }
    });

    if (errorIndexes.length === 1) {
      errorsObj.itemized_description =
        'Description is invalid. Please input a section label and section text';
    } else if (errorIndexes.length === 2) {
      // eslint-disable-next-line max-len
      errorsObj.itemized_description = `Descriptions ${errorIndexes[0]} and ${errorIndexes[1]} are invalid. Please input a section label and section text for each`;
    } else if (errorIndexes.length > 2) {
      let errorString = '';

      errorIndexes.forEach((elem, index) => {
        if (!errorIndexes[index + 1]) errorString += `and ${elem}`;
        else errorString += `${elem}, `;
      });
      // eslint-disable-next-line max-len
      errorsObj.itemized_description = `Descriptions ${errorString} are invalid. Please input a section label and section text for each`;
    }

    return errorsObj;
  };

  /**
   * @description confirms if all main_categories are present in
   * categories array and returns a boolean
   *
   * @param {Array} primaryArray - of primary cats
   * @param {Array} mainArray - of categories
   *
   * @returns {Boolean}
   */
  const validatePrimaryCategories = (primaryArray = [], mainArray = []) => {
    let primaryCount = 0;

    for (let i = 0; i < primaryArray.length; i += 1) {
      for (let j = 0; j < mainArray.length; j += 1) {
        if (primaryArray[i].unique_slug === mainArray[j].unique_slug)
          primaryCount += 1;
      }
    }

    return primaryCount === primaryArray.length;
  };

  return {
    validate,
    validateLinks,
    areValidDates,
    validateImages,
    getProcessedNeighborhoods,
    getUpdatedData,
    getProTypesForPlaceEvent,
    validateName,
    getIDFromPrettyID,
    pathnameHelper,
    formatDate,
    languageCodeHelper,
    validateMultiLinks,
    validateItemizedDescription,
    validatePrimaryCategories,
  };
};

// ----------------------------------------------------------------------------|
//                          Export - CommonService
// ----------------------------------------------------------------------------|
export default CommonService;
