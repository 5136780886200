/**
 * ************************************
 *
 * @module  Button.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description dynamic and reusable Button function component.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SearchIcon from 'assets/images/search.png';
import ClearIcon from 'assets/images/remove.png';

import './SearchBar.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const SearchBar = ({
  name,
  value,
  onChange,
  onSubmit,
  className,
  placeholder,
  selectedValue, // for when we need to display an already selected value
  onClear,
  onKeyPress,
  ...rest
}) => {
  /**
   * @description - text input state
   */
  const [inputValue, setInputValue] = useState(value);

  /**
   * @description - lifecycle update if selected value prop changes
   */
  useEffect(() => {
    if (selectedValue === null) {
      setInputValue('');
    }
  }, [selectedValue]);

  /**
   * @description - dynamic classNames
   */
  const containerClass = cx(['search-bar-container', `${className}`]);

  /**
   * @description
   * This method is used to update internal state of the component and
   * also send the updated state to parent.
   * @param {Object} event contains event object to which input
   * event is triggered
   */
  const changeHandler = (event) => {
    const { value: updatedValue } = event.target;
    setInputValue(updatedValue);
    onChange(updatedValue);
  };

  /**
   * @description
   * This method is used to clear input field and trigger change call
   * to parent with empty string.
   */
  const clearInput = () => {
    setInputValue('');
    onChange('');
    onClear(); // if parent needs some additional functionality
  };

  return (
    <div className={containerClass}>
      <img src={SearchIcon} alt="search" className="search-icon" />
      <input
        className="search-bar"
        type="text"
        name={name}
        value={selectedValue || inputValue}
        onChange={changeHandler}
        placeholder={placeholder}
        onKeyPress={($event) => {
          if ($event.key === 'Enter') {
            $event.preventDefault();
            const { value } = $event.target;
            onSubmit(value);
          }
        }}
        {...rest}
      />
      {inputValue.length !== 0 && (
        <img
          src={ClearIcon}
          alt="clear filed"
          className="clear-icon"
          onClick={clearInput}
        />
      )}
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
SearchBar.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClear: PropTypes.func,
  onKeyPress: PropTypes.func,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
SearchBar.defaultProps = {
  placeholder: 'Search',
  className: '',
  selectedValue: null,
  onClear: () => {}, // optional as without it these
  onChange: () => {},
  onSubmit: () => {},
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default SearchBar;
