import API from 'API';

import { toast } from 'react-toastify';

/**
 * @description - edit post request to flag a card
 *
 * @returns {void}
 */
const closeFlagReport = (
  reportId: string,
  successCallback: (a: string) => void
): void => {
  const { postData } = window.axios;

  postData(`${API.contentReportUpdate}${reportId}`, {
    status: 'closed',
  })
    .then(() => {
      toast.success('Sucessfully resolved flag');
      successCallback(reportId);
    })
    .catch((err: any) => {
      toast.error(err);
    });
};

/**
 * @description - submits post request to flag a card
 *
 * @returns {void}
 */
const submitFlagReport = ({
  prettyId,
  flaggedReasonDescription,
  modalClosed,
}: {
  prettyId: string;
  flaggedReasonDescription: string;
  modalClosed: () => void;
}): void => {
  const { postData } = window.axios;

  postData(`${API.contentReport}${prettyId}`, {
    report_type: 'internal',
    additional_information: flaggedReasonDescription,
    unit_pretty_id: prettyId,
  })
    .then(() => {
      toast.success('Sucessfully submitted a flag');
      modalClosed();
    })
    .catch((err: any) => {
      toast.error(err);
    });
};

export { closeFlagReport, submitFlagReport };
