/**
 * ************************************
 *
 * @module  Places.action.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description redux action file for Places component.
 *
 *  * ** PLACES IS IS A DEPENDENCY FOR OTHER FILES (ie. Curated Card(s)) and
 * can not yet be depreciated
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                             Action Exports
// ----------------------------------------------------------------------------|
export const getPlacesAction = (data) => ({
  type: ACTIONS.PLACES.GET_PLACES,
  data,
});

export const addPlacesAction = (data) => ({
  type: ACTIONS.PLACES.ADD_PLACES,
  data,
});

export const deletePlaceAction = (data) => ({
  type: ACTIONS.PLACES.DELETE_PLACE,
  data,
});

export const performBulkOperationAction = (data) => ({
  type: ACTIONS.PLACES.BULK_ACTION,
  data,
});

/* ----- Actions creators for select toggle in CardSelector component ------ */
export const selectPlaceAction = (data) => ({
  type: ACTIONS.PLACES.SELECT_PLACE,
  data,
});

export const deselectPlaceAction = (data) => ({
  type: ACTIONS.PLACES.DESELECT_PLACE,
  data,
});

/* -------------------------------------------------------------------------- */

export const clearPlacesAction = () => ({
  type: ACTIONS.PLACES.CLEAR_PLACES,
});

export const clearSelectedPlacesAction = () => ({
  type: ACTIONS.PLACES.CLEAR_SELECTED_PLACES,
});

export const fetchAdminCategoriesAction = () => ({
  type: ACTIONS.PLACES.FETCH_ADMIN_CATEGORIES,
});

export const clearAdminCategories = () => ({
  type: ACTIONS.PLACES.CLEAR_ADMIN_CATEGORIES,
});

export const fetchNeighborhoodLocationsAction = () => ({
  type: ACTIONS.PLACES.FETCH_NEIGHBORHOOD_LOCATIONS,
});

export const filterPlacesListAction = (data) => ({
  type: ACTIONS.PLACES.FILTER_LIST,
  data,
});
