/**
 * ************************************
 *
 * @module  VerticalDisplay.reducer.js
 * @author  Matt P
 * @date   07/02/2021
 * @description redux reducer file for VerticalDisplay container.
 * Houses all the card selection info by vertical type
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|

import ACTIONS from 'store/actions/actionTypes';

import {
  extractCardTypeData,
  findDataKey,
  packageFlaggedCard,
} from 'utils/utils';

// ----------------------------------------------------------------------------|
//                                Initial State
// ----------------------------------------------------------------------------|
const initialState = {
  cards: [],
  totalCount: null, // only take the first total from an API call unless tmdb
  isInitialDataLoaded: false,
  pageNum: 1,
  // neighborhood locations
  neighborhoodLocations: [],
  neighborhoodsLoaded: false,
  // vertical specific state
  tmdbPage: -1,
  fetchMore: true,
};

// ----------------------------------------------------------------------------|
//                          VerticalDisplay Reducer
// ----------------------------------------------------------------------------|
const VerticalDisplay = (state = initialState, action = null) => {
  switch (action.type) {
    /**
     * @description upon successful fetch of http list (dependent upon
     * verticalType) populate cards array for display
     */
    case ACTIONS.VERTICAL_DISPLAY.GET_VERTICAL_DISPLAY_CARDS_SUCCESS: {
      const { data } = action;
      const { count, results, flagged_units } = data;

      let cards = [];
      // if this is a universal search, the response will have a '.results'
      // property, if that's the case we need to prep the card data as to
      // extract the card type since it can be a mish-mash of cards
      if (results !== undefined) {
        cards = results.map((card) => {
          // if curated, already in a response form we can work with
          if (card.is_curated === true) return card;

          // if not a curated card, extract from card response wrapper
          return findDataKey(card);
        });
      } else if (flagged_units !== undefined) {
        // if flagged card fetch
        cards = flagged_units.map((card) => packageFlaggedCard(card));
      } else {
        cards = extractCardTypeData(action.data); // otherwise, just extract
      }

      const cardsList = [...state.cards, ...cards];

      // specific logic for MOVIES api requests. Little different with
      // our implementation of 3rd party fetch.
      if (action.data.movies) {
        const { queried_tmdb_page, movies } = action.data;

        // if we do an additional fetch request and nothing is returned
        // flip the fetchMore flag to true to prevent any more calls
        if (movies.length === 0) {
          return {
            ...state,
            cards: cardsList,
            totalCount: count,
            isInitialDataLoaded: true,
            fetchMore: false,
          };
        }

        // if we query tmdb, increase
        if (queried_tmdb_page > -1) {
          const updatedTMDBPageNum = queried_tmdb_page + 1;

          return {
            ...state,
            cards: cardsList,
            totalCount: count,
            isInitialDataLoaded: true,
            tmdbPage: updatedTMDBPageNum,
            fetchMore: true,
          };
        }
      }

      const updatedPageNum = state.pageNum + 1;

      let totalCount;

      if (flagged_units) totalCount = cardsList.length;
      else if (state.totalCount === null) totalCount = count;
      else totalCount = state.totalCount;

      return {
        ...state,
        cards: cardsList,
        totalCount,
        isInitialDataLoaded: true,
        pageNum: updatedPageNum,
        tmdbPage: -1,
        fetchMore: cards.length > 0,
      };
    }

    case ACTIONS.VERTICAL_DISPLAY.GET_VERTICAL_DISPLAY_CARDS_FAIL: {
      return { ...state, isInitialDataLoaded: true };
    }

    /**
     * @description upon unsuccessful fetch of http list
     */
    case ACTIONS.VERTICAL_DISPLAY.DELETE_CARD_SUCCESS: {
      const updatedCardsList = state.cards.filter(
        (card) => card.id !== action.data
      );
      const updatedTotalCount = state.totalCount - 1;

      return {
        ...state,
        cards: updatedCardsList,
        totalCount: updatedTotalCount,
      };
    }

    /**
     * @description upon unsuccessful delete of card
     */
    case ACTIONS.VERTICAL_DISPLAY.DELETE_CARD_FAIL: {
      return { ...state };
    }

    /**
     * @description resets mostly to original state, leaves
     * neighborhoodLocations and tmdb items
     */
    case ACTIONS.VERTICAL_DISPLAY.CLEAR_CARDS: {
      const { cards, totalCount, isInitialDataLoaded, pageNum } = initialState;

      return { ...state, cards, totalCount, isInitialDataLoaded, pageNum };
    }

    /**
     * @description on successful neighborhoodLocations http fetch
     */
    case ACTIONS.VERTICAL_DISPLAY.FETCH_NEIGHBORHOOD_LOCATIONS_SUCCESS: {
      const neighborhoodLocations = [...action.data];

      return { ...state, neighborhoodLocations, neighborhoodsLoaded: true };
    }

    /**
     * @description move card to top
     */
    case ACTIONS.VERTICAL_DISPLAY.MOVE_TO_TOP: {
      const { id, data: modifiedData } = action.data;
      const dataWithID = state.cards.find((el) => el.id === id);
      const updatedDataWithoutID = state.cards.filter((el) => el.id !== id);
      const updatedData = [
        { ...dataWithID, ...modifiedData },
        ...updatedDataWithoutID,
      ];

      return { ...state, cards: [...updatedData] };
    }

    /**
     * @description remove card from list
     */
    case ACTIONS.VERTICAL_DISPLAY.REMOVE_CARD: {
      const { id } = action.data;
      const updatedData = state.cards.filter((el) => el.id !== id);
      const updatedCount = state.totalCount - 1;

      return { ...state, cards: [...updatedData], totalCount: updatedCount };
    }

    /**
     * @description make an update to to single card on list screen
     */
    case ACTIONS.VERTICAL_DISPLAY.UPDATE_SINGLE_CARD_SUCCESS: {
      const { data } = action;
      const replaceWithResponse = state.cards.map((card) => {
        if (card.id === data.id) return data;
        return card;
      });

      return {
        ...state,
        cards: replaceWithResponse,
      };
    }

    default: {
      return state;
    }
  }
};

// ----------------------------------------------------------------------------|
//                      VerticalDisplay Reducer Export
// ----------------------------------------------------------------------------|
export default VerticalDisplay;
