/**
 * ************************************
 *
 * @module  ButtonDropdown.js
 * @author  Matt P
 * @date    02/09/2021
 * @description dynamic and reusable Button function component with
 * dropdown capability.
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                    Imports
// ---------------------------------------------------------------------------|
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import { NativeClickListener } from 'components';

import dropdownArrow from 'assets/images/dropdown.svg';

import 'components/ButtonDropdown/ButtonDropdown.scss';

// ---------------------------------------------------------------------------|
//                    React Function Component - ButtonDropdown
// ---------------------------------------------------------------------------|
const ButtonDropdown = ({
  name,
  className,
  disabled,
  onClick,
  options,
  preventDefault,
  children,
  ...rest
}) => {
  /**
   * @description - state for dropdown toggle boolean
   */
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  /**
   * @description - dynamic classNames
   */
  const buttonClass = cx(['btn', { disabled }, `${className}`]);

  /**
   * @description - toggles dropdown of button dropdown
   *
   * @returns {void}
   */
  const toggleDropdown = () => {
    setIsDropdownActive(!isDropdownActive);
  };

  /**
   * @description - handles button click interaction
   *
   * @param {Object} event - DOM event object
   *
   * @returns {void}
   */
  const onClickHandler = (event) => {
    if (preventDefault) {
      event.preventDefault();
    }
    toggleDropdown();

    onClick(event);
  };

  /**
   * @description - handles button click interaction
   *
   * @param {Object[]} inputs - Array of Objects used to render
   * dropdown options. if object has an href prop, it'll be a button
   * that FWD to a link. If a callback prop is on the object, it'll fire the
   * callback
   *
   * {
   *  name: {String},
   *  text: {String},
   * }
   *
   * @returns {JSX}
   */
  const createDropdown = (inputs) => {
    const mappedInputs = inputs.map((input, indx) => (
      <button
        key={`btn-dropdown-menu-options${indx}`}
        name={input.name}
        className="btn-dropdown-menu-options"
        onClick={onClickHandler}
        type="button"
      >
        {input.text}
      </button>
    ));

    return <div className="btn-dropdown-menu">{mappedInputs}</div>;
  };

  return (
    <div className="btn-dropdown">
      <button
        className={buttonClass}
        onClick={toggleDropdown}
        type="button"
        {...rest}
      >
        {children} <img src={dropdownArrow} alt="btn-dropdown-arrow-icon" />
      </button>
      {isDropdownActive ? (
        <NativeClickListener onClick={toggleDropdown}>
          {createDropdown(options)}
        </NativeClickListener>
      ) : (
        ''
      )}
    </div>
  );
};

// ---------------------------------------------------------------------------|
//                              PropTypes Check
// ---------------------------------------------------------------------------|
ButtonDropdown.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  // children: PropTypes.string.isRequired,
  children: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  name: PropTypes.string,
  id: PropTypes.string,
  preventDefault: PropTypes.bool,
};

ButtonDropdown.defaultProps = {
  className: '',
  disabled: false,
  style: {},
  name: '',
  id: '',
  preventDefault: false,
};

// ---------------------------------------------------------------------------|
//                                    Export
// ---------------------------------------------------------------------------|
export default ButtonDropdown;
