/**
 * ************************************
 *
 * @module  CobCheckbox.js
 * @author  Matt P
 * @date   05/26/2021
 * @description Checkbox and label done the right way. Used for forms and
 * places a button label nicely to the right of the checkbox radio button
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from 'components';

import cx from 'classnames';

import './CobCheckbox.scss';

// ----------------------------------------------------------------------------|
//                    React Function Component - CobCheckbox
// ----------------------------------------------------------------------------|
const CobCheckbox = (props) => {
  const { label, displayText, onClick, toBeChecked, className } = props;

  /**
   * @description - dynamic classNames
   */
  const cobCheckboxClass = cx(['square', `${className}`]);

  return (
    <div className="checkbox-container">
      <Checkbox
        className={cobCheckboxClass}
        label={label}
        toBeChecked={toBeChecked}
        onClick={onClick}
        fromRecurring
      />
      <div className="checkbox-text">{displayText}</div>
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                         PropTypes Check - MultiInput
// ----------------------------------------------------------------------------|
CobCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  displayText: PropTypes.string,
  toBeChecked: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                          Default Props - MultiInput
// ----------------------------------------------------------------------------|
CobCheckbox.defaultProps = {
  displayText: '',
  toBeChecked: false,
  className: '',
};

// ----------------------------------------------------------------------------|
//                            Export - MultiInput
// ----------------------------------------------------------------------------|
export default CobCheckbox;
