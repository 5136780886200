/**
 * ************************************
 *
 * @module  CardList.js
 * @author  Matt P
 * @date    07/09/2021
 * @description Renders a Modal component to select cards for a collection.
 * Not to be confused with the CardSelector which is legacy code used to create
 * curated cards
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { Component, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  FilterSection,
  InfiniteScroller,
  Loader,
  MapView,
  Modal,
  NoDataFound,
  WidgetBox,
} from 'components';

import { connect } from 'react-redux';

import {
  clearCardSelectionVerticalListAction,
  fetchCardSelectionVerticalListAction,
  resetCardSelectionStateAction,
  selectCardListAction,
} from 'store/actions/VerticalForm.action';

import cx from 'classnames';

import { GENERIC, COLLECTION } from 'constants.js';

import {
  FilterType,
  formGroupOptions,
} from 'components/FilterSection/FiltersFactory';

import mapboxgl from '!mapbox-gl';

import currentEnv from 'utils/EnvironmentSpecificValues';

import { newCardDesignVerticalSet } from 'containers/VerticalDisplay/VerticalForm/VerticalFormUtils';

import {
  getImageURL,
  getDateBasedOnStatus,
  checkHasMore,
  getSelectedCardCount,
  getCardPrettyIds,
} from 'utils/utils';

import './CardList.scss';

// ----------------------------------------------------------------------------|
//                            Redux - Property Mapping
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  addSelectedCard: (data) => dispatch(selectCardListAction(data)),
  clearCardList: (subType) =>
    dispatch(clearCardSelectionVerticalListAction(subType)),
  fetchCardList: (data, verticalType, subType) =>
    dispatch(fetchCardSelectionVerticalListAction(data, verticalType, subType)),
  resetCardSelectionState: () => dispatch(resetCardSelectionStateAction()),
});

const mapStateToProps = (state) => ({
  verticalForm: state.verticalForm,
  categories: state.categories,
  neighborhoods: state.verticalDisplay.neighborhoodLocations,
  neighborhoodsLoaded: state.verticalDisplay.neighborhoodsLoaded,
});

// ----------------------------------------------------------------------------|
//                        React Class Component - CardList
// ----------------------------------------------------------------------------|
class CardList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMore: false,
      selectedCards: props.selectedCards || [],
      // bandaid and not clean for card selector clear bug
      selectedCardsObjects: [],
      fetchingData: false,
      // modal filters
      cardStatus: 'published',
      category: [],
      currentTab: 'PLACES',
      neighborhood: [],
      query: '',
      viewType: 'LIST',
      mapContainer: React.createRef(),
      map: React.createRef(),
    };
  }

  // --------------------------------------------------------------------------|
  //                    Class Component - Lifecycle Methods
  // --------------------------------------------------------------------------|
  componentDidMount() {
    // on component render, fetch cards
    this.getCardList();
  }

  componentDidUpdate(prevProps) {
    // loads more cards for infinite scroll on component update
    const { verticalForm } = this.props;
    const { currentTab, fetchingData } = this.state;

    const subType = this.chooseSubType(currentTab);

    const { cardList } = verticalForm.cardListData[subType];

    if (
      cardList &&
      cardList.length !==
        prevProps.verticalForm.cardListData[subType].cardList.length
    ) {
      this.setHasMore();

      if (fetchingData) {
        this.setState({
          fetchingData: false,
        });
      }
    }
  }

  // --------------------------------------------------------------------------|
  //                      Class Component - Methods
  // --------------------------------------------------------------------------|
  /**
   * @description Updates state with conditional boolean
   * if more cards are available for infinite scroll
   *
   * @returns {void}
   */
  setHasMore = () => {
    const { verticalForm } = this.props;
    const { currentTab } = this.state;
    const { cardList, cardListDataCount } =
      verticalForm.cardListData[this.chooseSubType(currentTab)];

    const hasMore = checkHasMore(cardList.length, cardListDataCount);

    this.setState({
      hasMore,
    });
  };

  /**
   * @description chooses card subtype based on tab
   *
   * @param {String} currTab
   *
   * @returns {String}
   */
  chooseSubType = (currTab) => {
    switch (currTab) {
      case 'ACTIVITIES': {
        return 'activity';
      }
      case 'PLACES': {
        return 'place';
      }
      case 'EVENTS': {
        return 'event';
      }
      case 'MOVIES': {
        return 'movie';
      }
      case 'RECIPES': {
        return 'recipe';
      }
      default:
        return 'place';
    }
  };

  /**
   * @description Fetches card list by checking the currentTab
   * accounts for the differences between the different vertical
   * http requests
   *
   * @returns {void}
   */
  getCardList = () => {
    const { verticalForm, fetchCardList } = this.props;
    const { cardStatus, currentTab, category, query, neighborhood, viewType } =
      this.state;
    const { cardListData } = verticalForm;
    var bbox = {};
    if (viewType === 'MAP') {
      const { map } = this.state;
      if (map.current) {
        const bounds = map.current.getBounds();
        bbox = {"sw": {"latitude": bounds._sw.lat, "longitude": bounds._sw.lng}, "ne": {"latitude": bounds._ne.lat, "longitude": bounds._ne.lng}};
      }
    }

    const subType = this.chooseSubType(currentTab);
    const { cardListPageNum: page, cardList } = cardListData[subType];
    let loadedIds = [];

    // need to pass prettyID to stop dupes from
    // being fetched
    if (
      currentTab === 'MOVIES' ||
      currentTab === 'RECIPES' ||
      currentTab === 'ACTIVITIES' ||
      currentTab === 'PLACES'
    ) {
      loadedIds = getCardPrettyIds(cardList);
    }

    // throws the event flag
    const isEvent = currentTab === 'EVENTS';

    fetchCardList(
      {
        page,
        loaded_ids: loadedIds,
        status: cardStatus,
        // only place/event have mapview, also that endpoint is a GET and
        // translates to query params, not so with the other verticals
        ...(viewType === 'MAP' ? { page_size: 50 } : {}),
        ...(viewType === 'MAP' ? { bbox: bbox } : {}),
        ...(Array.isArray(neighborhood) && neighborhood.length
          ? { nh_unique_slugs: neighborhood.map(({ value }) => value) }
          : {}),
        ...(Array.isArray(category) && category.length
          ? { cat_unique_slug_list: category.map(({ value }) => value) }
          : {}),
        ...(isEvent ? { is_event: true } : {}),
        // yes... many ways to make a string query...
        ...(subType === 'place' || subType === 'event' ? { query } : {}),
        ...(subType === 'activity' ? { name: query } : {}),
        ...(subType === 'movie' || subType === 'recipe'
          ? { title: query }
          : {}),
      },
      'collection',
      subType
    );
  };

  /**
   * @description Method for getting additional card data and updating
   * state with said data
   *
   * @returns {void}
   */
  getMoreData = () =>
    this.setState({ hasMore: false, fetchingData: true }, this.getCardList);

  /**
   * @description Search bar method which cleared rendered cards
   * and invokes clearCardList() reducer, filtering results.
   *
   * @param {String} query: Query string to be searched
   *
   * @returns {void}
   */
  searchTerm = (query) => {
    const { clearCardList, verticalForm } = this.props;
    const { currentTab } = this.state;
    const subType = this.chooseSubType(currentTab);
    const { cardListData } = verticalForm;
    cardListData[subType].cardListPageNum = 1;

    clearCardList(subType);

    // this.dismissWidget();
    this.setState({ query }, this.getCardList);
  };

  /**
   * @description Updates selected card array to update state with
   * 'onCheckBoxSelected'
   *
   * @param {Object} prevState: Previous state
   * @param {String} cardId: String for card id
   * @param {Object} cardObj: Card data
   *
   * @returns {{
   *  selectedCards: Object[],
   *  selectedCardsObjects: Object[],
   * }}
   */
  updateSelectCards = (prevState, cardId, cardObj) => {
    let selectedCards = [...prevState.selectedCards];
    // added selected card obj since just the ID sucks when Searching
    // not clean but needed to squash this bug
    let selectedCardsObjects = [...prevState.selectedCardsObjects];

    if (selectedCards.includes(cardId)) {
      selectedCards = selectedCards.filter((id) => id !== cardId);
    } else {
      selectedCards.push(cardId);
    }

    let objectIncluded = false;

    selectedCardsObjects.forEach((card) => {
      if (card.id === cardObj.id) objectIncluded = true;
    });

    if (objectIncluded) {
      selectedCardsObjects = selectedCardsObjects.filter(
        (card) => card.id !== cardObj.id
      );
    } else {
      selectedCardsObjects.push(cardObj);
    }

    return { selectedCards, selectedCardsObjects };
  };

  /**
   * @description Selects card shown by css highlight
   *
   * @param {String} cardId: String for card id
   *
   * @returns {void}
   */
  onCheckBoxSelected = (cardId, cardObj) => {
    this.setState((prevState) =>
      this.updateSelectCards(prevState, cardId, cardObj)
    );
  };

  /**
   * @description Renders cards to the Modal
   *
   * @param {Array} cards: Array of card objects
   *
   * @returns {JSX} HTML components to be rendered to the DOM
   */
  generateContent = (cards) => {
    const { selectedCards } = this.state;

    const cardContent = cards.map((card) => {
      const {
        id,
        published_date: publishedDate,
        modified: modifiedDate,
        status,
        images,
        name,
        title,
        phrase,
        last_admin_review,
      } = card;
      const imageURL = getImageURL(images);

      const cardClass = cx([
        'card',
        { 'selected-card': selectedCards.includes(id) },
      ]);

      return (
        <Card
          key={id}
          id={id}
          className={cardClass}
          status={status}
          date={getDateBasedOnStatus(status, modifiedDate, publishedDate)}
          imageUrl={imageURL}
          onClick={() => {
            this.onCheckBoxSelected(id, card);
          }}
          title={name || title}
          phrase={phrase}
          adminReview={last_admin_review}
          hideMenu
          hideCheckBox
          shouldDisplayNewTabOpenCTA={false}
        />
      );
    });
    return <div className="card-container">{cardContent}</div>;
  };

  /**
   * @description Method to clear/set selectedCards state to empty array
   *
   * @returns {void}
   */
  dismissWidget = () =>
    this.setState({ selectedCards: [], selectedCardsObjects: [] });

  /**
   * @description Adds selected cards for curation. Fires when the DONE
   * button is pressed on the WidgetBox component
   *
   * @returns {void}
   */
  performBulkOperation = () => {
    const { modalClosed, addSelectedCard, resetCardSelectionState } =
      this.props;
    const { selectedCards, selectedCardsObjects } = this.state;

    addSelectedCard(selectedCardsObjects);
    modalClosed();
    resetCardSelectionState();
  };

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  //                  Filter Methods
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  /**
   * @description Fires when filter options are selected
   *
   * @param {String} returnType - string for data type
   * @param {Array} data - of object  data to set to state
   * @param {Function} callback - to fire after state update
   *
   * @returns {void}
   */
  onFilterSelect = (returnType, data, callback = () => {}) => {
    const { resetCardSelectionState } = this.props;
    let selectedData = data;

    if (returnType === 'cardStatus') {
      if (data.value) selectedData = data.value;
      else selectedData = 'published';
    }

    // prevents a bug with null being passed when deleting filters
    if (
      (returnType === 'category' || returnType === 'neighborhood') &&
      selectedData === null
    )
      selectedData = [];

    if (returnType === 'currentTab') {
      if (data.value) selectedData = data.value;
      else selectedData = 'PLACES';

      // selection will only update one filter at a time
      // however, we need to force the modal back into list
      // view if user selects any tab other than places and events
      // since this dropdown is not rendered on the other tab types
      if (data.value !== 'PLACES' && data.value !== 'EVENTS') {
        this.setState({ viewType: 'LIST' });
      }
    }

    if (returnType === 'viewType') {
      if (data.value) selectedData = data.value;
      else selectedData = 'LIST';
    }

    resetCardSelectionState();

    // API call to filter cards based on value selected from dropdown
    this.setState({ [returnType]: selectedData }, callback);
  };

  /**
   * @description clears away filters
   *
   * @returns {void}
   */
  clearFilters = () => {
    const { resetCardSelectionState } = this.props;

    resetCardSelectionState();

    this.setState(
      {
        category: [],
        currentTab: 'PLACES',
        neighborhood: [],
        viewType: 'LIST',
      },
      this.getCardList
    );
  };

  /**
   * @description gets filter for CardList
   *
   * @returns {void}
   */
  getFilters = () => {
    const { verticalTypes, categories, neighborhoods } = this.props;
    const { cardStatus, category, currentTab, neighborhood, viewType } =
      this.state;

    const {
      FILTER_DROP_DOWN: {
        CARD_STATUS,
        CATEGORY,
        CURRENT_TAB,
        LOCATION,
        VIEW_TYPE,
      },
    } = COLLECTION.CARD_SELECTION_MODAL;

    const chooseCategoriesByTab = (currTab) => {
      const {
        movieCategories,
        placeCategories,
        recipeCategories,
        recreationCategories,
      } = categories;

      switch (currTab) {
        case 'PLACES':
        case 'EVENTS': {
          return placeCategories;
        }
        case 'MOVIES': {
          return movieCategories;
        }
        case 'RECIPES': {
          return recipeCategories;
        }
        case 'ACTIVITIES': {
          return recreationCategories;
        }
        default:
          return placeCategories;
      }
    };

    const baseFilters = [
      {
        name: CARD_STATUS.LABEL,
        returnType: CARD_STATUS.RETURN_TYPE,
        options: [
          {
            label: 'Published',
            value: 'published',
          },
          {
            label: 'Drafts',
            value: 'draft',
          },
        ],
        value: cardStatus,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.SELECT,
        onMenuClose: this.getCardList,
        isSearchable: false,
      },
      {
        name: CURRENT_TAB.LABEL,
        returnType: CURRENT_TAB.RETURN_TYPE,
        options: verticalTypes,
        value: currentTab,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.SELECT,
        onMenuClose: this.getCardList,
        isSearchable: false,
      },
      {
        name: CATEGORY.LABEL,
        returnType: CATEGORY.RETURN_TYPE,
        options: formGroupOptions(
          chooseCategoriesByTab(currentTab),
          'categories',
          'unique_slug',
          'name'
        ),
        value: category,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.getCardList,
        isSearchable: true,
      },
    ];

    const placeSpecificFilters = [
      {
        name: LOCATION.LABEL,
        returnType: LOCATION.RETURN_TYPE,
        options: formGroupOptions(neighborhoods, 'neighborhoods'),
        value: neighborhood,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.getCardList,
        isSearchable: true,
      },
      {
        name: VIEW_TYPE.LABEL,
        returnType: VIEW_TYPE.RETURN_TYPE,
        options: [
          {
            label: 'List View',
            value: 'LIST',
          },
          {
            label: 'Map View',
            value: 'MAP',
          },
        ],
        value: viewType,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.SELECT,
        onMenuClose: this.getCardList,
        isSearchable: false,
      },
    ];

    if (currentTab === 'PLACES' || currentTab === 'EVENTS') {
      baseFilters.push(...placeSpecificFilters);
    }

    return baseFilters;
  };

  render() {
    const {
      show,
      modalClosed,
      className,
      verticalForm,
      resetCardSelectionState,
    } = this.props;

    const {
      currentTab,
      hasMore,
      query,
      selectedCards,
      viewType,
      fetchingData,
    } = this.state;

    const {
      BULK_ACTIONS: { SELECT },
    } = GENERIC;

    const { cardList, isCardListDataFetched, cardListDataCount } =
      verticalForm.cardListData[this.chooseSubType(currentTab)];

    const containerClass = cx(['card-list', `${className}`]);

    // Loading animation comp, conditionally rendered of course!
    let content = <Loader />;

    if (isCardListDataFetched) {
      if (cardListDataCount === 0) {
        content = <NoDataFound />;
      } else {
        content = this.generateContent(cardList);
      }
    }

    const chooseMapContent = () => {
      if (viewType === 'MAP' && cardList.length) {
        const { verticalType } = this.props;
        const { cardListData, formData } = verticalForm;
        const { lat, lng } = formData;
        const {map, mapContainer} = this.state;

        const isCardPairing =
          newCardDesignVerticalSet.has(verticalType) && lat && lng;

        const markerData = [];

        if (isCardPairing) markerData.push(formData);

        markerData.push(
          ...cardListData[this.chooseSubType(currentTab)].cardList
        );

        return (
          <MapView
            map={map}
            mapContainer={mapContainer}
            markerData={markerData}
            parentCard={isCardPairing ? formData : undefined}
            selectedCards={selectedCards}
            onMarkerClick={this.onCheckBoxSelected}
            initialZoom={isCardPairing ? 15 : undefined}
            longitude={isCardPairing ? lng : undefined}
            latitude={isCardPairing ? lat : undefined}
          />
        );
      }
      return <Loader />;
    };

    return (
      <Modal
        title="Select Units"
        show={show}
        modalClosed={() => {
          modalClosed();
          resetCardSelectionState();
        }}
        // old css class. it works just use it
        customClasses="curated-modal-selected"
      >
        <div className={containerClass}>
          <FilterSection
            filters={this.getFilters()}
            searchValue={query}
            onSearchInput={this.searchTerm}
            clearFilters={this.clearFilters}
            singleRow
            shouldDisplayImageInspectorSwitch={false}
          />
          <div className="card-list-content-container">
            {viewType === 'MAP' ? (
              chooseMapContent()
            ) : (
              <InfiniteScroller
                className="card-list-content"
                hasMore={hasMore}
                loadMore={this.getMoreData}
                useWindow={false}
              >
                {content}
              </InfiniteScroller>
            )}
          </div>
        </div>
        <WidgetBox
          className="card-list-widget"
          data={
            viewType === 'MAP'
              ? [
                  {
                    label: 'Fetch More',
                    onClick: (e) => {
                      e.preventDefault();
                      this.getMoreData();
                    },
                    disabled: !hasMore,
                  },
                  {
                    label: SELECT,
                    onClick: (e) => {
                      e.preventDefault();
                      this.performBulkOperation();
                    },
                  },
                ]
              : [
                  {
                    label: SELECT,
                    onClick: (e) => {
                      e.preventDefault();
                      this.performBulkOperation();
                    },
                  },
                ]
          }
          onDeselectClicked={this.dismissWidget}
          actionText={getSelectedCardCount(selectedCards)}
          showLoadingIcon={fetchingData}
          showStatus
        />
      </Modal>
    );
  }
}

// --------------------------------------------------------------------------|
//                          PropTypes Check - CardList
// --------------------------------------------------------------------------|
CardList.propTypes = {
  verticalForm: PropTypes.object.isRequired,
  fetchCardList: PropTypes.func.isRequired,
  clearCardList: PropTypes.func.isRequired,
  addSelectedCard: PropTypes.func.isRequired,
  resetCardSelectionState: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  tabData: PropTypes.object.isRequired,
  modalClosed: PropTypes.func.isRequired,
  className: PropTypes.string,
  selectedCards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  verticalType: PropTypes.string.isRequired,
};

CardList.defaultProps = {
  className: '',
};

// --------------------------------------------------------------------------|
//                      CardList Export with redux connect
// --------------------------------------------------------------------------|
export default connect(mapStateToProps, mapDispatchToProps)(CardList);
