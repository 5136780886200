/**
 * ************************************
 *
 * @module  Filters.js
 * @author  Matt P
 * @date    03/22/2021
 * @description Container for the filters Settings tab
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getFiltersListAction,
  getFiltersRulesListAction,
  toggleEnable as toggleEnableAction,
  toggleFeature as toggleFeatureAction,
  deleteFilterAction,
  clearListAction,
} from 'store/actions/Filters.action';

import { getCategoriesListAction } from 'store/actions/Categories.actions';

import {
  CardBanner,
  CobTable,
  NoDataFound,
  Loader,
  DeleteModal,
} from 'components';

import { FILTER } from 'constants.js';

import { cols, updateMapper } from './FiltersUtils';

import Filter from './Filter/Filter';

import './Filters.scss';

// ----------------------------------------------------------------------------|
//                        Redux - Property Mapping
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  getFiltersList: (data) => dispatch(getFiltersListAction(data)),
  getFiltersRulesList: (data) => dispatch(getFiltersRulesListAction(data)),
  getCategoriesList: (data) => dispatch(getCategoriesListAction(data)),
  clearCategoriesList: () => dispatch(clearListAction()),
  clearFiltersList: () => dispatch(clearListAction()),
  toggleEnable: (category) => dispatch(toggleEnableAction(category)),
  toggleFeature: (category) => dispatch(toggleFeatureAction(category)),
  deleteFilter: (id) => dispatch(deleteFilterAction(id)),
});

const mapStateToProps = (state) => ({
  filterLocationsDropdownOptions: state.categories.filterLocationsDropdownOptions,
  filters: state.filters.filters,
  filterRules: state.filters.filterRules,
  initialDataLoaded: state.filters.initialDataLoaded,
  filterRulesDataDownloaded: state.filters.filterRulesDataDownloaded,
});

// ----------------------------------------------------------------------------|
//                   React Class Component - Categories
// ----------------------------------------------------------------------------|
class Filters extends Component {
  // --------------------------------------------------------------------------|
  //                            PropTypes Check
  // --------------------------------------------------------------------------|
  static propTypes = {
    filters: PropTypes.array.isRequired,
    initialDataLoaded: PropTypes.bool.isRequired,
    filterRulesDataDownloaded: PropTypes.bool.isRequired,
    getFiltersList: PropTypes.func.isRequired,
    getFiltersRulesList: PropTypes.func.isRequired,
    getCategoriesList: PropTypes.func.isRequired,
    clearFiltersList: PropTypes.func.isRequired,
    clearCategoriesList: PropTypes.func.isRequired,
    toggleEnable: PropTypes.func.isRequired,
    toggleFeature: PropTypes.func.isRequired,
    deleteFilter: PropTypes.func.isRequired,
    filterLocationsDropdownOptions: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      pageTitle: FILTER.TITLE,
      showModal: false,
      editingFilter: null,
      deleteFilterData: null,
    };
  }

  // --------------------------------------------------------------------------|
  //                            Lifecycle Methods
  // --------------------------------------------------------------------------|
  componentDidMount() {
    const {
      getFiltersList,
      getFiltersRulesList,
      getCategoriesList,
    } = this.props;

    getFiltersList();
    getFiltersRulesList();
    getCategoriesList();
  }

  componentWillUnmount() {
    const { clearCategoriesList, clearFiltersList } = this.props;

    clearFiltersList();
    clearCategoriesList();
  }

  // --------------------------------------------------------------------------|
  //                      Component Methods - Filters
  // --------------------------------------------------------------------------|
  /**
   * @description Closes the create new modal by toggling state
   */
  closeModal = () => {
    this.setState({ showModal: false });
  };

  /**
   * @description Closes edit modal by toggling state
   */
  closeEditModal = () => {
    this.setState({ editingFilter: null, showEditModal: false });
  };

  /**
   * @description opens create new modal by toggling state
   */
  openModal = () => {
    this.setState({ showModal: true });
  };

  /**
   * @description opens edit modal by toggling state
   *
   * @param {Object} filter
   */
  showEditModal = (filter) => {
    this.setState({ editingFilter: filter, showEditModal: true });
  };

  /**
   * @description opens modal to create new filter by toggling state
   */
  createNewCategory = () => {
    this.openModal();
  };

  /**
   * @description Take operation type, filter. Prepare SAGA action data.
   *
   * @param operation ['DELETE', 'ENABLE', 'FEATURE']
   * @param filter
   */
  buildSagaData = (operation, filter) => {
    const key = updateMapper[operation];

    const apiData = {
      ...{ id: filter.id },
      ...{ name: filter.name },
      ...(key && { [key]: !filter[key] }),
    };

    return { filter, apiData };
  };

  /**
   * @description Toggles the enabling and disabling of a filter
   *
   * @param filter
   */
  toggleEnable = (filter) => {
    const { toggleEnable } = this.props;

    toggleEnable(this.buildSagaData(FILTER.ENABLE, filter));
  };

  /**
   * @description Toggles the ability to feature a filter
   *
   * @param {Object} filter
   */
  toggleFeature = (filter) => {
    const { toggleFeature, filters } = this.props;

    // only one filter can be featured at a time
    // checks if there is another on a true is_featured
    // submission
    for (let i = 0; i < filters.length; i += 1) {
      if (filters[i].is_featured && filters[i].id !== filter.id) {
        toast.error(FILTER.TOAST.FEATURE_ONLY_ONE_AT_A_TIME);
        return;
      }
    }

    toggleFeature(this.buildSagaData(FILTER.FEATURE, filter));
  };

  /**
   * @description Deletes a filter
   *
   * @param {Object} filter
   */
  deleteCategory = (filter) => {
    const { deleteFilter } = this.props;

    deleteFilter(this.buildSagaData(FILTER.DELETE, filter));
  };

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  Categories rendering
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  /**
   * @description Renders Modal on click
   */
  renderCreateModal = () => {
    const { showModal } = this.state;
    const {filterLocationsDropdownOptions} = this.props;

    return <Filter showModal={showModal} closeModal={this.closeModal} filterLocationsDropdownOptions={filterLocationsDropdownOptions} />;
  };

  /**
   * @description Renders Edit Modal on click
   */
  renderEditModal = () => {
    const { showEditModal, editingFilter } = this.state;
    const {filterLocationsDropdownOptions} = this.props;

    return (
      <Filter
        showModal={showEditModal}
        closeModal={this.closeEditModal}
        isEditing
        passedFilter={editingFilter}
        filterLocationsDropdownOptions={filterLocationsDropdownOptions}
      />
    );
  };

  /**
   * @description Renders Delete Modal on click
   */
  renderDeleteModal = () => {
    const { deleteFilterData } = this.state;

    return (
      <DeleteModal
        cancelClick={() =>
          this.setState({ showDeleteModal: false, deleteFilterData: null })
        }
        deleteClick={() => {
          const data = deleteFilterData;
          this.deleteCategory(data);
          this.setState({ deleteFilterData: null, showDeleteModal: false });
        }}
        showHeader={false}
      />
    );
  };

  /**
   * @description Renders content on load
   */
  renderContent = () => {
    const {
      filters,
      initialDataLoaded,
      filterRulesDataDownloaded,
      filterLocationsDropdownOptions,
    } = this.props;

    const NO_DATA = !Object.keys(filters).length;

    if (initialDataLoaded && filterRulesDataDownloaded) {
      if (NO_DATA) {
        return <NoDataFound subMessage={FILTER.NO_DATA} />;
      }

      return (
        <CobTable
          rows={filters}
          cols={cols}
          toggleFeatured={(filter) => this.toggleFeature(filter)}
          toggleEnable={(filter) => this.toggleEnable(filter)}
          showEditModal={(filter) => this.showEditModal(filter)}
          deleteCategory={(filter) => {
            this.setState({
              deleteFilterData: filter,
              showDeleteModal: true,
            });
          }}
          filterLocationsDropdownOptions={filterLocationsDropdownOptions}
        />
      );
    }

    return <Loader />;
  };

  render() {
    const { pageTitle, showModal, showEditModal, showDeleteModal } = this.state;

    return (
      <div className="filters-container">
        <CardBanner
          isDropdownEnabled={false}
          isSearchEnabled={false}
          title={pageTitle}
          newBtnClick={this.createNewCategory}
        />
        {this.renderContent()}
        {showModal && this.renderCreateModal()}
        {showEditModal && this.renderEditModal()}
        {showDeleteModal && this.renderDeleteModal()}
      </div>
    );
  }
}
// ----------------------------------------------------------------------------|
//                  filters Export with Redux Connect
// ----------------------------------------------------------------------------|
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Filters)
);
