/**
 * ************************************
 *
 * @module  rootReducer.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description file to combine all reducers
 *
 * ************************************
 */
/* eslint-disable import/order */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import { combineReducers } from 'redux';

import auth from 'store/reducers/Auth.reducer';

import categories from 'store/reducers/Categories.reducer';
import cities from 'store/reducers/Cities.reducer';
import curatedCard from 'store/reducers/CuratedCard.reducer';
import curated from 'store/reducers/CuratedCards.reducer';

import filters from 'store/reducers/Filters.reducer';

import place from 'store/reducers/Place.reducer';
import places from 'store/reducers/Places.reducer';

import verticalDisplay from './reducers/VerticalDisplay.reducer';
import verticalForm from './reducers/VerticalForm.reducer';

// ----------------------------------------------------------------------------|
//                                 DEPRECIATED
// ----------------------------------------------------------------------------|
import activity from 'store/reducers/~depreciated/Activity.reducer';
import events from 'store/reducers/~depreciated/Events.reducer';
import event from 'store/reducers/~depreciated/Event.reducer';
import movies from 'store/reducers/~depreciated/Movies.reducer';
import movie from 'store/reducers/~depreciated/Movie.reducer';
import recipe from 'store/reducers/~depreciated/Recipe.reducer';
import recipes from 'store/reducers/~depreciated/Recipes.reducer';
// ----------------------------------------------------------------------------|
//                              Combined Reducers
// ----------------------------------------------------------------------------|
const rootReducer = combineReducers({
  auth,
  categories,
  cities,
  curatedCard,
  curated,
  filters,
  place,
  places,
  verticalDisplay,
  verticalForm,

  // DEPRECIATED
  activity,
  events,
  event,
  movies,
  movie,
  recipe,
  recipes,
});

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default rootReducer;
