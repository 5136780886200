/**
 * ************************************
 *
 * @module  CardList.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Renders a Modal component to select curated
 * cards with infinite scrolling. Connects to the curatedCard store
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Card from 'components/Card/Card';
import Modal from 'components/Modal/Modal';
import InfiniteScroller from 'components/InfiniteScroller/InfiniteScroller';
import Loader from 'components/Loader/Loader';
import NoDataFound from 'components/NoDataFound/NoDataFound';
import SearchBar from 'components/SearchBar/SearchBar';
import WidgetBox from 'components/WidgetBox/WidgetBox';

import { connect } from 'react-redux';
import {
  fetchCardListAction,
  clearCardListAction,
  addSelectedCardAction,
} from 'store/actions/CuratedCard.action';

import cx from 'classnames';

import { GENERIC } from 'constants.js';

import {
  getImageURL,
  getDateBasedOnStatus,
  checkHasMore,
  getSelectedCardCount,
  getCardPrettyIds,
} from 'utils/utils';

import 'components/CardSelector/CardList/CardList.scss';

// ----------------------------------------------------------------------------|
//                            Redux - Property Mapping
// ----------------------------------------------------------------------------|
const mapStateToProps = (state) => ({
  curated: state.curatedCard,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCardList: (data) => dispatch(fetchCardListAction(data)),
  clearCardList: () => dispatch(clearCardListAction()),
  addSelectedCard: (data) => dispatch(addSelectedCardAction(data)),
});

// ----------------------------------------------------------------------------|
//                        React Class Component - CardList
// ----------------------------------------------------------------------------|
class CardList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 'PLACES',
      hasMore: false,
      query: '',
      selectedCards: [],
    };
  }

  // --------------------------------------------------------------------------|
  //                    Class Component - Lifecycle Methods
  // --------------------------------------------------------------------------|
  componentDidMount() {
    // on component render, fetch cards
    this.getCardList();
  }

  componentDidUpdate(prevProps) {
    // loads more cards for infinite scroll on component update
    const { curated } = this.props;
    const { cardListData } = curated;

    if (
      cardListData &&
      cardListData.length !== prevProps.curated.cardListData.length
    ) {
      this.setHasMore();
    }
  }

  componentWillUnmount() {
    const { clearCardList } = this.props;
    // on unmount/modal close, clear card list
    clearCardList();
  }

  // --------------------------------------------------------------------------|
  //                      Class Component - Methods
  // --------------------------------------------------------------------------|
  /**
   * @description Updates state with conditional boolean
   * if more cards are available for infinite scroll
   */
  setHasMore = () => {
    const { curated } = this.props;
    const { cardListData, cardListDataCount } = curated;
    const hasMore = checkHasMore(cardListData.length, cardListDataCount);

    this.setState({ hasMore });
  };

  /**
   * @description Fetches card list by checking the currentTab
   * (Places || Events) and invokes our fetchCardList reducer
   */
  getCardList = () => {
    const { curated, fetchCardList } = this.props;
    const { currentTab, query } = this.state;
    const { cardListPageNum: page } = curated;
    let loadedIds = [];

    // need to pass prettyID to stop dupes from
    // being fetched
    if (
      currentTab === 'MOVIES' ||
      currentTab === 'RECIPES' ||
      currentTab === 'ACTIVITIES'
    ) {
      const { cardListData } = curated;

      loadedIds = getCardPrettyIds(cardListData);
    }

    // throws the event flag
    const isEvent = currentTab === 'EVENTS';

    fetchCardList({
      query,
      is_event: isEvent,
      page,
      currentTab,
      loaded_ids: loadedIds,
    });
  };

  /**
   * @description Changes tab between Places and Events. Also
   *  clears the current card list in anticipation of the newly fetched list
   *
   * @param {Object} e: The "event object" for grabbing tab id
   */
  changeTab = (e) => {
    const { clearCardList } = this.props;
    const currentTab = e.target.id;

    clearCardList();

    this.dismissWidget();
    this.setState({ currentTab }, this.getCardList);
  };

  /**
   * @description Changes tab between Places and Events
   *
   * @returns {Array} Returns array of JSX tab list (<li>) elements
   */
  renderTabs = () => {
    const { tabData } = this.props;
    const { currentTab } = this.state;
    return Object.keys(tabData).map((tab, index) => (
      <li
        className={cx('tab-item', { active: currentTab === tabData[tab].name })}
        key={index}
        id={tabData[tab].name}
        onClick={this.changeTab}
      >
        {tabData[tab].displayName}
      </li>
    ));
  };

  /**
   * @description Method for getting additional card data and updating
   * state with said data
   */
  getMoreData = () => this.setState({ hasMore: false }, this.getCardList);

  /**
   * @description Search bar method which cleared rendered cards
   * and invokes clearCardList() reducer, filtering results.
   *
   * @param {String} query: Query string to be searched
   */
  searchTerm = (query) => {
    const { clearCardList } = this.props;

    clearCardList();

    this.dismissWidget();
    this.setState({ query }, this.getCardList);
  };

  /**
   * @description Updates selected card array to update state with
   * 'onCheckBoxSelected'
   *
   * @param {Object} prevState: Previous state
   * @param {String} cardId: String for card id
   */
  updateSelectCards = (prevState, cardId) => {
    let selectedCards = [...prevState.selectedCards];
    if (selectedCards.includes(cardId)) {
      selectedCards = selectedCards.filter((id) => id !== cardId);
    } else {
      selectedCards.push(cardId);
    }
    return { selectedCards };
  };

  /**
   * @description Selects card shown by css highlight
   *
   * @param {String} cardId: String for card id
   */
  onCheckBoxSelected = (cardId) => {
    this.setState((prevState) => this.updateSelectCards(prevState, cardId));
  };

  /**
   * @description Renders cards to the Modal
   *
   * @param {Array} cards: Array of card objects
   *
   * @returns {JSX} HTML components to be rendered to the DOM
   */
  generateContent = (cards) => {
    const { selectedCards, currentTab } = this.state;

    const cardContent = cards.map((card) => {
      const name =
        currentTab === 'MOVIES' || currentTab === 'RECIPES'
          ? card.title
          : card.name;

      const {
        id,
        published_date: publishedDate,
        modified: modifiedDate,
        status,
        images,
        // name,
        phrase,
      } = card;
      const imageURL = getImageURL(images);

      const cardClass = cx([
        'card',
        { 'selected-card': selectedCards.includes(id) },
      ]);

      return (
        <Card
          key={id}
          id={id}
          className={cardClass}
          status={status}
          date={getDateBasedOnStatus(status, modifiedDate, publishedDate)}
          imageUrl={imageURL}
          onClick={() => this.onCheckBoxSelected(id)}
          title={name}
          phrase={phrase}
          hideMenu
          hideCheckBox
          shouldDisplayNewTabOpenCTA={false}
        />
      );
    });
    return <div className="card-container">{cardContent}</div>;
  };

  /**
   * @description Method to clear/set selectedCards state to empty array
   */
  dismissWidget = () => this.setState({ selectedCards: [] });

  /**
   * @description Adds selected cards for curation. Fires when the DONE
   * button is pressed on the WidgetBox component
   */
  performBulkOperation = () => {
    const { modalClosed, addSelectedCard } = this.props;
    const { selectedCards } = this.state;

    modalClosed();
    addSelectedCard(selectedCards);
  };

  render() {
    const { show, modalClosed, className, curated } = this.props;
    const { hasMore, query, selectedCards } = this.state;
    const {
      BULK_ACTIONS: { SELECT },
    } = GENERIC;

    const { isCardListDataFetched, cardListData, cardListDataCount } = curated;

    const containerClass = cx(['card-list', `${className}`]);

    // Loading animation comp, conditionally rendered of course!
    let content = <Loader />;

    if (isCardListDataFetched) {
      if (cardListDataCount === 0) {
        content = <NoDataFound />;
      } else {
        content = this.generateContent(cardListData);
      }
    }

    // Renders bar with DONE button input and
    // car selected total when a card is selected
    const selectWidget = selectedCards.length !== 0 && (
      <WidgetBox
        className="card-list-widget"
        data={[
          {
            label: SELECT,
            onClick: () => {
              this.performBulkOperation();
            },
          },
        ]}
        onDeselectClicked={this.dismissWidget}
        actionText={getSelectedCardCount(selectedCards)}
        showStatus
      />
    );

    return (
      <Modal
        title="Select Curated Cards"
        show={show}
        modalClosed={modalClosed}
        customClasses="curated-modal-selected"
      >
        <div className={containerClass}>
          <ul className="modal-tabs">{this.renderTabs()}</ul>
          <SearchBar name="search" value={query} onSubmit={this.searchTerm} />
          <div className="card-list-content-container">
            <InfiniteScroller
              className="card-list-content"
              hasMore={hasMore}
              loadMore={this.getMoreData}
              useWindow={false}
            >
              {content}
            </InfiniteScroller>
          </div>
        </div>
        {selectWidget}
      </Modal>
    );
  }
}

// --------------------------------------------------------------------------|
//                          PropTypes Check - CardList
// --------------------------------------------------------------------------|
CardList.propTypes = {
  curated: PropTypes.object.isRequired,
  fetchCardList: PropTypes.func.isRequired,
  clearCardList: PropTypes.func.isRequired,
  addSelectedCard: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  tabData: PropTypes.object,
  modalClosed: PropTypes.func.isRequired,
  className: PropTypes.string,
};

CardList.defaultProps = {
  className: '',
};

// --------------------------------------------------------------------------|
//                      CardList Export with redux connect
// --------------------------------------------------------------------------|
export default connect(mapStateToProps, mapDispatchToProps)(CardList);
