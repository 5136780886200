/**
 * ************************************
 *
 * @module  RecipeService.js
 * @author  Matt P
 * @date    02/02/2021
 * @description File to prepare Recipe form
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import {
  chooseItemizedDescription,
  isValidURL,
  mapDetailedCategories,
} from 'utils/utils';

import { filterEmptyMultiLinks } from 'utils/FormFunc';

// ----------------------------------------------------------------------------|
//                             Recipe Service
// ----------------------------------------------------------------------------|
const RecipeService = {
  /**
   * @description processes the vertical specific data that is incoming from
   * the backend to populate an edit form.
   *
   * @param {Object} dataObj - of date data
   * @param {Object} additionalOptions - any additional data that may be
   * required to process incoming data
   *
   * @returns {Object}
   */
  processVerticalSpecificData(dataObj = {}, additionalOptions = {}) {
    const {
      description,
      itemized_description,
      links_to_recipe,
      phrase,
      main_categories,
      title,
      source_name,
      duration,
      prep_time,
      ingredients,
      meals,
      diets,
    } = dataObj;

    return {
      source_name: source_name || '',
      title: title || '',
      phrase: phrase || '',
      duration: duration || -1,
      prep_time: prep_time || -1,
      links_to_recipe: links_to_recipe || [],
      itemized_description: chooseItemizedDescription(
        itemized_description,
        description,
        RecipeService.defaultDescriptions
      ),
      // converts Ingredients from array of obj to what the form expects
      // array of strings only, targeting the name property on the object
      ingredients:
        ingredients && Array.isArray(ingredients)
          ? ingredients.map((ing) => ing.name)
          : [],
      meal_type: meals || [],
      diet: diets || [],
      main_categories: main_categories
        ? mapDetailedCategories(main_categories, additionalOptions.categoryMap)
        : [],
    };
  },

  /**
   * @description preps the vertical specific form data to be passed in the
   * request body for a form update
   *
   * @param {Object} formData - formData
   * @param {Object} additionalOptions - any additional data that may be
   * required to process outgoing data
   *
   * @returns {Object} for requestBody
   */
  prepareVerticalSpecificFormData(formData = {}, additionalOptions = {}) {
    const {
      phrase,
      source_name,
      duration,
      prep_time,
      links_to_recipe,
      ingredients,
    } = formData;

    return {
      phrase: phrase ? phrase.trim() : '',
      duration,
      prep_time,
      links_to_recipe:
        typeof links_to_recipe === 'string'
          ? [links_to_recipe]
          : links_to_recipe,
      source_name,
      // converts Ingredients from array of strings to array of
      // obj {name: '', quantity: ''}
      ingredients: filterEmptyMultiLinks(ingredients).map((ing) => ({
        name: ing,
        quantity: '',
      })),
    };
  },

  /**
   * @description error checking for 'publish' form submission
   *
   * @param {Object} data - formData
   *
   * @returns {Object} of errors
   */
  validate(data) {
    const {
      duration,
      ingredients,
      links_to_recipe,
      meal_type,
      prep_time,
    } = data;

    const errorsObj = {};

    if (meal_type && Array.isArray(meal_type) && meal_type.length === 0) {
      errorsObj.meal_type = 'At lease one meal type is required';
    }

    if (
      (typeof links_to_recipe === 'string' && !isValidURL(links_to_recipe)) ||
      (Array.isArray(links_to_recipe) &&
        links_to_recipe.length &&
        !isValidURL(links_to_recipe[0]))
    ) {
      errorsObj.links_to_recipe = 'Please enter a valid URL';
    }

    if (duration < 0) {
      errorsObj.duration = 'Duration is required';
    }

    if (prep_time < 0) {
      errorsObj.prep_time = 'Prep time is required';
    }

    // if (
    //   ingredients &&
    //   Array.isArray(ingredients) &&
    //   (ingredients.length === 0 || ingredients[0].length === 0)
    // ) {
    //   errorsObj.ingredients = 'At lease one ingredient is required';
    // }

    return errorsObj;
  },

  /**
   * @description default descriptions for the itemized_descriptions
   * form field
   */
  defaultDescriptions: [
    {
      header: 'What it is',
      body: '',
    },
    {
      header: 'Difficulty',
      body: '',
    },
    {
      header: 'Servings',
      body: '',
    },
    {
      header: 'Nutritional facts',
      body: '',
    },
  ],
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default RecipeService;
