/**
 * ************************************
 *
 * @module  PlacesFetchedDetails.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description PlaceFetchedDetails component
 * displays fetched 'cards'
 *
 * ************************************
 */
// ------------------------------------------------------------------------------------------|
//                                      Imports
// ------------------------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import { Button, Views } from 'components';

import { PLACE } from 'constants.js';
import { getPrice, sanitizeUrl } from 'utils/utils';

import './PlaceFetchedDetails.scss';

/**
 * @description generates neighborhood strings
 *
 * @param {Array} list - of neighborhoods
 *
 * @returns {String} - of neighborhoods
 */
const prepareNeighborhoodList = (list = []) => {
  // Checking if data is present and data is in array format
  if (list && Array.isArray(list)) {
    const res = list.reduce((acc, item) => `${acc + item.name}, `, '');
    // Remove last two characters
    return res.slice(0, -2);
  }
  return '-';
};

/**
 * @description generates fetched data rating string
 *
 * @param {Number} rating - of the location
 *
 * @returns {String}
 */
const generateRating = (rating) => (rating ? `${rating / 2}/5` : '-');

/**
 * @description generates fetched data categories string
 *
 * @param {Array} categories - of objects
 *
 * @returns {String}
 */
const generateCategories = (categories) => {
  if (categories && Array.isArray(categories)) {
    if (typeof categories[0] === 'string') {
      return categories.join(', ');
    }
    return categories.map((item) => item.name).join(', ');
  }
  return '-';
};

/**
 * @description generates fetched data main categories string
 *
 * @param {Array} categories - of strings
 *
 * @returns {String}
 */
const generatefsCategories = (categories) => {
  if (categories && categories.length) {
    return categories.join();
  }
  return '-';
};

const formatAddress = (address) => {
  return address
    .split(', ')
    .map((addr, index) => <p key={`address-p-${index}`}>{addr}</p>);
};

// ------------------------------------------------------------------------------------------|
//                       React Function Component - PlaceFetchedDetails
// ------------------------------------------------------------------------------------------|
const PlaceFetchedDetails = (props) => {
  const {
    imageURL,
    categories,
    name,
    neighborhoods,
    priceTier,
    rating,
    className,
    onReviewClick,
    fsCategories,
    fourthLayer,
    popularity,
    address,
  } = props;

  const { REVIEW_AND_PUBLISH } = PLACE;

  const containerClass = cx(['place-fetch-details', `${className}`]);

  const categoriesList = generateCategories(categories);
  const neighborhoodList = prepareNeighborhoodList(neighborhoods);
  const price = getPrice(priceTier);
  const rate = generateRating(rating);
  const fscategories = generatefsCategories(fsCategories);

  return (
    <div className={containerClass}>
      <div className="img-container">
        <img src={sanitizeUrl(imageURL)} alt="cover" className="cover-pic" />
      </div>
      <div className="content">
        <h5 className="categories">{categoriesList}</h5>
        <h1 className="name">{name}</h1>
        {fourthLayer ? (
          <div className="address-container">
            <p className="address">Address: </p>
            <p className="address-list">{formatAddress(address)}</p>
          </div>
        ) : (
          <p className="neighborhood">Neighborhood: {neighborhoodList}</p>
        )}
        <div className="details">
          <p className="cost">Cost: {price}</p>
          <p className="ratings">Rating: {rate}</p>
        </div>
        <div className="fscategories">
          <p className="fscategories-label">Categories:</p>
          <p>{fscategories}</p>
        </div>
        <Button className="review-btn" onClick={onReviewClick}>
          {REVIEW_AND_PUBLISH}
        </Button>
      </div>
      {fourthLayer ? <Views data={popularity} /> : ''}
    </div>
  );
};

// ------------------------------------------------------------------------------------|
//                               PropTypes Check - PlaceFetchedDetails
// ------------------------------------------------------------------------------------|
PlaceFetchedDetails.propTypes = {
  name: PropTypes.string.isRequired,
  onReviewClick: PropTypes.func.isRequired,
  imageURL: PropTypes.string,
  categories: PropTypes.array,
  neighborhoods: PropTypes.array,
  priceTier: PropTypes.string,
  rating: PropTypes.number,
  className: PropTypes.string,
  fsCategories: PropTypes.array,
  fourthLayer: PropTypes.bool,
  address: PropTypes.string,
};

// ------------------------------------------------------------------------------------|
//                               Default Props - PlaceFetchedDetails
// ------------------------------------------------------------------------------------|
PlaceFetchedDetails.defaultProps = {
  imageURL: '',
  categories: [],
  neighborhoods: [],
  priceTier: '',
  rating: '-',
  className: '',
  fsCategories: [],
  fourthLayer: false,
  address: '',
};

// ----------------------------------------------------------------------------------------|
//                                   PlaceFetchedDetails Export
// ----------------------------------------------------------------------------------------|
export default PlaceFetchedDetails;
