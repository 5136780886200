/**
 * ************************************
 *
 * @module  ButtonSearchBar.js
 * @author  Matt P
 * @date    11/10/2021
 * @description SearchBar with button submission functionality.
 * Does not automatically fire text search with debounce
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SearchBar, Button } from 'components';

import cx from 'classnames';

import './ButtonSearchBar.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const ButtonSearchBar = ({ name, onSubmission, placeholder, className }) => {
  const [queryValue, setQueryValue] = useState('');

  const buttonSearchBarClass = cx(['button-search-bar', `${className}`]);

  const onInputChange = (input) => {
    // if user clears, onSubmission handler with empty string
    if (input === '') onSubmission(input);

    setQueryValue(input);
  };

  const onButtonSubmit = () => {
    onSubmission(queryValue);
  };

  return (
    <div className={buttonSearchBarClass}>
      <SearchBar
        name={name}
        value={queryValue}
        onChange={onInputChange}
        placeholder={placeholder}
        onSubmit={onButtonSubmit}
      />
      <Button className="submit-search-btn" onClick={onButtonSubmit}>
        Search
      </Button>
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                             PropTypes Check
// ----------------------------------------------------------------------------|
ButtonSearchBar.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onSubmission: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                           Default Props
// ----------------------------------------------------------------------------|
ButtonSearchBar.defaultProps = {
  className: '',
  placeholder: '',
};

// ----------------------------------------------------------------------------|
//                             Export
// ----------------------------------------------------------------------------|
export default ButtonSearchBar;
