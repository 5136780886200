/**
 * ************************************
 *
 * @module  Categories.saga.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Categories saga file
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import { call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import API from 'API';

import ACTIONS from 'store/actions/actionTypes';
import { clearAdminCategories } from 'store/actions/Places.action';

import { reportAPIError } from 'utils/ErrorHandlingUtils';

import { CATEGORY } from 'constants.js';

// ----------------------------------------------------------------------------|
//                                Sagas - Categories
// ----------------------------------------------------------------------------|
/**
 * @description gets the list of categories
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* getCategoryList(action) {
  const { getData } = window.axios;

  try {
    const URL = `${API.appSetup}?should_fetch_categories=true`;
    const response = yield call(getData, URL);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.CATEGORIES.GET_LIST_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: ACTIONS.CATEGORIES.GET_LIST_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.CATEGORIES.GET_LIST_FAIL });
  }
}

/**
 * @description creates a category
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* createCategory(action) {
  const { postData } = window.axios;
  const { data } = action;

  if (data.unique_slug) {
    // if a unique slug is passed, the category is being edited
    yield* editCategory(action);
    return;
  }

  const { CREATE_CATEGORY_SUCCESS, CREATE_CATEGORY_FAILURE } = CATEGORY.TOAST;

  try {
    const URL = `${API.categories}`;
    const response = yield call(postData, URL, data);
    const responseData = response.data;

    if (response.status === 200) {
      yield put({
        type: ACTIONS.CATEGORIES.CREATE_CATEGORY_SUCCESS,
        responseData,
      });

      yield put(clearAdminCategories());

      toast.success(CREATE_CATEGORY_SUCCESS);
    } else {
      reportAPIError('Category Create API Error', response.data);

      yield put({ type: ACTIONS.CATEGORIES.AJAX_FAIL });

      toast.error(CREATE_CATEGORY_FAILURE);
    }
  } catch (error) {
    reportAPIError('Overall Category Create Catch', error);

    yield put({ type: ACTIONS.CATEGORIES.AJAX_FAIL });

    toast.error(CREATE_CATEGORY_FAILURE);
  }
}

/**
 * @description edits an existing category
 *
 * @param {Object} action - object passed from the redux action
 *
 *  @returns {void}
 */
function* editCategory(action) {
  const { patchData } = window.axios;
  const { passedCategory, apiData } = action.data;

  const { EDIT_CATEGORY_SUCCESS, EDIT_CATEGORY_FAILURE } = CATEGORY.TOAST;

  try {
    const URL = `${API.categories}`;
    const response = yield call(patchData, URL, apiData);
    const responseData = response.data;

    if (response.status === 200) {
      yield put({
        type: ACTIONS.CATEGORIES.EDIT_CATEGORY_SUCCESS,
        data: responseData,
      });

      yield put(clearAdminCategories());

      toast.success(EDIT_CATEGORY_SUCCESS);
    } else {
      reportAPIError('Category Edit HTTP', response.data);

      yield put({ type: ACTIONS.CATEGORIES.AJAX_FAIL });

      toast.error(EDIT_CATEGORY_FAILURE);
    }
  } catch (error) {
    reportAPIError('Overall Category Edit Catch', error);

    yield put({ type: ACTIONS.CATEGORIES.AJAX_FAIL });

    toast.error(EDIT_CATEGORY_FAILURE);
  }
}

/**
 * @description deletes a category
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* deleteCategory(action) {
  const { deleteData } = window.axios;
  const { DELETE_SUCCESS_MESSAGE, DELETE_FAILURE_MESSAGE } = CATEGORY.TOAST;
  const { category, apiData } = action.data;

  const { unique_slug } = apiData;

  const washedData = { unique_slug };

  try {
    const URL = `${API.categories}`;
    const response = yield call(deleteData, URL, washedData);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.CATEGORIES.DELETE_CATEGORY_SUCCESS,
        data: category,
      });

      yield put(clearAdminCategories());

      toast.success(DELETE_SUCCESS_MESSAGE);
    } else {
      reportAPIError('Category Delete HTTP', response.data);

      yield put({ type: ACTIONS.CATEGORIES.AJAX_FAIL });

      toast.error(DELETE_FAILURE_MESSAGE);
    }
  } catch (error) {
    reportAPIError('Overall Category Delete Catch', error);

    yield put({ type: ACTIONS.CATEGORIES.AJAX_FAIL });

    toast.error(DELETE_FAILURE_MESSAGE);
  }
}

/**
 * @description toggles a feature category
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* toggleFeature(action) {
  const { patchData } = window.axios;
  const {
    FEATURE_SUCCESS_MESSAGE,
    FEATURE_FAILURE_MESSAGE,
    UNFEATURE_SUCCESS_MESSAGE,
    UNFEATURE_FAILURE_MESSAGE,
  } = CATEGORY.TOAST;
  const { category, apiData } = action.data;

  try {
    const URL = `${API.categories}`;
    const response = yield call(patchData, URL, apiData);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.CATEGORIES.TOGGLE_FEATURE_SUCCESS,
        data: response.data,
      });

      yield put(clearAdminCategories());

      toast.success(
        apiData.is_featured
          ? FEATURE_SUCCESS_MESSAGE
          : UNFEATURE_SUCCESS_MESSAGE
      );
    } else {
      yield put({ type: ACTIONS.CATEGORIES.AJAX_FAIL });

      toast.error(
        apiData.is_featured
          ? FEATURE_FAILURE_MESSAGE
          : UNFEATURE_FAILURE_MESSAGE
      );
    }
  } catch (error) {
    yield put({ type: ACTIONS.CATEGORIES.AJAX_FAIL });

    toast.error(
      apiData.is_featured ? FEATURE_FAILURE_MESSAGE : UNFEATURE_FAILURE_MESSAGE
    );
  }
}

/**
 * @description toggles a enabling a category
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* toggleEnable(action) {
  const { patchData } = window.axios;
  const {
    ENABLE_SUCCESS_MESSAGE,
    ENABLE_FAILURE_MESSAGE,
    DISABLE_SUCCESS_MESSAGE,
    DISABLE_FAILURE_MESSAGE,
  } = CATEGORY.TOAST;
  const { category, apiData } = action.data;

  try {
    const URL = `${API.categories}`;
    const response = yield call(patchData, URL, apiData);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.CATEGORIES.TOGGLE_ENABLE_SUCCESS,
        data: response.data,
      });

      yield put(clearAdminCategories());
      toast.success(
        apiData.is_enabled ? ENABLE_SUCCESS_MESSAGE : DISABLE_SUCCESS_MESSAGE
      );
    } else {
      yield put({ type: ACTIONS.CATEGORIES.AJAX_FAIL });

      toast.error(
        apiData.is_enabled ? ENABLE_FAILURE_MESSAGE : DISABLE_FAILURE_MESSAGE
      );
    }
  } catch (error) {
    yield put({ type: ACTIONS.CATEGORIES.AJAX_FAIL });

    toast.error(
      apiData.is_enabled ? ENABLE_FAILURE_MESSAGE : DISABLE_FAILURE_MESSAGE
    );
  }
}

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export {
  getCategoryList,
  createCategory,
  editCategory,
  deleteCategory,
  toggleFeature,
  toggleEnable,
};
