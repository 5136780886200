/**
 * ************************************
 *
 * @module  Activity.js
 * @author  Matt P
 * @date    02/01/2021
 * @description Container for editing an 'Activity.' Currently renders within
 * the CardViewModal is shown renders when a card is clicked in the
 * ActivitiesList component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { fetchNeighborhoodLocationsAction } from 'store/actions/Places.action';

import { deleteActivityAction } from 'store/actions/~depreciated/Activities.action';
import {
  clearImagesAction,
  deleteImageAction,
  populateAutofillImagesAction,
  saveDataToRedux as saveDataToReduxAction,
  saveData,
  uploadImageAction,
  reOrderImagesAction,
  fetchActivityAction,
} from 'store/actions/~depreciated/Activity.action';

import { toast } from 'react-toastify';

import ActivityService from 'containers/~depreciated/Activity/ActivityService';

import {
  Form,
  FileUploader,
  WarningModal,
  Loader,
  FormActions,
  Overlay,
} from 'components';

import { ACTIVITY } from 'constants.js';

import {
  applyDrag,
  chooseItemizedDescription,
  getPriceRangeLabel,
  hasUniqueSlug,
  deepCopy,
  mapDetailedCategories,
} from 'utils/utils';

import CommonService from 'services/CommonService';
import ActivityData from './ActivityData.constants';

import './Activity.scss';

// ----------------------------------------------------------------------------|
//                        Redux - Property Mapping
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  // Fetch Data Actions
  fetchActivity: (data) => dispatch(fetchActivityAction(data)),
  fetchNeighborhoodLocations: () =>
    dispatch(fetchNeighborhoodLocationsAction()),
  // Image Actions
  uploadImage: (data) => dispatch(uploadImageAction(data)),
  deleteImage: (data) => dispatch(deleteImageAction(data)),
  populateAutofillImages: (data) =>
    dispatch(populateAutofillImagesAction(data)),
  clearImages: () => dispatch(clearImagesAction()),
  reOrderImages: (data) => dispatch(reOrderImagesAction(data)),
  // Data Save Actions
  saveData: (data, id, successCB) => dispatch(saveData(data, id, successCB)),
  saveDataToRedux: (data) => dispatch(saveDataToReduxAction(data)),
  deleteActivity: (data) => dispatch(deleteActivityAction(data)),
});

const mapStateToProps = (state) => ({
  activity: state.activity,
  categoryMap: state.places.categoryMap,
  adminCategories: state.places.adminCategories,
  // placeCategories: state.places.placeCategories,
  vibes: state.places.vibes,
  placeCategories: state.places.placeCategories,
  categoriesLoaded: state.places.categoriesLoaded,
});

// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|
const commonService = CommonService();

const { WARNING_ON_DRAFT } = ACTIVITY;

// ----------------------------------------------------------------------------|
//                    React Class PureComponent - Activity
// ----------------------------------------------------------------------------|
class Activity extends PureComponent {
  // --------------------------------------------------------------------------|
  //                            PropTypes Check
  // --------------------------------------------------------------------------|
  static propTypes = {
    activity: PropTypes.shape({
      autofilledImages: PropTypes.arrayOf(PropTypes.object),
      failedUploads: PropTypes.arrayOf(PropTypes.object),
      formData: PropTypes.shape({
        cta_title_long: PropTypes.string,
        cta_title_short: PropTypes.string,
        description: PropTypes.string,
        name: PropTypes.string,
        images: PropTypes.arrayOf(PropTypes.object),
        main_categories: PropTypes.arrayOf(PropTypes.object),
        nested_categories: PropTypes.arrayOf(PropTypes.object),
        categories: PropTypes.arrayOf(PropTypes.object),
        neighborhoods: PropTypes.arrayOf(PropTypes.object),
      }),
      images: PropTypes.arrayOf(PropTypes.object),
      isBtnDisabled: PropTypes.bool.isRequired,
      isDropZonePreviewRequired: PropTypes.bool.isRequired,
      // Only for fetched movie
      fetchedActivityData: PropTypes.shape({
        id: PropTypes.string.isRequired,
        cta_title_long: PropTypes.string,
        cta_title_short: PropTypes.string,
        nested_categories: PropTypes.arrayOf(PropTypes.object),
        main_categories: PropTypes.arrayOf(PropTypes.object),
        title: PropTypes.string,
        description: PropTypes.string,
        images: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      cta_title_long: PropTypes.string,
      cta_title_short: PropTypes.string,
      nested_categories: PropTypes.arrayOf(PropTypes.object),
      main_categories: PropTypes.arrayOf(PropTypes.object),
      title: PropTypes.string,
      description: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.object),
      shouldFetchActivity: PropTypes.bool,
      shouldDisplayLoader: PropTypes.bool,
    }).isRequired,
    currentTab: PropTypes.string.isRequired,
    adminCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
    neighborhoods: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchActivity: PropTypes.func.isRequired,
    saveData: PropTypes.func.isRequired,
    saveDataToRedux: PropTypes.func.isRequired,
    clearImages: PropTypes.func.isRequired,
    deleteImage: PropTypes.func.isRequired,
    populateAutofillImages: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    uploadImage: PropTypes.func.isRequired,
    reOrderImages: PropTypes.func.isRequired,
  };

  // --------------------------------------------------------------------------|
  //                        Default Props - Activity
  // --------------------------------------------------------------------------|
  static defaultProps = {};

  // --------------------------------------------------------------------------|
  //                                State
  // --------------------------------------------------------------------------|
  constructor(props) {
    super(props);

    const { data, saveDataToRedux } = this.props;
    const initialActivityState = this.handleActivityData(data);

    this.state = {
      activityId: data.id,
      errors: {},
      imageErrors: '',
      shouldFetchActivity: data.shouldFetchActivity,
      shouldDisplayLoader: false,
      ...initialActivityState,
    };
    // initially keep the data in the redux store
    saveDataToRedux(this.getToBeProcessedData(data));
  }

  // --------------------------------------------------------------------------|
  //                          Lifecycle Methods
  // --------------------------------------------------------------------------|
  componentDidMount() {
    const { populateAutofillImages, data, categoriesLoaded } = this.props;
    const { shouldFetchActivity } = this.state;

    populateAutofillImages(data.images);

    if (shouldFetchActivity) {
      this.fetchActivityFromServer();
      this.setState({ shouldFetchActivity: false, shouldDisplayLoader: true });
    }

    // cats and neighborhoods are async calls to redux saga, and should load
    // before the form renders. The shouldDisplayLoader flag is set for this
    // reason, and is flipped when the component updates on prop fulfillment
    if (!categoriesLoaded) {
      this.setState({ shouldDisplayLoader: true });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      activity,
      data,
      saveDataToRedux,
      populateAutofillImages,
      categoriesLoaded,
    } = this.props;

    const { activityId, shouldDisplayLoader } = this.state;

    const { shouldFetchActivity } = data;

    // used for updating and rendering a fetched activity
    // also checks if the async calls have come back and will flip
    // the loaderDisplay flag when loaded.
    if (shouldFetchActivity) {
      if (
        categoriesLoaded &&
        activity.fetchedActivityData &&
        activityId === activity.fetchedActivityData.id &&
        shouldDisplayLoader
      ) {
        this.setState({
          shouldDisplayLoader: false,
          ...this.handleActivityData(activity.fetchedActivityData),
        });

        saveDataToRedux(
          this.getToBeProcessedData(activity.fetchedActivityData)
        );
        populateAutofillImages(activity.fetchedActivityData.images);
      }
    } else if (
      categoriesLoaded &&
      !activity.fetchedActivityData &&
      shouldDisplayLoader
    ) {
      this.setState({
        shouldDisplayLoader: false,
        ...this.handleActivityData(data),
      });

      saveDataToRedux(this.getToBeProcessedData(data));
    }
  }

  componentWillUnmount() {
    const { clearImages, activity } = this.props;

    clearImages(activity);
  }

  // --------------------------------------------------------------------------|
  //                      Component Methods - Activity
  // --------------------------------------------------------------------------|
  /**
   * @description Fetch Activity object from server using ID
   */
  fetchActivityFromServer = () => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.fetchActivity({ activityId: this.state.activityId });
  };

  /**
   * @description Handle activity object data: prepare form, display, etc..
   *
   * @param {Object} activityDataToProcess - Object of movie data
   */
  handleActivityData = (activityDataToProcess) => {
    const processedData = this.getProcessedData(
      this.getToBeProcessedData(activityDataToProcess)
    );

    return { data: processedData };
  };

  /**
   * @description fires to reorders images
   *
   * @param {Object} dropData
   */
  onReOrderImages = (dropData) => {
    const { activity, reOrderImages } = this.props;
    const { autofilledImages, images } = activity;
    const reOrderedImages = applyDrag(
      [...autofilledImages, ...images],
      dropData
    );

    reOrderImages(reOrderedImages);
  };

  /**
   * @description fires to saveData. Runs several
   * validation checks found in ActivityService file
   *
   * @param {Object} e - Event Object.
   */
  saveData = (e) => {
    const {
      activity,
      data,
      closeModal,
      adminCategories,
      saveData,
    } = this.props;
    const { autofilledImages } = activity;

    const formattedImages = activity.autofilledImages.map((image) => {
      const imageTemp = { ...image };

      // // fixed error where is photo_metadata || photo_credit === null
      // // will wash to empty string for DB PUT/PATCH
      if (imageTemp.photo_credits === null) imageTemp.photo_credits = '';
      if (imageTemp.photo_metadata === null) imageTemp.photo_metadata = '';

      if (image.source === 'cobble') {
        delete imageTemp.fileId;

        return imageTemp;
      }

      return imageTemp;
    });

    const formData = ActivityService.getPreparedFormData(
      activity.formData,
      [...formattedImages],
      e.target.name
    );

    formData.categories = formData.categories.map((cat) => cat.unique_slug);

    formData.main_categories = activity.formData.main_categories.map(
      (cat) => cat.unique_slug
    );

    /** Perform validation only on published */
    if (e.target && e.target.name === 'published') {
      const errors = ActivityService.validate(activity.formData);

      // errors = commonService.validateLinks(activity.formData, errors);

      const imageErrors = commonService.validateImages(
        activity.images,
        autofilledImages
      )
        ? null
        : ACTIVITY.IMAGE_VALIDATION_MESSAGE;

      if (Object.keys(errors).length === 0 && imageErrors === null) {
        // dispatch to save
        saveData(formData, data.id, closeModal);
      } else {
        toast.error('There are some errors in the form');
      }

      this.setState({
        errors,
        imageErrors,
      });
    } else if (e.target && e.target.name === 'draft') {
      const errors = {
        ...ActivityService.validateActivityName(activity.formData.name),
      };

      if (
        !commonService.validatePrimaryCategories(
          activity.formData.main_categories,
          activity.formData.categories
        )
      ) {
        errors.categories =
          'Primary categories must be included in the selected categories';
      }

      const imageErrors = '';

      if (Object.keys(errors).length === 0) {
        // dispatch to save
        saveData(formData, data.id, closeModal);
      } else {
        toast.error('There are some errors in the form');
      }

      this.setState({ errors, imageErrors });
    }
  };

  /**
   * @description returns an object with the correct
   * key/value pairs for editing
   *
   * @param {Object} obj
   */
  getToBeProcessedData = (obj) => {
    const { categoryMap } = this.props;

    return {
      name: obj.name || '',
      categories: obj.categories || [],
      main_categories: obj.main_categories
        ? mapDetailedCategories(obj.main_categories, categoryMap)
        : [],
      price_tier: obj.price_tier || 0,
      phrase: obj.phrase || '',
      description: obj.description || '',
      images: obj.images || [],
      links_to_activity: obj.links_to_activity || [], // website link
      last_admin_review: obj.last_admin_review,
      unit_history: obj.unit_history,
      // special case where we want to put the detailed description from
      // old card details into the itemized one
      itemized_description: chooseItemizedDescription(
        obj.itemized_description,
        obj.description,
        ActivityService.defaultDescriptions
      ),
    };
  };

  /**
   * @description processes data of objects into an array of objects
   * for our form component to iterate and render
   *
   * @param {Array} data - data being passed || element name
   * @param {Array} categories - name of that input
   *
   * @returns {Array}
   */
  getProcessedData(data = []) {
    const { placeCategories } = this.props;

    return ActivityData.map((inputObj) => {
      let input = {};

      switch (inputObj.name) {
        case 'categories':
          input = {
            ...inputObj,
            main_categories: data.main_categories,
            tags: data.categories,
            suggestions: placeCategories || [],
          };
          break;
        case 'price_tier':
          input = {
            ...inputObj,
            value:
              data.price_tier !== undefined
                ? {
                    label: getPriceRangeLabel(data.price_tier),
                    value: data.price_tier,
                  }
                : '',
          };
          break;
        case 'last_admin_review':
          input = {
            ...inputObj,
            value: data.last_admin_review
              ? data.last_admin_review.review_status
              : null,
            reviewDate: data.last_admin_review
              ? data.last_admin_review.review_datetime
              : null,
            history: data.unit_history || [],
          };
          break;

        default:
          input = {
            ...inputObj,
            value: data[inputObj.name],
          };
          break;
      }

      return input;
    });
  }

  /**
   * @description toggles preview modal
   *
   * @param {Boolean} isShow
   */
  onShowPreviewModal = (isShow) => {
    this.setState({ showPreviewModal: isShow });
  };

  /**
   * @description fires on an 'event' which is any input or
   * change to the form. Updates redux
   *
   * @param {Any} event - data being passed || element name
   * @param {String} elemName - name of that input
   */
  eventHandler = (event, elemName) => {
    const { saveDataToRedux } = this.props;
    // const data = ActivityService.getDataToBeSaved(event, elemName);

    if (
      elemName === 'categories' ||
      elemName === 'main_categories' ||
      elemName === 'links_to_activity' ||
      elemName === 'admin_review' ||
      elemName === 'itemized_description'
    ) {
      saveDataToRedux({ [elemName]: event });
      return;
    }

    if (Array.isArray(event)) {
      saveDataToRedux({ [elemName]: event.map((each) => each.name) });
      return;
    }

    if (elemName === 'price_tier') {
      saveDataToRedux({ [elemName]: event.value });
      return;
    }

    const { name, value } = event.target;

    saveDataToRedux({ [name]: value });
  };

  /**
   * @description fires when any of the form action buttons (bottom of form)
   * are clicked - ie: "MOVE TO DRAFTS" / "PUBLISHED" / "PREVIEW" and passes
   * data to be validated for a DB PUT/PATCH
   *
   * @argument {Object} event - Event Object.
   */
  onFormAction = (event) => {
    const { currentTab, data } = this.props;
    const { name } = event.target;

    if (name === 'preview') {
      this.onShowPreviewModal(true);
    } else if (
      name === 'draft' &&
      currentTab === 'published' &&
      data &&
      data.id
    ) {
      event.persist();

      this.setState({
        showWarningModal: true,
        onWarningClose: () => {
          this.saveData(event);
          this.setState({ showWarningModal: false, onWarningClose: () => {} });
        },
      });
    } else if (name === 'published' || name === 'draft') {
      this.saveData(event);
    } else if (name === 'delete') {
      event.persist();

      this.setState({
        showWarningModal: true,
        onWarningClose: () => {
          const { deleteActivity, closeModal, data } = this.props;

          // we can't call closeModal until the delete is resolved,
          // as closeModal resets the list state to [] which will not
          // allow us to filter the deleted card. So we close it after the
          // delete async function is complete
          const promiseMe = new Promise((resolve, reject) => {
            resolve(deleteActivity(data.id));
          });

          promiseMe
            .then(() => {
              closeModal();
            })
            .then(() => {
              this.setState({
                showWarningModal: false,
                onWarningClose: () => {},
              });
            });
        },
      });
    }
  };

  updateStateDataAndRedux = (dataCopy, formField, formData) => {
    const { saveDataToRedux } = this.props;

    this.setState(
      {
        // updates data to be fed to form fields
        ...this.handleActivityData(dataCopy),
      }, // updates form data to be saved from form->backend so
      // it'll persist between sessions
      () => saveDataToRedux({ [formField]: formData })
    );
  };

  handleMiscOptionToggle = (checked, uniqueSlug, type) => {
    if (type === 'category') {
      const { activity } = this.props;
      const { formData } = activity;
      const { misc_options } = formData;

      const optionsCopy = deepCopy(misc_options);

      const hasSlug = hasUniqueSlug(misc_options, uniqueSlug);

      if (checked && !hasSlug) {
        const { categoryMap } = this.props;
        const category = categoryMap[uniqueSlug];

        // prevents undefined from being pushed to the array
        if (category) optionsCopy.push(category);
      }

      if (!checked && hasSlug) {
        for (let i = 0; i < optionsCopy.length; i += 1) {
          if (optionsCopy[i].unique_slug === uniqueSlug) {
            optionsCopy.splice(i, 1);
          }
        }
      }

      this.eventHandler(optionsCopy, 'misc_options');
    }
  };

  // --------------------------------------------------------------------------|
  //                              Activity - Render
  // --------------------------------------------------------------------------|
  render() {
    const {
      uploadImage,
      deleteImage,
      activity,
      populateAutofillImages,
      currentTab,
    } = this.props;
    const {
      data,
      errors,
      showWarningModal,
      onWarningClose,
      shouldDisplayLoader,
      imageErrors,
      showPreviewModal,
    } = this.state;
    const { formData } = activity;
    const updatedData = commonService.getUpdatedData(formData, [...data]);

    updatedData.event_venue_name = data.event_venue_name;

    return (
      <div className="activity">
        {shouldDisplayLoader === true ? (
          <div className="overlay-wrapper">
            <Overlay show>
              <Loader />
            </Overlay>
          </div>
        ) : (
          <Fragment>
            <div className="form-content">
              {showWarningModal && (
                <WarningModal
                  message={WARNING_ON_DRAFT}
                  onSubmit={onWarningClose}
                  onCancel={() => {
                    this.setState({ showWarningModal: false });
                  }}
                />
              )}
              <FileUploader
                isDropZonePreviewRequired={activity.isDropZonePreviewRequired}
                data={activity.autofilledImages}
                onEvent={populateAutofillImages}
                failedUploads={activity.failedUploads}
                onAddImage={uploadImage}
                onDeleteImage={deleteImage}
                error={imageErrors}
                onDrop={this.onReOrderImages}
              />
              <div className="form">
                <Form
                  data={updatedData}
                  onEvent={this.eventHandler}
                  errors={errors}
                />
              </div>
            </div>
            <FormActions
              verticalType="Recreation"
              onAction={this.onFormAction}
              currentTab={currentTab}
              cardId={this.props.data.id}
              // eslint-disable-next-line react/destructuring-assignment
              isNewCard={Object.keys(this.props.data).length > 0}
              isBtnDisabled={activity.isBtnDisabled}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

// ----------------------------------------------------------------------------|
//                      Activity Export with Redux Connect
// ----------------------------------------------------------------------------|
export default connect(mapStateToProps, mapDispatchToProps)(Activity);
