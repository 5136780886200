/**
 * ************************************
 *
 * @module  Button.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description dynamic and reusable Button function component.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import 'components/Button/Button.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const Button = ({ className, onClick, disabled, preventDefault, ...rest }) => {
  /**
   * @description - dynamic classNames
   */
  const buttonClass = cx(['btn', { disabled }, `${className}`]);

  /**
   * @description - handles button click interaction
   *
   * @param {Object} event - DOM event object
   *
   * @returns {void}
   */
  const onClickHandler = (event) => {
    if (preventDefault) {
      event.preventDefault();
    }
    onClick(event);
  };

  return (
    <button className={buttonClass} onClick={onClickHandler} {...rest}>
      {rest.children}
    </button>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  // children: PropTypes.string.isRequired,
  children: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  name: PropTypes.string,
  id: PropTypes.string,
  preventDefault: PropTypes.bool,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
Button.defaultProps = {
  className: '',
  disabled: false,
  style: {},
  name: '',
  id: '',
  preventDefault: false,
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default Button;
