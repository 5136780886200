/**
 * ************************************
 *
 * @module  VerticalDisplay.saga.js
 * @author  Matt P
 * @date    07/02/2021
 * @description redux saga file for the VerticalDisplay container.
 * Makes API calls based on verticalType flag in conjunction with the
 * API map.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                    Imports
// ----------------------------------------------------------------------------|
import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import ACTIONS from 'store/actions/actionTypes';

import API from 'API';
import apiMap from 'API/apiMap';

import {
  clearCardsAction,
  getVerticalDisplayCardsAction,
} from 'store/actions/VerticalDisplay.action';

import { GENERIC } from 'constants.js';
import { constructQueryParams } from 'utils/utils';

import { reportAPIError } from 'utils/ErrorHandlingUtils';

// ----------------------------------------------------------------------------|
//                                  Utilities
// ----------------------------------------------------------------------------|
/**
 * @description constructs url string based on vertical
 *
 * @param {String} endPoint
 * @param {String} queryParams
 *
 * @returns {String}
 */
const constructUrl = (endPoint, queryParams = '') =>
  // eslint-disable-next-line prefer-template
  `${endPoint}${queryParams ? '?' + queryParams : ''}`;

/**
 * @description selects correct request type
 *
 * @param {String} endPoint
 * @param {String} queryParams
 *
 * @returns {new Axios}
 */
const chooseRequestType = (requestType) => {
  const { patchData, postData, putData } = window.axios;

  switch (requestType) {
    case 'patch': {
      return patchData;
    }
    case 'post': {
      return postData;
    }
    case 'put': {
      return putData;
    }
    default: {
      break;
    }
  }
};

/**
 * @description returns correct request arguments as an array
 * to be spread to saga effect call()
 *
 * @param {String} type - of request
 * @param {String} url
 * @param {Object} data - optional but needed for request body calls
 *
 * @returns {new Axios}
 */
const chooseSagaCallArguments = (type, url, data = {}) => {
  const { page, page_size } = data;

  switch (type) {
    case 'get':
      return [type, constructUrl(url, constructQueryParams(data))];
    case 'post':
    case 'put':
      delete data.page;
      delete data.page_size;

      return [
        type,
        page || page_size
          ? constructUrl(
              url,
              constructQueryParams({
                ...(page ? { page } : {}),
                ...(page_size ? { page_size } : {}),
              })
            )
          : url,
        data,
      ];
    case 'delete':
      return [url];
    default:
      return [];
  }
};

// ----------------------------------------------------------------------------|
//                           Sagas - VerticalDisplay
// ----------------------------------------------------------------------------|
/**
 * @description gets card list http request by vertical type. Chooses correct
 * endpoints based on type of call and vertical type via apiMap
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* getVerticalDisplayCards(action) {
  const { data, verticalType } = action;
  const { searchData } = window.axios;

  try {
    const { status: tabStatus } = data;
    let response;

    if (tabStatus === 'queue') {
      const { endPoint, requestType } = apiMap[verticalType].uncuratedList;
      const { page } = data;

      delete data.status;
      delete data.page;

      response = yield call(
        searchData,
        ...chooseSagaCallArguments(requestType, endPoint, data)
      );
    } else {
      const { endPoint, requestType } = apiMap[verticalType].retrieveList;

      response = yield call(
        searchData,
        ...chooseSagaCallArguments(requestType, endPoint, data)
      );
    }

    if (response.status === 200) {
      yield put({
        type: ACTIONS.VERTICAL_DISPLAY.GET_VERTICAL_DISPLAY_CARDS_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: ACTIONS.VERTICAL_DISPLAY.GET_VERTICAL_DISPLAY_CARDS_FAIL,
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.VERTICAL_DISPLAY.GET_VERTICAL_DISPLAY_CARDS_FAIL,
    });
  }
}

/**
 * @description deletes a card by http request by vertical type. Chooses correct
 * endpoints based on type of call and vertical type via apiMap
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* deleteCard(action) {
  const { data, verticalType } = action;
  const { deleteData } = window.axios;
  const { DELETE_SUCCESS_MESSAGE, DELETE_FAILURE_MESSAGE } = GENERIC;

  try {
    const { endPoint, requestType } = apiMap[verticalType].deleteCard;

    const URL = `${endPoint}${data}${
      verticalType === 'event' ? '?is_event=true' : ''
    }`;
    const response = yield call(
      deleteData,
      ...chooseSagaCallArguments(requestType, URL)
    );

    if (response.status === 200) {
      toast.success(DELETE_SUCCESS_MESSAGE);

      yield put({
        type: ACTIONS.VERTICAL_DISPLAY.DELETE_CARD_SUCCESS,
        data: action.data,
      });
    } else {
      toast.error(DELETE_FAILURE_MESSAGE);

      yield put({ type: ACTIONS.VERTICAL_DISPLAY.DELETE_CARD_FAIL });
    }
  } catch (error) {
    toast.error(DELETE_FAILURE_MESSAGE);

    yield put({ type: ACTIONS.VERTICAL_DISPLAY.DELETE_CARD_FAIL });
  }
}

/**
 * @description fetches neighborhood location information
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* fetchNeighborhoodLocations(action) {
  const { getData } = window.axios;

  try {
    const response = yield call(
      getData,
      API.neighborhoodLocations,
      action.data
    );

    if (response.status === 200) {
      yield put({
        type: ACTIONS.VERTICAL_DISPLAY.FETCH_NEIGHBORHOOD_LOCATIONS_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: ACTIONS.VERTICAL_DISPLAY.FETCH_NEIGHBORHOOD_LOCATIONS_FAIL,
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.VERTICAL_DISPLAY.FETCH_NEIGHBORHOOD_LOCATIONS_FAIL,
    });
  }
}

/**
 * @description filters card display list
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* filterCardsList(action) {
  const { verticalType } = action;

  yield put(clearCardsAction());

  const pageNum = yield select((state) => state.verticalDisplay.pageNum);
  const data = { ...action.data, page: pageNum };

  yield put(getVerticalDisplayCardsAction(data, verticalType));
}

/**
 * @description Collections Specific (for now....). Used for updating
 * is_pinned and adding it back to the card list after a 200 response
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* updateSingleCardProperty(action) {
  const { id, verticalType } = action;
  const data = { ...action.data };
  const { is_pinned } = data;

  try {
    const { endPoint, requestType } = apiMap[verticalType].editCard;

    const response = yield call(
      chooseRequestType(requestType),
      `${endPoint}${id}`,
      data
    );

    if (response.status === 200) {
      yield put({
        type: ACTIONS.VERTICAL_DISPLAY.UPDATE_SINGLE_CARD_SUCCESS,
        data: { ...response.data },
      });

      toast.success(
        `Collection ${is_pinned ? 'pinned' : 'un-pinned'} successfully`
      );
    } else {
      reportAPIError(`${verticalType} Save - HTTP`, response.data);
      toast.error(`Collection ${is_pinned ? 'pin' : 'un-pin'} failed`);

      yield put({ type: ACTIONS.VERTICAL_DISPLAY.UPDATE_SINGLE_CARD_FAIL });
    }
  } catch (error) {
    reportAPIError(`Overall ${verticalType} Save Catch`, error);

    toast.error(`Collection ${is_pinned ? 'pin' : 'un-pin'} failed`);

    yield put({ type: ACTIONS.VERTICAL_DISPLAY.UPDATE_SINGLE_CARD_FAIL });
  }
}

// ----------------------------------------------------------------------------|
//                            Places Saga Export
// ----------------------------------------------------------------------------|
export {
  getVerticalDisplayCards,
  deleteCard,
  fetchNeighborhoodLocations,
  filterCardsList,
  updateSingleCardProperty,
};
