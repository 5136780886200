/**
 * ************************************
 *
 * @module  Place.reducer.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description redux reducer file for Place component
 *
 *  * ** PLACES IS IS A DEPENDENCY FOR OTHER FILES (ie. Curated Card(s)) and
 * can not yet be depreciated
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                               Initial State
// ----------------------------------------------------------------------------|
const initialState = {
  // Contains images that are to be auto-filled into the image upload component
  autofilledImages: [],
  images: [],
  formData: {},
  // Contains list of fileIds for whom upload failed
  failedUploads: [],
  isDropZonePreviewRequired: false,
  isBtnDisabled: false,
};

// ----------------------------------------------------------------------------|
//                                  Utilities
// ----------------------------------------------------------------------------|
// identifier can be the source url of the image in case of auto-filled images
// For images that were not auto-filled, it is the file id
// If an image's source url or its ID matches the identifier param, delete it
// from the array
const getFilteredImages = (identifier, images = []) =>
  images.filter(
    (image) => image.url !== identifier && image.fileId !== identifier
  );

// ----------------------------------------------------------------------------|
//                                Place Reducer
// ----------------------------------------------------------------------------|
const Place = (state = initialState, action = null) => {
  const { type, data } = action;

  switch (type) {
    /**
     * @description single card fetch success by ID
     */
    case ACTIONS.PLACE.FETCH_PLACE_SUCCESS: {
      return {
        ...state,
        fetchedPlaceData: data,
      };
    }

    /**
     * @description populates images
     */
    case ACTIONS.PLACE.POPULATE_IMAGES: {
      return {
        ...state,
        autofilledImages: data || [],
      };
    }

    /**
     * @description updates form data
     */
    case ACTIONS.PLACE.SAVE_DATA_TO_REDUX: {
      return {
        ...state,
        formData: { ...state.formData, ...data },
      };
    }

    /**
     * @description Clear any errors for image being uploaded
     */
    case ACTIONS.PLACE.UPLOAD_IMAGE: {
      return {
        ...state,
        isDropZonePreviewRequired: true,
        failedUploads: state.failedUploads.filter(
          (fileId) => fileId !== data.fileId
        ),
      };
    }

    /**
     * @description upon successful image upload
     */
    case ACTIONS.PLACE.UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        autofilledImages: [
          ...state.autofilledImages,
          { ...data, fileId: data.fileId, photo_metadata: data.photo_metadata },
        ],
        isDropZonePreviewRequired: false,
      };
    }

    /**
     * @description upon unsuccessful image upload
     */
    case ACTIONS.PLACE.UPLOAD_IMAGE_FAIL: {
      return { ...state, failedUploads: [...state.failedUploads, data.fileId] };
    }

    /**
     * @description deletes an image, no success or fail as this is not
     * http - data is saved upon form submission
     */
    case ACTIONS.PLACE.DELETE_IMAGE: {
      return {
        ...state,
        autofilledImages: getFilteredImages(data, state.autofilledImages),
      };
    }

    /**
     * @description clears image state
     */
    case ACTIONS.PLACE.CLEAR_IMAGES: {
      return { ...state, images: [] };
    }

    /**
     * @description reorders image array
     */
    case ACTIONS.PLACE.RE_ORDER_IMAGES: {
      return { ...state, autofilledImages: data };
    }

    /**
     * @description disables form submission buttons after submission
     */
    case ACTIONS.PLACE.BUTTON_DISBALING: {
      return {
        ...state,
        isBtnDisabled: data.isBtnDisabled,
      };
    }

    default: {
      return state;
    }
  }
};

// ----------------------------------------------------------------------------|
//                          Export - Place Reducer
// ----------------------------------------------------------------------------|
export default Place;
