/**
 * ************************************
 *
 * @module  MovieData.constants.js
 * @author  Matt P
 * @date    12/31/2020
 * @description Contains MovieData constants for editing/creating a Movie
 * maps over and generated the Movie form.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';

import { Button, DetailedDescription, Input, ReviewStatus } from 'components';

import { getMovieInstanceType } from 'utils/utils';
import { ONE_LINER_DESC_ROWS } from 'constants.js';

// ----------------------------------------------------------------------------|
//                        MovieData - Array of Objects
// ----------------------------------------------------------------------------|
const MovieData = [
  {
    type: 'component',
    name: 'title',
    component: ({ value, onChange, onButtonClick, error }) => (
      <div className="form-field-wrapper form-field-multi-comp">
        <div className="form-input">
          <label>Title</label>
          <div className="form-input__input-wrapper">
            <Input
              type="text"
              name="title"
              value={value || ''}
              onChange={onChange}
              maxLength={1000}
            />
            <Button
              className="btn-inverse"
              onClick={onButtonClick}
              preventDefault
            >
              API Connections
            </Button>
          </div>
        </div>
        <div className="error">{error}</div>
      </div>
    ),
  },
  {
    type: 'dropdown',
    label: 'Media Type',
    name: 'instance_type',
    values: getMovieInstanceType(),
    value: '',
    placeholder: 'Select media type',
    errorMsg: 'You must choose a media type (Movie or TV Show)',
  },
  {
    type: 'tag',
    label: 'Category (Genre)',
    name: 'categories',
    tags: [],
    suggestions: [],
    errorMsg: 'Type cannot be empty',
    showDropdown: true,
  },
  {
    type: 'text',
    name: 'release_date',
    label: 'Year of Release',
    value: '',
    errorMsg: 'Year of release is required',
  },
  {
    type: 'dropdown',
    label: 'Rating',
    name: 'rating',
    values: [],
    value: '',
    placeholder: 'Select rating',
    errorMsg: '',
  },
  {
    type: 'textarea',
    name: 'phrase',
    label: 'One-liner Description',
    value: '',
    errorMsg:
      'One liner description should be min 5 and max 50 characters long',
    rows: ONE_LINER_DESC_ROWS,
  },
  {
    type: 'component',
    name: 'itemized_description',
    label: '',
    // eslint-disable-next-line react/prop-types
    component: ({ onChange, value, errors }) => (
      <div className="form-field-wrapper">
        <DetailedDescription
          name="itemized_description"
          onChange={onChange}
          value={value}
          errors={errors}
        />
      </div>
    ),
  },
  {
    type: 'component',
    name: 'last_admin_review',
    label: '',
    // eslint-disable-next-line react/prop-types
    component: ({ value, history, onChange, reviewDate, errors }) => (
      <div className="form-field-wrapper">
        <ReviewStatus
          value={value}
          reviewDate={reviewDate}
          history={history}
          errors={errors}
          onChange={onChange}
        />
      </div>
    ),
  },
];

// ----------------------------------------------------------------------------|
//                           Export - MovieData
// ----------------------------------------------------------------------------|
export default MovieData;
