/**
 * ************************************
 *
 * @module DateSummary.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Date summary components for form and popup modal.
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                  Imports
// ---------------------------------------------------------------------------|
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DATE_SELECTOR_POPUP as LABELS } from 'constants.js';

import { weekOfMonth } from 'utils/utils';

import closeIcon from 'assets/images/remove-black.svg';

import WeekDaySelector from '../RecurrenceSelector/WeekDaySelector/WeekDaySelector';

import {
  REPEAT_MODE,
  AVAILABILITY_OPTIONS,
} from '../EventDateSelector.constants';

import './DateSummary.scss';

// ---------------------------------------------------------------------------|
//                              Text Imports
// ---------------------------------------------------------------------------|
const { DATE_FORMAT, DEFAULT_TIME, REMOVE_DATE_TIME } = LABELS;
const { WEEKLY, MONTHLY } = REPEAT_MODE;
const { MONTHLY_WEEKDAY, MONTHLY_ON_DATE } = AVAILABILITY_OPTIONS;

// ---------------------------------------------------------------------------|
//                React Function Component - DateSummary
// ---------------------------------------------------------------------------|
const DateSummary = (props) => {
  const {
    startDate,
    endDate,
    fromTime,
    toTime,
    isRecurring,
    repeatMode,
    selectedWeekDays,
    availability,
    onDelete,
    onEdit,
    className,
    errors,
  } = props;

  const mStartDate = moment(startDate);
  const mEndDate = moment(endDate);
  const weekIndex = weekOfMonth(mStartDate);
  const css = `event-dates-summary--wrapper ${className}`.trimRight();

  /**
   * @description - handles button click interaction
   *
   * @param {Object} event - DOM event object
   *
   * @returns {void}
   */
  const onClickHandler = (event) => {
    if (event.target.id === 'deleteSummary') {
      onDelete();
    } else {
      onEdit();
    }
  };

  return (
    <div role="button" tabIndex="-1" className={css} onClick={onClickHandler}>
      <div className="date-time-row">
        <div className="date-time">
          <span>{mStartDate.format(DATE_FORMAT)}</span>
          <span className="separator">|</span>
          <span>{`${fromTime} - ${toTime || DEFAULT_TIME}`}</span>
        </div>
        <span className="delete-summary" role="button">
          <img id="deleteSummary" src={closeIcon} alt={REMOVE_DATE_TIME} />
        </span>
      </div>
      {isRecurring && (
        <div className="recurrence-options-summary">
          <div className="weekdays-info">
            <label>{`${LABELS.RECURRING} ${repeatMode.label}`}</label>
            {repeatMode.value === WEEKLY && (
              <WeekDaySelector
                className="info-value"
                selected={selectedWeekDays}
              />
            )}
            {repeatMode.value === MONTHLY &&
              availability.value === MONTHLY_WEEKDAY.value && (
                <div className="info-value">
                  {`Every ${weekIndex} ${mStartDate.format('dddd')}`}
                </div>
              )}
            {repeatMode.value === MONTHLY &&
              availability.value === MONTHLY_ON_DATE.value && (
                <div className="info-value">
                  {`Every ${mStartDate.format('Do')}`}
                </div>
              )}
          </div>
          <div className="">
            <label>{LABELS.ENDS_ON}</label>
            <div className="info-value">{mEndDate.format(DATE_FORMAT)}</div>
          </div>
        </div>
      )}
      <div className="error">{errors}</div>
    </div>
  );
};

// -------------------------------------------------------------------------|
//                             PropTypes Check
// -------------------------------------------------------------------------|
DateSummary.propTypes = {
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
  ]).isRequired,
  fromTime: PropTypes.string.isRequired,
  toTime: PropTypes.string,
  isRecurring: PropTypes.bool,
  repeatMode: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
  ]),
  selectedWeekDays: PropTypes.arrayOf(PropTypes.string),
  availability: PropTypes.shape({
    value: PropTypes.string,
  }),
  className: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  errors: PropTypes.string,
};

// -------------------------------------------------------------------------|
//                              Default Props
// -------------------------------------------------------------------------|
DateSummary.defaultProps = {
  isRecurring: false,
  toTime: '00:00 AM',
  repeatMode: null,
  endDate: '',
  selectedWeekDays: [],
  availability: null,
  className: '',
  errors: '',
};

// -------------------------------------------------------------------------|
//                                  Export
// -------------------------------------------------------------------------|
export default DateSummary;
