/**
 * ************************************
 *
 * @module  Form.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description A generic Form component with takes an array of
 * objects to represent form elements
 *
 * This form component can be used to add/edit Places, Events, Curated Cards
 * Form Component takes Data(array of input elements) and errors set as props
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import FormInput from './FormInput/FormInput';

// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|
/**
 * @description Takes the passed in data array of objects and builds a state
 * object
 *
 * @param {Array} inputObj - Array of objects to be morphed into an object
 *
 * @return {Object} Object containing only name and value key for state obj
 */
const getInitState = (inputObj) =>
  inputObj.reduce((acc, curr) => {
    acc[curr.name] = curr.value;

    return acc;
  }, {});

// ----------------------------------------------------------------------------|
//                        React Class Component - Form
// ----------------------------------------------------------------------------|
class Form extends PureComponent {
  isPriceDisabled = this.props.isPriceDisabled;

  constructor(props) {
    super(props);
    const { data } = this.props;

    this.state = {
      ...getInitState(data),
    };
  }

  /**
   * @description updates for events triggered and updated by onAction
   *
   * @param {Any} event - from form
   */
  onAction = (event) => {
    const { onAction } = this.props;

    onAction(event);
  };

  /**
   * @description updates for events triggered and updated by onEvent
   *
   * @param {Any} event - from form
   */
  eventHandler = (event) => {
    const { onEvent } = this.props;

    onEvent(event);
  };

  /**
   * @description updates for events triggered and updated by onEvent,
   * however, has an additional parameter for element specific behavior
   *
   * @param {Any} event - from form
   * @param {String} elemName - passed from form element
   */
  onEvent = (event, elemName) => {
    const { onEvent } = this.props;

    if (elemName === 'price_tier') {
      this.isPriceDisabled = event.value <= 0;
    }

    // for movie dropdown options
    if (elemName === 'instance_type') {
      onEvent({ name: '', value: '' }, 'rating');
    }

    onEvent(event, elemName);
  };

  /**
   * @description checks if array is not undefined
   *
   * @param {Any} arr
   *
   * @returns {Any[]}
   */
  getValidArray = (arr) => arr || [];

  /**
   * @description consolidates properties for the
   * date and time form components
   */
  getComponentParams = ({ name, from, to }, errors) => {
    const { saveClick, showEditHours, editHoursError } = this.props;

    return {
      from,
      to,
      saveClick,
      onAction: this.onAction,
      showEditHours,
      editHoursError,
      errors: errors[name],
    };
  };

  /**
   * @description returns what input components to render
   * based on the index value of input arrayData
   *
   * @param {Object[]} inputArr
   *
   * @returns {JSX}
   */
  renderInputs = (inputArr) => {
    const { errors, onEvent } = this.props;

    return inputArr.map((inputObj, index) => {
      const { type, name, hide } = inputObj;
      if (type === 'component') {
        if (
          name === 'hour_range' ||
          name === 'date_range' ||
          name === 'place_hours'
        ) {
          const obj = this.getComponentParams(inputObj, errors);

          return (
            <Fragment key={`form-input-${index}--${name}`}>
              {inputObj.component(obj)}
            </Fragment>
          );
        }

        if (name === 'amount_per') {
          return (
            <Fragment key={`form-input-${index}--${name}`}>
              {inputObj.component({
                amount: inputObj.amount,
                amount_max: inputObj.amount_max,
                per: inputObj.per,
                errors: errors.event_amount,
                isPriceDisabled: this.isPriceDisabled,
                onChange: (selected, passedName) =>
                  this.eventHandler({
                    value: selected.value || selected.target.value,
                    name: passedName || selected.target.name,
                  }),
              })}
            </Fragment>
          );
        }

        if (name === 'recurring_event') {
          return (
            <Fragment key={`form-input-${index}--${name}`}>
              {inputObj.component({
                isRecurring: inputObj.isRecurring,
                selectedEvents: inputObj.recurring_days,
                errors: errors.recurring_event,
                onChange: (recData) =>
                  this.eventHandler({ name: 'recurring_event', ...recData }),
              })}
            </Fragment>
          );
        }

        if (['address_input', 'address'].includes(name)) {
          return (
            <Fragment key={`form-input-${index}--${name}`}>
              {inputObj.component({
                onPlaceSelected: (data) =>
                  this.eventHandler({ name: data.name, value: data }),
                name: inputObj.name,
                value: inputObj.value,
                location: inputObj.location,
                errors: errors[inputObj.name],
                additionalComponent: inputObj.additionalComponent,
                disabled: inputObj.disabled,
              })}
            </Fragment>
          );
        }

        if (name === 'event_hours') {
          return (
            <Fragment key={`form-input-${index}--${name}`}>
              {inputObj.component({
                dates: inputObj.dates || [],
                errors: errors.event_hours,
                onDatesChange: (dates) =>
                  onEvent({ name: inputObj.name, value: dates }, inputObj.name),
              })}
            </Fragment>
          );
        }

        if (name === 'itemized_description') {
          const { value } = inputObj;

          return (
            <Fragment key={`form-input-${index}--${name}`}>
              {inputObj.component({
                value,
                onChange: (data, elemName) => onEvent(data, elemName),
                errors: errors.itemized_description,
              })}
            </Fragment>
          );
        }

        if (name === 'last_admin_review') {
          const { value, history, reviewDate } = inputObj;

          return (
            <Fragment key={`form-input-${index}--${name}`}>
              {inputObj.component({
                value,
                history,
                reviewDate,
                onChange: (data, elemName) => onEvent(data, elemName),
                errors: errors.last_admin_review,
              })}
            </Fragment>
          );
        }

        if (name === 'checkbox_row') {
          const { selectedOptions, onChange, options } = inputObj;

          return (
            <Fragment key={`form-input-${index}--${name}`}>
              {inputObj.component({
                onChange, // special on change event handler for checkbox row
                selectedOptions,
                options,
              })}
            </Fragment>
          );
        }

        if (name === 'recipe_prep_&_duration') {
          const { leftValue, rightValue } = inputObj;

          return (
            <Fragment key={`form-input-${index}--${name}`}>
              {inputObj.component({
                leftValue,
                rightValue,
                onChange: (data, elemName) => onEvent(data, elemName),
                leftError: errors.prep_time,
                rightError: errors.duration,
              })}
            </Fragment>
          );
        }

        if (name === 'section_header') {
          return (
            <Fragment key={`form-input-${index}--${name}`}>
              {inputObj.component()}
            </Fragment>
          );
        }

        if (name === 'name' || name === 'title') {
          const { value, onButtonClick } = inputObj;

          return (
            <Fragment key={`form-input-${index}--${name}`}>
              {inputObj.component({
                value, // special on change event handler for checkbox row
                onButtonClick,
                onChange: this.onEvent,
                error: errors[name],
              })}
            </Fragment>
          );
        }

        if (name === 'neighborhoods') {
          const { tags, suggestions, onButtonClick } = inputObj;

          return (
            <Fragment key={`form-input-${index}--${name}`}>
              {inputObj.component({
                tags, // special on change event handler for checkbox row
                suggestions,
                onChange: this.onEvent,
                onButtonClick,
                error: errors[name],
              })}
            </Fragment>
          );
        }

        if (name === 'pairings') {
          const { title, pairingReasonsOptions, verticalTypes, verticalType } =
            inputObj;

          return (
            <Fragment key={`form-input-${index}--${name}`}>
              {inputObj.component({
                title,
                verticalTypes,
                pairingReasonsOptions,
                verticalType,
                error: errors[name],
              })}
            </Fragment>
          );
        }

        // as of this writing PLACE only has a main_categories component
        // the rest are bundled up under 'categories' multiTagSelect
        if (name === 'main_categories') {
          const { options, values } = inputObj;

          return (
            <Fragment key={`form-input-${index}--${name}`}>
              {inputObj.component({
                options,
                values,
                onChange: (data, elemName) => onEvent(data, elemName),
                error: errors[name],
              })}
            </Fragment>
          );
        }
      }

      if (type !== 'component' && !hide) {
        if (name === 'price_tier') {
          const chosen_price_tier = inputObj.value.value;
          if (chosen_price_tier != '' && chosen_price_tier > 0) {
            this.isPriceDisabled = false;
          }
        }
        return (
          <div className="form-field-wrapper" key={index}>
            <FormInput
              options={{
                type: inputObj.type,
                name: inputObj.name,
                header: inputObj.header,
                label: inputObj.label,
                errorMsg: errors[inputObj.name],
                values: this.getValidArray(inputObj.values),
                value: inputObj.value,
                tags: this.getValidArray(inputObj.tags),
                suggestions: this.getValidArray(inputObj.suggestions),
                rows: inputObj.rows,
                showDropdown: inputObj.showDropdown,
                mainCategories: inputObj.main_categories,
                disabled: inputObj.disabled,
                additionalComponent: inputObj.additionalComponent,
                hideLabel: inputObj.hideLabel,
                buttonTitle: inputObj.buttonTitle,
              }}
              placeholder={inputObj.placeholder}
              // special onEvent declared in this Class
              eventHandler={this.onEvent}
            />
          </div>
        );
      }

      return null;
    });
  };

  render() {
    const { data } = this.props;

    return (
      <div>
        <form>{this.renderInputs(data)}</form>
      </div>
    );
  }
}

// ----------------------------------------------------------------------------|
//                               PropTypes Check
// ----------------------------------------------------------------------------|
Form.propTypes = {
  data: PropTypes.array.isRequired,
  errors: PropTypes.object,
  onEvent: PropTypes.func.isRequired,
  saveClick: PropTypes.func,
  onAction: PropTypes.func,
  showEditHours: PropTypes.bool,
  editHoursError: PropTypes.object,
  isPriceDisabled: PropTypes.bool,
};

// ----------------------------------------------------------------------------|
//                               Default Props
// ----------------------------------------------------------------------------|
Form.defaultProps = {
  errors: {},
  saveClick: () => {},
  onAction: () => {},
  showEditHours: false,
  editHoursError: {},
  isPriceDisabled: true,
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default Form;
