/**
 * ************************************
 *
 * @module  RecipeData.constants.js
 * @author  Matt P
 * @date    02/02/2021
 * @description Container for editing a 'Recipe' Currently renders within
 * the CardViewModal is shown renders when a card is clicked in the
 * RecipesList component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';

import { getDurationTiers } from 'utils/utils';

import { ONE_LINER_DESC_ROWS } from 'constants.js';

import {
  CheckboxRow,
  DetailedDescription,
  DropDown,
  ReviewStatus,
} from 'components';

// ----------------------------------------------------------------------------|
//                        RecipeData - Array of Objects
// ----------------------------------------------------------------------------|
const RecipeData = [
  {
    type: 'text',
    name: 'title',
    label: 'Name of Recipe',
    value: '',
    errorMsg: 'Name should be min 1 and max 100 characters long',
  },
  {
    type: 'tag',
    label: 'Category',
    name: 'categories',
    tags: [],
    suggestions: [],
    errorMsg: 'Type cannot be empty',
    showDropdown: true,
  },
  {
    type: 'tag',
    label: 'Meal',
    name: 'meal_type',
    placeholder: 'Select meal',
    value: '',
    errorMsg: '',
  },
  {
    type: 'component',
    name: 'recipe_prep_&_duration',
    label: '',
    component: ({ leftValue, rightValue, onChange, leftError, rightError }) => (
      <div className="form-field-wrapper form-field-double-comp">
        <div className="form-input">
          <label>Prep Time</label>
          <DropDown
            name="prep_time"
            options={getDurationTiers()}
            onSelect={onChange}
            value={leftValue}
          />
          <div className="error">{leftError}</div>
        </div>
        <div className="form-input">
          <label>Total Time</label>
          <DropDown
            name="duration"
            options={getDurationTiers()}
            onSelect={onChange}
            value={rightValue}
          />
          <div className="error">{rightError}</div>
        </div>
      </div>
    ),
  },
  {
    type: 'textarea',
    name: 'phrase',
    label: 'Short Description of the Recipe',
    value: '',
    errorMsg:
      'One liner description should be min 5 and max 50 characters long',
    rows: ONE_LINER_DESC_ROWS,
  },
  // {
  //   type: 'component',
  //   name: 'checkbox_row',
  //   label: '',
  //   // eslint-disable-next-line react/prop-types
  //   component: ({ onChange, options, selectedOptions }) => (
  //     <div className="form-field-wrapper">
  //       <CheckboxRow
  //         name="Diet (check all that apply)"
  //         options={options}
  //         selectedOptions={selectedOptions}
  //         onChange={onChange}
  //       />
  //     </div>
  //   ),
  // },
  {
    type: 'component',
    name: 'itemized_description',
    label: '',
    // eslint-disable-next-line react/prop-types
    component: ({ onChange, value, errors }) => (
      <div className="form-field-wrapper">
        <DetailedDescription
          name="itemized_description"
          onChange={onChange}
          value={value}
          errors={errors}
        />
      </div>
    ),
  },
  // {
  //   type: 'component',
  //   name: 'section_header', // not a form field
  //   component: () => (
  //     <div className="form-field-header">
  //       <h2>Ingredients</h2>
  //     </div>
  //   ),
  // },
  // {
  //   type: 'multiinput',
  //   name: 'ingredients',
  //   label: '',
  //   value: [],
  //   buttonTitle: 'Ingredient',
  //   hideLabel: true,
  // },
  {
    type: 'text',
    name: 'links_to_recipe',
    label: 'Website Link',
    value: '',
    errorMsg: 'Invalid link',
  },
  {
    type: 'component',
    name: 'last_admin_review',
    label: '',
    component: ({ value, history, onChange, reviewDate, errors }) => (
      <div className="form-field-wrapper">
        <ReviewStatus
          value={value}
          reviewDate={reviewDate}
          history={history}
          errors={errors}
          onChange={onChange}
        />
      </div>
    ),
  },
];

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default RecipeData;
