/**
 * ************************************
 *
 * @module  CuratedCards.reducer.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description CuratedCards reducer file
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import { toast } from 'react-toastify';

import ACTIONS from 'store/actions/actionTypes';

import { CURATED, GENERIC } from 'constants.js';

// ----------------------------------------------------------------------------|
//                               Initial State
// ----------------------------------------------------------------------------|
const initialState = {
  data: [],
  count: 0,
  initialDataLoaded: false,
  bulkActionSuccess: false,
  page: 1,
};

// ----------------------------------------------------------------------------|
//                            CuratedCards Reducer
// ----------------------------------------------------------------------------|
const CuratedCards = (state = initialState, action = null) => {
  const { BULK_ACTION_SUCCESS_MESSAGE, BULK_ACTION_FAILURE_MESSAGE } = GENERIC;
  const {
    TOAST: { DELETE_SUCCESS_MESSAGE, DELETE_FAILURE_MESSAGE },
  } = CURATED;
  const { type, data } = action;

  switch (type) {
    /**
     * @description upon successful fetch of curated card http list
     */
    case ACTIONS.CURATED_CARDS.GET_LIST_SUCCESS: {
      const { cards, count } = data;
      const updatedData = [...state.data, ...cards];
      const updatedPage = state.page + 1;

      return {
        ...state,
        data: updatedData,
        initialDataLoaded: true,
        page: updatedPage,
        count,
      };
    }

    /**
     * @description upon unsuccessful fetch of curated card http list
     */
    case ACTIONS.CURATED_CARDS.GET_LIST_FAIL: {
      return { ...state, initialDataLoaded: true };
    }

    /**
     * @description clears list
     */
    case ACTIONS.CURATED_CARDS.CLEAR_LIST: {
      const {
        data: initialData,
        count,
        initialDataLoaded,
        page,
      } = initialState;

      return { ...state, data: initialData, count, initialDataLoaded, page };
    }

    /**
     * @description upon successful delete
     */
    case ACTIONS.CURATED_CARDS.DELETE_CURATED_CARDS_SUCCESS: {
      const { id } = action.data;
      const updatedData = state.data.filter((el) => el.id !== id);
      const updatedCount = state.count - 1;

      toast.success(DELETE_SUCCESS_MESSAGE);

      return { ...state, data: [...updatedData], count: updatedCount };
    }

    /**
     * @description upon unsuccessful delete
     */
    case ACTIONS.CURATED_CARDS.DELETE_CURATED_CARDS_FAIL: {
      toast.error(DELETE_FAILURE_MESSAGE);

      return { ...state };
    }

    /* ---------------- Bulk actions --------------------- */
    /**
     * @description bulk action like bulk delete or publish
     */
    case ACTIONS.CURATED_CARDS.BULK_ACTION: {
      return { ...state, bulkActionSuccess: false };
    }

    /**
     * @description upon successful bulk action
     */
    case ACTIONS.CURATED_CARDS.BULK_ACTION_SUCCESS: {
      const updatedCuratedCardList = state.data.filter(
        (curatedCard) => action.data.id_list.indexOf(curatedCard.id) < 0
      );
      const updatedTotalCount = state.totalCount - action.data.id_list.length;
      const message = action.data.delete
        ? DELETE_SUCCESS_MESSAGE
        : BULK_ACTION_SUCCESS_MESSAGE;

      toast.success(message);

      return {
        ...state,
        data: updatedCuratedCardList,
        totalCount: updatedTotalCount,
        bulkActionSuccess: true,
      };
    }

    /**
     * @description upon unsuccessful bulk action
     */
    case ACTIONS.CURATED_CARDS.BULK_ACTION_FAIL: {
      const message = action.data.delete
        ? DELETE_FAILURE_MESSAGE
        : BULK_ACTION_FAILURE_MESSAGE;

      toast.error(message);

      return { ...state };
    }

    /**
     * @description moves a card to the top
     */
    case ACTIONS.CURATED_CARDS.MOVE_TO_TOP: {
      const { id, data: modifiedData } = action.data;
      const dataWithID = state.data.find((el) => el.id === id);
      const updatedDataWithoutID = state.data.filter((el) => el.id !== id);
      const updatedData = [
        { ...dataWithID, ...modifiedData },
        ...updatedDataWithoutID,
      ];

      return { ...state, data: updatedData };
    }

    /**
     * @description removes card
     */
    case ACTIONS.CURATED_CARDS.REMOVE_CARD: {
      const { id } = action.data;
      const updatedData = state.data.filter((el) => el.id !== id);
      const updatedCount = state.count - 1;

      return { ...state, data: [...updatedData], count: updatedCount };
    }

    default: {
      return { ...state };
    }
  }
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default CuratedCards;
