/**
 * ************************************
 *
 * @module  Places.saga.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description redux saga file for the Places container.
 *
 *  * ** PLACES IS IS A DEPENDENCY FOR OTHER FILES (ie. Curated Card(s)) and
 * can not yet be depreciated
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                    Imports
// ----------------------------------------------------------------------------|
import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import ACTIONS from 'store/actions/actionTypes';
import API from 'API';
import {
  clearPlacesAction,
  getPlacesAction,
} from 'store/actions/Places.action';

import { GENERIC } from 'constants.js';
import { constructQueryParams } from 'utils/utils';

// ----------------------------------------------------------------------------|
//                                Sagas - Places
// ----------------------------------------------------------------------------|
/**
 * @description gets places card list http request
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* getPlaceList(action) {
  const { searchData } = window.axios;

  try {
    let URL;
    let response;

    if (action.data.status === 'queue') {
      URL = `${API.placeUncurated}?page=${action.data.page}`;

      delete action.data.status;
      delete action.data.page;

      response = yield call(searchData, 'post', URL, action.data);
    } else {
      // URL = `${API.place}?${constructQueryParams(action.data)}`;

      // response = yield call(searchData, 'get', URL);
      response = yield call(searchData, 'post', URL, action.data);
    }

    if (response.status === 200) {
      yield put({
        type: ACTIONS.PLACES.GET_PLACES_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: ACTIONS.PLACES.GET_PLACES_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.PLACES.GET_PLACES_FAIL });
  }
}

/**
 * @description handles delete place http request
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* deletePlace(action) {
  const { deleteData } = window.axios;
  const { DELETE_SUCCESS_MESSAGE, DELETE_FAILURE_MESSAGE } = GENERIC;

  try {
    const URL = `${API.place}${action.data}`;
    const response = yield call(deleteData, URL);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.PLACES.DELETE_PLACE_SUCCESS,
        data: action.data,
      });
      toast.success(DELETE_SUCCESS_MESSAGE);
    } else {
      toast.error(DELETE_FAILURE_MESSAGE);
      yield put({ type: ACTIONS.PLACES.DELETE_PLACE_FAIL });
    }
  } catch (error) {
    toast.error(DELETE_FAILURE_MESSAGE);
    yield put({ type: ACTIONS.PLACES.DELETE_PLACE_FAIL });
  }
}

/**
 * @description performs bulk operations like bulk delete and publish
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* performBulkOperationOnPlace(action) {
  const { patchData } = window.axios;
  const { data } = action;
  try {
    const response = yield call(patchData, API.place, data);

    if (response.status === 200) {
      yield put({ type: ACTIONS.PLACES.BULK_ACTION_SUCCESS, data });
    } else {
      yield put({ type: ACTIONS.PLACES.BULK_ACTION_FAIL, data });
    }
  } catch (error) {
    yield put({ type: ACTIONS.PLACES.BULK_ACTION_FAIL, data });
  }
}

/**
 * @description fetches the adminCategories. this is now handled by the
 * categories store for the VerticalForm and VerticalDisplay components
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
export function* fetchAdminCategories(action) {
  const { getData } = window.axios;

  try {
    const response = yield call(getData, API.categories, action.data);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.PLACES.FETCH_ADMIN_CATEGORIES_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: ACTIONS.PLACES.FETCH_ADMIN_CATEGORIES_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.PLACES.FETCH_ADMIN_CATEGORIES_FAIL });
  }
}

/**
 * @description fetches the neighborhood locations. this is now handled by the
 * categories store for the VerticalForm and VerticalDisplay components
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* fetchNeighborhoodLocations(action) {
  const { getData } = window.axios;

  try {
    const response = yield call(
      getData,
      API.neighborhoodLocations,
      action.data
    );

    if (response.status === 200) {
      yield put({
        type: ACTIONS.PLACES.FETCH_NEIGHBORHOOD_LOCATIONS_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: ACTIONS.PLACES.FETCH_NEIGHBORHOOD_LOCATIONS_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.PLACES.FETCH_NEIGHBORHOOD_LOCATIONS_FAIL });
  }
}

/**
 * @description filters places list
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* filterPlacesList(action) {
  yield put(clearPlacesAction());
  const pageNum = yield select((state) => state.places.pageNum);
  const data = { ...action.data, page: pageNum };
  yield put(getPlacesAction(data));
}

// ----------------------------------------------------------------------------|
//                            Places Saga Export
// ----------------------------------------------------------------------------|
export {
  getPlaceList,
  deletePlace,
  fetchNeighborhoodLocations,
  performBulkOperationOnPlace,
  filterPlacesList,
};
