/**
 * ************************************
 *
 * @module  CuratedCards.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description CuratedCards container
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import moment from 'moment';
import {
  fetchAdminCategoriesAction,
  fetchNeighborhoodLocationsAction,
  clearSelectedPlacesAction,
} from 'store/actions/Places.action.js';
import { clearData } from 'store/actions/CuratedCard.action.js';
import CuratedCardList from './CuratedCardList/CuratedCardList';
import CuratedCard from '../CuratedCard/CuratedCard';
import {
  CardBanner,
  CardViewModal,
  DeleteModal,
  Loader,
  NoDataFound,
  TotalCount,
  FilterSection,
  Tabs,
  WidgetBox,
} from 'components';
import {
  getCuratedCardsAction,
  performBulkOperationAction,
  clearCuratedCardsAction,
  deleteCuratedCardAction,
  filterCuratedCardsAction,
} from '../../store/actions/CuratedCards.action';
import { CURATED, GENERIC, PLACE, DATE_FORMATS } from 'constants.js';
import {
  getPartsFromUrl,
  checkHasMore,
  getSelectedCardCount,
  askForCloseConfirmation,
  getPriceRange as formPriceOptions,
} from 'utils/utils';
import {
  FilterType,
  formGroupOptions,
} from '../../components/FilterSection/FiltersFactory';
import CommonService from 'services/CommonService.js';
import './CuratedCards.scss';

// ----------------------------------------------------------------------------|
//                            Property Mapping
// ----------------------------------------------------------------------------|
// places from state are taken for filterSection.
const mapStateToProps = (state) => ({
  curated: state.curated,
  places: state.places,
  shouldDisplayImageInspector: state.shouldDisplayImageInspector,
});

const mapDispatchToProps = (dispatch) => ({
  getCuratedCardList: (data) => dispatch(getCuratedCardsAction(data)),
  clearCuratedCardList: () => dispatch(clearCuratedCardsAction()),
  deletePlace: (id) => dispatch(deleteCuratedCardAction(id)),
  fetchAdminCategories: () => dispatch(fetchAdminCategoriesAction()),
  fetchNeighborhoodLocations: () =>
    dispatch(fetchNeighborhoodLocationsAction()),
  clearData: () => dispatch(clearData()),
  clearSelectedPlaces: () => dispatch(clearSelectedPlacesAction()),
  performBulkOperation: (data) => dispatch(performBulkOperationAction(data)),
  filterCuratedCards: (data) => dispatch(filterCuratedCardsAction(data)),
});

// ----------------------------------------------------------------------------|
//                              Utilities
// ----------------------------------------------------------------------------|
const commonService = CommonService();

// ----------------------------------------------------------------------------|
//                     React Class Component - CuratedCard
// ----------------------------------------------------------------------------|
class CuratedCards extends Component {
  // --------------------------------------------------------------------------|
  //                          PropTypes Check
  // --------------------------------------------------------------------------|
  static propTypes = {
    location: PropTypes.object.isRequired,
    curated: PropTypes.object.isRequired,
    places: PropTypes.object.isRequired,
    getCuratedCardList: PropTypes.func.isRequired,
    performBulkOperation: PropTypes.func.isRequired,
    clearCuratedCardList: PropTypes.func.isRequired,
    deletePlace: PropTypes.func.isRequired,
    fetchAdminCategories: PropTypes.func.isRequired,
    fetchNeighborhoodLocations: PropTypes.func.isRequired,
    clearSelectedPlaces: PropTypes.func.isRequired,
    clearData: PropTypes.func.isRequired,
    filterCuratedCards: PropTypes.func.isRequired,
    shouldDisplayImageInspector: PropTypes.bool,
  };

  // --------------------------------------------------------------------------|
  //                              State - Places
  // --------------------------------------------------------------------------|
  constructor() {
    super();

    this.state = {
      query: '',
      currentTab: 'published',
      hasMore: true,
      bulkDeleteModal: false,
      deleteModalData: null,
      newCuratedCardData: null,
      selectedCards: [],
      isChecked: null,
      neighborhood: [],
      price: [],
      category: [],
      dateRange: undefined,
      openCardID: '',
      shouldDisplayImageInspector: false,
    };
  }

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  CuratedCards lifecycle
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  componentDidMount() {
    this.setCurrentTab();
    const { places } = this.props;
    // Fetch adminCategories, neighborhoodLocations only if they are not present
    if (!places.adminCategories.length) {
      this.props.fetchAdminCategories();
    }
    if (!places.neighborhoodLocations.length) {
      this.props.fetchNeighborhoodLocations();
    }
    this.unlisten = this.props.history.listen((location, action) => {
      // this.handleIDAnchor();
      this.handleIDPathname();
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setCurrentTab();
    }
    if (this.props.curated.data.length !== prevProps.curated.data.length) {
      this.setHasMore();
    }
    if (
      this.props.curated.bulkActionSuccess !==
        prevProps.curated.bulkActionSuccess &&
      this.props.curated.bulkActionSuccess
    ) {
      this.dismissWidget();
    }
    if (this.props.curated.initialDataLoaded) {
      // this.handleIDAnchor();
      this.handleIDPathname();
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  /**
   * @description Handle presence of DB id in URL: redirect user
   * to relevant content
   */
  handleIDPathname = () => {
    const { id } = commonService.pathnameHelper(this.props.location.pathname);

    if (id) {
      if (this.state.openCardID !== id) {
        if (this.state.openCardID) {
          this.setState({ newCuratedCardData: null, openCardID: '' });
        } else {
          this.setState({ openCardID: id });

          const { curated } = this.props;
          let curatedCardData;

          // searches for already fetched cards
          for (let i = 0; i < curated.data.length; i += 1) {
            if (curated.data[i].id === id) {
              curatedCardData = curated.data[i];
              break;
            }
          }

          // if it finds it, load it
          if (curatedCardData) {
            this.setState({ newCuratedCardData: curatedCardData });
          } else {
            // if not, open a new modal and have it fetch the id
            this.setState({
              newCuratedCardData: {
                id,
                shouldFetchCard: true,
                shouldDisplayLoader: true,
              },
            });
          }
        }
      }
    } else {
      if (this.state.openCardID) {
        this.setShowNewCuratedCard(null);
      }
    }
  };

  /**
   * @description sets state on if more cards are available
   * checkHasMore is imported from the common utils/utils file
   */
  setHasMore = () => {
    const { count, data } = this.props.curated;
    const hasMore = checkHasMore(data.length, count);
    this.setState({ hasMore });
  };

  /**
   * @description constructs a request object which is sent to Events.saga
   * and ultimately requests a response based on state
   *
   * @returns {Object} - request object sent to Events.saga
   */
  constructRequestObject = () => {
    /* eslint-disable camelcase */
    const { currentTab, category, neighborhood, price, query, dateRange } =
      this.state;
    const { page } = this.props.curated;

    let datetime_min;
    let datetime_max;
    if (dateRange) {
      const { isoDateTime } = DATE_FORMATS;
      datetime_min = moment(dateRange.start).format(isoDateTime);
      datetime_max = moment(dateRange.end).endOf('day').format(isoDateTime);
    }

    const status = currentTab === 'drafts' ? 'draft' : currentTab;
    return {
      page,
      query,
      status,
      cat_unique_slug_list: category.map(({ value }) => value),
      prices: price.map(({ value }) => value),
      nh_unique_slugs: neighborhood.map(({ value }) => value),
      datetime_min,
      datetime_max,
    };
  };

  /**
   * @description constructs a request object and invokes http request
   * (passed from redux) which is sent to redux saga and ultimately
   * requests a response based on state
   */
  getCuratedList = () => {
    const data = this.constructRequestObject();
    this.props.getCuratedCardList(data);
  };

  /**
   * @description sets the current horizontal tab by invoking the
   * getPartsFromUrl function imported from utils/utils based on
   * the second part of the current route.
   */
  setCurrentTab = () => {
    const { secondPart: currentTab } = getPartsFromUrl(
      this.props.location.pathname
    );
    this.props.clearCuratedCardList();
    this.dismissWidget();
    this.setState(
      { currentTab, newCuratedCardData: null },
      this.getCuratedList
    );
  };

  /**
   * @description fires when the modal window for editing a card
   * is closed. Used for pushing the correct history to Routers
   * history object, dismissing the widget then setting the tab.
   */
  onCloseModal = () => {
    const { section, tab, id } = commonService.pathnameHelper(
      this.props.location.pathname
    );

    if (id) {
      this.props.history.push(`/${section}/${tab}`);
    }

    this.dismissWidget();
    this.setCurrentTab();
  };

  /**
   * @description requests more data after setting states hasMore prop to false.
   */
  getMoreData = () => {
    this.setState({ hasMore: false }, this.getCuratedList);
  };

  /**
   * @description clears list and searches a string query
   */
  updateSearchTerm = (query) => {
    this.props.clearCuratedCardList();
    this.setState({ query }, this.getCuratedList);
  };

  /**
   * @description toggles creation of a new curated card
   */
  setShowNewCuratedCard = (newCuratedCardData) => {
    const { section, tab, id } = commonService.pathnameHelper(
      this.props.location.pathname
    );

    // prevents infinite loop with id/endpoint rendering
    if (id) {
      this.props.history.push(`/${section}/${tab}`);
    }

    this.props.clearSelectedPlaces();
    this.props.clearData();
    this.dismissWidget();
    this.setState({ newCuratedCardData, openCardID: '' });
  };

  /**
   * @description clears delete modal data
   */
  closeDeleteModalData = () => this.setState({ deleteModalData: null });

  /**
   * @description deletes a card
   */
  deletePlaceFromList = () => {
    const { deleteModalData } = this.state;
    this.closeDeleteModalData();
    this.props.deletePlace(deleteModalData);
  };

  /**
   * @description fires when you click a card, type of action depends on the
   * string value passed.
   *
   * @param {String} value - String specifying the action (Edit/Delete/Etc..)
   * @param {Object} data - Object of activity data
   */
  onMenuClicked = (value, data) => {
    const {
      SUB_MENU: { DELETE, EDIT },
    } = GENERIC;

    const { tab } = commonService.pathnameHelper(this.props.location.pathname);
    const { id } = data;

    if (value === EDIT) {
      window.open(`${tab}/${id}`);
      // this.props.history.push(`${tab}/${id}`);
    }

    // Delete action
    if (value === DELETE) {
      const { id } = data;
      this.setState({ deleteModalData: id });
    }
  };

  /* ------------ Bulk delete ------------------ */

  /**
   * @description sets bulkDeleteModal property to false
   */
  closeBulkDeleteModal = () => this.setState({ bulkDeleteModal: false });

  /**
   * @description triggers bulk delete from the List of cards
   */
  bulkDeleteFromList = () => {
    const {
      BULK_ACTIONS: { DELETE },
    } = GENERIC;
    this.closeBulkDeleteModal();
    this.performBulkOperation(DELETE);
  };

  /* ---------- Bulk action methods -------------- */
  /**
   * @description redux helper for correct action
   *
   * @argument {Object} actions - with action types
   */
  getRelevantActions = (actions) => {
    switch (this.state.currentTab) {
      case 'published':
        return [actions.DELETE, actions.DRAFT];
      case 'drafts':
        return [actions.DELETE, actions.PUBLISH];
      case 'expired':
        return [actions.DELETE];
      default:
        return [];
    }
  };

  /**
   * @description performs a bulk operation depending on type
   * string passed
   *
   * @argument {String} type - type of bulk operation
   */
  performBulkOperation = (type) => {
    const {
      BULK_ACTIONS: { DELETE, PUBLISH, DRAFT },
    } = GENERIC;
    const data = {
      delete: type === DELETE,
      id_list: this.state.selectedCards,
    };
    if (type === PUBLISH) {
      data.status = 'published';
    }
    if (type === DRAFT) {
      data.status = 'draft';
    }
    this.props.performBulkOperation(data);
  };

  /**
   * @description toggles what is included in the selectedCards state property
   *
   * @param {Boolean} currentCheckValue - toggles if card should be included
   * @param {String} cardId- string with unique cardId
   */
  onCheckBoxSelected = (currentCheckValue, cardId) => {
    this.setState((prevState) => {
      let selectedCards = [...prevState.selectedCards];
      if (currentCheckValue) {
        selectedCards.push(cardId);
      } else {
        selectedCards = selectedCards.filter((id) => id !== cardId);
      }
      const tempState = { selectedCards };
      if (typeof prevState.isChecked === 'boolean') {
        tempState.isChecked = null;
      }
      return tempState;
    });
  };

  /**
   * @description deselects all cards by setting state property isChecked
   * to false
   */
  deselectAllCards = () => {
    this.setState({ isChecked: false });
  };

  /**
   * @description resets widget to initial state
   */
  dismissWidget = () => {
    this.setState({ selectedCards: [], isChecked: null });
  };

  /**
   * @description Fires when filter options are selected
   *
   * @param {String} returnType - string for data type
   * @param {Array} data - of object  data to set to state
   * @param {Function} callback - to fire after state update
   */
  onFilterSelect = (returnType, data) => {
    let selectedData = data;
    // prevents a bug with null being passed when deleting filters
    if (
      (returnType === 'category' ||
        returnType === 'neighborhood' ||
        returnType === 'price' ||
        returnType === 'rating') &&
      selectedData === null
    )
      selectedData = [];

    // API call to filter cards based on value selected from dropdown
    this.setState({ [returnType]: selectedData });
  };

  /**
   * @description Fires when filter menu closes.
   * constructs a request object then invokes the redux action
   */
  onFilterMenuClosed = () => {
    const data = this.constructRequestObject();
    this.props.filterCuratedCards(data);
  };

  /**
   * @description clears away filters
   */
  clearFilters = () => {
    this.props.clearCuratedCardList();
    this.setState(
      { category: [], neighborhood: [], price: [], dateRange: undefined },
      this.getCuratedList
    );
  };

  /**
   * @description populates filter options for CuratedCards
   */
  getFiltersForCuratedCards = () => {
    const { price, category, dateRange, neighborhood } = this.state;
    const { adminCategories, neighborhoodLocations } = this.props.places;
    const {
      FILTER_DROP_DOWN: { LOCATION, PRICE, TYPE, DATE_RANGE },
    } = PLACE;

    return [
      {
        name: LOCATION.LABEL,
        returnType: LOCATION.RETURN_TYPE,
        options: formGroupOptions(neighborhoodLocations, 'neighborhoods'),
        value: neighborhood,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.onFilterMenuClosed,
        isSearchable: true,
      },
      {
        name: DATE_RANGE.LABEL,
        returnType: DATE_RANGE.RETURN_TYPE,
        value: dateRange,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.DATE_RANGE_PICKER,
        onMenuClose: this.onFilterMenuClosed,
      },
      {
        name: PRICE.LABEL,
        returnType: PRICE.RETURN_TYPE,
        options: formPriceOptions(),
        value: price,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.onFilterMenuClosed,
      },
      {
        name: TYPE.LABEL,
        returnType: TYPE.RETURN_TYPE,
        options: formGroupOptions(
          adminCategories,
          'categories',
          'unique_slug',
          'name'
        ),
        value: category,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.onFilterMenuClosed,
        isSearchable: true,
      },
    ];
  };

  /**
   * @description sets state and fires a GET request for a search bar
   *
   * @param {String} query - string for the query search
   */
  onSearchInput = (query) => {
    // API call to filter cards based on query
    this.props.clearCuratedCardList();
    this.dismissWidget();
    this.setState({ query }, this.getCuratedList);
  };

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  CuratedCards rendering
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  render() {
    const {
      deleteModalData,
      bulkDeleteModal,
      query,
      currentTab,
      hasMore,
      newCuratedCardData,
      selectedCards,
      isChecked,
      shouldDisplayImageInspector,
    } = this.state;

    const filterSectionUI = (
      <FilterSection
        filters={this.getFiltersForCuratedCards()}
        searchValue={this.state.query}
        onSearchInput={this.onSearchInput}
        clearFilters={this.clearFilters}
        imageInspectSwitchHandler={(value) => {
          this.setState({ shouldDisplayImageInspector: value });
        }}
        shouldDisplayImageInspectorSwitch={true}
        shouldDisplayImageInspector={shouldDisplayImageInspector}
      />
    );

    const { data, initialDataLoaded, count } = this.props.curated;

    const { TAB_DATA, CARD_VIEW_TITLE, CARD_VIEW_TITLE_EDIT } = CURATED;
    const {
      BULK_ACTIONS: { DELETE, PUBLISH, DRAFT },
      CLOSE_CARD_WARNING_MESSAGE,
    } = GENERIC;
    const pageTitle =
      newCuratedCardData && newCuratedCardData.id
        ? CARD_VIEW_TITLE_EDIT
        : CARD_VIEW_TITLE;
    // Collection of all possible actions in bulk actions widget
    const widgetActions = {
      DELETE: {
        label: DELETE,
        onClick: () => {
          this.setState({ bulkDeleteModal: true });
        },
        className: 'btn-inverse',
      },
      PUBLISH: {
        label: PUBLISH,
        onClick: () => {
          this.performBulkOperation(PUBLISH);
        },
      },
      DRAFT: {
        label: DRAFT,
        onClick: () => {
          this.performBulkOperation(DRAFT);
        },
      },
    };

    const selectionWidget = selectedCards.length > 0 && (
      <WidgetBox
        className="curated-widget"
        data={this.getRelevantActions(widgetActions)}
        onDeselectClicked={this.deselectAllCards}
        actionText={getSelectedCardCount(selectedCards)}
        showStatus
      />
    );

    let content = <Loader />;

    if (initialDataLoaded) {
      if (count === 0) {
        content = <NoDataFound />;
      } else {
        content = (
          <Fragment>
            <TotalCount current={data.length} total={count} />
            <CuratedCardList
              data={data}
              hasMore={hasMore}
              getMoreData={this.getMoreData}
              onMenuClicked={this.onMenuClicked}
              onCheckBoxSelected={this.onCheckBoxSelected}
              isChecked={isChecked}
              shouldDisplayImageInspector={shouldDisplayImageInspector}
            />
          </Fragment>
        );
      }
    }

    return (
      <div className="curated-card">
        <div className="page-content">
          {newCuratedCardData !== null ? (
            <CardViewModal
              title={pageTitle}
              modalClosed={() => askForCloseConfirmation(this.onCloseModal)}
            >
              {/* <Prompt when message={CLOSE_CARD_WARNING_MESSAGE} /> */}
              <CuratedCard
                data={newCuratedCardData}
                closeModal={() => this.onCloseModal()}
                currentTab={currentTab}
              />
            </CardViewModal>
          ) : (
            <Fragment>
              <CardBanner
                title="Curated Cards"
                searchFieldName="query"
                searchFieldValue={query}
                onSearch={this.updateSearchTerm}
                newBtnClick={this.setShowNewCuratedCard}
              />
              <Tabs data={TAB_DATA} active={currentTab} />
              {filterSectionUI}
              {content}
              {deleteModalData !== null && (
                <DeleteModal
                  cancelClick={this.closeDeleteModalData}
                  deleteClick={this.deletePlaceFromList}
                />
              )}
              {bulkDeleteModal && (
                <DeleteModal
                  cancelClick={this.closeBulkDeleteModal}
                  deleteClick={this.bulkDeleteFromList}
                />
              )}
            </Fragment>
          )}
        </div>
        {selectionWidget}
      </div>
    );
  }
}

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CuratedCards)
);
