/**
 * ************************************
 *
 * @module  EnvironmentSpecificValues.ts
 * @author  Matt P
 * @date    05/27/2021
 * @description some data created on Prod/Staging/Dev will have different
 * values and ID's (ie: Categories) depending on the database environment.
 *
 * This file is so the different environments target the correct data.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                          ENV Specific Values
// ----------------------------------------------------------------------------|
/* eslint-disable max-len */
const development = {
  parentSlugs: {
    vibes: 'vibe',
    movieTv: 'tv-movies--quarantine-coupledom',
    movieRating: 'movie-rating',
    tvRating: 'tv-show-rating',
    recreation: 'activities--quarantine-coupledom',
    cookAndBake: 'cook-bake--quarantine-coupledom',
    recipes: 'recipes',
    diet: 'diet',
    holiday: 'holiday',
    perfectFor: 'perfect-for',
    noteworthy: 'noteworthy',
    mealType: 'dish-type',
  },

  uniqueSlugs: {
    indoor: 'indoor',
    outdoor: 'outdoor',
    virtualEvent: 'virtual-events--quarantine-coupledom',
  },

  neighborhoodSlugs: {
    stayIn: 'special-quarantine',
    newYorkCity: 'new-york',
  },

  recipeMealSlugs: {
    breakfast: 'breakfast',
    brunch: 'brunch',
    dessert: 'dessert',
    dinner: 'dinner',
    lunch: 'lunch',
  },

  uniqueIds: {
    indoor: '60afdb7e64004aade2b24117',
    outdoor: '60afdb65612be5a82e26a46f',
    virtualEvent: '5f30d5f57ab1fa0c8ca52be0',
    // Recipe Meal types
    breakfast: '60be4971d105e981456572ac',
    brunch: '60be497ddab6ad0eb3c99b42',
    dessert: '60be49b9d105e981456572ae',
    dinner: '60be49a2dab6ad0eb3c99b43',
    lunch: '60be4987d105e981456572ad',
  },

  api: {
    clarifai: '6f89c8c148164f4b810f3e2038450960',
    mapbox:
      'pk.eyJ1IjoiY29iYmxlYXBwIiwiYSI6ImNrbnl1anB3ZTA3NHkyb3BlcHltdnpydnYifQ.tnlGsRKJTAzreULZX0gzyA',
  },

  styles: {
    mapbox: 'mapbox://styles/cobbleapp/cjxu74zji02ve1cmmiwwangjt',
  },
};

const staging = {
  parentSlugs: {
    vibes: 'vibe',
    movieTv: 'tv-movies--quarantine-coupledom',
    movieRating: 'movie-rating',
    tvRating: 'tv-show-rating',
    recreation: 'activities--quarantine-coupledom',
    recipes: 'recipes',
    cookAndBake: 'cook-bake--quarantine-coupledom',
    diet: 'diet',
    holiday: 'holiday',
    perfectFor: 'perfect-for',
    noteworthy: 'noteworthy',
    mealType: 'dish-type',
  },

  uniqueSlugs: {
    indoor: 'indoor',
    outdoor: 'outdoor',
    virtualEvent: 'virtual-events--quarantine-coupledom',
  },

  neighborhoodSlugs: {
    stayIn: 'special-quarantine',
    newYorkCity: 'new-york',
  },

  recipeMealSlugs: {
    breakfast: 'breakfast',
    brunch: 'brunch',
    dessert: 'dessert',
    dinner: 'dinner',
    lunch: 'lunch',
  },

  uniqueIds: {
    indoor: '60b16c8f4fb4dee2ceee922a',
    outdoor: '60b16cae4fb4dee2ceee922b',
    virtualEvent: '5f30e4fc7ab1fa0c8ca52cbb',
  },

  api: {
    clarifai: '6f89c8c148164f4b810f3e2038450960',
    mapbox:
      'pk.eyJ1IjoiY29iYmxlYXBwIiwiYSI6ImNrbnl1c2QxYjEyMXAybnF6eTQ0MmZkcmcifQ.dEdMfYpzNYjY_8sb4oJ0yg',
  },

  styles: {
    mapbox: 'mapbox://styles/cobbleapp/cjziflkae1mtz1cvv43tvrnho',
  },
};

const production = {
  parentSlugs: {
    vibes: 'vibe',
    movieTv: 'tv-movies--quarantine-coupledom',
    movieRating: 'movie-rating',
    tvRating: 'tv-show-rating',
    recreation: 'activities--quarantine-coupledom',
    recipes: 'recipes',
    cookAndBake: 'cook-bake--quarantine-coupledom',
    diet: 'diet',
    holiday: 'holiday',
    perfectFor: 'perfect-for',
    noteworthy: 'noteworthy',
    mealType: 'dish-type',
  },

  uniqueSlugs: {
    indoor: 'indoor',
    outdoor: 'outdoor-dining--outdoor-dining',
    virtualEvent: 'virtual-events--quarantine-coupledom',
  },

  neighborhoodSlugs: {
    stayIn: 'special-quarantine',
    newYorkCity: 'new-york',
  },

  recipeMealSlugs: {
    breakfast: 'breakfast',
    brunch: 'breakfast-brunch--restaurants-cafes',
    dessert: 'dessert--restaurants-cafes',
    dinner: 'dinner',
    drinks: 'drinks--drinks-nightlife',
    lunch: 'lunch--restaurants-cafes',
  },

  uniqueIds: {
    indoor: '60987c0df94400354216c63e',
    outdoor: '5ef4a8742ef6a96253daf3b6',
    virtualEvent: '5ea0b3fc63b9694b05b4f776',
  },

  api: {
    clarifai: '9afc1c2757ef4a09ae60020f35598db9',
    mapbox:
      'pk.eyJ1IjoiY29iYmxlYXBwIiwiYSI6ImNrbnl1dDRlMTExcGsydXF6cDhidTB6bncifQ.iidb2H4XiqDxS7qib2nHBQ',
  },

  styles: {
    mapbox: 'mapbox://styles/cobbleapp/ck1aqwsww07821cpgyk5q7uqt',
  },
};

// Uncomment for correct environment
// const currentEnv = development;

// const currentEnv = staging;

const currentEnv = production;

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default currentEnv;
