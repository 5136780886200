/**
 * ************************************
 *
 * @module  EditHours.js
 * @author  Matt P
 * @date    12/04/2020
 * @description Component which generates an hour range
 * picker. Includes buttons for Closed and Open 24 Hours. Used for edit hours
 * modal for place form +Add Hours button creates rows
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// this Checkbox is different from the src/components/Button component,
// lives within the EditHours directory
import Checkbox from 'components/EditHours/Checkbox';
import { Button } from 'components';

import 'components/EditHours/EditHours.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const EditHours = ({
  label,
  onChange,
  getHourRange,
  checkOpenOrClose,
  openCheck,
  closeCheck,
  varyCheck,
  errors,
  addMoreHours,
  addMoreHourCheck,
  keyData,
}) => {
  const timeSlots = [];

  if (
    keyData.slots.length &&
    keyData.slots[0] &&
    !(keyData.slots[0].join(' ') === ['00:00:00', '23:59:59'].join(' '))
  ) {
    keyData.slots.forEach((item, index) => {
      timeSlots.push(
        getHourRange(item[0], item[1], onChange, `${label} ${index}`, index)
      );
    });
  }

  return (
    <Fragment>
      <div className="edit-hours-modal">
        <div className="modal-header-container">
          <Checkbox
            className="square"
            label={`checkbox ${label} open`}
            onClick={checkOpenOrClose}
            toBeChecked={openCheck}
            fromRecurring
          />
          <label>Open 24 Hours</label>
          <Checkbox
            className="square"
            label={`checkbox ${label} close`}
            onClick={checkOpenOrClose}
            toBeChecked={closeCheck}
            fromRecurring
          />
          <label>Closed</label>
          <Checkbox
            className="square"
            label={`checkbox ${label} vary`}
            onClick={checkOpenOrClose}
            toBeChecked={varyCheck}
            fromRecurring
          />
          <label>Hours may vary</label>
        </div>
        <div className={`modal-container ${label}`}>{timeSlots}</div>
        {errors && <div className="errors">{errors}</div>}
        {keyData.slots.length < 4 &&
        !(closeCheck || openCheck || addMoreHourCheck) ? (
          <Button
            className="add-more-hours"
            onClick={(event) => {
              addMoreHours(event, keyData);
            }}
          >
            +Add Hours
          </Button>
        ) : (
          ''
        )}
      </div>
    </Fragment>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
EditHours.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  getHourRange: PropTypes.func.isRequired,
  checkOpenOrClose: PropTypes.func.isRequired,
  openCheck: PropTypes.bool,
  closeCheck: PropTypes.bool,
  errors: PropTypes.string,
  addMoreHours: PropTypes.func,
  addMoreHourCheck: PropTypes.bool,
  keyData: PropTypes.any.isRequired,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
EditHours.defaultProps = {
  label: '',
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default EditHours;
