/**
 * ************************************
 *
 * @module  TotalCount.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description shows total count of cards above card selection menus
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------------|
//                              Utilities
// ----------------------------------------------------------------------------|
const style = {
  height: '40px',
  width: '100%',
  backgroundColor: '#F6F5F5',
};
const contentStyle = {
  width: '100%',
  color: '#A8A4A0',
  textAlign: 'center',
  padding: '12px 0 12px 0',
};

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const TotalCount = ({ current, total, outsideFetch }) => {
  // for sections that fetch to outside resources, like TMDB
  // as we do now know the total count from these services
  if (outsideFetch) {
    return (
      <div style={style}>
        <div style={contentStyle}>{total}+</div>
      </div>
    );
  }

  return (
    <div style={style}>
      <div style={contentStyle}>
        {current} of {total}
      </div>
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
TotalCount.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  outsideFetch: PropTypes.bool,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
TotalCount.defaultProps = {
  outsideFetch: false,
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default TotalCount;
