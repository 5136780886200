import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
// import Switch from 'react-toggle-switch';
// import 'react-toggle-switch/dist/css/switch.min.css';
import ReactTooltip from 'react-tooltip';

import deleteActiveIcon from '../../../../assets/images/icn_delete_hover.png';
import deleteIcon from '../../../../assets/images/icn_delete.png';
import arrowdown from '../../../../assets/images/arrowdown.svg';
import arrowup from '../../../../assets/images/arrowup.svg';

import './CobTableRow.scss';

const CobTableRow = ({
  row,
  cols,
  rowClasses,
  hasChildren,
  onToggleSwitch,
  onDeleteCategory,
  showEditModal,
  onExpandRow,
  isDeleteAllowed,
  deleteDisabledTooltip,
}) => {
  const [isDeleteActive, setIsDeleteActive] = useState(false);

  const expandRow = () => onExpandRow(row);

  const renderExpandIcon = () => {
    const imageSource = row.hidden ? arrowdown : arrowup;
    return (
      hasChildren && (
        <img
          src={imageSource}
          alt="expand icon"
          onClick={expandRow}
          name="expand"
        />
      )
    );
  };

  const renderDeleteIcon = () => {
    const imageSource = isDeleteActive ? deleteActiveIcon : deleteIcon;
    if (isDeleteAllowed) {
      return (
        <img
          src={imageSource}
          alt="delete icon"
          onClick={() => onDeleteCategory(row)}
          name="delete"
          onMouseOver={() => setIsDeleteActive(true)}
          onMouseOut={() => setIsDeleteActive(false)}
          onFocus={() => setIsDeleteActive(true)}
          onBlur={() => setIsDeleteActive(false)}
        />
      );
    }

    return (
      <Fragment>
        {!isDeleteAllowed && deleteDisabledTooltip && (
          <ReactTooltip effect="solid" />
        )}
        <span className="delete-disabled" data-tip={deleteDisabledTooltip}>
          <img name="delete" alt="delete icon" src={deleteIcon} />
        </span>
      </Fragment>
    );
  };

  const actionDecider = {
    DELETE: renderDeleteIcon(),
    EXPAND: renderExpandIcon(),
    DEFAULT: () => {},
  };

  const decideAction = (values) => {
    return values.map((action, index) => {
      return (
        <span className="icon-container" key={index}>
          {actionDecider[action.toUpperCase()]
            ? actionDecider[action.toUpperCase()]
            : actionDecider.DEFAULT}
        </span>
      );
    });
  };

  const buildCell = () => {
    return cols.map((col, index) => {
      if (col.type === 'String') {
        let cellContent = row[col.value];
        if (col.value == 'name') {
          return (
            <td
              key={index}
              onClick={() => showEditModal(row)}
              className="clickable"
            >
              <span style={{ marginRight: '5px' }}>{row['icon']}</span>{' '}
              {cellContent}
            </td>
          );
        }
        if (col.value == 'cta_titles') {
          if (row['cta_title_long'] && row['cta_title_short']) {
            cellContent = `${row['cta_title_long']} / ${row['cta_title_short']}`;
          } else if (row['cta_title_long']) {
            cellContent = `${row['cta_title_long']} / no short CTA}`;
          } else if (row['cta_title_short']) {
            cellContent = `no long CTA / ${row['cta_title_short']}`;
          } else {
            cellContent = '';
          }
        }
        if (col.editable) {
          return (
            <td
              key={index}
              onClick={() => showEditModal(row)}
              className="clickable"
            >
              {cellContent}
            </td>
          );
        }
        return <td key={index}>{cellContent}</td>;
      }
      if (col.type === 'Boolean') {
        return <td key={index}></td>;
      }
      if (col.type === 'Options') {
        return <td key={index}>{decideAction(col.value)}</td>;
      }
      return null;
    });
  };

  return <tr className={rowClasses}>{buildCell()}</tr>;
};

CobTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  cols: PropTypes.array.isRequired,
  rowClasses: PropTypes.string.isRequired,
  hasChildren: PropTypes.bool,
  onToggleSwitch: PropTypes.func.isRequired,
  onDeleteCategory: PropTypes.func.isRequired,
  showEditModal: PropTypes.func,
  onExpandRow: PropTypes.func,
  isDeleteAllowed: PropTypes.bool,
  deleteDisabledTooltip: PropTypes.string,
};

CobTableRow.defaultProps = {
  hasChildren: false,
  onExpandRow: () => {},
  isDeleteAllowed: true,
  deleteDisabledTooltip: '',
  showEditModal: () => {},
};

export default CobTableRow;
