/**
 * ************************************
 *
 * @module  Auth.action.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Auth redux actions
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import ACTIONS from './actionTypes';

// ----------------------------------------------------------------------------|
//                                Exports
// ----------------------------------------------------------------------------|
export const loginAction = (email, password) => {
  const data = { email, password };
  return {
    type: ACTIONS.AUTH.LOGIN,
    data,
  };
};

export const logoutAction = () => ({
  type: ACTIONS.AUTH.LOGOUT,
});
