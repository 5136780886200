/**
 * ************************************
 *
 * @module  ValueContainer.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Container for multiselect dropdowns. Works in conjunction with
 * react-select
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import { components as Components } from 'react-select';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const ValueContainer = ({ getValue, children, ...props }) => {
  const { length } = getValue();

  return (
    <Components.ValueContainer
      className="selected-options-container"
      {...props}
    >
      {length !== 0 && (
        <span className="placeholder">{props.selectProps.placeholder}</span>
      )}
      {length !== 0 && (
        <span className="selected-options-count"> {length} </span>
      )}
      {children}
    </Components.ValueContainer>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
ValueContainer.propTypes = {
  children: PropTypes.any.isRequired,
  getValue: PropTypes.func.isRequired,
  selectProps: PropTypes.shape({
    placeholder: PropTypes.string.isRequired,
  }).isRequired,
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default ValueContainer;
