/**
 * ************************************
 *
 * @module  PreviewTab.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description component for preview card modal - Tab
 *
 * ************************************
 */
// ------------------------------------------------------------------------------------------|
//                                          Imports
// ------------------------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { GENERIC } from 'constants.js';

import './PreviewTab.scss';

// ------------------------------------------------------------------------------------------|
//                           React Function Component - PreviewTab
// ------------------------------------------------------------------------------------------|
const PreviewTab = (props) => {
  const { currentTab, setCurrentTab } = props;

  const {
    PREVIEW_MODAL: {
      TABS: { CARD, DETAIL },
    },
  } = GENERIC;

  const tabData = [
    {
      id: 1,
      label: 'Card View',
      classList: cx(['tab', { active: currentTab === CARD }]),
      returnValue: CARD,
    },
    {
      id: 2,
      label: 'Detail View',
      classList: cx(['tab', { active: currentTab === DETAIL }]),
      returnValue: DETAIL,
    },
  ];

  return (
    <div className="preview-tab">
      {tabData.map((tab) => {
        const { id, label, classList, returnValue, ...rest } = tab;
        return (
          <div
            key={id}
            className={classList}
            role="button"
            onClick={() => setCurrentTab(returnValue)}
            {...rest}
          >
            {label}
          </div>
        );
      })}
    </div>
  );
};

// ------------------------------------------------------------------------------------------|
//                               PropTypes Check - PreviewTab
// ------------------------------------------------------------------------------------------|
PreviewTab.propTypes = {
  currentTab: PropTypes.string.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
};

// ------------------------------------------------------------------------------------------|
//                                  Export - PreviewTab
// ------------------------------------------------------------------------------------------|
export default PreviewTab;
