/**
 * ************************************
 *
 * @module  Places.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Places container
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Place } from 'containers';

import {
  getPlacesAction,
  deletePlaceAction,
  clearPlacesAction,
  fetchAdminCategoriesAction,
  fetchNeighborhoodLocationsAction,
  performBulkOperationAction,
  selectPlaceAction,
  filterPlacesListAction,
} from 'store/actions/Places.action';

import { saveData } from 'store/actions/Place.action';

import { PLACE, GENERIC } from 'constants.js';

import {
  CardViewModal,
  DeleteModal,
  FilterSection,
  NoDataFound,
  Loader,
  Tabs,
  TotalCount,
  WidgetBox,
  CardBanner,
  ThirdPartySearchModal,
} from 'components';

import {
  getPartsFromUrl,
  getSelectedCardCount,
  getCardReviewStatus,
  checkHasMore,
  askForCloseConfirmation,
  getPriceRange as formPriceOptions,
  deepCopy,
} from 'utils/utils';

import {
  formRatingOptions,
  FilterType,
  formGroupOptions,
} from 'components/FilterSection/FiltersFactory';

import CommonService from 'services/CommonService';
import PlaceService from 'containers/~depreciated/Place/PlaceService';
import PlacesList from './PlacesList/PlacesList';

import './Places.scss';

// ----------------------------------------------------------------------------|
//                            Property Mapping
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  getPlacesList: (data) => dispatch(getPlacesAction(data)),
  deletePlace: (data) => dispatch(deletePlaceAction(data)),
  performBulkOperation: (data) => dispatch(performBulkOperationAction(data)),
  selectPlace: (data) => dispatch(selectPlaceAction(data)),
  clearPlacesList: () => dispatch(clearPlacesAction()),
  fetchAdminCategories: () => dispatch(fetchAdminCategoriesAction()),
  fetchNeighborhoodLocations: () =>
    dispatch(fetchNeighborhoodLocationsAction()),
  filterPlacesAction: (data) => dispatch(filterPlacesListAction(data)),
  saveData: (data, id, successCB) => dispatch(saveData(data, id, successCB)),
});

const mapStateToProps = (state) => ({
  places: state.places,
  adminCategories: state.places.adminCategories,
  placeCategories: state.places.placeCategories,
  categoriesLoaded: state.places.categoriesLoaded,
});

// ----------------------------------------------------------------------------|
//                              Utilities
// ----------------------------------------------------------------------------|
const { WARNING_ON_DELETE } = PLACE;

const commonService = CommonService();
const placeService = PlaceService();

// ----------------------------------------------------------------------------|
//                     React Class Component - Places
// ----------------------------------------------------------------------------|
class Places extends PureComponent {
  // --------------------------------------------------------------------------|
  //                          PropTypes Check
  // --------------------------------------------------------------------------|
  static propTypes = {
    status: PropTypes.string,
    hideExtras: PropTypes.bool,
    location: PropTypes.object.isRequired,
    getPlacesList: PropTypes.func.isRequired,
    performBulkOperation: PropTypes.func.isRequired,
    selectPlace: PropTypes.func.isRequired,
    dismissModal: PropTypes.func,
    deletePlace: PropTypes.func.isRequired,
    clearPlacesList: PropTypes.func.isRequired,
    fetchAdminCategories: PropTypes.func.isRequired,
    fetchNeighborhoodLocations: PropTypes.func.isRequired,
    places: PropTypes.object.isRequired,
    filterPlacesAction: PropTypes.func.isRequired,
    location: PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      action: PropTypes.string,
      block: PropTypes.func,
      createHref: PropTypes.func,
      go: PropTypes.func,
      goBack: PropTypes.func,
      goForward: PropTypes.func,
      length: PropTypes.number,
      listen: PropTypes.func,
      location: PropTypes.shape({
        hash: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
      }),
      push: PropTypes.func,
      replace: PropTypes.func,
    }).isRequired,
  };

  // --------------------------------------------------------------------------|
  //                                Default Props
  // --------------------------------------------------------------------------|
  static defaultProps = {
    status: '',
    hideExtras: false,
    dismissModal: () => {},
  };

  // --------------------------------------------------------------------------|
  //                              State - Places
  // --------------------------------------------------------------------------|
  constructor() {
    super();

    this.state = {
      currentTab: 'fetched',
      hasMore: true,
      newPlaceData: null,
      deleteModalData: null,
      bulkDeleteModal: false,
      category: [],
      rating: [],
      neighborhood: [],
      price: [],
      reviewStatus: '',
      query: '',
      selectedCards: [],
      isChecked: null,
      openPlaceID: '',
      showThirdPartyModal: false,
      thirdPartyEdit: null,
    };
  }

  // --------------------------------------------------------------------------|
  //                            LifeCycle Methods
  // --------------------------------------------------------------------------|
  componentDidMount() {
    this.setCurrentTab();

    const {
      places,
      fetchAdminCategories,
      fetchNeighborhoodLocations,
      history,
    } = this.props;

    // Fetch adminCategories, neighborhoodLocations only if they are not present
    if (!places.adminCategories.length) {
      fetchAdminCategories();
    }

    if (!places.neighborhoodLocations.length) {
      fetchNeighborhoodLocations();
    }

    this.unlisten = history.listen((location, action) => {
      this.handleIDPathname();
    });
  }

  componentDidUpdate(prevProps) {
    const { location, places } = this.props;

    if (location.pathname !== prevProps.location.pathname) {
      this.setCurrentTab();

      if (
        location.pathname.includes('fetched') ||
        prevProps.location.pathname.includes('fetched')
      ) {
        this.resetCategoriesFilter();
      }
    }

    if (
      places.bulkActionSuccess !== prevProps.places.bulkActionSuccess &&
      places.bulkActionSuccess
    ) {
      this.dismissWidget();
    }

    if (places.places.length !== prevProps.places.places.length) {
      this.setHasMore();
    }

    if (places.isInitialDataLoaded) {
      this.handleIDPathname();
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  Places methods
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  /**
   * @description Handle presence of DB id in URL: redirect user to relevant
   * content
   */
  handleIDPathname = () => {
    const { location, places: propsPlaces } = this.props;
    const { openPlaceID } = this.state;
    const { id } = commonService.pathnameHelper(location.pathname);

    if (id) {
      if (openPlaceID !== id) {
        if (openPlaceID) {
          this.setState({ newPlaceData: null, openPlaceID: '' });
        } else {
          this.setState({ openPlaceID: id });

          const { places } = propsPlaces;
          let placeData;

          // searches for already fetched cards
          for (let i = 0; i < places.length; i += 1) {
            if (places[i].id === id) {
              placeData = places[i];
              break;
            }
          }

          // if it finds it, load it
          if (placeData) {
            this.setState({ newPlaceData: placeData });
          } else {
            // if not, open a new modal and have it fetch the id
            this.setState({
              newPlaceData: {
                id,
                shouldFetchPlace: true,
                shouldDisplayLoader: true,
              },
            });
          }
        }
      }
    } else if (openPlaceID) {
      this.setNewPlaceData(null);
    }
  };

  /**
   * @description sets state on if more cards are available
   * checkHasMore is imported from the common utils/utils file
   */
  setHasMore = () => {
    const { places: propsPlaces } = this.props;
    const { places, totalCount } = propsPlaces;

    const hasMore = checkHasMore(places.length, totalCount);

    this.setState({ hasMore });
  };

  /**
   * @description constructs a request object which is sent to Events.saga
   * and ultimately requests a response based on state
   *
   * @returns {Object} - request object sent to Events.saga
   */
  constructRequestObject = () => {
    const { places, status: propsStatus } = this.props;
    const {
      currentTab,
      category,
      neighborhood,
      query,
      price,
      rating,
      reviewStatus,
    } = this.state;
    const { pageNum: page } = places;

    // Rating doubled for temporary issue with mapping of 1-5 => 1-10
    // Need to be changed later with proper cotract from BE.
    const ratings = rating.map(({ value: rating }) =>
      rating === '1' ? rating : rating * 2
    );

    const statusFromTab = currentTab === 'drafts' ? 'draft' : currentTab;
    // This prop is used to explicitly set the status of places to be fetched
    const status = propsStatus || statusFromTab;
    const slugList = category.map(({ value }) => value);

    if (currentTab !== 'queue') {
      const requestObject = {
        page,
        status,
        rating: ratings,
        nh_unique_slugs: neighborhood.map(({ value }) => value),
        prices: price.map(({ value }) => value),
        query,
        review_status: reviewStatus,
        ...(currentTab !== 'fetched'
          ? { cat_unique_slug_list: slugList }
          : { fs_cat_list: slugList }),
      };
      currentTab !== 'fetched'
        ? (requestObject.cat_unique_slug_list = category.map(
            ({ value }) => value
          ))
        : (requestObject.fs_cat_list = category.map(({ value }) => value));

      return requestObject;
    }

    const queueRequestObject = {
      page,
      status: currentTab,
      required_cat_unique_slug_list: [],
      nh_unique_slugs: neighborhood.map(({ value }) => value),
      nh_county_slug_list: [],
      query,
      cat_unique_slug_list: slugList,
      prices: price.map(({ value }) => value),
      cat_unique_slug_exclusions: [],
    };

    return queueRequestObject;
  };

  /**
   * @description constructs a request object and invokes getPlacesList
   * (passed from redux) which is sent to Places.saga and ultimately
   * requests a response based on state
   */
  getPlacesData = () => {
    const { getPlacesList } = this.props;
    const request = this.constructRequestObject();

    getPlacesList(request);
  };

  /**
   * @description sets the current horizontal tab by invoking the
   * getPartsFromUrl function imported from utils/utils based on
   * the second part of the current route.
   */
  setCurrentTab = () => {
    const { location, clearPlacesList } = this.props;

    // Active tab in horizontal nav is set based on the second
    // part of the current route
    const { secondPart: currentTab } = getPartsFromUrl(location.pathname);

    clearPlacesList();
    this.dismissWidget();

    this.setState(
      {
        currentTab,
        newPlaceData: null,
        openPlaceID: '',
      },
      this.getPlacesData
    );
  };

  /**
   * @description requests more data after setting states hasMore prop to false.
   */
  getMoreData = () => this.setState({ hasMore: false }, this.getPlacesData);

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  //                  Filter Methods
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  /**
   * @description Fires when filter options are selected
   *
   * @param {String} returnType - string for data type
   * @param {Array} data - of object  data to set to state
   * @param {Function} callback - to fire after state update
   */
  onFilterSelect = (returnType, data, callback = () => {}) => {
    const { clearPlacesList } = this.props;
    let selectedData = data;
    // prevents a bug with null being passed when deleting filters
    if (
      (returnType === 'category' ||
        returnType === 'neighborhood' ||
        returnType === 'price' ||
        returnType === 'rating') &&
      selectedData === null
    )
      selectedData = [];

    if (returnType === 'reviewStatus') {
      if (data.value) selectedData = data.value;
      else selectedData = '';
    }

    clearPlacesList();

    // API call to filter cards based on value selected from dropdown
    this.setState({ [returnType]: selectedData }, callback);
  };

  /**
   * @description Fires when filter menu closes.
   * constructs a request object then invokes the redux action
   */
  onFilterMenuClosed = () => {
    const { filterPlacesAction } = this.props;

    const request = this.constructRequestObject();

    filterPlacesAction(request);
  };

  /**
   * @description clears away filters
   */
  clearFilters = () => {
    const { clearPlacesList } = this.props;

    clearPlacesList();

    this.setState(
      {
        category: [],
        neighborhood: [],
        rating: [],
        price: [],
        reviewStatus: '',
      },
      this.getPlacesData
    );
  };

  resetCategoriesFilter = () => this.setState({ category: [], rating: [] });

  /**
   * @description sets state and fires a GET request for a search bar
   *
   * @param {String} query - string for the query search
   */
  onSearchInput = (query) => {
    const { clearPlacesList } = this.props;
    // API call to filter cards based on query
    clearPlacesList();
    this.dismissWidget();

    this.setState({ query }, this.getPlacesData);
  };

  /**
   * @description fires when the modal window for editing an event
   * is closed. Used for pushing the correct history to Routers
   * history object, dismissing the widget then setting the tab.
   */
  onCloseModal = () => {
    const { location, history } = this.props;

    const { section, tab, id } = commonService.pathnameHelper(
      location.pathname
    );

    if (id) {
      history.push(`/${section}/${tab}`);
    }

    this.dismissWidget();
    this.setCurrentTab();
  };

  /**
   * @description sets the state with a new place data object for editing,
   * Fires on clicking of a card or the add new button
   *
   * @param {Object} newPlaceData - Object of new place data to
   * be set to the state object
   */
  setNewPlaceData = (newPlaceData) => {
    const { location, history } = this.props;
    const { section, tab, id } = commonService.pathnameHelper(
      location.pathname
    );

    // prevents infinite loop with id/endpoint rendering
    if (id) {
      history.push(`/${section}/${tab}`);
    } else if (newPlaceData && newPlaceData.id) {
      // if from the 3rd party modal control flow
      const { status } = newPlaceData;

      history.push(`${status || tab}/${newPlaceData.id}`);
    }

    this.setState(
      { newPlaceData, openPlaceID: '', thirdPartyEdit: null },
      this.dismissWidget
    );
  };

  /**
   * @description fires when you click a card, type of action depends on the
   * string value passed.
   *
   * @param {String} value - String specifying the action (Edit/Delete/Etc..)
   * @param {Object} data - Object of activity data
   */
  onMenuClicked = (value, data) => {
    const { location, history } = this.props;

    const {
      SUB_MENU: { EDIT, DELETE },
    } = GENERIC;

    const { tab } = commonService.pathnameHelper(location.pathname);
    const { id } = data;

    if (value === EDIT) {
      history.push(`${tab}/${id}`);
    }

    if (value === DELETE) {
      this.setState({ deleteModalData: id });
    }
  };

  /**
   * @description resets deleteModalData property to null
   */
  closeDeleteModalData = () => this.setState({ deleteModalData: null });

  /**
   * @description sets bulkDeleteModal property to false
   */
  closeBulkDeleteModal = () => this.setState({ bulkDeleteModal: false });

  /**
   * @description triggers bulk delete from the List of cards
   */
  bulkDeleteFromList = () => {
    const {
      SUB_MENU: { DELETE },
    } = GENERIC;

    this.closeBulkDeleteModal();
    this.performBulkOperation(DELETE);
  };

  /**
   * @description fires when deleting an event from the list
   */
  deletePlaceFromList = () => {
    const { deletePlace } = this.props;
    const { deleteModalData } = this.state;

    this.closeDeleteModalData();
    deletePlace(deleteModalData);
  };

  /**
   * @description toggles what is included in the selectedCards state property
   *
   * @param {Boolean} currentCheckValue - toggles if card should be included
   * @param {String} cardId- string with unique cardId
   */
  onCheckBoxSelected = (currentCheckValue, cardId) => {
    this.setState((prevState) => {
      let selectedCards = [...prevState.selectedCards];

      if (currentCheckValue) {
        selectedCards.push(cardId);
      } else {
        selectedCards = selectedCards.filter((id) => id !== cardId);
      }

      const tempState = { selectedCards };

      if (typeof prevState.isChecked === 'boolean') {
        tempState.isChecked = null;
      }
      return tempState;
    });
  };

  /**
   * @description deselects all cards by setting state property isChecked
   * to false
   */
  deselectAllCards = () => {
    this.setState({ isChecked: false });
  };

  /**
   * @description resets widget to initial state
   */
  dismissWidget = () => {
    this.setState({ selectedCards: [], isChecked: null });
  };

  /**
   * @description redux helper for correct action
   *
   * @argument {Object} actions - with action types
   */
  getRelevantActions = (actions) => {
    const { status } = this.props;
    const { currentTab } = this.state;

    if (status === 'published') {
      return [actions.SELECT];
    }
    if (currentTab) {
      return currentTab === 'published'
        ? [actions.DELETE, actions.DRAFT]
        : [actions.DELETE, actions.PUBLISH];
    }

    return [];
  };

  /**
   * @description performs a bulk operation depending on type
   * string passed
   *
   * @argument {String} type - type of bulk operation
   */
  performBulkOperation = (type) => {
    const { dismissModal, performBulkOperation, selectPlace } = this.props;
    const { selectedCards } = this.state;

    const {
      BULK_ACTIONS: { DELETE, PUBLISH, DRAFT, SELECT },
    } = GENERIC;
    if (type === SELECT) {
      // This saves selected cards to redux
      dismissModal();
      selectPlace(selectedCards);
    } else {
      const data = {
        delete: type === DELETE,
        id_list: selectedCards,
      };
      if (type === PUBLISH) {
        data.status = 'published';
      }
      if (type === DRAFT) {
        data.status = 'draft';
      }
      performBulkOperation(data);
    }
  };

  /**
   * @description gets filter for places
   */
  getFiltersForPlaces = () => {
    const { places } = this.props;

    const {
      currentTab,
      category,
      rating,
      price,
      neighborhood,
      reviewStatus,
    } = this.state;

    const {
      FILTER_DROP_DOWN: { LOCATION, PRICE, RATING, TYPE, REVIEW_STATUS },
    } = PLACE;

    const {
      cardCategories,
      neighborhoodLocations,
      adminCategories,
      placeCategories,
    } = places;

    const baseFilters = [
      {
        name: LOCATION.LABEL,
        returnType: LOCATION.RETURN_TYPE,
        options: formGroupOptions(neighborhoodLocations, 'neighborhoods'),
        value: neighborhood,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.onFilterMenuClosed,
        isSearchable: true,
      },
      {
        name: PRICE.LABEL,
        returnType: PRICE.RETURN_TYPE,
        options: formPriceOptions(),
        value: price,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.onFilterMenuClosed,
      },
    ];

    const addtlFetchedFilters = [
      {
        name: RATING.LABEL,
        returnType: RATING.RETURN_TYPE,
        options: formRatingOptions(),
        value: rating,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.onFilterMenuClosed,
      },
      {
        name: TYPE.LABEL,
        returnType: TYPE.RETURN_TYPE,
        options: cardCategories.map((option) => ({
          value: option,
          label: option,
        })),
        value: category,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.onFilterMenuClosed,
      },
    ];

    const addtlQueueFilters = [
      {
        name: TYPE.LABEL,
        returnType: TYPE.RETURN_TYPE,
        options: cardCategories.map((option) => ({
          value: option,
          label: option,
        })),
        value: category,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.onFilterMenuClosed,
      },
    ];

    const addtlBaseFilters = [
      {
        name: TYPE.LABEL,
        returnType: TYPE.RETURN_TYPE,
        options: formGroupOptions(
          placeCategories,
          'categories',
          'unique_slug',
          'name'
        ),
        value: category,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.onFilterMenuClosed,
        isSearchable: true,
      },
      {
        name: REVIEW_STATUS.LABEL,
        returnType: REVIEW_STATUS.RETURN_TYPE,
        options: getCardReviewStatus(),
        value: reviewStatus,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.SELECT,
        onMenuClose: this.onFilterMenuClosed,
        isSearchable: false,
      },
    ];

    if (currentTab === 'fetched') {
      return [...baseFilters, ...addtlFetchedFilters];
    }

    if (currentTab === 'queue') {
      return [...baseFilters, ...addtlQueueFilters];
    }

    return [...baseFilters, ...addtlBaseFilters];
  };

  toggleThirdPartyModal = () => {
    const { showThirdPartyModal } = this.state;

    this.setState({
      showThirdPartyModal: !showThirdPartyModal,
      // clears the card data if the user has the modal open and closes it
      ...(showThirdPartyModal ? { thirdPartyEdit: null } : {}),
    });
  };

  editThirdParty = (data) => {
    this.setState(
      {
        thirdPartyEdit: data,
      },
      this.toggleThirdPartyModal
    );
  };

  submitThirdParty = (data = {}) => {
    const { saveData } = this.props;
    const dataCopy = deepCopy(data);

    dataCopy.should_refetch_3rd_parties_content = true;

    // if a fresh card (no queue or fetched)
    if (!data.id) {
      // place hours skeleton required for backend
      if (!dataCopy.place_hours) {
        dataCopy.place_hours = {
          monday: { slots: [], status: '' },
          tuesday: { slots: [], status: '' },
          wednesday: { slots: [], status: '' },
          thursday: { slots: [], status: '' },
          friday: { slots: [], status: '' },
          saturday: { slots: [], status: '' },
          sunday: { slots: [], status: '' },
        };
      }

      if (!dataCopy.status) {
        dataCopy.status = 'draft';
      }

      saveData(dataCopy, null, this.forwardToCardId);
    } else {
      dataCopy.misc_options = [];

      const formData = placeService.getPreparedFormData(
        dataCopy,
        [],
        !dataCopy.status ||
          dataCopy.status === 'fetched' ||
          dataCopy.status === 'queue'
          ? 'draft'
          : dataCopy.status
      );

      if (formData.categories && Array.isArray(formData.categories)) {
        formData.set_categories = formData.categories.map(
          (cat) => cat.unique_slug
        );

        delete formData.categories;
      }

      formData.set_neighborhoods = formData.set_neighborhoods.map(
        (n) => n.unique_slug
      );

      saveData(formData, dataCopy.id, this.forwardToCardId);
    }
  };

  forwardToCardId = (cardId = '', defaultTab) => {
    const { location, history } = this.props;
    const { tab } = commonService.pathnameHelper(location.pathname);

    if (cardId) {
      history.push(`${defaultTab || tab}/${cardId}`);
    }
  };

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  Places rendering
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  render() {
    const {
      newPlaceData,
      currentTab,
      deleteModalData,
      selectedCards,
      isChecked,
      hasMore,
      showThirdPartyModal,
      thirdPartyEdit,
    } = this.state;

    // True when places container has been rendered into a modal
    // - used to hide filters, submenu etc
    const { hideExtras, categoriesLoaded } = this.props;
    const { places, totalCount, isInitialDataLoaded } = this.props.places;

    const { CARD_VIEW_TITLE, TAB_DATA, CARD_VIEW_TITLE_EDIT } = PLACE;
    const {
      BULK_ACTIONS: { DELETE, PUBLISH, DRAFT, SELECT },
      CLOSE_CARD_WARNING_MESSAGE,
    } = GENERIC;
    const pageTitle =
      newPlaceData && newPlaceData.id ? CARD_VIEW_TITLE_EDIT : CARD_VIEW_TITLE;
    const fromFetched = currentTab === 'fetched' || currentTab === 'queue';
    if (fromFetched && newPlaceData) {
      newPlaceData.categories = [];
    }
    // Show filter section only in fetched places Tab
    // In CardSelector modal, show only search bar
    const filterSectionUI = (
      <FilterSection
        filters={this.getFiltersForPlaces()}
        searchValue={this.state.query}
        onSearchInput={this.onSearchInput}
        clearFilters={this.clearFilters}
      />
    );

    let placeListContent = <Loader />;

    // Collection of all possible actions in bulk actions widget
    const widgetActions = {
      DELETE: {
        label: DELETE,
        onClick: () => {
          this.setState({ bulkDeleteModal: true });
        },
        className: 'btn-inverse',
      },
      PUBLISH: {
        label: PUBLISH,
        onClick: () => {
          this.performBulkOperation(PUBLISH);
        },
      },
      DRAFT: {
        label: DRAFT,
        onClick: () => {
          this.performBulkOperation(DRAFT);
        },
      },
      SELECT: {
        label: SELECT,
        onClick: () => {
          this.performBulkOperation(SELECT);
        },
      },
    };

    const selectionWidget = selectedCards.length > 0 && (
      <WidgetBox
        className="places-widget"
        data={this.getRelevantActions(widgetActions)}
        onDeselectClicked={this.deselectAllCards}
        actionText={getSelectedCardCount(selectedCards)}
        showStatus
      />
    );

    const showOrHideNavigation = !hideExtras && (
      <Fragment>
        <CardBanner title="Places" newBtnClick={this.toggleThirdPartyModal} />
        <Tabs data={TAB_DATA} active={currentTab} />
      </Fragment>
    );
    if (isInitialDataLoaded) {
      if (totalCount) {
        const current = places.length;
        placeListContent = (
          <Fragment>
            <TotalCount current={current} total={totalCount} />
            <PlacesList
              data={places}
              hasMore={hasMore}
              onMoreData={this.getMoreData}
              onViewData={this.setNewPlaceData}
              onMenuClicked={(action, data) => {
                if (currentTab === 'fetched' || currentTab === 'queue') {
                  this.editThirdParty(data);
                } else {
                  this.onMenuClicked(action, data);
                }
              }}
              onCheckBoxSelected={this.onCheckBoxSelected}
              isChecked={isChecked}
              fromFetched={fromFetched}
              hideMenu={hideExtras}
              currentTab={currentTab}
            />
          </Fragment>
        );
      } else {
        placeListContent = <NoDataFound />;
      }
    }

    return (
      <div className="places-container">
        <div className="page-content">
          {newPlaceData !== null && categoriesLoaded ? (
            <CardViewModal
              title={pageTitle}
              modalClosed={() => askForCloseConfirmation(this.onCloseModal)}
            >
              {/* <Prompt when message={CLOSE_CARD_WARNING_MESSAGE} /> */}
              <Place
                data={newPlaceData}
                closeModal={() => this.onCloseModal()}
                currentTab={currentTab}
              />
            </CardViewModal>
          ) : (
            <Fragment>
              {showOrHideNavigation}
              {filterSectionUI}
              {placeListContent}
              {deleteModalData !== null && (
                <DeleteModal
                  cancelClick={this.closeDeleteModalData}
                  deleteClick={this.deletePlaceFromList}
                  additionalMessage={WARNING_ON_DELETE}
                />
              )}
              {showThirdPartyModal && (
                <ThirdPartySearchModal
                  verticalType="place"
                  showModal={showThirdPartyModal}
                  closeModal={this.toggleThirdPartyModal}
                  onSubmit={this.submitThirdParty}
                  cardData={thirdPartyEdit}
                  noDataCallback={this.setNewPlaceData}
                />
              )}
              {this.state.bulkDeleteModal && (
                <DeleteModal
                  cancelClick={this.closeBulkDeleteModal}
                  deleteClick={this.bulkDeleteFromList}
                />
              )}
            </Fragment>
          )}
        </div>
        {selectionWidget}
      </div>
    );
  }
}

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Places));
