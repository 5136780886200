/**
 * ************************************
 *
 * @module  Checkbox.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Renders a reusable checkbox leveraging React-Hooks.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import 'components/Checkbox/Checkbox.scss';

// ----------------------------------------------------------------------------|
//                    React Function Component - Checkbox
// ----------------------------------------------------------------------------|
/**
 * @description This is a functional component. Which is used to render a
 * checkbox.
 *
 * @param {Object} props
 *
 * @argument {Boolean} toBeChecked: true/false on if box should be checked
 * @argument {Function} onClick: function to fire onClick
 * @argument {String} label: label string for checkbox
 * @argument {String} children: optional text for checkbox
 * @argument {String} className: ClassName string for element
 * @argument {Boolean} fromRecurring: Bool used for firing useEffect() hook
 *
 * @returns {JSX} HTML components to be rendered to the DOM
 */

const Checkbox = ({
  toBeChecked,
  onClick,
  label,
  children,
  className,
  fromRecurring,
}) => {
  /**
   * @description - state boolean for check mark UI
   */
  const [checkState, setCheckState] = useState(toBeChecked);

  /**
   * @description - state updater hook to update check status
   */
  useEffect(() => {
    if (typeof toBeChecked === 'boolean' && !fromRecurring) {
      setCheckState(toBeChecked);
      onClick(toBeChecked, label);
    }
  }, [toBeChecked, label, onClick, fromRecurring]);

  /**
   * @description - dynamic classNames
   * Round is default classname, pass custom class names to overwrite the styles
   *
   */
  const CheckboxClass = cx(['round', `${className}`]);

  /**
   * @description - handles button click interaction
   *
   * @param {Object} e - DOM event object
   *
   * @returns {void}
   */
  const onClickHandler = (e) => {
    const { checked } = e.target;
    setCheckState(checked);
    onClick(checked, label, e);
  };

  return (
    <div className="cob-checkbox">
      <div className={CheckboxClass}>
        <input
          id={label}
          data-name="checkbox"
          type="checkbox"
          onChange={onClickHandler}
          checked={checkState}
        />
        <label htmlFor={label} data-name="checkbox">
          <span className="label-text">{children}</span>
        </label>
      </div>
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                          PropTypes Check - Checkbox
// ----------------------------------------------------------------------------|
Checkbox.propTypes = {
  onClick: PropTypes.func.isRequired,
  toBeChecked: PropTypes.any,
  label: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  fromRecurring: PropTypes.bool,
};

Checkbox.defaultProps = {
  toBeChecked: null,
  label: '',
  children: null,
  className: null,
  fromRecurring: false,
};

// ----------------------------------------------------------------------------|
//                              Checkbox Export
// ----------------------------------------------------------------------------|
export default Checkbox;
