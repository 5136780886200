import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { formatISODate, formatTimeString, sanitizeUrl } from 'utils/utils';
import { DATE_FORMATS, TIME_FORMATS } from 'constants.js';

const getListFromArray = (list) => {
  if (Array.isArray(list)) {
    return list.map((el, index) => {
      const value = typeof el === 'object' ? el.name : el;
      return <div key={index}>{value}</div>;
    });
  }
  return [];
};

const getCategoryItem = (category) => {
  if (Array.isArray(category) && category.length !== 0) {
    return typeof category[0] === 'object' ? category[0].name : category[0];
  }
  return ' ';
};

const PreviewCardModal = (props) => {
  const { data, className, fromEvent } = props;

  const {
    previewImage,
    title,
    category,
    neighborhood,
    price,
    shortDesc,
    address,
    eventAmount,
    eventAmountSuffix,
    startDate,
    fromTime,
  } = data;

  const containerClass = cx(['preview-modal', `${className}`]);
  const description = shortDesc;

  const categoryItem = getCategoryItem(category);
  const neighborhoodList = getListFromArray(neighborhood);

  const numberCheck = (amt) => {
    if (!isNaN(amt)) {
      return Number(amt).toFixed(2);
    }
    return amt;
  };

  return (
    <div className={containerClass}>
      <div className="image-carousel">
        <img src={sanitizeUrl(previewImage)} alt="preview" />
      </div>
      <div className="modal-detail">
        <h1 className="title">{title}</h1>
        <div className="details">
          {fromEvent ? (
            <Fragment>
              <div className="split-screen">
                <span className="category">{categoryItem}</span>
                <span>
                  {startDate && formatISODate(startDate, DATE_FORMATS.stringWithoutYear)},
                  {fromTime && formatTimeString(fromTime, TIME_FORMATS.time)}
                </span>
              </div>
              <div className="split-screen">
                <span className="neighborhood">{neighborhoodList}</span>
                <span className="amount">
                  ${numberCheck(eventAmount)}/{eventAmountSuffix}
                </span>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <h4 className="category">{categoryItem}</h4>
              <div className="pricing">
                <span className="neighborhood">{neighborhoodList}</span>
                <span className="separator">.</span>
                <span className="price">{price}</span>
              </div>
            </Fragment>
          )}
        </div>
        {fromEvent && <div className="address event">{address}</div>}
        <p className="desc">{description}</p>
      </div>
    </div>
  );
};

PreviewCardModal.propTypes = {
  data: PropTypes.shape({
    previewImage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.array.isRequired,
    neighborhood: PropTypes.array.isRequired,
    price: PropTypes.string.isRequired,
    shortDesc: PropTypes.string.isRequired,
    address: PropTypes.string,
    previewCardList: PropTypes.array,
    startDate: PropTypes.string,
    fromTime: PropTypes.string,
    eventAmount: PropTypes.number,
    eventAmountSuffix: PropTypes.string,
    phone: PropTypes.string,
  }),
  className: PropTypes.string,
  fromEvent: PropTypes.bool,
};

PreviewCardModal.defaultProps = {
  data: {
    address: '',
    previewCardList: [],
    startDate: '',
    fromTime: '',
    eventAmount: 0,
    eventAmountSuffix: '',
    phone: '',
  },
  className: '',
  fromEvent: false,
};

export default PreviewCardModal;
