/**
 * ************************************
 *
 * @module  FormActions.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Chooses and renders the button options on the bottom of
 * a cards form
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import cx from 'classnames';

import { Button, ButtonDropdown } from 'components';
import { VERTICAL_TYPE } from 'constants.js';
import { capitalize } from 'utils/utils';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const FormActions = ({
  verticalType,
  onAction,
  isNewCard,
  isBtnDisabled,
  currentTab,
  cardId,
}) => {
  /**
   * @description sets bulkDeleteModal property to false
   *
   * @returns {String}
   */
  const draftButtonLabel = () => {
    if (!cardId || currentTab === 'published' || currentTab === 'fetched') {
      return 'Move to Drafts';
    }

    if (currentTab === 'drafts') {
      return 'Save to Drafts';
    }

    return 'Move to Drafts';
  };

  /**
   * @description options for dropdown buttons
   */
  const dropdownButtonOptions = [];

  if (verticalType !== VERTICAL_TYPE.COLLECTION) {
    dropdownButtonOptions.push({ name: 'flag', text: 'Flag Card' });
  }

  dropdownButtonOptions.push({ name: 'draft', text: draftButtonLabel() });

  // don't include archive if they are already inactive
  if (currentTab !== 'inactive' && currentTab !== 'archived')
    dropdownButtonOptions.push({
      name: 'delete',
      text: `Archive ${capitalize(verticalType)}`,
    });

  if (currentTab === 'archived' || currentTab === 'inactive' || currentTab === 'expired')
    dropdownButtonOptions.push({
      name: 'reinstate',
      text: `Reinstate ${capitalize(verticalType)}`,
    });

  return (
    <div className="actions">
      <ButtonDropdown
        name="options"
        className={cx('btn-inverse btn', { disabled: isBtnDisabled })}
        disabled={false}
        onClick={onAction}
        options={dropdownButtonOptions}
      >
        OPTIONS
      </ButtonDropdown>
      <Button
        name="published"
        className={cx('btn', { disabled: isBtnDisabled })}
        disabled={false}
        onClick={onAction}
      >
        {currentTab === 'published' && cardId ? 'UPDATE' : 'PUBLISH'}
      </Button>
    </div>
  );
};

// --------------------------------------------------------------------------|
//                          PropTypes Check
// --------------------------------------------------------------------------|
FormActions.propTypes = {
  verticalType: PropTypes.string.isRequired,
  onAction: PropTypes.func.isRequired,
  isNewCard: PropTypes.bool,
  isBtnDisabled: PropTypes.bool,
  cardId: PropTypes.string.isRequired,
  currentTab: PropTypes.string.isRequired,
};

// --------------------------------------------------------------------------|
//                                Default Props
// --------------------------------------------------------------------------|
FormActions.defaultProps = { isNewCard: false, isBtnDisabled: false };

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default withRouter(FormActions);
