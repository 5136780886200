/**
 * ************************************
 *
 * @module  ActivityService.js
 * @author  Matt P
 * @date    02/01/2021
 * @description File to prepare Activity form
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import { isValidURL } from 'utils/utils';

import { trimItemizedDescription } from 'utils/FormFunc';

import CommonService from 'services/CommonService';

const commonService = CommonService();

// ----------------------------------------------------------------------------|
//                             Activity Service
// ----------------------------------------------------------------------------|
const ActivityService = {
  /**
   * @description preps the form data to be passed as in the request body
   *
   * @param {Object} data - formData
   * @param {Array} uploadedImages - of image data
   * @param {String} activityStatus - 'draft' / 'published / 'expired'
   *
   * @returns {Object} for requestBody
   */
  getPreparedFormData(data, uploadedImages, activityStatus) {
    const {
      name,
      categories,
      main_categories,
      price_tier,
      phrase,
      description,
      links_to_activity,
      admin_review,
      itemized_description,
    } = data;

    return {
      name,
      categories,
      main_categories,
      price_tier:
        price_tier !== undefined && price_tier !== null
          ? price_tier
          : undefined,
      phrase: phrase ? phrase.trim() : '',
      description: description ? description.trim() : '',
      links_to_activity:
        typeof links_to_activity === 'string'
          ? [links_to_activity]
          : links_to_activity,
      images: uploadedImages,
      status: activityStatus,
      admin_review,
      itemized_description: trimItemizedDescription(itemized_description),
    };
  },

  /**
   * @description error checking for 'publish' form submission
   *
   * @param {Object} data - formData
   *
   * @returns {Object} of errors
   */
  validate(data) {
    const errorsObj = {
      ...this.validateActivityName(data.name),
      ...commonService.validateItemizedDescription(data.itemized_description),
    };

    // checks categories
    if (
      !commonService.validatePrimaryCategories(
        data.main_categories,
        data.categories
      )
    ) {
      errorsObj.categories =
        'Primary categories must be included in the selected categories';
    }

    if (
      data.categories &&
      Array.isArray(data.categories) &&
      data.categories.length < 1
    ) {
      errorsObj.categories = 'At least one category is required';
    }

    if (
      (typeof data.links_to_activity === 'string' &&
        !isValidURL(data.links_to_activity)) ||
      (Array.isArray(data.links_to_activity) &&
        data.links_to_activity.length &&
        !isValidURL(data.links_to_activity[0]))
    ) {
      errorsObj.links_to_activity = 'Please enter a valid URL';
    }

    if (data.price_tier === undefined) {
      errorsObj.price_tier = 'Price range is required';
    }

    // checks phrase length
    if (data.phrase.length < 10 || data.phrase.length > 250) {
      errorsObj.phrase =
        'One-liner description should be min 10 and max 250 characters';
    }

    return errorsObj;
  },

  validateActivityName(titleString) {
    if (titleString.length < 1 || titleString > 100) {
      return { name: 'Recreation name should be min 1 and max 100 characters' };
    }

    return {};
  },

  defaultDescriptions: [
    {
      header: 'What it is',
      body: '',
    },
    {
      header: 'Why it\'s unique',
      body: '',
    },
  ],
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default ActivityService;
