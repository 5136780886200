/**
 * ************************************
 *
 * @module  RecurringEvent.js
 * @author  Matt P
 * @date    11/10/2020
 * @description Recurring event component for days of the week selection
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import './RecurringEvent.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const RecurringEvent = ({
  events,
  selectedEvents,
  error,
  onChange,
  className,
}) => {
  /**
   * @description Checks if the event is selected
   *
   * @param {Array} items - Array of events already selected
   * @param {Object} event - Event Obj of selected item
   */
  const onEventSelection = (items, event) => {
    const newEvents = [...items];
    const index = newEvents.indexOf(event.name);

    if (index !== -1) {
      newEvents.splice(index, 1);
    } else {
      newEvents.push(event.name);
    }

    const data = {
      events: newEvents,
    };

    onChange(data);
  };

  /**
   * @description Checks if the event is selected
   *
   * @param {Object} event - Object containing event data
   * @param {Boolean} selected - True/False on if has been selected
   * @param {Number} index - index for <li> item key
   *
   * @return {JSX} - Returns JSX <li> elements to be rendered
   */
  const renderEvent = (event, selected, index) => (
    <li
      className={selected ? 'active' : null}
      key={index}
      onClick={() => onEventSelection(selectedEvents, event)}
    >
      <span>{event.name.charAt(0).toUpperCase()}</span>
    </li>
  );

  /**
   * @description Checks if the event is selected
   *
   * @param {Object} event - Object containing event data
   *
   * @return {Boolean} - Checks if eventsSelectedState contains the event name
   * and returns a bool
   */
  const isActive = (event) => selectedEvents.includes(event.name);

  /**
   * @description clears current selected events and returns
   * data to onClick
   *
   * @param {Object} event - Object containing event data
   *
   * @return {Boolean} - Checks if eventsSelectedState contains the event name
   * and returns a bool
   */

  return (
    <div className={className}>
      <ul className="rec-event-container">
        {events.map((event, index) =>
          renderEvent(event, isActive(event), index)
        )}
      </ul>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
RecurringEvent.propTypes = {
  className: PropTypes.string,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedEvents: PropTypes.array.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
RecurringEvent.defaultProps = {
  className: '',
  error: '',
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default RecurringEvent;
