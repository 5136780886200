/**
 * ************************************
 *
 * @module  Login.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description CMS Login component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import LoginForm from 'containers/Login/LoginForm/LoginForm';
import { loginAction } from 'store/actions/Auth.action.js';

import { LOGIN as loginConstants, DEFAULT_AUTH_ROUTE } from 'constants.js';

import Logo from 'assets/images/logo.png';

import './Login.scss';

// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  onLogin: (email, password) => dispatch(loginAction(email, password)),
});

// ----------------------------------------------------------------------------|
//                        React Class Component - Login
// ----------------------------------------------------------------------------|
class Login extends Component {
  // --------------------------------------------------------------------------|
  //                            PropTypes Check
  // --------------------------------------------------------------------------|
  static propTypes = {
    auth: PropTypes.object.isRequired,
    onLogin: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  };

  // --------------------------------------------------------------------------|
  //                              State
  // --------------------------------------------------------------------------|
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      isSubmitDisabled: true,
    };
  }

  // --------------------------------------------------------------------------|
  //                            LifeCycle Methods
  // --------------------------------------------------------------------------|
  /**
   * @description - This lifecycle method is triggered when component is
   * initially loaded.
   *
   * @argument
   * 1. If user is authenticated then redirect to dashboard.
   */
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.redirectAuthenticatedRoute();
    }
  }

  /**
   * @description - This lifecycle method is triggered when there is any
   * update in state or props of this component.
   *
   * @param {object} prevProps This contains previous props value of this
   * component.
   *
   * @argument
   * 1. Check if isAuthentication prop in auth object(Auth Reducer) has
   * changed, if so check if value is true, then redirect to dashboard.
   */
  componentDidUpdate(prevProps) {
    if (
      this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated &&
      this.props.auth.isAuthenticated
    ) {
      this.redirectAuthenticatedRoute();
    }
  }

  // --------------------------------------------------------------------------|
  //                            Component Methods
  // --------------------------------------------------------------------------|
  /**
   * @description - This method is used redirect to Dashboard
   */
  redirectAuthenticatedRoute = () => {
    this.props.history.push(DEFAULT_AUTH_ROUTE);
  };

  /**
   * @description - This method gets triggered on change in input field.
   * This component will extract name and value from element and then set
   * state accordingly, then trigger checkSubmitDisable method.
   *
   * @param {object} event it event object which is emitted by an element
   * when there are changes.
   */
  inputChange = (event) => {
    const { name } = event.target;
    let { value } = event.target;

    if (name === 'email') {
      value = value.toLowerCase();
    }

    this.setState({ [name]: value }, () => this.checkSubmitDisabled());
  };

  /**
   * @description - This method is used to check if email and password
   * field is empty or not, if it is empty then it will disable submit button
   */
  checkSubmitDisabled = () => {
    const { email, password } = this.state;
    let isDisabled = true;
    if (email.trim() !== '' && password.trim() !== '') {
      isDisabled = false;
    }
    this.setState({ isSubmitDisabled: isDisabled });
  };

  /**
   * @description - This method handles login submission.
   */
  loginSubmit = () => {
    const { email, password } = this.state;
    this.props.onLogin(email, password);
  };

  render() {
    const { showWarning } = this.props.auth;
    const { email, password, isSubmitDisabled } = this.state;

    const {
      INFO: { TAG_LINE },
      CARD,
    } = loginConstants;

    const valueState = { email, password };

    return (
      <div className="login">
        <div className="login-content">
          <div className="login-details">
            <img src={Logo} alt="Logo" className="logo" />
            <p className="tag-line">{TAG_LINE}</p>
          </div>
          <LoginForm
            cardDetails={CARD}
            valueState={valueState}
            showWarning={showWarning}
            onChange={this.inputChange}
            onSubmit={this.loginSubmit}
            isSubmitDisabled={isSubmitDisabled}
          />
        </div>
      </div>
    );
  }
}

// ----------------------------------------------------------------------------|
//                        Export with Redux Connect
// ----------------------------------------------------------------------------|
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
