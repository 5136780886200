/**
 * ************************************
 *
 * @module  Loader.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Loader spinner component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';

import './Loader.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const Loader = () => <div className="loader" />;

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default Loader;
