/**
 * ************************************
 *
 * @module  CobReactTagSelect.js
 * @author  Matt P
 * @date    05/25/2021
 * @description Tag select form component leveraging react-select
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import cx from 'classnames';

import './CobReactTagSelect.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const CobReactTagSelect = (props) => {
  const {
    className,
    overrideComponents,
    options,
    placeholder,
    isClearable,
    name,
    value,
    styles,
    onChange,
    closeMenuOnSelect,
    hideSelectedOptions,
    isSearchable,
    disabled,
  } = props;

  const css = cx(['cob-react-tagselect drop-down', className]);

  return (
    <Select
      isMulti
      className={css}
      components={overrideComponents}
      options={options}
      placeholder={placeholder}
      isClearable={isClearable}
      name={name}
      value={value}
      styles={styles}
      onChange={onChange}
      closeMenuOnSelect={closeMenuOnSelect}
      hideSelectedOptions={hideSelectedOptions}
      isSearchable={isSearchable}
      isDisabled={disabled}
    />
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
CobReactTagSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.shape({}),
    })
  ),
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.shape({}).isRequired,
    })
  ),
  styles: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  closeMenuOnSelect: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  isSearchable: PropTypes.bool,
  filterByParent: PropTypes.bool,
  disabled: PropTypes.bool,
};

CobReactTagSelect.defaultProps = {
  className: '',
  options: [],
  value: [],
  styles: {},
  placeholder: '',
  isClearable: true,
  isSearchable: true,
  closeMenuOnSelect: false,
  hideSelectedOptions: true,
  overrideComponents: '',
  disabled: false,
};

// ----------------------------------------------------------------------------|
//                        CobReactMultiSelect Export
// ----------------------------------------------------------------------------|
export default CobReactTagSelect;
