/**
 * ************************************
 *
 * @module  ReviewStatus.js
 * @author  Matt P
 * @date   05/20/2021
 * @description Review status form field component
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CobCheckbox } from 'components';

import moment from 'moment';

import { deepCopy, momentObjectToStringObject } from 'utils/utils';

import dropdownArrow from 'assets/images/dropdown.svg';

import './ReviewStatus.scss';

// ----------------------------------------------------------------------------|
//                              Utilities
// ----------------------------------------------------------------------------|
/**
 * @description - Formats a moment/Date object to a string of our choice
 *
 * @param {Date} dateTime - default param is today
 *
 * @returns {String}
 */
const formatTimestampValue = (dateTime = moment()) => {
  const timeStamped = momentObjectToStringObject(dateTime);
  const { month, day, year } = timeStamped;

  return `${month}/${day}/${year}`;
};

/**
 * @description - Checks the checkbox column with the appropriate
 * values using an array of booleans
 *
 * @param {String} selectedOptions - review status from backend
 * @param {String} reviewDate - date string
 *
 * @returns {Boolean[]}
 */
const initialOptionsSwitchArray = (selectedOptions, reviewDate) => {
  if (selectedOptions === 'reviewed') return [true, true, true];

  const optionsSwitch = [false, false, false];

  if (selectedOptions === 'pending_review' || !selectedOptions)
    return optionsSwitch;

  if (
    !selectedOptions.includes('data') &&
    reviewDate >= '2021-08-08T19:18:52.552000'
  ) {
    // some janky shit to make sure the 'data' box isn't checked
    // before initial deploy as this was the third addition
    optionsSwitch[0] = true;
  }
  if (!selectedOptions.includes('content')) optionsSwitch[1] = true;
  if (!selectedOptions.includes('images')) optionsSwitch[2] = true;

  return optionsSwitch;
};

/**
 * @description - function that determined the review status string for the
 * backend based on the selections.
 *
 * @param {Boolean[]} switchArray - of review status
 *
 * @returns {String}
 */
const determineStatusReviewString = (switchArray = []) => {
  const [data, content, images] = switchArray;

  if (!data && !content && !images) return 'pending_review';
  if (data && content && images) return 'reviewed';
  if (data && content && !images) return 'pending_images_review';
  if (data && !content && images) return 'pending_content_review';
  if (!data && content && images) return 'pending_data_review';
  if (!data && !content && images) return 'pending_content_data_review';
  if (data && !content && !images) return 'pending_content_images_review';
  if (!data && content && !images) return 'pending_data_images_review';

  return 'pending_review';
};

// ----------------------------------------------------------------------------|
//                   React Function Component - ReviewStatus
// ----------------------------------------------------------------------------|
const ReviewStatus = ({ history, value, reviewDate, errors, onChange }) => {
  /**
   * @description - state for the 3 review status option check boxes
   * constructs an array to keep track of which checkboxes are checked
   */
  const [optionsSwitch, setOptionsSwitch] = useState(
    initialOptionsSwitchArray(value || 'pending_review', reviewDate)
  );

  /**
   * @description - state for the current/latest review date stamp
   */
  const [reviewedDateStamp, setReviewedDateStamp] = useState(
    reviewDate ? formatTimestampValue(moment(reviewDate)) : ''
  );

  /**
   * @description - toggles the dropdown to show the review history
   */
  const [historyShow, setHistoryShow] = useState(false);

  /**
   * @description - renders the history dropdown
   *
   * @param {Object[]} historyArray - DOM event object
   *
   * @returns {JSX}
   */
  const renderStatusHistory = (historyArray) => {
    const listArray = [];

    if (!historyArray.length) {
      listArray.push(
        <li key="empty-history">
          <b>No History</b>
        </li>
      );
    } else {
      for (let i = historyArray.length - 1; i >= 0; i -= 1) {
        const { timestamp, html_formatted_description } = historyArray[i];

        listArray.push(
          <li
            key={`history-list${timestamp}`}
            // a no-no but should be OK as this HTML is received from the server
            // and not set by the user
            dangerouslySetInnerHTML={{ __html: html_formatted_description }}
          />
        );
      }
    }

    return <ul>{listArray}</ul>;
  };

  /**
   * @description - handles the checkbox state toggling and updates the
   * form as well
   *
   * @param {Boolean} isChecked
   * @param {String} label - of the checkbox clicked
   *
   * @returns {void}
   */
  const eventHandler = (isChecked, label) => {
    const optionsSwitchCopy = deepCopy(optionsSwitch);

    setReviewedDateStamp(formatTimestampValue());

    switch (label) {
      case 'data':
        optionsSwitchCopy[0] = isChecked;
        break;
      case 'content':
        optionsSwitchCopy[1] = isChecked;
        break;
      case 'images':
        optionsSwitchCopy[2] = isChecked;
        break;
      default:
    }

    setOptionsSwitch(optionsSwitchCopy);

    onChange(
      {
        review_status: determineStatusReviewString(optionsSwitchCopy),
        comments: '',
      },
      'admin_review'
    );
  };

  return (
    <div className="review-status__container">
      <h2 className="review-status__header">Review</h2>
      <div className="review-status__input-container">
        <div className="review-status__status-checkboxes">
          <label>Review Status</label>
          <CobCheckbox
            label="data"
            displayText="Data Done"
            onClick={eventHandler}
            toBeChecked={optionsSwitch[0]}
          />
          <CobCheckbox
            label="content"
            displayText="Content Done"
            onClick={eventHandler}
            toBeChecked={optionsSwitch[1]}
          />
          <CobCheckbox
            label="images"
            displayText="Images Done"
            onClick={eventHandler}
            toBeChecked={optionsSwitch[2]}
          />
        </div>
        <div className="review-status__last-reviewed">
          <label>Last Reviewed</label>
          <input
            type="text"
            className="review-status__last-reviewed--display"
            name="last-reviewed"
            value={reviewedDateStamp}
            disabled
          />
        </div>
      </div>
      <div className="error">{errors}</div>
      <div className="review-status__status-history">
        <button
          type="button"
          onClick={() => {
            setHistoryShow(!historyShow);
          }}
        >
          {'Status History '}
          <img
            style={{ transform: historyShow ? '' : 'rotate(0deg)' }}
            // className={historyButtonClass}
            src={dropdownArrow}
            alt="btn-dropdown-arrow-icon"
          />
        </button>
        {historyShow && (
          <div className="review-status__status-history-list">
            {renderStatusHistory(history || [])}
          </div>
        )}
      </div>
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                               PropTypes Check
// ----------------------------------------------------------------------------|
ReviewStatus.propTypes = {
  reviewDate: PropTypes.string,
  history: PropTypes.arrayOf(
    PropTypes.shape({
      html_formatted_description: PropTypes.string.isRequired,
      timestamp: PropTypes.string.isRequired,
    })
  ).isRequired,
  errors: PropTypes.string,
  value: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                               Default Props
// ----------------------------------------------------------------------------|
ReviewStatus.defaultProps = {
  errors: '',
  reviewDate: null,
  value: null,
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default ReviewStatus;
