/**
 * ************************************
 *
 * @module  ErrorHandlingUtils.js
 * @author  Matt P
 * @date    06/15/2021
 * @description Collection of error handling utils. Are conditionally
 * rendered by environment (aka branch --  develop / staging / master)
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import Rollbar from 'rollbar';

// ----------------------------------------------------------------------------|
//                                 Utilities
// ----------------------------------------------------------------------------|
const reportAPIError = (vertical, errorMessage) => {
  // Production Only - will be commented in non production env
  const rollbar = new Rollbar({
    accessToken: '6fd2db79b5554b61bee90488dd8ddbca',
    handleUncaughtExceptions: true,
    handleUnhandledRejections: true,
    payload: {
      environment: 'production',
    },
  });

  rollbar.error(vertical, errorMessage);
};

export { reportAPIError };
