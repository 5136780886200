/**
 * ************************************
 *
 * @module  HourPicker.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description leverages rc-time-picker to create an hours
 * dropdown selector
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import TimePicker from 'rc-time-picker';

import timeIcon from '../../assets/images/time-icn.svg';

import 'rc-time-picker/assets/index.css';
import './HourPicker.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const HourPicker = ({
  name,
  defaultValue = '',
  onChange,
  placeholder,
  className,
}) => {
  const splitTime = defaultValue && defaultValue.split(':');
  const date = new Date();
  const pickerKey = `${className.replace(/ /g, '_')}_picker`;

  if (splitTime) {
    date.setHours(parseInt(splitTime[0], 10));
    date.setMinutes(parseInt(splitTime[1], 10));
    date.setSeconds(parseInt(splitTime[2], 10));
  }

  return (
    <span className="time-picker--wrapper">
      <TimePicker
        id={pickerKey}
        key={pickerKey}
        popupStyle={{ width: '200px' }}
        showSecond={false}
        inputReadOnly
        use12Hours
        minuteStep={15}
        allowEmpty={false}
        value={defaultValue ? moment(date) : null}
        placeholder={placeholder}
        className={`timePicker ${className}`.trimRight()}
        onChange={(value) => onChange(name, value, className)}
      />
      <img src={timeIcon} alt="Select time" className="time-picker-icon" />
    </span>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
HourPicker.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
HourPicker.defaultProps = {
  defaultValue: null,
  placeholder: '',
  className: '',
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default HourPicker;
