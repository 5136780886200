/**
 * ************************************
 *
 * @module  Event.reducer.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Categories reducer file
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|
// identifier can be the source url of the image in case of autofilled images
// For images that were not autofilled, it is the file id
// If an image's source url or its ID matches the identifier param, delete
// it from the array
const getFilteredImages = (identifier, images = []) =>
  images.filter(
    (image) => image.url !== identifier && image.fileId !== identifier
  );

// ----------------------------------------------------------------------------|
//                               Initial State
// ----------------------------------------------------------------------------|
const initialState = {
  // Contains images that are to be autofilled into the image upload component
  autofilledImages: [],
  images: [],
  formData: {},
  // Contains list of fileIds for whom upload failed
  failedUploads: [],
  isDropZonePreviewRequired: false,
  isBtnDisabled: false,
};

// ----------------------------------------------------------------------------|
//                            Event Reducer
// ----------------------------------------------------------------------------|
const Event = (state = initialState, action = null) => {
  const { type, data } = action;

  switch (type) {
    case ACTIONS.PLACE.FETCH_PLACE_SUCCESS: {
      return {
        ...state,
        fetchedPlaceData: data,
      };
    }

    case ACTIONS.EVENT.POPULATE_IMAGES: {
      return {
        ...state,
        autofilledImages: data || [],
      };
    }

    case ACTIONS.EVENT.SAVE_DATA_TO_REDUX: {
      return {
        ...state,
        formData: { ...state.formData, ...data },
      };
    }

    // Clear any errors for image being uploaded
    case ACTIONS.EVENT.UPLOAD_IMAGE: {
      return {
        ...state,
        isDropZonePreviewRequired: true,
        failedUploads: state.failedUploads.filter(
          (fileId) => fileId !== data.fileId
        ),
      };
    }

    case ACTIONS.EVENT.UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        autofilledImages: [
          ...state.autofilledImages,
          { ...data, fileId: data.fileId, photo_metadata: data.photo_metadata },
        ],
        isDropZonePreviewRequired: false,
      };
    }

    case ACTIONS.EVENT.UPLOAD_IMAGE_FAIL: {
      return { ...state, failedUploads: [...state.failedUploads, data.fileId] };
    }

    case ACTIONS.EVENT.DELETE_IMAGE: {
      return {
        ...state,
        autofilledImages: getFilteredImages(data, state.autofilledImages),
      };
    }

    case ACTIONS.EVENT.BUTTON_DISBALING: {
      return {
        ...state,
        isBtnDisabled: data.isBtnDisabled,
      };
    }

    case ACTIONS.EVENT.CLEAR_IMAGES: {
      return { ...state, images: [] };
    }

    case ACTIONS.EVENT.RE_ORDER_IMAGES: {
      return { ...state, autofilledImages: data };
    }

    default: {
      return state;
    }
  }
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default Event;
