/**
 * ************************************
 *
 * @module  Events.saga.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Events saga file
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import API from 'API';

import ACTIONS from 'store/actions/actionTypes';
import {
  clearListAction,
  getEventsListAction,
} from 'store/actions/~depreciated/Events.actions';

import { GENERIC } from 'constants.js';
import { constructQueryParams } from 'utils/utils';

// ----------------------------------------------------------------------------|
//                            Sagas - Events
// ----------------------------------------------------------------------------|
/**
 * @description handles getting events list data
 *
 * @param {Object} action - object passed from the redux action
 */
function* getEventsList(action) {
  const { searchData } = window.axios;

  try {
    const URL = `${API.place}?${constructQueryParams(action.data)}`;
    const response = yield call(searchData, 'get', URL);

    if (response.status === 200) {
      yield put({ type: ACTIONS.EVENTS.GET_LIST_SUCCESS, data: response.data });
    } else {
      yield put({ type: ACTIONS.EVENTS.GET_LIST_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.EVENTS.GET_LIST_FAIL });
  }
}

/**
 * @description handles deleting an event from the DB
 *
 * @param {Object} action - object passed from the redux action
 */
function* deleteEvent(action) {
  const { deleteData } = window.axios;
  const { DELETE_SUCCESS_MESSAGE, DELETE_FAILURE_MESSAGE } = GENERIC;

  try {
    const URL = `${API.place}${action.data}?is_event=true`;
    const response = yield call(deleteData, URL);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.EVENTS.DELETE_EVENT_SUCCESS,
        data: action.data,
      });
      toast.success(DELETE_SUCCESS_MESSAGE);
    } else {
      yield put({ type: ACTIONS.EVENTS.DELETE_EVENT_FAIL });
      toast.error(DELETE_FAILURE_MESSAGE);
    }
  } catch (error) {
    yield put({ type: ACTIONS.EVENTS.DELETE_EVENT_FAIL });
  }
}

/**
 * @description handles bulk operation on events
 *
 * @param {Object} action - object passed from the redux action
 */
function* performBulkOperationOnEvents(action) {
  const { patchData } = window.axios;
  const { data } = action;

  try {
    const response = yield call(patchData, API.place, data);

    if (response.status === 200) {
      yield put({ type: ACTIONS.EVENTS.BULK_ACTION_SUCCESS, data });
    } else {
      yield put({ type: ACTIONS.EVENTS.BULK_ACTION_FAIL, data });
    }
  } catch (error) {
    yield put({ type: ACTIONS.EVENTS.BULK_ACTION_FAIL, data });
  }
}

/**
 * @description filters events list
 *
 * @param {Object} action - object passed from the redux action
 */
function* filterEventsList(action) {
  yield put(clearListAction());

  const pageNum = yield select((state) => state.events.pageNum);
  const data = { ...action.data, page: pageNum };

  yield put(getEventsListAction(data));
}

// ----------------------------------------------------------------------------|
//                          Events Saga Export
// ----------------------------------------------------------------------------|
export {
  getEventsList,
  deleteEvent,
  performBulkOperationOnEvents,
  filterEventsList,
};
