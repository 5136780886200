/**
 * ************************************
 *
 * @module  CardInput.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Component to add an additional card to Curated Cards.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                            Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import addIcon from 'assets/images/add.svg';

import 'components/CardSelector/CardInput/CardInput.scss';

// ----------------------------------------------------------------------------|
//                  React Function Component - Button
// ----------------------------------------------------------------------------|
/**
 * @description This is a functional component used to add additional cards by
 * toggling the CardViewModal - Select Curated Cards
 *
 * @param {Object} props
 *
 * @returns {JSX} HTML components to be rendered to the DOM
 */
const CardInput = (props) => (
  <div className="card-input" role="button" onClick={props.clickHandler}>
    <img className="add-icon" src={addIcon} alt="Add more" />
  </div>
);

// ----------------------------------------------------------------------------|
//                        PropTypes Check - CardInput
// ----------------------------------------------------------------------------|
CardInput.propTypes = {
  clickHandler: PropTypes.func.isRequired,
};

// ----------------------------------------------------------------------------|
//                              CardInput Export
// ----------------------------------------------------------------------------|
export default CardInput;
