/**
 * ************************************
 *
 * @module  WidgetBox.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description reusable popup for buttons for various parts of the app.
 * Renders at the bottom of a modal or page
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button } from 'components';

import DeSelectIcon from 'assets/images/icn-deselect.png';
import LoadingIcon from 'assets/images/loading-grey.gif';

import './WidgetBox.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const WidgetBox = ({
  data,
  showStatus,
  onDeselectClicked,
  actionText,
  className,
  showLoadingIcon,
}) => {
  const widgetClass = cx([
    'widget-box',
    { 'action-only': !showStatus },
    `${className}`,
  ]);

  return (
    <div className={widgetClass}>
      {showStatus && (
        <div className="clickable-action">
          <img
            src={DeSelectIcon}
            alt="deselect selected"
            onClick={onDeselectClicked}
          />
          <span className="action-label">{actionText}</span>
        </div>
      )}
      <div className="action-wrapper">
        {showLoadingIcon && (
          <img
            className="widget-loading-icon"
            src={LoadingIcon}
            alt="loading"
          />
        )}
        {data.map((btn, index) => {
          const { label, onClick, className: btnClassName = '', ...rest } = btn;
          const btnClass = cx(['widget-box-btn', `${btnClassName}`]);

          return (
            <Button
              className={btnClass}
              onClick={onClick}
              {...rest}
              key={index}
            >
              {label}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
WidgetBox.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      className: PropTypes.string,
    }).isRequired
  ).isRequired,
  showStatus: PropTypes.bool,
  onDeselectClicked: PropTypes.func,
  actionText: PropTypes.string,
  className: PropTypes.string,
  showLoadingIcon: PropTypes.bool,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
WidgetBox.defaultProps = {
  showStatus: false,
  onDeselectClicked: () => {},
  actionText: 'cards selected',
  className: '',
  showLoadingIcon: false,
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default WidgetBox;
