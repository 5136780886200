/**
 * ************************************
 *
 * @module  CategoriesUtils.js
 * @author  Matt P
 * @date    03/03/2021
 * @description Categories related utility functions
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|
/**
 * @description array map of table columns
 */
const cols = [
  {
    type: 'String',
    label: 'Category Name',
    value: 'name',
    visibility: 'all',
  },
  {
    type: 'Switch',
    label: 'Enabled',
    value: 'is_enabled',
    visibility: 'all',
  },
  {
    type: 'Tag',
    label: 'Parent(s)',
    value: ['tags'],
    visibility: 'all',
  },
  {
    type: 'Options',
    label: '',
    value: ['options'], // This is collection which decides there could be multiple functionalities in same column
    visibility: 'all', // visibility has 3 possibilities: all || parent || child
  },
];

/**
 * @description Category updates : Toggle enable, toggle featured, delete
 * If delete, no key passed to API
 */
const updateMapper = {
  DELETE: null,
  ENABLE: 'is_enabled',
  FEATURE: 'is_featured',
};

/**
 * @description map of input fields and related properties
 */
const categoryFormFields = [
  {
    label: 'Category Name',
    type: 'text',
    placeholder: 'Enter name',
    value: 'name',
  },
  {
    label: 'Emoji Icon',
    type: 'text',
    placeholder: 'Enter emoji',
    value: 'emoji_icon',
  },
  {
    label: 'Parent(s)',
    type: 'tag',
    placeholder: 'Add parent category',
    value: '',
  },
  {
    label: 'Enabled',
    type: 'switch',
    placeholder: '',
    value: 'is_enabled',
  },
];

/**
 * @description category tag select styling
 */
const tagDropdownStyling = () => ({
  control: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    minHeight: '44px',
    boxShadow: 'none',
    border: '1px solid rgba(168, 164, 160, 0.5)',
    ':hover': {
      border: '1px solid rgba(168, 164, 160, 0.5)',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    fontFamily: 'Libre Franklin', // $libre-franklin
    fontSize: '14.5px',
    padding: '13px 4px',
    color: '#a8a4a0',
    fontWeight: '400',
  }),
  menuList: (styles) => ({
    ...styles,
    height: '150px',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    cursor: 'pointer',
    backgroundColor: 'white',
    color: isFocused ? '#f7bc25' : styles.color,
    ':hover': {
      color: '#f7bc25',
      backgroundColor: 'white',
    },
    ':active': {
      color: '#f7bc25',
      backgroundColor: 'white',
    },
    ':selected': {
      color: '#f7bc25',
      backgroundColor: 'white',
      outline: 'none',
    },
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    display: 'flex',
    alignItems: 'center',
    minHeight: '25px',
    backgroundColor: '#f0f0f0', // $grey9
    borderRadius: '50px',
    color: '#7c8e9a',
    padding: '3px 8px',
    marginRight: '6px',
    marginTop: '2px',
    marginBottom: '2px',
    cursor: 'pointer',
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontFamily: 'Libre Franklin', // $libre-franklin
    fontWeight: '600',
    color: '#353029', // $text-color
    fontSize: '12px',
    lineHeight: '14.54px',
    margin: '0px',
    paddingRight: '0px',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: '#353029',
    ':hover': {
      color: '#716c67',
    },
  }),
});

/**
 * @description switch component styling
 */
const switchStyling = () => ({
  offColor: '#A8A4A0',
  onColor: '#F7BC25',
  handleDiameter: 20,
  uncheckedIcon: false,
  checkedIcon: false,
  height: 10,
  width: 35,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.25)',
});

/**
 * @description sorts cat by parent
 *
 * @param {Object[]} categories
 *
 * @returns {Object[]} array of cats *meow
 */
const filterCategoryParents = (categories) => {
  const parentObject = {};

  categories.forEach((category) => {
    const { parent_categories } = category;

    for (let i = 0; i < parent_categories.length; i += 1) {
      if (!parentObject[parent_categories[i].unique_slug]) {
        parentObject[parent_categories[i].unique_slug] = parent_categories[i];
      }
    }
  });

  return Object.values(parentObject).sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};

/**
 * @description filters categories based on search query
 *
 * @param {Array} categories - array of cat objects
 * @param {String} query
 *
 * @returns {Array}
 */
const filterCategoriesByQuery = (categories, query) =>
  categories.filter((cat) =>
    cat.name.toLowerCase().includes(query.toLowerCase())
  );

/**
 * @description maps categories by parent unique_slug key
 *
 * @param {Object[]} parentArray
 *
 * @returns {Object}
 */
const createParentKeyValue = (parentArray) => {
  const returnedObj = {};

  parentArray.forEach((arr) => {
    returnedObj[arr.unique_slug] = arr;
  });

  return returnedObj;
};

/**
 * @description filters categories for dropdown based on parent
 *
 * @param {Array} categories - array of cat objects
 * @param {String} parentSlug - parent slug string
 *
 * @returns {Array}
 */
const filterCategoriesByParent = (categories, parentSlug) => {
  if (parentSlug === 'ALL') return categories;

  return categories.filter((cats) => {
    const { parent_categories } = cats;

    for (let i = 0; i < parent_categories.length; i += 1) {
      if (parent_categories[i].unique_slug === parentSlug) return true;
    }

    return false;
  });
};

/**
 * @description filters categories by parent for search
 *
 * @param {Array} categories - array of cat objects
 * @param {String} parentSlug - parent slug string
 * @param {String} query
 *
 * @returns {Array}
 */
const filterCategories = (categories, parentSlug, query) =>
  filterCategoriesByQuery(
    filterCategoriesByParent(categories, parentSlug),
    query
  );

/**
 * @description filters categories based on search query
 *
 * @param {Array} categories - array of cat objects
 * @param {String} query
 *
 * @returns {Array}
 */
const createCatParentDropdownOptions = (parentArray) => {
  const parentArrayWithDefault = [
    {
      label: 'All categories',
      value: { name: 'All categories', unique_slug: 'ALL' },
    },
  ];

  parentArray.forEach((parentObj) => {
    parentArrayWithDefault.push({
      label: parentObj.name,
      value: parentObj,
    });
  });

  return parentArrayWithDefault;
};

/**
 * @description creates tag options for tag select dropdown
 *
 * @param {Object[]} parentArray
 *
 * @returns {Object[]}
 */
const createTagOptions = (parentArray) =>
  parentArray.map((parentObj) => ({ label: parentObj.name, value: parentObj }));

/**
 * @description filters out tag options that are already selected
 *
 * @param {Object[]} currTags
 * @param {Object[]} allOptions
 *
 * @returns {Object[]}
 */
const filterInitialTagOptions = (currTags, allOptions) =>
  allOptions.filter((options) => {
    for (let i = 0; i < currTags.length; i += 1) {
      if (currTags[i].unique_slug === options.unique_slug) return false;
    }

    return true;
  });

/**
 * @description maps over cat array and replaces the successful change
 * with the changes
 *
 * @param {Object[]} categories
 * @param {Object} category
 *
 * @returns {Object[]}
 */
const mapAndReplace = (categories, category) =>
  categories.map((cat) => {
    if (cat.unique_slug === category.unique_slug) return category;
    return cat;
  });

/**
 * @description error checking for emoji input field
 *
 * @param {String} emojiString
 *
 * @returns {Boolean}
 */
const emojiStringErrorChecking = (emojiString) => {
  if (emojiString === '') return true;
  // eslint-disable-next-line max-len
  const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;

  return emojiRegex.test(emojiString);
};

export {
  cols,
  updateMapper,
  categoryFormFields,
  filterCategoryParents,
  filterCategoriesByQuery,
  filterCategoriesByParent,
  filterCategories,
  mapAndReplace,
  createCatParentDropdownOptions,
  switchStyling,
  createParentKeyValue,
  createTagOptions,
  tagDropdownStyling,
  emojiStringErrorChecking,
  filterInitialTagOptions,
};
