/**
 * ************************************
 *
 * @module  Routes.js
 * @author  Matt P
 * @date    03/11/2020
 * @description React Router routes component.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';
// though we're not using the component directly, importing fixes a
// strange bug with react-loadable and react-scripts v5, which uses
// webpack 5 under the hood.
import { VerticalDisplay } from 'containers';
import Loadable from 'react-loadable';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthRoute from 'routes/AuthRoutes';

import { Loader } from 'components';

import { DEFAULT_AUTH_ROUTE, VERTICAL_TYPE } from 'constants.js';

// ----------------------------------------------------------------------------|
//                      Conditional Rendering - Loadable
// ----------------------------------------------------------------------------|
/**
 * @description Loading Animation component while async calls resolve
 *
 * @returns {JSX} HTML components to be rendered to the DOM
 */
const LoaderComponent = () => <Loader />;

/**
 * @description Async vertical/page sections, leverages loadable
 * with dynamic imports.
 */
const AsyncLogin = Loadable({
  loader: () => import('containers/Login/Login'),
  loading: LoaderComponent,
});

const AsyncVerticalDisplay = Loadable({
  loader: () => import('containers/VerticalDisplay/VerticalDisplay'),
  loading: LoaderComponent,
});

const AsyncCuratedCards = Loadable({
  loader: () => import('containers/CuratedCards/CuratedCards'),
  loading: LoaderComponent,
});

const AsyncCategories = Loadable({
  loader: () => import('containers/Categories/Categories'),
  loading: LoaderComponent,
});

const AsyncFilters = Loadable({
  loader: () => import('containers/Filters/Filters'),
  loading: LoaderComponent,
});

const AsyncCities = Loadable({
  loader: () => import('containers/Cities/Cities'),
  loading: LoaderComponent,
});

// ----------------------------------------------------------------------------|
//                    React Function Component - Routes
// ----------------------------------------------------------------------------|
/**
 * @description This is a functional component. Which is used to render a button
 * It will trigger onClick event which is connected to it.
 *
 * @param {Object} props
 *
 * @argument {Object} childProps: Props to be passed to selected route
 *
 * @returns {JSX} HTML components to be rendered to the DOM
 */

const Routes = ({ childProps }) => {
  const props = {
    ...childProps,
  };

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" exact component={AsyncLogin} props={props} />
        <AuthRoute
          path="/collections"
          component={AsyncVerticalDisplay}
          props={{ verticalType: VERTICAL_TYPE.COLLECTION, ...props }}
        />
        <AuthRoute
          path="/curated"
          component={AsyncCuratedCards}
          props={{ verticalType: VERTICAL_TYPE.CURATED, ...props }}
        />
        <AuthRoute
          path="/places"
          component={AsyncVerticalDisplay}
          props={{ verticalType: VERTICAL_TYPE.PLACE, ...props }}
        />
        <AuthRoute
          path="/events"
          component={AsyncVerticalDisplay}
          props={{ verticalType: VERTICAL_TYPE.EVENT, ...props }}
        />
        <AuthRoute
          path="/flagged"
          component={AsyncVerticalDisplay}
          props={{ verticalType: VERTICAL_TYPE.FLAGGED, ...props }}
        />
        <AuthRoute
          path="/movies"
          component={AsyncVerticalDisplay}
          props={{ verticalType: VERTICAL_TYPE.MOVIE, ...props }}
        />
        <AuthRoute
          path="/recipes"
          component={AsyncVerticalDisplay}
          props={{ verticalType: VERTICAL_TYPE.RECIPE, ...props }}
        />
        <AuthRoute
          path="/recreation"
          component={AsyncVerticalDisplay}
          props={{ verticalType: VERTICAL_TYPE.ACTIVITY, ...props }}
        />
        <AuthRoute
          path="/search"
          component={AsyncVerticalDisplay}
          props={{ verticalType: VERTICAL_TYPE.UNIVERSAL_SEARCH, ...props }}
        />
        <AuthRoute
          path="/settings/categories"
          component={AsyncCategories}
          props={{ verticalType: VERTICAL_TYPE.CATEGORY, ...props }}
        />
        <AuthRoute
          path="/settings/filters"
          component={AsyncFilters}
          props={{ verticalType: VERTICAL_TYPE.FILTER, ...props }}
        />
        <AuthRoute
          path="/settings/cities"
          component={AsyncCities}
          props={{ verticalType: VERTICAL_TYPE.CITIES }}
        />
        {/* Finally, catch all unmatched routes */}
        <Redirect to={DEFAULT_AUTH_ROUTE} />
      </Switch>
    </BrowserRouter>
  );
};

// ----------------------------------------------------------------------------|
//                          PropTypes Check - Routes
// ----------------------------------------------------------------------------|
Routes.propTypes = {
  childProps: PropTypes.object,
};

// ----------------------------------------------------------------------------|
//                                Default Props
// ----------------------------------------------------------------------------|

Routes.defaultProps = {
  childProps: {},
};

// ----------------------------------------------------------------------------|
//                                Routes Export
// ----------------------------------------------------------------------------|
export default Routes;
