/**
 * ************************************
 *
 * @module  PlaceData.constants.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Data for the Place card forms. Loops through and renders
 * the various form inputs and components
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import {
  AddressInput,
  Button,
  CardSelection,
  CheckboxRow,
  Input,
  MultiDropdownSelect,
  MultiTagSelect,
  PlaceHours,
  ReviewStatus,
} from 'components';

import { getPriceRange, getReservationTiers } from 'utils/utils';

import PlaceService from './PlaceService';

// ----------------------------------------------------------------------------|
//                        PlaceData - Array of Objects
// ----------------------------------------------------------------------------|
const PlaceData = [
  {
    type: 'component',
    name: 'name',
    component: ({ value, onChange, onButtonClick, error }) => (
      <div className="form-field-wrapper form-field-multi-comp">
        <div className="form-input">
          <label>Name of Place</label>
          <div className="form-input__input-wrapper">
            <Input
              type="text"
              name="name"
              value={value || ''}
              onChange={onChange}
              maxLength={1000}
            />
            <Button
              className="btn-inverse"
              onClick={onButtonClick}
              preventDefault
            >
              API Connections
            </Button>
          </div>
        </div>
        <div className="error">{error}</div>
      </div>
    ),
  },
  {
    type: 'component',
    name: 'address_input',
    label: '',
    // eslint-disable-next-line react/prop-types
    component: ({ onPlaceSelected, value, errors }) => (
      <div className="form-field-wrapper">
        <AddressInput
          label="Address"
          id="addressInput"
          name="address_input"
          onPlaceSelected={onPlaceSelected}
          value={value}
          errors={errors}
          fromPlace
        />
      </div>
    ),
  },
  {
    type: 'component',
    name: 'neighborhoods',
    component: ({ tags, suggestions, onChange, onButtonClick, error }) => (
      <div className="form-field-wrapper form-field-multi-comp">
        <div className="form-input">
          <label>Neighborhoods</label>
          <div className="form-input__input-wrapper">
            <MultiTagSelect
              name="neighborhoods"
              tags={tags}
              suggestions={suggestions}
              onChange={onChange}
              groupSuggestions
            />
            <Button
              className="btn-inverse"
              onClick={onButtonClick}
              preventDefault
            >
              Auto Tag
            </Button>
          </div>
        </div>
        <div className="error">{error}</div>
      </div>
    ),
  },
  {
    type: 'component',
    label: 'Main Category',
    name: 'main_categories',
    component: ({ options, values, onChange, disabled, error }) => (
      <div className="form-field-wrapper">
        <MultiDropdownSelect
          options={options}
          values={values}
          name="main_categories"
          onChange={onChange}
          disabled={disabled}
          error={error}
        />
      </div>
    ),
    errorMsg: 'Main Category cannot be empty',
  },
  {
    type: 'tag',
    label: 'Noteworthy',
    name: 'noteworthy_categories',
    values: [],
    value: '',
    placeholder: '',
    errorMsg: 'Noteworthy Category cannot be empty',
  },
  {
    type: 'tag',
    label: '“If you’re...”',
    name: 'perfect_for',
    placeholder: '',
    errorMsg: '“If you’re...” cannot be empty',
  },
  {
    type: 'tag',
    label: 'Vibes',
    name: 'vibes',
    placeholder: 'Select Vibes',
    errorMsg: 'Please Select Vibes',
  },
  {
    type: 'dropdown',
    label: 'Price Range',
    name: 'price_tier',
    values: getPriceRange(),
    placeholder: 'Select Price Range',
    errorMsg: 'Price Range cannot be empty',
  },
  {
    type: 'component',
    name: 'pairings',
    label: '',
    // eslint-disable-next-line react/prop-types
    component: ({
      pairingReasonsOptions,
      title,
      verticalTypes,
      verticalType,
      errors,
    }) => (
      <div className="form-field-wrapper">
        <CardSelection
          pairingReasonsOptions={pairingReasonsOptions}
          title={title}
          verticalTypes={verticalTypes}
          verticalType={verticalType}
          errors={errors}
        />
      </div>
    ),
  },
  {
    type: 'component',
    name: 'section_header', // not a form field
    component: () => (
      <div className="form-field-header">
        <h2>Place Details</h2>
      </div>
    ),
  },
  {
    type: 'component',
    name: 'checkbox_row',
    label: '',
    // eslint-disable-next-line react/prop-types
    component: ({ onChange, selectedOptions }) => (
      <div className="form-field-wrapper">
        <CheckboxRow
          name={null}
          options={PlaceService.placeOptions}
          selectedOptions={selectedOptions}
          onChange={onChange}
        />
      </div>
    ),
  },
  {
    type: 'multiinput',
    name: 'menu_links',
    label: 'Menu Links',
    value: [],
    buttonTitle: 'Link',
    errorMsg: 'Invalid link',
  },
  {
    type: 'multiinput',
    name: 'booking_links',
    label: 'Reservation Links',
    value: [],
    buttonTitle: 'Link',
    errorMsg: 'Invalid link',
  },
  {
    type: 'dropdown',
    label: 'How hard is it to book?',
    name: 'reservation_tier',
    // remove walk_in_only as that's toggled by checkbox
    values: getReservationTiers().slice(0, -1),
    value: '',
    errorMsg: '',
  },
  {
    type: 'multiinput',
    name: 'ordering_links',
    label: 'Delivery / Take Out Links',
    value: [],
    buttonTitle: 'Link',
    errorMsg: 'Invalid link',
  },
  {
    type: 'text',
    name: 'website_link',
    label: 'Website Link',
    value: '',
    errorMsg: 'Invalid link',
  },
  {
    type: 'component',
    name: 'place_hours',
    // eslint-disable-next-line react/prop-types
    component: ({
      from,
      errors,
      saveClick,
      onAction,
      showEditHours,
      editHoursError,
    }) => (
      <PlaceHours
        from={from}
        saveClick={saveClick}
        onAction={onAction}
        showEditHours={showEditHours}
        errors={errors}
        editHoursError={editHoursError}
      />
    ),
    from: {
      monday: { slots: [], status: '' },
      tuesday: { slots: [], status: '' },
      wednesday: { slots: [], status: '' },
      thursday: { slots: [], status: '' },
      friday: { slots: [], status: '' },
      saturday: { slots: [], status: '' },
      sunday: { slots: [], status: '' },
    },
    errorMsg: '',
  },
  {
    type: 'text',
    name: 'phone_number',
    label: 'Phone Number',
    value: '',
    errorMsg: 'Enter valid phone number',
    placeholder: '123-456-7890',
  },
  {
    type: 'component',
    name: 'last_admin_review',
    label: '',
    component: ({ value, history, onChange, reviewDate, errors }) => (
      <div className="form-field-wrapper">
        <ReviewStatus
          value={value}
          reviewDate={reviewDate}
          history={history}
          errors={errors}
          onChange={onChange}
        />
      </div>
    ),
  }
];

// ----------------------------------------------------------------------------|
//                               PropTypes Check
// ----------------------------------------------------------------------------|
PlaceData.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default PlaceData;
