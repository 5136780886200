import React from 'react';
import PropTypes from 'prop-types';

import { CobDatePicker, HourPicker } from 'components';

import './HourDateRange.scss';

const HourDateRange = ({ from, to, errors, type, onChange }) => {
  const getDateRange = (fromDate, toDate) => (
    <React.Fragment>
      <CobDatePicker
        minDate={new Date()}
        selectedDate={fromDate}
        name="start_date"
        onChange={onChange}
      />
      <span className="timer-separator">To</span>
      <CobDatePicker
        minDate={new Date()}
        selectedDate={toDate}
        name="end_date"
        onChange={onChange}
      />
    </React.Fragment>
  );

  const getHourRange = (fromHour, toHour) => (
    <React.Fragment>
      <HourPicker defaultValue={fromHour} name="fromHour" onChange={onChange} />
      <span className="timer-separator">To</span>
      <HourPicker defaultValue={toHour} name="toHour" onChange={onChange} />
    </React.Fragment>
  );

  const getComponent = (componentType) => {
    if (componentType === 'hour') {
      return getHourRange(from, to);
    }
    if (componentType === 'date') {
      return getDateRange(from, to);
    }
    return null;
  };
  return (
    <div className="hour-date-range">
      <label className="range-label">{type === 'hour' ? 'Hour' : 'Date'}</label>
      <div className="timer">{getComponent(type)}</div>
      <div className="error">{errors}</div>
    </div>
  );
};

HourDateRange.propTypes = {
  type: PropTypes.string.isRequired,
  from: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.string,
};

HourDateRange.defaultProps = {
  errors: '',
};
export default HourDateRange;
