/**
 * ************************************
 *
 * @module  EventService.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description File to prepare Event form
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import CommonService from 'services/CommonService';

import {
  chooseItemizedDescription,
  formatISODate,
  getProcessedNeighborhoods,
  hasUniqueSlug,
  qualifyArrayRendering,
  mapDetailedCategories,
} from 'utils/utils';

import { VERTICAL_TYPE } from 'constants.js';

import {
  createCategoryUniqueSlugSet,
  extractCategoryTypes,
} from 'utils/CategoryUtils';

import currentEnv from 'utils/EnvironmentSpecificValues';

import {
  filterEmptyMultiLinks,
  isValidNumber,
  trimAllNonNumberCharacters,
} from 'utils/FormFunc';
// eslint-disable-next-line max-len
import EventDateSelectorService from 'components/EventDateSelector/EventDateSelectorService';

// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|
const dateFormat = 'YYYY-MM-DD';
const commonService = CommonService();

const constraints = {
  address: {},
  description: {},
  neighborhoods: {},
  event_venue_name: {},
  recurring_days: {},
  event_amount: {},
  event_hours: {},
};

// ----------------------------------------------------------------------------|
//                             Event Service
// ----------------------------------------------------------------------------|
const EventService = {
  /**
   * @description processes the vertical specific data that is incoming from
   * the backend to populate an edit form.
   *
   * @param {Object} dataObj - of date data
   * @param {Object} additionalOptions - any additional data that may be
   * required to process incoming data
   *
   * @returns {Object}
   */
  processVerticalSpecificData(dataObj = {}, additionalOptions = {}) {
    const {
      address,
      booking_links,
      categories,
      description,
      duration,
      end_date,
      event_amount,
      event_amount_max,
      event_amount_suffix,
      event_hours,
      event_venue_name,
      from_time,
      itemized_description,
      lat,
      lng,
      main_categories,
      name,
      neighborhoods,
      noteworthy_categories,
      pairings,
      perfect_for,
      phone,
      phrase,
      price_tier,
      recurring_days,
      recurring_event,
      reservation_tier,
      start_date,
      to_time,
      vibes,
      website,
    } = dataObj;

    const { categoryMap } = additionalOptions;

    // since we are using categories for several different fields,
    // we need to delineate the categories to the specific fields
    // they will later upon submission be joined for the API POST request
    const { misc } = extractCategoryTypes(
      mapDetailedCategories(noteworthy_categories, categoryMap)
    );

    // virtual event come in under noteworthy. Those need to be separated
    // out on fetch
    let filteredNoteworthy = noteworthy_categories;

    if (
      qualifyArrayRendering(noteworthy_categories) &&
      qualifyArrayRendering(misc)
    ) {
      const miscSet = createCategoryUniqueSlugSet(misc);

      filteredNoteworthy = noteworthy_categories.filter(
        (m) => !miscSet.has(m.unique_slug)
      );
    }

    return {
      name: name || '',
      address: address || '',
      phrase: phrase || '',
      description,
      main_categories: main_categories
        ? mapDetailedCategories(main_categories, categoryMap)
        : [],
      neighborhoods: neighborhoods
        ? getProcessedNeighborhoods(neighborhoods)
        : [],
      price_tier:
        price_tier === undefined || price_tier === null ? '' : price_tier,
      from_time,
      to_time,
      website_link: website,
      booking_links,
      reservation_tier: reservation_tier || '',
      phone_number: phone,
      start_date: start_date || new Date(),
      end_date: end_date || new Date(),
      recurring_days,
      recurring_event,
      location: '',
      event_amount: event_amount ? String(event_amount) : '0',
      event_amount_max: event_amount_max ? String(event_amount_max) : '',
      event_amount_suffix,
      lat,
      lng,
      event_venue_name: event_venue_name || '',
      event_hours: EventDateSelectorService.prepareDataForUI(event_hours),
      vibes: vibes || [],
      noteworthy_categories: filteredNoteworthy
        ? mapDetailedCategories(filteredNoteworthy, categoryMap)
        : [],
      perfect_for: perfect_for
        ? mapDetailedCategories(perfect_for, categoryMap)
        : [],
      pairings: pairings || [],
      // special case where we want to put the detailed description from
      // old card details into the itemized one
      itemized_description: chooseItemizedDescription(
        itemized_description,
        description,
        EventService.defaultDescriptions
      ),
      // used to keep track of the miscOptions that are also categories,
      // are joined together upon form submission
      misc_options: misc || [],
      duration: duration || 0,
    };
  },

  /**
   * @description preps the vertical specific form data to be passed in the
   * request body for a form update
   *
   * @param {Object} formData - formData
   * @param {Object} additionalOptions - any additional data that may be
   * required to process outgoing data
   *
   * @returns {Object} for requestBody
   */
  prepareVerticalSpecificFormData(formData = {}, additionalOptions = {}) {
    const {
      address,
      description,
      duration,
      event_amount,
      event_amount_max,
      event_amount_suffix,
      event_hours,
      end_date,
      from_time,
      lat,
      lng,
      neighborhoods,
      phone_number,
      phrase,
      price_tier,
      recurring_event,
      recurring_days,
      reservation_tier,
      start_date,
      to_time,
      website_link,
    } = formData;

    const { selectedCards } = additionalOptions;

    let { booking_links } = formData;

    // checks if 'does not take reservations' is toggled. If so,
    // will pass an empty booking links array to the backend on save
    if (reservation_tier === 'walk_in_only') {
      booking_links = [];
    }

    return {
      address,
      description: description || '',
      is_event: true,
      phrase: phrase ? phrase.trim() : '',
      set_neighborhoods:
        (neighborhoods && getProcessedNeighborhoods(neighborhoods)) || [],
      event_amount:
        event_amount === '' || !event_amount
          ? 0 // backend requires number
          : trimAllNonNumberCharacters(event_amount),
      event_amount_max:
        event_amount_max === '' || !event_amount_max
          ? 0 // backend requires number
          : trimAllNonNumberCharacters(event_amount_max),
      event_amount_suffix: event_amount_suffix || 'person',
      start_date: formatISODate(start_date, dateFormat),
      end_date: formatISODate(end_date, dateFormat),
      to_time,
      from_time,
      booking_links: booking_links ? filterEmptyMultiLinks(booking_links) : [],
      website: website_link || '',
      phone: phone_number || '',
      price_tier:
        price_tier === '' || price_tier === undefined ? undefined : price_tier,
      reservation_tier: reservation_tier || '',
      recurring_event: recurring_event || false,
      recurring_days,
      lat,
      lng,
      event_hours: EventDateSelectorService.prepareDataForBackend(event_hours),
      duration,
      pairings: selectedCards.map((card) => ({
        pairing_reason: card.pairing_reason,
        unit_pretty_id: card.pretty_id,
      })),
    };
  },

  /**
   * @description error checking for 'publish' form submission
   *
   * @param {Object} data - formData
   *
   * @returns {Object} of errors
   */
  validate(data) {
    const errorsObj = {
      ...commonService.validate(data, VERTICAL_TYPE.EVENT),
      ...commonService.validateMultiLinks(data.booking_links, 'booking_links'),
    };
    const keys = Object.keys(constraints);

    delete errorsObj.date_range;
    delete errorsObj.hour_range;

    keys.forEach((elem) => {
      if (elem === 'event_amount') {
        delete errorsObj.event_amount;

        const trimmed = trimAllNonNumberCharacters(data.event_amount);

        if (
          data.price_tier > 0 &&
          (trimmed === '' || !isValidNumber(trimmed))
        ) {
          errorsObj.event_amount = 'Please enter a valid amount';
        }
      }

      if (elem === 'event_amount_max') {
        delete errorsObj.event_amount_max;

        const trimmed = trimAllNonNumberCharacters(data.event_amount_max);

        if (
          data.price_tier > 0 &&
          (trimmed === '' || !isValidNumber(trimmed))
        ) {
          errorsObj.event_amount_max = 'Please enter a valid amount';
        }
      }

      if (elem === 'event_venue_name') {
        delete errorsObj.event_venue_name;
        if (!data.event_venue_name) {
          // Making field no longer required
          // errorsObj.event_venue_name = 'Event Location is required';
        }
      }

      // if (elem === 'description' && Boolean(!data.description.length)) {
      //   errorsObj.description = 'Please add a description';
      // }

      if (
        elem === 'address' &&
        typeof data.address === 'string' &&
        data.address.length === 0 &&
        !hasUniqueSlug(data.misc_options, currentEnv.uniqueSlugs.virtualEvent)
      ) {
        errorsObj.address_input = 'Address or virtual event check is required';
      }

      if (elem === 'event_hours') {
        if (!data.event_hours || !data.event_hours.length) {
          // Making field no longer required
          errorsObj.event_hours = 'At least one date is required';
        }

        // eslint-disable-next-line max-len
        const overlappingError =
          EventDateSelectorService.checkForOverlappingDates(data.event_hours);
        if (overlappingError.value) {
          errorsObj.event_hours = overlappingError.value;
        }
      }

      if (data.vibes.length < 1) {
        errorsObj.vibes = 'At least one vibe is required';
      }

      if (data.price_tier === undefined || data.price_tier === '') {
        errorsObj.price_tier = 'Price range is required';
      }
    });

    return commonService.validateLinks(data, errorsObj);
  },

  /**
   * @description default descriptions for the itemized_descriptions
   * form field
   */
  defaultDescriptions: [
    {
      header: 'What it is',
      body: '',
    },
    {
      header: 'What you need to know',
      body: '',
    },
  ],
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default EventService;
