/**
 * ************************************
 *
 * @module  CuratedCardService.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description service function for various methods used by the CuratedCard
 * component.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import { isArrayEmpty } from '../../utils/FormFunc';

const CuratedCardService = () => {
  /**
   * @description Validates length of card selector.
   *
   * @param {Object[]}
   *
   * @returns {Boolean}
   */
  const validateCards = (cards = []) => cards.length === 2;

  /**
   * @description Validates if all selected cards have a status
   * of published
   *
   * @param {Object[]}
   *
   * @returns {Boolean}
   */
  const validatePublishedCards = (cards = []) =>
    cards.every((card) => card.status === 'published' && !card.is_expired);

  /**
   * @description maps over and processes neighborhood objects
   *
   * @argument {Object[]} neighborhoods
   *
   * @returns {Object[]}
   */
  const getProcessedNeighborhoods = (neighborhoods = []) => {
    if (typeof neighborhoods[0] === 'object') {
      return neighborhoods.map((neighborhood) => neighborhood.name);
    }
    return neighborhoods;
  };

  /**
   * @description returns array of id strings
   *
   * @argument {Object[]} cards
   *
   * @returns {String[]}
   */
  const _getCardIds = (cards = []) => cards.map((card) => card.id);

  /**
   * @description returns array of pretty_id strings
   *
   * @argument {Object[]} cards
   *
   * @returns {String[]}
   */
  const _getCardPrettyIds = (cards = []) => cards.map((card) => card.pretty_id);

  /**
   * @description preps curated card form data for HTTP requests
   *
   * @returns {Object}
   */
  const getPreparedFormData = (data, uploadedImages, status, cards) => {
    const {
      name,
      one_liner_description,
      long_description,
      neighborhoods,
      categories,
      price_tier,
    } = data;

    return {
      is_curated: true,
      name,
      phrase: one_liner_description ? one_liner_description.trim() : '',
      description: long_description ? long_description.trim() : '',
      price_tier,
      categories,
      set_neighborhoods:
        (neighborhoods && getProcessedNeighborhoods(neighborhoods)) || [],
      set_images: uploadedImages,
      // set_places: _getCardIds(cards),
      card_units_pretty_ids: _getCardPrettyIds(cards),
      status,
    };
  };

  /**
   * @description preps error message
   *
   * @param {String} elem
   * @param {Number} min
   * @param {Number} max
   *
   * @returns {String}
   */
  const _getMessage = (elem, min, max) => {
    return `${elem} should
       be min ${min} and max ${max} characters`;
  };

  /**
   * @description validates curated card form values
   *
   * @param {Object} data
   *
   * @returns {Object}
   */
  const validate = (data) => {
    const errorsObj = {};
    const constraints = {
      name: {
        label: 'Name',
        min: 1,
        max: 100,
      },
      one_liner_description: {
        label: 'One Liner Description',
        min: 10,
        max: 250,
      },
      long_description: { label: 'Long Description', min: 10, max: 2500 },
      neighborhoods: {},
      categories: {},
      price_tier: {},
    };

    const keys = Object.keys(constraints);

    keys.forEach((elem) => {
      const { min, max, label } = constraints[elem];

      if (!data[elem] || data[elem].length < min || data[elem].length > max) {
        errorsObj[elem] = _getMessage(label, min, max);
      }
      delete errorsObj.price_tier;
      if (elem === 'neighborhoods' && data[elem] && data[elem].length === 0) {
        errorsObj[elem] = 'Neighbourhood is required';
      }
      if (elem === 'categories' && isArrayEmpty(data[elem])) {
        errorsObj[elem] = 'Category is required';
      }
    });
    return errorsObj;
  };

  return {
    validate,
    validateCards,
    validatePublishedCards,
    getPreparedFormData,
  };
};
export default CuratedCardService;
