/**
 * ************************************
 *
 * @module  Filter.js
 * @author  Matt P
 * @date    03/22/2021
 * @description Container for the filter component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import Select from 'react-select';
import Switch from 'react-switch';

import { connect } from 'react-redux';

import FormInput from 'components/Form/FormInput/FormInput';

import { Button, Modal, Checkbox, CobReactTagSelect, ControledCheckbox } from 'components';

import { FILTER } from 'constants.js';

import {
  createFilterAction,
  editFilterAction,
} from 'store/actions/Filters.action';

import {
  capitalize,
  createDropdownOptions,
  deepCopy,
  filterInitialTagOptions,
} from 'utils/utils';
import { tagDropdownStyling } from 'utils/ReactSelectStyles';

import {
  filterFormFields,
  typeDropdownOptions,
  defaultAdvancedOptions,
  switchStyling,
  emojiStringErrorChecking,
  generateAdvancedRuleOptions,
  wasRuleChecked,
  findTypeDropdownLabel,
  findLocationTypeLabel,
  mapLocationSlug,
  areArrayEquals,
} from '../FiltersUtils';

import './Filter.scss';

// ----------------------------------------------------------------------------|
//                        Redux - Property Mapping
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  createFilter: (data) => dispatch(createFilterAction(data)),
  editFilter: (data) => dispatch(editFilterAction(data)),
});

const mapStateToProps = (state) => ({
  filterRules: state.filters.filterRules,
  categories: state.categories.categories,
});

// ----------------------------------------------------------------------------|
//                   React Function Component - Category
// ----------------------------------------------------------------------------|
const Filter = ({
  showModal,
  closeModal,
  createFilter,
  editFilter,
  isEditing,
  passedFilter,
  categories,
  filterRules,
  filterLocationsDropdownOptions,
}) => {
  const initialState = {
    name: passedFilter ? passedFilter.name : '',
    nameErrorMsg: '',
    is_featured: passedFilter ? passedFilter.is_featured : false,
    is_enabled: passedFilter ? passedFilter.is_enabled : false,
    categories_in_filter: passedFilter
      ? createDropdownOptions(passedFilter.categories_in_filter) // needs update
      : [],
    categoriesErrorMsg: '',
    filter_types: passedFilter
      ? findLocationTypeLabel(passedFilter.filter_types)
      : [],
    filterTypeErrorMsg: '',
    advanced_options:
      passedFilter && passedFilter.advanced_options
        ? passedFilter.advanced_options
        : [],
    emoji_icon:
      passedFilter && passedFilter.emoji_icon ? passedFilter.emoji_icon : '',
    emojiErrorMsg: '',
    required_neighborhoods:
      passedFilter && Array.isArray(passedFilter.required_neighborhoods)
        ? mapLocationSlug(passedFilter.required_neighborhoods)
        : [],
    excluded_neighborhoods:
      passedFilter && Array.isArray(passedFilter.excluded_neighborhoods)
        ? mapLocationSlug(passedFilter.excluded_neighborhoods)
        : [],
    filterLocationsDropdownOptions: typeDropdownOptions,
  };

  const [formData, setFormData] = useState(initialState);

  const [priceCheckboxesCheckStatus, setPriceCheckboxesCheckStatus] = useState(
    defaultAdvancedOptions.filter((option) => {return (option.option_key === "price")}).sort((a, b) => (b.option_values.length - a.option_values.length)).map((options) => (
      wasRuleChecked(
        passedFilter && passedFilter.advanced_options
        ? passedFilter.advanced_options
        : [],
        options,
        'defaultRule'
      )
    ))
  );

  /**
   * @description changes the form data based on key passed
   *
   * @param {String} field
   * @param {Any} value
   */
  const eventHandler = (field, value) => {
    const formDataCopy = { ...formData };
    formDataCopy[field] = value;
    setFormData({ ...formDataCopy });
  };

  /**
   * @description validates submitted form data
   */
  const validFormData = () => {
    const errorObj = {};

    errorObj.nameErrorMsg =
      !formData.name || !formData.name.trim() ? FILTER.NAME_ERR_MSG : '';

    errorObj.emojiErrorMsg = !emojiStringErrorChecking(formData.emoji_icon)
      ? 'Please add a valid emoji icon'
      : '';

    errorObj.categoriesErrorMsg =
      formData.categories_in_filter.length === 0
        ? 'At least one Category must be selected'
        : '';

    setFormData({ ...formData, ...errorObj });

    return (
      !errorObj.nameErrorMsg &&
      !errorObj.emojiErrorMsg &&
      !errorObj.categoriesErrorMsg
    );
  };

  /**
   * @description submits form to redux, checks if this is a new cat or edit
   */
  const submitForm = () => {
    if (isEditing && validFormData()) {
      const {
        is_featured,
        categories_in_filter,
        filter_types,
        advanced_options,
        name,
        emoji_icon,
        is_enabled,
        required_neighborhoods,
        excluded_neighborhoods,
      } = formData;

      const apiData = {
        is_featured,
        categories_in_filter: categories_in_filter.map(
          (cat) => cat.value.unique_slug
        ),
        filter_types: filter_types.map((type) => type.value),
        advanced_options,
        name,
        emoji_icon,
        is_enabled,
        id: passedFilter.id,
        required_neighborhoods,
        excluded_neighborhoods,
      };

      editFilter({ passedFilter, apiData });
      closeModal();
    } else if (validFormData()) {
      const {
        is_featured,
        categories_in_filter,
        filter_types,
        advanced_options,
        name,
        emoji_icon,
        is_enabled,
        required_neighborhoods,
        excluded_neighborhoods,
      } = formData;

      const apiData = {
        is_featured,
        categories_in_filter: categories_in_filter.map(
          (cat) => cat.value.unique_slug
        ),
        filter_types: filter_types.map((type) => type.value),
        advanced_options,
        name,
        emoji_icon,
        is_enabled,
        required_neighborhoods,
        excluded_neighborhoods,
      };

      createFilter(apiData);
      closeModal();
    }
  };

  /**
   * @description returns footer jsx
   *
   * @returns {JSX}
   */
  const designFooter = () => (
    <div className="modal-actions">
      <Button
        name="SAVE"
        type="button"
        className="btn action-btn"
        onClick={submitForm}
      >
        SAVE
      </Button>
    </div>
  );

  /**
   * @description On change function passed to <Select>
   *
   * @returns {Array} Array of mapped objects
   */
  const onSelectChange = (selectedValue, stateType) => {
    // On clearing filter, empty object is sent to back end
    const option = selectedValue || [];

    eventHandler(stateType, option);
  };

  const onTypeDropdownSelect = (selectedDropdown = {}) => {
    const formDataCopy = deepCopy(formData);

    setFormData({ ...formDataCopy, ...selectedDropdown.value });
  };

  const onOptionsCheckboxClick = (value, checked) => {
    if (checked) {
      var formDataAdvancedOptionsCopy = [
        ...formData.advanced_options,
      ];
      if (value.option_key === "price") {
        // Control for both price checkboxes
        const newPriceCheckboxesCheckStatus = priceCheckboxesCheckStatus.map((checkboxStatus, index) => {
          return index == value.option_bind_key ? true : false
        });
        setPriceCheckboxesCheckStatus(newPriceCheckboxesCheckStatus);
        formDataAdvancedOptionsCopy = formDataAdvancedOptionsCopy.filter((option) => {
          return option.option_key !== "price";
        })
      }
      formDataAdvancedOptionsCopy.push(value);
      eventHandler('advanced_options', formDataAdvancedOptionsCopy);
    } else {
      if (value.option_key === "price") {
        // Control for both price checkboxes
        const newPriceCheckboxesCheckStatus = priceCheckboxesCheckStatus.map((checkboxStatus, index) => {
          return index == value.option_bind_key ? false : priceCheckboxesCheckStatus[index]
        });
        setPriceCheckboxesCheckStatus(newPriceCheckboxesCheckStatus);
      }
      const formDataAdvancedOptionsCopy = [...formData.advanced_options];
      eventHandler(
        'advanced_options',
        formDataAdvancedOptionsCopy.filter((option) => {
          if (option.option_key === 'rule' && value.option_key === 'rule') {
            return option.option_rule.rule_key !== value.option_rule.rule_key;
          }
          return (option.option_key !== value.option_key || !areArrayEquals(option.option_values, value.option_values));
        })
      );
    }
  };

  const title = isEditing ? FILTER.EDIT_FILTER_TITLE : FILTER.NEW_FILTER_TITLE;

  return (
    <Modal
      title={title}
      customClasses="filters-modal"
      show={showModal}
      modalClosed={() => closeModal()}
      modalFooter={designFooter()}
    >
      <form name="new-filter" className="filter-form">
        {filterFormFields.map((field, index) => {
          const {
            label,
            placeholder,
            type,
            subtype,
            value,
            overriddenComponents, // pass them to Select dropdown for mad flavor
            inputOptions,
            inputKey,
          } = field;
          
          var runtimeInputOptions = inputOptions;
          if (inputKey == 'geo_location') {
            runtimeInputOptions = filterLocationsDropdownOptions;
          }

          const options = {
            type,
            name: label,
            label,
            value: '',
          };

          if (type === 'checkbox') {
            const classNames = `new-filter-checkbox ${
              value === 'is_child' ? 'child-of' : 'togglers' // change
            }`;

            return (
              <div className={classNames} key={inputKey}>
                <div className="checkbox-container">
                  <Checkbox
                    key={`checkbox-${index}`}
                    className="square"
                    onClick={(result) => eventHandler(value, result)}
                    label={label}
                  />
                  <label htmlFor={label}>{label}</label>
                </div>
              </div>
            );
          }

          if (type === 'tag') {
            // tag can be either location or cats conditionally render props
            // based on this.
            const tagOptions =
              subtype && subtype === 'categories'
                ? createDropdownOptions(
                    filterInitialTagOptions(
                      passedFilter ? formData.categories_in_filter : [],
                      categories
                    )
                  )
                : runtimeInputOptions;

            const tagName =
              subtype && subtype === 'categories'
                ? 'categories-tag'
                : 'location-tag';

            const tagValue =
              subtype && subtype === 'categories'
                ? formData.categories_in_filter
                : formData.filter_types;

            return (
              <div className="form-input" key={inputKey}>
                <label className="form-input-category-label">{label}</label>
                <CobReactTagSelect
                  components={overriddenComponents}
                  placeholder={placeholder}
                  options={tagOptions}
                  isClearable={false}
                  name={tagName}
                  value={tagValue}
                  styles={tagDropdownStyling()}
                  onChange={(selection) => {
                    onSelectChange(
                      selection,
                      subtype && subtype === 'categories'
                        ? 'categories_in_filter'
                        : 'filter_types'
                    );
                  }}
                  closeMenuOnSelect={false}
                  hideSelectedOptions
                />
                <div className="error">{formData.categoriesErrorMsg}</div>
              </div>
            );
          }

          if (type === 'dropdown') {
            const { required_neighborhoods, excluded_neighborhoods } = formData;

            return (
              <div
                className="form-input filter-form-dropdown"
                key={inputKey}
              >
                <label className="form-input-category-label">{label}</label>
                <Select
                  components={overriddenComponents || ''}
                  placeholder={placeholder}
                  options={runtimeInputOptions || []}
                  name="filter-type"
                  value={findTypeDropdownLabel(
                    required_neighborhoods,
                    excluded_neighborhoods,
                    runtimeInputOptions
                  )}
                  styles={tagDropdownStyling()}
                  onChange={(result) => onTypeDropdownSelect(result)}
                />
                <div className="error">{formData.filterTypeErrorMsg}</div>
              </div>
            );
          }

          if (type === 'switch') {
            const classNames = `new-filter-checkbox ${
              value === 'is_child' ? 'child-of' : 'togglers'
            }`;

            return (
              <div className={classNames} key={inputKey}>
                <div className="switch-container">
                  <Switch
                    onChange={(result) => eventHandler(value, result)}
                    checked={formData[value]}
                    {...switchStyling()}
                  />
                  <label htmlFor={label}>{label}</label>
                </div>
              </div>
            );
          }

          if (type === 'text') {
            if (label === 'Filter Name') {
              options.errorMsg = formData.nameErrorMsg;
              options.maxLength = FILTER.NAME_MAX_LENGTH;
            }

            if (label === 'Emoji Icon') {
              options.errorMsg = formData.emojiErrorMsg;
            }

            if (isEditing && passedFilter) {
              if (value === 'emoji_icon') {
                options.value = passedFilter.emoji_icon;
              } else if (value === 'name') {
                options.value = passedFilter.name;
              }
            }

            return (
              <FormInput
                key={inputKey}
                type={type}
                placeholder={placeholder}
                options={options}
                eventHandler={(result) =>
                  eventHandler(value, result.target.value)
                }
              />
            );
          }

          return null;
        })}
      </form>
      <div className="advanced-options-container">
        <h2 className="advanced-options-header-label">Advanced Options</h2>
        <div className="advanced-options-checkbox-container">
        {defaultAdvancedOptions.filter((option) => {return (option.option_key === "price")}).map((options) => (
          <div className="advanced-options-checkbox" key={`checkbox-wrapper-${options.option_key}-${options.option_name}`}>
            <ControledCheckbox
              key={`checkbox-${options.option_key}`}
              className="square"
              checkState={priceCheckboxesCheckStatus[options.option_bind_key]}
              onClick={(result) => onOptionsCheckboxClick(options, result)}
              label={options.option_name}
            />
            <div className="advanced-options-checkbox-text">
              {capitalize(options.option_name)}
            </div>
          </div>
        ))}
        {defaultAdvancedOptions.filter((option) => {return (option.option_key !== "price")}).map((options) => (
          <div className="advanced-options-checkbox" key={`checkbox-wrapper-${options.option_key}-${options.option_name}`}>
            <Checkbox
              key={`checkbox-${options.option_key}`}
              className="square"
              toBeChecked={
                passedFilter && passedFilter.advanced_options.length > 0
                  ? wasRuleChecked(
                      passedFilter.advanced_options,
                      options,
                      'defaultRule'
                    )
                  : false
              }
              fromRecurring
              onClick={(result) => onOptionsCheckboxClick(options, result)}
              label={options.option_name}
            />
            <div className="advanced-options-checkbox-text">
              {capitalize(options.option_name)}
            </div>
          </div>
        ))}
        {filterRules.length > 0
          ? filterRules.map((rule, index) => (
              // eslint-disable-next-line react/jsx-indent
              <div className="advanced-options-checkbox" key={`checkbox-wrapper-${rule.name}`}>
                <Checkbox
                  key={`checkbox-${rule.name}`}
                  className="square"
                  toBeChecked={
                    passedFilter && passedFilter.advanced_options.length > 0
                      ? wasRuleChecked(
                          passedFilter.advanced_options,
                          rule,
                          'fetchedRule'
                        )
                      : false
                  }
                  fromRecurring
                  onClick={(result) => {
                    onOptionsCheckboxClick(
                      generateAdvancedRuleOptions(rule.rule_key),
                      result
                    );
                  }}
                  label={rule.name}
                />
                <div className="advanced-options-checkbox-text">
                  {capitalize(rule.name)}
                </div>
              </div>
            ))
          : ''}
        </div>
      </div>
    </Modal>
  );
};

// --------------------------------------------------------------------------|
//                             PropTypes Check
// --------------------------------------------------------------------------|
Filter.propTypes = {
  showModal: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  filterRules: PropTypes.array.isRequired,
  filterLocationsDropdownOptions: PropTypes.array,
  closeModal: PropTypes.func.isRequired,
  createFilter: PropTypes.func.isRequired,
  editFilter: PropTypes.func,
  isEditing: PropTypes.bool,
  passedFilter: PropTypes.any,
  neighborhoods: PropTypes.arrayOf(
    PropTypes.shape({
      county: PropTypes.string,
      county_slug: PropTypes.string,
      name: PropTypes.string,
      should_display_in_filters: PropTypes.bool,
      unique_slug: PropTypes.string.isRequired,
    })
  ).isRequired,
};

// --------------------------------------------------------------------------|
//                              Default Props
// --------------------------------------------------------------------------|
Filter.defaultProps = {
  isEditing: false,
  passedFilter: null,
  editFilter: () => {},
};

// ----------------------------------------------------------------------------|
//                    Filter Export with Redux Connect
// ----------------------------------------------------------------------------|
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Filter));
