/**
 * ************************************
 *
 * @module  FileUploader.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description component for image uploads on form inputs
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Container, Draggable } from 'react-smooth-dnd';
import 'react-dropzone-uploader/dist/styles.css';

import Dropzone from 'react-dropzone-uploader';
import { ImagePreview, Input } from 'components';

import { deepCopy } from 'utils/utils';
import { handleUpload, imageMetadata } from 'utils/ImageUtils';

import { MAX_IMAGE_UPLOADS } from 'constants.js';

import Layout from './Layout';

import './FileUploader.scss';

// ----------------------------------------------------------------------------|
//                  React Function Component - FileUploader
// ----------------------------------------------------------------------------|
const FileUploader = ({
  data,
  failedUploads,
  onAddImage,
  onDeleteImage,
  onEvent,
  error,
  onDrop,
  isDropZonePreviewRequired,
  title,
  largePreview,
}) => {
  /**
   * @description filters data for any undefined elements
   *
   * @returns {Array} - of filtered objects.
   */
  const filterData = (input) => input.filter((each) => each !== undefined);

  /**
   * @description Preps the incoming image data array
   * by filling in default photo_credit options (depending on
   * image source)
   *
   * Will leverage filterData func
   *
   * @returns {Array} - of objects.
   */
  const prepPhotoData = (input) => {
    // deep copy of array of objects to prevent mutation
    const filteredDataTemp = deepCopy(filterData(input));
    let newFetchedPhotoCount = 0;

    // current list of image sources
    const imageSources = {
      tmdb: 'TMDB',
      foursquare: 'Foursquare',
      cobble: '',
    };

    // maps photo data with default credit fields populated
    const mappedPhotos = filteredDataTemp.map((arrayData) => {
      // if photo is fetched and not uploaded via cobble CMS
      if (
        // foursquare fetched images will not have a photo_credits prop
        // while tmdb will have the prop but will be set to null
        (arrayData.photo_credits === undefined ||
          arrayData.photo_credits === null) &&
        arrayData.source !== 'cobble' &&
        arrayData.source !== 'Cobble'
      ) {
        const { source } = arrayData;
        let photoSource = '';

        newFetchedPhotoCount += 1;

        if (source.toLowerCase().includes('tmdb')) {
          photoSource = 'tmdb';
        } else if (source.toLowerCase().includes('foursquare')) {
          photoSource = 'foursquare';
        } else {
          photoSource = '';
        }

        if (imageSources[photoSource]) {
          arrayData.photo_credits = imageSources[photoSource];

          return arrayData;
        }
      }
      return arrayData;
    });

    // if any photos are fetched and have not been set with a
    // photo credit, will update redux;
    if (newFetchedPhotoCount > 0 && onEvent) onEvent(mappedPhotos);

    return mappedPhotos;
  };

  /**
   * @description called every time a file's `status` changes
   *
   * @argument {Object} fileData - Object of file data
   */

  const handleChangeStatus = async (fileData) => {
    const { file, meta } = fileData;

    if (meta.status === 'done') {
      const formattedImage = await handleUpload(file);
      const photo_metadata = await imageMetadata(formattedImage);

      const formData = new FormData();

      formData.append('image', formattedImage);

      onAddImage({ file: formData, fileId: meta.previewUrl, photo_metadata });
    }

    if (meta.status === 'removed') {
      // Remove file object from reducer state using fileID as key
      onDeleteImage(meta.previewUrl);
    }
  };

  /**
   * @description Handle 'x' button click for images in the
   * autofilled preview
   *
   * @argument {Object} e - Object of file data
   */
  const removeImageFromPreview = (e) => {
    // Remove file object from reducer state using source url as key
    onDeleteImage(e.target.name);
  };

  /**
   * @description Max number of files that can be uploaded should be 4
   * If some images are already auto-filled from API, file
   * count should allow to upload only the remaining number
   */
  const getMaxFileCount = () =>
    data.length ? MAX_IMAGE_UPLOADS - data.length : MAX_IMAGE_UPLOADS;

  /**
   * @description Renders an image preview which is displayed on the form
   */
  const renderPreview = (image, index) => (
    <ImagePreview
      key={`image-preview-${image.url}-${index}`}
      imageUrl={image.url}
      onRemove={removeImageFromPreview}
      largePreview={largePreview}
    />
  );

  /**
   * @description fires when a photo credit input changes
   *
   * @argument {Object} e - event object
   */
  const photoInputHandler = (e, i, type) => {
    // Deep copy of photo data to update redux with
    const photoDataStateTemp = deepCopy(data);

    if (type === 'source') photoDataStateTemp[i].photo_credits = e.target.value;
    if (type === 'meta') photoDataStateTemp[i].photo_metadata = e.target.value;

    onEvent(photoDataStateTemp);
  };

  /**
   * @description Renders a collection of image previews which is
   * displayed on the form. If images have already been uploaded
   * and are coming from API, render their previews
   */
  const imagePreviews =
    Boolean(data.length) &&
    prepPhotoData(data).map((image, i) => (
      <Draggable key={`image-draggable-${image.url}-${i}`}>
        {renderPreview(image, i)}
        <div
          className="photo-preview-input-wrapper"
          key={`image-credit-draggable-wrapper-div-${image.url}`}
        >
          <div className="photo-preview-input">
            <Input
              key={`credit-input-${image.thumb_url}`}
              type="text"
              className={`photo-credit-input-${i}`}
              index={i}
              name={`photo-credit-image-${i}`}
              value={image.photo_credits || ''}
              onChange={(e) => photoInputHandler(e, i, 'source')}
              placeholder={image.photo_credits ? image.photo_credits : 'Source'}
              maxLength={50}
            />
          </div>
        </div>
        <div
          className="photo-preview-input-wrapper"
          key={`image-metadata-draggable-wrapper-div-${image.url}`}
        >
          <div className="photo-preview-input">
            <Input
              key={`metadata-input-${image.thumb_url}`}
              type="text"
              className={`photo-credit-input-${i}`}
              index={i}
              name={`photo-credit-image-${i}`}
              value={image.photo_metadata || ''}
              onChange={(e) => photoInputHandler(e, i, 'meta')}
              placeholder="Metadata"
            />
          </div>
        </div>
      </Draggable>
    ));

  /**
   * @description Renders layout with additional props
   *
   * @argument {Object} formerProps
   */
  const getLayoutWithExtraProps = (formerProps) => (
    <div className="layout">
      <Layout
        {...formerProps}
        failedUploads={failedUploads}
        getMaxFileCount={getMaxFileCount()}
        isPreviewRequired={isDropZonePreviewRequired}
        largePreview={largePreview}
      />
    </div>
  );

  /**
   * @description Displays errors
   */
  const errors = Boolean(failedUploads.length) && (
    <div className="upload-error">Upload failed</div>
  );

  return (
    <div className="file-uploader">
      <div className="preview-heading">{title}</div>
      {errors}
      <div className="uploader-content">
        <Container onDrop={onDrop} orientation="horizontal">
          {imagePreviews}
        </Container>
        <Dropzone
          onChangeStatus={handleChangeStatus}
          accept="image/*"
          canCancel={false}
          LayoutComponent={getLayoutWithExtraProps}
          inputContent=""
          styles={{
            dropzoneActive: { borderColor: 'green' },
          }}
        />
      </div>
      <div className="error">{error}</div>
    </div>
  );
};

// --------------------------------------------------------------------------|
//                      PropTypes Check - FileUploader
// --------------------------------------------------------------------------|
FileUploader.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  failedUploads: PropTypes.array,
  onAddImage: PropTypes.func,
  onEvent: PropTypes.func,
  onDeleteImage: PropTypes.func,
  onDrop: PropTypes.func.isRequired,
  error: PropTypes.string,
  isDropZonePreviewRequired: PropTypes.bool.isRequired,
  largePreview: PropTypes.bool,
};

// --------------------------------------------------------------------------|
//                        Default Props - FileUploader
// --------------------------------------------------------------------------|
FileUploader.defaultProps = {
  data: [],
  title: 'Card Images',
  failedUploads: [],
  onAddImage: () => {},
  onDeleteImage: () => {},
  error: '',
  largePreview: false,
};

// --------------------------------------------------------------------------|
//                          Export - FileUploader
// --------------------------------------------------------------------------|
export default memo(FileUploader);
