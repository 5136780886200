/**
 * ************************************
 *
 * @module  MoviesList.js
 * @author  Matt P
 * @date    12/24/2020
 * @description Movieslist functional component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                    Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  MovieFetchedDetails,
  InfiniteScroller,
  LoadingFetchedCard,
} from 'components';

import { getImageURL, getDateBasedOnStatus } from 'utils/utils';

import { GENERIC } from 'constants.js';

// ----------------------------------------------------------------------------|
//                    React Function Component - MoviesList
// ----------------------------------------------------------------------------|
const MoviesList = (props) => {
  const {
    data,
    hasMore,
    onMoreData,
    onViewData,
    onMenuClicked,
    onCheckBoxSelected,
    isChecked,
    fromFetched,
    hideMenu,
    fetchingData,
  } = props;

  const {
    SUB_MENU: { EDIT },
  } = GENERIC;

  /**
   * @description limits the characters for a movie
   * description to limit card height
   *
   * @param {String} desc - movie description string
   * @param {Number} limit - number of characters
   *
   * @returns {String} - limited by char limit
   */
  const descriptionLimiter = (str, limit) => {
    if (str.length <= limit) return str;

    let slicedString = str.slice(0, limit);

    slicedString += '...';

    return slicedString;
  };

  /**
   * @description generates fetched data cards
   *
   * @param {Object} place - object of movie data
   *
   * @returns {JSX} - of MovieFetchedDetail
   */
  const generateFetchedData = (movie) => {
    const {
      id,
      images,
      main_categories,
      movie_production_status,
      release_date,
      title,
      original_language,
      rating,
      runtime,
    } = movie;

    const imageURL = getImageURL(images);

    return (
      <MovieFetchedDetails
        key={id}
        id={id}
        imageURL={imageURL}
        categories={main_categories}
        name={title}
        language={original_language}
        runtime={runtime}
        rating={rating}
        status={movie_production_status}
        releaseDate={release_date}
        onReviewClick={() => onMenuClicked(EDIT, movie)}
      />
    );
  };

  /**
   * @description generates card component
   *
   * @param {Array} movieListData - Array of movie objects
   *
   * @returns {JSX} - with Card components
   */
  const generateCards = (movieListData) => {
    let res = null;

    if (Array.isArray(movieListData)) {
      res = movieListData.map((movie) => {
        const {
          id,
          published_date: publishedDate,
          modified: modifiedDate,
          status,
          images,
          title,
          phrase,
          last_admin_review,
        } = movie;

        const imageURL = getImageURL(images);

        return (
          <Card
            key={id}
            id={id}
            className="card"
            onMenuClick={(value) => onMenuClicked(value, movie)}
            onCheck={onCheckBoxSelected}
            onClick={() => onMenuClicked(EDIT, movie)}
            isChecked={isChecked}
            hideMenu={hideMenu}
            date={getDateBasedOnStatus(status, modifiedDate, publishedDate)}
            status={status}
            imageUrl={imageURL}
            title={title}
            phrase={descriptionLimiter(phrase, 175)}
            hideCheckBox
            adminReview={last_admin_review}
          />
        );
      });
    }

    return <div className="card-container">{res}</div>;
  };

  // switch var for if data is fromFetched or another tab
  let content = null;

  if (fromFetched) {
    content = data.map((movie) => generateFetchedData(movie));
  } else {
    content = generateCards(data);
  }

  return (
    <section className="movie-list">
      <InfiniteScroller
        loadMore={onMoreData}
        hasMore={hasMore}
        useWindow={!hideMenu}
      >
        {content}
      </InfiniteScroller>
      {fetchingData ? <LoadingFetchedCard /> : ''}
    </section>
  );
};

// ----------------------------------------------------------------------------|
//                         PropTypes Check - MoviesList
// ----------------------------------------------------------------------------|
MoviesList.propTypes = {
  data: PropTypes.array.isRequired,
  onMoreData: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  onViewData: PropTypes.func,
  onMenuClicked: PropTypes.func,
  onCheckBoxSelected: PropTypes.func,
  isChecked: PropTypes.any,
  fromFetched: PropTypes.bool,
  hideMenu: PropTypes.bool,
};

// ----------------------------------------------------------------------------|
//                          DefaultProps - MoviesList
// ----------------------------------------------------------------------------|
MoviesList.defaultProps = {
  onViewData: () => {},
  onMenuClicked: () => {},
  onCheckBoxSelected: () => {},
  fromFetched: true,
  isChecked: null,
  hideMenu: false,
};

// ----------------------------------------------------------------------------|
//                               MoviesList Export
// ----------------------------------------------------------------------------|
export default MoviesList;
