/**
 * ************************************
 *
 * @module  EventDateSelectorUtils.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Validates if dates are overlapping
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                  Imports
// ---------------------------------------------------------------------------|
import moment from 'moment';

import { convertArrayToBooleanMap, weekIndexOfMonth } from 'utils/utils';
import { DATE_SELECTOR_POPUP } from 'constants.js';
import {
  REPEAT_MODE,
  AVAILABILITY_OPTIONS,
} from './EventDateSelector.constants';

const { TIME_FORMAT } = DATE_SELECTOR_POPUP;
const { DAILY, WEEKLY, MONTHLY } = REPEAT_MODE;
const { MONTHLY_ON_DATE, MONTHLY_WEEKDAY } = AVAILABILITY_OPTIONS;

const areDatesSame = (dateRangeA, dateRangeB) => {
  const momentStartDateA = moment(dateRangeA.startDate).startOf('day');
  const momentStartDateB = moment(dateRangeB.startDate).startOf('day');
  return momentStartDateA.isSame(momentStartDateB);
};

const areTimeRangesOverlapping = (dateRangeA, dateRangeB) => {
  const fromA = moment(dateRangeA.fromTime, TIME_FORMAT);
  const toA = dateRangeA.toTime
    ? moment(dateRangeA.toTime, TIME_FORMAT)
    : moment('11:59 PM', TIME_FORMAT);

  const fromB = moment(dateRangeB.fromTime, TIME_FORMAT);
  const toB = dateRangeB.toTime
    ? moment(dateRangeB.toTime, TIME_FORMAT)
    : moment('11:59 PM', TIME_FORMAT);
  return !(toA.isSameOrBefore(fromB) || fromA.isSameOrAfter(toB));
};

const areDateRangesOverlapping = (dateRangeA, dateRangeB) => {
  const startA = moment(dateRangeA.startDate).startOf('day');
  const endA = moment(dateRangeA.endDate).startOf('day');
  const startB = moment(dateRangeB.startDate).startOf('day');
  const endB = moment(dateRangeB.endDate).startOf('day');
  return !(endA.isSameOrBefore(startB) || startA.isSameOrAfter(endB));
};

const areWeekDaysOverlapping = (recurringDateRange, nonRecurringDateRange) => {
  const { selectedWeekDays } = recurringDateRange;
  const weekDaysMap = convertArrayToBooleanMap(selectedWeekDays, true);

  if (areDatesSame(recurringDateRange, nonRecurringDateRange)) {
    const startDay = moment(recurringDateRange.startDate)
      .format('dddd')
      .toLowerCase();
    weekDaysMap[startDay] = true;
  }
  const weekDay = moment(nonRecurringDateRange.startDate)
    .format('dddd')
    .toLowerCase();
  if (weekDaysMap[weekDay]) {
    return areTimeRangesOverlapping(recurringDateRange, nonRecurringDateRange);
  }
  return false;
};

const areDatesOverlappingMonthly = (
  recurringDateRange,
  nonRecurringDateRange
) => {
  if (recurringDateRange.availability.value === MONTHLY_ON_DATE.value) {
    if (
      moment(recurringDateRange).date() === moment(nonRecurringDateRange).date()
    ) {
      return areTimeRangesOverlapping(
        recurringDateRange,
        nonRecurringDateRange
      );
    }
  } else if (recurringDateRange.availability.value === MONTHLY_WEEKDAY.value) {
    const weekIndexA = weekIndexOfMonth(recurringDateRange.startDate);
    const weekDayA = recurringDateRange.startDate.format('dddd');
    const weekIndexB = weekIndexOfMonth(nonRecurringDateRange.startDate);
    const weekDayB = nonRecurringDateRange.startDate.format('dddd');
    if (weekIndexA === weekIndexB && weekDayA === weekDayB) {
      return areTimeRangesOverlapping(
        recurringDateRange,
        nonRecurringDateRange
      );
    }
  }
  return false;
};

const isStartDateLiesInRange = (start, rangeStart, rangeEnd) => {
  const momentStart = moment(start).startOf('day');
  const momentRangeStart = moment(rangeStart).startOf('day');
  const momentRangeEnd = moment(rangeEnd).startOf('day');

  return (
    momentStart.isSameOrAfter(momentRangeStart) &&
    start.isSameOrBefore(momentRangeEnd)
  );
};

const hasCommonWeekDays = (selectedWeekDaysA = [], selectedWeekDaysB = []) => {
  const weekDayMap = convertArrayToBooleanMap(selectedWeekDaysA, true);
  return selectedWeekDaysB.some((day) => weekDayMap[day]);
};

const areDatesOverlapping = (dateRangeA, dateRangeB) => {
  // Case 1 - Both dates are non-recurring
  if (!dateRangeA.isRecurring && !dateRangeB.isRecurring) {
    if (areDatesSame(dateRangeA, dateRangeB)) {
      return areTimeRangesOverlapping(dateRangeA, dateRangeB);
    }
    return false;
  }

  if (dateRangeA.isRecurring && dateRangeB.isRecurring) {
    // Case 2 - Both dates are recurring!
    if (areDateRangesOverlapping(dateRangeA, dateRangeB)) {
      switch (dateRangeA.repeatMode.value) {
        case DAILY:
          return areTimeRangesOverlapping(dateRangeA, dateRangeB);
        case WEEKLY: {
          switch (dateRangeB.repeatMode.value) {
            case DAILY:
              return areTimeRangesOverlapping(dateRangeA, dateRangeB);
            case WEEKLY: {
              if (
                hasCommonWeekDays(
                  dateRangeA.selectedWeekDays,
                  dateRangeB.selectedWeekDays
                )
              ) {
                return areTimeRangesOverlapping(dateRangeA, dateRangeB);
              }
              return false;
            }
            case MONTHLY: {
              const weekDayMap = convertArrayToBooleanMap(
                dateRangeA.selectedWeekDays,
                true
              );
              if (dateRangeB.availability.value === MONTHLY_ON_DATE.value) {
                for (
                  let date = moment(dateRangeB.startDate).startOf('day');
                  date.isSameOrBefore(
                    moment(dateRangeB.endDate).startOf('day')
                  );
                  date.add(1, 'M')
                ) {
                  if (weekDayMap[date.format('dddd').toLowerCase()]) {
                    return areTimeRangesOverlapping(dateRangeA, dateRangeB);
                  }
                }
                return false;
              }
              if (dateRangeB.availability.value === MONTHLY_WEEKDAY.value) {
                const weekDay = moment(dateRangeB.startDate)
                  .format('dddd')
                  .toLowerCase();
                if (weekDayMap[weekDay]) {
                  return areTimeRangesOverlapping(dateRangeA, dateRangeB);
                }
                return false;
              }
              return false;
            }
            default:
              return false;
          }
        }
        case MONTHLY: {
          switch (dateRangeB.repeatMode.value) {
            case DAILY:
              return areTimeRangesOverlapping(dateRangeA, dateRangeB);
            case WEEKLY: {
              if (
                hasCommonWeekDays(
                  dateRangeA.selectedWeekDays,
                  dateRangeB.selectedWeekDays
                )
              ) {
                return areTimeRangesOverlapping(dateRangeA, dateRangeB);
              }
              return false;
            }
            case MONTHLY: {
              if (dateRangeA.availability.value === MONTHLY_ON_DATE.value) {
                if (dateRangeB.availability.value === MONTHLY_ON_DATE.value) {
                  if (
                    dateRangeA.startDate.date() === dateRangeB.startDate.date()
                  ) {
                    return areTimeRangesOverlapping(dateRangeA, dateRangeB);
                  }
                  return false;
                }
                let dateItr = moment(dateRangeB.startDate).startOf('day');
                while (
                  dateItr.isSameOrBefore(dateRangeB.endDate.startOf('day'))
                ) {
                  if (dateItr.date() === dateRangeA.startDate.date()) {
                    return areTimeRangesOverlapping(dateRangeA, dateRangeB);
                  }
                  dateItr = dateItr.date(1).add(1, 'M');
                  const weekDay = dateRangeB.startDate.format('dddd');
                  const weekIndex = weekIndexOfMonth(dateRangeB.startDate);
                  while (dateItr.format('dddd') !== weekDay) {
                    dateItr.add(1, 'd');
                  }
                  dateItr = dateItr.add(weekIndex - 1, 'week');
                }
                return false;
              }
              if (dateRangeA.availability.value === MONTHLY_WEEKDAY.value) {
                if (dateRangeB.availability.value === MONTHLY_WEEKDAY.value) {
                  const weekDayA = dateRangeA.startDate.format('dddd');
                  const weekDayB = dateRangeB.startDate.format('dddd');
                  const weekIndexA = weekIndexOfMonth(dateRangeA.startDate);
                  const weekIndexB = weekIndexOfMonth(dateRangeB.startDate);
                  if (weekDayA === weekDayB && weekIndexA === weekIndexB) {
                    return areTimeRangesOverlapping(dateRangeA, dateRangeB);
                  }
                } else {
                  let dateItr = moment(dateRangeA.startDate).startOf('day');
                  while (
                    dateItr.isSameOrBefore(dateRangeA.endDate.startOf('day'))
                  ) {
                    if (dateItr.date() === dateRangeB.startDate.date()) {
                      return areTimeRangesOverlapping(dateRangeA, dateRangeB);
                    }
                    dateItr = dateItr.date(1).add(1, 'M');
                    const weekDay = dateRangeA.startDate.format('dddd');
                    const weekIndex = weekIndexOfMonth(dateRangeA.startDate);
                    while (dateItr.format('dddd') !== weekDay) {
                      dateItr.add(1, 'd');
                    }
                    dateItr = dateItr.add(weekIndex - 1, 'week');
                  }
                }
              } else {
                return false;
              }
              return false;
            }
            default:
              return false;
          }
        }
        default:
          return false;
      }
    }
  } else {
    // Case 3 - One of the two dates is recurring
    let recurringDateRange;
    let nonRecurringDateRange;
    if (dateRangeA.isRecurring && !dateRangeB.isRecurring) {
      recurringDateRange = dateRangeA;
      nonRecurringDateRange = dateRangeB;
    } else {
      recurringDateRange = dateRangeB;
      nonRecurringDateRange = dateRangeA;
    }

    if (
      isStartDateLiesInRange(
        nonRecurringDateRange.startDate,
        recurringDateRange.startDate,
        recurringDateRange.endDate
      )
    ) {
      switch (recurringDateRange.repeatMode.value) {
        case DAILY:
          return areTimeRangesOverlapping(
            recurringDateRange,
            nonRecurringDateRange
          );
        case WEEKLY:
          return areWeekDaysOverlapping(
            recurringDateRange,
            nonRecurringDateRange
          );
        case MONTHLY:
          return areDatesOverlappingMonthly(
            recurringDateRange,
            nonRecurringDateRange
          );
        default:
          return false;
      }
    }
    return false;
  }
};

export default areDatesOverlapping;
