/**
 * ************************************
 *
 * @module  Cities.js
 * @author  Matt P
 * @date    04/25/2022
 * @description Container for the Cities Settings tab
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { historyDataType, locationDataType } from 'utils/typeUtils';

import { toast } from 'react-toastify';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getCitiesListAction,
  toggleEnable as toggleEnableAction,
  deleteCityAction,
  clearListAction,
  createCitiesAction,
  setCityToEditAction,
  clearCityToEditAction,
} from 'store/actions/Cities.action';

import {
  CardBanner,
  CobTable,
  DeleteModal,
  Loader,
  NoDataFound,
  ThirdPartySearchModal,
} from 'components';

import { CITIES } from 'constants.js';

import {
  cityCols,
  updateMapper,
  determineToggleAvailablity,
} from './CitiesUtils';

import City from './City/City';

import './Cities.scss';

// ----------------------------------------------------------------------------|
//                        Redux - Property Mapping
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  getCitiesList: () => dispatch(getCitiesListAction()),
  createCity: (data) => dispatch(createCitiesAction(data)),
  clearCitiesList: () => dispatch(clearListAction()),
  toggleEnable: (city, parentCityId) =>
    dispatch(toggleEnableAction(city, parentCityId)),
  deleteCity: (id) => dispatch(deleteCityAction(id)),
  setCityToEdit: (city) => dispatch(setCityToEditAction(city)),
  clearCityToEdit: () => dispatch(clearCityToEditAction()),
});

const mapStateToProps = (state) => ({
  cities: state.cities.cities,
  cityToEdit: state.cities.cityToEdit,
  initialDataLoaded: state.cities.initialDataLoaded,
});

// ----------------------------------------------------------------------------|
//                   React Class Component - Categories
// ----------------------------------------------------------------------------|
class Cities extends Component {
  // --------------------------------------------------------------------------|
  //                            PropTypes Check
  // --------------------------------------------------------------------------|
  static propTypes = {
    cities: PropTypes.array.isRequired,
    initialDataLoaded: PropTypes.bool.isRequired,
    getCitiesList: PropTypes.func.isRequired,
    clearCitiesList: PropTypes.func.isRequired,
    createCity: PropTypes.func.isRequired,
    toggleEnable: PropTypes.func.isRequired,
    setCityToEdit: PropTypes.func.isRequired,
    cityToEdit: PropTypes.oneOf([PropTypes.number, locationDataType]),
    clearCityToEdit: PropTypes.func.isRequired,
    deleteCity: PropTypes.func.isRequired,
    history: historyDataType.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      pageTitle: CITIES.TITLE,
      showModal: false,
      deleteCityData: null,
      showDeleteModal: false,
      showEditModal: false,
    };
  }

  // --------------------------------------------------------------------------|
  //                            Lifecycle Methods
  // --------------------------------------------------------------------------|
  componentDidMount() {
    const { getCitiesList, history } = this.props;

    getCitiesList();

    this.unlisten = history.listen(() => {
      this.handleIDPathname();
    });
  }

  componentWillUnmount() {
    const { clearCitiesList } = this.props;

    clearCitiesList();
    this.unlisten();
  }

  // --------------------------------------------------------------------------|
  //                      Component Methods - Cities
  // --------------------------------------------------------------------------|
  handleIDPathname = () => {
    const { history, clearCityToEdit } = this.props;

    // if a user hits browser back on the edit modal, close it
    if (history.action === 'POP') {
      this.setState({ showEditModal: false });
      clearCityToEdit();
    }
  };

  /**
   * @description Closes the create new modal by toggling state
   */
  closeModal = () => {
    this.setState({ showModal: false });
  };

  /**
   * @description Closes edit modal by toggling state
   */
  closeEditModal = () => {
    const { history, clearCityToEdit } = this.props;

    history.goBack();

    this.setState({ showEditModal: false });
    clearCityToEdit();
  };

  /**
   * @description opens create new modal by toggling state
   */
  openModal = () => {
    this.setState({ showModal: true });
  };

  /**
   * @description opens edit modal by toggling state
   *
   * @param {Object} city
   */
  showEditModal = (city) => {
    const { history, setCityToEdit } = this.props;

    history.push('/settings/cities/edit');

    setCityToEdit(city);
    this.setState({ showEditModal: true });
  };

  /**
   * @description opens modal to create new filter by toggling state
   */
  createNewCity = () => {
    this.openModal();
  };

  /**
   * @description Take operation type, filter. Prepare SAGA action data.
   *
   * @param operation ['DELETE', 'ENABLE']
   * @param city
   */
  buildSagaData = (operation, city) => {
    const key = updateMapper[operation];

    const apiData = {
      ...{ id: city.id },
      ...{ name: city.name },
      ...(key && { [key]: !city[key] }),
    };

    return { city, apiData };
  };

  /**
   * @description Toggles the enabling and disabling of a city
   *
   * @param city
   */
  toggleEnable = (city, parentCityId) => {
    const { toggleEnable } = this.props;
    const { id, name, status } = city;

    const apiData = {
      id,
      name,
      status: determineToggleAvailablity(status),
    };

    toggleEnable(apiData, parentCityId);
  };

  /**
   * @description Deletes a filter
   *
   * @param {Object} city
   */
  deleteCity = (city) => {
    const { deleteCity } = this.props;

    deleteCity(this.buildSagaData(CITIES.DELETE, city));
  };

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  Categories rendering
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  /**
   * @description Renders Modal on click
   */
  renderCreateModal = () => {
    const { showModal } = this.state;
    const { createCity } = this.props;

    return (
      <ThirdPartySearchModal
        showModal={showModal}
        closeModal={this.closeModal}
        verticalType="city"
        onSubmit={(data) => {
          createCity(data);
        }}
      />
    );
  };

  /**
   * @description Renders Edit Modal on click
   */
  renderEditModal = () => {
    const { showEditModal } = this.state;

    return (
      <City
        showModal={showEditModal}
        closeModal={this.closeEditModal}
        toggleEnable={this.toggleEnable}
      />
    );
  };

  /**
   * @description Renders Delete Modal on click
   */
  renderDeleteModal = () => {
    const { deleteCityData } = this.state;

    return (
      <DeleteModal
        cancelClick={() =>
          this.setState({ showDeleteModal: false, deleteCityData: null })
        }
        deleteClick={() => {
          const data = deleteCityData;

          this.deleteCity(data);
          this.setState({ deleteCityData: null, showDeleteModal: false });
        }}
        showHeader={false}
      />
    );
  };

  /**
   * @description Renders content on load
   */
  renderContent = () => {
    const { cities, initialDataLoaded } = this.props;

    const NO_DATA = !Object.keys(cities).length;

    if (initialDataLoaded) {
      if (NO_DATA) {
        return <NoDataFound subMessage={CITIES.NO_DATA} />;
      }

      return (
        <CobTable
          rows={cities}
          cols={cityCols}
          toggleFeatured={(city) => this.toggleFeature(city)}
          toggleEnable={(city) => this.toggleEnable(city)}
          showEditModal={(city) => this.showEditModal(city)}
          deleteCategory={(city) => {
            this.setState({
              deleteCityData: city,
              showDeleteModal: true,
            });
          }}
        />
      );
    }

    return <Loader />;
  };

  render() {
    const { cityToEdit } = this.props;
    const { pageTitle, showModal, showEditModal, showDeleteModal } = this.state;

    return (
      <div className="cities-container">
        <CardBanner
          isDropdownEnabled={false}
          isSearchEnabled={false}
          title={pageTitle}
          newBtnClick={this.createNewCity}
        />
        {this.renderContent()}
        {showModal && this.renderCreateModal()}
        {showEditModal && cityToEdit && this.renderEditModal()}
        {showDeleteModal && this.renderDeleteModal()}
      </div>
    );
  }
}
// ----------------------------------------------------------------------------|
//                  Cities Export with Redux Connect
// ----------------------------------------------------------------------------|
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cities));
