/**
 * ************************************
 *
 * @module  typeUtils.js
 * @author  Matt P
 * @date    05/04/2021 - 'May the 4th be with you'
 * @description home for the various proptypes declarations
 * Centralized for maintainability
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import {
  arrayOf,
  bool,
  exact,
  func,
  instanceOf,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';

// ----------------------------------------------------------------------------|
//                                  Types
// ----------------------------------------------------------------------------|
const categoryDataType = shape({
  cta_title_long: string,
  cta_title_short: string,
  icon: string.isRequired,
  image: shape({
    source: string,
    thumb_url: string,
    url: string,
  }),
  is_enabled: bool.isRequired,
  is_featured: bool.isRequired,
  items_count: number,
  matches_count: number,
  name: string.isRequired,
  parent: string,
  parent_categories: arrayOf(
    shape({
      id: string,
    })
  ),
  parent_slug: string,
  should_display_in_filters: bool.isRequired,
  unique_slug: string.isRequired,
});

const arrayOfCategoryDataTypes = arrayOf(categoryDataType);

const historyDataType = shape({
  action: string,
  block: func,
  createHref: func,
  go: func,
  goBack: func,
  goForward: func,
  length: number,
  listen: func,
  location: shape({
    hash: string,
    pathname: string,
    search: string,
  }),
  push: func,
  replace: func,
});

const locationDataType = shape({
  hash: string,
  pathname: string,
  search: string,
});

const neighborhoodDataType = shape({
  boundaries_coordinates: shape({
    type: string,
  }),
  center_coordinates: shape({
    type: string,
  }),
  county: string,
  county_slug: string,
  name: string.isRequired,
  should_display_in_filters: bool.isRequired,
  unique_slug: string.isRequired,
});

const categoryPropsDataType = shape({
  initialDataLoaded: bool,
  cardCategories: arrayOf(string),
  adminCategories: arrayOfCategoryDataTypes,
  placeCategories: arrayOfCategoryDataTypes,
  movieCategories: arrayOfCategoryDataTypes,
  movieRatings: arrayOfCategoryDataTypes,
  tvRatings: arrayOfCategoryDataTypes,
  vibes: arrayOfCategoryDataTypes,
  recreationCategories: arrayOfCategoryDataTypes,
  recipeCategories: arrayOfCategoryDataTypes,
  mealTypes: arrayOfCategoryDataTypes,
  diet: arrayOfCategoryDataTypes,
});
// ----------------------------------------------------------------------------|
//                                 Exports
// ----------------------------------------------------------------------------|
export {
  arrayOfCategoryDataTypes,
  categoryDataType,
  categoryPropsDataType,
  historyDataType,
  locationDataType,
  neighborhoodDataType,
};
