/**
 * ************************************
 *
 * @module  CategoryUtils.js
 * @author  Matt P
 * @date    02/26/2022
 * @description category manipulation specific utilities
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import currentEnv from 'utils/EnvironmentSpecificValues';
import { qualifyArrayRendering } from './utils';

// ----------------------------------------------------------------------------|
//                                 Utilities
// ----------------------------------------------------------------------------|
const createCategoryMap = (categoryArray = []) =>
  categoryArray.reduce((acc, curr) => {
    if (!acc[curr.unique_slug]) acc[curr.unique_slug] = curr;

    return acc;
  }, {});

const createCategoryUniqueSlugSet = (categoryArray = []) =>
  categoryArray.reduce((acc, curr) => {
    acc.add(curr.unique_slug);

    return acc;
  }, new Set());

/**
 * @description is used to organize categories by types
 *
 * @param {Object[]} categories
 *
 * @returns {Object} Extract Child Categories
 */
const extractCategoryTypes = (categories = []) => {
  const { parentSlugs, uniqueSlugs, recipeMealSlugs } = currentEnv;
  const {
    vibes,
    movieTv,
    movieRating,
    tvRating,
    recreation,
    cookAndBake,
    recipes,
    holiday,
    diet,
    perfectFor,
    mealType,
    noteworthy,
  } = parentSlugs;
  // import the list of curr misc slugs via environment
  // set allows us to dole them out
  const miscOptionsList = new Set(Object.values(uniqueSlugs));
  const recipeMealTypes = new Set(Object.values(recipeMealSlugs));
  const excludedParentSlugs = new Set(Object.values(parentSlugs));

  const catTypes = {
    categoryTagOptions: [],
    child: [],
    places: [],
    vibes: [],
    misc: [], // miscOptions that are also categories
    movies: [], // Movie and TV genre
    movieRatings: [],
    tvRatings: [],
    recreation: [],
    mealTypes: [],
    recipes: [],
    diet: [],
    perfectFor: [],
    noteworthy: [],
  };

  categories.forEach((cat = {}) => {
    const { unique_slug, parent_slug, parent_categories } = cat;

    // for places and events, children only
    if (unique_slug !== parent_slug) catTypes.child.push(cat);

    if (!miscOptionsList.has(unique_slug))
      catTypes.categoryTagOptions.push(cat);

    // the cats that come from the admin list are different than
    // the ones that are present on a cards categories. This is so the algo
    // works with both. however the parent_categories array from the direct
    // categories API request will take priority as cats can have multiple
    // parents
    if (
      !miscOptionsList.has(unique_slug) &&
      parent_categories &&
      Array.isArray(parent_categories)
    ) {
      // used to classify places and events cats which
      // should not include the parent slugs below
      let count = 0;

      for (let i = 0; i < parent_categories.length; i += 1) {
        // vibes
        if (parent_categories[i].unique_slug === vibes) {
          catTypes.vibes.push(cat);
          count += 1;
        }
        // movie genres
        if (parent_categories[i].unique_slug === movieTv) {
          catTypes.movies.push(cat);
          count += 1;
        }
        // movie ratings
        if (parent_categories[i].unique_slug === movieRating) {
          catTypes.movieRatings.push(cat);
          count += 1;
        }
        // tv ratings
        if (parent_categories[i].unique_slug === tvRating) {
          catTypes.tvRatings.push(cat);
          count += 1;
        }
        // recreation/activities
        if (parent_categories[i].unique_slug === recreation) {
          catTypes.recreation.push(cat);
          // count += 1;
        }
        // recipes ('recipes' and 'cook and bake')
        if (
          parent_categories[i].unique_slug === recipes ||
          parent_categories[i].unique_slug === cookAndBake
        ) {
          catTypes.recipes.push(cat);
          count += 1;
        }
        // diet (recipes)
        if (parent_categories[i].unique_slug === diet) {
          catTypes.diet.push(cat);
          count += 1;
        }
        // meal type (recipes)
        if (parent_categories[i].unique_slug === mealType) {
          catTypes.mealTypes.push(cat);
          count += 1;
        }
        // perfectFor
        if (parent_categories[i].unique_slug === perfectFor) {
          catTypes.perfectFor.push(cat);
          // count += 1;
        }
        // perfectFor
        if (parent_categories[i].unique_slug === noteworthy) {
          catTypes.noteworthy.push(cat);
          // count += 1;
        }

        if (parent_categories[i].unique_slug === holiday) {
          catTypes.movies.push(cat);
          catTypes.recreation.push(cat);
          catTypes.recipes.push(cat);
          catTypes.places.push(cat);
          count += 1;
        }
      }

      // if none of the for..loop conditionals hit, push that cat
      // to places (used for events as well)
      if (count === 0) catTypes.places.push(cat);
    }
    // for when we are separating cats that come from a card details
    // which do not have a parent_categories array
    else if (!parent_categories && !miscOptionsList.has(unique_slug)) {
      // for places and events
      if (
        unique_slug !== parent_slug &&
        !excludedParentSlugs.has(parent_slug)
        // &&
        // !recipeMealTypes.has(unique_slug)
      ) {
        catTypes.places.push(cat);
      }

      if (parent_slug === holiday) {
        catTypes.places.push(cat);
        catTypes.movies.push(cat);
        catTypes.recreation.push(cat);
        catTypes.recipes.push(cat);
      }

      if (parent_slug === vibes) catTypes.vibes.push(cat);
      if (parent_slug === movieTv) catTypes.movies.push(cat);
      if (parent_slug === movieRating) catTypes.movieRatings.push(cat);
      if (parent_slug === tvRating) catTypes.tvRatings.push(cat);
      if (parent_slug === recreation) {
        catTypes.places.push(cat);
        catTypes.recreation.push(cat);
      }
      if (parent_slug === recipes) catTypes.recipes.push(cat);
      if (parent_slug === diet) catTypes.diet.push(cat);
    }

    // Grouped based on specific slugs
    if (miscOptionsList.has(unique_slug)) catTypes.misc.push(cat);

    // if (recipeMealTypes.has(unique_slug)) catTypes.mealTypes.push(cat);

    if (unique_slug === recreation) catTypes.recreation.push(cat);
    if (unique_slug === movieTv) catTypes.movies.push(cat);
    if (unique_slug === cookAndBake) catTypes.recipes.push(cat);
  });

  return catTypes;
};

const findVerticalCategoryByMainAndNoteworthy = (
  mainCatSlug,
  noteworthyCatSlug,
  childLinks = {}
) => {
  if (mainCatSlug === undefined || noteworthyCatSlug === undefined)
    return false;

  const parentLink = Object.entries(childLinks) || [];
  if (!qualifyArrayRendering(parentLink)) return false;

  const findParent = parentLink.find((link) => link[1][mainCatSlug]);
  if (findParent === undefined) return false;

  const childLink = Object.entries(findParent[1]) || [];
  if (!qualifyArrayRendering(childLink)) return false;

  const findChild = childLink.find((link) => link[1][noteworthyCatSlug]);
  if (findChild === undefined) return false;

  const findVerticalCategory = parentLink.find(
    (link) =>
      childLinks[link[0]] &&
      childLinks[link[0]][findChild[0]] &&
      childLinks[link[0]][findChild[0]][noteworthyCatSlug]
  );

  return findVerticalCategory ? findVerticalCategory[0] : false;
};

const findValidMainCategoryParent = (mainCatSlug, childLinks = {}) => {
  if (mainCatSlug === undefined) return false;

  const parentLink = Object.entries(childLinks) || [];
  if (!qualifyArrayRendering(parentLink)) return false;

  const mainCategoryParent = parentLink.find(
    (link) => childLinks[link[0]] && childLinks[link[0]][mainCatSlug]
  );

  return mainCategoryParent ? mainCategoryParent[0] : false;
};

const mapCategoriesByUniqueSlug = (slugArray = [], categoryMap = {}) =>
  slugArray.map((uniqueSlug) => categoryMap[uniqueSlug]);

const mapChildLinks = (
  parentsArray = [],
  categoryArray = [],
  categoryMap = {}
) => {
  const grandParentsSet = createCategoryUniqueSlugSet(parentsArray);
  const parentsSet = new Set();
  const childSet = new Set();
  // find the children of the first grandparents/first order and add them to the
  // parentSet unique values.
  categoryArray.forEach((category) => {
    const { is_enabled, parent_categories, unique_slug } = category || {};

    if (is_enabled && qualifyArrayRendering(parent_categories)) {
      parent_categories.forEach((cat) => {
        const { unique_slug: parent_unique_slug } = cat || {};

        if (grandParentsSet.has(parent_unique_slug))
          parentsSet.add(unique_slug);
      });
    }
  });
  // now that we have the parents unique_slug set, loop through the entire
  // category array again and find the children of the parents/second order.
  // These are for the noteworthy tag select, and also need to be a child of
  // noteworthy as well.
  categoryArray.forEach((category) => {
    const { is_enabled, parent_categories, unique_slug } = category || {};

    if (
      is_enabled &&
      qualifyArrayRendering(parent_categories) &&
      parent_categories.find(
        (arr) => arr.unique_slug === currentEnv.parentSlugs.noteworthy
      )
    ) {
      parent_categories.forEach((cat) => {
        const { unique_slug: parent_unique_slug } = cat || {};

        if (parentsSet.has(parent_unique_slug)) childSet.add(unique_slug);
      });
    }
  });

  // Now that we have three orders deep of unique_slug data, rebuild
  // the relationship the opposite way.
  const parentMap = {};

  childSet.forEach((childSlug) => {
    const childObject = categoryMap[childSlug] || {};
    const { parent_categories, unique_slug } = childObject;

    if (qualifyArrayRendering(parent_categories)) {
      parent_categories.forEach((cat) => {
        const { unique_slug: parent_unique_slug } = cat || {};

        if (parentsSet.has(parent_unique_slug)) {
          if (!parentMap[parent_unique_slug]) {
            parentMap[parent_unique_slug] = {
              [unique_slug]: unique_slug,
            };
          } else {
            parentMap[parent_unique_slug][unique_slug] = unique_slug;
          }
        }
      });
    }
  });
  // add the remaining parent slugs with no applicable children so they
  // still show up as main category dropdown options
  parentsSet.forEach((parentSlug) => {
    if (!parentMap[parentSlug]) {
      parentMap[parentSlug] = {};
    }
  });

  const categoryFamilyTree = {};
  // loop over second order parent keys and add them to an object of
  // grandparent keys with child keys that include grandchildren unique_id's
  Object.keys(parentMap).forEach((parentKey) => {
    const parentObject = categoryMap[parentKey];
    const { parent_categories, unique_slug } = parentObject || {};

    if (qualifyArrayRendering(parent_categories)) {
      parent_categories.forEach((cat) => {
        const { unique_slug: parent_unique_slug } = cat || {};

        if (grandParentsSet.has(parent_unique_slug)) {
          if (!categoryFamilyTree[parent_unique_slug]) {
            categoryFamilyTree[parent_unique_slug] = {
              [unique_slug]: parentMap[parentKey],
            };
          } else {
            categoryFamilyTree[parent_unique_slug][unique_slug] =
              parentMap[parentKey];
          }
        }
      });
    }
  });

  return categoryFamilyTree;
};

const prepCatMultiDropdownOptions = (
  subsetCategoryMap = {},
  catagoryMap = {}
) =>
  Object.entries(subsetCategoryMap).map((entry) => {
    const [name, children] = entry;

    return {
      label: catagoryMap[name].name,
      value: Object.keys(children).map((childrenKeys) => ({
        label: catagoryMap[childrenKeys].name,
        value: catagoryMap[childrenKeys],
      })),
    };
  });

// ----------------------------------------------------------------------------|
//                                Exports
// ----------------------------------------------------------------------------|
export {
  createCategoryMap,
  createCategoryUniqueSlugSet,
  extractCategoryTypes,
  findValidMainCategoryParent,
  findVerticalCategoryByMainAndNoteworthy,
  mapCategoriesByUniqueSlug,
  mapChildLinks,
  prepCatMultiDropdownOptions,
};
