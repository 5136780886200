/**
 * ************************************
 *
 * @module  Events.action.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Events redux actions
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import ACTIONS from '../actionTypes';

// ----------------------------------------------------------------------------|
//                                Exports
// ----------------------------------------------------------------------------|
export const getEventsListAction = (data) => ({
  type: ACTIONS.EVENTS.GET_LIST,
  data: { ...data, is_event: true },
});

export const clearListAction = () => ({
  type: ACTIONS.EVENTS.CLEAR_LIST,
});

export const deleteEventAction = (id) => ({
  type: ACTIONS.EVENTS.DELETE_EVENT,
  data: id,
});

export const performBulkOperationAction = (data) => ({
  type: ACTIONS.EVENTS.BULK_ACTION,
  data: { ...data, is_event: true },
});

export const filterEventsListAction = (data) => ({
  type: ACTIONS.EVENTS.FILTER_LIST,
  data: { ...data, is_event: true },
});
