/**
 * ************************************
 *
 * @module  DetailedDescription.js
 * @author  Matt P
 * @date   05/26/2021
 * @description Component for rendering detailed description inputs. Additional
 * inputs are rendered via clicking on the +Add More
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import deleteIcon from 'assets/images/icn_delete.png';
import './DetailedDescription.scss';

// ----------------------------------------------------------------------------|
//                    React Function Component - DetailedDescription
// ----------------------------------------------------------------------------|
const DetailedDescription = ({
  value,
  name,
  onChange,
  className = '',
  errors,
}) => {
  const [detailedDescriptionState, setDetailedDescriptionState] = useState(
    value || []
  );

  const textInputClass = cx(['input-bar', `${className.split(' ').join(',')}`]);

  const textAreaClass = cx([
    'textarea-bar',
    `${className.split(' ').join(',')}`,
  ]);

  /**
   * @description helper function for setting local state
   * then updating the parent component on change prop
   *
   * @param {Array} newState - of arrays [str, str]
   * @param {String} fieldName - for form input update
   */
  const setStateAndOnChange = (newState, fieldName) => {
    // Sets new state via hooks
    setDetailedDescriptionState(newState);
    // fires the onChange function passed to comp
    onChange(newState, fieldName);
  };

  /**
   * @description Fires when any of the input fields change
   * updates state and fires onChange prop function
   *
   * @param {String} key - array obj key
   * @param {String} val - input val
   * @param {Number} index - index of detail
   */
  const onChangeHandler = (key, val, index) => {
    const newDetailedDescriptionState = [...detailedDescriptionState];

    newDetailedDescriptionState[index][key] = val;

    setStateAndOnChange(newDetailedDescriptionState, name);
  };

  /**
   * @description Adds input field on button click
   */
  const addInput = () => {
    const newDetailedDescriptionState = [...detailedDescriptionState];

    newDetailedDescriptionState.push({
      header: '',
      body: '',
    });

    // Sets new state via hooks
    setDetailedDescriptionState(newDetailedDescriptionState);
  };

  /**
   * @description Deletes an input row then updates state and fires
   * the onChange prop function
   *
   * @param {Number} index - index of detail
   */
  const deleteInputs = (index) => {
    const newDetailedDescriptionState = [...detailedDescriptionState];

    newDetailedDescriptionState.splice(index, 1);

    setStateAndOnChange(newDetailedDescriptionState, name);
  };

  /**
   * @description Renders input fields based on array length
   *
   * @returns {JSX} HTML components to be rendered to the DOM
   */
  const buildInputs = () =>
    detailedDescriptionState.map((field, index) => (
      <div
        className="detailed-description__input-wrapper"
        key={`detailed-description-${index}`}
      >
        <div className="detailed-description__input-column">
          <label htmlFor="section-label">Section Label</label>
          <input
            className={textInputClass}
            type="text"
            value={field.header}
            onChange={(e) => onChangeHandler('header', e.target.value, index)}
          />
        </div>
        <div className="detailed-description__text-area-column">
          <label htmlFor="section-text">Section Text</label>
          <textarea
            className={textAreaClass}
            value={field.body}
            onChange={(e) => onChangeHandler('body', e.target.value, index)}
            rows={6}
          />
        </div>
        <div className="detailed-description__input-delete">
          <button
            onClick={() => deleteInputs(index)}
            data-name="delete"
            type="button"
          >
            <img src={deleteIcon} alt="remove icon" />
          </button>
        </div>
      </div>
    ));

  return (
    <div className="detailed-description__container">
      <h2 className="detailed-description__header">
        Detailed Description Sections
      </h2>
      {buildInputs()}
      <button
        className="detailed-description__add-section"
        type="button"
        onClick={() => {
          addInput();
        }}
      >
        + Add Section
      </button>
      <div className="error">{errors}</div>
    </div>
  );
};
// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
DetailedDescription.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      body: PropTypes.string,
    })
  ),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  errors: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
DetailedDescription.defaultProps = {
  value: [],
  className: '',
  errors: '',
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default React.memo(DetailedDescription);
