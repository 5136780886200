/**
 * ************************************
 *
 * @module  TextArea.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description textarea input form field component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './TextArea.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const TextArea = ({
  value,
  name,
  placeholder,
  onChange,
  className = '',
  rows,
  ...rest
}) => {
  /**
   * @description - text input state
   */
  const [inputState, setInputState] = useState(value);

  const classNames = cx(['textarea-bar', `${className.split(' ').join(',')}`]);

  /**
   * @description - handles input change. updates form and local state
   *
   * @param {Object} e - DOM event object
   *
   * @returns {void}
   */
  const onChangeHandler = (e) => {
    setInputState(e.target.value);
    onChange(e);
  };

  return (
    <textarea
      className={classNames}
      value={inputState}
      name={name}
      placeholder={placeholder}
      onChange={onChangeHandler}
      rows={rows}
      {...rest}
    />
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
TextArea.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  rows: PropTypes.number,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
TextArea.defaultProps = {
  rows: 2,
  value: '',
  className: '',
  placeholder: '',
};

export default React.memo(TextArea);
