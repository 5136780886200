/**
 * ************************************
 *
 * @module  Auth.reducer.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description redux reducer file for authentication
 *
 * ************************************
 */

// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

import { adminSession } from 'constants.js';
import { setSession, isUserLoggedIn, logout } from 'services/AuthService';

// ----------------------------------------------------------------------------|
//                              Initial State
// ----------------------------------------------------------------------------|
const initialState = {
  name: '',
  isAuthenticated: isUserLoggedIn(adminSession),
  showWarning: false,
};

// ----------------------------------------------------------------------------|
//                               Auth Reducer
// ----------------------------------------------------------------------------|
const Auth = (state = initialState, action = {}) => {
  switch (action.type) {

    case ACTIONS.AUTH.LOGIN_IN_PROGRESS: {
      return { ...state, showWarning: false };
    }
    /**
     * @description - on successful login
     */
    case ACTIONS.AUTH.LOGIN_SUCCESS: {
      const { name } = action.data;

      setSession(action.data, adminSession);

      return { ...state, name, isAuthenticated: true };
    }

    /**
     * @description - on unsuccessful login
     */
    case ACTIONS.AUTH.LOGIN_FAIL: {
      return { ...state, isAuthenticated: false, showWarning: true };
    }

    /**
     * @description - on successful logout
     */
    case ACTIONS.AUTH.LOGOUT_SUCCESS: {
      logout(adminSession);

      return { ...initialState, isAuthenticated: false };
    }

    /**
     * @description - on unsuccessful logout
     */
    case ACTIONS.AUTH.LOGOUT_FAIL: {
      return { ...state };
    }

    default:
      return state;
  }
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default Auth;
