/**
 * ************************************
 *
 * @module VerticalDisplayUtils.js
 * @author  Matt P
 * @date    07/02/2021
 * @description helper functions specific to the VerticalDisplay container
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import moment from 'moment';

import {
  ACTIVITY,
  COLLECTION,
  DATE_FORMATS,
  DEFAULT_PAGE_SIZE,
  EVENTS,
  FLAGGED,
  MOVIE,
  PLACE,
  RECIPE,
  UNIVERSAL_SEARCH,
  VERTICAL_TYPE,
} from 'constants.js';

import {
  ActivityService,
  CollectionService,
  EventService,
  MovieService,
  PlaceService,
  RecipeService,
} from './VerticalForm/VerticalFormServices';

// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|

/**
 * @description returns proper constant object by verticalType
 *
 * @param {String} verticalType
 *
 * @returns {Object}
 */
const chooseConstant = (verticalType) => {
  switch (verticalType) {
    case 'activity':
      return ACTIVITY;
    case 'collection':
      return COLLECTION;
    case 'event':
      return EVENTS;
    case 'flagged':
      return FLAGGED;
    case 'movie':
      return MOVIE;
    case 'place':
      return PLACE;
    case 'recipe':
      return RECIPE;
    case 'universalSearch':
      return UNIVERSAL_SEARCH;
    default:
      return {};
  }
};

/**
 * @description returns proper header text by verticalType
 *
 * @param {String} verticalType
 *
 * @returns {String}
 */
const chooseVerticalHeader = (verticalType) => {
  switch (verticalType) {
    case VERTICAL_TYPE.CURATED:
      return 'Curated Cards';
    case VERTICAL_TYPE.COLLECTION:
      return 'Collections';
    case VERTICAL_TYPE.PLACE:
      return 'Places';
    case VERTICAL_TYPE.EVENT:
      return 'Events';
    case VERTICAL_TYPE.FLAGGED:
      return 'Flagged Content';
    case VERTICAL_TYPE.MOVIE:
      return 'Movies & Television';
    case VERTICAL_TYPE.ACTIVITY:
      return 'Recreation';
    case VERTICAL_TYPE.RECIPE:
      return 'Recipes';
    case VERTICAL_TYPE.UNIVERSAL_SEARCH:
      return 'Search All Cards';
    default:
      return '';
  }
};

/**
 * @description returns proper header text by verticalType
 *
 * @param {String} verticalType
 *
 * @returns {String}
 */
const chooseVerticalService = (verticalType) => {
  switch (verticalType) {
    case VERTICAL_TYPE.COLLECTION:
      return CollectionService;
    case VERTICAL_TYPE.PLACE:
      return PlaceService;
    case VERTICAL_TYPE.EVENT:
      return EventService;
    case VERTICAL_TYPE.MOVIE:
      return MovieService;
    case VERTICAL_TYPE.ACTIVITY:
      return ActivityService;
    case VERTICAL_TYPE.RECIPE:
      return RecipeService;
    default:
      return '';
  }
};

/**
 * @description forms correct request object properties
 * based on verticalType
 *
 * @param {Object} requestState
 * @param {String} verticalType
 *
 * @returns {Object}
 */
const chooseRequestObject = (requestState = {}, verticalType) => {
  const {
    cat_unique_slug_list,
    dateRange,
    loaded_ids,
    required_neighborhoods_groups,
    order_by_parameter,
    page,
    price,
    query,
    rating,
    review_status,
    status,
    tmdb_page,
    report_types,
  } = requestState;

  const commonRequestProps = {
    loaded_ids,
    order_by_parameter,
    page,
    review_status,
    // backend is looking for 'draft' while we name it drafts
    status,
  };

  let datetime_min;
  let datetime_max;

  // Formatting to remove empty groups that could be interpreted by the back-end as a void condition
  let formatted_required_neighborhoods_groups = [];
  if (required_neighborhoods_groups) {
    for (const required_neighborhoods_group of required_neighborhoods_groups) {
      if (required_neighborhoods_group.length > 0) {
        formatted_required_neighborhoods_groups.push(required_neighborhoods_group);
      }
    }
  }

  switch (verticalType) {
    case VERTICAL_TYPE.COLLECTION:
      return {
        page_size: DEFAULT_PAGE_SIZE,
        title: query,
        requested_neighborhoods_groups: formatted_required_neighborhoods_groups,
        ...commonRequestProps,
      };
    case VERTICAL_TYPE.PLACE:
      if (status === 'queue') {
        return {
          cat_unique_slug_exclusions: [],
          cat_unique_slug_list,
          is_event: false,
          query,
          required_neighborhoods_groups: formatted_required_neighborhoods_groups,
          nh_county_slug_list: [],
          prices: price.map(({ value }) => value),
          required_cat_unique_slug_list: [],
          ...commonRequestProps,
        };
      }

      return {
        is_event: false,
        query,
        required_neighborhoods_groups: formatted_required_neighborhoods_groups,
        rating,
        prices: price.map(({ value }) => value),
        ...(status !== 'fetched'
          ? { cat_unique_slug_list }
          : { fs_cat_list: cat_unique_slug_list }),
        ...commonRequestProps,
      };
    case VERTICAL_TYPE.EVENT:
      if (dateRange) {
        const { isoDateTime } = DATE_FORMATS;

        datetime_min = moment(dateRange.start).format(isoDateTime);
        datetime_max = moment(dateRange.end).endOf('day').format(isoDateTime);
      }

      return {
        cat_unique_slug_list,
        datetime_min,
        datetime_max,
        is_event: true,
        query,
        required_neighborhoods_groups: formatted_required_neighborhoods_groups,
        prices: price.map(({ value }) => value),
        ...commonRequestProps,
      };
    case VERTICAL_TYPE.FLAGGED:
      return {
        reports_types: report_types ? [report_types] : undefined,
        loaded_pretty_ids: loaded_ids,
        // page_size: 20,
        title: query || undefined,
        object_types: [
          'card',
          VERTICAL_TYPE.PLACE,
          VERTICAL_TYPE.MOVIE,
          VERTICAL_TYPE.RECIPE,
          VERTICAL_TYPE.ACTIVITY,
          'user_unit',
        ],
      };
    case VERTICAL_TYPE.MOVIE:
      return {
        ratings: rating,
        title: query,
        tmdb_page,
        ...(status !== 'fetched' ? { cat_unique_slug_list } : {}),
        ...commonRequestProps,
      };
    case VERTICAL_TYPE.ACTIVITY:
      return {
        cat_unique_slug_list,
        name: query,
        ratings: rating,
        ...commonRequestProps,
      };
    case VERTICAL_TYPE.RECIPE:
      return {
        title: query,
        ratings: rating,
        ...commonRequestProps,
      };
    case VERTICAL_TYPE.UNIVERSAL_SEARCH:
      return {
        loaded_ids,
        query,
        page_size: 20,
      };
    default:
      return {};
  }
};

// ----------------------------------------------------------------------------|
//                                  Exports
// ----------------------------------------------------------------------------|
export {
  chooseConstant,
  chooseRequestObject,
  chooseVerticalHeader,
  chooseVerticalService,
};
