/**
 * ************************************
 *
 * @module  Categories.js
 * @author  Matt P
 * @date    03/03/2021
 * @description Container for the categories Settings tab
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getCategoriesListAction,
  toggleEnable,
  toggleFeature,
  deleteCategoryAction,
  clearListAction,
  saveDataToRedux,
  filterDataAction,
  filterDataByParentAction,
} from 'store/actions/Categories.actions';

import {
  CardBanner,
  CobTable,
  NoDataFound,
  Loader,
  DeleteModal,
} from 'components';

import { CATEGORY } from 'constants.js';

import {
  cols,
  updateMapper,
  createCatParentDropdownOptions,
} from './CategoriesUtils';

import Category from './Category/Category';

import './Categories.scss';

// ----------------------------------------------------------------------------|
//                        Redux - Property Mapping
// ----------------------------------------------------------------------------|

const mapDispatchToProps = (dispatch) => ({
  getCategoriesList: (data) => dispatch(getCategoriesListAction(data)),
  clearCategoriesList: () => dispatch(clearListAction()),
  toggleEnable: (category) => dispatch(toggleEnable(category)),
  toggleFeature: (category) => dispatch(toggleFeature(category)),
  deleteCategory: (id) => dispatch(deleteCategoryAction(id)),
  saveData: (data) => dispatch(saveDataToRedux(data)),
  filterData: (data) => dispatch(filterDataAction(data)),
  filterDataByParent: (data) => dispatch(filterDataByParentAction(data)),
});

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  parents: state.categories.parents,
  initialDataLoaded: state.categories.initialDataLoaded,
  filteredData: state.categories.filteredData,
});
// ----------------------------------------------------------------------------|
//                   React Class Component - Categories
// ----------------------------------------------------------------------------|
class Categories extends Component {
  // --------------------------------------------------------------------------|
  //                            PropTypes Check
  // --------------------------------------------------------------------------|
  static propTypes = {
    categories: PropTypes.array.isRequired,
    parents: PropTypes.array.isRequired,
    initialDataLoaded: PropTypes.bool.isRequired,
    filteredData: PropTypes.array.isRequired,
    filterData: PropTypes.func.isRequired,
    filterDataByParent: PropTypes.func.isRequired,
    saveData: PropTypes.func.isRequired,
    getCategoriesList: PropTypes.func.isRequired,
    clearCategoriesList: PropTypes.func.isRequired,
    toggleEnable: PropTypes.func.isRequired,
    toggleFeature: PropTypes.func.isRequired,
    deleteCategory: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      pageTitle: CATEGORY.TITLE,
      query: '',
      showModal: false,
      parentFilter: {
        label: 'All categories',
        value: { unique_slug: 'ALL' },
      },
    };
  }

  // --------------------------------------------------------------------------|
  //                            Lifecycle Methods
  // --------------------------------------------------------------------------|
  componentDidMount() {
    const { getCategoriesList } = this.props;

    getCategoriesList();
  }

  componentWillUnmount() {
    const { clearCategoriesList } = this.props;

    clearCategoriesList();
  }

  // --------------------------------------------------------------------------|
  //                      Component Methods - Categories
  // --------------------------------------------------------------------------|
  /**
   * @description Closes the create new modal by toggling state
   */
  closeModal = () => {
    this.setState({ showModal: false });
  };

  /**
   * @description Closes edit modal by toggling state
   */
  closeEditModal = () => {
    this.setState({ editingCategory: null });
    this.setState({ showEditModal: false });
  };

  /**
   * @description opens create new modal by toggling state
   */
  openModal = () => {
    this.setState({ showModal: true });
  };

  /**
   * @description opens edit modal by toggling state
   *
   * @param {Object} category
   */
  showEditModal = (category) => {
    this.setState({ editingCategory: category, showEditModal: true });
  };

  /**
   * @description opens modal to create new category by toggling state
   */
  createNewCategory = () => {
    this.openModal();
  };

  /**
   * @description Searching takes in query and checks across categories
   * as follows:
   *
   * If any child name contains query as substring, it would be added to result
   * with it's parent
   *
   * If any parent name contains query as substring, it would be added to result
   *
   * @param {String} query
   */
  searchEvent = (query) => {
    const { filterData } = this.props;

    this.setState({
      query,
    });

    filterData(query);
  };

  onDropdownSelect = (category) => {
    const { filterDataByParent } = this.props;
    const { value } = category;

    const buildSelectInput = {
      label: value.name,
      value,
    };

    this.setState({ parentFilter: buildSelectInput });

    filterDataByParent(value.unique_slug);
  };

  /**
   * @description Take operation type, category. Prepare SAGA action data.
   * If category is parent, send parent_slug or unique_slug key with
   * operative key. If operation is delete, no operative key.
   *
   * @param operation ['DELETE', 'ENABLE', 'FEATURE']
   * @param category
   */
  buildSagaData = (operation, category) => {
    // const isParent = category.unique_slug === category.parent_slug;
    const key = updateMapper[operation];

    const apiData = {
      ...{ unique_slug: category.unique_slug },
      ...{ name: category.name },
      ...(key && { [key]: !category[key] }),
    };

    return { category, apiData };
  };

  /**
   * @description Toggles the enabling and disabling of a category
   *
   * @param category
   */
  toggleEnable = (category) => {
    const { toggleEnable } = this.props;

    toggleEnable(this.buildSagaData(CATEGORY.ENABLE, category));
  };

  /**
   * @description Toggles the ability to feature a category
   *
   * @param {Object} category
   */
  toggleFeature = (category) => {
    const { toggleFeature } = this.props;

    toggleFeature(this.buildSagaData(CATEGORY.FEATURE, category));
  };

  /**
   * @description Deletes a category
   *
   * @param {Object} category
   */
  deleteCategory = (category) => {
    const { deleteCategory } = this.props;

    deleteCategory(this.buildSagaData(CATEGORY.DELETE, category));
  };

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  Categories rendering
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  /**
   * @description Renders Modal on click
   */
  renderCreateModal = () => {
    const { showModal } = this.state;

    return <Category showModal={showModal} closeModal={this.closeModal} />;
  };

  /**
   * @description Renders Edit Modal on click
   */
  renderEditModal = () => {
    const { showEditModal, editingCategory } = this.state;

    return (
      <Category
        showModal={showEditModal}
        closeModal={this.closeEditModal}
        isEditing
        passedCategory={editingCategory}
      />
    );
  };

  /**
   * @description Renders Delete Modal on click
   */
  renderDeleteModal = () => {
    const { deleteCategoryData } = this.state;

    return (
      <DeleteModal
        cancelClick={() =>
          this.setState({ showDeleteModal: false, deleteCategoryData: null })
        }
        deleteClick={() => {
          const data = deleteCategoryData;
          this.deleteCategory(data);
          this.setState({ deleteCategoryData: null, showDeleteModal: false });
        }}
        showHeader={false}
      />
    );
  };

  /**
   * @description Renders content on load
   */
  renderContent = () => {
    const { filteredData, initialDataLoaded } = this.props;

    const NO_DATA = !Object.keys(filteredData).length;

    if (initialDataLoaded) {
      if (NO_DATA) {
        return <NoDataFound subMessage={CATEGORY.NO_DATA} />;
      }

      return (
        <CobTable
          rows={filteredData}
          cols={cols}
          toggleFeatured={(category) => this.toggleFeature(category)}
          toggleEnable={(category) => this.toggleEnable(category)}
          showEditModal={(category) => this.showEditModal(category)}
          deleteCategory={(category) => {
            this.setState({
              deleteCategoryData: category,
              showDeleteModal: true,
            });
          }}
          invokeParentFilter={this.onDropdownSelect}
        />
      );
    }

    return <Loader />;
  };

  render() {
    const { parents } = this.props;
    const {
      query,
      pageTitle,
      showModal,
      showEditModal,
      showDeleteModal,
      parentFilter,
    } = this.state;

    return (
      <div className="categories-container">
        <CardBanner
          query={query}
          isDropdownEnabled
          isSearchEnabled
          title={pageTitle}
          newBtnClick={this.createNewCategory}
          searchEvent={this.searchEvent}
          dropdownValue={parentFilter}
          dropdownOptions={createCatParentDropdownOptions(parents)}
          dropdownDefaultOption="All categories"
          onDropdownSelect={this.onDropdownSelect}
        />
        {this.renderContent()}
        {showModal && this.renderCreateModal()}
        {showEditModal && this.renderEditModal()}
        {showDeleteModal && this.renderDeleteModal()}
      </div>
    );
  }
}
// ----------------------------------------------------------------------------|
//                  Categories Export with Redux Connect
// ----------------------------------------------------------------------------|
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Categories)
);
