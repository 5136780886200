/**
 * ************************************
 *
 * @module MapView.js
 * @author  Matt P
 * @date    07/15/2021
 * @description Container for the MapBox map view
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import ReactDOM from 'react-dom';
import React, { Fragment, useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Card } from 'components';

import cx from 'classnames';

import currentEnv from 'utils/EnvironmentSpecificValues';

import { getDateBasedOnStatus, getImageURL } from 'utils/utils';

// eslint-disable-next-line import/no-webpack-loader-syntax
// import * as mapboxgl from 'mapbox-gl';
import mapboxgl from '!mapbox-gl';

import './MapView.scss';

mapboxgl.accessToken = currentEnv.api.mapbox;

// ----------------------------------------------------------------------------|
//                     React Functional Component - MapView
// ----------------------------------------------------------------------------|
const MapView = (props) => {
  const {
    initialZoom,
    longitude,
    latitude,
    markerData,
    onMarkerClick,
    selectedCards,
    parentCard,
    map,
    mapContainer
  } = props;

  const [isMapReady, setIsMapReady] = useState(false);

  /**
   * @description - renders map markers on an initialized map
   *
   * @param {Object[]} cardArray - of card objects
   *
   * @returns {void}
   */
  const renderMarkers = useCallback(
    (cardArray = []) => {
      const selectionSet = new Set(selectedCards);

      cardArray.forEach((card) => {
        const { lat: cardLat, lng: cardLng } = card;

        if (cardLat && cardLng) {
          const {
            id,
            published_date: publishedDate,
            modified: modifiedDate,
            status,
            images,
            name,
            title,
            phrase,
            last_admin_review,
          } = card;

          const imageURL = getImageURL(images);

          const cardClass = cx([
            'card',
            { 'selected-card': selectedCards.includes(id) },
          ]);

          const placeholder = document.createElement('div');

          const isParentCard = id === parentCard.id;

          ReactDOM.render(
            <Card
              key={id}
              id={id}
              className={cardClass}
              status={status}
              date={getDateBasedOnStatus(status, modifiedDate, publishedDate)}
              imageUrl={imageURL}
              onClick={
                !isParentCard
                  ? () => {
                      onMarkerClick(id, card);
                    }
                  : null
              }
              title={name || title}
              phrase={phrase}
              adminReview={last_admin_review}
              hideMenu
              hideCheckBox
            />,
            placeholder
          );

          let markerColor = selectionSet.has(id) ? '#F7BC25' : '#71D1DF';

          if (isParentCard) markerColor = '#CC8899';

          new mapboxgl.Marker({
            color: markerColor,
          })
            .setLngLat([cardLng, cardLat])
            .setPopup(
              new mapboxgl.Popup({
                closeButton: false,
                maxWidth: 'none',
                maxHeight: 'none',
              }).setDOMContent(placeholder)
            )
            .addTo(map.current);
        }
      });
    },
    [onMarkerClick, selectedCards]
  );

  /**
   * @description - instantiates map on component mount
   */
    useEffect(() => {
      // initialize map only once
      if (isMapReady) return;
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: currentEnv.styles.mapbox,
        center: [longitude, latitude],
        zoom: initialZoom,
      });
      setIsMapReady(true);
    });

  /**
   * @description - fires marker function to populate map
   */
  useEffect(() => {
    if (Array.isArray(markerData) && markerData.length) {
      renderMarkers(markerData);
    }
  }, [selectedCards, markerData, renderMarkers]);

  return <div className="map-container" ref={mapContainer} />;
};

// ---------------------------------------------------------------------------|
//                             PropTypes Check
// ---------------------------------------------------------------------------|
MapView.propTypes = {
  initialZoom: PropTypes.number,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  markerData: PropTypes.arrayOf(PropTypes.shape({})),
  onFetchMore: PropTypes.func,
  onMarkerClick: PropTypes.func,
  selectedCards: PropTypes.arrayOf(PropTypes.string),
  parentCard: PropTypes.shape({}),
  map: PropTypes.object,
  mapContainer: PropTypes.object,
};

// ---------------------------------------------------------------------------|
//                              Default Props
// ---------------------------------------------------------------------------|
MapView.defaultProps = {
  initialZoom: 11.38,
  // NYC default
  latitude: 40.7503,
  longitude: -73.9366,
  markerData: [],
  onMarkerClick: () => {},
  selectedCards: [],
  parentCard: {},
  map: null,
  mapContainer: null
};

// ---------------------------------------------------------------------------|
//                                  Export
// ---------------------------------------------------------------------------|

export default MapView;
