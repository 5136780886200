/**
 * ************************************
 *
 * @module  CardSelection.js
 * @author  Matt P
 * @date    07/09/2021
 * @description component for selecting and composing collections
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
// React imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// App imports
import { Card, DropDown } from 'components';
import { deselectCardListAction, updateCollectionCardsAction } from 'store/actions/VerticalForm.action';
import { getImageURL } from 'utils/utils';
import { findCardById, getCardTypeByPrettyId, newWindowToCardDetail } from 'containers/VerticalDisplay/VerticalForm/VerticalFormUtils';
import CardInput from './CardInput/CardInput';
import CardList from './CardList/CardList';
import { GENERIC } from 'constants.js';
import './CardSelection.scss';

// ----------------------------------------------------------------------------|
//                            Redux - Property Mapping
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
    onDelete: (data) => dispatch(deselectCardListAction(data)),
    updateCardProperties: (data) => dispatch(updateCollectionCardsAction(data)),
});

const mapStateToProps = (state) => ({
    verticalForm: state.verticalForm,
});

// ----------------------------------------------------------------------------|
//                  React Function Component - CardSelection
// ----------------------------------------------------------------------------|
const CardSelection = (props) => {
    const { errors, onDelete, pairingReasonsOptions, title, verticalType, verticalTypes, verticalForm } = props;
    const { selectedCards } = verticalForm;
    const [showModal, setShowModal] = useState(false);


    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    /**
     * @description function to toggle close modal window
     * leverages react hooks
     * @param {String} propertyName of the card data object
     * @param {Any} value to add/change to data
     * @param {Number} index in the selectedCards array
     *
     * @returns {void}
     */
    const updateSelectedCardDataProperty = (propertyName, value, index) => {
        const { updateCardProperties } = props;
        const selectedCardsCopy = [...selectedCards];
        const selectedCard = selectedCardsCopy[index];
        selectedCardsCopy[index] = {...selectedCard, [propertyName]: value};
        updateCardProperties(selectedCardsCopy);
    };

    const renderSelectedCards = () => {
        if (Array.isArray(selectedCards)) {
            return selectedCards.map((card, index) => {
                const { id, published_date: publishedDate, status, images, name, title: cardTitle, phrase, is_expired: isExpired, last_admin_review, pairing_reason, is_pinned } = card;
                const imageURL = getImageURL(images);
                const cardType = getCardTypeByPrettyId(card);
                const { SUB_MENU: { PIN } } = GENERIC;
                return (
                    <div className="selected-card">
                        <Card verticalType={verticalType === "collection" ? verticalType : null} isPinned={verticalType === "collection" ? is_pinned : false} onMenuClick={(menuType) => {if (menuType === PIN) {updateSelectedCardDataProperty("is_pinned", !card.is_pinned, index);}}}
                            key={id} id={id} className="card" date={publishedDate} status={status} imageUrl={imageURL} onClick={() => {newWindowToCardDetail(findCardById(id, selectedCards));}}
                            isExpired={cardType === 'curated' || cardType === 'event' ? isExpired : false} title={name || cardTitle} phrase={phrase} onDelete={() => onDelete(card)}
                            adminReview={last_admin_review} hideMenu showDeleteButton shouldDisplayNewTabOpenCTA={false} />
                        { pairingReasonsOptions && (
                            <DropDown options={pairingReasonsOptions.map((option) => ({label: option, value: option}))} name="pairings-reason" onSelect={(boo) => updateSelectedCardDataProperty('pairing_reason', boo.value, index)} value={pairing_reason} />
                        )}
                    </div>
                );
            });
        }
    };
    
    const chooseHeading = (cardArray) => {
        if (Array.isArray(cardArray) && cardArray.length) {
            return `${title} (${cardArray.length} card${cardArray.length === 1 ? '' : 's'} selected)`;
        }
        return title;
    };

    return (
        <div className="card-selection-container">
            <div className="preview-heading">{chooseHeading(selectedCards)}</div>
            <div className="selected-card-list">
                {renderSelectedCards()}
                <CardInput clickHandler={openModal} />
            </div>
            <div className="error">{errors}</div>
            {showModal && (
                <CardList verticalTypes={verticalTypes} verticalType={verticalType} show={showModal} modalClosed={closeModal} selectedCards={Array.isArray(selectedCards) ? selectedCards.map(({ id }) => id) : []} />
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------|
//                       PropTypes Check - CardSelection
// ----------------------------------------------------------------------------|
CardSelection.propTypes = {
    title: PropTypes.string,
    onDelete: PropTypes.func.isRequired,
    updateCardProperties: PropTypes.func.isRequired,
    verticalForm: PropTypes.shape({
        selectedCards: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    errors: PropTypes.string,
    pairingReasonsOptions: PropTypes.oneOf([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.number,
    ]),
    verticalTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    verticalType: PropTypes.string.isRequired,
};

CardSelection.defaultProps = {
    title: 'Add Places/Events',
    errors: '',
};

// ----------------------------------------------------------------------------|
//                     CardSelection Export with redux connect
// ----------------------------------------------------------------------------|
export default connect(mapStateToProps, mapDispatchToProps)(CardSelection);
