/**
 * ************************************
 *
 * @module  Categories.action.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Cat redux actions
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                                Exports
// ----------------------------------------------------------------------------|
export const getCategoriesListAction = (data) => ({
  type: ACTIONS.CATEGORIES.GET_LIST,
  data,
});

export const createCategoryAction = (data) => ({
  type: ACTIONS.CATEGORIES.CREATE_CATEGORY,
  data,
});

export const editCategoryAction = (data) => ({
  type: ACTIONS.CATEGORIES.EDIT_CATEGORY,
  data,
});

export const clearListAction = () => ({
  type: ACTIONS.CATEGORIES.CLEAR_LIST,
});

export const toggleEnable = (data) => ({
  type: ACTIONS.CATEGORIES.TOGGLE_ENABLE,
  data,
});

export const toggleFeature = (data) => ({
  type: ACTIONS.CATEGORIES.TOGGLE_FEATURE,
  data,
});

export const deleteCategoryAction = (id) => ({
  type: ACTIONS.CATEGORIES.DELETE_CATEGORY,
  data: id,
});

export const filterDataAction = (query) => ({
  type: ACTIONS.CATEGORIES.QUERY_FILTER,
  query,
});

export const filterDataByParentAction = (data) => ({
  type: ACTIONS.CATEGORIES.PARENT_FILTER,
  data,
});

export const saveDataToRedux = (data) => ({
  type: ACTIONS.CATEGORIES.SAVE_DATA_TO_REDUX,
  data,
});
