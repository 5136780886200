/**
 * ************************************
 *
 * @module  CuratedCard.saga.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description redux saga file for the CuratedCard component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import { call, put, select } from 'redux-saga/effects';

import { constructQueryParams } from 'utils/utils';

import API from 'API';
import ACTIONS from '../actions/actionTypes';

import {
  clearCuratedCardsAction,
  getCuratedCardsAction,
} from '../actions/CuratedCards.action';

// ----------------------------------------------------------------------------|
//                          Sagas - CuratedCards
// ----------------------------------------------------------------------------|
/**
 * @description gets curated card list http request
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* getCuratedList(action) {
  const { searchData } = window.axios;

  try {
    const URL = `${API.curatedCard}`;
    const response = yield call(searchData, 'post', URL, action.data);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.CURATED_CARDS.GET_LIST_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: ACTIONS.CURATED_CARDS.GET_LIST_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.CURATED_CARDS.GET_LIST_FAIL });
  }
}

/**
 * @description handles delete curated card http request
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* deleteCuratedCard(action) {
  const { deleteData } = window.axios;

  try {
    const { id } = action.data;
    const URL = `${API.curatedCard}${id}`;

    const response = yield call(deleteData, URL);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.CURATED_CARDS.DELETE_CURATED_CARDS_SUCCESS,
        data: { id },
      });
    } else {
      yield put({ type: ACTIONS.CURATED_CARDS.DELETE_CURATED_CARDS_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.CURATED_CARDS.DELETE_CURATED_CARDS_FAIL });
  }
}

/**
 * @description performs bulk operations like bulk delete and publish
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* performBulkOperationOnCurated(action) {
  const { patchData } = window.axios;
  const { data } = action;
  try {
    const response = yield call(patchData, API.curatedCard, data);

    if (response.status === 200) {
      yield put({ type: ACTIONS.CURATED_CARDS.BULK_ACTION_SUCCESS, data });
    } else {
      yield put({ type: ACTIONS.CURATED_CARDS.BULK_ACTION_FAIL, data });
    }
  } catch (error) {
    yield put({ type: ACTIONS.CURATED_CARDS.BULK_ACTION_FAIL, data });
  }
}

/**
 * @description filters curated card list
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* filterCuratedCardsList(action) {
  yield put(clearCuratedCardsAction());
  const page = yield select((state) => state.curated.page);
  const data = { ...action.data, page };
  yield put(getCuratedCardsAction(data));
}

export {
  getCuratedList,
  deleteCuratedCard,
  performBulkOperationOnCurated,
  filterCuratedCardsList,
};
