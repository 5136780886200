/**
 * ************************************
 *
 * @module  Filters.action.js
 * @author  Matt P
 * @date    03/22/2021
 * @description redux action for Filters
 *
 * ************************************
 */

// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                                  Exports
// ----------------------------------------------------------------------------|
export const getFiltersListAction = (data) => ({
  type: ACTIONS.FILTERS.GET_LIST,
  data,
});

export const getFiltersRulesListAction = (data) => ({
  type: ACTIONS.FILTERS.GET_RULE_LIST,
  data,
});

export const createFilterAction = (data) => ({
  type: ACTIONS.FILTERS.CREATE_FILTER,
  data,
});

export const editFilterAction = (data) => ({
  type: ACTIONS.FILTERS.EDIT_FILTER,
  data,
});

export const clearListAction = () => ({
  type: ACTIONS.FILTERS.CLEAR_LIST,
});

export const toggleEnable = (data) => ({
  type: ACTIONS.FILTERS.TOGGLE_ENABLE,
  data,
});

export const toggleFeature = (data) => ({
  type: ACTIONS.FILTERS.TOGGLE_FEATURE,
  data,
});

export const deleteFilterAction = (id) => ({
  type: ACTIONS.FILTERS.DELETE_FILTER,
  data: id,
});

export const saveDataToRedux = (data) => ({
  type: ACTIONS.FILTERS.SAVE_DATA_TO_REDUX,
  data,
});
