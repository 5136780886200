/**
 * ************************************
 *
 * @module  MovieFetchedDetails.js
 * @author  Matt P
 * @date    12/24/2020
 * @description MovieFetchedDetails functional component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import { Button } from 'components';

import { MOVIE } from 'constants.js';

import { sanitizeUrl } from 'utils/utils';

import LanguageCodes from 'utils/LanguageCodes';

import './MovieFetchedDetails.scss';

const generateRating = (rating) => (rating ? `${rating / 2}/5` : '-');

const generateCategories = (categories) => {
  if (categories && Array.isArray(categories)) {
    if (typeof categories[0] === 'string') {
      return categories.join(', ');
    }
    return categories.map((item) => item.name).join(', ');
  }
  return '-';
};

// ----------------------------------------------------------------------------|
//                React Function Component - MovieFetchedDetails
// ----------------------------------------------------------------------------|
const MovieFetchedDetails = (props) => {
  const {
    id,
    imageURL,
    categories,
    name,
    language,
    runtime,
    rating,
    status,
    releaseDate,
    className,
    onReviewClick,
  } = props;

  const { REVIEW_AND_PUBLISH } = MOVIE;

  const containerClass = cx(['movie-fetch-details', `${className}`]);

  const categoriesList = generateCategories(categories);

  const rate = generateRating(rating);

  const nativeLanguage =
    LanguageCodes[language] && LanguageCodes[language].name
      ? LanguageCodes[language].name
      : '-';

  return (
    <div className={containerClass}>
      <img src={sanitizeUrl(imageURL)} alt="cover" className="cover-pic" />
      <div className="content">
        <h5 className="categories">{categoriesList}</h5>
        <h1 className="name">{name}</h1>
        <div className="details">
          <p>Runtime: {runtime ? `${runtime} minutes` : '-'}</p>
        </div>
        <div className="details">
          <p>Language: {nativeLanguage}</p>
          <p className="ratings">Rating: {rate}</p>
        </div>
        <Button className="review-btn" onClick={onReviewClick}>
          {REVIEW_AND_PUBLISH}
        </Button>
      </div>
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                    PropTypes Check - MovieFetchedDetails
// ----------------------------------------------------------------------------|
MovieFetchedDetails.propTypes = {
  name: PropTypes.string.isRequired,
  onReviewClick: PropTypes.func.isRequired,
  imageURL: PropTypes.string,
  categories: PropTypes.array,
  rating: PropTypes.string,
  className: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                    Default Props - MovieFetchedDetails
// ----------------------------------------------------------------------------|
MovieFetchedDetails.defaultProps = {
  imageURL: '',
  categories: [],
  rating: '',
  className: '',
};

// ----------------------------------------------------------------------------|
//                        MovieFetchedDetails Export
// ----------------------------------------------------------------------------|
export default MovieFetchedDetails;
