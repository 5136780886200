/**
 * ************************************
 *
 * @module  Place.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Container for editing a 'Place' Currently renders within
 * the CardViewModal is shown renders when a card is clicked in the PlacesList
 * component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import {
  fetchNeighborhoodLocationsAction,
  deletePlaceAction,
} from 'store/actions/Places.action';

import {
  clearImagesAction,
  deleteImageAction,
  populateAutofillImagesAction,
  saveDataToRedux as saveDataToReduxAction,
  saveData,
  uploadImageAction,
  reOrderImagesAction,
  fetchPlaceAction,
} from 'store/actions/Place.action';

import { toast } from 'react-toastify';

import PlaceService from 'containers/~depreciated/Place/PlaceService';
import PlaceData from 'containers/~depreciated/Place/PlaceData.constants';

import {
  CobCheckbox,
  Form,
  FileUploader,
  // PreviewWrapper,
  WarningModal,
  Loader,
  FormActions,
  Overlay,
  ThirdPartySearchModal,
} from 'components';

import { PLACE } from 'constants.js';

import {
  getUniqueSlug,
  getPriceRangeLabel,
  getProcessedNeighborhoods,
  getImageURL,
  applyDrag,
  locationCoordinateCheck,
  hasUniqueSlug,
  deepCopy,
  getReservationTiers,
  chooseItemizedDescription,
  mapDetailedCategories,
} from 'utils/utils';
import { extractCategoryTypes } from 'utils/CategoryUtils';

import CommonService from 'services/CommonService';

import './Place.scss';

// ----------------------------------------------------------------------------|
//                        Redux - Property Mapping
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  fetchPlaceOrEvent: (data) => dispatch(fetchPlaceAction(data)),
  fetchNeighborhoodLocations: () =>
    dispatch(fetchNeighborhoodLocationsAction()),
  uploadImage: (data) => dispatch(uploadImageAction(data)),
  deleteImage: (data) => dispatch(deleteImageAction(data)),
  populateAutofillImages: (data) =>
    dispatch(populateAutofillImagesAction(data)),
  clearImages: () => dispatch(clearImagesAction()),
  reOrderImages: (data) => dispatch(reOrderImagesAction(data)),
  saveDataToRedux: (data) => dispatch(saveDataToReduxAction(data)),
  saveData: (data, id, successCB) => dispatch(saveData(data, id, successCB)),
  deletePlace: (data) => dispatch(deletePlaceAction(data)),
});

const mapStateToProps = (state) => ({
  place: state.place,
  categoryMap: state.places.categoryMap,
  adminCategories: state.places.adminCategories,
  placeCategories: state.places.placeCategories,
  vibes: state.places.vibes,
  neighborhoods: state.places.neighborhoodLocations,
});

// ----------------------------------------------------------------------------|
//                              Utilities
// ----------------------------------------------------------------------------|
const placeService = PlaceService();

const commonService = CommonService();

const { WARNING_ON_DRAFT } = PLACE;

// ----------------------------------------------------------------------------|
//                    React Class PureComponent - Place
// ----------------------------------------------------------------------------|
class Place extends PureComponent {
  // --------------------------------------------------------------------------|
  //                        PropTypes Check - Place
  // --------------------------------------------------------------------------|
  static propTypes = {
    place: PropTypes.object.isRequired,
    ...commonService.getProTypesForPlaceEvent(),
  };

  // --------------------------------------------------------------------------|
  //                        Default Props - Place
  // --------------------------------------------------------------------------|
  static defaultProps = {};

  // --------------------------------------------------------------------------|
  //                          State - Place
  // --------------------------------------------------------------------------|
  constructor(props) {
    super(props);

    const { data, saveDataToRedux } = this.props;
    const initialPlaceState = this.handlePlaceData(data);

    this.state = {
      placeId: data.id,
      errors: {},
      imageErrors: '',
      showEditHours: false,
      shouldFetchPlace: data.shouldFetchPlace,
      shouldDisplayLoader: false,
      showThirdPartyModal: false,
      thirdPartyEdit: null,
      ...initialPlaceState,
    };
    // initially keep the data in the redux store
    saveDataToRedux(this.getToBeProcessedData(data));
  }

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  Place lifecycle
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  componentDidMount() {
    const { adminCategories, data, neighborhoods, populateAutofillImages } =
      this.props;
    const { shouldFetchPlace } = this.state;

    populateAutofillImages(data.images);

    if (shouldFetchPlace) {
      this.fetchPlaceFromServer();
      this.setState({ shouldFetchPlace: false, shouldDisplayLoader: true });
    }

    // cats and neighborhoods are async calls to redux saga, and should load
    // before the form renders. The shouldDisplayLoader flag is set for this
    // reason, and is flipped when the component updates on prop fulfillment
    if (neighborhoods.length === 0 || adminCategories.length === 0) {
      this.setState({ shouldDisplayLoader: true });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      adminCategories,
      vibes,
      data,
      neighborhoods,
      place,
      saveDataToRedux,
      populateAutofillImages,
    } = this.props;
    const { placeId, shouldDisplayLoader } = this.state;

    // used for updating and rendering a fetched place
    // also checks if the async calls have come back and will flip
    // the loaderDisplay flag when loaded.
    if (data.shouldFetchPlace) {
      if (
        neighborhoods.length !== 0 &&
        vibes.length !== 0 &&
        adminCategories.length !== 0 &&
        place.fetchedPlaceData &&
        placeId === place.fetchedPlaceData.id &&
        shouldDisplayLoader
      ) {
        this.setState({
          shouldDisplayLoader: false,
          ...this.handlePlaceData(place.fetchedPlaceData),
        });

        this.replaceUrl(place.fetchedPlaceData);

        saveDataToRedux(this.getToBeProcessedData(place.fetchedPlaceData));
        populateAutofillImages(place.fetchedPlaceData.images);
      }
    } else if (
      neighborhoods.length !== 0 &&
      adminCategories.length !== 0 &&
      vibes.length !== 0 &&
      !place.fetchedPlaceData &&
      shouldDisplayLoader
    ) {
      this.setState({
        shouldDisplayLoader: false,
        ...this.handlePlaceData(data),
      });

      saveDataToRedux(this.getToBeProcessedData(data));

      this.replaceUrl(data);
    }
  }

  componentWillUnmount() {
    const { clearImages, place } = this.props;

    clearImages(place);
  }

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  Place methods
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  // Used to replace the URl with the correct section
  // (Drafts/Published/Fetched) as per the
  // cards 'status' to stay consistent
  replaceUrl = (data = {}) => {
    // check if the URl pathname is off
    const { location, history } = this.props;
    const { section, tab, id } = commonService.pathnameHelper(
      location.pathname
    );

    if (data.status) {
      const cardStatus = data.status === 'draft' ? 'drafts' : data.status;

      if (tab !== cardStatus) {
        history.replace({
          pathname: `/${section}/${cardStatus}/${data.id || id}`,
        });
      }
    }
  };

  /**
   * @description
   * Fetch Place object from server using ID
   */
  fetchPlaceFromServer = () => {
    const { fetchPlaceOrEvent } = this.props;
    const { placeId } = this.state;

    fetchPlaceOrEvent({ placeId });
  };

  /**
   * @description
   * Handle Place object data: prepare form, display, etc..
   */
  handlePlaceData = (placeDataToProcess) => {
    const toBeProcessedObj = this.getToBeProcessedData(placeDataToProcess);
    const processedData = this.getProcessedData(toBeProcessedObj);

    const placeData = processedData.filter((obj) => obj.name === 'place_hours');

    if (placeData[0].from) {
      const placeHours = JSON.parse(JSON.stringify(placeData[0].from));
    }

    return { data: processedData, editHoursError: { place_hours: '' } };
  };

  onSaveAction = (event) => {
    if (event) event.preventDefault();

    this.setState((prevState) => ({
      showEditHours: !prevState.showEditHours,
      editHoursError: { place_hours: '' },
    }));
  };

  onReOrderImages = (dropData) => {
    const { place, reOrderImages } = this.props;

    const { autofilledImages, images } = place;
    const reOrderedImges = applyDrag(
      [...autofilledImages, ...images],
      dropData
    );

    reOrderImages(reOrderedImges);
  };

  saveData = (e) => {
    const {
      place,
      data,
      closeModal,
      adminCategories,
      saveData,
      neighborhoods,
    } = this.props;
    const { autofilledImages } = place;

    const formattedImages = place.autofilledImages.map((image) => {
      const imageTemp = { ...image };

      // // fixed error where is photo_metadata || photo_credit === null
      // // will wash to empty string for DB PUT/PATCH
      if (imageTemp.photo_credits === null) imageTemp.photo_credits = '';
      if (imageTemp.photo_metadata === null) imageTemp.photo_metadata = '';

      if (image.source === 'cobble') {
        delete imageTemp.fileId;

        return imageTemp;
      }

      return imageTemp;
    });

    const formData = placeService.getPreparedFormData(
      place.formData,
      [...formattedImages],
      e.target.name
    );

    formData.set_categories = formData.categories.map((cat) => cat.unique_slug);

    delete formData.categories;

    formData.main_categories = place.formData.main_categories.map(
      (cat) => cat.unique_slug
    );

    formData.set_neighborhoods = getUniqueSlug(
      place.formData.neighborhoods,
      neighborhoods
    );

    /** Perform validation only on published */
    if (e.target && e.target.name === 'published') {
      let errors = placeService.validate(place.formData);

      errors = commonService.validateLinks(place.formData, errors);

      const imageErrors = commonService.validateImages(
        place.images,
        autofilledImages
      )
        ? null
        : PLACE.IMAGE_VALIDATION_MESSAGE;
      if (Object.keys(errors).length === 0 && imageErrors === null) {
        // dispatch to save
        saveData(formData, data.id, closeModal);
      } else {
        toast.error('There are some errors in the form');
      }

      this.setState({
        errors,
        imageErrors,
      });
    } else if (e.target && e.target.name === 'draft') {
      const errors = {};
      const imageErrors = '';
      const validateName = commonService.validateName(place.formData.name);

      if (validateName) {
        errors.name = validateName;
      }

      if (
        !commonService.validatePrimaryCategories(
          place.formData.main_categories,
          place.formData.categories
        )
      ) {
        errors.categories =
          'Primary categories must be included in the selected categories';
      }

      // no validations required for drafting
      if (Object.keys(errors).length === 0) {
        // dispatch to save
        saveData(formData, data.id, closeModal);
      } else {
        toast.error('There are some errors in the form');
      }
      this.setState({ errors, imageErrors });
    }
  };

  // Edit mode : Obj is there
  getToBeProcessedData = (obj) => {
    const { categoryMap } = this.props;

    // since we are using categories for several different fields,
    // we need to delineate the categories to the specific fields
    // they will later upon submission be joined for the API POST request
    const { misc } = extractCategoryTypes(
      mapDetailedCategories(obj.categories, categoryMap)
    );

    return {
      name: obj.name,
      address: obj.address || '',
      one_liner_description: obj.phrase,
      long_description: obj.description,
      images: obj.images,
      categories: obj.categories || [],
      main_categories: obj.main_categories
        ? mapDetailedCategories(obj.main_categories, categoryMap)
        : [],
      neighborhoods: getProcessedNeighborhoods(obj.neighborhoods),
      price_tier: obj.price_tier,
      from_time: obj.from_time,
      to_time: obj.to_time,
      website_link: obj.website,
      booking_links: obj.booking_links || [],
      ordering_links: obj.ordering_links || [],
      phone_number: obj.phone,
      cta_title_long: obj.cta_title_long,
      cta_title_short: obj.cta_title_short,
      reservation_tier: obj.reservation_tier || '',
      lat: obj.lat,
      lng: obj.lng,
      menu_links: obj.menu_links || [],
      place_hours: obj.place_hours || {
        monday: { slots: [], status: '' },
        tuesday: { slots: [], status: '' },
        wednesday: { slots: [], status: '' },
        thursday: { slots: [], status: '' },
        friday: { slots: [], status: '' },
        saturday: { slots: [], status: '' },
        sunday: { slots: [], status: '' },
      },
      last_admin_review: obj.last_admin_review,
      unit_history: obj.unit_history,
      vibes: obj.vibes || [],
      // special case where we want to put the detailed description from
      // old card details into the itemized one
      itemized_description: chooseItemizedDescription(
        obj.itemized_description,
        obj.description,
        placeService.defaultDescriptions()
      ),
      // used to keep track of the miscOptions that are also categories,
      // are joined together upon form submission
      misc_options: misc || [],
      // third party info
      related_gmaps_id: obj.related_gmaps_id || '',
      related_yelp_id: obj.related_yelp_id || '',
      related_foursquare_id: obj.related_foursquare_id || '',
    };
  };

  /**
   * @description Maps over and processes data for the form
   * component. This is the function that you need to edit to
   * conform to the various endpoint naming differences with the prop
   * fields of the form input components
   *
   * @param {Object} data - of form/card data
   */
  getProcessedData = (data = {}) => {
    const { placeCategories, vibes, neighborhoods } = this.props;

    return PlaceData.map((inputObj) => {
      let input = {};

      switch (inputObj.name) {
        case 'name':
          input = {
            ...inputObj,
            value: data.name,
            onButtonClick: () => {
              this.editThirdParty();
            },
          };
          break;
        case 'categories':
          input = {
            ...inputObj,
            main_categories: data.main_categories,
            tags: data.categories,
            suggestions: placeCategories || [],
          };
          break;
        case 'vibes':
          input = {
            ...inputObj,
            tags: data.vibes,
            suggestions: vibes || [],
          };
          break;
        case 'checkbox_row':
          input = {
            ...inputObj,
            onChange: this.handleMiscOptionToggle,
            selectedOptions: data.misc_options,
          };
          break;
        case 'neighborhoods':
          input = {
            ...inputObj,
            tags: data.neighborhoods,
            suggestions: getProcessedNeighborhoods(neighborhoods),
            onButtonClick: this.onAutoPopulateLocationsClick,
          };
          break;
        case 'place_hours':
          input = {
            ...inputObj,
            from: data.place_hours,
            errors: '',
          };
          break;
        case 'price_tier':
          input = {
            ...inputObj,
            value:
              data.price_tier !== undefined
                ? {
                    label: getPriceRangeLabel(data.price_tier),
                    value: data.price_tier,
                  }
                : '',
          };
          break;
        case 'booking_links':
          input = {
            ...inputObj,
            value: data.booking_links,
            disabled: data.reservation_tier === 'walk_in_only',
            additionalComponent: (
              <CobCheckbox
                label="reservation_tier" // property name
                displayText="Does not take reservations"
                onClick={(checked, label) => {
                  const reservationType = getReservationTiers();

                  if (checked) {
                    // set val to 'walk_in_only'
                    this.eventHandler(reservationType[5], label);
                  } else {
                    // set val to ''
                    this.eventHandler(reservationType[0], label);
                  }
                }}
                toBeChecked={data.reservation_tier === 'walk_in_only'}
              />
            ),
          };
          break;
        case 'reservation_tier':
          // conditionally renders cased on reservation toggle
          input = {
            ...inputObj,
            value: data.reservation_tier,
            hide: data.reservation_tier === 'walk_in_only',
          };
          break;
        case 'ordering_links':
          input = {
            ...inputObj,
            value: data.ordering_links,
          };
          break;
        case 'last_admin_review':
          input = {
            ...inputObj,
            value: data.last_admin_review
              ? data.last_admin_review.review_status
              : null,
            reviewDate: data.last_admin_review
              ? data.last_admin_review.review_datetime
              : null,
            history: data.unit_history || [],
          };
          break;

        default:
          input = {
            ...inputObj,
            value: data[inputObj.name],
          };
          break;
      }
      return input;
    });
  };

  onShowPreviewModal = (isShow) => {
    this.setState({ showPreviewModal: isShow });
  };

  /**
   * @description This function is specific to updating a Place hours
   * information and packages up a placeHours object to be passed to
   * a redux reducer and ultimately update the database
   *
   * Is currently passed through several layers of components
   * (Place => Form => PlaceHours => EditPopUpModal) where it is fired upon
   * clicking the save button.
   *
   * @param {Object} event - strictly to prevent default
   * @param {Object} weeklyHoursData - weekly hours passed from EditPopUpModal
   */
  saveClick = (event, weeklyHoursData) => {
    const { saveDataToRedux } = this.props;
    event.preventDefault();

    const errorObj = {};

    let count = 0;

    // creates an array to check if there are 'NA' statuses for more than 2 days
    const objEntries = Object.entries(weeklyHoursData);

    for (let i = 0; i < objEntries.length; i += 1) {
      if (objEntries[i][1].status === 'NA') count += 1;

      if (count > 2) {
        errorObj.place_hours = 'Admin should add timings for at least 5 days';
      }
    }

    // if 'NA' count is 2 or less & if there are no errors,
    // update store abd database
    if (count <= 2 && !Object.keys(errorObj).length) {
      saveDataToRedux({ place_hours: weeklyHoursData, error: '' });
      this.onSaveAction();
    }

    // If there's any length to the error object. State is set with error;
    if (Object.keys(errorObj).length) {
      this.setState({ editHoursError: errorObj });
    }
  };

  eventHandler = (event, elemName, classname) => {
    const { saveDataToRedux } = this.props;

    if (
      elemName === 'categories' ||
      elemName === 'main_categories' ||
      elemName === 'vibes' ||
      elemName === 'ordering_links' ||
      elemName === 'booking_links' ||
      elemName === 'misc_options' ||
      elemName === 'admin_review' ||
      elemName === 'itemized_description'
    ) {
      saveDataToRedux({ [elemName]: event });
      return;
    }

    if (Array.isArray(event)) {
      const data = event.map((each) => each.name);

      saveDataToRedux({ [elemName]: data });
      return;
    }

    if (event.name === 'from_time' || event.name === 'to_time') {
      saveDataToRedux({
        [elemName]: event.value && event.value.format('HH:mm'),
      });
      return;
    }

    if (elemName === 'price_tier' || elemName === 'reservation_tier') {
      saveDataToRedux({ [elemName]: event.value });
      return;
    }

    if (event.name === 'address_input') {
      const data = {
        lat: event.value.lat,
        lng: event.value.long,
        address: event.value.address || '',
      };
      saveDataToRedux(data);
      return;
    }

    const { name, value } = event.target;

    saveDataToRedux({ [name]: value });
  };

  onFormAction = (event) => {
    const { currentTab, data } = this.props;
    const { name } = event.target;

    if (name === 'preview') {
      this.onShowPreviewModal(true);
    } else if (
      name === 'draft' &&
      currentTab === 'published' &&
      data &&
      data.id
    ) {
      event.persist();

      this.setState({
        showWarningModal: true,
        onWarningClose: () => {
          this.saveData(event);
          this.setState({ showWarningModal: false, onWarningClose: () => {} });
        },
      });
    } else if (name === 'published' || name === 'draft') {
      this.saveData(event);
    } else if (name === 'delete') {
      event.persist();

      this.setState({
        showWarningModal: true,
        onWarningClose: () => {
          const { deletePlace, closeModal, data } = this.props;

          // we can't call closeModal until the delete is resolved,
          // as closeModal resets the list state to [] which will not
          // allow us to filter the deleted card. So we close it after the
          // delete async function is complete
          const promiseMe = new Promise((resolve, reject) => {
            resolve(deletePlace(data.id));
          });

          promiseMe
            .then(() => {
              closeModal();
            })
            .then(() => {
              this.setState({
                showWarningModal: false,
                onWarningClose: () => {},
              });
            });
        },
      });
    }
  };

  autoPopulateLocation = (placeData, neighborhoods) => {
    const { lat, lng } = placeData;
    const returnedLocations = [];

    for (let i = 0; i < neighborhoods.length; i += 1) {
      const latLongCheck = locationCoordinateCheck(lat, lng, neighborhoods[i]);

      if (neighborhoods[i].boundaries_coordinates && latLongCheck)
        returnedLocations.push(neighborhoods[i]);
    }

    return returnedLocations;
  };

  updateStateDataAndRedux = (dataCopy, formField, formData) => {
    const { saveDataToRedux } = this.props;

    this.setState(
      {
        // updates data to be fed to form fields
        ...this.handlePlaceData(dataCopy),
      }, // updates form data to be saved from form->backend so
      // it'll persist between sessions
      () => saveDataToRedux({ [formField]: formData })
    );
  };

  handleMiscOptionToggle = (checked, uniqueSlug, type) => {
    if (type === 'category') {
      const { place } = this.props;
      const { formData } = place;
      const { misc_options } = formData;

      const optionsCopy = deepCopy(misc_options);

      const hasSlug = hasUniqueSlug(misc_options, uniqueSlug);

      if (checked && !hasSlug) {
        const { categoryMap } = this.props;
        const category = categoryMap[uniqueSlug];

        // prevents undefined from being pushed to the array
        if (category) optionsCopy.push(category);
      }

      if (!checked && hasSlug) {
        for (let i = 0; i < optionsCopy.length; i += 1) {
          if (optionsCopy[i].unique_slug === uniqueSlug) {
            optionsCopy.splice(i, 1);
          }
        }
      }

      this.eventHandler(optionsCopy, 'misc_options');
    }
  };

  toggleThirdPartyModal = () => {
    const { showThirdPartyModal } = this.state;

    this.setState({
      showThirdPartyModal: !showThirdPartyModal,
      // clears the card data if the user has the modal open and closes it
      ...(showThirdPartyModal ? { thirdPartyEdit: null } : {}),
    });
  };

  editThirdParty = () => {
    const { place } = this.props;

    this.setState(
      {
        thirdPartyEdit: place.formData,
      },
      this.toggleThirdPartyModal
    );
  };

  onThirdPartyModalSubmit = (dataObj = {}) => {
    const { place, data, adminCategories, saveData, neighborhoods, location } =
      this.props;
    const { autofilledImages } = place;
    const { tab } = commonService.pathnameHelper(location.pathname);

    const formattedImages = place.autofilledImages.map((image) => {
      const imageTemp = { ...image };

      // // fixed error where is photo_metadata || photo_credit === null
      // // will wash to empty string for DB PUT/PATCH
      if (imageTemp.photo_credits === null) imageTemp.photo_credits = '';
      if (imageTemp.photo_metadata === null) imageTemp.photo_metadata = '';

      if (image.source === 'cobble') {
        delete imageTemp.fileId;

        return imageTemp;
      }

      return imageTemp;
    });

    const formData = placeService.getPreparedFormData(
      dataObj,
      [...formattedImages],
      tab === 'drafts' || tab === 'fetched' || tab === 'queue'
        ? 'draft'
        : 'published'
    );

    formData.set_categories = formData.categories.map((cat) => cat.unique_slug);

    delete formData.categories;

    formData.main_categories = place.formData.main_categories.map(
      (cat) => cat.unique_slug
    );

    formData.set_neighborhoods = getUniqueSlug(
      place.formData.neighborhoods,
      neighborhoods
    );

    formData.should_refetch_3rd_parties_content = true;

    /** Perform validation only on published */
    if (tab === 'published') {
      let errors = placeService.validate(place.formData);

      errors = commonService.validateLinks(place.formData, errors);

      const imageErrors = commonService.validateImages(
        place.images,
        autofilledImages
      )
        ? null
        : PLACE.IMAGE_VALIDATION_MESSAGE;
      if (Object.keys(errors).length === 0 && imageErrors === null) {
        toast.success('Fetching 3rd party updates and refreshing');

        setTimeout(() => {
          // dispatch to save
          saveData(formData, data.id, () => {
            window.location.reload();
          });
        }, 2000);
      } else {
        toast.error('There are some errors in the form');
      }

      this.setState({
        errors,
        imageErrors,
      });
    } else if (tab === 'drafts' || tab === 'fetched' || tab === 'queue') {
      const errors = {};
      const imageErrors = '';
      const validateName = commonService.validateName(place.formData.name);

      if (validateName) {
        errors.name = validateName;
      }

      if (
        !commonService.validatePrimaryCategories(
          place.formData.main_categories,
          place.formData.categories
        )
      ) {
        errors.categories =
          'Primary categories must be included in the selected categories';
      }

      // no validations required for drafting
      if (Object.keys(errors).length === 0) {
        toast.success('Fetching 3rd party updates and refreshing');

        setTimeout(() => {
          // dispatch to save
          saveData(formData, data.id, () => {
            window.location.reload();
          });
        }, 2000);
      } else {
        toast.error('There are some errors in the form');
      }

      this.setState({ errors, imageErrors });
    }
  };

  onAutoPopulateLocationsClick = () => {
    const { data, place, neighborhoods } = this.props;

    const locationGridResult = this.autoPopulateLocation(
      place.formData,
      neighborhoods
    );

    if (data.shouldFetchPlace) {
      // if card was fetched
      this.updateStateDataAndRedux(
        {
          ...place.fetchedPlaceData,
          neighborhoods: locationGridResult,
        },
        'neighborhoods',
        locationGridResult
      );
    } else {
      // if it's a card already fetched
      this.updateStateDataAndRedux(
        {
          ...data,
          neighborhoods: locationGridResult,
        },
        'neighborhoods',
        locationGridResult
      );
    }
  };

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  Place rendering
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  render() {
    const {
      data: propsData,
      uploadImage,
      deleteImage,
      place,
      currentTab,
      populateAutofillImages,
    } = this.props;
    const {
      data: stateData,
      errors,
      showWarningModal,
      onWarningClose,
      shouldDisplayLoader,
      imageErrors,
      showEditHours,
      editHoursError,
      showPreviewModal,
      showThirdPartyModal,
      thirdPartyEdit,
    } = this.state;
    const { formData } = place;
    const updatedData = commonService.getUpdatedData(formData, [...stateData]);
    // eslint-disable-next-line camelcase
    const { name, long_description, price_tier, one_liner_description } =
      formData;
    // Data for preview modal, please update this data with updated one.
    const images = [...place.autofilledImages, ...place.images];

    const previewModalData = {
      previewImage: getImageURL(images, false),
      title: name,
      category: place.formData.categories,
      neighborhood: place.formData.neighborhoods,
      address: place.formData.address,
      price: getPriceRangeLabel(price_tier),
      desc: long_description,
      shortDesc: one_liner_description,
    };

    return (
      <div className="place">
        {shouldDisplayLoader === true ? (
          <div className="overlay-wrapper">
            <Overlay show>
              <Loader />
            </Overlay>
          </div>
        ) : (
          <Fragment>
            <div className="form-content">
              {showWarningModal && (
                <WarningModal
                  message={WARNING_ON_DRAFT}
                  onSubmit={onWarningClose}
                  onCancel={() => {
                    this.setState({ showWarningModal: false });
                  }}
                />
              )}
              <FileUploader
                isDropZonePreviewRequired={place.isDropZonePreviewRequired}
                data={place.autofilledImages}
                onEvent={populateAutofillImages}
                failedUploads={place.failedUploads}
                onAddImage={uploadImage}
                onDeleteImage={deleteImage}
                error={imageErrors}
                onDrop={this.onReOrderImages}
              />
              <div className="form">
                <Form
                  data={updatedData}
                  saveClick={this.saveClick}
                  onEvent={this.eventHandler}
                  errors={errors}
                  onAction={this.onSaveAction}
                  showEditHours={showEditHours}
                  editHoursError={editHoursError}
                />
              </div>
            </div>
            <FormActions
              verticalType="Place"
              onAction={this.onFormAction}
              currentTab={currentTab}
              cardId={propsData.id}
              isNewCard={Object.keys(propsData).length > 0}
              isBtnDisabled={place.isBtnDisabled}
            />
            {showThirdPartyModal && (
              <ThirdPartySearchModal
                verticalType="place"
                showModal={showThirdPartyModal}
                closeModal={this.toggleThirdPartyModal}
                onSubmit={this.onThirdPartyModalSubmit}
                cardData={thirdPartyEdit}
                noDataCallback={this.toggleThirdPartyModal}
              />
            )}
            {/*showPreviewModal && (
              <PreviewWrapper
                data={previewModalData}
                modalClose={() => this.onShowPreviewModal(false)}
                fromPlace
              />
            )*/}
          </Fragment>
        )}
      </div>
    );
  }
}

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Place));
