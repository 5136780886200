/**
 * ************************************
 *
 * @module  DateDropdownSelect.js
 * @author  Matt P
 * @date    01/04/2021
 * @description Component for selecting a date from a grouped dropdown menu
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CobReactSelect } from 'components';

import './DateDropdownSelect.scss';

// ----------------------------------------------------------------------------|
//                                         Utilities
// ----------------------------------------------------------------------------|
/**
 * @description Fires when any of the input fields change
 * updates state and fires onChange prop function
 *
 * @argument {String} str - passed in string representing a date
 *
 * @returns {Object} with month/date/year
 */
const createDateObject = (str) => {
  const returnedObj = {
    month: '',
    day: '',
    year: '',
  };

  if (str && str.split('-').length === 3) {
    const [year, month, day] = str.split('-');

    returnedObj.year = year;
    returnedObj.month = month;
    returnedObj.day = day;
  }

  return returnedObj;
};

/**
 * @description - react-select dropdown styling
 */
const dropdownStyling = () => ({
  control: (styles) => ({
    ...styles,
    minHeight: '45px',
    boxShadow: 'none',
    border: '1px solid rgba(168, 164, 160, 0.5)',
    ':hover': {
      border: '1px solid rgba(168, 164, 160, 0.5)',
    },
  }),
  input: (styles) => ({
    ...styles,
    fontFamily: 'Libre Franklin', // $libre-franklin
    fontSize: '15px',
    fontWeight: '500',
    marginLeft: '10px',
  }),
  placeholder: (styles) => ({
    ...styles,
    fontFamily: 'Libre Franklin', // $libre-franklin
    fontSize: '15px',
    color: '#a8a4a0',
    fontWeight: '500',
  }),
  menuList: (styles) => ({
    ...styles,
    height: '200px',
  }),
  valueContainer: (styles) => ({
    ...styles,
    margin: '0',
    padding: '0',
    marginLeft: '10px',
    fontSize: '15px',
  }),
  singleValue: (styles) => ({
    ...styles,
    marginLeft: '10px',
    fontSize: '15px',

    fontWeight: '400',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    cursor: 'pointer',
    paddingLeft: '20px',
    color: isFocused ? '#f7bc25' : styles.color,
    backgroundColor: isSelected ? styles.color : 'white',
    color: isSelected ? 'black' : styles.color,
    ':hover': {
      color: '#f7bc25',
      backgroundColor: 'white',
    },
    ':active': {
      color: 'black',
      backgroundColor: 'blue',
    },
    ':selected': {
      color: isSelected ? 'black' : '#f7bc25',
      backgroundColor: 'white',
      outline: 'none',
    },
  }),
});

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const DateDropdownSelect = forwardRef((props, ref) => {
  const { type, value, name, onChange, className = '', ...rest } = props;
  const classNames = cx([
    'date-dropdown-select',
    `${className.split(' ').join(',')}`,
  ]);

  const [dateObjectState, setDateObjectState] = useState(
    createDateObject(value)
  );

  /**
   * @description Fires when any of the input fields change
   * updates state and fires onChange prop function
   *
   * @argument {Object} e - event object
   */
  const onChangeHandler = (e) => {
    const updatedDateObject = { ...dateObjectState };

    updatedDateObject[e.type] = e.value;

    setDateObjectState(updatedDateObject);

    const { year } = updatedDateObject;
    let { month, day } = updatedDateObject;

    // default date if only year is selected
    if (!month) month = '07';
    if (!day) day = '31';

    onChange(`${year}-${month}-${day}`, name);
  };

  /**
   * @description Creates days of the week based on month
   * passed in
   *
   * @argument {String} month - event object
   *
   * @returns {Array} of objects for dropdown
   */
  const createDayOptions = (month) => {
    const returnedOptions = [];
    let dayCount = 0;

    if (
      month === '01' ||
      month === '03' ||
      month === '05' ||
      month === '07' ||
      month === '08' ||
      month === '10' ||
      month === '12' ||
      !month
    ) {
      dayCount = 31;
    } else if (
      month === '04' ||
      month === '06' ||
      month === '09' ||
      month === '11'
    ) {
      dayCount = 30;
    } else if (month === '02') {
      dayCount = 29;
    }

    for (let i = 1; i <= dayCount; i += 1) {
      const dayIndex = String(i);
      const dayString = dayIndex.length === 1 ? `0${dayIndex}` : dayIndex;

      returnedOptions.push({
        value: dayString,
        label: dayIndex,
        type: 'day',
      });
    }

    return returnedOptions;
  };

  /**
   * @description Created Month Options for the CobSelectDropdown
   *
   * @returns {Object[]} of objects for dropdown
   */
  const createMonthOptions = () =>
    [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ].map((month, i) => {
      const monthIndex = String(i + 1);

      return {
        value: monthIndex.length === 1 ? `0${monthIndex}` : monthIndex,
        label: month,
        type: 'month',
      };
    });

  /**
   * @description Creates year dropdown options starting from
   * 1920 up until 10 years past the current date.
   *
   * @returns {Array} of objects for dropdown
   */
  const createYearOptions = () => {
    const futureYears = Number(new Date().getFullYear()) + 10;
    const returnedOptions = [];

    for (let i = 1920; i <= futureYears; i += 1) {
      returnedOptions.push({
        value: String(i),
        label: String(i),
        type: 'year',
      });
    }

    return returnedOptions;
  };

  return (
    <div className={classNames}>
      <CobReactSelect
        name="month"
        className="date-dropdown-select__month"
        options={createMonthOptions()}
        onSelect={onChangeHandler}
        value={dateObjectState.month}
        placeholder="Month"
        styles={dropdownStyling()}
        isClearable={false}
      />
      <CobReactSelect
        name="day"
        className="date-dropdown-select__day"
        options={createDayOptions(dateObjectState.month)}
        onSelect={onChangeHandler}
        value={dateObjectState.day}
        placeholder="Day"
        styles={dropdownStyling()}
        isClearable={false}
      />
      <CobReactSelect
        name="year"
        className="date-dropdown-select__year"
        options={createYearOptions()}
        onSelect={onChangeHandler}
        value={dateObjectState.year}
        placeholder="Year"
        styles={dropdownStyling()}
        isClearable={false}
      />
    </div>
  );
});

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
DateDropdownSelect.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
DateDropdownSelect.defaultProps = {
  value: '',
  className: '',
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default React.memo(DateDropdownSelect);
