/**
 * ************************************
 *
 * @module  Tooltip.js
 * @author  Matt P
 * @date    03/08/2021
 * @description Container for tooltip package.
 * wrapping it in a comp makes it easier to automatically render
 * based on passing props
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import ReactTooltip from 'react-tooltip';

// ----------------------------------------------------------------------------|
//                           React Class Component
// ----------------------------------------------------------------------------|
class Tooltip extends Component {
  // --------------------------------------------------------------------------|
  //                            PropTypes Check
  // --------------------------------------------------------------------------|
  static propTypes = {
    show: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
  };

  // --------------------------------------------------------------------------|
  //                            Default Props
  // --------------------------------------------------------------------------|
  static defaultProps = {};

  componentDidMount() {
    const { show } = this.props;

    if (show) {
      this.showTooltip();
    }
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;

    if (show) {
      this.showTooltip();
    } else {
      this.hideTooltip();
    }
  }

  showTooltip() {
    const { id } = this.props;
    const tooltip = document.querySelectorAll(
      `[data-tip][data-for="${id}"]`
    )[0];

    ReactTooltip.show(tooltip);
  }

  hideTooltip() {
    const { id } = this.props;
    const tooltip = document.querySelectorAll(
      `[data-tip][data-for="${id}"]`
    )[0];

    ReactTooltip.hide(tooltip);
  }

  render() {
    const { children, ...props } = this.props;

    if (!children) return null;

    return <ReactTooltip {...props}>{children}</ReactTooltip>;
  }
}

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default Tooltip;
