/**
 * ************************************
 *
 * @module  VerticalForm.js
 * @author  Matt P
 * @date    07/02/2021
 * @description Container for editing a vertical card. Currently renders within
 * the CardViewModal is shown renders when a card is clicked in the
 * VerticalDisplay container
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { PureComponent, Fragment } from 'react';

import PropTypes from 'prop-types';
import {
  arrayOfCategoryDataTypes,
  categoryPropsDataType,
  historyDataType,
  locationDataType,
  neighborhoodDataType,
} from 'utils/typeUtils';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import {
  deleteCardAction,
  fetchNeighborhoodLocationsAction,
} from 'store/actions/VerticalDisplay.action';
import {
  clearImagesAction,
  clearFormStateAction,
  deleteImageAction,
  fetchVerticalFormDetailAction,
  populateAutofillImagesAction,
  populateCollectionCardsAction,
  reinstateUnitAction,
  reOrderImagesAction,
  saveDataAction,
  saveDataToReduxAction,
  uploadImageAction,
} from 'store/actions/VerticalForm.action';

import { toast } from 'react-toastify';

import {
  CardSelection,
  CobCheckbox,
  FileUploader,
  FlagContentModal,
  FlaggedCardNotice,
  Form,
  FormActions,
  Loader,
  Overlay,
  ThirdPartySearchModal,
  WarningModal,
} from 'components';

import {
  applyDrag,
  createDropdownOptions,
  deepCopy,
  formatISODate,
  getDurationTiers,
  getPriceRangeLabel,
  getProcessedNeighborhoods,
  getReservationTiers,
  getUniqueSlug,
  hasUniqueSlug,
  locationCoordinateCheck,
  mapDetailedCategories,
  qualifyArrayRendering,
} from 'utils/utils';
import {
  extractCategoryTypes,
  prepCatMultiDropdownOptions,
} from 'utils/CategoryUtils';

import CommonService from 'services/CommonService';

import currentEnv from 'utils/EnvironmentSpecificValues';

import {
  chooseConstant,
  chooseVerticalService,
} from 'containers/VerticalDisplay/VerticalDisplayUtils';

import { IMAGE_VALIDATION_MESSAGE, VERTICAL_TYPE } from 'constants.js';

import {
  alwaysFetchVerticalSet,
  cardSelectorVerticalTypes,
  chooseFormData,
  getPreparedFormData,
  handleCheckboxRow,
  newCardDesignVerticalSet,
  processIncomingVerticalFormData,
  validateForm,
} from './VerticalFormUtils';

import './VerticalForm.scss';

// ----------------------------------------------------------------------------|
//                        Redux - Property Mapping
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  clearImages: () => dispatch(clearImagesAction()),
  clearFormState: () => dispatch(clearFormStateAction()),
  deleteCard: (data, verticalType) =>
    dispatch(deleteCardAction(data, verticalType)),
  deleteImage: (data) => dispatch(deleteImageAction(data)),
  fetchNeighborhoodLocations: () =>
    dispatch(fetchNeighborhoodLocationsAction()),
  fetchVerticalFormDetail: (data, verticalType) =>
    dispatch(fetchVerticalFormDetailAction(data, verticalType)),
  populateAutofillImages: (data) =>
    dispatch(populateAutofillImagesAction(data)),
  populateCollectionCards: (data) =>
    dispatch(populateCollectionCardsAction(data)),
  reinstateUnit: (data) => dispatch(reinstateUnitAction(data)),
  reOrderImages: (data) => dispatch(reOrderImagesAction(data)),
  saveData: (data, id, successCB, verticalType) =>
    dispatch(saveDataAction(data, id, successCB, verticalType)),
  saveDataToRedux: (data) => dispatch(saveDataToReduxAction(data)),
  uploadImage: (data, verticalType) =>
    dispatch(uploadImageAction(data, verticalType)),
});

const mapStateToProps = (state) => ({
  verticalForm: state.verticalForm,
  categories: state.categories,
  neighborhoods: state.verticalDisplay.neighborhoodLocations,
  neighborhoodsLoaded: state.verticalDisplay.neighborhoodsLoaded,
});

// ----------------------------------------------------------------------------|
//                              Utilities
// ----------------------------------------------------------------------------|
const commonService = CommonService();

// ----------------------------------------------------------------------------|
//                React Class PureComponent - VerticalForm
// ----------------------------------------------------------------------------|
class VerticalForm extends PureComponent {
  // --------------------------------------------------------------------------|
  //                        PropTypes Check
  // --------------------------------------------------------------------------|
  static propTypes = {
    verticalType: PropTypes.string.isRequired,
    currentTab: PropTypes.string.isRequired,
    data: PropTypes.shape({
      id: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.shape({})),
      pretty_id: PropTypes.string,
      shouldFetchCard: PropTypes.bool,
    }).isRequired,
    verticalForm: PropTypes.shape({
      autofilledImages: PropTypes.arrayOf(PropTypes.shape({})),
      images: PropTypes.arrayOf(PropTypes.shape({})),
      formData: PropTypes.shape({
        misc_options: arrayOfCategoryDataTypes,
        neighborhoods: PropTypes.arrayOf(neighborhoodDataType),
        categories: PropTypes.arrayOf(categoryPropsDataType),
        main_categories: PropTypes.arrayOf(categoryPropsDataType),
        name: PropTypes.string,
        title: PropTypes.string,
        rating: PropTypes.arrayOf(categoryPropsDataType),
        instance_type: PropTypes.string,
      }).isRequired,
      fetchedCardData: PropTypes.shape({
        id: PropTypes.string.isRequired,
        pretty_id: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      selectedCards: PropTypes.arrayOf(PropTypes.shape({})),
      failedUploads: PropTypes.arrayOf(PropTypes.string),
      isDropZonePreviewRequired: PropTypes.bool.isRequired,
      isBtnDisabled: PropTypes.bool.isRequired,
    }).isRequired,
    neighborhoods: PropTypes.arrayOf(neighborhoodDataType).isRequired,
    cities: PropTypes.arrayOf(neighborhoodDataType).isRequired,
    neighborhoodsLoaded: PropTypes.bool.isRequired,
    categories: categoryPropsDataType.isRequired,
    location: locationDataType.isRequired,
    history: historyDataType.isRequired,
    closeModal: PropTypes.func.isRequired,
    deleteCard: PropTypes.func.isRequired,
    saveDataToRedux: PropTypes.func.isRequired,
    populateAutofillImages: PropTypes.func.isRequired,
    clearImages: PropTypes.func.isRequired,
    fetchVerticalFormDetail: PropTypes.func.isRequired,
    reOrderImages: PropTypes.func.isRequired,
    uploadImage: PropTypes.func.isRequired,
    deleteImage: PropTypes.func.isRequired,
    saveData: PropTypes.func.isRequired,
    populateCollectionCards: PropTypes.func.isRequired,
    clearFormState: PropTypes.func.isRequired,
    flaggedReports: PropTypes.arrayOf(PropTypes.shape({})),
    reinstateUnit: PropTypes.func.isRequired,
  };

  // --------------------------------------------------------------------------|
  //                        Default Props - VerticalForm
  // --------------------------------------------------------------------------|
  static defaultProps = {};

  // --------------------------------------------------------------------------|
  //                          State - VerticalForm
  // --------------------------------------------------------------------------|
  constructor(props) {
    super(props);

    const { data, saveDataToRedux, flaggedReports } = this.props;
    const initialFormState = this.handleCardData(data);

    this.state = {
      cardErrors: '',
      cardId: data.id,
      errors: {},
      imageErrors: '',
      showEditHours: false,
      shouldFetchCard: data.shouldFetchCard,
      shouldDisplayLoader: false,
      showThirdPartyModal: false,
      showFlagContentModal: false,
      thirdPartyEdit: null,
      flaggedReports: flaggedReports || [],
      ...initialFormState,
    };

    // initially keep the data in the redux store
    saveDataToRedux(this.getToBeProcessedData(data));
  }

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\     VerticalForm lifecycle
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  componentDidMount() {
    const { categories, data, neighborhoodsLoaded, populateAutofillImages } =
      this.props;
    const { shouldFetchCard } = this.state;

    populateAutofillImages(data.images);

    if (shouldFetchCard) {
      this.fetchCardFromServer();
      this.setState({ shouldFetchCard: false, shouldDisplayLoader: true });
    }

    // cats and neighborhoods are async calls to redux saga, and should load
    // before the form renders. The shouldDisplayLoader flag is set for this
    // reason, and is flipped when the component updates on prop fulfillment
    if (!neighborhoodsLoaded || !categories.initialDataLoaded) {
      this.setState({ shouldDisplayLoader: true });
    }
  }

  componentDidUpdate() {
    const {
      categories,
      currentTab,
      data,
      neighborhoodsLoaded,
      populateAutofillImages,
      populateCollectionCards,
      saveDataToRedux,
      verticalForm,
      verticalType,
    } = this.props;

    const { cardId, shouldDisplayLoader } = this.state;
    const { fetchedCardData } = verticalForm;

    // used for updating and rendering a fetched place
    // also checks if the async calls have come back and will flip
    // the loaderDisplay flag when loaded.
    if (data.shouldFetchCard) {
      if (
        neighborhoodsLoaded &&
        categories.initialDataLoaded &&
        fetchedCardData &&
        cardId === fetchedCardData.id &&
        shouldDisplayLoader
      ) {
        this.setState({
          shouldDisplayLoader: false,
          ...this.handleCardData(fetchedCardData),
        });

        // alwaysFetchVerticalSet vertical types will always be fetched
        // even if they are populated on the main vertical
        // initial fetch due to speed issues with sending all
        // card data - this will populate the fetched cards
        // for the CardSelection comp once fetchedCardData
        // is returned
        if (alwaysFetchVerticalSet.has(verticalType)) {
          populateCollectionCards(fetchedCardData);
        }

        if (currentTab === 'published' || currentTab === 'drafts') {
          this.replaceUrl(fetchedCardData);
        }

        saveDataToRedux(this.getToBeProcessedData(fetchedCardData));
        populateAutofillImages(fetchedCardData.images);
      }
    } else if (
      neighborhoodsLoaded &&
      categories.initialDataLoaded &&
      !fetchedCardData &&
      shouldDisplayLoader
    ) {
      this.setState({
        shouldDisplayLoader: false,
        ...this.handleCardData(data),
      });

      saveDataToRedux(this.getToBeProcessedData(data));
    }
  }

  componentWillUnmount() {
    const { clearImages, clearFormState, verticalForm } = this.props;

    clearFormState();
    clearImages(verticalForm);
  }

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\       VerticalForm methods
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  /**
   * @description Used to replace the URl with the correct section
   * (Drafts/Published/Fetched) as per the cards 'status' to stay consistent
   *
   * @param {Object} data - card object
   */
  replaceUrl = (data = {}) => {
    // check if the URl pathname is off
    const { location, history } = this.props;
    const { section, tab, id } = commonService.pathnameHelper(
      location.pathname
    );

    if (data.status) {
      const cardStatus = data.status === 'draft' ? 'drafts' : data.status;

      if (tab !== cardStatus) {
        history.replace({
          pathname: `/${section}/${cardStatus}/${data.id || id}`,
        });
      }
    }
  };

  /**
   * @description Fetch card data object from server using ID
   * if not present in the already fetched list of options
   */
  fetchCardFromServer = () => {
    const { fetchVerticalFormDetail, verticalType } = this.props;
    const { cardId } = this.state;

    fetchVerticalFormDetail({ cardId }, verticalType);
  };

  /**
   * @description Handle card object data: prepare form, display, etc..
   *
   * @param {Object} cardDataToProcess - of card data to process
   *
   * @returns {Object}
   */
  handleCardData = (cardDataToProcess) => {
    const processedData = this.getProcessedData(
      this.getToBeProcessedData(cardDataToProcess)
    );

    // const placeData = processedData.filter((obj) => obj.name === 'place_hours');

    // if (placeData[0].from) {
    //   const placeHours = JSON.parse(JSON.stringify(placeData[0].from));
    // }

    return { data: processedData, editHoursError: { place_hours: '' } };
  };

  /**
   * @description fires when the edit hours modal (Place)
   * fires
   *
   * @param {Object} event
   */
  onSaveAction = (event) => {
    if (event) event.preventDefault();

    this.setState((prevState) => ({
      showEditHours: !prevState.showEditHours,
      editHoursError: { place_hours: '' },
    }));
  };

  /**
   * @description reorders the array of images
   *
   * @param {Object} dropData
   */
  onReOrderImages = (dropData) => {
    const { verticalForm, reOrderImages } = this.props;
    const { autofilledImages, images } = verticalForm;

    reOrderImages(applyDrag([...autofilledImages, ...images], dropData));
  };

  /**
   * @description reinstates an archived card
   *
   * @param {Object} dropData
   */
  onReinstateUnit = () => {
    const { closeModal, data, reinstateUnit, verticalForm } = this.props;
    const { pretty_id: directPrettyId } = data;
    const { fetchedCardData } = verticalForm;

    const reinstateData =
      directPrettyId ||
      (fetchedCardData !== null && fetchedCardData.pretty_id) ||
      undefined;

    reinstateUnit(reinstateData);
    closeModal();
  };

  /**
   * @description fires to saveData. Runs several validation checks
   * depending on the verticalType
   *
   * @param {Object} e - Event Object.
   */
  saveData = (e) => {
    const {
      verticalType,
      verticalForm,
      data,
      closeModal,
      saveData,
      neighborhoods,
    } = this.props;

    let submissionType = '';

    if (e.target) {
      if (e.target.name === 'published') submissionType = 'published';
      if (e.target.name === 'draft') submissionType = 'draft';
    }

    // error checking based on submission type
    const errors = {
      ...validateForm(verticalForm.formData, submissionType, verticalType),
    };
    let imageErrors = null;
    let cardErrors = null;

    if (submissionType === 'published') {
      imageErrors = commonService.validateImages(
        verticalForm.images,
        verticalForm.autofilledImages
      )
        ? null
        : IMAGE_VALIDATION_MESSAGE;
    }

    if (
      submissionType === 'published' &&
      verticalType === VERTICAL_TYPE.COLLECTION
    ) {
      const { selectedCards } = verticalForm;

      cardErrors = chooseVerticalService(verticalType).validateSelectedCards(
        selectedCards
      )
        ? null
        : 'You must select at least 2 published cards for a collection';
    }

    // if no errors, prep form data for submission
    if (
      Object.keys(errors).length === 0 &&
      imageErrors === null &&
      cardErrors === null
    ) {
      const formattedImages = verticalForm.autofilledImages.map((image) => {
        const imageTemp = { ...image };
        if (image.source != null) {
          imageTemp.source = image.source.toLowerCase();
        }
        // // fixed error where is photo_metadata || photo_credit === null
        // // will wash to empty string for DB PUT/PATCH
        if (imageTemp.photo_credits === null) imageTemp.photo_credits = '';
        if (imageTemp.photo_metadata === null) imageTemp.photo_metadata = '';

        if (image.source === 'cobble') {
          delete imageTemp.fileId;

          return imageTemp;
        }

        return imageTemp;
      });

      const formData = getPreparedFormData(
        verticalForm.formData,
        verticalType !== VERTICAL_TYPE.MOVIE
          ? [...formattedImages]
          : [
              // Special method for submitting the correct property data
              // on movie image objects
              ...chooseVerticalService(verticalType).convertImageLinks(
                formattedImages
              ),
            ],
        submissionType,
        verticalType,
        verticalForm.selectedCards && Array.isArray(verticalForm.selectedCards)
          ? { selectedCards: verticalForm.selectedCards }
          : {}
      );

      if (
        verticalType === VERTICAL_TYPE.PLACE ||
        verticalType === VERTICAL_TYPE.EVENT
      ) {
        formData.set_neighborhoods = getUniqueSlug(
          verticalForm.formData.neighborhoods,
          neighborhoods
        );
      }

      if (verticalType === VERTICAL_TYPE.COLLECTION) {
        formData.location_areas_slugs = getUniqueSlug(
          verticalForm.formData.neighborhoods,
          neighborhoods
        );
      }

      // dispatch to save
      saveData(formData, data.id, closeModal, verticalType);
    } else {
      toast.error('There are some errors in the form');
    }

    // either reset or set errors
    this.setState({ errors, imageErrors, cardErrors });
  };

  /**
   * @description returns an object with the correct key/value pairs
   * for editing
   *
   * @param {Object} obj
   */
  getToBeProcessedData = (obj) => {
    const { categories, verticalType } = this.props;

    return {
      ...processIncomingVerticalFormData(
        obj,
        categories.categoryMap,
        verticalType
      ),
    };
  };

  /**
   * @description Maps over and processes data for the form
   * component. This is the function that you need to edit to
   * conform to the various endpoint naming differences with the prop
   * fields of the form input components
   *
   * @param {Object} data - of form/card data
   */
  getProcessedData = (data = {}) => {
    const { categories, neighborhoods, cities, verticalType } = this.props;

    // temporary need a method to choose based on vertical
    const { vibes, mealTypes, diet } = extractCategoryTypes(
      mapDetailedCategories(categories.adminCategories, categories.categoryMap)
    );

    // Collections only has city level selections
    const verticalSpecificNeighborhood = (verticalType === VERTICAL_TYPE.COLLECTION) ? cities.cities : neighborhoods;

    const chooseCheckboxRow = () => {
      switch (verticalType) {
        case VERTICAL_TYPE.RECIPE: {
          return {
            options: diet.map((cat) => ({
              displayText: cat.name,
              uniqueSlug: cat.unique_slug,
              id: cat.id,
              type: 'category',
            })),
          };
        }
        case 'place': {
          return { selectedOptions: data.misc_options };
        }
        default: {
          return {};
        }
      }
    };

    return chooseFormData(verticalType).map((inputObj) => {
      if (
        newCardDesignVerticalSet.has(verticalType) &&
        inputObj.name === 'main_categories'
      ) {
        const { main_categories } = data;

        return {
          ...inputObj,
          options: prepCatMultiDropdownOptions(
            categories.verticalDropdownMap,
            categories.categoryMap
          ),
          values: main_categories,
          error: '',
        };
      }

      let input = {};

      switch (inputObj.name) {
        case 'address_input':
          input = {
            ...inputObj,
            value: data.event_venue_name,
            errors: '',
            disabled: hasUniqueSlug(
              data.misc_options,
              currentEnv.uniqueSlugs.virtualEvent
            ),
            additionalComponent: (
              <CobCheckbox
                label={currentEnv.uniqueSlugs.virtualEvent} // cat unique slug
                displayText="Stay-In/Virtual"
                onClick={(checked, label) => {
                  this.handleMiscOptionToggle(checked, label, 'category');
                }}
                toBeChecked={hasUniqueSlug(
                  data.misc_options,
                  currentEnv.uniqueSlugs.virtualEvent
                )}
              />
            ),
          };
          break;
        case 'amount_per':
          input = {
            ...inputObj,
            amount: data.event_amount,
            amount_max: data.event_amount_max,
            per: data.event_amount_suffix,
            errors: '',
          };
          break;
        case 'booking_links':
          input = {
            ...inputObj,
            value: data.booking_links,
            disabled: data.reservation_tier === 'walk_in_only',
            additionalComponent: (
              <CobCheckbox
                label="reservation_tier" // property name
                displayText="Does not take reservations"
                onClick={(checked, label) => {
                  const reservationType = getReservationTiers();

                  if (checked) {
                    // set val to 'walk_in_only'
                    this.eventHandler(reservationType[5], label);
                  } else {
                    // set val to ''
                    this.eventHandler(reservationType[0], label);
                  }
                }}
                toBeChecked={data.reservation_tier === 'walk_in_only'}
              />
            ),
          };
          break;
        case 'categories':
          input = {
            ...inputObj,
            main_categories: data.main_categories,
            tags: data.categories,
            // all categories available to tag
            suggestions: categories.categoryTagOptions || [],
          };
          break;
        case 'checkbox_row':
          input = {
            ...inputObj,
            onChange: this.handleMiscOptionToggle,
            ...chooseCheckboxRow(),
            ...handleCheckboxRow(data, verticalType),
          };
          break;
        case 'date_range':
          input = {
            ...inputObj,
            from: data.start_date,
            to: data.end_date,
            errors: '',
          };
          break;
        case 'duration':
          input = {
            ...inputObj,
            value:
              data.duration !== undefined && data.duration !== null
                ? getDurationTiers(data.duration).pop()
                : getDurationTiers().pop(),
          };
          break;
        case 'hour_range':
          input = {
            ...inputObj,
            from: data.from_time,
            to: data.to_time,
            errors: '',
          };
          break;
        case 'last_admin_review':
          input = {
            ...inputObj,
            value: data.last_admin_review
              ? data.last_admin_review.review_status
              : null,
            reviewDate: data.last_admin_review
              ? data.last_admin_review.review_datetime
              : null,
            history: data.unit_history || [],
          };
          break;
        case 'meal_type':
          input = {
            ...inputObj,
            tags: data.meal_type,
            suggestions: categories.mealTypes || [],
          };
          break;
        case 'name':
          input = {
            ...inputObj,
            value: data.name,
            onButtonClick: () => {
              this.editThirdParty(data);
            },
          };
          break;
        case 'neighborhoods':
          input = {
            ...inputObj,
            tags: data.neighborhoods,
            suggestions: getProcessedNeighborhoods(
              verticalSpecificNeighborhood
            ),
            onButtonClick: this.onAutoPopulateLocationsClick,
          };
          break;
        case 'ordering_links':
          input = {
            ...inputObj,
            value: data.ordering_links,
          };
          break;
        case 'pairings':
          input = {
            ...inputObj,
            title: 'Pairings',
            verticalTypes: [{ label: 'Places', value: 'PLACES' }],
            pairingReasonsOptions: newCardDesignVerticalSet.has(verticalType)
              ? categories.pairingReasonsOptions
              : null,
            verticalType,
          };
          break;
        case 'place_hours':
          input = {
            ...inputObj,
            from: data.place_hours,
            errors: '',
          };
          break;
        case 'price_tier':
          input = {
            ...inputObj,
            value:
              data.price_tier !== undefined
                ? {
                    label: getPriceRangeLabel(data.price_tier),
                    value: data.price_tier,
                  }
                : '',
          };
          break;
        case 'rating':
          input = {
            ...inputObj,
            value:
              Array.isArray(data.rating) && data.rating.length
                ? data.rating[0].name
                : '',
            values:
              data.instance_type === 'movie'
                ? createDropdownOptions(categories.movieRatings)
                : createDropdownOptions(categories.tvRatings),
          };
          break;
        case 'links_to_movie':
          input = {
            ...inputObj,
            value: data.links_to_movie,
          };
          break;
        case 'recipe_prep_&_duration':
          input = {
            ...inputObj,
            leftValue:
              data.prep_time !== undefined && data.prep_time !== null
                ? getDurationTiers(data.prep_time).pop()
                : getDurationTiers().pop(),
            rightValue:
              data.duration !== undefined && data.duration !== null
                ? getDurationTiers(data.duration).pop()
                : getDurationTiers().pop(),
          };
          break;
        case 'recurring_event':
          input = {
            ...inputObj,
            isRecurring: data.recurring_event,
            recurring_days: data.recurring_days,
            errors: '',
          };
          break;
        case 'release_date':
          input = {
            ...inputObj,
            value:
              typeof data.release_date === 'string'
                ? data.release_date.split('-')[0]
                : data.release_date,
          };
          break;
        case 'reservation_tier':
          // conditionally renders cased on reservation toggle
          input = {
            ...inputObj,
            value: data.reservation_tier,
            hide: data.reservation_tier === 'walk_in_only',
          };
          break;
        case 'title':
          input = {
            ...inputObj,
            value: data.title,
            onButtonClick: () => {
              this.editThirdParty();
            },
          };
          break;
        case 'vibes':
          input = {
            ...inputObj,
            tags: data.vibes,
            suggestions: vibes || [],
          };
          break;
        case 'perfect_for':
          input = {
            ...inputObj,
            tags: data.perfect_for,
            suggestions: categories.perfectFor || [],
          };
          break;
        case 'noteworthy_categories':
          input = {
            ...inputObj,
            tags: data.noteworthy_categories,
            suggestions: categories.noteworthy || [],
          };
          break;

        default:
          input = {
            ...inputObj,
            value: data[inputObj.name],
          };
          break;
      }
      return input;
    });
  };

  /**
   * @description This function is specific to updating a Place hours
   * information and packages up a placeHours object to be passed to
   * a redux reducer and ultimately update the database
   *
   * Is currently passed through several layers of components
   * (Place => Form => PlaceHours => EditPopUpModal) where it is fired upon
   * clicking the save button.
   *
   * @param {Object} event - strictly to prevent default
   * @param {Object} weeklyHoursData - weekly hours passed from EditPopUpModal
   */
  saveClick = (event, weeklyHoursData) => {
    const { saveDataToRedux } = this.props;
    event.preventDefault();

    const errorObj = {};

    let count = 0;

    // creates an array to check if there are 'NA' statuses for more than 2 days
    const objEntries = Object.entries(weeklyHoursData);

    for (let i = 0; i < objEntries.length; i += 1) {
      if (objEntries[i][1].status === 'NA') count += 1;

      if (count > 2) {
        errorObj.place_hours = 'Admin should add timings for at least 5 days';
      }
    }

    // if 'NA' count is 2 or less & if there are no errors,
    // update store abd database
    if (count <= 2 && !Object.keys(errorObj).length) {
      saveDataToRedux({ place_hours: weeklyHoursData, error: '' });
      this.onSaveAction();
    }

    // If there's any length to the error object. State is set with error;
    if (Object.keys(errorObj).length) {
      this.setState({ editHoursError: errorObj });
    }
  };

  /**
   * @description maps any input or change to the form. Updates redux
   *
   * @param {Object || String} event - data being passed || element name
   * @param {String} elemName - name of that input
   *
   * @return {void} to exit function
   */
  eventHandler = (event, elemName) => {
    const { saveDataToRedux } = this.props;

    if (
      elemName === 'admin_review' ||
      elemName === 'booking_links' ||
      elemName === 'categories' ||
      elemName === 'neighborhoods' ||
      elemName === 'itemized_description' ||
      elemName === 'main_categories' ||
      // place/event specific
      elemName === 'misc_options' ||
      elemName === 'ordering_links' ||
      elemName === 'menu_links' ||
      elemName === 'vibes' ||
      elemName === 'perfect_for' ||
      elemName === 'noteworthy_categories' ||
      // activity/recreation specific
      elemName === 'links_to_activity' ||
      // recipe specific
      elemName === 'diet' ||
      elemName === 'ingredients' ||
      elemName === 'links_to_recipe' ||
      elemName === 'meal_type' ||
      // movie specific
      elemName === 'images' ||
      elemName === 'links_to_movie' ||
      elemName === 'release_date'
    ) {
      saveDataToRedux({ [elemName]: event });
      return;
    }

    if (elemName === 'third_party_ids') {
      saveDataToRedux({ ...event });
      return;
    }

    if (Array.isArray(event)) {
      const data = event.map((each) => each.name);

      saveDataToRedux({ [elemName]: data });
      return;
    }

    if (
      elemName === 'duration' ||
      elemName === 'instance_type' ||
      elemName === 'prep_time'
    ) {
      saveDataToRedux({ [elemName]: event.value });
      return;
    }

    if (elemName === 'rating') {
      saveDataToRedux({ [elemName]: [event.value] });
      return;
    }

    if (event.name === 'from_time' || event.name === 'to_time') {
      saveDataToRedux({
        [elemName]: event.value && event.value.format('HH:mm'),
      });
      return;
    }

    if (event.name === 'start_date' || event.name === 'end_date') {
      saveDataToRedux({
        [event.name]: formatISODate(event.value, 'YYYY-MM-DD'),
      });
      return;
    }

    if (
      elemName === 'price_tier' ||
      elemName === 'reservation_tier' ||
      elemName === 'duration'
    ) {
      saveDataToRedux({ [elemName]: event.value });
      return;
    }

    if (
      event.name === 'event_amount' ||
      event.name === 'event_amount_max' ||
      event.name === 'event_amount_suffix' ||
      event.name === 'event_hours'
    ) {
      saveDataToRedux({ [event.name]: event.value });
      return;
    }

    if (event.name === 'recurring_event') {
      saveDataToRedux({
        recurring_days: event.events,
        recurring_event: event.isRecurring,
      });
      return;
    }

    if (event.name === 'address_input') {
      const data = {
        lat: event.value.lat,
        lng: event.value.long,
        address: event.value.address || '',
      };
      saveDataToRedux(data);
      return;
    }

    const { name, value } = event.target;

    saveDataToRedux({ [name]: value });
  };

  toggleFlagContentModal = () => {
    const { showFlagContentModal } = this.state;

    this.setState({ showFlagContentModal: !showFlagContentModal });
  };

  /**
   * @description fires when any of the form action buttons (bottom of form)
   * are clicked - ie: "MOVE TO DRAFTS" / "PUBLISHED" / "PREVIEW" and passes
   * data to be validated for a DB PUT/PATCH
   *
   * @param {Object} event - Event Object.
   */
  onFormAction = (event) => {
    const { currentTab, data } = this.props;
    const { name } = event.target;

    if (name === 'preview') {
      this.onShowPreviewModal(true);
    } else if (
      name === 'draft' &&
      currentTab === 'published' &&
      data &&
      data.id
    ) {
      event.persist();

      this.setState({
        showWarningModal: true,
        onWarningClose: () => {
          this.saveData(event);
          this.setState({ showWarningModal: false, onWarningClose: () => {} });
        },
      });
    } else if (name === 'published' || name === 'draft') {
      this.saveData(event);
    } else if (name === 'delete') {
      event.persist();

      this.setState({
        showWarningModal: true,
        onWarningClose: () => {
          const { deleteCard, closeModal, verticalType } = this.props;

          // we can't call closeModal until the delete is resolved,
          // as closeModal resets the list state to [] which will not
          // allow us to filter the deleted card. So we close it after the
          // delete async function is complete
          const promiseMe = new Promise((resolve) => {
            resolve(deleteCard(data.id, verticalType));
          });

          promiseMe
            .then(() => {
              closeModal();
            })
            .then(() => {
              this.setState({
                showWarningModal: false,
                onWarningClose: () => {},
              });
            });
        },
      });
    } else if (name === 'flag') {
      this.toggleFlagContentModal();
    } else if (name === 'reinstate') {
      this.onReinstateUnit();
    }
  };

  /**
   * @description updates state then redux
   *
   * @param {Object} dataCopy - card Object.
   * @param {String} formField - of formData
   * @param {Object} formData - of formData
   */
  updateStateDataAndRedux = (dataCopy, formField, formData) => {
    const { saveDataToRedux } = this.props;
    this.setState(
      {
        // updates data to be fed to form fields
        ...this.handleCardData(dataCopy),
      }, // updates form data to be saved from form->backend so
      // it'll persist between sessions
      () => saveDataToRedux({ [formField]: formData })
    );
  };

  /**
   * @description handles the toggle of some forms checkboxes
   * that toggle categories under the hood
   *
   * @param {Boolean} checked
   * @param {String} uniqueSlug - of category
   * @param {String} type - of checkbox
   */
  handleMiscOptionToggle = (checked, uniqueSlug, type) => {
    if (type === 'category') {
      const { verticalForm, verticalType } = this.props;
      const { formData } = verticalForm;
      const { misc_options, diet } = formData;
      const typeOption =
        verticalType === VERTICAL_TYPE.RECIPE ? diet : misc_options;

      const optionsCopy = deepCopy(typeOption);

      const hasSlug = hasUniqueSlug(typeOption, uniqueSlug);

      if (checked && !hasSlug) {
        const { categories } = this.props;
        const { categoryMap } = categories;
        const category = categoryMap[uniqueSlug];

        // prevents undefined from being pushed to the array
        if (category) optionsCopy.push(category);
      }

      if (!checked && hasSlug) {
        for (let i = 0; i < optionsCopy.length; i += 1) {
          if (optionsCopy[i].unique_slug === uniqueSlug) {
            optionsCopy.splice(i, 1);
          }
        }
      }

      this.eventHandler(
        optionsCopy,
        verticalType === VERTICAL_TYPE.RECIPE ? 'diet' : 'misc_options'
      );
    }
  };

  /**
   * @description Toggles the ThirdPartySearch modal for the
   * Place and Movie vertical forms
   */
  toggleThirdPartyModal = () => {
    const { showThirdPartyModal } = this.state;

    this.setState({
      showThirdPartyModal: !showThirdPartyModal,
      // clears the card data if the user has the modal open and closes it
      ...(showThirdPartyModal ? { thirdPartyEdit: null } : {}),
    });
  };

  /**
   * @description sets up editing of a card by pulling the cards
   * current form data
   */
  editThirdParty = () => {
    const { verticalForm } = this.props;

    this.setState(
      {
        thirdPartyEdit: verticalForm.formData,
      },
      this.toggleThirdPartyModal
    );
  };

  /**
   * @description on submission of a third party modal
   *
   * @param {Object} dataObj - card object
   */
  onThirdPartyModalSubmit = (dataObj = {}) => {
    const {
      verticalForm,
      verticalType,
      data,
      categories,
      saveData,
      neighborhoods,
      location,
    } = this.props;
    const { autofilledImages } = verticalForm;
    const { tab } = commonService.pathnameHelper(location.pathname);

    const formattedImages = verticalForm.autofilledImages.map((image) => {
      const imageTemp = { ...image };

      // // fixed error where is photo_metadata || photo_credit === null
      // // will wash to empty string for DB PUT/PATCH
      if (imageTemp.photo_credits === null) imageTemp.photo_credits = '';
      if (imageTemp.photo_metadata === null) imageTemp.photo_metadata = '';

      if (image.source === 'cobble') {
        delete imageTemp.fileId;

        return imageTemp;
      }

      return imageTemp;
    });

    const formData = getPreparedFormData(
      dataObj,
      verticalType !== VERTICAL_TYPE.MOVIE
        ? [...formattedImages]
        : [
            // Special method for submitting the correct property data
            // on movie image objects
            ...chooseVerticalService(verticalType).convertImageLinks(
              formattedImages
            ),
          ],
      tab === 'drafts' || tab === 'fetched' || tab === 'queue'
        ? 'draft'
        : 'published',
      verticalType
    );

    if (
      verticalType === VERTICAL_TYPE.PLACE ||
      verticalType === VERTICAL_TYPE.EVENT
    ) {
      formData.set_neighborhoods = getUniqueSlug(
        verticalForm.formData.neighborhoods,
        neighborhoods
      );
    }

    formData.should_refetch_3rd_parties_content = true;

    /** Perform validation only on published */
    if (tab === 'published') {
      let errors = {
        ...validateForm(verticalForm.formData, 'published', verticalType),
      };

      // chooseFormData(verticalType).validate(verticalForm.formData);

      errors = commonService.validateLinks(verticalForm.formData, errors);

      const imageErrors = commonService.validateImages(
        verticalForm.images,
        autofilledImages
      )
        ? null
        : IMAGE_VALIDATION_MESSAGE;
      if (Object.keys(errors).length === 0 && imageErrors === null) {
        toast.success('Fetching 3rd party updates and refreshing');

        setTimeout(() => {
          // dispatch to save
          saveData(
            formData,
            data.id,
            () => {
              window.location.reload();
            },
            verticalType
          );
        }, 2000);
      } else {
        toast.error('There are some errors in the form');
      }

      this.setState({
        errors,
        imageErrors,
      });
    } else if (tab === 'drafts' || tab === 'fetched' || tab === 'queue') {
      const errors = {
        ...validateForm(verticalForm.formData, 'draft', verticalType),
      };
      const imageErrors = '';

      if (Object.keys(errors).length === 0) {
        toast.success('Fetching 3rd party updates and refreshing');

        setTimeout(() => {
          // dispatch to save
          saveData(
            formData,
            data.id,
            () => {
              window.location.reload();
            },
            verticalType
          );
        }, 2000);
      } else {
        toast.error('There are some errors in the form');
      }

      this.setState({ errors, imageErrors });
    }
  };

  /**
   * @description searches neighborhood slugs and auto-populates
   * neighborhood tags on the form. Only applicable on forms with
   * an address field
   *
   * @param {Object} cardData - card Object.
   * @param {Array} neighborhoods - of neighborhood objects
   *
   * @returns {Array} - of neighborhood objects
   */
  autoPopulateLocation = (cardData = {}, neighborhoods = []) => {
    const { lat, lng } = cardData;
    const returnedLocations = [];

    neighborhoods.forEach((neighborhood) => {
      const latLongCheck = locationCoordinateCheck(lat, lng, neighborhood);

      if (neighborhood.boundaries_coordinates && latLongCheck)
        returnedLocations.push(neighborhood);
    });

    return returnedLocations;
  };

  /**
   * @description fires on the auto-populate location button
   * checks if this was from fetched or not so it knows
   * where that good data is at
   */
  onAutoPopulateLocationsClick = () => {
    const { data, verticalForm, neighborhoods } = this.props;

    const locationGridResult = this.autoPopulateLocation(
      verticalForm.formData,
      neighborhoods
    );

    this.updateStateDataAndRedux(
      {
        ...(verticalForm.formData),
        neighborhoods: locationGridResult,
      },
      'neighborhoods',
      locationGridResult
    );
  };

  filterClosedReport = (reportId) => {
    const { flaggedReports } = this.state;

    this.setState({
      flaggedReports: flaggedReports.filter(({ id }) => id !== reportId),
    });
  };

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\   VerticalForm rendering
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  render() {
    const {
      verticalType,
      currentTab,
      categories,
      data: propsData,
      deleteImage,
      populateAutofillImages,
      uploadImage,
      verticalForm,
    } = this.props;

    const {
      autofilledImages,
      isBtnDisabled,
      isDropZonePreviewRequired,
      failedUploads,
      fetchedCardData = {},
      formData,
    } = verticalForm;

    const {
      data: stateData,
      errors,
      cardErrors,
      showWarningModal,
      onWarningClose,
      shouldDisplayLoader,
      imageErrors,
      showEditHours,
      editHoursError,
      showFlagContentModal,
      showThirdPartyModal,
      thirdPartyEdit,
      flaggedReports,
    } = this.state;

    const updatedData =
      verticalType !== VERTICAL_TYPE.MOVIE
        ? commonService.getUpdatedData(formData, [...stateData])
        : commonService
            .getUpdatedData(formData, [...stateData])
            .map((update) => {
              // some extra special handling since the rating dropdown options
              // have to change if the user selects a 'show' or 'movie'

              // this is unique to movie vertical and is not a 'commonService'
              if (update.name === 'rating') {
                const updateCopy = deepCopy(update);

                updateCopy.value =
                  Array.isArray(formData.rating) &&
                  formData.rating.length &&
                  formData.rating[0].name
                    ? formData.rating[0].name
                    : '';

                updateCopy.values =
                  formData.instance_type === 'movie'
                    ? createDropdownOptions(categories.movieRatings)
                    : createDropdownOptions(categories.tvRatings);

                return updateCopy;
              }

              return update;
            });

    // activity????
    // updatedData.event_venue_name = data.event_venue_name;

    return (
      <div className="vertical-form">
        {shouldDisplayLoader === true ? (
          <div className="overlay-wrapper">
            <Overlay show>
              <Loader />
            </Overlay>
          </div>
        ) : (
          <Fragment>
            <div className="form-content">
              {showWarningModal && (
                <WarningModal
                  message={chooseConstant(verticalType).WARNING_ON_DRAFT}
                  onSubmit={onWarningClose}
                  onCancel={() => {
                    this.setState({ showWarningModal: false });
                  }}
                />
              )}
              {qualifyArrayRendering(flaggedReports) ? (
                <FlaggedCardNotice
                  flaggedCardData={flaggedReports}
                  successCallback={this.filterClosedReport}
                />
              ) : null}
              {verticalType === VERTICAL_TYPE.COLLECTION && (
                <CardSelection
                  title="Add Activities"
                  verticalType={verticalType}
                  verticalTypes={cardSelectorVerticalTypes}
                  errors={cardErrors}
                />
              )}
              <FileUploader
                title={
                  verticalType === VERTICAL_TYPE.COLLECTION
                    ? 'Featured Image / GIF (First image will be shown in app)'
                    : 'Card Images'
                }
                isDropZonePreviewRequired={isDropZonePreviewRequired}
                data={autofilledImages}
                onEvent={populateAutofillImages}
                failedUploads={failedUploads}
                onAddImage={(imgData) => {
                  uploadImage(imgData, verticalType);
                }}
                onDeleteImage={deleteImage}
                error={imageErrors}
                onDrop={this.onReOrderImages}
                largePreview
              />
              <div className="form">
                <Form
                  data={updatedData}
                  saveClick={this.saveClick}
                  onEvent={this.eventHandler}
                  errors={errors}
                  onAction={this.onSaveAction}
                  showEditHours={showEditHours}
                  editHoursError={editHoursError}
                />
              </div>
            </div>
            <FormActions
              verticalType={verticalType}
              onAction={this.onFormAction}
              cardId={propsData.id}
              isNewCard={Object.keys(propsData).length > 0}
              isBtnDisabled={isBtnDisabled}
              currentTab={currentTab}
            />
            {showFlagContentModal && (
              <FlagContentModal
                show={showFlagContentModal}
                contentPrettyId={
                  propsData.pretty_id || fetchedCardData.pretty_id
                }
                modalClosed={this.toggleFlagContentModal}
              />
            )}
            {showThirdPartyModal && (
              <ThirdPartySearchModal
                verticalType={verticalType}
                showModal={showThirdPartyModal}
                closeModal={this.toggleThirdPartyModal}
                onSubmit={this.onThirdPartyModalSubmit}
                cardData={thirdPartyEdit}
                noDataCallback={this.toggleThirdPartyModal}
              />
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerticalForm)
);
