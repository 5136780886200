/**
 * ************************************
 *
 * @module  CuratedCard.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Form edit component for CuratedCard
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  fetchNeighborhoodLocationsAction,
  clearSelectedPlacesAction,
} from 'store/actions/Places.action';

import {
  deleteImageAction,
  uploadImageAction,
  populateAutofillImagesAction,
  saveDataToReduxAction,
  saveDataAction,
  clearData,
  fetchCuratedCardAction,
  deSelectedCardAction,
  reOrderImagesAction,
} from 'store/actions/CuratedCard.action';

import {
  getPriceRangeLabel,
  getProcessedNeighborhoods,
  getUniqueSlug,
  getImageURL,
  formatTimeString,
  formatISODate,
  applyDrag,
  qualifyArrayRendering,
} from 'utils/utils';

import { toast } from 'react-toastify';

import CommonService from 'services/CommonService';

import {
  PLACE,
  DATE_FORMATS,
  TIME_FORMATS,
  CURATED,
  VERTICAL_TYPE,
} from 'constants.js';

import {
  CardSelector,
  Form,
  FileUploader,
  Loader,
  Overlay,
  FormActions,
  FlagContentModal,
  FlaggedCardNotice,
} from 'components';

import CuratedCardService from './CuratedCardService';

import CuratedData from './CuratedData.constants';

import './CuratedCard.scss';
import '../~depreciated/Place/Place.scss';

// ----------------------------------------------------------------------------|
//                        Redux - Property Mapping
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  fetchNeighborhoodLocations: () =>
    dispatch(fetchNeighborhoodLocationsAction()),
  uploadImage: (data) => dispatch(uploadImageAction(data)),
  deleteImage: (data) => dispatch(deleteImageAction(data)),
  populateAutofillImages: (data) =>
    dispatch(populateAutofillImagesAction(data)),
  saveDataToRedux: (data) => dispatch(saveDataToReduxAction(data)),
  saveData: (data, id, successCB) =>
    dispatch(saveDataAction(data, id, successCB)),
  removeCard: (data) => dispatch(deSelectedCardAction(data)),
  clearData: () => dispatch(clearData),
  clearSelectedPlaces: () => dispatch(clearSelectedPlacesAction),
  fetchCuratedCard: (id) => dispatch(fetchCuratedCardAction(id)),
  reOrderImages: (data) => dispatch(reOrderImagesAction(data)),
});

const mapStateToProps = (state) => ({
  curatedCard: state.curatedCard,
  adminCategories: state.places.adminCategories,
  neighborhoods: state.places.neighborhoodLocations,
});

// ----------------------------------------------------------------------------|
//                              Utilities
// ----------------------------------------------------------------------------|
const curatedCardService = CuratedCardService();

const commonService = CommonService();
class CuratedCard extends Component {
  // --------------------------------------------------------------------------|
  //                          PropTypes Check
  // --------------------------------------------------------------------------|
  static propTypes = {
    curatedCard: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    adminCategories: PropTypes.array.isRequired,
    saveDataToRedux: PropTypes.func.isRequired,
    neighborhoods: PropTypes.array.isRequired,
    uploadImage: PropTypes.func.isRequired,
    deleteImage: PropTypes.func.isRequired,
    saveData: PropTypes.func.isRequired,
    populateAutofillImages: PropTypes.func.isRequired,
    removeCard: PropTypes.func.isRequired,
    fetchCuratedCard: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    reOrderImages: PropTypes.func.isRequired,
  };

  // --------------------------------------------------------------------------|
  //                            Default Props
  // --------------------------------------------------------------------------|
  static defaultProps = {};

  // --------------------------------------------------------------------------|
  //                                State
  // --------------------------------------------------------------------------|
  constructor(props) {
    super(props);

    const initialPlaceState = this.handleCardData(this.props.data);

    this.state = {
      cardId: this.props.data.id,
      ...initialPlaceState,
      errors: {},
      imageErrors: '',
      cardErrors: '',
      shouldFetchCard: this.props.data.shouldFetchCard,
      shouldDisplayLoader: false,
      showFlagContentModal: false,
      flaggedReports: props.data.content_reports || [],
    };

    // initially keep the data in to the redux
    this.props.saveDataToRedux(this.getToBeProcessedData(this.props.data));
  }

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  CuratedCard lifecycle
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  componentDidMount() {
    const { id, images } = this.props.data;
    if (this.state.shouldFetchCard) {
      this.setState({ shouldFetchCard: false, shouldDisplayLoader: true });
    } else {
      this.props.populateAutofillImages(images);
    }
    if (Object.keys(this.props.data).length) {
      this.props.fetchCuratedCard(id);
    }
  }

  componentDidUpdate() {
    if (
      this.props.curatedCard.fetchedCardData &&
      this.state.cardId == this.props.curatedCard.fetchedCardData.id &&
      this.state.shouldDisplayLoader
    ) {
      this.setState({
        shouldDisplayLoader: false,
        ...this.handleCardData(this.props.curatedCard.fetchedCardData),
      });
      this.props.saveDataToRedux(
        this.getToBeProcessedData(this.props.curatedCard.fetchedCardData)
      );
      this.props.populateAutofillImages(
        this.props.curatedCard.fetchedCardData.images
      );
    }
  }

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  CuratedCard methods
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  /**
   * @description Handle CuratedCard object data: prepare form, display, etc..
   */
  handleCardData = (cardDataToProcess) => {
    const toBeProcessedObj = this.getToBeProcessedData(cardDataToProcess);
    const processedData = this.getProcessedData(toBeProcessedObj);
    return { data: processedData };
  };

  /**
   * @description reorders the array of images
   *
   * @param {Object} dropData
   */
  onReOrderImages = (dropData) => {
    const { autofilledImages } = this.props.curatedCard;
    const reOrderedImges = applyDrag([...autofilledImages], dropData);
    this.props.reOrderImages(reOrderedImges);
  };

  /**
   * @description returns an object with the correct key/value pairs
   * for editing
   *
   * @param {Object} obj
   */
  getToBeProcessedData = (obj) => ({
    name: obj.name,
    one_liner_description: obj.phrase,
    images: obj.images,
    neighborhoods: getProcessedNeighborhoods(obj.neighborhoods),
    long_description: obj.description,
    price_tier: obj.price_tier || 0,
    categories: obj.categories || [],
  });

  /**
   * @description Maps over and processes data for the form
   * component. This is the function that you need to edit to
   * conform to the various endpoint naming differences with the prop
   * fields of the form input components
   *
   * @param {Object} data - of form/card data
   */
  getProcessedData = (data = []) =>
    CuratedData.map((inputObj) => {
      let input = {};
      switch (inputObj.name) {
        case 'categories':
          input = {
            ...inputObj,
            tags: data.categories,
            suggestions: this.props.adminCategories || [],
          };
          break;
        case 'neighborhoods':
          input = {
            ...inputObj,
            tags: data.neighborhoods,
            suggestions: getProcessedNeighborhoods(this.props.neighborhoods),
          };
          break;
        case 'price_tier':
          input = {
            ...inputObj,
            value: {
              label: data.price_tier && getPriceRangeLabel(data.price_tier),
              value: data.price_tier,
            },
          };
          break;
        default:
          input = {
            ...inputObj,
            value: data[inputObj.name],
          };
          break;
      }

      return input;
    });

  /**
   * @description fires to saveData button click. Does form
   * validation and processing
   *
   * @param {Object} e - Event Object.
   */
  saveData = (e) => {
    const { curatedCard, data, closeModal } = this.props;
    const { autofilledImages, selectedCards } = curatedCard;

    const formattedImages = curatedCard.autofilledImages.map((image) => {
      if (image.source === 'cobble') {
        const imageTemp = { ...image };

        delete imageTemp.fileId;

        return imageTemp;
      }

      return image;
    });

    const formData = curatedCardService.getPreparedFormData(
      curatedCard.formData,
      [...formattedImages],
      e.target.name,
      selectedCards
    );

    formData.set_categories = getUniqueSlug(
      curatedCard.formData.categories,
      this.props.adminCategories
    );

    delete formData.categories;

    formData.set_neighborhoods = getUniqueSlug(
      curatedCard.formData.neighborhoods,
      this.props.neighborhoods
    );

    if (e.target && e.target.name === 'published') {
      const errors = curatedCardService.validate(curatedCard.formData);
      const imageErrors = commonService.validateImages(
        curatedCard.images,
        autofilledImages
      )
        ? null
        : PLACE.IMAGE_VALIDATION_MESSAGE;

      let cardErrors = curatedCardService.validateCards(selectedCards)
        ? null
        : CURATED.CARD_VALIDATION_MESSAGE;

      cardErrors = curatedCardService.validatePublishedCards(selectedCards)
        ? cardErrors
        : CURATED.CARD_NOT_VALID_TO_PUBLISH;

      if (
        Object.keys(errors).length === 0 &&
        imageErrors === null &&
        cardErrors === null
      ) {
        // dispatch to save
        this.props.saveData(formData, data.id, closeModal);
      } else {
        toast.error('There are some errors in the form');
      }

      this.setState({
        errors,
        imageErrors,
        cardErrors,
      });
    } else if (e.target && e.target.name === 'draft') {
      const errors = {};
      const imageErrors = '';
      const cardErrors = '';
      const validateName = commonService.validateName(formData.name);

      if (validateName) {
        errors.name = validateName;
      }

      //  validations required only for name for drafting
      if (Object.keys(errors).length === 0) {
        this.props.saveData(formData, data.id, closeModal);
      } else {
        toast.error('There are some errors in the form');
      }

      this.setState({
        errors,
        imageErrors,
        cardErrors,
      });
    }
  };

  /**
   * @description maps any input or change to the form. Updates redux
   *
   * @param {Object || String} event - data being passed || element name
   * @param {String} elemName - name of that input
   *
   * @return {void} to exit function
   */
  eventHandler = (event, elemName) => {
    /* This is the case for Tags that return  an array, not an event object */
    if (elemName === 'categories') {
      this.props.saveDataToRedux({ [elemName]: event });
      return;
    }
    if (Array.isArray(event)) {
      const data = event.map((each) => each.name);
      this.props.saveDataToRedux({ [elemName]: data });
      return;
    }
    if (event.name === 'from') {
      this.props.saveDataToRedux({ from_time: event.value.format('hh:mm') });
      return;
    }
    if (event.name === 'to') {
      this.props.saveDataToRedux({ to_time: event.value.format('hh:mm') });
      return;
    }
    if (elemName === 'price_tier') {
      this.props.saveDataToRedux({ [elemName]: event.value });
      return;
    }
    const { name, value } = event.target;
    this.props.saveDataToRedux({ [name]: value });
  };

  /**
   * @description used in prepping of preview card modal. No longer being used
   *
   * @param {Object[]} list
   */
  prepareSelectedCard = (list) => {
    if (list) {
      const res = [];
      for (const el of list) {
        const {
          images,
          name,
          categories: category,
          neighborhoods: neighborhood,
          price_tier: priceValue,
          published_date: publishedDate,
          from_time: startTimeValue,
          to_time: endTimeValue,
        } = el;

        const previewImage = getImageURL(images);
        const price = getPriceRangeLabel(priceValue);
        const date = formatISODate(
          publishedDate,
          DATE_FORMATS.stringWithoutYear
        );
        const startTime = startTimeValue
          ? formatTimeString(startTimeValue, TIME_FORMATS.time)
          : '-';
        const endTime = endTimeValue
          ? formatTimeString(endTimeValue, TIME_FORMATS.time)
          : '-';

        const data = {
          previewImage,
          name,
          category,
          neighborhood,
          price,
          date,
          startTime,
          endTime,
        };
        res.push(data);
      }
      return res;
    }
    return [];
  };

  /**
   * @description preps preview card modal, which is no longer being used,
   */
  preparePreviewModalData = () => {
    const { selectedCards } = this.props.curatedCard;
    const {
      formData: {
        name,
        address,
        categories,
        neighborhoods,
        price_tier: priceValue,
        long_description: desc,
        one_liner_description: shortDesc,
      },
      images,
      autofilledImages = [],
    } = this.props.curatedCard;

    const price = getPriceRangeLabel(priceValue);
    const imageList = [...autofilledImages, ...images];
    const previewCardList = this.prepareSelectedCard(selectedCards);

    return {
      previewImage: getImageURL(imageList, false),
      title: name,
      category: categories,
      neighborhood: neighborhoods,
      address,
      price,
      desc,
      shortDesc,
      previewCardList,
    };
  };

  /**
   * @description fires when any of the form action buttons (bottom of form)
   * are clicked - ie: "MOVE TO DRAFTS" / "PUBLISHED" / "PREVIEW" and passes
   * data to be validated for a DB PUT/PATCH
   *
   * @param {Object} event - Event Object.
   */
  onFormAction = (event) => {
    const { name } = event.target;

    if (name === 'draft' || name === 'published') {
      this.saveData(event);
    } else if (name === 'flag') {
      this.toggleFlagContentModal();
    }
  };

  toggleFlagContentModal = () => {
    const { showFlagContentModal } = this.state;

    this.setState({ showFlagContentModal: !showFlagContentModal });
  };

  filterClosedReport = (reportId) => {
    const { flaggedReports } = this.state;

    this.setState({
      flaggedReports: flaggedReports.filter(({ id }) => id !== reportId),
    });
  };

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  CuratedCard rendering
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  render() {
    const {
      uploadImage,
      deleteImage,
      curatedCard,
      removeCard,
      currentTab,
      data: propsData,
    } = this.props;
    const {
      data,
      errors,
      cardErrors,
      shouldDisplayLoader,
      fetchedCardData = {},
      showFlagContentModal,
      flaggedReports,
    } = this.state;
    const { formData, selectedCards } = this.props.curatedCard;

    const updatedData = commonService.getUpdatedData(formData, [...data]);

    return (
      <div className="place curated-card-container">
        {shouldDisplayLoader === true ? (
          <div className="overlay-wrapper">
            <Overlay show>
              <Loader />
            </Overlay>
          </div>
        ) : (
          <Fragment>
            {qualifyArrayRendering(flaggedReports) ? (
              <FlaggedCardNotice
                flaggedCardData={flaggedReports}
                successCallback={this.filterClosedReport}
              />
            ) : null}
            <CardSelector
              onDelete={removeCard}
              selectedCards={selectedCards}
              errors={cardErrors}
            />
            <div className="form-content">
              <div className="form">
                <Form
                  data={updatedData}
                  onEvent={this.eventHandler}
                  errors={errors}
                />
              </div>
              <FileUploader
                isDropZonePreviewRequired={
                  curatedCard.isDropZonePreviewRequired
                }
                data={curatedCard.autofilledImages}
                onEvent={this.props.populateAutofillImages}
                failedUploads={curatedCard.failedUploads}
                onAddImage={uploadImage}
                onDeleteImage={deleteImage}
                error={this.state.imageErrors}
                onDrop={this.onReOrderImages}
              />
            </div>
            <FormActions
              verticalType={VERTICAL_TYPE.CURATED}
              onAction={this.onFormAction}
              cardId={this.props.data.id}
              currentTab={currentTab}
              isNewCard={Object.keys(this.props.data).length > 0}
              isBtnDisabled={curatedCard.isBtnDisabled}
            />
            {showFlagContentModal && (
              <FlagContentModal
                show={showFlagContentModal}
                contentPrettyId={
                  propsData.pretty_id || fetchedCardData.pretty_id
                }
                modalClosed={this.toggleFlagContentModal}
              />
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CuratedCard);
