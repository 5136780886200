/**
 * ************************************
 *
 * @module  AuthRoutes.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Authorization Routes component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';

import { Navbar, Tabs } from 'components';

import { getPartsFromUrl } from 'utils/utils';

import { getSession } from 'services/AuthService';

import SearchIcon from 'assets/images/search-small.svg';

import { settingsDropdownOptions } from './AuthRoutes.constants';

import './AuthRoutes.scss';

// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|
/**
 * @description - tab data array to render the order of the navbar
 */
const tabData = [
  { name: 'Collections', url: '/collections/published', type: 'section' },
  // { name: 'Curated Cards', url: '/curated/published', type: 'section' },
  { name: 'Places', url: '/places/published', type: 'section' },
  { name: 'Events', url: '/events/published', type: 'section' },
  { name: 'Movies & Television', url: '/movies/published', type: 'section' },
  { name: 'Recipes', url: '/recipes/published', type: 'section' },
  { name: 'Recreation', url: '/recreation/published', type: 'section' },
  { name: 'Flagged', url: '/flagged', type: 'section' },
  {
    name: 'Search All Cards',
    url: '/search',
    type: 'section',
    icon: SearchIcon,
  },
  {
    name: 'Settings',
    url: '/settings',
    type: 'dropdown',
    options: settingsDropdownOptions,
  },
];

// ----------------------------------------------------------------------------|
//                     React Class Component - AuthRoutes
// ----------------------------------------------------------------------------|
const AuthRoutes = ({ component: C, props: cProps, ...rest }) => {
  /**
   * @description - obtains session data
   */
  const sessionData = getSession();

  /**
   * @description - This indicates which tab should be set as
   * active in the side nav
   */
  const { firstPart } = getPartsFromUrl(rest.location.pathname);

  /**
   * @description - render the app and navbar if user is authorized,
   * else redirect to login
   */
  const authorizedComponent = (
    <Fragment>
      <header>
        <Navbar />
      </header>
      <main className="auth-routes">
        <aside>
          <Tabs
            active={firstPart}
            classNames="vertical-tab side-nav"
            data={tabData}
            listClassNames="side-bar-tabs"
            linkClassNames="nav-link"
          />
        </aside>
        <section className="main-container">
          <Route {...rest} render={(props) => <C {...props} {...cProps} />} />
        </section>
      </main>
    </Fragment>
  );

  return Object.entries(sessionData).length > 0 && sessionData.token ? (
    authorizedComponent
  ) : (
    <Redirect to="/login" />
  );
};

// ----------------------------------------------------------------------------|
//                                PropTypes Check
// ----------------------------------------------------------------------------|
AuthRoutes.propTypes = {
  component: PropTypes.elementType.isRequired,
  props: PropTypes.object,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
AuthRoutes.defaultProps = {
  props: {},
};

// ----------------------------------------------------------------------------|
//                            Component Export
// ----------------------------------------------------------------------------|
export default AuthRoutes;
