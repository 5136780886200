/**
 * ************************************
 *
 * @module  LoadingFetchedCard.js
 * @author  Matt P
 * @date    03/01/2021
 * @description Fetched Card to display when loading more results
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import './LoadingFetchedCard.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const LoadingFetchedCard = ({ className }) => {
  const containerClass = cx(['loading-fetch-card', `${className}`]);

  return (
    <div className={containerClass}>
      <div className="fetch-card-loader" />
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
LoadingFetchedCard.propTypes = {
  className: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
LoadingFetchedCard.defaultProps = {
  className: '',
};

// ----------------------------------------------------------------------------|
//                                  Exports
// ----------------------------------------------------------------------------|
export default LoadingFetchedCard;
