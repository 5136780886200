/**
 * ************************************
 *
 * @module  CardViewModal.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Modal window to select curated cards.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import CloseIcon from 'assets/images/close.png';

import 'components/CardViewModal/CardViewModal.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const CardViewModal = (props) => {
  const { title, modalClosed, children, className, ...rest } = props;

  /**
   * @description - dynamic classNames
   */
  const componentClass = cx(['card-view-modal', `${className}`]);

  return (
    <section className={componentClass} {...rest}>
      <div className="modal-innerWrapper">
        <header>
          <h1 className="title">{title}</h1>
          <img src={CloseIcon} alt="close" onClick={modalClosed} />
        </header>
        <section className="modal-contains">{children}</section>
      </div>
    </section>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
CardViewModal.propTypes = {
  title: PropTypes.string.isRequired,
  modalClosed: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

CardViewModal.defaultProps = {
  className: '',
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default CardViewModal;
