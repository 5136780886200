/**
 * ************************************
 *
 * @module  AuthRoutes.constants.ts
 * @author  Matt P
 * @date   03/19/2021
 * @description constant variables used for the AuthRoutes
 *
 * ************************************
 */
const settingsDropdownOptions = [
  {
    id: 'settings-dropdown-cities',
    title: 'Cities',
    url: '/cities',
  },
  {
    id: 'settings-dropdown-categories',
    title: 'Categories',
    url: '/categories',
  },
  {
    id: 'settings-dropdown-filters',
    title: 'Filters',
    url: '/filters',
  },
];

// --------------------------------------------------------------------------|
//                                  Export
// --------------------------------------------------------------------------|
export { settingsDropdownOptions };
