/**
 * ************************************
 *
 * @module  EventDateRangeEdit.js
 * @author  Matt P
 * @date    02/17/2021
 * @description Edits times for a selected date range.
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                  Imports
// ---------------------------------------------------------------------------|
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import TimePicker from 'rc-time-picker';

import timeIcon from 'assets/images/time-icn.svg';

import { DATE_SELECTOR_POPUP as LABELS } from 'constants.js';

import RecurrenceSelector from '../RecurrenceSelector/RecurrenceSelector';

// ---------------------------------------------------------------------------|
//                React Class Component - EventDateRangeEdit
// ---------------------------------------------------------------------------|
class EventDateRangeEdit extends Component {
  // -------------------------------------------------------------------------|
  //                             PropTypes Check
  // -------------------------------------------------------------------------|
  static propTypes = {
    fromDate: PropTypes.string.isRequired,
    toDate: PropTypes.string.isRequired,
    updateDateRange: PropTypes.func.isRequired,
    isEdit: PropTypes.bool.isRequired,
    editPayload: PropTypes.shape({
      startDate: PropTypes.shape(PropTypes.object).isRequired,
      fromTime: PropTypes.string.isRequired,
      toTime: PropTypes.string.isRequired,
      isRecurring: PropTypes.bool,
      endDate: PropTypes.shape(PropTypes.object),
      selectedWeekDays: PropTypes.arrayOf(PropTypes.string),
      repeatMode: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
      availability: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    }),
  };

  // -------------------------------------------------------------------------|
  //                              Default Props
  // -------------------------------------------------------------------------|
  static defaultProps = {
    editPayload: null,
  };

  // -------------------------------------------------------------------------|
  //                          Constructor and State
  // -------------------------------------------------------------------------|
  constructor(props) {
    super(props);

    this.state = {
      dateObject: this.generateInitialDateObject(),
    };
  }

  componentDidUpdate(prevProps) {
    const { fromDate } = this.props;

    if (prevProps.fromDate !== fromDate) {
      const { dateObject } = this.state;

      if (dateObject) dateObject.startDate = fromDate;
    }
  }

  // -------------------------------------------------------------------------|
  //                          Class Component Methods
  // -------------------------------------------------------------------------|
  /**
   * @description generated the date object based on if this is an edit
   * or a fresh selection
   *
   * @returns {Object}
   */
  generateInitialDateObject = () => {
    const { editPayload, isEdit } = this.props;

    if (isEdit) {
      // old data may not have the selectedWeekdays array so when it's
      // passed to be edited this could cause an error. add it if not
      if (!editPayload.selectedWeekDays) editPayload.selectedWeekDays = [];

      return editPayload;
    }

    return this.buildDateObject();
  };

  /**
   * @description constructs repeat options for the repeat dropdown, will have a
   * will auto populate the value if this is an edit
   *
   * @param {Array} - of day strings ('monday', 'tuesday', etc..)
   *
   * @returns {Object} - object with date properties
   */
  buildDateObject = () => {
    const { fromDate, toDate } = this.props;

    return {
      startDate: fromDate,
      fromTime: null,
      toTime: null,
      isRecurring: true,
      endDate: toDate,
      repeatMode: '',
      availability: null,
      selectedWeekDays: [],
      errors: {},
    };
  };

  /**
   * @description updates the time range of a date object based
   *
   * @param {String} fieldName - fromTime or toTime
   * @param {Object} time - of time data
   */
  updateTimeRange = (fieldName, time) => {
    const { updateDateRange } = this.props;
    const { dateObject } = this.state;
    const formattedTime = time.format(LABELS.TIME_FORMAT);

    dateObject[fieldName] = formattedTime;

    // sets the component state with date objects
    this.setState({ dateObject });

    if (dateObject.fromTime && dateObject.toTime) {
      // passes the data to the parent component to be
      // grouped and filtered if needed
      updateDateRange([dateObject]);
    }
  };

  /**
   * @description updates availability for Monthly repeat and checks for
   * date range errors
   *
   * @param {Object} availability - object with label and value
   */
  updateAvailability = (availability) => {
    const { updateDateRange } = this.props;
    const { dateObject } = this.state;

    dateObject.availability = availability;

    this.setState({
      dateObject,
    });

    updateDateRange([dateObject]);
  };

  /**
   * @description updates repeat mode on dropdown selection
   * sets state accordingly
   *
   * @param {Object} repeatMode - object with label and value
   */
  updateRepeatMode = (repeatMode) => {
    const { updateDateRange } = this.props;
    const { dateObject } = this.state;

    dateObject.repeatMode = repeatMode;
    dateObject.selectedWeekDays = [];
    dateObject.availability = null;

    this.setState({
      dateObject,
    });

    updateDateRange([dateObject]);
  };

  /**
   * @description updates selected weekdays
   *
   * @param {Array} selectedWeekDays - of weekday strings
   */
  updateWeekdays = (selectedWeekDays) => {
    const { updateDateRange } = this.props;
    const { dateObject } = this.state;

    dateObject.selectedWeekDays = selectedWeekDays;

    this.setState({ dateObject });

    updateDateRange([dateObject]);
  };

  /**
   * @description renders time pickers for time selection
   *
   * @param {Number} index - in array of datesObjects
   * @param {String} label - for the specific range
   *
   * @returns {JSX}
   */
  renderTimePicker = (label = '') => {
    const { dateObject } = this.state;
    const { SELECT_TIME, TIME_FORMAT } = LABELS;

    return (
      <div className="hours-range">
        <label id="" className="hours-range-label">{`${label}`}</label>
        <div className="timer">
          <span className="time-picker--wrapper">
            <TimePicker
              popupStyle={{ width: '168px' }}
              showSecond={false}
              use12Hours
              allowEmpty={false}
              inputReadOnly
              minuteStep={15}
              value={
                dateObject.fromTime
                  ? moment(dateObject.fromTime, TIME_FORMAT)
                  : null
              }
              className="timePicker"
              onChange={(value) => this.updateTimeRange('fromTime', value)}
              placeholder={SELECT_TIME}
            />
            <img
              src={timeIcon}
              alt="Select time"
              className="time-picker-icon"
            />
          </span>
          <span className="timer-separator">to</span>
          <span className="time-picker--wrapper">
            <TimePicker
              popupStyle={{ width: '168px' }}
              showSecond={false}
              use12Hours
              allowEmpty={false}
              inputReadOnly
              minuteStep={15}
              value={
                dateObject.toTime
                  ? moment(dateObject.toTime, TIME_FORMAT)
                  : null
              }
              className="timePicker"
              onChange={(value) => this.updateTimeRange('toTime', value)}
              placeholder={SELECT_TIME}
            />
            <img
              src={timeIcon}
              alt="Select time"
              className="time-picker-icon"
            />
          </span>
        </div>
        <div className="error">
          {dateObject.errors && dateObject.errors.timeErrors
            ? dateObject.errors.timeErrors
            : ''}
        </div>
      </div>
    );
  };

  /**
   * @description updates the end date from input selection
   *
   * @param {Object} selectedDay
   */
  handleEndDateChange = (selectedDay) => {
    const { updateDateRange } = this.props;
    const { dateObject } = this.state;

    dateObject.endDate = moment(selectedDay);

    this.setState({
      dateObject,
    });

    updateDateRange([dateObject]);
  };

  render() {
    const { fromDate, isEdit, editPayload } = this.props;
    const { dateObject } = this.state;

    const {
      selectedWeekDays,
      endDate,
      repeatMode,
      availability,
      errors,
    } = dateObject;

    return (
      <div className="event-recurring-edit">
        <RecurrenceSelector
          startDate={fromDate}
          repeatMode={repeatMode}
          endDate={isEdit ? moment(editPayload.endDate) : endDate}
          selectedWeekDays={dateObject.selectedWeekDays}
          availability={availability}
          onRepeatModeSelected={this.updateRepeatMode}
          onWeekdaysSelected={this.updateWeekdays}
          onAvailabilitySelected={this.updateAvailability}
          handleEndDateChange={this.handleEndDateChange}
          errors={errors}
        />
        <div className="error">
          {errors && errors.dateRangeError ? errors.dateRangeError : ''}
        </div>
        {(dateObject &&
          repeatMode.value === 'Weekly' &&
          selectedWeekDays.length > 0 &&
          endDate &&
          !errors.weekDaysError) ||
        (dateObject &&
          repeatMode.value === 'Monthly' &&
          endDate &&
          availability &&
          !errors.selectedMonthError)
          ? this.renderTimePicker('Select Hours')
          : ''}
      </div>
    );
  }
}

// ---------------------------------------------------------------------------|
//                                  Export
// ---------------------------------------------------------------------------|
export default EventDateRangeEdit;
