/**
 * ************************************
 *
 * @module  FormFunc.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description home base for functions used for forms throughout the CMS
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import { deepCopy } from './utils';

// ----------------------------------------------------------------------------|
//                              Utilities
// ----------------------------------------------------------------------------|
/**
 * @description - validates phone number
 *
 * @param {String} input
 *
 * @returns {Boolean}
 */
const isValidPhone = (input = '') => {
  const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-.]?([0-9]{4})$/;
  if (input === null) {
    return false;
  }

  return regex.test(input);
};

/**
 * @description - checks if an array is empty
 *
 * @param {Any[]} input
 *
 * @returns {Boolean}
 */
const isArrayEmpty = (input = []) => {
  if (input === null) return true;

  if (Array.isArray(input)) {
    return input.length === 0;
  }

  return true;
};

/**
 * @description - checks if input is number
 *
 * @param {Any} num
 *
 * @returns {Boolean}
 */
const isValidNumber = (num) => (num === null ? false : !isNaN(num));

/**
 * @description - checks if input is between a min and max
 *
 * @param {Number} input
 * @param {Number} min
 * @param {Number} max
 *
 * @returns {Boolean}
 */
const isValidValue = (input, min, max) => {
  if (input === null || input === '') return false;
  return input && input.length >= min && input.length <= max;
};

/**
 * @description trims all non numeric characters (ie: $)
 * for number inputs
 *
 * @param {String} numString
 *
 * @returns {String} without non-numeric characters or '.'
 */
const trimAllNonNumberCharacters = (numString) =>
  numString.replace(/[^\d.-]/g, '');

/**
 * @description trims the itemized description fields to prevent extra white
 * space and misc characters
 *
 * @param {Array} descArray - of detailed desc objects
 * @param {String} type - vertical type
 *
 * @returns {Array}
 */
const trimItemizedDescription = (descArray = [], type) => {
  const arrayCopy = deepCopy(descArray);
  const returnedArray = [];

  arrayCopy.forEach((arr) => {
    returnedArray.push({
      header: arr.header.trim(),
      // we want to leave a new line for recipe, only trim end
      body: type === 'recipe' ? arr.body.trimEnd() : arr.body.trim(),
    });
  });

  return returnedArray;
};

/**
 * @description filters out empty multi-link fields
 *
 * @param {Array} linkArray - of strings
 *
 * @returns {Array}
 */
const filterEmptyMultiLinks = (linkArray = []) =>
  linkArray.filter((link) => typeof link === 'string' && link.length > 0);

export {
  isValidPhone,
  isArrayEmpty,
  isValidValue,
  isValidNumber,
  trimAllNonNumberCharacters,
  trimItemizedDescription,
  filterEmptyMultiLinks,
};
