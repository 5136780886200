/**
 * ************************************
 *
 * @module  FiltersUtils.js
 * @author  Matt P
 * @date    03/22/2021
 * @description Filters related utility functions
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import Option from 'components/CobReactMultiSelect/Option';

import currentEnv from 'utils/EnvironmentSpecificValues';

/**
 * @description Filter updates : Toggle enable, toggle featured, delete
 * If delete, no key passed to API
 */
const updateMapper = {
  DELETE: null,
  ENABLE: 'is_enabled',
  FEATURE: 'is_featured',
};

/**
 * @description Table columns for filters
 */
const cols = [
  {
    type: 'String',
    label: 'Filter Name',
    value: 'name',
    visibility: 'all',
  },
  {
    type: 'Switch',
    label: 'Enabled',
    value: 'is_enabled',
    visibility: 'all',
  },
  {
    type: 'Switch',
    label: 'Featured',
    value: 'is_featured',
    visibility: 'all',
  },
  {
    type: 'String',
    label: 'Filter Type',
    value: 'filter_types',
    visibility: 'all',
  },
  {
    type: 'Tag',
    label: 'Categories',
    value: ['tags'],
    visibility: 'all',
  },
  {
    type: 'Options',
    label: '',
    value: ['options'],
    visibility: 'all',
  },
];

const typeDropdownOptions = [
  {
    label: 'Go Out Anywhere',
    value: {
      required_neighborhoods: [],
      excluded_neighborhoods: [currentEnv.neighborhoodSlugs.stayIn],
    },
  },
  {
    label: 'New York',
    value: {
      required_neighborhoods: [[currentEnv.neighborhoodSlugs.newYorkCity]],
      excluded_neighborhoods: [],
    },
  },
  {
    label: 'Stay In',
    value: {
      required_neighborhoods: [[currentEnv.neighborhoodSlugs.stayIn]],
      excluded_neighborhoods: [],
    },
  },
  {
    label: 'Everywhere (all cities, stay-in & going out)',
    value: {
      required_neighborhoods: [],
      excluded_neighborhoods: [],
    },
  },
];

const locationDropdownOptions = [
  {
    label: 'My List',
    value: 'my_list',
  },
  {
    label: 'My List - Stay In',
    value: 'my_list__stay_in',
  },
  {
    label: 'Discovery',
    value: 'swiping_deck',
  },
  {
    label: 'Discovery - Stay In',
    value: 'swiping_deck__stay_in',
  },
  {
    label: 'Polls',
    value: 'poll',
  },
  {
    label: 'Polls - Stay In',
    value: 'poll__stay_in',
  },
  {
    label: 'Map',
    value: 'map',
  },
  {
    label: 'Mix SO',
    value: 'mix__so',
  },
  {
    label: 'Mix Close Connection',
    value: 'mix__close',
  },
  {
    label: 'Mix Acquaintance',
    value: 'mix__other',
  },
];

/**
 * @description form fields for Filters
 */
const filterFormFields = [
  {
    label: 'Filter Name',
    type: 'text',
    placeholder: 'Enter name',
    value: 'name',
    inputKey: 'name',
  },
  {
    label: 'Emoji Icon',
    type: 'text',
    placeholder: 'Enter emoji',
    value: 'emoji_icon',
    inputKey: 'emoji_icon',
  },
  {
    label: 'Categories',
    type: 'tag',
    subtype: 'categories',
    placeholder: 'Add categories',
    value: '',
    inputKey: 'categories',
  },
  {
    label: 'Where this Filter Appears in the App',
    type: 'tag',
    subtype: 'location',
    placeholder: 'Choose App Locations',
    value: '',
    overriddenComponents: {
      Option,
    },
    inputOptions: locationDropdownOptions,
    inputKey: 'app_location',
  },
  {
    label: 'Geographic Location(s) for Filter',
    type: 'dropdown',
    placeholder: 'Go Out, Stay In, or Both',
    value: '',
    inputOptions: typeDropdownOptions,
    inputKey: 'geo_location',
  },
  {
    label: 'Enabled',
    type: 'switch',
    placeholder: '',
    value: 'is_enabled',
    inputKey: 'is_enabled',
  },
];

/**
 * @description default advanced options
 */
const defaultAdvancedOptions = [
  {
    option_key: 'location',
    option_bind_key: 'location',
    option_values: ['nearby', 'neighborhoods'],
    option_name: 'Location'
  },
  {
    option_key: 'price',
    option_bind_key: 0,
    option_values: ['0', '1', '2', '3', '4'],
    option_name: 'Price (Free - $$$$)'
  },
  {
    option_key: 'price',
    option_bind_key: 1,
    option_values: ['1', '2', '3', '4'],
    option_name: 'Price ($ - $$$$)'
  },
];

/**
 * @description styling for <Switch> component
 */
const switchStyling = () => ({
  offColor: '#A8A4A0',
  onColor: '#F7BC25',
  handleDiameter: 20,
  uncheckedIcon: false,
  checkedIcon: false,
  height: 10,
  width: 35,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.25)',
});

/**
 * @description  maps over filter array and replaces the successful
 * change with the changes
 *
 * @param {Array} filters
 * @param {Array} filter
 *
 * @returns {Array}
 */
const mapAndReplace = (filters, filter) =>
  filters.map((fil) => {
    if (fil.id === filter.id) return filter;
    return fil;
  });

/**
 * @description  checks if a valid emoji was types in
 *
 * @param {String} emojiString
 *
 * @returns {Boolean}
 */
const emojiStringErrorChecking = (emojiString) => {
  if (emojiString === '') return true;
  // eslint-disable-next-line max-len
  const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;

  return emojiRegex.test(emojiString);
};

/**
 * @description generates an advanced rule option object
 *
 * @param {String} ruleKey
 *
 * @returns {Object}
 */
const generateAdvancedRuleOptions = (ruleKey) => ({
  option_key: 'rule',
  option_rule: {
    rule_type: 'category_rule',
    rule_key: ruleKey,
  },
});

const areArrayEquals = (a1, a2) => {
  if (a1 == null && a2 == null || a1 == undefined && a2 == undefined) {
    return true;
  }
  if (a1 == null || a2 == null || a1 == undefined || a2 == undefined) {
    return false;
  }
  if (a1.length != a2.length) {
    return false;
  }
  var i = a1.length;
  while (i--) {
      if (a1[i] !== a2[i]) return false;
  }
  return true;
}

/**
 * @description checks if a rule has been checked on a rule edit
 * will show the box as checked if so
 *
 * @param {Array} fetchedRuleData
 * @param {Object} ruleToCheck
 * @param {String} type - backend rules have a different shape than
 * the default ones need something to ID them apart
 *
 * @returns {Boolean}
 */
const wasRuleChecked = (fetchedRuleData = [], ruleToCheck, type) => {
  for (let i = 0; i < fetchedRuleData.length; i += 1) {
    if (
      type === 'defaultRule' &&
      ruleToCheck.option_key &&
      ruleToCheck.option_key !== 'rule' &&
      ruleToCheck.option_key === fetchedRuleData[i].option_key &&
      areArrayEquals(ruleToCheck.option_values, fetchedRuleData[i].option_values)
    ) {
      return true;
    }

    if (
      type === 'fetchedRule' &&
      ruleToCheck.rule_key &&
      fetchedRuleData[i].option_rule &&
      fetchedRuleData[i].option_rule.rule_key &&
      fetchedRuleData[i].option_rule.rule_key === ruleToCheck.rule_key
    ) {
      return true;
    }
  }
  return false;
};

const findTypeDropdownLabel = (
  requiredNeighborhoodsList = [],
  excludedNeighborhoods = [],
  dropDownOptions = typeDropdownOptions,
) => {
  const searchedValue = {
    required_neighborhoods: requiredNeighborhoodsList,
    excluded_neighborhoods: excludedNeighborhoods,
  }

  for (const dropdownOption of dropDownOptions) {
    var isDropdownFound = true;
    if (dropdownOption.value.excluded_neighborhoods.length !== searchedValue.excluded_neighborhoods.length){ continue; }
    for (const excluded_neighborhood of dropdownOption.value.excluded_neighborhoods) {
      if (!searchedValue.excluded_neighborhoods.includes(excluded_neighborhood)) { 
        isDropdownFound = false;
        break;
      }
    }
    if (!isDropdownFound) { continue; }
    if (dropdownOption.value.required_neighborhoods.length !== searchedValue.required_neighborhoods.length){ continue; }
    for (const required_neighborhoods of dropdownOption.value.required_neighborhoods) {
      var isFound = true;
      for (const searched_required_neighborhoods of searchedValue.required_neighborhoods) {
        isFound = true;
        if (searched_required_neighborhoods.length !== required_neighborhoods.length){ 
          isFound = false;
        }
        if (isFound) {
          for (const required_neighborhood of required_neighborhoods) {
            if (!searched_required_neighborhoods.includes(required_neighborhood)) { 
              isFound = false;
              break;
            }
          }
        }
        if (isFound) { break; }
      }
      if (!isFound) { 
        isDropdownFound = false;
        break;
      }
    }
    if (!isDropdownFound) { continue; }
    if (isDropdownFound) {
      return dropdownOption;
    }
  }
  return dropDownOptions[0];
};

const findLocationTypeLabel = (filterTypes = []) => {
  const tokenArray = [];

  filterTypes.forEach((arr) => {
    if (arr === 'my_list') tokenArray.push(locationDropdownOptions[0]);
    if (arr === 'my_list__stay_in') tokenArray.push(locationDropdownOptions[1]);
    if (arr === 'swiping_deck') tokenArray.push(locationDropdownOptions[2]);
    if (arr === 'swiping_deck__stay_in') tokenArray.push(locationDropdownOptions[3]);
    if (arr === 'poll') tokenArray.push(locationDropdownOptions[4]);
    if (arr === 'poll__stay_in') tokenArray.push(locationDropdownOptions[5]);
    if (arr === 'map') tokenArray.push(locationDropdownOptions[6]);
    if (arr === 'mix__so') tokenArray.push(locationDropdownOptions[7]);
    if (arr === 'mix__close') tokenArray.push(locationDropdownOptions[8]);
    if (arr === 'mix__other') tokenArray.push(locationDropdownOptions[9]);
  });

  return tokenArray;
};

const mapLocationSlug = (locationArray = []) => {
  const returnedArray = [];

  locationArray.forEach((arrElem) => {
    // if array of arrays containing neighborhood object slugs
    if (Array.isArray(arrElem)) {
      const uniqueSlugNestedArray = [];

      arrElem.forEach((slug) => uniqueSlugNestedArray.push(slug.unique_slug));
      returnedArray.push(uniqueSlugNestedArray);
    } else if (typeof arrElem === 'object') {
      returnedArray.push(arrElem.unique_slug);
    }
  });

  return returnedArray;
};

export {
  cols,
  updateMapper,
  filterFormFields,
  typeDropdownOptions,
  defaultAdvancedOptions,
  mapAndReplace,
  switchStyling,
  emojiStringErrorChecking,
  generateAdvancedRuleOptions,
  wasRuleChecked,
  findTypeDropdownLabel,
  findLocationTypeLabel,
  mapLocationSlug,
  areArrayEquals,
};
