/**
 * ************************************
 *
 * @module  PlacesList.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description PlacesList component file which
 * displays places cards
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import { Card, PlaceFetchedDetails, InfiniteScroller } from 'components';

import { getImageURL, getDateBasedOnStatus } from 'utils/utils';

import { GENERIC } from 'constants.js';

// ----------------------------------------------------------------------------|
//                        React Function Component - PlacesList
// ----------------------------------------------------------------------------|
const PlacesList = (props) => {
  const {
    data,
    onMoreData,
    hasMore,
    onViewData,
    onMenuClicked,
    onCheckBoxSelected,
    fromFetched,
    isChecked,
    hideMenu,
    currentTab,
  } = props;

  const {
    SUB_MENU: { EDIT },
  } = GENERIC;

  /**
   * @description generates fetched data cards
   *
   * @param {Object} place - object of place data
   *
   * @returns {JSX} - of PlaceFetchedDetail
   */
  const generateFetchedData = (place, tab) => {
    const {
      id,
      images,
      categories,
      name,
      neighborhoods,
      price_tier: priceTier,
      rating,
      fs_categories: fsCategories,
      popularity_score,
      address,
    } = place;

    const imageURL = getImageURL(images);
    const fourthLayerQueue = tab === 'queue';

    return (
      <PlaceFetchedDetails
        key={id}
        imageURL={imageURL}
        categories={categories}
        name={name}
        neighborhoods={neighborhoods}
        priceTier={priceTier}
        rating={rating}
        fsCategories={fsCategories}
        // onReviewClick={() => onViewData(place)}
        onReviewClick={() => onMenuClicked(EDIT, place)}
        fourthLayer={fourthLayerQueue}
        popularity={popularity_score}
        address={address}
      />
    );
  };

  /**
   * @description generates card component
   *
   * @param {Array} placeListData - Array of place objects
   *
   * @returns {JSX[]} - with Card components
   */
  const generateCards = (placeListData) => {
    let res = null;

    if (Array.isArray(placeListData)) {
      res = placeListData.map((place) => {
        const {
          id,
          published_date: publishedDate,
          modified: modifiedDate,
          status,
          images,
          name,
          phrase,
          last_admin_review,
        } = place;

        const imageURL = getImageURL(images);

        return (
          <Card
            key={id}
            id={id}
            className="card"
            onMenuClick={(value) => onMenuClicked(value, place)}
            onCheck={onCheckBoxSelected}
            onClick={() => onMenuClicked(EDIT, place)}
            isChecked={isChecked}
            hideMenu={hideMenu}
            date={getDateBasedOnStatus(status, modifiedDate, publishedDate)}
            status={status}
            imageUrl={imageURL}
            title={name}
            phrase={phrase}
            adminReview={last_admin_review}
          />
        );
      });
    }

    return <div className="card-container">{res}</div>;
  };

  // switch var for if data is fromFetched or another tab
  let content;

  if (fromFetched) {
    content = data.map((place) => generateFetchedData(place, currentTab));
  } else {
    content = generateCards(data);
  }

  return (
    <section className="place-list">
      <InfiniteScroller
        loadMore={onMoreData}
        hasMore={hasMore}
        useWindow={!hideMenu}
      >
        {content}
      </InfiniteScroller>
    </section>
  );
};

// ----------------------------------------------------------------------------|
//                       PropTypes Check - PlacesList
// ----------------------------------------------------------------------------|
PlacesList.propTypes = {
  data: PropTypes.array.isRequired,
  onMoreData: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  onViewData: PropTypes.func,
  onMenuClicked: PropTypes.func,
  onCheckBoxSelected: PropTypes.func,
  isChecked: PropTypes.any,
  fromFetched: PropTypes.bool,
  hideMenu: PropTypes.bool,
};

// ----------------------------------------------------------------------------|
//                          Default Props - PlacesList
// ----------------------------------------------------------------------------|
PlacesList.defaultProps = {
  onViewData: () => {},
  onMenuClicked: () => {},
  onCheckBoxSelected: () => {},
  fromFetched: true,
  isChecked: null,
  hideMenu: false,
};

// ----------------------------------------------------------------------------|
//                              PlacesList Export
// ----------------------------------------------------------------------------|
export default PlacesList;
