/**
 * ************************************
 *
 * @module  FormInput.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Dolls out proper props for a form's text input type components
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import {
  Input,
  TextArea,
  DropDown,
  Checkbox,
  CobDatePicker,
  MultiInput,
  MultiTagSelect,
  DateDropdownSelect,
  PricingDropdown,
} from 'components';

import { capitalize } from 'utils/utils';

import './FormInput.scss';

// ----------------------------------------------------------------------------|
//                     React Class Component - FormInput
// ----------------------------------------------------------------------------|
class FormInput extends PureComponent {
  static propTypes = {
    options: PropTypes.object.isRequired,
    eventHandler: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    placeholder: '',
  };

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  FormInput rendering
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  renderInput = (inputObj) => {
    const { eventHandler, placeholder, options } = this.props;
    const {
      name,
      type,
      values,
      value,
      rows,
      showDropdown,
      additionalComponent,
      disabled,
      buttonTitle,
    } = inputObj;

    switch (type) {
      case 'text':
        return (
          <Input
            type={type}
            name={name}
            value={Array.isArray(value) ? value[0] : value || ''}
            onChange={eventHandler}
            placeholder={placeholder}
            maxLength={options.maxLength || 1000}
          />
        );
      case 'textarea':
        return (
          <TextArea
            name={name}
            value={value}
            onChange={eventHandler}
            placeholder={placeholder}
            rows={rows}
          />
        );
      case 'dropdown':
        return (
          <DropDown
            name={name}
            options={values}
            onSelect={eventHandler}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
          />
        );
      case 'checkbox':
        return <Checkbox name={name} options={values} onClick={eventHandler} />;
      case 'tag':
        return (
          <MultiTagSelect
            name={options.name}
            title={capitalize(options.name)}
            tags={options.tags}
            suggestions={options.suggestions}
            onChange={eventHandler}
            mainTags={options.mainCategories}
            disabled={options.disabled}
            groupSuggestions
          />
        );
      case 'datepicker':
        return (
          <CobDatePicker
            name={name}
            selectedDate={value ? moment(value).toDate() : null}
            onChange={eventHandler}
            placeholder={placeholder}
            dateFormat={inputObj.dateFormat}
            minimumDate={inputObj.minimumDate}
          />
        );
      case 'multiinput':
        return (
          <MultiInput
            type="text"
            name={name}
            value={value}
            onChange={eventHandler}
            disabled={disabled}
            buttonTitle={buttonTitle}
            checkboxComponent={additionalComponent}
          />
        );
      case 'date-dropdown-select':
        return (
          <DateDropdownSelect
            type="text"
            name={name}
            value={value}
            onChange={eventHandler}
          />
        );
      case 'pricing-dropdown':
        return (
          <PricingDropdown name={name} value={value} onChange={eventHandler} />
        );
      default:
        return '';
    }
  };

  render() {
    const { options } = this.props;
    const { label, errorMsg, hideLabel } = options;

    return (
      <div className="form-input">
        {hideLabel ? '' : <label>{label}</label>}
        {this.renderInput(options)}
        <div className="error">{errorMsg}</div>
      </div>
    );
  }
}

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default React.memo(FormInput);
