/**
 * ************************************
 *
 * @module  NoDataFound.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description renders when an HTTP request responds with no data
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';
import { GENERIC } from '../../constants';

import './NoDataFound.scss';

// ----------------------------------------------------------------------------|
//                              Utilities
// ----------------------------------------------------------------------------|
const { NO_DATA_FOUND_MESSAGE, NO_DATA_FOUND_SUB_MESSAGE } = GENERIC;

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const NoDataFound = ({ message, subMessage }) => (
  <div className="no-data-found">
    <span className="message">{message}</span>
    <span className="sub_message">{subMessage}</span>
  </div>
);

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
NoDataFound.propTypes = {
  message: PropTypes.string,
  subMessage: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
NoDataFound.defaultProps = {
  message: NO_DATA_FOUND_MESSAGE,
  subMessage: NO_DATA_FOUND_SUB_MESSAGE,
};

export default NoDataFound;
