/**
 * ************************************
 *
 * @module  Movies.action.js
 * @author  Matt P
 * @date    12/23/2020
 * @description Movies component redux actions
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                                  Exports
// ----------------------------------------------------------------------------|
export const getMoviesListAction = (data) => ({
  type: ACTIONS.MOVIES.GET_MOVIES_LIST,
  data,
});

export const getMoviesSearchAction = (data) => ({
  type: ACTIONS.MOVIES.GET_MOVIES_SEARCH,
  data,
});

export const addMoviesAction = (data) => ({
  type: ACTIONS.MOVIES.ADD_MOVIES,
  data,
});

export const deleteMovieAction = (data) => ({
  type: ACTIONS.MOVIES.DELETE_MOVIE,
  data,
});

export const performBulkOperationAction = (data) => ({
  type: ACTIONS.MOVIES.BULK_ACTION,
  data,
});

/* --------- Actions for select toggle in CardSelector component ------------ */
export const selectMovieAction = (data) => ({
  type: ACTIONS.MOVIES.SELECT_MOVIE,
  data,
});

export const deselectMovieAction = (data) => ({
  type: ACTIONS.MOVIES.DESELECT_MOVIE,
  data,
});

/* -------------------------------------------------------------------------- */

export const clearMoviesAction = () => ({
  type: ACTIONS.MOVIES.CLEAR_MOVIES,
});

export const clearSelectedMoviesAction = () => ({
  type: ACTIONS.MOVIES.CLEAR_SELECTED_MOVIES,
});

/* -------------------------------------------------------------------------- */

export const filterMoviesListAction = (data) => ({
  type: ACTIONS.MOVIES.FILTER_LIST,
  data,
});
