/**
 * ************************************
 *
 * @module  config.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description determines which API the app hits based on branch
 *
 * ************************************
 */
const config = {
  PORT: 7770,
  BASE_URL: 'https://api.trycobble.com',
  INSTANCE_ENVIRONMENT: 'production',
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default config;
