/**
 * ************************************
 *
 * @module  EventDateSelectorPopup.js
 * @author  Matt P
 * @date    02/16/2021
 * @description Popup Modal for EventDateSelector.
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                  Imports
// ---------------------------------------------------------------------------|
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { Backdrop, Button } from 'components';
import FormInput from 'components/Form/FormInput/FormInput';

import { momentObjectToStringObject } from 'utils/utils';

import { DATE_SELECTOR_POPUP as LABELS } from 'constants.js';

import closeIcon from 'assets/images/close.png';
import { DATE_SELECTOR_OPTIONS } from '../EventDateSelector.constants';

import DateSummary from '../DateSummary/DateSummary';
import EventDateSelectorService from '../EventDateSelectorService';
import EventDateTimeEdit from '../EventDateTimeEdit/EventDateTimeEdit';
import EventRecurringEdit from '../EventRecurringEdit/EventRecurringEdit';

import './EventDateSelectorPopup.scss';
import 'rc-time-picker/assets/index.css';
import '../../HoursRange/HoursRange.scss';

// ---------------------------------------------------------------------------|
//                                  Utilities
// ---------------------------------------------------------------------------|
const {
  validateTimeRange,
  checkForOverlappingDates,
  validateDateRange,
  validateWeekDays,
  validateSelectedMonth,
} = EventDateSelectorService;

// for cal month/year selection
const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear, new Date().getMonth());
const toMonth = new Date(currentYear + 10, 11);

// ---------------------------------------------------------------------------|
//                React Class Component - EventDateSelectorPopup
// ---------------------------------------------------------------------------|
class EventDateSelectorPopup extends Component {
  // -------------------------------------------------------------------------|
  //                             PropTypes Check
  // -------------------------------------------------------------------------|
  static propTypes = {
    dates: PropTypes.arrayOf(
      PropTypes.shape({
        startDate: PropTypes.shape(PropTypes.object),
        fromTime: PropTypes.string.isRequired,
        toTime: PropTypes.string.isRequired,
        isRecurring: PropTypes.bool,
        endDate: PropTypes.shape(PropTypes.object),
        selectedWeekDays: PropTypes.arrayOf(PropTypes.string),
        repeatMode: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
        availability: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      })
    ).isRequired,
    closeModal: PropTypes.func.isRequired,
    onDateUpdated: PropTypes.func.isRequired,
  };

  // -------------------------------------------------------------------------|
  //                              Default Props
  // -------------------------------------------------------------------------|
  static defaultProps = {
    date: null,
  };

  // -------------------------------------------------------------------------|
  //                          Constructor and State
  // -------------------------------------------------------------------------|
  constructor(props) {
    super(props);

    const { dates } = this.props;

    this.state = {
      dates: this.addErrorProperty(dates),
      selectorType: undefined,
      selectedDay: null,
      selectedDays: [],
      selectedDateRange: [],
      from: undefined,
      to: undefined,
      editFlag: false,
      month: fromMonth, // for cal month/year selection
    };
  }

  // -------------------------------------------------------------------------|
  //                          Class Component Methods
  // -------------------------------------------------------------------------|
  /**
   * @description dates from DB call will not have an error property, which
   * this component uses heavily to construct and edit dates.
   *
   * Upon initial mount, add this property to play nice with error handling
   *
   * @param {Array} - of day objects
   *
   * @returns {Array} - of day objects with error property
   */
  addErrorProperty = (datesArray) => {
    const datesArrayCopy = [...datesArray];

    for (let i = 0; i < datesArrayCopy.length; i += 1) {
      if (!datesArrayCopy[i].errors) datesArrayCopy[i].errors = {};
    }

    return datesArrayCopy;
  };

  /**
   * @description constructs repeat options for the repeat dropdown, will have a
   * will auto populate the value if this is an edit
   *
   * @param {Array} - of day strings ('monday', 'tuesday', etc..)
   *
   * @returns {Object} - object with date properties
   */
  buildDateObject = (repeatMode) => ({
    startDate: null,
    fromTime: null,
    toTime: null,
    isRecurring: true,
    endDate: null,
    repeatMode,
    availability: null,
    selectedWeekDays: [],
    errors: {},
  });

  /**
   * @description propagates/autofills the correct dropdown selection
   * upon edit
   *
   * @param {Array} - of day strings ('monday', 'tuesday', etc..)
   *
   * @returns {Object} - object with date properties
   */
  findDateSelectorDropdownValue = (datesArray) => {
    if (datesArray[0]) {
      const { repeatMode } = datesArray[0];

      if (!repeatMode) return DATE_SELECTOR_OPTIONS.DATES;

      if (repeatMode.value === 'Daily') return DATE_SELECTOR_OPTIONS.DATE_RANGE;
      if (repeatMode.value === 'Weekly' || repeatMode.value === 'Monthly')
        return DATE_SELECTOR_OPTIONS.RECURRING;
    }

    return null;
  };

  /**
   * @description constructs date selector options
   *
   * @returns {Object} - object with dropdown selections
   */
  constructDateSelectorOptions = () => {
    const { selectorType, editFlag, selectedDateRange, selectedDays } =
      this.state;

    let dropdownValue;

    // if this is an edit, we will populate the correct selectorType
    // dropdown value
    if (editFlag) {
      dropdownValue = this.findDateSelectorDropdownValue([
        ...selectedDateRange,
        ...selectedDays,
      ]);
    } else if (!editFlag && selectorType) {
      dropdownValue = selectorType.value;
    } else {
      dropdownValue = null;
    }

    return {
      type: 'dropdown',
      name: 'dateSelectorOptions',
      label: '',
      values: [
        DATE_SELECTOR_OPTIONS.DATES,
        DATE_SELECTOR_OPTIONS.DATE_RANGE,
        DATE_SELECTOR_OPTIONS.RECURRING,
      ],
      value: dropdownValue,
    };
  };

  /**
   * @description clears the selections and updates the selector
   * type
   *
   * @param {String} fieldName - either fromTime or toTime
   */
  updateSelectionType = (selectionType) => {
    this.clearSelections();

    this.setState({
      selectorType: selectionType,
      selectedDateRange:
        selectionType.value === 'dateRange'
          ? [this.buildDateObject({ label: 'Daily', value: 'Daily' })]
          : [],
    });
  };

  /**
   * @description used for updating the time range for 'singly selected dates'
   *
   * @param {String} fieldName - either fromTime or toTime
   * @param {moment Object} time
   * @param {Number} index - of date object array
   */
  updateTimeRange = (fieldName, time, index) => {
    const { selectedDays, selectedDateRange, selectorType } = this.state;
    const formattedTime = time.format(LABELS.TIME_FORMAT);

    let selectedStateCopy;

    if (selectorType.value === 'selectDates') {
      selectedStateCopy = [...selectedDays];
    } else if (selectorType.value === 'dateRange') {
      selectedStateCopy = [...selectedDateRange];
    }

    selectedStateCopy[index][fieldName] = formattedTime;

    // error handing for time range selection
    if (selectedStateCopy[index].fromTime && selectedStateCopy[index].toTime) {
      const { fromTime, toTime } = selectedStateCopy[index];

      selectedStateCopy[index].errors.timeErrors = validateTimeRange(
        fromTime,
        toTime
      );
    }

    if (selectorType.value === 'selectDates') {
      this.setState({ selectedDays: selectedStateCopy });
    } else if (selectorType.value === 'dateRange') {
      this.setState({ selectedDateRange: selectedStateCopy });
    }
  };

  /**
   * @description confirms if all built dates have a start and
   * end time, if not, disables the save button
   *
   * @param {Array} dateArray - either fromTime or toTime
   *
   * @returns {Boolean}
   */
  emptyStartOrEndTime = (dateArray) => {
    for (let i = 0; i < dateArray.length; i += 1) {
      if (!dateArray[i].fromTime || !dateArray[i].toTime) return true;
    }

    return false;
  };

  /**
   * @description used for the EventDateRange component. Updates
   * the date range object value and sets the parent
   * component state on the range type
   *
   * @param {Array} dateObj - with a single dateObject
   */
  updateDateRange = (dateObj) => {
    const [workingObj] = [...dateObj];

    const checkedForErrors = this.checkAndMarkDateRangeErrors(workingObj);

    const { startDate } = checkedForErrors;

    checkedForErrors.startDate = moment(startDate);

    this.setState({
      selectedDateRange: [checkedForErrors],
    });
  };

  /**
   * @description checks and marks any date range errors
   * to display as well as display save button
   *
   * @param {Array} dateObj - with a single dateObject
   */
  checkAndMarkDateRangeErrors = (dateObj) => {
    const dateObjectCopy = { ...dateObj };
    const { startDate, endDate, selectedWeekDays, availability } =
      dateObjectCopy;

    dateObjectCopy.errors.dateRangeError = validateDateRange(
      startDate,
      endDate
    );

    dateObjectCopy.errors.weekDaysError = validateWeekDays(
      startDate,
      endDate,
      selectedWeekDays
    );

    dateObjectCopy.errors.selectedMonthError = validateSelectedMonth(
      moment(startDate),
      endDate,
      availability
    );

    // error handing for time range selection
    if (dateObjectCopy.fromTime && dateObjectCopy.toTime) {
      const { fromTime, toTime } = dateObjectCopy;

      dateObjectCopy.errors.timeErrors = validateTimeRange(fromTime, toTime);
    }

    return dateObjectCopy;
  };

  /**
   * @description saves data to be set on parent comp form
   * does one last check for errors before allowing submission
   * to Event form
   */
  saveDate = () => {
    const { dates } = this.state;

    // check for overlapping errors before allowing done
    const overlapCheck = this.checkAndMarkOverlappingDates(dates);

    if (!this.isThereOverLap(overlapCheck)) {
      const { onDateUpdated, closeModal } = this.props;

      onDateUpdated(dates);
      closeModal();
    }
  };

  /**
   * @description runs the overlapping date check on apply as well as
   * submit
   *
   * @param {Array} datesArray - of objects with date data
   *
   * @returns {Array} - with errors properties
   */
  checkAndMarkOverlappingDates = (datesArray) => {
    const datesArrayCopy = [...datesArray];
    const dateCheck = checkForOverlappingDates(datesArrayCopy);

    // if there are overlap errors, mark the index of the overlap
    if (dateCheck.value) {
      if (!datesArrayCopy[dateCheck.overlap1].errors)
        datesArrayCopy[dateCheck.overlap1].errors = {};

      if (!datesArrayCopy[dateCheck.overlap2].errors)
        datesArrayCopy[dateCheck.overlap2].errors = {};

      datesArrayCopy[dateCheck.overlap1].errors.overlapError = dateCheck.value;
      datesArrayCopy[dateCheck.overlap2].errors.overlapError = dateCheck.value;

      return datesArrayCopy;
    }

    // if check comes back clean, wash all errors for dates array
    // or else corrected errors will persist
    for (let i = 0; i < datesArray.length; i += 1) {
      if (!datesArrayCopy[i].errors) datesArrayCopy[i].errors = {};
      datesArrayCopy[i].errors.overlapError = '';
    }

    return datesArrayCopy;
  };

  /**
   * @description saves data to be set on parent comp form
   *
   * @param {Array} datesArray
   *
   * @returns {Array} sorted by date
   */
  sortDates = (datesArray) => datesArray.sort((a, b) => a.date - b.date);

  /**
   * @description renders date selector (Calender) component
   *
   * @returns {JSX}
   */
  renderDateSelector = () => {
    const { selectedDay, selectedDays, from, to, month, selectorType } =
      this.state;

    const modifiers = { start: from, end: to };

    // points the cal to the correct day state
    let routedState;

    if (selectorType.value === 'dateRange') {
      routedState = [from, { from, to }];
    } else if (selectorType.value === 'selectDates') {
      routedState = selectedDays.map((selected) => selected.date);
    } else {
      routedState = selectedDay;
    }

    return (
      <div className="modal-content__date-selector">
        <div className="datepicker--calendar">
          <DayPicker
            month={month}
            fromMonth={fromMonth} // located in file utils
            toMonth={toMonth} // located in file utils
            captionElement={({ date, localeUtils }) =>
              this.renderYearMonthForm({
                date,
                localeUtils,
                onChange: this.handleYearMonthChange,
              })
            }
            showOutsideDays
            fixedWeeks
            selectedDays={routedState}
            modifiers={modifiers}
            onDayClick={this.handleDayClick}
            todayButton="Go To Today"
          />
        </div>
      </div>
    );
  };

  /**
   * @description allows main cal to have a year and month
   * dropdown
   *
   * @param {Object} date
   * @param {Object} localeUtils
   * @param {Function} onChange
   *
   * @returns {JSX}
   */
  renderYearMonthForm = ({ date, localeUtils, onChange }) => {
    const months = localeUtils.getMonths();
    const years = [];

    for (
      let i = fromMonth.getFullYear() - 1;
      i <= toMonth.getFullYear();
      i += 1
    ) {
      years.push(i);
    }

    const handleChange = (e) => {
      const { year, month } = e.target.form;
      onChange(new Date(year.value, month.value));
    };

    return (
      <form className="DayPicker-Caption">
        <select
          name="month"
          className="DayPicker-Caption__options"
          onChange={handleChange}
          value={date.getMonth()}
        >
          {months.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </select>
        <select
          name="year"
          className="DayPicker-Caption__options"
          onChange={handleChange}
          value={date.getFullYear()}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </form>
    );
  };

  /**
   * @description handles month change for calender
   *
   * @param {Object} month
   */
  handleYearMonthChange = (month) => {
    this.setState({ month });
  };

  /**
   * @description handles calender date selection
   *
   * @param {Object - Date} day
   * @param {Object - Date} selected
   */
  handleDayClick = (day, { selected }) => {
    const { selectorType } = this.state;

    if (selectorType.value === 'selectDates') {
      const { selectedDays } = this.state;

      if (selected) {
        // toggles selection and splices from already selected days array
        const selectedIndex = selectedDays.findIndex((selectedDay) =>
          DateUtils.isSameDay(selectedDay.date, day)
        );

        selectedDays.splice(selectedIndex, 1);
      } else {
        const buildObj = {
          date: day,
          isRecurring: false,
          startDate: moment(day),
          fromTime: undefined,
          toTime: undefined,
          errors: {},
        };

        selectedDays.push(buildObj);
      }

      this.setState({ selectedDays: this.sortDates(selectedDays) });
    }

    if (selectorType.value === 'dateRange') {
      const { selectedDateRange } = this.state;
      const dateRangeCopy = [...selectedDateRange];

      const range = DateUtils.addDayToRange(day, this.state);
      const { from, to } = range;

      dateRangeCopy[0].startDate = moment(from);
      dateRangeCopy[0].endDate = moment(to);

      this.setState({
        from,
        to,
        selectedDateRange: dateRangeCopy,
      });
    }

    if (selectorType.value === 'recurring') {
      const { selectedDateRange } = this.state;
      const dateRangeCopy = [...selectedDateRange];

      // if this is an edit or you change the start date of
      // a time you're building
      if (dateRangeCopy.length === 1) {
        dateRangeCopy[0].startDate = selected ? undefined : moment(day);

        const markedAndChecked = this.checkAndMarkDateRangeErrors(
          dateRangeCopy[0]
        );

        this.setState({
          selectedDay: selected ? undefined : day,
          selectedDateRange: [markedAndChecked],
        });
      } else {
        this.setState({
          selectedDay: selected ? undefined : day,
          selectedDateRange: dateRangeCopy,
        });
      }
    }
  };

  /**
   * @description renders modal header with close modal button
   *
   * @returns {JSX}
   */
  renderModalHeader = () => {
    const { closeModal } = this.props;

    return (
      <div className="modal-header">
        <span className="modal-title-bar">
          <h1 className="modal-title">Add Dates</h1>
        </span>
        <span
          className="modal-close"
          role="button"
          tabIndex="-1"
          onClick={closeModal}
        >
          <img src={closeIcon} alt="close popup" />
        </span>
      </div>
    );
  };

  /**
   * @description checks for an empty dates array as well
   * as for errors or empty times to disable or enable
   * the SAVE button
   *
   * @returns {Boolean}
   */
  shouldDisableSaveButton = (datesArray) => {
    if (datesArray.length === 0) return true;

    if (
      this.areThereErrors(datesArray) ||
      this.emptyStartOrEndTime(datesArray)
    ) {
      return true;
    }

    return false;
  };

  /**
   * @description renders modal footer with submission and clear buttons
   *
   * @returns {JSX}
   */
  renderModalFooter = () => {
    const { dates, selectedDays, selectedDateRange } = this.state;

    return (
      <div className="modal-footer">
        <div className="btn-container">
          <div className="date-picker-buttons">
            <Button
              className="btn-inverse"
              disabled={
                selectedDays.length > 0 || selectedDateRange
                  ? this.shouldDisableSaveButton([
                      ...selectedDays,
                      ...selectedDateRange,
                    ])
                  : true
              }
              onClick={this.applySelections}
              preventDefault
            >
              SAVE
            </Button>
            <Button
              disabled={dates.length > 0 ? this.isThereOverLap(dates) : true}
              onClick={this.saveDate}
              preventDefault
            >
              DONE
            </Button>
          </div>
        </div>
      </div>
    );
  };

  /**
   * @description checks if any of the build dateObjects have
   * any errors and if so, returns true to disable the save button.
   *
   * @returns {Boolean}
   */
  areThereErrors = (dateArray) => {
    for (let i = 0; i < dateArray.length; i += 1) {
      if (
        dateArray[i].errors &&
        (dateArray[i].errors.timeErrors ||
          dateArray[i].errors.dateRangeError ||
          dateArray[i].errors.weekDaysError ||
          dateArray[i].errors.selectedMonthError)
      )
        return true;
    }

    return false;
  };

  /**
   * @description checks if any of the applied dateObjects have
   * any overlap errors and if so, returns true to disable the done button.
   *
   * @returns {Boolean}
   */
  isThereOverLap = (dateArray) => {
    for (let i = 0; i < dateArray.length; i += 1) {
      if (dateArray[i].errors && dateArray[i].errors.overlapError) return true;
    }

    return false;
  };

  /**
   * @description filters any date objects with null or undefined
   * from or to dates, prevents form fields not completely filled our
   * to not be applied
   *
   * @param {Array} dateObjects
   *
   * @returns {Array}
   */
  scrubUndefinedOrNull = (dateObjects) =>
    dateObjects.filter((date) => date.fromTime && date.toTime);

  /**
   * @description fires on SAVE button click. Transfers the queued/edited
   * dates and puts them into the dates array to be displayed in the right
   * section of the form
   */
  applySelections = () => {
    const { selectedDays, selectedDateRange, dates } = this.state;

    const markOverlapErrors = this.checkAndMarkOverlappingDates([
      ...this.scrubUndefinedOrNull(selectedDays),
      ...dates,
      ...selectedDateRange,
    ]);

    this.setState(
      {
        dates: markOverlapErrors,
        editFlag: false,
      },
      this.clearSelections
    );
  };

  /**
   * @description clears the form of any user input
   */
  clearSelections = () => {
    this.setState({
      selectorType: undefined,
      selectedDay: null,
      selectedDays: [],
      selectedDateRange: [],
      from: undefined,
      to: undefined,
      editFlag: false,
    });
  };

  /**
   * @description deletes any applied date
   *
   * @param {Number} index - of the element to be deleted
   */
  deleteAppliedTime = (index) => {
    const { dates } = this.state;
    const datesCopy = [...dates];

    datesCopy.splice(index, 1);

    const checkedDates = this.checkAndMarkOverlappingDates(datesCopy);

    this.setState({
      dates: checkedDates,
    });
  };

  /**
   * @description edits an already applied time. fired when DateSummary
   * is clicked
   *
   * @param {Number} index - of the element to be edited
   */
  editAppliedTime = (index) => {
    const { dates } = this.state;

    this.clearSelections();

    const datesCopy = [...dates];
    const dateToEdit = datesCopy.splice(index, 1);

    const checkedDates = this.checkAndMarkOverlappingDates(datesCopy);

    // if the card is Daily, Weekly or Monthly
    if (dateToEdit[0].isRecurring) {
      const [toEdit] = dateToEdit;

      // finds the correct tab to queue up

      this.setState({
        selectedDateRange: [toEdit],
        dates: checkedDates,
        dateRangeType: toEdit.repeatMode.value,
        editFlag: true,
        from: new Date(toEdit.startDate),
        to: new Date(moment(toEdit.endDate)),
        selectorType:
          toEdit.repeatMode.value === 'Daily'
            ? DATE_SELECTOR_OPTIONS.DATE_RANGE
            : DATE_SELECTOR_OPTIONS.RECURRING,
        selectedDay:
          toEdit.repeatMode.value !== 'Daily'
            ? new Date(toEdit.startDate)
            : undefined,
      });
    } else {
      // Our fancy new date picker cal needs a date property to
      // show an indicator on the calender. Newly created dates will have it,
      // but older legacy DateSummary's will not. Adding the property
      // manually for this scenario
      if (!dateToEdit[0].date)
        dateToEdit[0].date = new Date(dateToEdit[0].startDate);

      this.setState({
        selectedDays: dateToEdit,
        dates: checkedDates,
        selectorType: DATE_SELECTOR_OPTIONS.DATES,
        editFlag: true,
      });
    }
  };

  /**
   * @description generates labels for components depending on selector type
   *
   * @param {Object} dates - dates object
   *
   * @returns {String}
   */
  getProperLabel = (date) => {
    const { selectorType, from, to } = this.state;

    if (selectorType.value === 'selectDates') {
      const dateObj = momentObjectToStringObject(moment(date));
      const { monthName, day, year } = dateObj;

      return `${monthName} ${day}, ${year}`;
    }

    if (selectorType.value === 'dateRange') {
      const fromObj = momentObjectToStringObject(moment(from));
      const toObj = momentObjectToStringObject(moment(to));

      return `Daily from ${fromObj.month}/${fromObj.day}/${fromObj.year}
       to ${toObj.month}/${toObj.day}/${toObj.year}`;
    }
  };

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  //        EventDateSelectorPopup rendering
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  render() {
    const {
      dates,
      selectorType,
      selectedDays,
      selectedDay,
      selectedDateRange,
      to,
      from,
      editFlag,
    } = this.state;

    const savedDates = this.sortDates(dates).map((date, index) => (
      <DateSummary
        {...date}
        key={index}
        className="modal-date-summary"
        onEdit={() => {
          if (!editFlag) this.editAppliedTime(index);
        }}
        onDelete={() => this.deleteAppliedTime(index)}
        errors={
          date.errors && date.errors.overlapError
            ? date.errors.overlapError
            : ''
        }
      />
    ));

    return (
      <Fragment>
        <Backdrop onClick={() => null} />
        <div className="popup-foreground-outer">
          <div className="popup-foreground-table">
            <div className="popup-foreground-wrapper">
              <div className="event-date-selector-modal modal modal-show">
                {this.renderModalHeader()}
                <div className="event-date-selector-modal__selection-content">
                  <div className="event-date-selector-modal__selection-content--date-picker">
                    <div className="modal-content">
                      <div className="datepicker--options">
                        <FormInput
                          value={selectorType}
                          options={this.constructDateSelectorOptions()}
                          eventHandler={this.updateSelectionType}
                          placeholder="Select"
                        />
                      </div>
                      {selectorType ? this.renderDateSelector() : ''}
                      <div className="modal-content__time-selector">
                        {selectorType &&
                        selectorType.value === 'selectDates' &&
                        selectedDays.length > 0
                          ? selectedDays.map((day, index) => (
                              // eslint-disable-next-line react/jsx-indent
                              <EventDateTimeEdit
                                key={`EventDateTimeEdit-${index}`}
                                label={this.getProperLabel(day.date)}
                                date={day.date}
                                fromTime={day.fromTime}
                                toTime={day.toTime}
                                updateTimeRange={this.updateTimeRange}
                                index={index}
                                errors={
                                  day.errors && day.errors.timeErrors
                                    ? day.errors.timeErrors
                                    : ''
                                }
                              />
                            ))
                          : ''}
                        {selectorType &&
                        selectorType.value === 'dateRange' &&
                        to &&
                        from ? (
                          <EventDateTimeEdit
                            date={selectedDay}
                            label={this.getProperLabel()}
                            fromTime={selectedDateRange[0].fromTime}
                            toTime={selectedDateRange[0].toTime}
                            updateTimeRange={this.updateTimeRange}
                            index={0}
                            errors={
                              selectedDateRange[0].errors.timeErrors
                                ? selectedDateRange[0].errors.timeErrors
                                : ''
                            }
                          />
                        ) : (
                          ''
                        )}
                        {selectorType &&
                        selectorType.value === 'recurring' &&
                        selectedDay ? (
                          // eslint-disable-next-line react/jsx-indent
                          <EventRecurringEdit
                            isEdit={editFlag}
                            editPayload={editFlag ? selectedDateRange[0] : null}
                            fromDate={selectedDay}
                            updateDateRange={this.updateDateRange}
                            errors={
                              selectedDateRange[0] &&
                              selectedDateRange[0].errors
                                ? selectedDateRange[0].errors
                                : ''
                            }
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="event-date-selector-modal__selection-content-dates-list">
                    <div className="modal-content">
                      <h2>Saved Dates</h2>
                      {dates.length !== 0 ? <div>{savedDates}</div> : ''}
                    </div>
                  </div>
                </div>
                {this.renderModalFooter()}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

// -------------------------------------------------------------------------|
//                                  Export
// -------------------------------------------------------------------------|
export default EventDateSelectorPopup;
