/**
 * ************************************
 *
 * @module  Overlay.js
 * @author  Alex A
 * @date    ????
 * @description overlay
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import './Overlay.scss';

// ----------------------------------------------------------------------------|
//                          React Class Component
// ----------------------------------------------------------------------------|
const Overlay = ({ show, children, customClasses }) => {
  const modalClasses = cx(['overlay', { 'overlay-show': show }, customClasses]);

  return (
    <Fragment>
      <div className={modalClasses}>
        <div className="overlay-innerWrapper">
          <div className="overlay-content">{children}</div>
        </div>
      </div>
    </Fragment>
  );
};
// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
Overlay.propTypes = {
  show: PropTypes.bool,
  customClasses: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
Overlay.defaultProps = {
  show: false,
  customClasses: '',
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default Overlay;
