/**
 * ************************************
 *
 * @module  CuratedCards.action.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description CuratedCards redux actions
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import ACTIONS from './actionTypes';

export const getCuratedCardsAction = (data) => ({
  type: ACTIONS.CURATED_CARDS.GET_LIST,
  data,
});

// ----------------------------------------------------------------------------|
//                                Exports
// ----------------------------------------------------------------------------|
export const performBulkOperationAction = (data) => ({
  type: ACTIONS.CURATED_CARDS.BULK_ACTION,
  data,
});

export const clearCuratedCardsAction = (data) => ({
  type: ACTIONS.CURATED_CARDS.CLEAR_LIST,
  data,
});

export const deleteCuratedCardAction = (id) => ({
  type: ACTIONS.CURATED_CARDS.DELETE_CURATED_CARDS,
  data: { id },
});

export const filterCuratedCardsAction = (data) => ({
  type: ACTIONS.CURATED_CARDS.FILTER_LIST,
  data,
});
