/**
 * ************************************
 *
 * @module  Option.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Component rendering the dropdown options
 * (Neighborhood/Price/Category)
 * checkbox comp included! Works in conjunction with react-select
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { components as Components } from 'react-select';

import { Checkbox } from 'components';

import './Option.scss';

// ----------------------------------------------------------------------------|
//                    React Function Component - Option
// ----------------------------------------------------------------------------|
const Option = (props) => {
  const { isSelected, label } = props;

  const optionContainerCss = cx({
    option: true,
    'is-selected': isSelected,
    'check-box-option': true,
  });

  return (
    <Components.Option className={optionContainerCss} {...props}>
      <Checkbox
        className="square"
        onClick={() => null}
        toBeChecked={isSelected}
      />
      <span>{label}</span>
    </Components.Option>
  );
};

// ----------------------------------------------------------------------------|
//                        PropTypes Check - Option
// ----------------------------------------------------------------------------|
Option.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

// ----------------------------------------------------------------------------|
//                            Option Export
// ----------------------------------------------------------------------------|
export default Option;
