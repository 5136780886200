/**
 * ************************************
 *
 * @module  Event.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description container for the Event card form edit
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { fetchPlaceAction } from 'store/actions/Place.action';
import { fetchNeighborhoodLocationsAction } from 'store/actions/Places.action';

import { deleteEventAction } from 'store/actions/~depreciated/Events.actions';

import {
  clearImagesAction,
  deleteImageAction,
  populateAutofillImagesAction,
  saveDataToReduxAction,
  saveDataAction,
  uploadImageAction,
  reOrderImagesAction,
} from 'store/actions/~depreciated/Event.action';

import { toast } from 'react-toastify';

import CommonService from 'services/CommonService';
// eslint-disable-next-line max-len
import EventDateSelectorService from 'components/EventDateSelector/EventDateSelectorService';
import currentEnv from 'utils/EnvironmentSpecificValues';

import {
  CobCheckbox,
  Form,
  FileUploader,
  // PreviewWrapper,
  WarningModal,
  Loader,
  FormActions,
  Overlay,
} from 'components';

import { PLACE, EVENTS } from 'constants.js';

import {
  getUniqueSlug,
  getPriceRangeLabel,
  getProcessedNeighborhoods,
  getImageURL,
  applyDrag,
  locationCoordinateCheck,
  hasUniqueSlug,
  deepCopy,
  formatISODate,
  getReservationTiers,
  getDurationTiers,
  chooseItemizedDescription,
  mapDetailedCategories,
} from 'utils/utils';
import { extractCategoryTypes } from 'utils/CategoryUtils';
import { isValidNumber } from 'utils/FormFunc';

import EventService from './EventService';
import EventData from './EventData.constants';

import 'containers/~depreciated/Place/Place.scss';

// ----------------------------------------------------------------------------|
//                        Redux - Property Mapping
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  fetchPlaceOrEvent: (data) => dispatch(fetchPlaceAction(data)),
  fetchNeighborhoodLocations: () =>
    dispatch(fetchNeighborhoodLocationsAction()),
  uploadImage: (data) => dispatch(uploadImageAction(data)),
  deleteImage: (data) => dispatch(deleteImageAction(data)),
  populateAutofillImages: (data) =>
    dispatch(populateAutofillImagesAction(data)),
  saveDataToRedux: (data) => dispatch(saveDataToReduxAction(data)),
  saveData: (data, id, successCB) =>
    dispatch(saveDataAction(data, id, successCB)),
  clearImages: () => dispatch(clearImagesAction()),
  reOrderImages: (data) => dispatch(reOrderImagesAction(data)),
  deleteEvent: (id) => dispatch(deleteEventAction(id)),
});

const mapStateToProps = (state) => ({
  event: state.event,
  categoryMap: state.places.categoryMap,
  adminCategories: state.places.adminCategories,
  placeCategories: state.places.placeCategories,
  vibes: state.places.vibes,
  neighborhoods: state.places.neighborhoodLocations,
  categoriesLoaded: state.places.categoriesLoaded,
});

// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|
const commonService = CommonService();

const eventService = EventService();

const { WARNING_ON_DRAFT } = EVENTS;

// ----------------------------------------------------------------------------|
//                    React Class PureComponent - Event
// ----------------------------------------------------------------------------|
class Event extends PureComponent {
  // --------------------------------------------------------------------------|
  //                            PropTypes Check
  // --------------------------------------------------------------------------|
  static propTypes = {
    event: PropTypes.object.isRequired,
    ...commonService.getProTypesForPlaceEvent(),
  };

  // --------------------------------------------------------------------------|
  //                        Default Props - Activity
  // --------------------------------------------------------------------------|
  static defaultProps = {};

  // --------------------------------------------------------------------------|
  //                                State
  // --------------------------------------------------------------------------|
  constructor(props) {
    super(props);

    const { data, saveDataToRedux } = this.props;

    const initialEventState = this.handlePlaceData(data);

    this.state = {
      eventId: data.id,
      errors: {},
      imageErrors: '',
      shouldFetchEvent: data.shouldFetchEvent,
      shouldDisplayLoader: false,
      ...initialEventState,
    };
    // initially keep the data in the redux
    saveDataToRedux(this.getToBeProcessedData(data));
  }

  // --------------------------------------------------------------------------|
  //                          Lifecycle Methods
  // --------------------------------------------------------------------------|
  componentDidMount() {
    const { categoriesLoaded, data, neighborhoods, populateAutofillImages } =
      this.props;
    const { shouldFetchEvent } = this.state;

    populateAutofillImages(data.images);

    if (shouldFetchEvent) {
      this.fetchEventFromServer();
      this.setState({ shouldFetchEvent: false, shouldDisplayLoader: true });
    }

    // cats and neighborhoods are async calls to redux saga, and should load
    // before the form renders. The shouldDisplayLoader flag is set for
    // this reason, and is flipped when the component updates on prop
    // fulfillment
    if (neighborhoods.length === 0 || !categoriesLoaded) {
      this.setState({ shouldDisplayLoader: true });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      categoriesLoaded,
      data,
      neighborhoods,
      event,
      saveDataToRedux,
      populateAutofillImages,
    } = this.props;
    const { eventId, shouldDisplayLoader } = this.state;

    // used for updating and rendering a fetched place
    // also checks if the async calls have come back and will flip
    // the loaderDisplay flag when loaded.
    if (data.shouldFetchEvent) {
      if (
        neighborhoods.length !== 0 &&
        categoriesLoaded &&
        event.fetchedPlaceData &&
        eventId === event.fetchedPlaceData.id &&
        shouldDisplayLoader
      ) {
        this.setState({
          shouldDisplayLoader: false,
          ...this.handlePlaceData(event.fetchedPlaceData),
        });

        saveDataToRedux(this.getToBeProcessedData(event.fetchedPlaceData));
        populateAutofillImages(event.fetchedPlaceData.images);
      }
    } else if (
      neighborhoods.length !== 0 &&
      categoriesLoaded &&
      shouldDisplayLoader
    ) {
      this.setState({
        shouldDisplayLoader: false,
        ...this.handlePlaceData(data),
      });

      saveDataToRedux(this.getToBeProcessedData(data));
    }
  }

  componentWillUnmount() {
    const { clearImages } = this.props;

    clearImages();
  }

  // --------------------------------------------------------------------------|
  //                      Component Methods - Event
  // --------------------------------------------------------------------------|
  /**
   * @description Fetch Activity object from server using ID
   */
  fetchEventFromServer = () => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.fetchPlaceOrEvent({ placeId: this.state.eventId });
  };

  /**
   * @description Handle activity object data: prepare form, display, etc..
   *
   * @param {Object} placeDataToProcess - Object of movie data
   */
  handlePlaceData = (placeDataToProcess) => {
    const toBeProcessedObj = this.getToBeProcessedData(placeDataToProcess);
    const processedData = this.getProcessedData(toBeProcessedObj);

    return { data: processedData };
  };

  /**
   * @description fires to reorders images
   *
   * @param {Object} dropData
   */
  onReOrderImages = (dropData) => {
    const { event, reOrderImages } = this.props;
    const { autofilledImages } = event;
    const reOrderedImges = applyDrag([...autofilledImages], dropData);

    reOrderImages(reOrderedImges);
  };

  /**
   * @description fires to saveData. Runs several
   * validation checks found in EventService file
   *
   * @param {Object} e - Event Object.
   */
  saveData = (e) => {
    const {
      event,
      data,
      closeModal,
      adminCategories,
      saveData,
      neighborhoods,
    } = this.props;
    const { autofilledImages } = event;

    const formattedImages = event.autofilledImages.map((image) => {
      const imageTemp = { ...image };

      // // fixed error where is photo_metadata || photo_credit === null
      // // will wash to empty string for DB PUT/PATCH
      if (imageTemp.photo_credits === null) imageTemp.photo_credits = '';
      if (imageTemp.photo_metadata === null) imageTemp.photo_metadata = '';

      if (image.source === 'cobble') {
        delete imageTemp.fileId;

        return imageTemp;
      }

      return imageTemp;
    });

    const formData = eventService.getPreparedFormData(
      event.formData,
      [...formattedImages],
      e.target.name
    );

    formData.set_categories = formData.categories.map((cat) => cat.unique_slug);

    delete formData.categories;

    formData.main_categories = event.formData.main_categories.map(
      (cat) => cat.unique_slug
    );

    formData.set_neighborhoods = getUniqueSlug(
      event.formData.neighborhoods,
      neighborhoods
    );

    formData.event_venue_name =
      formData.event_venue_name || event.formData.event_venue_name;

    if (!formData.recurring_event) {
      formData.recurring_days = [];
    }
    /** Perform validation only on published */
    if (e.target && e.target.name === 'published') {
      const errors = eventService.validate(event.formData);
      const imageErrors = commonService.validateImages(
        event.images,
        autofilledImages
      )
        ? null
        : PLACE.IMAGE_VALIDATION_MESSAGE;

      if (Object.keys(errors).length === 0 && imageErrors === null) {
        // dispatch to save
        saveData(formData, data.id, closeModal);
      } else {
        toast.error('There are some errors in the form');
      }

      this.setState({
        errors,
        imageErrors,
      });
    } else if (e.target && e.target.name === 'draft') {
      const errors = {};
      const imageErrors = '';
      const validateName = commonService.validateName(event.formData.name);

      if (
        formData.price_tier > 0 &&
        (formData.event_amount === '' || !isValidNumber(formData.event_amount))
      ) {
        errors.event_amount = 'Please enter a valid amount';
      }

      if (validateName) {
        errors.name = validateName;
      }

      if (
        !commonService.validatePrimaryCategories(
          event.formData.main_categories,
          event.formData.categories
        )
      ) {
        errors.categories =
          'Primary categories must be included in the selected categories';
      }

      if (Object.keys(errors).length === 0) {
        formData.event_amount = formData.event_amount || 0;
        saveData(formData, data.id, closeModal);
      } else {
        toast.error('There are some errors in the form');
      }

      this.setState({
        errors,
        imageErrors,
      });
    }
  };

  /**
   * @description returns an object with the correct
   * key/value pairs for editing
   *
   * @param {Object} obj
   */
  getToBeProcessedData = (obj) => {
    const { categoryMap } = this.props;

    // since we are using categories for several different fields,
    // we need to delineate the categories to the specific fields
    // they will later upon submission be joined for the API POST request
    const { misc } = extractCategoryTypes(
      mapDetailedCategories(obj.categories, categoryMap)
    );

    return {
      name: obj.name,
      address: obj.address || '',
      one_liner_description: obj.phrase,
      long_description: obj.description,
      images: obj.images,
      categories: obj.categories || [],
      main_categories: obj.main_categories
        ? mapDetailedCategories(obj.main_categories, categoryMap)
        : [],
      neighborhoods: getProcessedNeighborhoods(obj.neighborhoods),
      price_tier: obj.price_tier,
      from_time: obj.from_time,
      to_time: obj.to_time,
      website_link: obj.website,
      booking_links: obj.booking_links,
      cta_title_long: obj.cta_title_long,
      cta_title_short: obj.cta_title_short,
      reservation_tier: obj.reservation_tier || '',
      phone_number: obj.phone,
      start_date: obj.start_date || new Date(),
      end_date: obj.end_date || new Date(),
      recurring_days: obj.recurring_days,
      recurring_event: obj.recurring_event,
      location: '',
      event_amount: obj.event_amount ? String(obj.event_amount) : '0',
      event_amount_suffix: obj.event_amount_suffix,
      lat: obj.lat,
      lng: obj.lng,
      event_venue_name: obj.event_venue_name || '',
      event_hours: EventDateSelectorService.prepareDataForUI(obj.event_hours),
      last_admin_review: obj.last_admin_review,
      unit_history: obj.unit_history,
      vibes: obj.vibes || [],
      // special case where we want to put the detailed description from
      // old card details into the itemized one
      itemized_description: chooseItemizedDescription(
        obj.itemized_description,
        obj.description,
        eventService.defaultDescriptions()
      ),
      // used to keep track of the miscOptions that are also categories,
      // are joined together upon form submission
      misc_options: misc || [],
      duration: obj.duration || 0,
    };
  };

  getProcessedData = (data = []) => {
    const { placeCategories, vibes, neighborhoods } = this.props;

    return EventData.map((inputObj) => {
      let input = {};

      switch (inputObj.name) {
        case 'categories':
          input = {
            ...inputObj,
            main_categories: data.main_categories,
            tags: data.categories,
            suggestions: placeCategories || [],
          };
          break;
        case 'vibes':
          input = {
            ...inputObj,
            tags: data.vibes,
            suggestions: vibes || [],
          };
          break;
        case 'neighborhoods':
          input = {
            ...inputObj,
            tags: data.neighborhoods,
            suggestions: getProcessedNeighborhoods(neighborhoods),
            onButtonClick: this.onAutoPopulateLocationsClick,
          };
          break;
        case 'hour_range':
          input = {
            ...inputObj,
            from: data.from_time,
            to: data.to_time,
            errors: '',
          };
          break;
        case 'date_range':
          input = {
            ...inputObj,
            from: data.start_date,
            to: data.end_date,
            errors: '',
          };
          break;
        case 'amount_per':
          input = {
            ...inputObj,
            amount: data.event_amount,
            per: data.event_amount_suffix,
            errors: '',
          };
          break;
        case 'recurring_event':
          input = {
            ...inputObj,
            isRecurring: data.recurring_event,
            recurring_days: data.recurring_days,
            errors: '',
          };
          break;
        case 'address_input':
          input = {
            ...inputObj,
            value: data.event_venue_name,
            errors: '',
            disabled: hasUniqueSlug(
              data.misc_options,
              currentEnv.uniqueSlugs.virtualEvent
            ),
            additionalComponent: (
              <CobCheckbox
                label={currentEnv.uniqueSlugs.virtualEvent} // cat unique slug
                displayText="Stay-In/Virtual"
                onClick={(checked, label) => {
                  this.handleMiscOptionToggle(checked, label, 'category');
                }}
                toBeChecked={hasUniqueSlug(
                  data.misc_options,
                  currentEnv.uniqueSlugs.virtualEvent
                )}
              />
            ),
          };
          break;
        case 'price_tier':
          input = {
            ...inputObj,
            value:
              data.price_tier !== undefined
                ? {
                    label: getPriceRangeLabel(data.price_tier),
                    value: data.price_tier,
                  }
                : '',
          };
          break;
        case 'duration':
          input = {
            ...inputObj,
            value:
              data.duration !== undefined && data.duration !== null
                ? getDurationTiers(data.duration).pop()
                : getDurationTiers().pop(),
          };
          break;
        case 'booking_links':
          input = {
            ...inputObj,
            value: data.booking_links,
            disabled: data.reservation_tier === 'walk_in_only',
            additionalComponent: (
              <CobCheckbox
                label="reservation_tier" // property name
                displayText="Does not take reservations"
                onClick={(checked, label) => {
                  const reservationType = getReservationTiers();

                  if (checked) {
                    // set val to 'walk_in_only'
                    this.eventHandler(reservationType[5], label);
                  } else {
                    // set val to ''
                    this.eventHandler(reservationType[0], label);
                  }
                }}
                toBeChecked={data.reservation_tier === 'walk_in_only'}
              />
            ),
          };
          break;
        case 'reservation_tier':
          // conditionally renders cased on reservation toggle
          input = {
            ...inputObj,
            value: data.reservation_tier,
            hide: data.reservation_tier === 'walk_in_only',
          };
          break;
        case 'last_admin_review':
          input = {
            ...inputObj,
            value: data.last_admin_review
              ? data.last_admin_review.review_status
              : null,
            reviewDate: data.last_admin_review
              ? data.last_admin_review.review_datetime
              : null,
            history: data.unit_history || [],
          };
          break;

        default:
          input = {
            ...inputObj,
            value: data[inputObj.name],
          };
          break;
      }

      return input;
    });
  };

  /**
   * @description toggles preview modal
   *
   * @param {Boolean} isShow
   */
  onShowPreviewModal = (isShow) => {
    this.setState({ showPreviewModal: isShow });
  };

  /**
   * @description fires on an 'event' which is any input or
   * change to the form. Updates redux
   *
   * @param {Any} event - data being passed || element name
   * @param {String} elemName - name of that input
   */
  eventHandler = (event, elemName) => {
    const { saveDataToRedux } = this.props;
    // const data = eventService.getDataToBeSaved(event, elemName);

    if (
      elemName === 'categories' ||
      elemName === 'main_categories' ||
      elemName === 'vibes' ||
      elemName === 'booking_links' ||
      elemName === 'misc_options' ||
      elemName === 'admin_review' ||
      elemName === 'itemized_description'
    ) {
      saveDataToRedux({ [elemName]: event });
      return;
    }

    if (Array.isArray(event)) {
      const data = event.map((each) => each.name);

      saveDataToRedux({ [elemName]: data });
      return;
    }

    if (
      elemName === 'price_tier' ||
      elemName === 'reservation_tier' ||
      elemName === 'duration'
    ) {
      saveDataToRedux({ [elemName]: event.value });
      return;
    }

    if (event.name === 'from_time' || event.name === 'to_time') {
      saveDataToRedux({
        [elemName]: event.value && event.value.format('HH:mm'),
      });
      return;
    }

    if (event.name === 'start_date' || event.name === 'end_date') {
      saveDataToRedux({
        [event.name]: formatISODate(event.value, 'YYYY-MM-DD'),
      });
      return;
    }

    if (
      event.name === 'event_amount' ||
      event.name === 'event_amount_suffix' ||
      event.name === 'event_hours'
    ) {
      saveDataToRedux({ [event.name]: event.value });
      return;
    }

    if (event.name === 'recurring_event') {
      saveDataToRedux({
        recurring_days: event.events,
        recurring_event: event.isRecurring,
      });
      return;
    }

    if (event.name === 'address_input') {
      const data = {
        lat: event.value.lat,
        lng: event.value.long,
        address: event.value.address || '',
      };
      saveDataToRedux(data);
      return;
    }

    const { name, value } = event.target;

    saveDataToRedux({ [name]: value });
  };

  /**
   * @description fires when any of the form action buttons (bottom of form)
   * are clicked - ie: "MOVE TO DRAFTS" / "PUBLISHED" / "PREVIEW" and passes
   * data to be validated for a DB PUT/PATCH
   *
   * @argument {Object} event - Event Object.
   */
  onFormAction = (event) => {
    const { name } = event.target;
    const { currentTab, data } = this.props;

    if (name === 'preview') {
      this.onShowPreviewModal(true);
    } else if (
      name === 'draft' &&
      currentTab === 'published' &&
      data &&
      data.id
    ) {
      event.persist();

      this.setState({
        showWarningModal: true,
        onWarningClose: () => {
          this.saveData(event);
          this.setState({ showWarningModal: false, onWarningClose: () => {} });
        },
      });
    } else if (name === 'published' || name === 'draft') {
      this.saveData(event);
    } else if (name === 'delete') {
      event.persist();

      this.setState({
        showWarningModal: true,
        onWarningClose: () => {
          const { deleteEvent, closeModal, data } = this.props;

          // we can't call closeModal until the delete is resolved,
          // as closeModal resets the list state to [] which will not
          // allow us to filter the deleted card. So we close it after the
          // delete async function is complete
          const promiseMe = new Promise((resolve, reject) => {
            resolve(deleteEvent(data.id));
          });

          promiseMe
            .then(() => {
              closeModal();
            })
            .then(() => {
              this.setState({
                showWarningModal: false,
                onWarningClose: () => {},
              });
            });
        },
      });
    }
  };

  autoPopulateLocation = (eventData, neighborhoods) => {
    const { lat, lng } = eventData;
    const returnedLocations = [];

    for (let i = 0; i < neighborhoods.length; i += 1) {
      const latLongCheck = locationCoordinateCheck(lat, lng, neighborhoods[i]);

      if (neighborhoods[i].boundaries_coordinates && latLongCheck)
        returnedLocations.push(neighborhoods[i]);
    }

    return returnedLocations;
  };

  updateStateDataAndRedux = (dataCopy, formField, formData) => {
    const { saveDataToRedux } = this.props;

    this.setState(
      {
        // updates data to be fed to form fields
        ...this.handlePlaceData(dataCopy),
      }, // updates form data to be saved from form->backend so
      // it'll persist between sessions
      () => saveDataToRedux({ [formField]: formData })
    );
  };

  handleMiscOptionToggle = (checked, uniqueSlug, type) => {
    if (type === 'category') {
      const { event } = this.props;
      const { formData } = event;
      const { misc_options } = formData;

      const optionsCopy = deepCopy(misc_options);

      const hasSlug = hasUniqueSlug(misc_options, uniqueSlug);

      if (checked && !hasSlug) {
        const { categoryMap } = this.props;
        const category = categoryMap[uniqueSlug];

        // prevents undefined from being pushed to the array
        if (category) optionsCopy.push(category);
      }

      if (!checked && hasSlug) {
        for (let i = 0; i < optionsCopy.length; i += 1) {
          if (optionsCopy[i].unique_slug === uniqueSlug) {
            optionsCopy.splice(i, 1);
          }
        }
      }

      this.eventHandler(optionsCopy, 'misc_options');
    }
  };

  onAutoPopulateLocationsClick = () => {
    const { data, event, neighborhoods } = this.props;

    const locationGridResult = this.autoPopulateLocation(
      event.formData,
      neighborhoods
    );

    if (data.shouldFetchEvent) {
      // if card was fetched
      this.updateStateDataAndRedux(
        {
          ...event.fetchedPlaceData,
          neighborhoods: locationGridResult,
        },
        'neighborhoods',
        locationGridResult
      );
    } else {
      // if it's a card already fetched
      this.updateStateDataAndRedux(
        {
          ...data,
          neighborhoods: locationGridResult,
        },
        'neighborhoods',
        locationGridResult
      );
    }
  };

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  Event rendering
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  render() {
    const {
      uploadImage,
      deleteImage,
      event,
      populateAutofillImages,
      currentTab,
    } = this.props;
    const {
      data,
      errors,
      showWarningModal,
      onWarningClose,
      shouldDisplayLoader,
      imageErrors,
      showPreviewModal,
    } = this.state;
    const { formData } = event;
    const updatedData = commonService.getUpdatedData(formData, [...data]);

    updatedData.event_venue_name = data.event_venue_name;

    const {
      name,
      categories,
      neighborhoods,
      address,
      long_description: desc,
      price_tier: priceTier,
      phone_number: phone,
      start_date,
      from_time,
      event_amount,
      event_amount_suffix,
      one_liner_description: shortDesc,
    } = formData;
    // Data for preview modal, please update this data with updated one.
    const images = [...event.autofilledImages, ...event.images];
    const previewModalData = {
      previewImage: getImageURL(images, false),
      title: name,
      category: categories,
      neighborhood: neighborhoods,
      address,
      price: getPriceRangeLabel(priceTier),
      desc,
      shortDesc,
      startDate: start_date,
      fromTime: from_time,
      eventAmount: event_amount,
      eventAmountSuffix: event_amount_suffix,
      phone,
    };

    return (
      <div className="place">
        {shouldDisplayLoader === true ? (
          <div className="overlay-wrapper">
            <Overlay show>
              <Loader />
            </Overlay>
          </div>
        ) : (
          <Fragment>
            <div className="form-content">
              {showWarningModal && (
                <WarningModal
                  message={WARNING_ON_DRAFT}
                  onSubmit={onWarningClose}
                  onCancel={() => {
                    this.setState({ showWarningModal: false });
                  }}
                />
              )}
              <FileUploader
                isDropZonePreviewRequired={event.isDropZonePreviewRequired}
                data={event.autofilledImages}
                onEvent={populateAutofillImages}
                failedUploads={event.failedUploads}
                onAddImage={uploadImage}
                onDeleteImage={deleteImage}
                error={imageErrors}
                onDrop={this.onReOrderImages}
              />
              <div className="form">
                <Form
                  data={updatedData}
                  onEvent={this.eventHandler}
                  errors={errors}
                />
              </div>
            </div>
            <FormActions
              verticalType="Event"
              onAction={this.onFormAction}
              currentTab={currentTab}
              cardId={this.props.data.id}
              // eslint-disable-next-line react/destructuring-assignment
              isNewCard={Object.keys(this.props.data).length > 0}
              isBtnDisabled={event.isBtnDisabled}
            />
            {/*showPreviewModal && (
              <PreviewWrapper
                data={previewModalData}
                modalClose={() => this.onShowPreviewModal(false)}
                fromEvent
              />
            )*/}
          </Fragment>
        )}
      </div>
    );
  }
}
// ----------------------------------------------------------------------------|
//                      Event Export with Redux Connect
// ----------------------------------------------------------------------------|
export default connect(mapStateToProps, mapDispatchToProps)(Event);
