/**
 * ************************************
 *
 * @module  Recipes.action.js
 * @author  Matt P
 * @date    01/19/2021
 * @description Recipes component redux actions
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                                  Exports
// ----------------------------------------------------------------------------|
export const getRecipesListAction = (data) => ({
  type: ACTIONS.RECIPES.GET_RECIPES_LIST,
  data: { ...data },
});

export const addRecipesAction = (data) => ({
  type: ACTIONS.RECIPES.ADD_RECIPES,
  data,
});

export const deleteRecipeAction = (data) => ({
  type: ACTIONS.RECIPES.DELETE_RECIPE,
  data,
});

export const performBulkOperationAction = (data) => ({
  type: ACTIONS.RECIPES.BULK_ACTION,
  data: { ...data },
});

/* ----- Actions for toggling selection in CardSelector component ----- */
export const selectRecipeAction = (data) => ({
  type: ACTIONS.RECIPES.SELECT_RECIPE,
  data,
});

export const deselectRecipeAction = (data) => ({
  type: ACTIONS.RECIPES.DESELECT_RECIPE,
  data,
});

/* -------------------------------------------------------------------------- */

export const clearRecipesAction = () => ({
  type: ACTIONS.RECIPES.CLEAR_RECIPES,
});

export const clearSelectedRecipesAction = () => ({
  type: ACTIONS.RECIPES.CLEAR_SELECTED_RECIPES,
});

/* -------------------------------------------------------------------------- */

export const filterRecipesListAction = (data) => ({
  type: ACTIONS.RECIPES.FILTER_LIST,
  data: { ...data },
});
