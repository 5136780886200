/**
 * ************************************
 *
 * @module  Place.saga.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description redux saga file for the Place component
 *
 *  *  * ** PLACES IS IS A DEPENDENCY FOR OTHER FILES (ie. Curated Card(s)) and
 * can not yet be depreciated
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import ACTIONS from 'store/actions/actionTypes';

import API from 'API';

import { GENERIC } from 'constants.js';
import { getPartsFromUrl, constructQueryParams } from 'utils/utils';

import { reportAPIError } from 'utils/ErrorHandlingUtils';

// ----------------------------------------------------------------------------|
//                                  Utilities
// ----------------------------------------------------------------------------|
/**
 * @description formats data
 *
 * @param {Array} data - Array of Objects
 *
 * @returns {Array}
 */
const formatData = (data = []) => {
  if (typeof data[0] === 'object') {
    return data.map((each) => each.name);
  }
  return data;
};

// ----------------------------------------------------------------------------|
//                                Sagas - Place
// ----------------------------------------------------------------------------|
/**
 * @description handles uploading an image
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* uploadImage(action) {
  const { IMAGE_UPLOAD_FAIL } = GENERIC;
  const { postData } = window.axios;
  const { file, fileId, photo_metadata } = action.data;

  try {
    const response = yield call(postData, API.placeImage, file, {
      'Content-Type': 'multipart/form-data',
    });

    if (response.status === 200) {
      yield put({
        type: ACTIONS.PLACE.UPLOAD_IMAGE_SUCCESS,
        data: { ...response.data, fileId, photo_metadata },
      });
    } else {
      toast.error(IMAGE_UPLOAD_FAIL);
      yield put({ type: ACTIONS.PLACE.UPLOAD_IMAGE_FAIL });
    }
  } catch (error) {
    toast.error(IMAGE_UPLOAD_FAIL);
    yield put({ type: ACTIONS.PLACE.UPLOAD_IMAGE_FAIL });
  }
}

/**
 * @description handles updating data
 *
 * @param {Object} action - object passed from the redux action
 * @param {String} id - id of action
 */
function* updatedData(data, id) {
  const { status: toBeStatus } = data;
  const { secondPart: currentTab } = getPartsFromUrl(window.location.pathname);

  const currentStatus = currentTab === 'drafts' ? 'draft' : currentTab;

  if (toBeStatus === currentStatus) {
    yield put({ type: ACTIONS.PLACES.MOVE_TO_TOP, data: { id, data } });
  } else {
    yield put({ type: ACTIONS.PLACES.REMOVE_PLACE, data: { id } });
  }
}

/**
 * @description Fetch Place object using ID
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* fetchPlace(action) {
  const { getData } = window.axios;
  try {
    const URL = `${API.place}${action.data.placeId}`;
    const response = yield call(getData, URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.PLACE.FETCH_PLACE_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: ACTIONS.PLACE.FETCH_PLACE_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.PLACE.FETCH_PLACE_FAIL });
  }
}

/**
 * @description saves data to DB
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* saveData(action) {
  const { PLACE_SAVE_SUCCESS, PLACE_SAVE_FAIL } = GENERIC;
  const { postData, putData } = window.axios;
  const data = { ...action.data };

  data.neighborhood = formatData(data.neighborhood);

  yield put({
    type: ACTIONS.PLACE.BUTTON_DISBALING,
    data: { isBtnDisabled: true },
  });
  try {
    let response;

    if (action.id) {
      // update
      response = yield call(putData, `${API.place}${action.id}`, data);
    } else {
      // create
      response = yield call(postData, API.place, data);
    }

    if (response.status === 200) {
      yield put({
        type: ACTIONS.PLACE.SAVE_DATA_SUCCESS,
        data: { ...response.data },
      });

      yield updatedData(response.data, action.id);

      if (data.should_refetch_3rd_parties_content) {
        const currTab =
          data.status === 'fetched' ||
          data.status === 'queue' ||
          data.status === 'draft'
            ? 'drafts'
            : data.status;

        action.successCB(response.data.id, currTab);
      } else {
        toast.success(PLACE_SAVE_SUCCESS);
        action.successCB();
      }
    } else if (
      // if duplicate FWD to that ID
      response.status === 400 &&
      response.data.error_code === 'DUPLICATED_FOURSQUARE_ID'
    ) {
      toast.success('Card ID already exists, redirecting');

      const currTab = data.status === 'draft' ? 'drafts' : data.status;

      const duplicateId = response.data.message.split(' ').pop();

      action.successCB(duplicateId, currTab);
    } else {
      reportAPIError('Place Save - HTTP', response.data);

      yield put({ type: ACTIONS.PLACE.SAVE_DATA_FAIL });

      toast.error(PLACE_SAVE_FAIL);
    }
  } catch (error) {
    reportAPIError('Overall Place Save Catch', error);

    yield put({ type: ACTIONS.PLACE.SAVE_DATA_FAIL });
    toast.error(PLACE_SAVE_FAIL);
  } finally {
    yield put({
      type: ACTIONS.PLACE.BUTTON_DISBALING,
      data: { isBtnDisabled: false },
    });
  }
}

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export { uploadImage, fetchPlace, saveData };
