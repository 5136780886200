/**
 * ************************************
 *
 * @module  ImageUtils.js
 * @author  Matt P
 * @date    05/12/2021
 * @description helper function for image files
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import Resizer from 'react-image-file-resizer';

import { toast } from 'react-toastify';

import currentEnv from 'utils/EnvironmentSpecificValues';

import { qualifyArrayRendering } from 'utils/utils';

import {
  MAX_IMAGE_HEIGHT,
  MAX_IMAGE_WIDTH,
  METADATA_CONCEPTS_TOTAL,
} from 'constants.js';

// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|
/**
 * @description converts image to base64 for clarifai API
 *
 * @param {Object} file
 *
 * @returns {Promise} containing base64Encoding
 */
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');

      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }

      resolve(encoded);
    };

    reader.onerror = (error) => reject(error);
  });

/**
 * @description obtains image metadata
 *
 * @param {Object} file
 *
 * @returns {Object} of concept image recognition metadata
 */
const imageMetadata = async (file) => {
  const to64 = await toBase64(file);

  return fetch(
    'https://api.clarifai.com/v2/models/aaa03c23b3724a16a56b629203edc62c/outputs',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Key ${currentEnv.api.clarifai}`,
      },
      body: JSON.stringify({
        inputs: [
          {
            data: {
              image: {
                base64: to64,
              },
            },
          },
        ],
      }),
    }
  )
    .then((response) => response.json())
    .then((response) => {
      if (!qualifyArrayRendering(response.outputs)) {
        throw new Error('No AI model output');
      }
      const { data, status } = response.outputs[0];
      const { concepts } = data;

      if (status.code !== 10000) {
        throw new Error(status.description);
      }

      if (concepts && concepts.length > 0) {
        let metadataString = '';
        const iterations =
          concepts.length < METADATA_CONCEPTS_TOTAL
            ? concepts.length
            : METADATA_CONCEPTS_TOTAL;

        for (let i = 0; i < iterations; i += 1) {
          metadataString += `${concepts[i].name}, `;
        }

        return metadataString.slice(0, -2);
      }

      return '';
    })
    .catch((e) => {
      toast.error(`${e} - Unable to fetch metadata`);
    });
};

/**
 * @description checks image width
 *
 * @param {Object} file
 *
 * @returns {Number} image width
 */
const checkImageWidth = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const image = new Image();

      image.src = event.target.result;
      image.onload = () => {
        resolve(image.width);

        return image.width;
      };

      reader.onerror = (err) => reject(err);
    };
  });

/**
 * @description resizes images via react-image-file-resizer
 * package.
 *
 * @param {Object} file
 *
 * @returns {Promise => Object} containing resized image data
 */
const resizeImage = (file) => {
  const quality = 100;

  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, // file of the image which will resized.
      MAX_IMAGE_WIDTH, // maxWidth of the resized new image.
      MAX_IMAGE_HEIGHT, // maxHeight of the resized new image.
      'JPEG', // compressFormat of the resized new image.
      quality, // quality of the resized new image.
      0, // degree of clockwise rotation to apply.
      (uri) => {
        // callback function of the resized new image URI.
        resolve(uri);
      },
      'file' // output type of the resized new image.
    );
  });
};

/**
 * @description checks if width is too large, if so will resize based on
 * the resizer specs above, else just pass it through
 *
 * @param {Object} file
 *
 * @returns {Object} containing resized image data
 */
const handleUpload = async (file) => {
  const width = await checkImageWidth(file);

  if (width <= MAX_IMAGE_WIDTH) return file;

  return resizeImage(file);
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export { handleUpload, imageMetadata };
