/**
 * ************************************
 *
 * @module  ThirdPartySearch.js
 * @author  Matt P
 * @date    06/23/2021
 * @description Search input against third parties with AJAX requests;
 * When focused, shows dropdown of that search's HTTP results
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  InfiniteScroller,
  LoadingFetchedCard,
  NativeClickListener,
  SearchBar,
} from 'components';

import { constructQueryParams } from 'utils/utils';

import {
  chooseApi,
  searchDropdownByService,
  handleResponseDataByServiceName,
} from '../ThirdPartySearchModalUtils';

import './ThirdPartySearch.scss';

// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|
let shouldFetchMore = true;
let thirdPartySearchPage = 1;

// ----------------------------------------------------------------------------|
//              React Function Component - ThirdPartySearch
// ----------------------------------------------------------------------------|
const ThirdPartySearch = ({
  value,
  label,
  serviceName,
  disabled,
  onOptionClick,
  searchDependencies,
  verticalType,
  onClear,
  placeholder,
}) => {
  /**
   * @description - state that keeps track of users prior search query
   */
  const [priorQuery, setPriorQuery] = useState('');

  /**
   * @description - state that keeps track the users third party selection
   */
  const [selectedOption, setSelectedOption] = useState(value || null);

  /**
   * @description - state for fetched content and HTTP status's
   */
  const [fetchedOptions, setFetchedOptions] = useState([]);
  const [fetchedOptionsError, setFetchedOptionsError] = useState('');
  const [fetchedOptionsIsLoading, setFetchedOptionsIsLoading] = useState(false);

  const [focused, setFocused] = useState(false);

  /**
   * @description - since some searches require dependencies
   * such as a Google ID or lat, lng. This useEffect will clear
   * other input's state if the user decides to clear the value that
   * satisfied the dependencies
   */
  useEffect(() => {
    if (value === null) {
      setSelectedOption(null);
    }
  }, [value]);

  /**
   * @description - http search for third party options
   *
   * @param {String} stringQuery -search string query
   * @param {Object[]} priorData - current data set for pagination
   *
   * @returns {void}
   */
  const fetchThirdPartyOptions = useCallback(
    (stringQuery, priorData) => {
      const { searchData } = window.axios;

      setFetchedOptionsError('');

      let queryParams;

      if (verticalType === 'place' || verticalType === 'movie') {
        queryParams = `${chooseApi[verticalType].search}?${constructQueryParams(
          {
            source: serviceName, // service to query
            query: stringQuery,
            page: thirdPartySearchPage,
            page_size: 10,
            // optional dependencies
            ...(searchDependencies
              ? {
                  ...searchDependencies,
                }
              : {}),
          }
        )}`;
      } else if (verticalType === 'city' || verticalType === 'neighborhood') {
        queryParams = `${chooseApi[verticalType].search}?${constructQueryParams(
          {
            source: serviceName,
            name: stringQuery,
            location_type: verticalType,
            // optional dependencies
            ...(searchDependencies
              ? {
                  ...searchDependencies,
                }
              : {}),
          }
        )}`;
      }

      searchData('get', queryParams)
        .then((response) => {
          const { data } = response;

          if (thirdPartySearchPage >= 5 || data.results.length < 10) {
            // limit it to 50 results
            shouldFetchMore = false;
          }

          thirdPartySearchPage += 1;

          if ([...priorData, ...data.results].length) {
            setFetchedOptionsError('');
            setFetchedOptions(
              handleResponseDataByServiceName(
                serviceName,
                priorData,
                data.results
              )
            );
          } else {
            shouldFetchMore = false;

            setFetchedOptionsError('No Results');
            setFetchedOptions([]);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setFetchedOptionsIsLoading(false);
        });
    },
    [serviceName, searchDependencies, verticalType]
  );

  /**
   * @description - resets search state
   *
   * @returns {void}
   */
  const resetSearchState = () => {
    thirdPartySearchPage = 1;
    shouldFetchMore = true;
    setFetchedOptions([]); // reset prior searches
  };

  /**
   * @description - fires on search input
   *
   * @param {String} inputQuery
   *
   * @returns {void}
   */
  const onSearchInput = (inputQuery) => {
    if (inputQuery === '') {
      resetSearchState();
      setSelectedOption(null);
      setFetchedOptionsError('');
    } else if (inputQuery !== priorQuery) {
      resetSearchState();
      setFetchedOptionsIsLoading(true);

      fetchThirdPartyOptions(inputQuery, []); // fetch new batch
      setPriorQuery(inputQuery); // keep track of prior search
    }
  };

  /**
   * @description - fires upon an option selection
   *
   * @param {String} source - which search source
   * @param {String} id - source id
   * @param {String} name - source name
   * @param {Object} addtlFields - some search types/options
   * require additional data, this is an object spread to handle that
   *
   * @returns {void}
   */
  const onOptionSelection = (source, id, name, addtlFields) => {
    onOptionClick(source, id, { ...addtlFields });
    resetSearchState();
    setSelectedOption(name);
  };

  /**
   * @description - manages focus of a specific
   * input as a fucus triggers the dropdown show
   *
   * @returns {void}
   */
  const onFocus = () => setFocused(true);

  /**
   * @description - manages blur taking away focus of a specific
   * input as a fucus triggers the dropdown show
   *
   * @returns {void}
   */
  const onBlur = () => setFocused(false);

  /**
   * @description - triggers on search query clear
   *
   * @returns {void}
   */
  const clearSearch = () => {
    setPriorQuery('');
    setSelectedOption('');
    onClear(serviceName);
  };

  /**
   * @description - toggles display none for dropdown that is not focused
   *
   * @returns {Object}
   */
  const chooseDropdownStyling = (isFocused) => {
    if (isFocused) {
      return {};
    }

    return {
      display: 'none',
    };
  };

  return (
    <div className="third-party-search-input-container">
      <label id={`${label}-third-party-search`}>{label}</label>
      <NativeClickListener onClick={onBlur}>
        <SearchBar
          name={`${serviceName}SearchInput`}
          value={selectedOption || priorQuery}
          onSubmit={onSearchInput}
          onClear={clearSearch}
          placeholder={placeholder || `Search ${label}`}
          // disable if there is a selected option
          // prompts user to clear if they need to redo
          disabled={disabled || selectedOption}
          onFocus={onFocus}
          style={{
            width: '100%',
          }}
          selectedValue={selectedOption}
        />
        {focused && fetchedOptions.length ? (
          <div
            className="third-party-search-options-dropdown"
            style={chooseDropdownStyling(focused)}
          >
            <InfiniteScroller
              hasMore={!fetchedOptionsIsLoading && shouldFetchMore}
              loadMore={() => {
                setFetchedOptionsIsLoading(true);

                fetchThirdPartyOptions(priorQuery, fetchedOptions);
              }}
              useWindow={false}
              threshold={10}
            >
              {fetchedOptions.map((option) =>
                searchDropdownByService[serviceName](
                  option,
                  serviceName,
                  onOptionSelection,
                  // optional dependencies
                  searchDependencies ? { ...searchDependencies } : {}
                )
              )}
              {fetchedOptionsIsLoading ? (
                <LoadingFetchedCard className="small" />
              ) : (
                ''
              )}
            </InfiniteScroller>
          </div>
        ) : (
          ''
        )}
        {focused && fetchedOptionsIsLoading && !fetchedOptions.length ? (
          <div
            className="third-party-search-options-dropdown"
            style={chooseDropdownStyling(focused)}
          >
            <LoadingFetchedCard className="small" />
          </div>
        ) : (
          ''
        )}
      </NativeClickListener>
      <div className="error">{fetchedOptionsError}</div>
    </div>
  );
};

// --------------------------------------------------------------------------|
//                             PropTypes Check
// --------------------------------------------------------------------------|
ThirdPartySearch.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onClear: PropTypes.func,
  verticalType: PropTypes.string.isRequired,
  searchDependencies: PropTypes.shape({}),
  placeholder: PropTypes.string,
};

// --------------------------------------------------------------------------|
//                              Default Props
// --------------------------------------------------------------------------|
ThirdPartySearch.defaultProps = {
  value: null,
  disabled: false,
  onClear: () => {},
  placeholder: '',
};

// ----------------------------------------------------------------------------|
//                        Export with Redux Connect
// ----------------------------------------------------------------------------|
export default ThirdPartySearch;
