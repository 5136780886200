/**
 * ************************************
 *
 * @module  EventDateSelector.constants.js
 * @author  Matt P
 * @date    02/20/2021
 * @description useful constants for the EventDateSelector component
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                  Imports
// ---------------------------------------------------------------------------|

export const EVENT_DATE_SELECTOR_POPUP_STEPS = {
  SELECT_DATE: 0, // 0: Date picker
  SELECT_RECURRENCE: 1, // 1: Recurrence selector (ex: Daily, Weekly, Monthly)
};

export const WEEKDAYS = [
  { name: 'Sunday' },
  { name: 'Monday' },
  { name: 'Tuesday' },
  { name: 'Wednesday' },
  { name: 'Thursday' },
  { name: 'Friday' },
  { name: 'Saturday' },
];

export const weekdays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const REPEAT_MODE = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
};

export const REPEAT_MODE_OPTIONS = {
  DAILY: {
    label: REPEAT_MODE.DAILY,
    value: REPEAT_MODE.DAILY,
  },
  WEEKLY: {
    label: REPEAT_MODE.WEEKLY,
    value: REPEAT_MODE.WEEKLY,
  },
  MONTHLY: {
    label: REPEAT_MODE.MONTHLY,
    value: REPEAT_MODE.MONTHLY,
  },
};

export const DATE_SELECTOR_OPTIONS = {
  DATES: {
    label: 'Select date(s)',
    value: 'selectDates',
  },
  DATE_RANGE: {
    label: 'Date Range',
    value: 'dateRange',
  },
  RECURRING: {
    label: 'Recurring',
    value: 'recurring',
  },
};

export const AVAILABILITY_OPTIONS = {
  MONTHLY_WEEKDAY: {
    label: 'Monthly on %s',
    value: 'MONTHLY_WEEKDAY',
  },
  MONTHLY_ON_DATE: {
    label: 'Monthly on day %s',
    value: 'MONTHLY_ON_DATE',
  },
};
