/**
 * ************************************
 *
 * @module  ActivityData.constants.js
 * @author  Matt P
 * @date    02/01/2021
 * @description Container for editing an 'Activity' Currently renders within
 * the CardViewModal is shown renders when a card is clicked in the
 * ActivitiesList component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import { getPriceRange } from 'utils/utils';
import { ONE_LINER_DESC_ROWS } from 'constants.js';

import { DetailedDescription, ReviewStatus } from 'components';

// ----------------------------------------------------------------------------|
//                        ActivityData - Array of Objects
// ----------------------------------------------------------------------------|
const ActivityData = [
  {
    type: 'text',
    name: 'name',
    label: 'Name of Recreation',
    value: '',
    errorMsg: 'Name should be min 1 and max 100 characters long',
  },
  {
    type: 'tag',
    label: 'Category',
    name: 'categories',
    tags: [],
    suggestions: [],
    errorMsg: 'Type cannot be empty',
    showDropdown: true,
  },
  {
    type: 'dropdown',
    label: 'Price Range',
    name: 'price_tier',
    values: getPriceRange(),
    value: '',
    placeholder: 'Select Price Range',
    errorMsg: 'Price Range cannot be empty',
  },
  {
    type: 'textarea',
    name: 'phrase',
    label: 'One-liner Description',
    value: '',
    errorMsg: '',
    rows: ONE_LINER_DESC_ROWS,
  },
  {
    type: 'component',
    name: 'itemized_description',
    label: '',
    // eslint-disable-next-line react/prop-types
    component: ({ onChange, value, errors }) => (
      <div className="form-field-wrapper">
        <DetailedDescription
          name="itemized_description"
          onChange={onChange}
          value={value}
          errors={errors}
        />
      </div>
    ),
  },
  {
    type: 'text',
    name: 'links_to_activity', // using only one index of array
    label: 'Website Link',
    value: '',
    errorMsg: 'Invalid link',
  },
  {
    type: 'component',
    name: 'last_admin_review',
    label: '',
    component: ({ value, history, onChange, reviewDate, errors }) => (
      <div className="form-field-wrapper">
        <ReviewStatus
          value={value}
          reviewDate={reviewDate}
          history={history}
          errors={errors}
          onChange={onChange}
        />
      </div>
    ),
  },
];

// ----------------------------------------------------------------------------|
//                               PropTypes Check
// ----------------------------------------------------------------------------|
ActivityData.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onPlaceSelected: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  errors: PropTypes.string.isRequired,
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default ActivityData;
