/**
 * ************************************
 *
 * @module  Events.reducer.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Events reducer file
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import { toast } from 'react-toastify';
import ACTIONS from 'store/actions/actionTypes';

import { GENERIC } from 'constants.js';

// ----------------------------------------------------------------------------|
//                               Initial State
// ----------------------------------------------------------------------------|
const initialData = {
  data: [],
  count: 0,
  isInitialDataLoaded: false,
  pageNum: 1,
  bulkActionSuccess: false,
};

// ----------------------------------------------------------------------------|
//                              Events Reducer
// ----------------------------------------------------------------------------|
const Events = (state = initialData, action = {}) => {
  const {
    DELETE_SUCCESS_MESSAGE,
    BULK_ACTION_SUCCESS_MESSAGE,
    DELETE_FAILURE_MESSAGE,
    BULK_ACTION_FAILURE_MESSAGE,
  } = GENERIC;

  switch (action.type) {
    case ACTIONS.EVENTS.GET_LIST_SUCCESS: {
      const { places: data, count } = action.data;
      const updatedData = [...state.data, ...data];
      const updatedPageNum = state.pageNum + 1;

      return {
        ...state,
        data: updatedData.map((eventData) => ({
          ...eventData,
          // event_hours: mock,
        })),
        count,
        isInitialDataLoaded: true,
        pageNum: updatedPageNum,
      };
    }

    case ACTIONS.EVENTS.GET_LIST_FAIL: {
      return {
        ...state,
        isInitialDataLoaded: true,
      };
    }

    case ACTIONS.EVENTS.CLEAR_LIST: {
      return {
        ...state,
        data: [],
        count: 0,
        isInitialDataLoaded: false,
        pageNum: 1,
      };
    }

    case ACTIONS.EVENTS.BULK_ACTION_SUCCESS: {
      const updatedEventList = state.data.filter(
        (event) => action.data.id_list.indexOf(event.id) < 0
      );
      const updatedCount = state.count - action.data.id_list.length;
      const message = action.data.delete
        ? DELETE_SUCCESS_MESSAGE
        : BULK_ACTION_SUCCESS_MESSAGE;

      toast.success(message);

      return {
        ...state,
        data: updatedEventList,
        count: updatedCount,
        bulkActionSuccess: true,
      };
    }

    case ACTIONS.EVENTS.BULK_ACTION_FAIL: {
      const message = action.data.delete
        ? DELETE_FAILURE_MESSAGE
        : BULK_ACTION_FAILURE_MESSAGE;

      toast.error(message);

      return { ...state, bulkActionSuccess: false };
    }

    case ACTIONS.EVENTS.DELETE_EVENT_SUCCESS: {
      const updatedData = state.data.filter((el) => el.id !== action.data);
      const updatedCount = state.count - 1;

      return { ...state, data: updatedData, count: updatedCount };
    }

    case ACTIONS.EVENTS.DELETE_EVENT_FAIL: {
      return state;
    }

    case ACTIONS.EVENTS.MOVE_TO_TOP: {
      const { id, data: modifiedData } = action.data;
      const dataWithID = state.data.find((el) => el.id === id);
      const updatedDataWithoutID = state.data.filter((el) => el.id !== id);
      const updatedData = [
        { ...dataWithID, ...modifiedData },
        ...updatedDataWithoutID,
      ];

      return { ...state, data: [...updatedData] };
    }

    case ACTIONS.EVENTS.REMOVE_EVENT: {
      const { id } = action.data;
      const updatedData = state.data.filter((el) => el.id !== id);
      const updatedCount = state.count - 1;

      return { ...state, data: [...updatedData], count: updatedCount };
    }

    default: {
      return state;
    }
  }
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default Events;
