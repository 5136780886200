/**
 * ************************************
 *
 * @module  CobReactDateRangePicker.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Date range dropdown component.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import { isDocumentElement } from 'utils/utils';

import DropdownButton from './DropdownButton/DropdownButton';
import DropdownPanel from './DropdownPanel/DropdownPanel';

import 'react-daterange-picker/dist/css/react-calendar.css';
import './CobReactDateRangePicker.scss';

// ----------------------------------------------------------------------------|
//                         React Class Component
// ----------------------------------------------------------------------------|
class CobReactDateRangePicker extends Component {
  parentDiv = React.createRef();

  state = {
    isOpen: false,
  };

  // --------------------------------------------------------------------------|
  //                Class Component - Lifecycle Methods
  // --------------------------------------------------------------------------|
  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleOutsideClick);
  };

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  };

  // --------------------------------------------------------------------------|
  //                    Class Component - Methods
  // --------------------------------------------------------------------------|
  /**
   * @description Selects item from dropdown calender menu
   */
  onSelect = (item) => {
    this.props.onSelect(item);
    this.toggleHandler();
  };

  /**
   * @description When user clicks outside other then component.
   * Then drop down will close
   *
   * @param {Object} event - event object targeting the element clicked
   */
  handleOutsideClick = (event) => {
    // Do not close the dropdown if user tries click on scroll bar/buttons.
    if (isDocumentElement(event.target)) {
      return;
    }

    if (!this.parentDiv.current.contains(event.target)) {
      // Click is outside. So close the dropdown menu
      this.setState({ isOpen: false });
    }
  };

  /**
   * @description Closes menu and resets dropdown
   */
  resetDropdown = () => {
    this.props.onSelect(undefined);
    this.setState({ isOpen: false }, () => {
      if (this.props.onMenuClose) {
        this.props.onMenuClose();
      }
    });
  };

  /**
   * @description Toggles menu
   */
  toggleHandler = () => {
    this.setState(
      (prevState) => ({ isOpen: !prevState.isOpen }),
      () => {
        if (!this.state.isOpen && this.props.onMenuClose) {
          this.props.onMenuClose();
        }
      }
    );
  };

  render() {
    const { isOpen } = this.state;
    const {
      selectedDateRange,
      className,
      placeholder,
      isClearable,
    } = this.props;
    const datepickerCss = cx('datepicker-drop-down', className, {
      'is-open': isOpen,
    });

    return (
      <div className={datepickerCss} ref={this.parentDiv}>
        <div className="dropdown-menu">
          <DropdownButton
            isClearable={isClearable}
            selectedDateRange={selectedDateRange}
            toggleHandler={this.toggleHandler}
            placeholder={placeholder}
            onDropdownCleared={this.resetDropdown}
          />
        </div>
        {isOpen && (
          <DropdownPanel
            selectedDateRange={selectedDateRange}
            onSelect={this.onSelect}
          />
        )}
      </div>
    );
  }
}

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
CobReactDateRangePicker.propTypes = {
  selectedDateRange: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onMenuClose: PropTypes.func,
  isClearable: PropTypes.bool,
};

CobReactDateRangePicker.defaultProps = {
  selectedDateRange: undefined,
  className: '',
  placeholder: 'Date Range',
  onMenuClose: null,
  isClearable: false,
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default CobReactDateRangePicker;
