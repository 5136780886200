/**
 * ************************************
 *
 * @module Recipes.js
 * @author  Matt P
 * @date    02/01/2021
 * @description Recipes container
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

// import moment from 'moment';

import { connect } from 'react-redux';
import {
  getRecipesListAction,
  deleteRecipeAction,
  clearRecipesAction,
  performBulkOperationAction,
  selectRecipeAction,
  filterRecipesListAction,
} from 'store/actions/~depreciated/Recipes.action';

import {
  fetchAdminCategoriesAction,
  fetchNeighborhoodLocationsAction,
} from 'store/actions/Places.action';

import { Recipe } from 'containers';

import { RECIPE, GENERIC } from 'constants.js';

import {
  CardViewModal,
  DeleteModal,
  FilterSection,
  NoDataFound,
  Loader,
  Tabs,
  TotalCount,
  WidgetBox,
  CardBanner,
} from 'components';

import {
  getPartsFromUrl,
  getSelectedCardCount,
  getCardReviewStatus,
  checkHasMore,
  askForCloseConfirmation,
  getCardPrettyIds,
} from 'utils/utils';

import {
  formRatingOptions,
  FilterType,
  formGroupOptions,
  orderByOptions,
} from 'components/FilterSection/FiltersFactory';

import CommonService from 'services/CommonService';
import RecipesList from './RecipesList/RecipesList';

import './Recipes.scss';

// ----------------------------------------------------------------------------|
//                            Property Mapping
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  getRecipesList: (data) => dispatch(getRecipesListAction(data)),
  deleteRecipe: (data) => dispatch(deleteRecipeAction(data)),
  performBulkOperation: (data) => dispatch(performBulkOperationAction(data)),
  selectRecipe: (data) => dispatch(selectRecipeAction(data)),
  clearRecipesList: () => dispatch(clearRecipesAction()),
  fetchAdminCategories: () => dispatch(fetchAdminCategoriesAction()),
  fetchNeighborhoodLocations: () =>
    dispatch(fetchNeighborhoodLocationsAction()),
  filterRecipesAction: (data) => dispatch(filterRecipesListAction(data)),
});

const mapStateToProps = (state) => ({
  recipes: state.recipes,
  recipeCategories: state.places.recipeCategories,
  adminCategories: state.places.adminCategories,
  neighborhoodLocations: state.places.neighborhoodLocations,
});

// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|
const { WARNING_ON_DELETE } = RECIPE;

const commonService = CommonService();

// ----------------------------------------------------------------------------|
//                      Recipes React Pure Component
// ----------------------------------------------------------------------------|
class Recipes extends PureComponent {
  // --------------------------------------------------------------------------|
  //                          PropTypes Check
  // --------------------------------------------------------------------------|
  static propTypes = {
    status: PropTypes.string,
    hideExtras: PropTypes.bool,
    getRecipesList: PropTypes.func.isRequired,
    performBulkOperation: PropTypes.func.isRequired,
    selectRecipe: PropTypes.func.isRequired,
    dismissModal: PropTypes.func,
    deleteRecipe: PropTypes.func.isRequired,
    clearRecipesList: PropTypes.func.isRequired,
    fetchAdminCategories: PropTypes.func.isRequired,
    filterRecipesAction: PropTypes.func.isRequired,
    recipes: PropTypes.shape({
      // adminCategories: PropTypes.arrayOf(PropTypes.object),
      bulkActionSuccess: PropTypes.bool,
      cardCategories: PropTypes.arrayOf(PropTypes.object),
      isInitialDataLoaded: PropTypes.bool,
      recipes: PropTypes.arrayOf(PropTypes.object),
      pageNum: PropTypes.number,
      searchedRecipes: PropTypes.arrayOf(PropTypes.object),
      selectedRecipes: PropTypes.arrayOf(PropTypes.object),
      totalCount: PropTypes.number,
    }).isRequired,
    adminCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
    location: PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      action: PropTypes.string,
      block: PropTypes.func,
      createHref: PropTypes.func,
      go: PropTypes.func,
      goBack: PropTypes.func,
      goForward: PropTypes.func,
      length: PropTypes.number,
      listen: PropTypes.func,
      location: PropTypes.shape({
        hash: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
      }),
      push: PropTypes.func,
      replace: PropTypes.func,
    }).isRequired,
  };

  // --------------------------------------------------------------------------|
  //                                Default Props
  // --------------------------------------------------------------------------|
  static defaultProps = {
    status: '',
    hideExtras: false,
    dismissModal: () => {},
  };

  // --------------------------------------------------------------------------|
  //                             State - Recipes
  // --------------------------------------------------------------------------|
  constructor() {
    super();

    this.state = {
      currentTab: 'published',
      hasMore: true,
      newRecipeData: null,
      deleteModalData: null,
      bulkDeleteModal: false,
      category: [],
      rating: [],
      orderBy: '',
      reviewStatus: '',
      // neighborhood: [],
      // price: [],
      // dateRange: undefined,
      query: '',
      selectedCards: [],
      isChecked: null,
      openRecipeID: '',
    };
  }

  // --------------------------------------------------------------------------|
  //                            LifeCycle Methods
  // --------------------------------------------------------------------------|
  componentDidMount() {
    this.setCurrentTab();

    const {
      recipes,
      fetchAdminCategories,
      fetchNeighborhoodLocations,
      neighborhoodLocations,
      history,
      adminCategories,
    } = this.props;
    // Fetch adminCategories, neighborhoodLocations only if they are not present
    if (!adminCategories.length) {
      fetchAdminCategories();
    }

    if (!neighborhoodLocations.length) {
      fetchNeighborhoodLocations();
    }

    this.unlisten = history.listen((location, action) => {
      this.handleIDPathname();
    });
  }

  componentDidUpdate(prevProps) {
    const { location, recipes } = this.props;

    if (location.pathname !== prevProps.location.pathname) {
      this.setCurrentTab();

      if (
        location.pathname.includes('fetched') ||
        prevProps.location.pathname.includes('fetched')
      ) {
        this.resetCategoriesFilter();
      }
    }

    if (
      recipes.bulkActionSuccess !== prevProps.recipes.bulkActionSuccess &&
      recipes.bulkActionSuccess
    ) {
      this.dismissWidget();
    }

    if (recipes.recipes.length !== prevProps.recipes.recipes.length) {
      this.setHasMore();
    }

    if (recipes.isInitialDataLoaded) {
      this.handleIDPathname();
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  // --------------------------------------------------------------------------|
  //                        Class Component Methods
  // --------------------------------------------------------------------------|
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  //                   DB Call Methods
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  /**
   * @description Handle presence of DB id in URL: redirect user to relevant
   * content
   */
  handleIDPathname = () => {
    const { location, recipes } = this.props;
    const { openRecipeID } = this.state;
    const { id } = commonService.pathnameHelper(location.pathname);

    if (id) {
      if (openRecipeID !== id) {
        if (openRecipeID) {
          this.setState({ newRecipeData: null, openRecipeID: '' });
        } else {
          this.setState({ openRecipeID: id });

          let recipeData;

          // searches for already fetched cards
          for (let i = 0; i < recipes.recipes.length; i += 1) {
            if (recipes.recipes[i].id === id) {
              recipeData = recipes.recipes[i];
              break;
            }
          }

          // if it finds it, load it
          if (recipeData) {
            this.setState({ newRecipeData: recipeData });
          } else {
            // if not, open a new modal and have it fetch the id
            this.setState({
              newRecipeData: {
                id,
                shouldFetchRecipe: true,
                shouldDisplayLoader: true,
              },
            });
          }
        }
      }
    } else if (openRecipeID) {
      this.setNewRecipeData(null);
    }
  };

  /**
   * @description sets state on if more cards are available
   * checkHasMore is imported from the common utils/utils file
   */
  setHasMore = () => {
    const { recipes } = this.props;
    const { totalCount } = recipes;
    const hasMore = checkHasMore(recipes.recipes.length, totalCount);

    this.setState({ hasMore });
  };

  /**
   * @description constructs a request object which is sent to Recipes.saga
   * and ultimately requests a response based on state
   *
   * @returns {Object} - request object sent to Recipes.saga
   */
  constructRequestObject = () => {
    const {
      recipes,
      // status
    } = this.props;
    const {
      currentTab,
      category,
      query,
      rating,
      orderBy,
      reviewStatus,
    } = this.state;
    const { pageNum: page } = recipes;

    // Rating doubled for temporary issue with mapping of 1-5 => 1-10
    // Need to be changed later with proper cotract from BE.
    const ratings = rating.map(({ value: rating }) =>
      rating === '1' ? rating : rating * 2
    );

    const status = currentTab === 'drafts' ? 'draft' : currentTab;

    return {
      status,
      page,
      title: query,
      cat_unique_slug_list: category.map(({ value }) => value),
      rating: ratings,
      order_by_parameter: orderBy,
      review_status: reviewStatus,
      loaded_ids: getCardPrettyIds(recipes.recipes),
    };
  };

  /**
   * @description constructs a request object and invokes getRecipesList
   * (passed from redux) which is sent to Recipes.saga and ultimately
   * requests a response based on state
   */
  getRecipesData = () => {
    const { getRecipesList } = this.props;
    const request = this.constructRequestObject();

    getRecipesList(request);
  };

  /**
   * @description sets the current horizontal tab by invoking the
   * getPartsFromUrl function imported from utils/utils based on
   * the second part of the current route.
   */
  setCurrentTab = () => {
    const { location, clearRecipesList } = this.props;
    // Active tab in horizontal nav is set based on the second part
    // of the current route
    const { secondPart: currentTab } = getPartsFromUrl(location.pathname);

    clearRecipesList();
    this.dismissWidget();

    this.setState(
      {
        currentTab,
        newRecipeData: null,
        openRecipeID: '',
      },
      this.getRecipesData
    );
  };

  /**
   * @description requests more data after setting states hasMore prop to false.
   */
  getMoreData = () => this.setState({ hasMore: false }, this.getRecipesData);

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  //                  Filter Methods
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  /**
   * @description Fires when filter options are selected
   *
   * @param {String} returnType - string for data type
   * @param {Function} callback - to fire after state update
   */
  onFilterSelect = (returnType, data, callback = () => {}) => {
    const { clearRecipesList } = this.props;
    let selectedData = data;
    // prevents a bug with null being passed when deleting filters
    if (
      (returnType === 'category' || returnType === 'rating') &&
      // returnType === 'neighborhood' ||
      // returnType === 'price' ||
      // returnType === 'orderBy'
      selectedData === null
    )
      selectedData = [];

    if (returnType === 'orderBy' && data) selectedData = data.value;

    if (returnType === 'reviewStatus') {
      if (data.value) selectedData = data.value;
      else selectedData = '';
    }

    clearRecipesList();

    // API call to filter cards based on value selected from dropdown
    this.setState({ [returnType]: selectedData }, callback);
  };

  /**
   * @description Fires when filter menu closes.
   * constructs a request object then invokes the redux action
   * filterRecipes.
   */
  onFilterMenuClosed = () => {
    const { filterRecipesAction } = this.props;

    const request = this.constructRequestObject();

    filterRecipesAction(request);
  };

  /**
   * @description clears away filters by invoking the redux
   * clearRecipesList function, resetting the states 'filtering'
   * properties, then requests a fresh GET request from the
   * backend via getRecipesData
   */
  clearFilters = () => {
    const { clearRecipesList } = this.props;

    clearRecipesList();

    this.setState(
      { category: [], rating: [], orderBy: '', reviewStatus: '' },
      this.getRecipesData
    );
  };

  /**
   * @description just clears the category and ratings state properties
   */
  resetCategoriesFilter = () => this.setState({ category: [], rating: [] });

  /**
   * @description sets state and fires a GET request for a search bar
   * quest (aka: input). First clears the list, dismisses the widget,
   * then setsState with the query string before firing a getRecipesData req
   *
   * @param {String} query - string for the query search
   */
  onSearchInput = (query) => {
    const { clearRecipesList } = this.props;
    // API call to filter cards based on query
    clearRecipesList();
    this.dismissWidget();

    this.setState({ query }, this.getRecipesData);
  };

  /**
   * @description fires when the modal window for editing a recipe
   * is closed. Used for pushing the correct history to Routers
   * history object, dismissing the widget then setting the tab.
   */
  onCloseModal = () => {
    const { location, history } = this.props;
    const { section, tab, id } = commonService.pathnameHelper(
      location.pathname
    );

    if (id) {
      history.push(`/${section}/${tab}`);
    }

    this.dismissWidget();
    this.setCurrentTab();
  };

  /**
   * @description sets the state with a new recipe data object for editing,
   * Fires on clicking of a card or the add new button
   *
   * @param {Object} newRecipeData - Object of new movie data to
   * be set to the state object
   */
  setNewRecipeData = (newRecipeData) => {
    const { location, history } = this.props;
    const { section, tab, id } = commonService.pathnameHelper(
      location.pathname
    );

    // prevents infinite loop with id/endpoint rendering
    if (id) {
      history.push(`/${section}/${tab}`);
    }

    this.setState({ newRecipeData, openRecipeID: '' }, this.dismissWidget);
  };

  /**
   * @description fires when you click a card, type of action depends on the
   * string value passed.
   *
   * @param {String} value - String specifying the action (Edit/Delete/Etc..)
   * @param {Object} data - Object of recipe data
   */
  onMenuClicked = (value, data) => {
    const { location, history } = this.props;
    const {
      SUB_MENU: { EDIT, DELETE },
    } = GENERIC;

    const { tab } = commonService.pathnameHelper(location.pathname);
    const { id } = data;

    if (value === EDIT) {
      history.push(`${tab}/${id}`);
    }

    if (value === DELETE) {
      this.setState({ deleteModalData: id });
    }
  };

  /**
   * @description fires when deleting a recipe from the list
   */
  deleteRecipeFromList = () => {
    const { deleteRecipe } = this.props;
    const { deleteModalData } = this.state;

    this.closeDeleteModalData();
    deleteRecipe(deleteModalData);
  };

  /**
   * @description resets deleteModalData property to null
   */
  closeDeleteModalData = () => this.setState({ deleteModalData: null });

  /**
   * @description sets bulkDeleteModal property to false
   */
  closeBulkDeleteModal = () => this.setState({ bulkDeleteModal: false });

  /**
   * @description triggers bulk delete from the List of cards
   */
  bulkDeleteFromList = () => {
    const {
      SUB_MENU: { DELETE },
    } = GENERIC;

    this.closeBulkDeleteModal();
    this.performBulkOperation(DELETE);
  };

  /**
   * @description toggles what is included in the selectedCards state property
   *
   * @param {Boolean} currentCheckValue - toggles if card should be included
   * @param {String} cardId- string with unique cardId
   */
  onCheckBoxSelected = (currentCheckValue, cardId) => {
    this.setState((prevState) => {
      let selectedCards = [...prevState.selectedCards];

      if (currentCheckValue) {
        selectedCards.push(cardId);
      } else {
        selectedCards = selectedCards.filter((id) => id !== cardId);
      }

      const tempState = { selectedCards };

      if (typeof prevState.isChecked === 'boolean') {
        tempState.isChecked = null;
      }

      return tempState;
    });
  };

  /**
   * @description deselects all cards by setting state property isChecked
   * to false
   */
  deselectAllCards = () => {
    this.setState({ isChecked: false });
  };

  /**
   * @description resets widget to initial state
   */
  dismissWidget = () => {
    this.setState({ selectedCards: [], isChecked: null });
  };

  /**
   * @description redux helper for correct action
   *
   * @argument {Object} actions - with action types
   */
  getRelevantActions = (actions) => {
    const { status } = this.props;
    const { currentTab } = this.state;

    if (status === 'published') {
      return [actions.SELECT];
    }

    if (currentTab) {
      return currentTab === 'published'
        ? [actions.DELETE, actions.DRAFT]
        : [actions.DELETE, actions.PUBLISH];
    }

    return [];
  };

  /**
   * @description performs a bulk operation depending on type
   * string passed
   *
   * @argument {String} type - type of bulk operation
   */
  performBulkOperation = (type) => {
    const { dismissModal, selectRecipe, performBulkOperation } = this.props;
    const { selectedCards } = this.state;
    const {
      BULK_ACTIONS: { DELETE, PUBLISH, DRAFT, SELECT },
    } = GENERIC;

    if (type === SELECT) {
      // This saves selected cards to redux
      dismissModal();
      selectRecipe(selectedCards);
    } else {
      const data = {
        delete: type === DELETE,
        id_list: selectedCards,
      };

      if (type === PUBLISH) {
        data.status = 'published';
      }

      if (type === DRAFT) {
        data.status = 'draft';
      }

      performBulkOperation(data);
    }
  };

  /**
   * @description gets filter for recipes
   */
  getFiltersForRecipes = () => {
    const { recipeCategories } = this.props;
    const { category, rating, orderBy, reviewStatus } = this.state;
    const {
      FILTER_DROP_DOWN: { RATING, TYPE, ORDER, REVIEW_STATUS },
    } = RECIPE;

    return [
      {
        name: TYPE.LABEL,
        returnType: TYPE.RETURN_TYPE,
        options: formGroupOptions(
          recipeCategories,
          'categories',
          'unique_slug',
          'name'
        ),
        value: category,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.onFilterMenuClosed,
        isSearchable: true,
      },
      {
        name: RATING.LABEL,
        returnType: RATING.RETURN_TYPE,
        options: formRatingOptions(),
        value: rating,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.onFilterMenuClosed,
      },
      {
        name: ORDER.LABEL,
        returnType: ORDER.RETURN_TYPE,
        options: orderByOptions,
        value: orderBy,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.SELECT,
        onMenuClose: this.onFilterMenuClosed,
        isSearchable: false,
      },
      {
        name: REVIEW_STATUS.LABEL,
        returnType: REVIEW_STATUS.RETURN_TYPE,
        options: getCardReviewStatus(),
        value: reviewStatus,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.SELECT,
        onMenuClose: this.onFilterMenuClosed,
        isSearchable: false,
      },
    ];
  };

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  //                Recipes rendering
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  render() {
    const {
      hideExtras,
      recipes,
      // adminCategories
    } = this.props;

    const {
      newRecipeData,
      currentTab,
      deleteModalData,
      selectedCards,
      isChecked,
      hasMore,
      bulkDeleteModal,
      query,
    } = this.state;

    // True when recipes container has been rendered into a modal
    // - used to hide filters, submenu etc
    const { totalCount, isInitialDataLoaded } = recipes;
    const { CARD_VIEW_TITLE, TAB_DATA, CARD_VIEW_TITLE_EDIT } = RECIPE;
    const {
      BULK_ACTIONS: { DELETE, PUBLISH, DRAFT, SELECT },
      CLOSE_CARD_WARNING_MESSAGE,
    } = GENERIC;

    const pageTitle =
      newRecipeData && newRecipeData.id
        ? CARD_VIEW_TITLE_EDIT
        : CARD_VIEW_TITLE;
    // const fromFetched = currentTab === 'fetched';

    // if (fromFetched && newRecipeData) {
    //   newRecipeData.categories = [];
    // }

    const filterSectionUI = (
      <FilterSection
        filters={this.getFiltersForRecipes()}
        searchValue={query}
        onSearchInput={this.onSearchInput}
        clearFilters={this.clearFilters}
      />
    );

    let recipeListContent = <Loader />;

    // Collection of all possible actions in bulk actions widget
    const widgetActions = {
      DELETE: {
        label: DELETE,
        onClick: () => {
          this.setState({ bulkDeleteModal: true });
        },
        className: 'btn-inverse',
      },
      PUBLISH: {
        label: PUBLISH,
        onClick: () => {
          this.performBulkOperation(PUBLISH);
        },
      },
      DRAFT: {
        label: DRAFT,
        onClick: () => {
          this.performBulkOperation(DRAFT);
        },
      },
      SELECT: {
        label: SELECT,
        onClick: () => {
          this.performBulkOperation(SELECT);
        },
      },
    };

    const selectionWidget = selectedCards.length > 0 && (
      <WidgetBox
        className="recipes-widget"
        data={this.getRelevantActions(widgetActions)}
        onDeselectClicked={this.deselectAllCards}
        actionText={getSelectedCardCount(selectedCards)}
        showStatus
      />
    );

    const showOrHideNavigation = !hideExtras && (
      <Fragment>
        <CardBanner
          title="Recipes"
          newBtnClick={() => this.setNewRecipeData({})}
        />
        <Tabs data={TAB_DATA} active={currentTab} />
      </Fragment>
    );

    if (isInitialDataLoaded) {
      if (totalCount) {
        const current = recipes.recipes.length;

        recipeListContent = (
          <Fragment>
            <TotalCount current={current} total={totalCount} />
            <RecipesList
              data={recipes.recipes}
              hasMore={hasMore}
              onMoreData={this.getMoreData}
              onViewData={this.setNewRecipeData}
              onMenuClicked={this.onMenuClicked}
              onCheckBoxSelected={this.onCheckBoxSelected}
              isChecked={isChecked}
              // fromFetched={fromFetched}
              hideMenu={hideExtras}
            />
          </Fragment>
        );
      } else {
        recipeListContent = <NoDataFound />;
      }
    }

    return (
      <div className="recipes-container">
        <div className="page-content">
          {newRecipeData !== null ? (
            <CardViewModal
              title={pageTitle}
              modalClosed={() => askForCloseConfirmation(this.onCloseModal)}
            >
              <Recipe
                data={newRecipeData}
                closeModal={() => this.onCloseModal()}
                currentTab={currentTab}
              />
            </CardViewModal>
          ) : (
            <Fragment>
              {showOrHideNavigation}
              {filterSectionUI}
              {recipeListContent}
              {deleteModalData !== null && (
                <DeleteModal
                  cancelClick={this.closeDeleteModalData}
                  deleteClick={this.deleteRecipeFromList}
                  additionalMessage={WARNING_ON_DELETE}
                />
              )}
              {bulkDeleteModal && (
                <DeleteModal
                  cancelClick={this.closeBulkDeleteModal}
                  deleteClick={this.bulkDeleteFromList}
                />
              )}
            </Fragment>
          )}
        </div>
        {selectionWidget}
      </div>
    );
  }
}

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Recipes)
);
