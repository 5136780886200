/**
 * ************************************
 *
 * @module  CheckboxRow.js
 * @author  Matt P
 * @date   05/27/2021
 * @description Row of checkboxes for the form components
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import { CobCheckbox } from 'components';

import { hasUniqueSlug } from 'utils/utils';

import './CheckboxRow.scss';

// ----------------------------------------------------------------------------|
//                    React Function Component - CobCheckbox
// ----------------------------------------------------------------------------|
const CheckboxRow = ({ name, options, selectedOptions, onChange }) => {
  /**
   * @description - maps over checkbox options passed to component,
   * rendering a row of selectable checkboxes
   *
   * @param {{
   *  uniqueSlug: String,
   *  displayText: String,
   *  type: String,
   * }[]} formCheckboxOptions - DOM event object
   *
   * @returns {JSX[]}
   */
  const renderCheckboxOptions = (formCheckboxOptions) =>
    formCheckboxOptions.map((option) => {
      const { uniqueSlug, displayText, type } = option;
      const toBeChecked = hasUniqueSlug(selectedOptions, uniqueSlug);

      return (
        <div className="checkbox-row__option" key={`${uniqueSlug}-${type}`}>
          <CobCheckbox
            label={uniqueSlug}
            displayText={displayText}
            onClick={(checked, label) => {
              onChange(checked, label, type);
            }}
            toBeChecked={toBeChecked}
          />
        </div>
      );
    });

  return (
    <div className="checkbox-row-container">
      {name && <h2 className="checkbox-row__header">{name}</h2>}
      <div className="checkbox-row__contents">
        {renderCheckboxOptions(options)}
      </div>
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                         PropTypes Check - MultiInput
// ----------------------------------------------------------------------------|
CheckboxRow.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      displayText: PropTypes.string,
      uniqueSlug: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  selectedOptions: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
};

// ----------------------------------------------------------------------------|
//                          Default Props - MultiInput
// ----------------------------------------------------------------------------|
CheckboxRow.defaultProps = {
  options: [],
  selectedOptions: [],
  onChange: () => {},
};

// ----------------------------------------------------------------------------|
//                            Export - MultiInput
// ----------------------------------------------------------------------------|
export default CheckboxRow;
