/**
 * ************************************
 *
 * @module  CobTableRow.js
 * @author  Matt P
 * @date    03/04/2021
 * @description Component for a table row
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Switch from 'react-switch';

import ReactTooltip from 'react-tooltip';

import { switchStyling } from 'containers/Categories/CategoriesUtils';
import {
  findTypeDropdownLabel,
  typeDropdownOptions,
} from 'containers/Filters/FiltersUtils';

import { CITIES } from 'constants.js';

import options from 'assets/images/options-2.svg';

import CobTableRowToken from './CobTableRowToken/CobTableRowToken';

import './CobTableRow.scss';

// ----------------------------------------------------------------------------|
//                   React Function Component - CobTableRow
// ----------------------------------------------------------------------------|
const CobTableRow = ({
  row,
  cols,
  rowClasses,
  tags,
  onToggleSwitch,
  onDeleteCategory,
  showEditModal,
  isDeleteAllowed,
  deleteDisabledTooltip,
  invokeParentFilter,
  filterLocationsDropdownOptions,
}) => {
  /**
   * @description - renders the three dot (...) ellipsis at the
   * far end of a table. Used for additional options
   *
   * @returns {JSX}
   */
  const renderOptionsIcon = () => {
    if (isDeleteAllowed) {
      return (
        <img
          src={options}
          alt="options icon"
          onClick={() => onDeleteCategory(row)}
          name="options"
          onMouseOver={() => {}}
          onMouseOut={() => {}}
          onFocus={() => {}}
          onBlur={() => {}}
        />
      );
    }

    return (
      <Fragment>
        {!isDeleteAllowed && deleteDisabledTooltip && (
          <ReactTooltip effect="solid" />
        )}
        <span className="delete-disabled" data-tip={deleteDisabledTooltip}>
          <img name="options" alt="options icon" src={options} />
        </span>
      </Fragment>
    );
  };

  /**
   * @description - renders tag icons
   *
   * @returns {JSX}
   */
  const renderTagIcons = () => (
    <CobTableRowToken tags={tags} onClick={invokeParentFilter} />
  );

  /**
   * @description - chooses which function to invoke
   * based on object Key
   *
   * @returns {void}
   */
  const actionDecider = {
    TAGS: renderTagIcons(),
    OPTIONS: renderOptionsIcon(),
    DEFAULT: () => {},
  };

  /**
   * @description - decides which action to take based on
   * decider above
   *
   * @param {String[]}
   *
   * @returns {JSX[]}
   */
  const decideAction = (values) =>
    values.map((action, index) => (
      <span className={`${action}-container`} key={index}>
        {actionDecider[action.toUpperCase()]
          ? actionDecider[action.toUpperCase()]
          : actionDecider.DEFAULT}
      </span>
    ));

  /**
   * @description - builds a cell based on column object
   *
   * @returns {JSX[]}
   */
  const buildCell = () =>
    cols.map((col, index) => {
      if (col.type === 'String') {
        const className = 'name-column';
        const cellContent = row[col.value];

        if (col.value === 'name') {
          const rowIcon = row.icon || row.emoji_icon || '';

          return (
            <td
              key={index}
              onClick={() => showEditModal(row)}
              className={`${className} clickable`}
            >
              <span style={{ marginRight: '5px' }}>{rowIcon}</span>
              {cellContent}
            </td>
          );
        }

        if (col.value === 'subareas') {
          return (
            <td
              key={index}
              onClick={() => showEditModal(row)}
              className={`${className} clickable`}
            >
              {cellContent.length}
            </td>
          );
        }

        if (col.editable) {
          return (
            <td
              key={index}
              onClick={() => showEditModal(row)}
              className={`${className} clickable`}
            >
              {cellContent}
            </td>
          );
        }

        if (col.value === 'filter_types') {
          const required_neighborhoods_list = [];
          const excluded_neighborhoods = [];
          for (const required_neighborhoods of row.required_neighborhoods) {
            const required_neighborhoods_group = [];
            for (const required_neighborhood of required_neighborhoods) {
              required_neighborhoods_group.push(
                required_neighborhood.unique_slug
              );
            }
            required_neighborhoods_list.push(required_neighborhoods_group);
          }
          for (const excluded_neighborhood of row.excluded_neighborhoods) {
            excluded_neighborhoods.push(excluded_neighborhood.unique_slug);
          }
          return (
            <td key={index}>
              {
                findTypeDropdownLabel(
                  required_neighborhoods_list,
                  excluded_neighborhoods,
                  filterLocationsDropdownOptions
                    ? filterLocationsDropdownOptions
                    : typeDropdownOptions
                ).label
              }
            </td>
          );
        }

        return <td key={index}>{cellContent}</td>;
      }

      if (col.type === 'Switch') {
        let checked;

        if (col.value === 'status') {
          checked = row[col.value] === CITIES.AVAILABLE;
        } else {
          checked = row[col.value];
        }

        return (
          <td key={index} className="switch-column">
            <Switch
              onChange={() => onToggleSwitch(row, col.value)}
              checked={checked}
              {...switchStyling()}
            />
          </td>
        );
      }

      if (col.type === 'Options') {
        return (
          <td key={index} className="options-column">
            {decideAction(col.value)}
          </td>
        );
      }

      if (col.type === 'Tag') {
        return (
          <td key={index} className="tag-column">
            {decideAction(col.value)}
          </td>
        );
      }

      return null;
    });

  return <tr className={rowClasses}>{buildCell()}</tr>;
};

// --------------------------------------------------------------------------|
//                            PropTypes Check
// --------------------------------------------------------------------------|
CobTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  cols: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  rowClasses: PropTypes.string.isRequired,
  // hasChildren: PropTypes.bool,
  onToggleSwitch: PropTypes.func.isRequired,
  onDeleteCategory: PropTypes.func.isRequired,
  showEditModal: PropTypes.func,
  // onExpandRow: PropTypes.func,
  isDeleteAllowed: PropTypes.bool,
  deleteDisabledTooltip: PropTypes.string,
  parentSlug: PropTypes.string,
  filterLocationsDropdownOptions: PropTypes.array,
};

// --------------------------------------------------------------------------|
//                              DefaultProps
// --------------------------------------------------------------------------|
CobTableRow.defaultProps = {
  // hasChildren: false,
  onExpandRow: () => {},
  isDeleteAllowed: true,
  deleteDisabledTooltip: '',
  showEditModal: () => {},
  invokeParentFilter: () => {},
  parentSlug: '',
  tags: [],
};

// --------------------------------------------------------------------------|
//                                Export
// --------------------------------------------------------------------------|
export default CobTableRow;
