/**
 * ************************************
 *
 * @module  Input.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description reusable input component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import './Input.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const Input = forwardRef((props, ref) => {
  const {
    type,
    value,
    name,
    placeholder,
    onChange,
    className = '',
    ...rest
  } = props;
  /**
   * @description - input string state
   */
  const [inputState, setInputState] = useState(value);

  const classNames = cx(['input-bar', `${className.split(' ').join(',')}`]);

  useEffect(() => {
    setInputState(value);
  }, [value]);

  /**
   * @description - handles button click interaction
   *
   * @param {Object} e - DOM event object
   *
   * @returns {void}
   */
  const onChangeHandler = (e) => {
    setInputState(e.target.value);
    // fires the onchange function passed to input
    onChange(e);
  };

  return (
    <input
      ref={ref}
      className={classNames}
      type={type}
      value={inputState}
      name={name}
      placeholder={placeholder}
      onChange={onChangeHandler}
      {...rest}
    />
  );
});

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
Input.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
Input.defaultProps = {
  value: '',
  className: '',
  placeholder: '',
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default React.memo(Input);
