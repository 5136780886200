/**
 * ************************************
 *
 * @module  DropDown.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description dropdown menu leveraging ReactDropDown.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactDropDown from 'react-dropdown';

import 'react-dropdown/style.css';

import './DropDown.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const DropDown = ({
  name,
  options,
  onSelect,
  className,
  controlClassName,
  placeholderClassName,
  menuClassName,
  arrowClassName,
  placeholder,
  value,
  defaultOption,
  disabled = false,
}) => {
  const containerClass = cx(['drop-down', `${className}`]);
  const controlClass = cx(['control', `${controlClassName}`]);
  const placeholderClass = cx(['placeholder'], `${placeholderClassName}`);
  const arrowClass = cx(['arrow'], `${arrowClassName}`);

  /**
   * @description - handles drop down selection
   *
   * @param {Any} selectedValue
   *
   * @returns {void}
   */
  const onChange = (selectedValue) => onSelect(selectedValue, name);

  /**
   * @description - preps options in a format that ReactDropdown is
   * expecting
   *
   * @returns {Object[]}
   */
  const prepareOptionWithClassName = () =>
    options.map((option) => ({
      ...option,
      className: 'option',
    }));

  return (
    <ReactDropDown
      options={prepareOptionWithClassName()}
      onChange={onChange}
      className={containerClass}
      controlClassName={controlClass}
      placeholderClassName={placeholderClass}
      menuClassName={menuClassName}
      arrowClassName={arrowClass}
      placeholder={placeholder}
      value={value || defaultOption}
      disabled={disabled}
    />
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
DropDown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  controlClassName: PropTypes.string,
  placeholderClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  arrowClassName: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  defaultOption: PropTypes.any,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
DropDown.defaultProps = {
  className: '',
  controlClassName: '',
  placeholderClassName: '',
  menuClassName: '',
  arrowClassName: '',
  placeholder: '',
  value: '',
  defaultOption: '',
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default DropDown;
