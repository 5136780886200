/**
 * ************************************
 *
 * @module  Movie.action.js
 * @author  Matt P
 * @date    12/27/2020
 * @description redux action for Movie component
 *
 * ************************************
 */

// ----------------------------------------------------------------------------|
//                             Import ActionTypes
// ----------------------------------------------------------------------------
import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                            Export - Movie Actions
// ----------------------------------------------------------------------------|

// -------------------------Fetch Data Actions --------------------------------|
export const fetchMovieAction = (data, successCB) => ({
  type: ACTIONS.MOVIE.FETCH_MOVIE,
  data,
  successCB,
});

// -------------------------Data Save Actions ---------------------------------|
export const saveData = (data, id, successCB) => ({
  type: ACTIONS.MOVIE.SAVE_DATA,
  data,
  id,
  successCB,
});

export const saveDataToRedux = (data) => ({
  type: ACTIONS.MOVIE.SAVE_DATA_TO_REDUX,
  data,
});

// ------------------------- Image Actions ------------------------------------|
export const clearImagesAction = (data) => ({
  type: ACTIONS.MOVIE.CLEAR_IMAGES,
  data,
});

export const deleteImageAction = (data) => ({
  type: ACTIONS.MOVIE.DELETE_IMAGE,
  data,
});

export const populateAutofillImagesAction = (data) => ({
  type: ACTIONS.MOVIE.POPULATE_IMAGES,
  data,
});

export const reOrderImagesAction = (data) => ({
  type: ACTIONS.MOVIE.RE_ORDER_IMAGES,
  data,
});

export const uploadImageAction = (data) => ({
  type: ACTIONS.MOVIE.UPLOAD_IMAGE,
  data,
});
