/**
 * ************************************
 *
 * @module  ImagePreview.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description preview image for the FileUploader component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import removeIcon from 'assets/images/remove.svg';
import placeholder from 'assets/images/placeholder.png';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const ImagePreview = ({ onRemove, imageUrl, showError, largePreview }) => {
  const classNames = cx(
    'dzu-previewImage',
    { error: showError },
    { largePreview: largePreview }
  );

  /**
   * @description - prevents default while allowing the click
   * to drag ro rearrange functionality of the image uploader
   *
   * @param {Object} e - DOM event object
   *
   * @returns {void}
   */
  const onMouseMove = (e) => e.preventDefault();

  return (
    <div className="preview-file">
      <img
        onMouseMove={onMouseMove}
        alt="preview"
        className={classNames}
        src={imageUrl || placeholder}
      />
      <img
        name={imageUrl}
        className="preview-action"
        src={removeIcon}
        alt="Remove"
        onClick={onRemove}
      />
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
ImagePreview.propTypes = {
  imageUrl: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  largePreview: PropTypes.bool,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
ImagePreview.defaultProps = {
  imageUrl: '',
  showError: false,
  largePreview: false,
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default ImagePreview;
