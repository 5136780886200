/**
 * ************************************
 *
 * @module  PreviewWrapper.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description component for the preview card button
 *
 * ************************************
 */
// ------------------------------------------------------------------------------------------|
//                                          Imports
// ------------------------------------------------------------------------------------------|
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Backdrop, PreviewModal, PreviewCardModal } from 'components';

import { GENERIC } from 'constants.js';

import PreviewHeader from './PreviewHeader/PreviewHeader';
import PreviewTab from './PreviewTab/PreviewTab';

import './PreviewWrapper.scss';

// ------------------------------------------------------------------------------------------|
//                             React Function Component - PreviewWrapper
// ------------------------------------------------------------------------------------------|
const PreviewWrapper = (props) => {
  const {
    PREVIEW_MODAL: {
      TABS: { CARD },
    },
  } = GENERIC;

  const { modalClose, data, fromEvent, fromPlace } = props;

  const [currentTab, setCurrentTab] = useState(CARD);

  return (
    <Fragment>
      <Backdrop show onClick={modalClose} />
      <div className="preview-wrapper">
        <PreviewHeader modalClose={modalClose} />
        <PreviewTab currentTab={currentTab} setCurrentTab={setCurrentTab} />
        <div className="tab-content">
          {currentTab === CARD ? (
            <PreviewCardModal data={data} fromEvent={fromEvent} />
          ) : (
            <PreviewModal data={data} fromEvent={fromEvent} fromPlace={fromPlace} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

// ------------------------------------------------------------------------------------------|
//                               PropTypes Check - PreviewWrapper
// ------------------------------------------------------------------------------------------|
PreviewWrapper.propTypes = {
  data: PropTypes.shape({
    previewImage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.array.isRequired,
    neighborhood: PropTypes.array.isRequired,
    price: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    address: PropTypes.string,
    previewCardList: PropTypes.array,
    startDate: PropTypes.string,
    fromTime: PropTypes.string,
    eventAmount: PropTypes.number,
    eventAmountSuffix: PropTypes.string,
    phone: PropTypes.string,
  }),
  modalClose: PropTypes.func.isRequired,
  fromEvent: PropTypes.bool,
  fromPlace: PropTypes.bool,
};

// ------------------------------------------------------------------------------------------|
//                               Default Props - PreviewWrapper
// ------------------------------------------------------------------------------------------|
PreviewWrapper.defaultProps = {
  data: {
    address: '',
    previewCardList: [],
    startDate: '',
    fromTime: '',
    eventAmount: 0,
    eventAmountSuffix: '',
    phone: '',
  },
  fromEvent: false,
  fromPlace: false,
};

// ------------------------------------------------------------------------------------------|
//                                   Export - PreviewWrapper
// ------------------------------------------------------------------------------------------|
export default PreviewWrapper;
