/**
 * ************************************
 *
 * @module  Movie.js
 * @author  Matt P
 * @date    12/27/2020
 * @description Container for editing a 'Movie' Currently renders within
 * the CardViewModal is shown renders when a card is clicked in the PlacesList
 * component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import { deleteMovieAction } from 'store/actions/~depreciated/Movies.action';

import {
  clearImagesAction,
  deleteImageAction,
  fetchMovieAction,
  populateAutofillImagesAction,
  reOrderImagesAction,
  saveData,
  saveDataToRedux as saveDataToReduxAction,
  uploadImageAction,
} from 'store/actions/~depreciated/Movie.action';

import { toast } from 'react-toastify';

import MovieData from 'containers/~depreciated/Movie/MovieData.constants';

import {
  FileUploader,
  Form,
  FormActions,
  Loader,
  Overlay,
  WarningModal,
  ThirdPartySearchModal,
} from 'components';

import { MOVIE } from 'constants.js';

import {
  applyDrag,
  deepCopy,
  chooseItemizedDescription,
  createDropdownOptions,
  mapDetailedCategories,
} from 'utils/utils';

import CommonService from 'services/CommonService';
import MovieService from 'containers/~depreciated/Movie/MovieService';

import './Movie.scss';

// ----------------------------------------------------------------------------|
//                            Redux - Property Mapping
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  // Fetch Data Actions
  fetchMovie: (data) => dispatch(fetchMovieAction(data)),
  // Data Save Actions
  saveData: (data, id, successCB) => dispatch(saveData(data, id, successCB)),
  saveDataToRedux: (data) => dispatch(saveDataToReduxAction(data)),
  // Image Actions
  clearImages: () => dispatch(clearImagesAction()),
  deleteImage: (data) => dispatch(deleteImageAction(data)),
  populateAutofillImages: (data) =>
    dispatch(populateAutofillImagesAction(data)),
  reOrderImages: (data) => dispatch(reOrderImagesAction(data)),
  // updateImage: (data) => dispatch(updateImageAction(data)),
  uploadImage: (data) => dispatch(uploadImageAction(data)),
  deleteMovie: (data) => dispatch(deleteMovieAction(data)),
});

const mapStateToProps = (state) => ({
  movie: state.movie,
  categoryMap: state.places.categoryMap,
  adminCategories: state.places.adminCategories,
  movieGenres: state.places.movieCategories,
  movieRatings: state.places.movieRatings,
  tvRatings: state.places.tvRatings,
  categoriesLoaded: state.places.categoriesLoaded,
});

// ----------------------------------------------------------------------------|
//                                  Utilities
// ----------------------------------------------------------------------------|
const commonService = CommonService();

const { WARNING_ON_DRAFT } = MOVIE;

// ----------------------------------------------------------------------------|
//                       React Class PureComponent - Movie
// ----------------------------------------------------------------------------|
class Movie extends PureComponent {
  // --------------------------------------------------------------------------|
  //                        PropTypes Check - Movie
  // --------------------------------------------------------------------------|
  static propTypes = {
    movie: PropTypes.shape({
      autofilledImages: PropTypes.arrayOf(PropTypes.object),
      failedUploads: PropTypes.arrayOf(PropTypes.object),
      formData: PropTypes.shape({
        cta_title_long: PropTypes.string,
        cta_title_short: PropTypes.string,
        description: PropTypes.string,
        images: PropTypes.arrayOf(PropTypes.object),
        links_to_movie: PropTypes.arrayOf(PropTypes.string),
        main_categories: PropTypes.arrayOf(PropTypes.object),
        movie_production_status: PropTypes.string,
        categories: PropTypes.arrayOf(PropTypes.object),
        original_language: PropTypes.string,
        original_title: PropTypes.string,
        phrase: PropTypes.string,
        release_date: PropTypes.string,
        runtime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string,
      }),
      images: PropTypes.arrayOf(PropTypes.object),
      isBtnDisabled: PropTypes.bool.isRequired,
      isDropZonePreviewRequired: PropTypes.bool.isRequired,
      // Only for fetched movie
      fetchedMovieData: PropTypes.shape({
        id: PropTypes.string.isRequired,
        original_language: PropTypes.string,
        cta_title_long: PropTypes.string,
        cta_title_short: PropTypes.string,
        categories: PropTypes.arrayOf(PropTypes.object),
        main_categories: PropTypes.arrayOf(PropTypes.object),
        release_date: PropTypes.string,
        status: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        original_title: PropTypes.string,
        runtime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        links_to_movie: PropTypes.arrayOf(PropTypes.string),
        phrase: PropTypes.string,
        images: PropTypes.arrayOf(PropTypes.object),
        movie_production_status: PropTypes.string,
      }),
    }).isRequired,
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      original_language: PropTypes.string,
      cta_title_long: PropTypes.string,
      cta_title_short: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.object),
      main_categories: PropTypes.arrayOf(PropTypes.object),
      release_date: PropTypes.string,
      status: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      original_title: PropTypes.string,
      runtime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      links_to_movie: PropTypes.arrayOf(PropTypes.string),
      phrase: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.object),
      movie_production_status: PropTypes.string,
      // Below are only for a movie that's fetched
      shouldFetchMovie: PropTypes.bool,
      shouldDisplayLoader: PropTypes.bool,
    }).isRequired,
    currentTab: PropTypes.string.isRequired,
    adminCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
    reOrderImages: PropTypes.func.isRequired,
    fetchMovie: PropTypes.func.isRequired,
    saveData: PropTypes.func.isRequired,
    saveDataToRedux: PropTypes.func.isRequired,
    clearImages: PropTypes.func.isRequired,
    deleteImage: PropTypes.func.isRequired,
    populateAutofillImages: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    uploadImage: PropTypes.func.isRequired,
  };

  // --------------------------------------------------------------------------|
  //                          Default Props - Movie
  // --------------------------------------------------------------------------|
  static defaultProps = {};

  // --------------------------------------------------------------------------|
  //                      Constructor and State - Movie
  // --------------------------------------------------------------------------|
  constructor(props) {
    super(props);

    const { data, saveDataToRedux } = this.props;
    const initialMovieState = this.handleMovieData(data);

    this.state = {
      movieId: data.id,
      errors: {},
      imageErrors: '',
      showEditHours: false,
      shouldFetchMovie: data.shouldFetchMovie,
      shouldDisplayLoader: false,
      showThirdPartyModal: false,
      thirdPartyEdit: null,
      ...initialMovieState,
    };
    // initially keep the data in the redux store
    saveDataToRedux(this.getToBeProcessedData(data));
  }

  // --------------------------------------------------------------------------|
  //                          Lifecycle Methods
  // --------------------------------------------------------------------------|
  componentDidMount() {
    const { populateAutofillImages, data, categoriesLoaded } = this.props;
    const { shouldFetchMovie } = this.state;

    populateAutofillImages(data.images);

    // fires if we need to fetch a move based on id
    if (shouldFetchMovie) {
      this.fetchMovieFromServer();
      this.setState({ shouldFetchMovie: false, shouldDisplayLoader: true });
    }

    // cats are async calls to redux saga, and should load before the
    // form renders. The shouldDisplayLoader flag is set for this reason,
    // and is flipped when the component updates on prop fulfillment
    if (
      // movieGenres.length === 0 ||
      // adminCategories.length === 0 ||
      // movieRatings.length === 0 ||
      // tvRatings.length === 0 ||
      // Object.entries(categoryMap).length === 0
      !categoriesLoaded
    ) {
      this.setState({ shouldDisplayLoader: true });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      data,
      movie,
      saveDataToRedux,
      populateAutofillImages,
      categoriesLoaded,
    } = this.props;

    const { movieId, shouldDisplayLoader } = this.state;

    const { shouldFetchMovie } = data;

    if (shouldFetchMovie) {
      if (
        // adminCategories.length !== 0 &&
        // movieRatings.length !== 0 &&
        // tvRatings.length !== 0 &&
        // movieGenres.length !== 0 &&
        categoriesLoaded &&
        movie.fetchedMovieData &&
        movieId === movie.fetchedMovieData.id &&
        shouldDisplayLoader
      ) {
        this.setState({
          shouldDisplayLoader: false,
          ...this.handleMovieData(movie.fetchedMovieData),
        });

        this.replaceUrl(movie.fetchedMovieData);

        saveDataToRedux(this.getToBeProcessedData(movie.fetchedMovieData));
        populateAutofillImages(movie.fetchedMovieData.images);
      }
    } else if (
      // adminCategories.length !== 0 &&
      // movieRatings.length !== 0 &&
      // tvRatings.length !== 0 &&
      // movieGenres.length !== 0 &&
      categoriesLoaded &&
      !movie.fetchedMovieData &&
      shouldDisplayLoader
    ) {
      this.setState({
        shouldDisplayLoader: false,
        ...this.handleMovieData(data),
      });

      saveDataToRedux(this.getToBeProcessedData(data));

      this.replaceUrl(data);
    }
  }

  componentWillUnmount() {
    const { clearImages, movie } = this.props;

    clearImages(movie);
  }

  // --------------------------------------------------------------------------|
  //                      Component Methods - Movie
  // --------------------------------------------------------------------------|
  // Used to replace the URl with the correct section
  // (Drafts/Published/Fetched) as per the
  // cards 'status' to stay consistent
  replaceUrl = (data = {}) => {
    // check if the URl pathname is off
    const { location, history } = this.props;
    const { section, tab, id } = commonService.pathnameHelper(
      location.pathname
    );

    if (data.status) {
      const cardStatus = data.status === 'draft' ? 'drafts' : data.status;

      if (tab !== cardStatus) {
        history.replace({
          pathname: `/${section}/${cardStatus}/${data.id || id}`,
        });
      }
    }
  };

  /**
   * @description Fetches Movie object from server using ID
   */
  fetchMovieFromServer = () => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.fetchMovie({ movieId: this.state.movieId });
  };

  /**
   * @description Handle Movie object data: prepare form, display, etc..
   *
   * @param {Object} movieDataToProcess - Object of movie data
   */
  handleMovieData = (movieDataToProcess) => {
    const toBeProcessedObj = this.getToBeProcessedData(movieDataToProcess);
    const processedData = this.getProcessedData(toBeProcessedObj);

    return {
      data: processedData,
    };
  };

  /**
   * @description fires to reorders images
   *
   * @param {Object} dropData
   */
  onReOrderImages = (dropData) => {
    const { movie, reOrderImages } = this.props;
    const { autofilledImages, images } = movie;
    const reOrderedImages = applyDrag(
      [...autofilledImages, ...images],
      dropData
    );

    reOrderImages(reOrderedImages);
  };

  /**
   * @description fires to saveData. Runs several
   * validation checks found in MovieService file
   *
   * @param {Object} e - Event Object.
   */
  saveData = (e) => {
    const { movie, data, closeModal, adminCategories, saveData } = this.props;
    const { autofilledImages } = movie;

    const formattedImages = movie.autofilledImages.map((image) => {
      const imageTemp = { ...image };

      // // fixed error where is photo_metadata || photo_credit === null
      // // will wash to empty string for DB PUT/PATCH
      if (imageTemp.photo_credits === null) imageTemp.photo_credits = '';
      if (imageTemp.photo_metadata === null) imageTemp.photo_metadata = '';

      if (image.source === 'cobble') {
        delete imageTemp.fileId;

        return imageTemp;
      }

      return imageTemp;
    });

    // Special method for submitting the correct property data on image objects
    const convertedImageLinks = MovieService.convertImageLinks(formattedImages);

    const formData = MovieService.getPreparedFormData(
      movie.formData,
      [...convertedImageLinks],
      e.target.name
    );

    formData.categories = formData.categories.map((cat) => cat.unique_slug);

    formData.main_categories = movie.formData.main_categories.map(
      (cat) => cat.unique_slug
    );

    /** Perform validation only on published */
    if (e.target && e.target.name === 'published') {
      // let errors = MovieService.validate(movie.formData);
      const errors = MovieService.validate(movie.formData);

      const imageErrors = commonService.validateImages(
        movie.images,
        autofilledImages
      )
        ? null
        : MOVIE.IMAGE_VALIDATION_MESSAGE;

      if (Object.keys(errors).length === 0 && imageErrors === null) {
        // dispatch to save
        saveData(formData, data.id, closeModal);
      } else {
        toast.error('There are some errors in the form');
      }
      this.setState({
        errors,
        imageErrors,
      });
    } else if (e.target && e.target.name === 'draft') {
      // some basic checks for saving to drafts
      const errors = {
        ...MovieService.validateTitle(formData.title, 'title'),
        ...MovieService.validateReleaseDate(formData.release_date),
      };
      const imageErrors = '';

      if (
        formData.instance_type !== 'movie' &&
        formData.instance_type !== 'show'
      ) {
        errors.instance_type =
          'You must choose a media type (Movie or TV Show)';
      }

      if (
        !commonService.validatePrimaryCategories(
          movie.formData.main_categories,
          movie.formData.categories
        )
      ) {
        errors.categories =
          'Primary categories must be included in the selected categories';
      }

      if (Object.keys(errors).length === 0) {
        // dispatch to save
        saveData(formData, data.id, closeModal);
      } else {
        toast.error('There are some errors in the form');
      }
      this.setState({ errors, imageErrors });
    }
  };

  /**
   * @description returns an object with the correct
   * key/value pairs for editing
   *
   * @param {Object} obj
   */
  getToBeProcessedData = (obj) => {
    const { categoryMap } = this.props;

    return {
      title: obj.title,
      categories: obj.categories || [],
      main_categories: obj.main_categories
        ? mapDetailedCategories(obj.main_categories, categoryMap)
        : [],
      instance_type: obj.instance_type || '',
      release_date: obj.release_date,
      phrase: obj.phrase,
      description: obj.description,
      images: obj.images,
      last_admin_review: obj.last_admin_review,
      unit_history: obj.unit_history,
      // special case where we want to put the detailed description from
      // old card details into the itemized one
      itemized_description: chooseItemizedDescription(
        obj.itemized_description,
        obj.description,
        MovieService.movieDefaultDescriptions
      ),
      rating: obj.audience_ratings,
      related_tmdb_id: obj.related_tmdb_id || '',
    };
  };

  /**
   * @description Maps over and processes data for the form
   * component. This is the function that you need to edit to
   * conform to the various endpoint naming differences with the prop
   * fields of the form input components
   *
   * @param {Object} data - of form/card data
   */
  getProcessedData = (data = {}) => {
    const { movieGenres, movieRatings, tvRatings } = this.props;

    return MovieData.map((inputObj) => {
      let input = {};

      switch (inputObj.name) {
        case 'title':
          input = {
            ...inputObj,
            value: data.title,
            onButtonClick: () => {
              this.editThirdParty();
            },
          };
          break;
        case 'categories':
          input = {
            ...inputObj,
            main_categories: data.main_categories,
            tags: data.categories,
            suggestions: movieGenres || [],
          };
          break;
        case 'release_date':
          input = {
            ...inputObj,
            value:
              typeof data.release_date === 'string'
                ? data.release_date.split('-')[0]
                : data.release_date,
          };
          break;
        case 'rating':
          input = {
            ...inputObj,
            value:
              Array.isArray(data.rating) && data.rating.length
                ? data.rating[0].name
                : '',
            values:
              data.instance_type === 'movie'
                ? createDropdownOptions(movieRatings)
                : createDropdownOptions(tvRatings),
          };
          break;
        case 'last_admin_review':
          input = {
            ...inputObj,
            value: data.last_admin_review
              ? data.last_admin_review.review_status
              : null,
            reviewDate: data.last_admin_review
              ? data.last_admin_review.review_datetime
              : null,
            history: data.unit_history || [],
          };
          break;

        default:
          input = {
            ...inputObj,
            value: data[inputObj.name],
          };
          break;
      }

      return input;
    });
  };

  /**
   * @description toggles preview modal
   *
   * @param {Boolean} isShow
   */
  onShowPreviewModal = (isShow) => {
    this.setState({ showPreviewModal: isShow });
  };

  /**
   * @description fires on an 'event' which is any input or
   * change to the form. Updates redux
   *
   * @param {Any} event - data being passed || element name
   * @param {String} elemName - name of that input
   * @param {String} className
   *
   * @return {undefined} to exit function
   */
  eventHandler = (event, elemName, classname) => {
    const { saveDataToRedux } = this.props;

    if (
      elemName === 'categories' ||
      elemName === 'main_categories' ||
      elemName === 'images' ||
      elemName === 'admin_review' ||
      elemName === 'release_date' ||
      elemName === 'itemized_description'
    ) {
      saveDataToRedux({ [elemName]: event });
      return;
    }

    if (Array.isArray(event)) {
      const data = event.map((each) => each.name);

      saveDataToRedux({ [elemName]: data });
      return;
    }

    if (elemName === 'instance_type') {
      saveDataToRedux({ [elemName]: event.value });
      return;
    }

    if (elemName === 'rating') {
      saveDataToRedux({ [elemName]: [event.value] });
      return;
    }

    const { name, value } = event.target;

    saveDataToRedux({ [name]: value });
  };

  /**
   * @description fires when any of the form action buttons (bottom of form)
   * are clicked - ie: "MOVE TO DRAFTS" / "PUBLISHED" / "PREVIEW" and passes
   * data to be validated for a DB PUT/PATCH
   *
   * @argument {Object} event - Event Object.
   */
  onFormAction = (event) => {
    const { currentTab, data } = this.props;
    const { name } = event.target;

    if (name === 'preview') {
      this.onShowPreviewModal(true);
    } else if (
      name === 'draft' &&
      currentTab === 'published' &&
      data &&
      data.id
    ) {
      event.persist();

      this.setState({
        showWarningModal: true,
        onWarningClose: () => {
          this.saveData(event);
          this.setState({ showWarningModal: false, onWarningClose: () => {} });
        },
      });
    } else if (name === 'published' || name === 'draft') {
      this.saveData(event);
    } else if (name === 'delete') {
      event.persist();

      this.setState({
        showWarningModal: true,
        onWarningClose: () => {
          const { deleteMovie, closeModal, data } = this.props;

          // we can't call closeModal until the delete is resolved,
          // as closeModal resets the list state to [] which will not
          // allow us to filter the deleted card. So we close it after the
          // delete async function is complete
          const promiseMe = new Promise((resolve, reject) => {
            resolve(deleteMovie(data.id));
          });

          promiseMe
            .then(() => {
              closeModal();
            })
            .then(() => {
              this.setState({
                showWarningModal: false,
                onWarningClose: () => {},
              });
            });
        },
      });
    }
  };

  toggleThirdPartyModal = () => {
    const { showThirdPartyModal } = this.state;

    this.setState({
      showThirdPartyModal: !showThirdPartyModal,
      // clears the card data if the user has the modal open and closes it
      ...(showThirdPartyModal ? { thirdPartyEdit: null } : {}),
    });
  };

  editThirdParty = () => {
    const { movie } = this.props;

    this.setState(
      {
        thirdPartyEdit: movie.formData,
      },
      this.toggleThirdPartyModal
    );
  };

  onThirdPartyModalSubmit = (dataObj = {}) => {
    const { movie, data, adminCategories, saveData, location } = this.props;
    const { autofilledImages } = movie;
    const { tab } = commonService.pathnameHelper(location.pathname);

    const formattedImages = movie.autofilledImages.map((image) => {
      const imageTemp = { ...image };

      // // fixed error where is photo_metadata || photo_credit === null
      // // will wash to empty string for DB PUT/PATCH
      if (imageTemp.photo_credits === null) imageTemp.photo_credits = '';
      if (imageTemp.photo_metadata === null) imageTemp.photo_metadata = '';

      if (image.source === 'cobble') {
        delete imageTemp.fileId;

        return imageTemp;
      }

      return imageTemp;
    });

    // Special method for submitting the correct property data on image objects
    const convertedImageLinks = MovieService.convertImageLinks(formattedImages);

    const formData = MovieService.getPreparedFormData(
      dataObj,
      [...convertedImageLinks],
      tab === 'drafts' || tab === 'fetched' || tab === 'queue'
        ? 'draft'
        : 'published'
    );

    formData.categories = formData.categories.map((cat) => cat.unique_slug);

    formData.main_categories = movie.formData.main_categories.map(
      (cat) => cat.unique_slug
    );

    formData.should_refetch_3rd_parties_content = true;

    /** Perform validation only on published */
    if (tab === 'published') {
      const errors = MovieService.validate(movie.formData);

      const imageErrors = commonService.validateImages(
        movie.images,
        autofilledImages
      )
        ? null
        : MOVIE.IMAGE_VALIDATION_MESSAGE;

      if (Object.keys(errors).length === 0 && imageErrors === null) {
        toast.success('Fetching 3rd party updates and refreshing');

        setTimeout(() => {
          // dispatch to save
          saveData(formData, data.id, () => {
            window.location.reload();
          });
        }, 2000);
      } else {
        toast.error('There are some errors in the form');
      }
      this.setState({
        errors,
        imageErrors,
      });
    } else if (tab === 'drafts' || tab === 'fetched' || tab === 'queue') {
      // some basic checks for saving to drafts
      const errors = {
        ...MovieService.validateTitle(formData.title, 'title'),
        ...MovieService.validateReleaseDate(formData.release_date),
      };
      const imageErrors = '';

      if (
        formData.instance_type !== 'movie' &&
        formData.instance_type !== 'show'
      ) {
        errors.instance_type =
          'You must choose a media type (Movie or TV Show)';
      }

      if (
        !commonService.validatePrimaryCategories(
          movie.formData.main_categories,
          movie.formData.categories
        )
      ) {
        errors.categories =
          'Primary categories must be included in the selected categories';
      }

      if (Object.keys(errors).length === 0) {
        toast.success('Fetching 3rd party updates and refreshing');

        setTimeout(() => {
          // dispatch to save
          saveData(formData, data.id, () => {
            window.location.reload();
          });
        }, 2000);
      } else {
        toast.error('There are some errors in the form');
      }

      this.setState({ errors, imageErrors });
    }
  };

  // --------------------------------------------------------------------------|
  //                              Movie - Render
  // --------------------------------------------------------------------------|
  render() {
    const {
      uploadImage,
      deleteImage,
      movie,
      adminCategories,
      populateAutofillImages,
      currentTab,
      movieRatings,
      tvRatings,
    } = this.props;
    const {
      data,
      errors,
      showWarningModal,
      onWarningClose,
      shouldDisplayLoader,
      imageErrors,
      showThirdPartyModal,
      thirdPartyEdit,
    } = this.state;
    const { formData } = movie;
    const updatedData = commonService
      .getUpdatedData(formData, [...data])
      .map((update) => {
        // some extra special handling since the rating dropdown options
        // have to change if the user selects a 'show' or 'movie'

        // this is unique to movie vertical and is not a 'commonService'
        if (update.name === 'rating') {
          const updateCopy = deepCopy(update);

          updateCopy.value =
            Array.isArray(formData.rating) &&
            formData.rating.length &&
            formData.rating[0].name
              ? formData.rating[0].name
              : '';

          updateCopy.values =
            formData.instance_type === 'movie'
              ? createDropdownOptions(movieRatings)
              : createDropdownOptions(tvRatings);

          return updateCopy;
        }

        return update;
      });

    return (
      <div className="movie">
        {shouldDisplayLoader === true ? (
          <div className="overlay-wrapper">
            <Overlay show>
              <Loader />
            </Overlay>
          </div>
        ) : (
          <Fragment>
            <div className="form-content">
              {showWarningModal && (
                <WarningModal
                  message={WARNING_ON_DRAFT}
                  onSubmit={onWarningClose}
                  onCancel={() => {
                    this.setState({ showWarningModal: false });
                  }}
                />
              )}
              <FileUploader
                isDropZonePreviewRequired={movie.isDropZonePreviewRequired}
                data={movie.autofilledImages}
                onEvent={populateAutofillImages}
                failedUploads={movie.failedUploads}
                onAddImage={uploadImage}
                onDeleteImage={deleteImage}
                error={imageErrors}
                onDrop={this.onReOrderImages}
              />
              <div className="form">
                <Form
                  data={updatedData}
                  saveClick={() => {}}
                  onEvent={this.eventHandler}
                  errors={errors}
                  // showEditHours={showEditHours}
                  // editHoursError={editHoursError}
                />
              </div>
            </div>
            <FormActions
              verticalType="Movie"
              onAction={this.onFormAction}
              currentTab={currentTab}
              cardId={this.props.data.id}
              // eslint-disable-next-line react/destructuring-assignment
              isNewCard={Object.keys(this.props.data).length > 0}
              isBtnDisabled={movie.isBtnDisabled}
            />
            {showThirdPartyModal && (
              <ThirdPartySearchModal
                verticalType="movie"
                showModal={showThirdPartyModal}
                closeModal={this.toggleThirdPartyModal}
                onSubmit={this.onThirdPartyModalSubmit}
                cardData={thirdPartyEdit}
                noDataCallback={this.toggleThirdPartyModal}
              />
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

// --------------------------------------------------------------------------|
//                    Movie Export with Redux Connect
// --------------------------------------------------------------------------|
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Movie));
