/**
 * ************************************
 *
 * @module  AddressInput.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description address input component, used to search a location
 * and populate form location string and lat lng properties.
 * .
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import 'components/AddressInput/AddressInput.scss';

// ----------------------------------------------------------------------------|
//                          Redux - Property Mapping
// ----------------------------------------------------------------------------|
const mapStateToProps = (state) => ({
  verticalForm: state.verticalForm,
});

// ----------------------------------------------------------------------------|
//                    React Class Component - AddressInput
// ----------------------------------------------------------------------------|
class AddressInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
    this.instances = [];
  }

  // --------------------------------------------------------------------------|
  //                    Class Component - Lifecycle Methods
  // --------------------------------------------------------------------------|
  componentDidMount() {
    const { id, verticalForm } = this.props;
    const { formData } = verticalForm;

    this.instance = document.getElementById(id);
    this.instances = document.getElementsByClassName('address-input');

    this.instances[0].value = formData.address;

    this.initAutocomplete();
  }

  componentWillUnmount() {
    // Removes event listeners upon unmount for fresh listeners on mount
    window.google &&
      window.google.maps &&
      window.google.maps.event.removeListener(this.listener);
  }

  // --------------------------------------------------------------------------|
  //                    Class Component - Methods
  // --------------------------------------------------------------------------|
  /**
   * @description Autocomplete Method for Location Search - Leveraging Google
   * Maps api
   *
   * @returns {void}
   */
  initAutocomplete = () => {
    const { id, fromPlace } = this.props;

    this.autocomplete = {};

    const autocomplete = new window.google.maps.places.Autocomplete(
      this.instance,
      {
        types: ['geocode', 'establishment'],
      }
    );

    autocomplete.addListener('place_changed', () => {
      const shortName = id === '' && fromPlace;

      this.getLocation(autocomplete, this.instance.name, shortName);
    });
  };

  /**
   * @description getLocation Method for Google Maps api
   *
   * @param {Object} autocomplete - New instance of Googlemaps Constructor
   * @param {String} fieldName - Field name of location
   * @param {Boolean} shortName - True/False on if a shortened name is available
   *
   * @returns {void}
   */
  getLocation = (autocomplete, fieldName, shortName) => {
    const place = autocomplete.getPlace();
    const { geometry, name, formatted_address: formattedAddress } = place;

    let city;
    let zipCode;

    if (name) {
      const { onPlaceSelected, fromPlace } = this.props;

      if (shortName) {
        this.instances[0].value = name;
      }

      const placeData = {
        city,
        zipCode,
        // Assumption: Every second instance will have address as mapper
        address:
          fieldName === this.instances[0].name && !fromPlace
            ? name
            : formattedAddress,
        lat: geometry.location.lat(),
        long: geometry.location.lng(),
        name: fieldName,
      };

      onPlaceSelected(placeData);
    }
  };

  /**
   * @description On Input Change Method for Address Input
   *
   * @param {Object} e - "event object" for grabbing input field
   * values for location search
   *
   * @returns {void}
   */
  onInputChange = (e) => {
    if (!e.target.value) {
      const { onPlaceSelected } = this.props;

      const placeData = {
        city: null,
        zipCode: null,
        address: null,
        lat: null,
        long: null,
        name: e.target.name,
      };

      onPlaceSelected(placeData);
    }
  };

  render() {
    const { id, label, errors, name, checkboxComponent, disabled } = this.props;

    return (
      <Fragment>
        <label className="address-label">{label}</label>
        <div className="address-input__wrapper">
          <input
            className="input-bar address-input"
            placeholder=""
            type="text"
            id={id}
            key={id}
            name={name}
            onChange={this.onInputChange}
            disabled={disabled}
          />
          {checkboxComponent && (
            <div className="address-input__input-checkbox-component">
              {checkboxComponent}
            </div>
          )}
        </div>
        <div className="error">{errors}</div>
      </Fragment>
    );
  }
}

// --------------------------------------------------------------------------|
//                      PropTypes Check - AddressInput
// --------------------------------------------------------------------------|
AddressInput.propTypes = {
  onPlaceSelected: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  errors: PropTypes.string,
  fromPlace: PropTypes.bool,
  checkboxComponent: PropTypes.elementType,
  disabled: PropTypes.bool,
  verticalForm: PropTypes.shape({
    formData: PropTypes.shape({
      address: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

AddressInput.defaultProps = {
  label: '',
  errors: '',
  fromPlace: false,
  checkboxComponent: false,
  disabled: false,
};

// --------------------------------------------------------------------------|
//                        AddressInput Export
// --------------------------------------------------------------------------|
export default connect(mapStateToProps)(AddressInput);
