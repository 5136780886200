/**
 * ************************************
 *
 * @module  VerticalDisplay.action.js
 * @author  Matt P
 * @date   07/02/2021
 * @description redux action file for the VerticalDisplay container.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                               Actions
// ----------------------------------------------------------------------------|
const clearCardsAction = () => ({
  type: ACTIONS.VERTICAL_DISPLAY.CLEAR_CARDS,
});

const deleteCardAction = (data, verticalType) => ({
  type: ACTIONS.VERTICAL_DISPLAY.DELETE_CARD,
  data,
  verticalType,
});

const fetchNeighborhoodLocationsAction = () => ({
  type: ACTIONS.VERTICAL_DISPLAY.FETCH_NEIGHBORHOOD_LOCATIONS,
});

const filterVerticalListAction = (data, verticalType) => ({
  type: ACTIONS.VERTICAL_DISPLAY.FILTER_LIST,
  data,
  verticalType,
});

const getVerticalDisplayCardsAction = (data, verticalType) => ({
  type: ACTIONS.VERTICAL_DISPLAY.GET_VERTICAL_DISPLAY_CARDS,
  data,
  verticalType,
});

const updateSingleCardPropertyAction = (data, id, verticalType) => ({
  type: ACTIONS.VERTICAL_DISPLAY.UPDATE_SINGLE_CARD_PROPERTY,
  data,
  id,
  verticalType,
});

// ----------------------------------------------------------------------------|
//                               Exports
// ----------------------------------------------------------------------------|
export {
  clearCardsAction,
  deleteCardAction,
  fetchNeighborhoodLocationsAction,
  filterVerticalListAction,
  getVerticalDisplayCardsAction,
  updateSingleCardPropertyAction,
};
