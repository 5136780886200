/**
 * ************************************
 *
 * @module  Places.reducer.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description redux reducer file for Places component.
 *
 *  * ** PLACES IS IS A DEPENDENCY FOR OTHER FILES (ie. Curated Card(s)) and
 * can not yet be depreciated
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import { toast } from 'react-toastify';

import { GENERIC } from 'constants.js';
import { splitArray } from 'utils/utils';
import { extractCategoryTypes } from 'utils/CategoryUtils';

import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                                Initial State
// ----------------------------------------------------------------------------|
const initialState = {
  places: [],
  selectedPlaces: [],
  totalCount: 0,
  bulkActionSuccess: false,
  isInitialDataLoaded: false,
  pageNum: 1,
  // categories
  categoriesLoaded: false,
  cardCategories: [],
  categoryMap: {},
  adminCategories: [],
  placeCategories: [],
  movieCategories: [],
  movieRatings: [],
  tvRatings: [],
  vibes: [],
  recreationCategories: [],
  recipeCategories: [],
  mealTypes: [],
  diet: [],
  // neighborhood locations
  neighborhoodLocations: [],
  locations: [],
};

// ----------------------------------------------------------------------------|
//                                Places Reducer
// ----------------------------------------------------------------------------|
const Places = (state = initialState, action = null) => {
  const {
    DELETE_SUCCESS_MESSAGE,
    BULK_ACTION_SUCCESS_MESSAGE,
    DELETE_FAILURE_MESSAGE,
    BULK_ACTION_FAILURE_MESSAGE,
  } = GENERIC;

  switch (action.type) {
    case ACTIONS.PLACES.GET_PLACES_SUCCESS: {
      const { places, count } = action.data;
      const placeList = [...state.places, ...places];
      // This subtracts selected places from the placelist.
      // Selected places should not be shown in search results in CardListModal
      const updatedPlaceList = placeList.filter(
        ({ id: placeId }) =>
          !state.selectedPlaces.some(
            ({ id: selectedPlaceId }) => selectedPlaceId === placeId
          )
      );
      const updatedPageNum = state.pageNum + 1;

      return {
        ...state,
        places: updatedPlaceList,
        totalCount: count,
        isInitialDataLoaded: true,
        pageNum: updatedPageNum,
      };
    }

    case ACTIONS.PLACES.GET_PLACES_FAIL: {
      return { ...state, isInitialDataLoaded: true };
    }

    case ACTIONS.PLACES.DELETE_PLACE_SUCCESS: {
      const updatedPlaceList = state.places.filter(
        (place) => place.id !== action.data
      );
      const updatedTotalCount = state.totalCount - 1;
      return {
        ...state,
        places: updatedPlaceList,
        totalCount: updatedTotalCount,
      };
    }

    case ACTIONS.PLACES.DELETE_PLACE_FAIL: {
      return { ...state };
    }

    case ACTIONS.PLACES.BULK_ACTION: {
      return { ...state, bulkActionSuccess: false };
    }

    case ACTIONS.PLACES.BULK_ACTION_SUCCESS: {
      const updatedPlaceList = state.places.filter(
        (place) => action.data.id_list.indexOf(place.id) < 0
      );
      const updatedTotalCount = state.totalCount - action.data.id_list.length;
      const message = action.data.delete
        ? DELETE_SUCCESS_MESSAGE
        : BULK_ACTION_SUCCESS_MESSAGE;
      toast.success(message);
      return {
        ...state,
        places: updatedPlaceList,
        totalCount: updatedTotalCount,
        bulkActionSuccess: true,
      };
    }

    case ACTIONS.PLACES.BULK_ACTION_FAIL: {
      const message = action.data.delete
        ? DELETE_FAILURE_MESSAGE
        : BULK_ACTION_FAILURE_MESSAGE;
      toast.error(message);
      return { ...state };
    }

    case ACTIONS.PLACES.CLEAR_PLACES: {
      const { places, totalCount, isInitialDataLoaded, pageNum } = initialState;
      return { ...state, places, totalCount, isInitialDataLoaded, pageNum };
    }

    case ACTIONS.PLACES.CLEAR_SELECTED_PLACES: {
      return { ...state, selectedPlaces: [] };
    }

    /* ------------ Reducer for CardSelector component ------------- */
    case ACTIONS.PLACES.SELECT_PLACE: {
      const firstNumSelected = action.data;
      // Unselected places should not be shown in the modal. Hence
      // removed from main places list
      const { pass: selectedPlaces, fail: unselectedPlaces } = splitArray(
        state.places,
        (place) => firstNumSelected.indexOf(place.id) >= 0
      );
      return {
        ...state,
        selectedPlaces: [...state.selectedPlaces, ...selectedPlaces],
        places: [...unselectedPlaces],
      };
    }

    case ACTIONS.PLACES.DESELECT_PLACE: {
      // Deselected places are pushed back to the main places list
      const { pass: deselectedPlaces, fail: selectedPlaces } = splitArray(
        state.selectedPlaces,
        (place) => action.data === place.id
      );
      return {
        ...state,
        selectedPlaces: [...selectedPlaces],
        places: [...state.places, ...deselectedPlaces],
      };
    }

    /* ----------------------------------------------------------------- */
    case ACTIONS.PLACES.FETCH_ADMIN_CATEGORIES_SUCCESS: {
      const {
        child,
        places,
        movies,
        movieRatings,
        tvRatings,
        vibes,
        recreation,
        mealTypes,
        recipes,
        diet,
      } = extractCategoryTypes(action.data.admin_categories);

      // maps for constant key val lookup
      const categoryMap = action.data.admin_categories.reduce((acc, curr) => {
        if (!acc[curr.unique_slug]) acc[curr.unique_slug] = curr;

        return acc;
      }, {});

      return {
        ...state,
        categoriesLoaded: true,
        adminCategories: child,
        categoryMap,
        placeCategories: places,
        movieCategories: movies,
        movieRatings,
        tvRatings,
        vibes,
        recreationCategories: recreation,
        recipeCategories: recipes,
        mealTypes,
        diet,
        cardCategories: action.data.fs_categories,
      };
    }

    case ACTIONS.PLACES.CLEAR_ADMIN_CATEGORIES: {
      return { ...state, adminCategories: [] };
    }

    case ACTIONS.PLACES.FETCH_NEIGHBORHOOD_LOCATIONS_SUCCESS: {
      const neighborhoodLocations = [...action.data];
      return { ...state, neighborhoodLocations };
    }

    case ACTIONS.PLACES.MOVE_TO_TOP: {
      const { id, data: modifiedData } = action.data;
      const dataWithID = state.places.find((el) => el.id === id);
      const updatedDataWithoutID = state.places.filter((el) => el.id !== id);
      const updatedData = [
        { ...dataWithID, ...modifiedData },
        ...updatedDataWithoutID,
      ];

      return { ...state, places: [...updatedData] };
    }

    case ACTIONS.PLACES.REMOVE_PLACE: {
      const { id } = action.data;
      const updatedData = state.places.filter((el) => el.id !== id);
      const updatedCount = state.totalCount - 1;

      return { ...state, places: [...updatedData], totalCount: updatedCount };
    }

    default: {
      return state;
    }
  }
};

// ----------------------------------------------------------------------------|
//                          Places Reducer Export
// ----------------------------------------------------------------------------|
export default Places;
