/**
 * ************************************
 *
 * @module  CobTableRowToken.js
 * @author  Matt P
 * @date    03/04/2021
 * @description Token/Tag component for the CobTableRow
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import './CobTableRowToken.scss';
// ----------------------------------------------------------------------------|
//                   React Function Component - CobTableRowToken
// ----------------------------------------------------------------------------|
const CobTableRowToken = ({ tags, onClick }) => {
  const tokens = [];

  /**
   * @description - display max 18 tokens on table
   */
  const maxTokensToDisplay = tags.length > 18 ? 18 : tags.length;

  /**
   * @description - loops over creating token icons
   */
  for (let i = 0; i < maxTokensToDisplay; i += 1) {
    tokens.push(
      <li
        key={`token-${tags[i].unique_slug}-${i}${tags.length}`}
        className="table-row-token"
        onClick={() => {
          onClick({ label: tags[i].name || '', value: tags[i] });
        }}
      >
        <span className="table-row-token-label">{tags[i].name || ''}</span>
      </li>
    );
  }

  if (tags.length > 18)
    tokens.push(
      <li
        className="table-row-token"
        onClick={() => {}}
        key={`final-token-${tags[0].unique_slug}${tags.length}`}
      >
        <span className="table-row-token-label">{'. . .'}</span>
      </li>
    );

  return [tokens];
};

// --------------------------------------------------------------------------|
//                            PropTypes Check
// --------------------------------------------------------------------------|
CobTableRowToken.propTypes = {
  onClick: PropTypes.func,
};

// --------------------------------------------------------------------------|
//                              DefaultProps
// --------------------------------------------------------------------------|
CobTableRowToken.defaultProps = {
  onClick: () => {},
};

// --------------------------------------------------------------------------|
//                                Export
// --------------------------------------------------------------------------|
export default CobTableRowToken;
