/**
 * ************************************
 *
 * @module  Movies.js
 * @author  Matt P
 * @date    12/23/2020
 * @description Movies container
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { fetchAdminCategoriesAction } from 'store/actions/Places.action';
import {
  getMoviesListAction,
  deleteMovieAction,
  clearMoviesAction,
  performBulkOperationAction,
  selectMovieAction,
  filterMoviesListAction,
} from 'store/actions/~depreciated/Movies.action';

import { saveData } from 'store/actions/~depreciated/Movie.action';

import { Movie } from 'containers';
import MoviesList from 'containers/~depreciated/Movies/MoviesList/MoviesList';

import { MOVIE, GENERIC } from 'constants.js';

import {
  CardViewModal,
  DeleteModal,
  FilterSection,
  NoDataFound,
  Loader,
  Tabs,
  TotalCount,
  WidgetBox,
  CardBanner,
  ThirdPartySearchModal,
} from 'components';

import {
  getPartsFromUrl,
  getSelectedCardCount,
  getCardReviewStatus,
  checkHasMore,
  askForCloseConfirmation,
  getCardPrettyIds,
  deepCopy,
} from 'utils/utils';

import {
  formRatingOptions,
  FilterType,
  formGroupOptions,
} from 'components/FilterSection/FiltersFactory';

import MovieService from 'containers/~depreciated/Movie/MovieService';
import CommonService from 'services/CommonService';

import './Movies.scss';

// ----------------------------------------------------------------------------|
//                          Props Mapping - Movies
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  getMoviesList: (data) => dispatch(getMoviesListAction(data)),
  deleteMovie: (data) => dispatch(deleteMovieAction(data)),
  performBulkOperation: (data) => dispatch(performBulkOperationAction(data)),
  selectMovie: (data) => dispatch(selectMovieAction(data)),
  clearMoviesList: () => dispatch(clearMoviesAction()),
  fetchAdminCategories: () => dispatch(fetchAdminCategoriesAction()),
  filterMoviesAction: (data) => dispatch(filterMoviesListAction(data)),
  saveData: (data, id, successCB) => dispatch(saveData(data, id, successCB)),
});

const mapStateToProps = (state) => ({
  movies: state.movies,
  adminCategories: state.places.adminCategories,
  movieGenres: state.places.movieCategories,
  categoriesLoaded: state.places.categoriesLoaded,
});

// ----------------------------------------------------------------------------|
//                                  Utilities
// ----------------------------------------------------------------------------|
const { WARNING_ON_DELETE } = MOVIE;

const commonService = CommonService();

// ----------------------------------------------------------------------------|
//                       React Class PureComponent - Movies
// ----------------------------------------------------------------------------|
class Movies extends PureComponent {
  // --------------------------------------------------------------------------|
  //                         PropTypes Check - Movies
  // --------------------------------------------------------------------------|
  static propTypes = {
    status: PropTypes.string,
    hideExtras: PropTypes.bool,
    getMoviesList: PropTypes.func.isRequired,
    performBulkOperation: PropTypes.func.isRequired,
    selectMovie: PropTypes.func.isRequired,
    dismissModal: PropTypes.func,
    deleteMovie: PropTypes.func.isRequired,
    clearMoviesList: PropTypes.func.isRequired,
    fetchAdminCategories: PropTypes.func.isRequired,
    filterMoviesAction: PropTypes.func.isRequired,
    movies: PropTypes.shape({
      adminCategories: PropTypes.arrayOf(PropTypes.object),
      bulkActionSuccess: PropTypes.bool,
      cardCategories: PropTypes.arrayOf(PropTypes.object),
      isInitialDataLoaded: PropTypes.bool,
      movies: PropTypes.arrayOf(PropTypes.object),
      pageNum: PropTypes.number,
      searchedMovies: PropTypes.arrayOf(PropTypes.object),
      selectedMovies: PropTypes.arrayOf(PropTypes.object),
      tmdbPage: PropTypes.number,
      totalCount: PropTypes.number,
      fetchMore: PropTypes.bool.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      action: PropTypes.string,
      block: PropTypes.func,
      createHref: PropTypes.func,
      go: PropTypes.func,
      goBack: PropTypes.func,
      goForward: PropTypes.func,
      length: PropTypes.number,
      listen: PropTypes.func,
      location: PropTypes.shape({
        hash: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
      }),
      push: PropTypes.func,
      replace: PropTypes.func,
    }).isRequired,
  };

  // --------------------------------------------------------------------------|
  //                         Default Props - Movies
  // --------------------------------------------------------------------------|
  static defaultProps = {
    status: '',
    hideExtras: false,
    dismissModal: () => {},
  };

  // --------------------------------------------------------------------------|
  //                            State - Movies
  // --------------------------------------------------------------------------|
  constructor() {
    super();

    this.state = {
      currentTab: 'fetched',
      hasMore: true,
      newMovieData: null,
      deleteModalData: null,
      bulkDeleteModal: false,
      category: [],
      rating: [],
      reviewStatus: '',
      query: '',
      selectedCards: [],
      isChecked: null,
      openMovieID: '',
      newSearch: false,
      fetchingData: false,
      showThirdPartyModal: false,
      thirdPartyEdit: null,
    };
  }

  // --------------------------------------------------------------------------|
  //                      LifeCycle Methods - Movies
  // --------------------------------------------------------------------------|
  componentDidMount() {
    this.setCurrentTab();

    const {
      movies,
      fetchAdminCategories,
      history,
      categoriesLoaded,
    } = this.props;

    if (!categoriesLoaded) {
      fetchAdminCategories();
    }

    this.unlisten = history.listen((location, action) => {
      this.handleIDPathname();
    });
  }

  componentDidUpdate(prevProps) {
    const { location, movies } = this.props;

    if (location.pathname !== prevProps.location.pathname) {
      this.setCurrentTab();

      if (
        location.pathname.includes('fetched') ||
        prevProps.location.pathname.includes('fetched')
      ) {
        this.resetCategoriesFilter();
      }
    }

    if (
      movies.bulkActionSuccess !== prevProps.movies.bulkActionSuccess &&
      movies.bulkActionSuccess
    ) {
      this.dismissWidget();
    }

    if (movies.movies.length !== prevProps.movies.movies.length) {
      const { currentTab, fetchingData } = this.state;

      this.setHasMore();

      // will flip the fetching data flag so the loading card no longer
      // conditionally renders
      if (currentTab === 'fetched' && fetchingData) {
        this.setState({
          fetchingData: false,
        });
      }
    }

    if (prevProps.movies.fetchMore && !movies.fetchMore) {
      // flips for the loading card when we no longer have data to
      // fetch
      this.setState({
        fetchingData: false,
      });
    }

    if (movies.isInitialDataLoaded) {
      this.handleIDPathname();
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  // --------------------------------------------------------------------------|
  //                     Class Component Methods - Movies
  // --------------------------------------------------------------------------|

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  //                   DB Call Methods
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  /**
   * @description Handle presence of DB id in URL: redirect user to relevant
   * content
   */
  handleIDPathname = () => {
    const { location, movies } = this.props;
    const { openMovieID } = this.state;
    const { id } = commonService.pathnameHelper(location.pathname);

    if (id) {
      if (openMovieID !== id) {
        if (openMovieID) {
          this.setState({ newMovieData: null, openMovieID: '' });
        } else {
          this.setState({ openMovieID: id });

          let movieData;

          // searches for already fetched cards
          for (let i = 0; i < movies.movies.length; i += 1) {
            if (movies.movies[i].id === id) {
              movieData = movies.movies[i];
              break;
            }
          }

          // if it finds it, load it
          if (movieData) {
            this.setState({ newMovieData: movieData });
          } else {
            // if not, open a new modal and have it fetch the id
            this.setState({
              newMovieData: {
                id,
                shouldFetchMovie: true,
                shouldDisplayLoader: true,
              },
            });
          }
        }
      }
    } else if (openMovieID) {
      this.setNewMovieData(null);
    }
  };

  /**
   * @description sets state on if more cards are available
   * checkHasMore is imported from the common utils/utils file
   */
  setHasMore = () => {
    const { movies } = this.props;
    const { totalCount, fetchMore, tmdbPage } = movies;

    let hasMore;

    if (tmdbPage > -1) {
      hasMore = fetchMore;
    } else {
      hasMore = checkHasMore(movies.movies.length, totalCount);
    }

    this.setState({ hasMore });
  };

  /**
   * @description constructs a request object which is sent to Movies.saga
   * and ultimately requests a response based on state
   *
   * @returns {Object} - request object sent to Movies.saga
   */
  constructRequestObject = () => {
    const { movies, status } = this.props;
    const { pageNum: page, tmdbPage: tmdb_page } = movies;
    const {
      currentTab,
      category,
      rating,
      query,
      tmdbPage,
      newSearch,
      reviewStatus,
    } = this.state;

    const ratings = rating.map(({ value: rating }) =>
      rating === '1' ? rating : rating * 2
    );

    const statusFromTab = currentTab === 'drafts' ? 'draft' : currentTab;
    // This prop is used to explicitly set the status of places to be fetched
    const requestStatus = status || statusFromTab;
    // used when searching for specific categories
    const slugList = category.map(({ value }) => value);

    const requestObject = {
      page,
      status: requestStatus,
      ratings,
      title: query,
      review_status: reviewStatus,
      loaded_ids: getCardPrettyIds(movies.movies),
      tmdb_page: newSearch ? -1 : tmdb_page, // new search resets tmdb_page = -1
      ...(currentTab !== 'fetched'
        ? { cat_unique_slug_list: slugList }
        : { fs_cat_list: slugList }),
    };

    if (tmdbPage > 0 && currentTab === 'fetched')
      requestObject.tmdb_page = tmdbPage;

    currentTab !== 'fetched'
      ? (requestObject.cat_unique_slug_list = category.map(
          ({ value }) => value
        ))
      : (requestObject.fs_cat_list = category.map(({ value }) => value));

    // resets new search flag so we can reset tmdb_page to -1
    if (newSearch) {
      this.setState({
        newSearch: false,
      });
    }

    return requestObject;
  };

  /**
   * @description constructs a request object and invokes getMovies
   * (passed from redux) which is sent to Movies.saga and ultimately
   * requests a response based on state
   */
  getMoviesData = () => {
    const { getMoviesList } = this.props;
    const { currentTab } = this.state;
    const request = this.constructRequestObject();

    if (currentTab === 'fetched') {
      this.setState({
        fetchingData: true,
      });
    }

    getMoviesList(request);
  };

  /**
   * @description sets the current horizontal tab by invoking the
   * getPartsFromUrl function imported from utils/utils based on
   * the second part of the current route.
   */
  setCurrentTab = () => {
    const { location, clearMoviesList } = this.props;
    // Active tab in horizontal nav is set based on the second
    // part of the current route
    const { secondPart: currentTab } = getPartsFromUrl(location.pathname);

    clearMoviesList();
    this.dismissWidget();

    this.setState(
      {
        currentTab,
        newMovieData: null,
        openMovieID: '',
      },
      this.getMoviesData
    );
  };

  /**
   * @description requests more data after setting states hasMore prop to false.
   */
  getMoreData = () => this.setState({ hasMore: false }, this.getMoviesData);

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  //                  Filter Methods
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  /**
   * @description Fires when filter options are selected
   *
   * @param {String} returnType - string for data type
   * @param {Array} data - of object  data to set to state
   * @param {Function} callback - to fire after state update
   */
  onFilterSelect = (returnType, data, callback = () => {}) => {
    const { clearMoviesList } = this.props;
    let selectedData = data;
    // prevents a bug with null being passed when deleting filter cats
    if (
      (returnType === 'category' || returnType === 'rating') &&
      selectedData === null
    )
      selectedData = [];

    if (returnType === 'reviewStatus') {
      if (data.value) selectedData = data.value;
      else selectedData = '';
    }

    clearMoviesList();

    // API call to filter cards based on value selected from dropdown
    this.setState({ [returnType]: selectedData }, callback);
  };

  /**
   * @description Fires when filter menu closes.
   * constructs a request object then invokes the redux action
   * filterMovies.
   */
  onFilterMenuClosed = () => {
    const { filterMoviesAction } = this.props;

    const request = this.constructRequestObject();

    filterMoviesAction(request);
  };

  /**
   * @description clears away filters by invoking the redux
   * clearMoviesList function, resetting the states 'filtering'
   * properties, then requests a fresh GET request from the
   * backend via getMoviesData
   */
  clearFilters = () => {
    const { clearMoviesList } = this.props;

    clearMoviesList();

    this.setState(
      { category: [], rating: [], reviewStatus: '' },
      this.getMoviesData
    );
  };

  /**
   * @description just clears the category and ratings state properties
   */
  resetCategoriesFilter = () => this.setState({ category: [], rating: [] });

  /**
   * @description sets state and fires a GET request for a search bar
   * quest (aka: input). First clears the list, dismisses the widget,
   * then setsState with the query string before firing a getMoviesData req
   *
   * @param {String} query - string for the query search
   */
  onSearchInput = (query) => {
    const { clearMoviesList } = this.props;
    // API call to filter cards based on query
    clearMoviesList();
    this.dismissWidget();

    this.setState(
      {
        query,
        newSearch: true,
      },
      this.getMoviesData
    );
  };

  /**
   * @description fires when the modal window for editing a movie
   * is closed. Used for pushing the correct history to Routers
   * history object, dismissing the widget then setting the tab.
   */
  onCloseModal = () => {
    const { location, history } = this.props;
    const { section, tab, id } = commonService.pathnameHelper(
      location.pathname
    );

    if (id) {
      history.push(`/${section}/${tab}`);
    }

    this.dismissWidget();
    this.setCurrentTab();
  };

  /**
   * @description sets the state with a new movie data object for editing,
   * Fires on clicking of a card or the add new button
   *
   * @param {Object} newMovieData - Object of new movie data to
   * be set to the state object
   */
  setNewMovieData = (newMovieData) => {
    const { location, history } = this.props;
    const { section, tab, id } = commonService.pathnameHelper(
      location.pathname
    );

    // prevents infinite loop with id/endpoint rendering
    if (id) {
      history.push(`/${section}/${tab}`);
    } else if (newMovieData && newMovieData.id) {
      // if from the 3rd party modal control flow
      const { status } = newMovieData;

      history.push(`${status || tab}/${newMovieData.id}`);
    }

    this.setState(
      { newMovieData, openMovieID: '', thirdPartyEdit: null },
      this.dismissWidget
    );
  };

  /**
   * @description fires when you click a card, type of action depends on the
   * string value passed.
   *
   * @param {String} value - String specifying the action (Edit/Delete/Etc..)
   * @param {Object} data - Object of movie data
   */
  onMenuClicked = (value, data) => {
    const { location, history } = this.props;
    const {
      SUB_MENU: { EDIT, DELETE },
    } = GENERIC;

    const { tab } = commonService.pathnameHelper(location.pathname);
    const { id } = data;

    if (value === EDIT) {
      history.push(`${tab}/${id}`);
    }

    if (value === DELETE) {
      this.setState({ deleteModalData: id });
    }
  };

  /**
   * @description resets deleteModalData property to null
   */
  closeDeleteModalData = () => this.setState({ deleteModalData: null });

  /**
   * @description sets bulkDeleteModal property to false
   */
  closeBulkDeleteModal = () => this.setState({ bulkDeleteModal: false });

  /**
   * @description triggers bulk delete from the List of cards
   */
  bulkDeleteFromList = () => {
    const {
      SUB_MENU: { DELETE },
    } = GENERIC;

    this.closeBulkDeleteModal();
    this.performBulkOperation(DELETE);
  };

  /**
   * @description fires when deleting a movie from the list
   */
  deleteMovieFromList = () => {
    const { deleteMovie } = this.props;
    const { deleteModalData } = this.state;

    this.closeDeleteModalData();
    deleteMovie(deleteModalData);
  };

  /**
   * @description toggles what is included in the selectedCards state property
   *
   * @param {Boolean} currentCheckValue - toggles if card should be included
   * @param {String} cardId- string with unique cardId
   */
  onCheckBoxSelected = (currentCheckValue, cardId) => {
    this.setState((prevState) => {
      let selectedCards = [...prevState.selectedCards];

      if (currentCheckValue) {
        selectedCards.push(cardId);
      } else {
        selectedCards = selectedCards.filter((id) => id !== cardId);
      }

      const tempState = { selectedCards };

      if (typeof prevState.isChecked === 'boolean') {
        tempState.isChecked = null;
      }
      return tempState;
    });
  };

  /**
   * @description deselects all cards by setting state property isChecked
   * to false
   */
  deselectAllCards = () => {
    this.setState({ isChecked: false });
  };

  /**
   * @description resets widget to initial state
   */
  dismissWidget = () => {
    this.setState({ selectedCards: [], isChecked: null });
  };

  /**
   * @description redux helper for correct action
   *
   * @argument {Object} actions - with action types
   */
  getRelevantActions = (actions) => {
    const { status } = this.props;
    const { currentTab } = this.state;

    if (status === 'published') {
      return [actions.SELECT];
    }

    if (currentTab) {
      return currentTab === 'published'
        ? [actions.DELETE, actions.DRAFT]
        : [actions.DELETE, actions.PUBLISH];
    }

    return [];
  };

  /**
   * @description performs a bulk operation depending on type
   * string passed
   *
   * @argument {String} type - type of bulk operation
   */
  performBulkOperation = (type) => {
    const { dismissModal, selectMovie, performBulkOperation } = this.props;
    const { selectedCards } = this.state;
    const {
      BULK_ACTIONS: { DELETE, PUBLISH, DRAFT, SELECT },
    } = GENERIC;

    if (type === SELECT) {
      // This saves selected cards to redux
      dismissModal();
      selectMovie(selectedCards);
    } else {
      const data = {
        delete: type === DELETE,
        id_list: selectedCards,
      };

      if (type === PUBLISH) {
        data.status = 'published';
      }

      if (type === DRAFT) {
        data.status = 'draft';
      }

      performBulkOperation(data);
    }
  };

  /**
   * @description gets filter for movies
   */
  getFiltersForMovies = () => {
    const { adminCategories, movieGenres } = this.props;
    const { currentTab, category, rating, reviewStatus } = this.state;
    const {
      FILTER_DROP_DOWN: { RATING, TYPE, REVIEW_STATUS },
    } = MOVIE;

    if (currentTab === 'fetched')
      return [
        {
          name: RATING.LABEL,
          returnType: RATING.RETURN_TYPE,
          options: formRatingOptions(),
          value: rating,
          onFilterSelect: this.onFilterSelect,
          filterType: FilterType.MULTI_SELECT,
          onMenuClose: this.onFilterMenuClosed,
        },
      ];

    return [
      {
        name: RATING.LABEL,
        returnType: RATING.RETURN_TYPE,
        options: formRatingOptions(),
        value: rating,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.onFilterMenuClosed,
      },
      {
        name: TYPE.LABEL,
        returnType: TYPE.RETURN_TYPE,
        options: formGroupOptions(
          movieGenres,
          'categories',
          'unique_slug',
          'name'
        ),
        value: category,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.MULTI_SELECT,
        onMenuClose: this.onFilterMenuClosed,
        isSearchable: true,
      },
      {
        name: REVIEW_STATUS.LABEL,
        returnType: REVIEW_STATUS.RETURN_TYPE,
        options: getCardReviewStatus(),
        value: reviewStatus,
        onFilterSelect: this.onFilterSelect,
        filterType: FilterType.SELECT,
        onMenuClose: this.onFilterMenuClosed,
        isSearchable: false,
      },
    ];
  };

  toggleThirdPartyModal = () => {
    const { showThirdPartyModal } = this.state;

    this.setState({
      showThirdPartyModal: !showThirdPartyModal,
      // clears the card data if the user has the modal open and closes it
      ...(showThirdPartyModal ? { thirdPartyEdit: null } : {}),
    });
  };

  editThirdParty = (data) => {
    this.setState(
      {
        thirdPartyEdit: data,
      },
      this.toggleThirdPartyModal
    );
  };

  submitThirdParty = (data = {}) => {
    const { saveData } = this.props;
    const dataCopy = deepCopy(data);

    dataCopy.should_refetch_3rd_parties_content = true;

    // if a fresh card (no queue or fetched)
    if (!data.id) {
      if (!dataCopy.status) {
        dataCopy.status = 'draft';
      }

      if (dataCopy.rating) {
        delete dataCopy.rating;
      }

      if (dataCopy.related_tmdb_id) {
        dataCopy.related_tmdb_id = dataCopy.related_tmdb_id.toString();
      }

      saveData(dataCopy, null, this.forwardToCardId);
    } else {
      dataCopy.rating = [];

      const formData = MovieService.getPreparedFormData(
        dataCopy,
        [],
        !dataCopy.status ||
          dataCopy.status === 'fetched' ||
          dataCopy.status === 'queue'
          ? 'draft'
          : dataCopy.status
      );

      if (formData.categories && Array.isArray(formData.categories)) {
        formData.categories = formData.categories.map((cat) => cat.unique_slug);
      }

      saveData(formData, dataCopy.id, this.forwardToCardId);
    }
  };

  forwardToCardId = (cardId = '', defaultTab) => {
    const { location, history } = this.props;
    const { tab } = commonService.pathnameHelper(location.pathname);

    if (cardId) {
      history.push(`${defaultTab || tab}/${cardId}`);
    }
  };

  render() {
    const { hideExtras, movies, categoriesLoaded } = this.props;
    const { tmdbPage } = movies;
    const {
      newMovieData,
      currentTab,
      deleteModalData,
      selectedCards,
      isChecked,
      hasMore,
      bulkDeleteModal,
      query,
      fetchingData,
      showThirdPartyModal,
      thirdPartyEdit,
    } = this.state;

    // True when places container has been rendered into a modal
    // - used to hide filters, sub menu etc
    const { totalCount, isInitialDataLoaded } = movies;

    const { CARD_VIEW_TITLE, TAB_DATA, CARD_VIEW_TITLE_EDIT } = MOVIE;
    const {
      BULK_ACTIONS: { DELETE, PUBLISH, DRAFT, SELECT },
      CLOSE_CARD_WARNING_MESSAGE,
    } = GENERIC;

    const pageTitle =
      newMovieData && newMovieData.id ? CARD_VIEW_TITLE_EDIT : CARD_VIEW_TITLE;
    const fromFetched = currentTab === 'fetched';

    if (fromFetched && newMovieData) {
      newMovieData.categories = [];
    }

    // Show filter section only in fetched places Tab
    // In CardSelector modal, show only search bar
    const filterSectionUI = (
      <FilterSection
        filters={this.getFiltersForMovies()}
        searchValue={query}
        onSearchInput={this.onSearchInput}
        clearFilters={this.clearFilters}
      />
    );

    let movieListContent = <Loader />;

    // Collection of all possible actions in bulk actions widget
    const widgetActions = {
      DELETE: {
        label: DELETE,
        onClick: () => {
          this.setState({ bulkDeleteModal: true });
        },
        className: 'btn-inverse',
      },
      PUBLISH: {
        label: PUBLISH,
        onClick: () => {
          this.performBulkOperation(PUBLISH);
        },
      },
      DRAFT: {
        label: DRAFT,
        onClick: () => {
          this.performBulkOperation(DRAFT);
        },
      },
      SELECT: {
        label: SELECT,
        onClick: () => {
          this.performBulkOperation(SELECT);
        },
      },
    };

    const selectionWidget = selectedCards.length > 0 && (
      <WidgetBox
        className="movies-widget"
        data={this.getRelevantActions(widgetActions)}
        onDeselectClicked={this.deselectAllCards}
        actionText={getSelectedCardCount(selectedCards)}
        showStatus
      />
    );

    const showOrHideNavigation = !hideExtras && (
      <Fragment>
        <CardBanner
          title="Movies & Television"
          newBtnClick={this.toggleThirdPartyModal}
        />
        <Tabs data={TAB_DATA} active={currentTab} />
      </Fragment>
    );

    if (isInitialDataLoaded) {
      if (totalCount) {
        const current = movies.movies.length;

        movieListContent = (
          <Fragment>
            <TotalCount
              // so long as we're querying tmdb we don't know how many results
              // we'll get, this prop will conditionally render the number of
              // result counts displayed
              outsideFetch={tmdbPage > -1 && hasMore}
              current={current}
              total={totalCount}
            />
            <MoviesList
              data={movies.movies}
              hasMore={hasMore}
              fetchingData={fetchingData}
              onMoreData={this.getMoreData}
              onViewData={this.setNewMovieData}
              onMenuClicked={(action, data) => {
                if (currentTab === 'fetched' || currentTab === 'queue') {
                  this.editThirdParty(data);
                } else {
                  this.onMenuClicked(action, data);
                }
              }}
              // Bulk operations have not been created for Movies as this
              // is thought to be an unused feature, keeping logic for
              // now until a decision is made on it's merits
              // MoviesList card
              // onCheckBoxSelected={this.onCheckBoxSelected}
              onCheckBoxSelected={() => {}}
              isChecked={isChecked}
              fromFetched={fromFetched}
              hideMenu={hideExtras}
            />
          </Fragment>
        );
      } else {
        movieListContent = <NoDataFound />;
      }
    }

    return (
      <section className="movies-container">
        <div className="page-content">
          {newMovieData !== null && categoriesLoaded ? (
            <CardViewModal
              title={pageTitle}
              modalClosed={() => askForCloseConfirmation(this.onCloseModal)}
            >
              <Movie
                data={newMovieData}
                closeModal={() => this.onCloseModal()}
                currentTab={currentTab}
              />
            </CardViewModal>
          ) : (
            <Fragment>
              {showOrHideNavigation}
              {filterSectionUI}
              {movieListContent}
              {deleteModalData !== null && (
                <DeleteModal
                  cancelClick={this.closeDeleteModalData}
                  deleteClick={this.deleteMovieFromList}
                  additionalMessage={WARNING_ON_DELETE}
                />
              )}
              {showThirdPartyModal && (
                <ThirdPartySearchModal
                  verticalType="movie"
                  showModal={showThirdPartyModal}
                  closeModal={this.toggleThirdPartyModal}
                  onSubmit={this.submitThirdParty}
                  cardData={thirdPartyEdit}
                  noDataCallback={this.setNewMovieData}
                />
              )}

              {bulkDeleteModal && (
                <DeleteModal
                  cancelClick={this.closeBulkDeleteModal}
                  deleteClick={this.bulkDeleteFromList}
                />
              )}
            </Fragment>
          )}
        </div>
        {selectionWidget}
      </section>
    );
  }
}

// ----------------------------------------------------------------------------|
//                                Movies Export
// ----------------------------------------------------------------------------|
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Movies));
