/**
 * ************************************
 *
 * @module  MultiTagSelect.tsx
 * @author  Matt P
 * @date    10/05/2021
 * @description Multi tag input component with optional input for a sub-set of
 * main selections - ie: Categories and Main Categories form fields.
 *
 * Only renders second input for the subset when a mainTags prop is passed
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ICategories, INeighborhoods } from 'interfaces/apiDataInterfaces';

import { CobReactTagSelect } from 'components';

import { createDropdownOptions, filterInitialTagOptions } from 'utils/utils';
import { tagDropdownStyling } from 'utils/ReactSelectStyles';

import './MultiTagSelect.scss';

// ----------------------------------------------------------------------------|
//                            TypeScript Interfaces
// ----------------------------------------------------------------------------|
interface IProps {
  name: string;
  title?: string | undefined;
  tags: (ICategories | INeighborhoods)[];
  suggestions: (ICategories | INeighborhoods)[];
  groupSuggestions?: boolean | undefined;
  onChange: (
    selectedTags: (ICategories | INeighborhoods)[] | [],
    formProperty: string
  ) => void;
  mainTags?: (ICategories | INeighborhoods)[] | undefined;
  placeholder?: string;
  disabled?: boolean;
}

// ----------------------------------------------------------------------------|
//                          React Function Component
// ----------------------------------------------------------------------------|
const MultiTagSelect: React.FC<IProps> = ({
  name,
  title,
  tags,
  suggestions,
  groupSuggestions,
  onChange,
  mainTags,
  placeholder,
  disabled,
}) => {
  const [formTags, setFormTags] = useState(tags);
  const [formMainTags, setFormMainTags] = useState(mainTags);

  useEffect(() => {
    setFormTags(tags);
  }, [tags]);

  /**
   * @description updates either (or both) formTags and formMainTags
   * upon tag selection
   */
  const onSelectChange = (
    selectedValue: {
      label: string;
      value: ICategories | INeighborhoods;
    }[],
    stateType: string
  ): void => {
    // On clearing val may be undefined, but catch it and assign
    // empty array
    const option = selectedValue ? selectedValue.map((obj) => obj.value) : [];

    switch (stateType) {
      case 'categories':
      case 'vibes':
      case 'meal_type':
      case 'noteworthy_categories':
      case 'perfect_for':
      case 'neighborhoods': {
        // filter main tags if a user deletes a main tag
        if (formMainTags !== undefined && formTags.length > option.length) {
          const filteredMainTags = formMainTags.filter((mainTag) => {
            for (let i = 0; i < option.length; i += 1) {
              if (mainTag.unique_slug === option[i].unique_slug) return true;
            }

            return false;
          });

          setFormMainTags(filteredMainTags);
          onChange(filteredMainTags, 'main_categories');
        }

        setFormTags(option);
        onChange(option, stateType);
        break;
      }
      case 'main_categories':
      case 'main_neighborhoods': {
        setFormMainTags(option);
        onChange(option, stateType);
        break;
      }
      default: {
        break;
      }
    }
  };

  /**
   * @description - forms category groups by parent
   *
   * @returns {Object[]}
   */
  const formCategoryGroups = (
    catArray: ICategories[]
  ): {
    label: string;
    options: {
      label: string;
      value: ICategories;
    }[];
  }[] => {
    const groupedObj: {
      [key: string]: ICategories[];
    } = {};

    catArray.forEach((cat) => {
      const { parent_categories } = cat;

      if (parent_categories.length === 0) {
        if (!groupedObj['No Parents']) {
          groupedObj['No Parents'] = [cat];
        } else {
          groupedObj['No Parents'].push(cat);
        }
      } else {
        parent_categories.forEach((parents: any) => {
          if (parents.name) {
            if (!groupedObj[parents.name]) {
              groupedObj[parents.name] = [cat];
            } else {
              groupedObj[parents.name].push(cat);
            }
          }
        });
      }
    });

    return Object.entries(groupedObj).map((group) => ({
      label: group[0],
      options: group[1]
        .reduce(
          (
            resultArray: { value: ICategories; label: string }[],
            option: ICategories
          ) => {
            resultArray.push({ value: option, label: option.name });

            return resultArray;
          },
          []
        )
        .sort((a, b) => a.label.localeCompare(b.label)),
    }));
  };

  /**
   * @description - forms neighborhood groups by county property
   *
   * @returns {Object[]}
   */
  const formNeighborhoodGroups = (
    neighArray: INeighborhoods[]
  ): {
    label: string;
    options: {
      label: string;
      value: INeighborhoods;
    }[];
  }[] => {
    const groupedObj: { [key: string]: INeighborhoods[] } = {};

    neighArray.forEach((arr) => {
      if (arr.county) {
        if (!groupedObj[arr.county]) groupedObj[arr.county] = [arr];
        else groupedObj[arr.county].push(arr);
      } else if (!groupedObj.City) groupedObj.City = [arr];
      else groupedObj.City.push(arr);
    });

    return Object.entries(groupedObj).map((group) => ({
      label: group[0],
      options: group[1]
        .reduce(
          (
            resultArray: { value: INeighborhoods; label: string }[],
            option: INeighborhoods
          ) => {
            resultArray.push({ value: option, label: option.name });

            return resultArray;
          },
          []
        )
        .sort((a, b) => a.label.localeCompare(b.label)),
    }));
  };

  /**
   * @description - chooses what dropdown options to show based on
   * input name/form property name passed to component
   *
   * @returns {Object[]}
   */
  const chooseDropdownSuggestions = () => {
    switch (name) {
      case 'categories':
      case 'vibes':
      case 'noteworthy_categories':
      case 'perfect_for':
      case 'meal_type': {
        if (groupSuggestions)
          return formCategoryGroups(
            filterInitialTagOptions(formTags, suggestions)
          );
        return createDropdownOptions(
          filterInitialTagOptions(formTags, suggestions)
        );
      }
      case 'neighborhoods': {
        if (groupSuggestions)
          return formNeighborhoodGroups(
            filterInitialTagOptions(formTags, suggestions)
          );
        return createDropdownOptions(
          filterInitialTagOptions(formTags, suggestions)
        );
      }
      default: {
        return [];
      }
    }
  };

  return (
    <div className="multi-tag-select-container">
      <CobReactTagSelect
        placeholder={placeholder || ''}
        options={chooseDropdownSuggestions()}
        isClearable={false}
        name={name || ''}
        value={tags ? createDropdownOptions(formTags) : []}
        styles={tagDropdownStyling()}
        onChange={(selection) => {
          onSelectChange(selection, name);
        }}
        closeMenuOnSelect={false}
        hideSelectedOptions
        disabled={disabled}
      />
      {mainTags && (
        <div className="multi-tag-select-main-cat-container">
          <label className="main-multi-tag-select-header">
            {title ? `Main ${title}` : ''}
          </label>
          <CobReactTagSelect
            placeholder={placeholder || ''}
            options={
              formTags
                ? createDropdownOptions(
                    filterInitialTagOptions(formMainTags, formTags)
                  )
                : []
            }
            isClearable={false}
            name={name || ''}
            value={mainTags ? createDropdownOptions(formMainTags) : []}
            styles={tagDropdownStyling()}
            onChange={(selection) => {
              onSelectChange(selection, `main_${name}`);
            }}
            closeMenuOnSelect={false}
            hideSelectedOptions
          />
        </div>
      )}
    </div>
  );
};

// --------------------------------------------------------------------------|
//                             PropTypes Check
// --------------------------------------------------------------------------|
MultiTagSelect.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  groupSuggestions: PropTypes.bool,
};

// --------------------------------------------------------------------------|
//                              Default Props
// --------------------------------------------------------------------------|
MultiTagSelect.defaultProps = {
  placeholder: '',
  groupSuggestions: false,
  disabled: false,
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default MultiTagSelect;
