/**
 * ************************************
 *
 * @module  Recipes.reducer.js
 * @author  Matt P
 * @date    01/19/2021
 * @description redux reducer file for Recipes component.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|

import { toast } from 'react-toastify';

import { GENERIC } from 'constants.js';
import { splitArray, extractChildCategories } from 'utils/utils';

import ACTIONS from 'store/actions/actionTypes';

// ----------------------------------------------------------------------------|
//                               Initial State
// ----------------------------------------------------------------------------|
const initialState = {
  recipes: [],
  selectedRecipes: [],
  cardCategories: [],
  adminCategories: [],
  totalCount: 0,
  bulkActionSuccess: false,
  isInitialDataLoaded: false,
  pageNum: 1,
};

// ----------------------------------------------------------------------------|
//                              Recipes Reducer
// ----------------------------------------------------------------------------|
const Recipes = (state = initialState, action = null) => {
  const {
    DELETE_SUCCESS_MESSAGE,
    BULK_ACTION_SUCCESS_MESSAGE,
    DELETE_FAILURE_MESSAGE,
    BULK_ACTION_FAILURE_MESSAGE,
  } = GENERIC;

  switch (action.type) {
    case ACTIONS.RECIPES.GET_RECIPES_SUCCESS: {
      const { recipes, count } = action.data;

      // Else, a search for our backend
      const recipeList = [...state.recipes, ...recipes];
      // This subtracts selected recipes from the recipe list.
      // Selected recipes should not be shown in search results in CardListModal
      const updatedRecipeList = recipeList.filter(
        ({ id: recipeId }) =>
          !state.selectedRecipes.some(
            ({ id: selectedRecipeId }) => selectedRecipeId === recipeId
          )
      );
      const updatedPageNum = state.pageNum + 1;

      return {
        ...state,
        recipes: updatedRecipeList,
        totalCount: count,
        isInitialDataLoaded: true,
        pageNum: updatedPageNum,
        searchedRecipes: [],
      };
    }

    case ACTIONS.RECIPES.GET_RECIPES_FAIL: {
      return { ...state, isInitialDataLoaded: true };
    }

    case ACTIONS.RECIPES.DELETE_RECIPE_SUCCESS: {
      const updatedRecipeList = state.recipes.filter(
        (recipe) => recipe.id !== action.data
      );

      const updatedTotalCount = state.totalCount - 1;

      return {
        ...state,
        recipes: updatedRecipeList,
        totalCount: updatedTotalCount,
      };
    }

    case ACTIONS.RECIPES.DELETE_RECIPE_FAIL: {
      return { ...state };
    }

    case ACTIONS.RECIPES.BULK_ACTION: {
      return { ...state, bulkActionSuccess: false };
    }

    case ACTIONS.RECIPES.BULK_ACTION_SUCCESS: {
      const updatedRecipeList = state.recipes.filter(
        (recipe) => action.data.id_list.indexOf(recipe.id) < 0
      );
      const updatedTotalCount = state.totalCount - action.data.id_list.length;
      const message = action.data.delete
        ? DELETE_SUCCESS_MESSAGE
        : BULK_ACTION_SUCCESS_MESSAGE;

      toast.success(message);

      return {
        ...state,
        recipe: updatedRecipeList,
        totalCount: updatedTotalCount,
        bulkActionSuccess: true,
      };
    }

    case ACTIONS.RECIPES.BULK_ACTION_FAIL: {
      const message = action.data.delete
        ? DELETE_FAILURE_MESSAGE
        : BULK_ACTION_FAILURE_MESSAGE;

      toast.error(message);

      return { ...state };
    }

    case ACTIONS.RECIPES.CLEAR_RECIPES: {
      const {
        recipes,
        totalCount,
        isInitialDataLoaded,
        pageNum,
      } = initialState;

      return { ...state, recipes, totalCount, isInitialDataLoaded, pageNum };
    }

    case ACTIONS.RECIPES.CLEAR_SELECTED_RECIPES: {
      return { ...state, selectedRecipes: [] };
    }

    /* ------------ Reducer for CardSelector component ------------- */

    case ACTIONS.RECIPES.SELECT_RECIPE: {
      const firstNumSelected = action.data;
      // Unselected recipes should not be shown in the modal. Hence
      // removed from main recipe list
      const { pass: selectedRecipes, fail: unselectedRecipes } = splitArray(
        state.recipes,
        (recipe) => firstNumSelected.indexOf(recipe.id) >= 0
      );

      return {
        ...state,
        selectedRecipe: [...state.selectedRecipes, ...selectedRecipes],
        recipes: [...unselectedRecipes],
      };
    }

    case ACTIONS.RECIPES.DESELECT_RECIPE: {
      // Deselected recipes are pushed back to the main recipe list
      const { pass: deselectedRecipes, fail: selectedRecipes } = splitArray(
        state.selectedRecipes,
        (recipe) => action.data === recipe.id
      );

      return {
        ...state,
        selectedRecipes: [...selectedRecipes],
        recipes: [...state.recipes, ...deselectedRecipes],
      };
    }

    /* ----------------------------------------------------------------- */
    case ACTIONS.RECIPES.MOVE_TO_TOP: {
      const { id, data: modifiedData } = action.data;
      const dataWithID = state.recipes.find((el) => el.id === id);
      const updatedDataWithoutID = state.recipes.filter((el) => el.id !== id);
      const updatedData = [
        { ...dataWithID, ...modifiedData },
        ...updatedDataWithoutID,
      ];

      return { ...state, recipes: [...updatedData] };
    }

    case ACTIONS.RECIPES.REMOVE_RECIPE: {
      const { id } = action.data;
      const updatedData = state.recipes.filter((el) => el.id !== id);
      const updatedCount = state.totalCount - 1;

      return { ...state, recipes: [...updatedData], totalCount: updatedCount };
    }

    default: {
      return state;
    }
  }
};

// ----------------------------------------------------------------------------|
//                            Recipes Reducer Export
// ----------------------------------------------------------------------------|
export default Recipes;
