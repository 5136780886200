/**
 * ************************************
 *
 * @module  CobTable.js
 * @author  Matt P
 * @date    03/04/2021
 * @description Table component for the Categories and Filters Tab. Does not
 * have the accordion feature of the original build
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import CobTableRow from './CobTableRow/CobTableRow';

import './CobTable.scss';

// ----------------------------------------------------------------------------|
//                    React Function Component - CobTable
// ----------------------------------------------------------------------------|
const CobTable = ({
  cols,
  rows,
  className,
  toggleFeatured,
  toggleEnable,
  deleteCategory,
  showEditModal,
  invokeParentFilter,
  filterLocationsDropdownOptions,
}) => {
  const rowsKeys = Object.keys(rows);
  const classNames = cx('cob-interactive-table', className);

  /**
   * @description Renders the table headers/col headers
   *
   * @param {Object} col - Column info
   * @param {Number} index
   *
   * @returns {JSX}
   */
  const buildTableHead = (col, index) => (
    <th key={`${col.value}-head-${index}`}>{col.label.toUpperCase()}</th>
  );

  /**
   * @description handled the table and rows toggle switches
   * enabling or disabling featured and enabled status
   *
   * @param {Object} row - cat for the respective row
   * @param {String} column - which column the toggle is clicked
   *
   * @returns {JSX}
   */
  const onToggleSwitch = (row, column) => {
    if (column === 'is_featured') {
      toggleFeatured(row);
    } else if (column === 'is_enabled' || column === 'status') {
      toggleEnable(row);
    }
  };

  /**
   * @description  CobTableRow injector
   *
   * @returns {JSX}
   */
  const injectTableRow = (row, columns, tagsArray, index) => (
    <CobTableRow
      rowClasses="row-container"
      key={`column-${index}-${row.id}`}
      row={row}
      cols={columns}
      tags={tagsArray}
      onToggleSwitch={onToggleSwitch}
      showEditModal={showEditModal}
      onDeleteCategory={deleteCategory}
      invokeParentFilter={invokeParentFilter}
      deleteDisabledTooltip="At least one child category is mandatory!"
      filterLocationsDropdownOptions={filterLocationsDropdownOptions}
    />
  );

  /**
   * @description Makes columns based on level of the rows
   *
   * @returns {Object[]}
   */
  const makeColumns = () => {
    const resultColumns = [];

    cols.forEach((col) => {
      const newCol = { ...col };

      resultColumns.push(newCol);
    });

    return resultColumns;
  };

  /**
   * @description builds table rows
   *
   * @returns {JSX[]}
   */
  const buildRows = () => {
    const columns = makeColumns();

    return rows.map((row, index) => {
      const tags = row.parent_categories || row.categories_in_filter || [];

      return (
        <React.Fragment key={`frag-table-${row.id}`}>
          {injectTableRow(row, columns, tags, index)}
        </React.Fragment>
      );
    });
  };

  return (
    <div className={classNames}>
      {rowsKeys && (
        <table className="cob-table">
          <thead>
            <tr>{cols.map((col, index) => buildTableHead(col, index))}</tr>
          </thead>
          <tbody>{buildRows()}</tbody>
        </table>
      )}
    </div>
  );
};

// --------------------------------------------------------------------------|
//                            PropTypes Check
// --------------------------------------------------------------------------|
CobTable.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.array.isRequired,
  cols: PropTypes.array.isRequired,
  toggleFeatured: PropTypes.func.isRequired,
  toggleEnable: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  showEditModal: PropTypes.func,
  invokeParentFilter: PropTypes.func,
  filterLocationsDropdownOptions: PropTypes.array
};

// --------------------------------------------------------------------------|
//                            DefaultProps
// --------------------------------------------------------------------------|
CobTable.defaultProps = {
  className: null,
  invokeParentFilter: () => {},
  showEditModal: () => {},
};

// --------------------------------------------------------------------------|
//                                Export
// --------------------------------------------------------------------------|
export default CobTable;
