/**
 * ************************************
 *
 * @module  Cities.reducer.ts
 * @author  Matt P
 * @date    04/25/2022
 * @description Cities reducer file
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

import { INeighborhoods } from 'interfaces/apiDataInterfaces';

import { mapAndReplace } from 'containers/Cities/CitiesUtils';

import { ActionReturnType } from '../actions/Cities.action';

type CitiesState = {
  data: any[];
  cities: any[];
  cityToEdit: INeighborhoods | null;
  initialDataLoaded: boolean;
};

// ----------------------------------------------------------------------------|
//                               Initial State
// ----------------------------------------------------------------------------|
const initialData = {
  data: [],
  cities: [],
  cityToEdit: null,
  initialDataLoaded: false,
};

// ----------------------------------------------------------------------------|
//                              Cities Reducer
// ----------------------------------------------------------------------------|
const Cities = (
  state: CitiesState = initialData,
  action: ActionReturnType
): CitiesState => {
  const { type } = action;

  switch (type) {
    /**
     * @description upon successful fetch of cities http list
     */
    case ACTIONS.CITIES.GET_LIST_SUCCESS: {
      const data = action.data.cities;
      const updatedData = [...state.data, ...data];

      return {
        ...state,
        data: updatedData,
        cities: [...data],
        initialDataLoaded: true,
      };
    }

    /**
     * @description upon unsuccessful fetch cities http list
     */
    case ACTIONS.CITIES.GET_LIST_FAIL: {
      return { ...state, initialDataLoaded: true };
    }

    /**
     * @description clears list
     */
    case ACTIONS.CITIES.CLEAR_LIST: {
      return {
        ...state,
        data: [],
        cities: [],
        initialDataLoaded: false,
      };
    }

    /**
     * @description delete success
     */
    case ACTIONS.CITIES.DELETE_CITY_SUCCESS: {
      const { data, cityToEdit } = state;
      const { data: city, parentCityId } = action;
      // filters the city we just deleted from the list
      let editedCities;
      let editedCity = null;

      if (parentCityId !== undefined && typeof parentCityId === 'string') {
        editedCities = data.map((cit) => {
          if (
            cityToEdit &&
            Array.isArray(cityToEdit.subareas) &&
            cit.id === parentCityId
          ) {
            editedCity = {
              ...cityToEdit,
              subareas: cityToEdit.subareas.filter((c) => c.id !== city.id),
            };

            return editedCity;
          }

          return cit;
        });
      } else {
        editedCities = [...data].filter((cit) => cit.id !== city.id);
      }

      return {
        ...state,
        data: editedCities,
        cities: editedCities,
        cityToEdit: editedCity === null ? state.cityToEdit : editedCity,
      };
    }

    /**
     * @description create city success
     */
    case ACTIONS.CITIES.CREATE_CITY_SUCCESS: {
      const { responseData: city, parentCityId } = action;
      const citiesCopy = [...state.data];
      let editedCity;

      if (parentCityId !== undefined && typeof parentCityId === 'string') {
        for (let i = 0; i < citiesCopy.length; i += 1) {
          if (citiesCopy[i].id === parentCityId) {
            citiesCopy[i].subareas.push(city);
            editedCity = citiesCopy[i];
          }
        }
      }
      // new city may not have an areas array if not add it
      else if (parentCityId === undefined && !city.subareas) {
        city.subareas = [];
        citiesCopy.push(city);
      }

      return {
        ...state,
        data: citiesCopy,
        cities: citiesCopy,
        cityToEdit: editedCity === null ? state.cityToEdit : editedCity,
      };
    }

    /**
     * @description edit city success
     */
    case ACTIONS.CITIES.EDIT_CITY_SUCCESS: {
      const { cities } = state;
      const { data: city, parentCityId } = action;
      let mappedCities;
      let editedCity = null;

      if (parentCityId !== undefined && typeof parentCityId === 'string') {
        mappedCities = cities.map((cit) => {
          if (
            cit.id === parentCityId &&
            cit.subareas &&
            Array.isArray(cit.subareas)
          ) {
            editedCity = {
              ...cit,
              subareas: mapAndReplace(cit.subareas, city),
            };

            return editedCity;
          }

          return cit;
        });
      } else {
        mappedCities = cities.map((cit) => {
          if (city.id === cit.id) {
            return {
              ...cit,
              name: city.name,
              status: city.status,
            };
          }

          return cit;
        });
      }

      return {
        ...state,
        data: mappedCities,
        cities: mappedCities,
        cityToEdit: editedCity === null ? state.cityToEdit : editedCity,
      };
    }

    /**
     * @description toggle enable/disable city success
     */
    case ACTIONS.CITIES.TOGGLE_ENABLE_SUCCESS: {
      const { cities } = state;
      const { data: city, parentCityId } = action;
      let mappedCities;
      let editedCity = null;

      if (parentCityId !== undefined && typeof parentCityId === 'string') {
        mappedCities = cities.map((cit) => {
          if (
            cit.id === parentCityId &&
            cit.subareas &&
            Array.isArray(cit.subareas)
          ) {
            editedCity = {
              ...cit,
              subareas: mapAndReplace(cit.subareas, city),
            };

            return editedCity;
          }

          return cit;
        });
      } else {
        mappedCities = cities.map((cit) => {
          if (city.id === cit.id) {
            return {
              ...cit,
              status: city.status,
            };
          }

          return cit;
        });
      }

      return {
        ...state,
        data: mappedCities,
        cities: mappedCities,
        cityToEdit: editedCity === null ? state.cityToEdit : editedCity,
      };
    }

    /**
     * @description Any local state state saving
     */
    case ACTIONS.CITIES.SET_CITY_TO_EDIT: {
      const { data } = action;

      return {
        ...state,
        cityToEdit: data,
      };
    }

    /**
     * @description Any local state state saving
     */
    case ACTIONS.CITIES.CLEAR_CITY_TO_EDIT: {
      return {
        ...state,
        cityToEdit: null,
      };
    }

    case ACTIONS.CITIES.AJAX_FAIL: {
      return state;
    }

    default: {
      return state;
    }
  }
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default Cities;
