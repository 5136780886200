/**
 * ************************************
 *
 * @module  Filters.reducer.js
 * @author  Matt P
 * @date    03/22/2021
 * @description Filters reducer file
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

import { mapAndReplace } from 'containers/Filters/FiltersUtils';

// ----------------------------------------------------------------------------|
//                               Initial State
// ----------------------------------------------------------------------------|
const initialData = {
  data: [],
  parents: [],
  filters: [],
  filterRules: [],
  dropdownFilterParentSlug: 'ALL',
  initialDataLoaded: false,
  filterRulesDataDownloaded: false,
};

// ----------------------------------------------------------------------------|
//                              Filters Reducer
// ----------------------------------------------------------------------------|
const Filters = (state = initialData, action = {}) => {
  switch (action.type) {
    /**
     * @description upon successful fetch of filters http list
     */
    case ACTIONS.FILTERS.GET_LIST_SUCCESS: {
      const data = action.data.app_filters;
      const updatedData = [...state.data, ...data];

      return {
        ...state,
        data: updatedData,
        filters: [...data],
        initialDataLoaded: true,
      };
    }

    /**
     * @description upon unsuccessful fetch of filters http list
     */
    case ACTIONS.FILTERS.GET_LIST_FAIL: {
      return { ...state, initialDataLoaded: true };
    }

    /**
     * @description upon successful fetch of filter rules http list
     */
    case ACTIONS.FILTERS.GET_RULES_LIST_SUCCESS: {
      const filterRules = action.data.categories_filter_rules;
      const updatedData = [...state.filterRules, ...filterRules];

      return {
        ...state,
        filterRules: updatedData,
        filterRulesDataDownloaded: true,
      };
    }

    /**
     * @description upon unsuccessful fetch of filter rules http list
     */
    case ACTIONS.FILTERS.GET_RULES_LIST_FAIL: {
      return { ...state, filterRulesDataDownloaded: true };
    }

    /**
     * @description clears filter list
     */
    case ACTIONS.FILTERS.CLEAR_LIST: {
      return {
        ...state,
        data: [],
        filters: [],
        filterRules: [],
        initialDataLoaded: false,
      };
    }

    /**
     * @description delete success
     */
    case ACTIONS.FILTERS.DELETE_FILTER_SUCCESS: {
      const { data: filter } = action;
      // filters the filter we just deleted from the list
      const filters = [...state.data].filter((fil) => fil.name !== filter.name);

      return {
        ...state,
        data: filters,
        filters,
      };
    }

    /**
     * @description create filter success
     */
    case ACTIONS.FILTERS.CREATE_FILTER_SUCCESS: {
      const { responseData: filter } = action;
      const filters = [...state.data, ...[filter.app_filter]];

      return {
        ...state,
        data: filters,
        filters,
      };
    }

    /**
     * @description edit filter success
     */
    case ACTIONS.FILTERS.EDIT_FILTER_SUCCESS: {
      const { data } = state;
      const { data: filter } = action;
      const filters = mapAndReplace(data, filter.app_filter);

      return {
        ...state,
        data: filters,
        filters,
      };
    }

    /**
     * @description toggle enable/disable filter success
     */
    case ACTIONS.FILTERS.TOGGLE_ENABLE_SUCCESS: {
      const { data } = state;
      const { data: filter } = action;
      const filters = mapAndReplace(data, filter.app_filter);

      return {
        ...state,
        data: filters,
        filters,
      };
    }

    /**
     * @description toggle feature/un-feature filter success
     */
    case ACTIONS.FILTERS.TOGGLE_FEATURE_SUCCESS: {
      const { data } = state;
      const { data: filter } = action;
      const filters = mapAndReplace(data, filter.app_filter);

      return {
        ...state,
        data: filters,
        filters,
      };
    }

    /**
     * @description Any local state state saving
     */
    case ACTIONS.FILTERS.SAVE_DATA_TO_REDUX: {
      const { data } = action;
      const updatedData = { ...data };

      return {
        ...state,
        data: updatedData,
      };
    }

    case ACTIONS.FILTERS.AJAX_FAIL: {
      return state;
    }

    default: {
      return state;
    }
  }
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default Filters;
