/**
 * ************************************
 *
 * @module  CobDatePicker.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Datepicker functional component leveraging React-Hooks.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import 'components/CobDatePicker/CobDatePicker.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
/**
 * @description This is a functional component which renders a date picker
 * (Calender View).
 *
 * @param {Object} props
 *
 * @argument {String} name:
 * @argument {Function} onChange: function to fire onChange of date
 * @argument {String || Date} selectedDate: String or Date object for
 * selected date
 * @argument {String} placeholder: Placeholder text. Default: ''
 * @argument {String} dateFormat: Format of Date - Default: 'd MMM yyyy',
 * @argument {Date} minimumDate: Date object - Default: Current Date
 *
 * @returns {JSX} HTML components to be rendered to the DOM
 */
const CobDatePicker = ({
  name,
  onChange,
  selectedDate,
  placeholder,
  dateFormat,
  minimumDate,
}) => {
  const [selectedValue, setSelectedValue] = useState(selectedDate);

  const handleChange = (date) => {
    setSelectedValue(date);
    onChange(name, date);
  };

  return (
    <DatePicker
      dateFormat={dateFormat}
      selected={selectedValue}
      onChange={handleChange}
      calendarClassName="custom-calendar"
      dayClassName={(date) => 'custom-day'}
      minDate={minimumDate}
      placeholderText={placeholder}
    />
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
CobDatePicker.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  selectedDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
  ]),
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  minimumDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
  ]),
};

// ----------------------------------------------------------------------------|
//                      Default Props - CobDatePicker
// ----------------------------------------------------------------------------|
CobDatePicker.defaultProps = {
  selectedDate: null,
  placeholder: '',
  dateFormat: 'd MMM yyyy',
  minimumDate: new Date(),
};

// ----------------------------------------------------------------------------|
//                        CobDatePicker Export
// ----------------------------------------------------------------------------|
export default CobDatePicker;
