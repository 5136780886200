/**
 * ************************************
 *
 * @module  AmountFor.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Location price functional component, has both a text input and
 * dropdown. Used as a form input
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import { DropDown, Input } from 'components';

import 'components/AmountFor/AmountFor.scss';

// ----------------------------------------------------------------------------|
//                    React Function Component - AmountFor
// ----------------------------------------------------------------------------|
const AmountFor = (props) => {
  const { errors, amount, amount_max, per, onChange } = props;
  const forOptions = [
    { value: 'person', label: 'Per Person' },
    { value: 'couple', label: 'Per Couple' },
  ];

  return (
    <React.Fragment>
      <div className="amount-people-container">
        <div className="amount-container">
          <label className="hours-range-label">Price amount</label>
          <Input
            type="text"
            name="event_amount"
            value={amount}
            onChange={onChange}
            placeholder="$ 0.00"
          />
        </div>
        <div className="amount-container">
          <label className="hours-range-label">Max price amount (optional)</label>
          <Input
            type="text"
            name="event_amount_max"
            value={amount_max}
            onChange={onChange}
            placeholder="$ 0.00"
          />
        </div>
        <div className="for-container">
          <label className="hours-range-label">For</label>
          <DropDown
            name="event_amount_suffix"
            value={per || forOptions[0]}
            options={forOptions}
            onSelect={(event) => onChange(event, 'event_amount_suffix')}
          />
        </div>
      </div>
      <div className="error">{errors}</div>
    </React.Fragment>
  );
};

// ----------------------------------------------------------------------------|
//                        PropTypes Check - AmountFor
// ----------------------------------------------------------------------------|
AmountFor.propTypes = {
  errors: PropTypes.string,
  amount: PropTypes.string,
  per: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

AmountFor.defaultProps = {
  per: '',
  errors: '',
  amount: '',
};

// ----------------------------------------------------------------------------|
//                              AmountFor Export
// ----------------------------------------------------------------------------|
export default AmountFor;
