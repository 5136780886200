/**
 * ************************************
 *
 * @module  constants.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description File for constant strings used throughout CMS
 *
 * ************************************
 */
/* eslint-disable max-len */
/* eslint-disable quotes */
// ----------------------------------------------------------------------------|
//                                    Exports
// ----------------------------------------------------------------------------|
export const adminSession = 'adminSession';

export const IMAGE_VALIDATION_MESSAGE = 'Images are mandatory';

const GOOGLE_API_KEY = 'AIzaSyCb6Bzsf108Jpv78T9unA8hzZOItY8BbRE';

export const PLACES_AUTOCOMPLETE_API_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;

export const DEFAULT_AUTH_ROUTE = '/collections/published';

export const DEFAULT_PAGE_SIZE = 10; // for pagination

export const LONG_DESC_ROWS = 12;
export const ONE_LINER_DESC_ROWS = 5;

export const METADATA_CONCEPTS_TOTAL = 7;
export const MAX_IMAGE_UPLOADS = 10;
export const MAX_IMAGE_WIDTH = 1440; // px
export const MAX_IMAGE_HEIGHT = 1440; // px
// export const MIN_IMAGE_WIDTH =
// export const MIN_IMAGE_HEIGHT =

export const TIME_FORMATS = {
  time: 'hh:mm a',
};

export const DATE_FORMATS = {
  stringWithYear: 'MMM D YYYY', // Jan 10 1997
  stringWithoutYear: 'MMM D', // Jan 10
  isoDateTime: 'YYYY-MM-DDTHH:mm:ss.sssZ',
};

export const CARDS_NAV_TABS = {
  PUBLISHED: {
    NAME: 'Published',
  },
  DRAFT: {
    NAME: 'Draft',
  },
  EXPIRED: {
    NAME: 'Expired',
  },
};

export const VERTICAL_TYPE = {
  ACTIVITY: 'activity',
  CATEGORY: 'category',
  CITIES: 'cities',
  COLLECTION: 'collection',
  CURATED: 'curated',
  EVENT: 'event',
  FILTER: 'filter',
  FLAGGED: 'flagged',
  MOVIE: 'movie',
  PLACE: 'place',
  RECIPE: 'recipe',
  UNIVERSAL_SEARCH: 'universalSearch',
};

export const REVIEW_AND_PUBLISH = 'REVIEW & PUBLISH';

export const FILTER_DROP_DOWN = {
  DATE_RANGE: {
    LABEL: 'DateRange',
    RETURN_TYPE: 'dateRange',
  },
  FLAGGED_REASON: {
    LABEL: 'Flagged Reason',
    RETURN_TYPE: 'flaggedReason',
  },
  LOCATION: {
    LABEL: 'Neighborhood',
    RETURN_TYPE: 'neighborhood',
  },
  CITIES: {
    LABEL: 'Cities',
    RETURN_TYPE: 'city',
  },
  ORDER: {
    LABEL: 'Order By',
    RETURN_TYPE: 'orderBy',
  },
  PRICE: {
    LABEL: 'Price',
    RETURN_TYPE: 'price',
  },
  RATING: {
    LABEL: 'Rating',
    RETURN_TYPE: 'rating',
  },
  REVIEW_STATUS: {
    LABEL: 'Review Status',
    RETURN_TYPE: 'reviewStatus',
  },
  TYPE: {
    LABEL: 'Category',
    RETURN_TYPE: 'category',
  },
};

export const LOGIN = {
  INFO: {
    TAG_LINE: "We're all about life after the swipe.",
  },
  CARD: {
    TITLE: 'Login',
    INPUTS: [
      { id: 1, type: 'email', name: 'email', placeholder: 'Email ID' },
      { id: 2, type: 'password', name: 'password', placeholder: 'Password' },
    ],
    BUTTON_TEXT: 'Login',
    WARNING_MESSAGE:
      'Oops, the email or password you entered is not correct. Please try again.',
  },
};

export const ACTIVITY = {
  REVIEW_AND_PUBLISH: 'REVIEW & PUBLISH',
  CARD_VIEW_TITLE: 'Create New Recreation',
  CARD_VIEW_TITLE_EDIT: 'Edit Recreation',
  FILTER_DROP_DOWN: {
    LOCATION: {
      LABEL: 'Neighborhood',
      RETURN_TYPE: 'neighborhood',
    },
    PRICE: {
      LABEL: 'Price',
      RETURN_TYPE: 'price',
    },
    RATING: {
      LABEL: 'Rating',
      RETURN_TYPE: 'rating',
    },
    TYPE: {
      LABEL: 'Category',
      RETURN_TYPE: 'category',
    },
    ORDER: {
      LABEL: 'Order By',
      RETURN_TYPE: 'orderBy',
    },
    REVIEW_STATUS: {
      LABEL: 'Review Status',
      RETURN_TYPE: 'reviewStatus',
    },
  },
  IMAGE_VALIDATION_MESSAGE: 'Images are mandatory',
  TAB_DATA: [
    { name: 'published', url: '/recreation/published' },
    { name: 'drafts', url: '/recreation/drafts' },
    { name: 'archived', url: '/recreation/archived' },
  ],
  WARNING_ON_DRAFT:
    'Curated cards having this recreation will also move to drafts and will not show up in the app.',
  WARNING_ON_DELETE:
    'Curated cards having this recreation will move to drafts and will not show up in the app.',
};

export const COLLECTION = {
  REVIEW_AND_PUBLISH: 'REVIEW & PUBLISH',
  CARD_VIEW_TITLE: 'Create New Collection',
  CARD_VIEW_TITLE_EDIT: 'Edit Collection',
  FILTER_DROP_DOWN: {
    ORDER: {
      LABEL: 'Order By',
      RETURN_TYPE: 'orderBy',
    },
    REVIEW_STATUS: {
      LABEL: 'Review Status',
      RETURN_TYPE: 'reviewStatus',
    },
  },
  TAB_DATA: [
    { name: 'published', url: '/collections/published' },
    { name: 'drafts', url: '/collections/drafts' },
  ],
  CARD_SELECTION_MODAL: {
    FILTER_DROP_DOWN: {
      CARD_STATUS: {
        LABEL: 'Card Status',
        RETURN_TYPE: 'cardStatus',
      },
      CATEGORY: {
        LABEL: 'Category',
        RETURN_TYPE: 'category',
      },
      CURRENT_TAB: {
        LABEL: 'Current Tab',
        RETURN_TYPE: 'currentTab',
      },
      LOCATION: {
        LABEL: 'Neighborhood',
        RETURN_TYPE: 'neighborhood',
      },
      VIEW_TYPE: {
        LABEL: 'View Type',
        RETURN_TYPE: 'viewType',
      },
    },
  },
  WARNING_ON_DRAFT: 'Are you sure you want to move this collection to draft?',
  WARNING_ON_DELETE: 'are you sure you want to archive this collection',
};

export const RECIPE = {
  REVIEW_AND_PUBLISH: 'REVIEW & PUBLISH',
  CARD_VIEW_TITLE: 'Create New Recipe',
  CARD_VIEW_TITLE_EDIT: 'Edit Recipe',
  FILTER_DROP_DOWN: {
    RATING: {
      LABEL: 'Rating',
      RETURN_TYPE: 'rating',
    },
    TYPE: {
      LABEL: 'Category',
      RETURN_TYPE: 'category',
    },
    ORDER: {
      LABEL: 'Order By',
      RETURN_TYPE: 'orderBy',
    },
    REVIEW_STATUS: {
      LABEL: 'Review Status',
      RETURN_TYPE: 'reviewStatus',
    },
  },
  IMAGE_VALIDATION_MESSAGE: 'Images are mandatory',
  TAB_DATA: [
    { name: 'published', url: '/recipes/published' },
    { name: 'drafts', url: '/recipes/drafts' },
    { name: 'archived', url: '/recipes/archived' },
  ],
  WARNING_ON_DRAFT:
    'Curated cards having this recipe will also move to drafts and will not show up in the app.',
  WARNING_ON_DELETE:
    'Curated cards having this recipe will move to drafts and will not show up in the app.',
};

export const PLACE = {
  REVIEW_AND_PUBLISH: 'REVIEW & PUBLISH',
  CARD_VIEW_TITLE: 'Create New Place',
  CARD_VIEW_TITLE_EDIT: 'Edit Place',
  FILTER_DROP_DOWN: {
    LOCATION: {
      LABEL: 'Neighborhood',
      RETURN_TYPE: 'neighborhood',
    },
    PRICE: {
      LABEL: 'Price',
      RETURN_TYPE: 'price',
    },
    RATING: {
      LABEL: 'Rating',
      RETURN_TYPE: 'rating',
    },
    TYPE: {
      LABEL: 'Category',
      RETURN_TYPE: 'category',
    },
    DATE_RANGE: {
      LABEL: 'DateRange',
      RETURN_TYPE: 'dateRange',
    },
    REVIEW_STATUS: {
      LABEL: 'Review Status',
      RETURN_TYPE: 'reviewStatus',
    },
  },
  IMAGE_VALIDATION_MESSAGE: 'Images are mandatory',
  TAB_DATA: [
    { name: 'published', url: '/places/published' },
    { name: 'drafts', url: '/places/drafts' },
    { name: 'inactive', url: '/places/inactive' },
    { name: 'archived', url: '/places/archived' },
    // { name: 'fetched', url: '/places/fetched' },
  ],
  WARNING_ON_DRAFT:
    'Curated cards having this place will also move to drafts and will not show up in the app.',
  WARNING_ON_DELETE:
    'Curated cards having this place will move to drafts and will not show up in the app.',
};

export const MOVIE = {
  REVIEW_AND_PUBLISH: 'REVIEW & PUBLISH',
  CARD_VIEW_TITLE: 'Create New Movie',
  CARD_VIEW_TITLE_EDIT: 'Edit Movie',
  FILTER_DROP_DOWN: {
    STATUS: {
      LABEL: 'Production Status',
      RETURN_TYPE: 'productionStatus',
    },
    LANGUAGE: {
      LABEL: 'Original Language',
      RETURN_TYPE: 'originalLanguage',
    },
    RATING: {
      LABEL: 'Rating',
      RETURN_TYPE: 'rating',
    },
    TYPE: {
      LABEL: 'Category',
      RETURN_TYPE: 'category',
    },
    DATE_RANGE: {
      LABEL: 'DateRange',
      RETURN_TYPE: 'dateRange',
    },
    REVIEW_STATUS: {
      LABEL: 'Review Status',
      RETURN_TYPE: 'reviewStatus',
    },
  },
  IMAGE_VALIDATION_MESSAGE: 'Images are mandatory',
  TAB_DATA: [
    { name: 'published', url: '/movies/published' },
    { name: 'drafts', url: '/movies/drafts' },
    { name: 'archived', url: '/movies/archived' },
    // { name: 'fetched', url: '/movies/fetched' },
  ],
  WARNING_ON_DRAFT:
    'Curated cards having this place will also move to drafts and will not show up in the app.',
  WARNING_ON_DELETE:
    'Curated cards having this place will move to drafts and will not show up in the app.',
};

export const CURATED = {
  TAB_DATA: [
    { name: 'published', url: '/curated/published' },
    { name: 'drafts', url: '/curated/drafts' },
    { name: 'expired', url: '/curated/expired' },
  ],
  CARD_VIEW_TITLE: 'New Curated Card',
  CARD_VIEW_TITLE_EDIT: 'Edit Curated Card',
  TOAST: {
    DELETE_SUCCESS_MESSAGE: 'Card has been deleted successfuly',
    DELETE_FAILURE_MESSAGE: 'Card deletion failed',
    FETCH_FAILURE_MESSAGE: 'Failed to fetch card detail',
  },
  CARD_VALIDATION_MESSAGE: 'Curated Cards require two Places/Events',
  CARD_NOT_VALID_TO_PUBLISH:
    'All selected Places/Events should be published before publishing this curated card',
  CARD_LIST_TAB_DATA: {
    PLACES: {
      name: 'PLACES',
      displayName: 'places',
    },
    EVENTS: {
      name: 'EVENTS',
      displayName: 'events',
    },
  },
};

export const EVENTS = {
  TITLE: 'Events',
  TAB_DATA: [
    { name: 'published', url: '/events/published' },
    { name: 'drafts', url: '/events/drafts' },
    { name: 'expired', url: '/events/expired' },
    { name: 'archived', url: '/events/archived' },
  ],
  FILTER_DROP_DOWN: {
    LOCATION: {
      LABEL: 'Neighborhood',
      RETURN_TYPE: 'neighborhood',
    },
    PRICE: {
      LABEL: 'Price',
      RETURN_TYPE: 'price',
    },
    RATING: {
      LABEL: 'Rating',
      RETURN_TYPE: 'rating',
    },
    TYPE: {
      LABEL: 'Category',
      RETURN_TYPE: 'category',
    },
    DATE_RANGE: {
      LABEL: 'DateRange',
      RETURN_TYPE: 'dateRange',
    },
    REVIEW_STATUS: {
      LABEL: 'Review Status',
      RETURN_TYPE: 'reviewStatus',
    },
  },

  CARD_VIEW_TITLE: 'New Event',
  CARD_VIEW_TITLE_EDIT: 'Edit Event',
  WARNING_ON_DRAFT:
    'Curated cards having this event will also move to drafts and will not show up in the app.',
  WARNING_ON_DELETE:
    'Curated cards having this event will move to drafts and will not show up in the app.',
};

export const CATEGORY = {
  TITLE: 'Categories',
  NAME_MAX_LENGTH: 50,
  NAME_ERR_MSG: 'Category name is mandatory',
  PARENT_ERR_MSG: 'Parent category is mandatory',
  ENABLE: 'ENABLE',
  FEATURE: 'FEATURE',
  DELETE: 'DELETE',
  NO_DATA: 'No category found. Please try again.',
  NEW_CATEGORY_TITLE: 'Add New Category',
  EDIT_CATEGORY_TITLE: 'Edit Category',
  TOAST: {
    FETCH_FAILURE_MESSAGE: 'Failed to fetch category detail',
    CREATE_CATEGORY_SUCCESS: 'Category created successfully',
    CREATE_CATEGORY_FAILURE: 'Category creation failed',
    EDIT_CATEGORY_SUCCESS: 'Category edited successfully',
    EDIT_CATEGORY_FAILURE: 'Failed to edit category',
    DELETE_SUCCESS_MESSAGE: 'Category has been deleted successfully',
    DELETE_FAILURE_MESSAGE: 'Category deletion failed',
    FEATURE_SUCCESS_MESSAGE: 'Category is now featuring',
    FEATURE_FAILURE_MESSAGE: 'Failed to make category featuring',
    UNFEATURE_SUCCESS_MESSAGE: 'Category is now not featuring',
    UNFEATURE_FAILURE_MESSAGE: 'Failed to unfeature the category',
    ENABLE_SUCCESS_MESSAGE: 'Enabling category is successful',
    ENABLE_FAILURE_MESSAGE: 'Failed to enable the category',
    DISABLE_SUCCESS_MESSAGE: 'Disabling category is successful',
    DISABLE_FAILURE_MESSAGE: 'Failed to disable the category',
  },
};

export const CITIES = {
  TITLE: 'Cities',
  NAME_MAX_LENGTH: 50,
  NAME_ERR_MSG: 'City name is mandatory',
  PARENT_ERR_MSG: 'Parent category is mandatory',
  COMING_SOON: 'coming_soon',
  UNAVAILABLE: 'unavailable',
  AVAILABLE: 'available',
  FEATURE: 'FEATURE',
  DELETE: 'DELETE',
  NO_DATA: 'No cities found. Please try again.',
  NEW_CITY_TITLE: 'Create New City',
  EDIT_CITY_TITLE: 'Edit City',
  NEW_NEIGHBORHOOD_TITLE: 'Add Neighborhood',
  EDIT_NEIGHBORHOOD_TITLE: 'Edit Neighborhood',
  TOAST: {
    FETCH_FAILURE_MESSAGE: 'Failed to fetch city detail',
    CREATE_CITY_SUCCESS: 'City created successfully',
    CREATE_CITY_FAILURE: 'City creation failed',
    EDIT_CITY_SUCCESS: 'City edited successfully',
    EDIT_CITY_FAILURE: 'Failed to edit city',
    DELETE_SUCCESS_MESSAGE: 'City has been deleted successfully',
    DELETE_FAILURE_MESSAGE: 'City deletion failed',
    ENABLE_SUCCESS_MESSAGE: 'Enabling city was successful',
    ENABLE_FAILURE_MESSAGE: 'Failed to enable city',
    DISABLE_SUCCESS_MESSAGE: 'Disabling city was successful',
    DISABLE_FAILURE_MESSAGE: 'Failed to disable the city',
  },
};

export const FILTER = {
  TITLE: 'Filters',
  NAME_MAX_LENGTH: 50,
  NAME_ERR_MSG: 'Filter name is mandatory',
  PARENT_ERR_MSG: 'Parent category is mandatory',
  ENABLE: 'ENABLE',
  FEATURE: 'FEATURE',
  DELETE: 'DELETE',
  NO_DATA: 'No filters found. Please try again.',
  NEW_FILTER_TITLE: 'Add New Filter',
  EDIT_FILTER_TITLE: 'Edit Filter',
  TOAST: {
    FETCH_FAILURE_MESSAGE: 'Failed to fetch filter detail',
    CREATE_FILTER_SUCCESS: 'Filter created successfully',
    CREATE_FILTER_FAILURE: 'Filter creation failed',
    EDIT_FILTER_SUCCESS: 'Filter edited successfully',
    EDIT_FILTER_FAILURE: 'Failed to edit filter',
    DELETE_SUCCESS_MESSAGE: 'Filter has been deleted successfully',
    DELETE_FAILURE_MESSAGE: 'Filter deletion failed',
    FEATURE_SUCCESS_MESSAGE: 'Filter is now featuring',
    FEATURE_FAILURE_MESSAGE: 'Failed to make filter featuring',
    FEATURE_ONLY_ONE_AT_A_TIME: 'Only one filter can be featured at a time',
    UNFEATURE_SUCCESS_MESSAGE: 'Filter is now not featuring',
    UNFEATURE_FAILURE_MESSAGE: 'Failed to unfeature the filter',
    ENABLE_SUCCESS_MESSAGE: 'Enabling filter is successful',
    ENABLE_FAILURE_MESSAGE: 'Failed to enable the filter',
    DISABLE_SUCCESS_MESSAGE: 'Disabling filter is successful',
    DISABLE_FAILURE_MESSAGE: 'Failed to disable the filter',
  },
};

export const GENERIC = {
  DELETE_WARNING_MESSAGE: 'Are you sure you want to delete this item?',
  DELETE_SUCCESS_MESSAGE: 'Deleted Successfully',
  BULK_ACTION_SUCCESS_MESSAGE: 'Updated successfully',
  DELETE_FAILURE_MESSAGE: 'These items could not be deleted',
  BULK_ACTION_FAILURE_MESSAGE: 'These items could not be updated',
  SUB_MENU: {
    EDIT: 'Edit',
    DELETE: 'Delete',
    PIN: 'Pin',
  },
  BULK_ACTIONS: {
    DELETE: 'Delete',
    PUBLISH: 'Publish now',
    DRAFT: 'Move to drafts',
    SELECT: 'Done',
  },
  IMAGE_UPLOAD_FAIL: 'Failed to upload image',
  ACTIVITY_SAVE_SUCCESS: 'Activity saved successfully',
  ACTIVITY_SAVE_FAIL: 'Activity save failed',
  EVENT_SAVE_SUCCESS: 'Event saved successfully',
  EVENT_SAVE_FAIL: 'Event save failed',
  MOVIE_SAVE_SUCCESS: 'Movie saved successfully',
  MOVIE_SAVE_FAIL: 'Movie save failed',
  PLACE_SAVE_SUCCESS: 'Place saved successfully',
  PLACE_SAVE_FAIL: 'Place save failed',
  RECIPE_SAVE_SUCCESS: 'Recipe saved successfully',
  RECIPE_SAVE_FAIL: 'Recipe saved failed',
  NO_DATA_FOUND_MESSAGE: "Oops! We're sorry.",
  NO_DATA_FOUND_SUB_MESSAGE:
    'we could not find any cards that match your search.',
  CURATED_CARD_SAVE_SUCCESS: 'Curated Card saved successfully',
  CURATED_CARD_SAVE_FAIL: 'Curated Card save failed',
  VALID_READ_MORE_LENGTH: 200,
  CLOSE_CARD_WARNING_MESSAGE: 'Are you sure you want to exit without saving?',
  PREVIEW_MODAL: {
    TABS: {
      CARD: 'card',
      DETAIL: 'detail',
    },
  },
  EXPAND: 'Expand',
  NEXT: 'NEXT',
  DONE: 'DONE',
  THIRD_PARTY_SEARCH_FAIL: 'Search failed',
  REINSTATE_SUCCESS_MESSAGE: 'Reinstated Successfully',
  REINSTATE_FAILURE_MESSAGE: 'These items could not be reinstated',
};

export const NEW_BUTTON_TEXT = {
  categories: 'ADD NEW CATEGORY',
  cities: 'ADD NEW CITY',
  collections: 'ADD NEW',
  curated_cards: 'CREATE NEW',
  events: 'ADD NEW',
  filters: 'ADD NEW FILTER',
  flagged: 'CREATE NEW',
  'movies_&_television': 'ADD NEW',
  places: 'CREATE NEW',
  recipes: 'ADD NEW',
  recreation: 'ADD NEW',
};

export const DATE_SELECTOR_POPUP = {
  DATE_FORMAT: 'DD MMM YYYY',
  TIME_FORMAT: 'hh:mm A',
  TIME_FORMAT_FOR_BACKEND: 'HH:mm:ss',
  LONG_DATE_FORMAT: 'YYYY-MM-DDTHH:mm:ss.sss',
  ADD_DATE: 'Add Date',
  SELECT_END_DATE: 'Select End Date',
  ENDS_ON: 'Ends On',
  REPEAT: 'Repeat',
  SELECT_AVAILABILITY: 'Select Availability',
  SELECT_DAYS: 'Select Days',
  RECURRING: 'Recurring',
  SELECT_HOURS: 'Select Hours',
  SELECT_TIME: 'Select Time',
  GO_BACK_TO_SELECT_DATE: 'Go back to select start date',
  $ADD_DATE: '+ Add date',
  $ADD_ANOTHER_DATE: '+ Add Another Date',
  DEFAULT_TIME: '00:00 AM',
  REMOVE_DATE_TIME: 'Remove date & time',
  DATE: 'Date',
  TIME_RANGE_ERROR: 'End time should be greater than the Start time',
  DATE_RANGE_ERROR: 'Start Date cannot be greater than end Date',
  WEEKDAY_RANGE_ERROR:
    "Event's Start & End Date range is too short for selected week days",
  MONTHLY_DATE_RANGE_ERROR:
    "Event's Start & End Date range is too short for monthly repetitions",
  DATE_OVERLAP_ERROR: 'Overlap found between these dates',
};

export const UNIVERSAL_SEARCH = {
  // no tab data for uni search
  TAB_DATA: [],
};

export const FLAGGED = {
  // no tab data for flagged
  TAB_DATA: [],
};
