/**
 * ************************************
 *
 * @module  Auth.saga.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description CMS Auth/Login saga file.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import { call, put } from 'redux-saga/effects';

import ACTIONS from 'store/actions/actionTypes';
import API from 'API';

// ----------------------------------------------------------------------------|
//                                Sagas - Auth
// ----------------------------------------------------------------------------|
/**
 * @description handles login POST request
 *
 * @param {Object} action - object passed from the redux action
 */
function* login(action) {
  const { postData } = window.axios;
  yield put({ type: ACTIONS.AUTH.LOGIN_IN_PROGRESS });
  try {
    const response = yield call(postData, API.login, action.data);

    if (response.status === 200) {
      yield put({ type: ACTIONS.AUTH.LOGIN_SUCCESS, data: response.data });
    } else {
      yield put({ type: ACTIONS.AUTH.LOGIN_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.AUTH.LOGIN_FAIL });
  }
}

/**
 * @description handles logout POST request
 */
function* logout() {
  const { postData } = window.axios;

  try {
    const response = yield call(postData, API.logout);

    if (response.status === 200) {
      yield put({ type: ACTIONS.AUTH.LOGOUT_SUCCESS, data: response.data });
    } else {
      yield put({ type: ACTIONS.AUTH.LOGOUT_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.AUTH.LOGOUT_FAIL });
  }
}

// ----------------------------------------------------------------------------|
//                                Exports
// ----------------------------------------------------------------------------|
export { login, logout };
