/**
 * ************************************
 *
 * @module  actionTypes.ts
 * @author  Vignesh D
 * @date    03/11/2020
 * @description redux actionTypes file
 *
 * ************************************
 */

// ----------------------------------------------------------------------------|
//                              ActionTypes Object
// ----------------------------------------------------------------------------|

const actionTypes = {
  AUTH: {
    LOGIN: 'AUTH-LOGIN',
    LOGIN_FAIL: 'AUTH-LOGIN_FAIL',
    LOGIN_SUCCESS: 'AUTH-LOGIN_SUCCESS',
    LOGIN_IN_PROGRESS: 'AUTH-LOGIN_IN_PROGRESS',
    LOGOUT: 'AUTH-LOGOUT',
    LOGOUT_FAIL: 'AUTH-LOGOUT_FAIL',
    LOGOUT_SUCCESS: 'AUTH-LOGOUT_SUCCESS',
  },

  // Universal containers
  VERTICAL_DISPLAY: {
    ADD_CARD: 'VERTICAL_DISPLAY-ADD_CARD',
    ADD_CARD_FAIL: 'VERTICAL_DISPLAY-ADD_CARD_FAIL',
    ADD_CARD_SUCCESS: 'VERTICAL_DISPLAY-ADD_CARD_SUCCESS',
    CLEAR_ADMIN_CATEGORIES: 'CLEAR_ADMIN_CATEGORIES', // needed?
    CLEAR_CARDS: 'VERTICAL_DISPLAY-CLEAR_CARDS',
    DELETE_CARD: 'VERTICAL_DISPLAY-DELETE_CARD',
    DELETE_CARD_FAIL: 'VERTICAL_DISPLAY-DELETE_CARD_FAIL',
    DELETE_CARD_SUCCESS: 'VERTICAL_DISPLAY-DELETE_CARD_SUCCESS',
    FETCH_NEIGHBORHOOD_LOCATIONS: 'FETCH_NEIGHBORHOOD_LOCATIONS',
    FETCH_NEIGHBORHOOD_LOCATIONS_FAIL: 'FETCH_NEIGHBORHOOD_LOCATIONS_FAIL',
    FETCH_NEIGHBORHOOD_LOCATIONS_SUCCESS:
      'FETCH_NEIGHBORHOOD_LOCATIONS_SUCCESS',
    FILTER_LIST: 'VERTICAL_DISPLAY-FILTER_LIST',
    GET_VERTICAL_DISPLAY_CARDS: 'VERTICAL_DISPLAY-GET_CARDS',
    GET_VERTICAL_DISPLAY_CARDS_FAIL: 'VERTICAL_DISPLAY-GET_CARDS_FAIL',
    GET_VERTICAL_DISPLAY_CARDS_SUCCESS: 'VERTICAL_DISPLAY-GET_CARDS_SUCCESS',
    MOVE_TO_TOP: 'VERTICAL_DISPLAY-MOVE_TO_TOP',
    REMOVE_CARD: 'VERTICAL_DISPLAY-REMOVE_CARD',
    UPDATE_SINGLE_CARD_PROPERTY: 'VERTICAL_DISPLAY-UPDATE_SINGLE_CARD_PROPERTY',
    UPDATE_SINGLE_CARD_FAIL:
      'VERTICAL_DISPLAY-UPDATE_SINGLE_CARD_PROPERTY_FAIL',
    UPDATE_SINGLE_CARD_SUCCESS:
      'VERTICAL_DISPLAY-UPDATE_SINGLE_CARD_PROPERTY_SUCCESS',
  },

  VERTICAL_FORM: {
    BUTTON_DISABLING: 'VERTICAL_FORM-BUTTON_DISABLING',
    CLEAR_IMAGES: 'VERTICAL_FORM-CLEAR_IMAGES',
    CLEAR_FORM_STATE: 'VERTICAL_FORM-CLEAR_FORM_STATE',
    DELETE_IMAGE: 'VERTICAL_FORM-DELETE_IMAGE',
    FETCH_VERTICAL_FORM_DETAIL: 'VERTICAL_FORM-FETCH_DETAIL',
    FETCH_VERTICAL_FORM_DETAIL_FAIL: 'VERTICAL_FORM-FETCH_DETAIL_FAIL',
    FETCH_VERTICAL_FORM_DETAIL_SUCCESS: 'VERTICAL_FORM-FETCH_DETAIL_SUCCESS',
    POPULATE_IMAGES: 'VERTICAL_FORM-POPULATE_IMAGES',
    REINSTATE_UNIT: 'VERTICAL_FORM-REINSTATE_UNIT',
    REINSTATE_UNIT_FAIL: 'VERTICAL_FORM-REINSTATE_UNIT_FAIL',
    REINSTATE_UNIT_SUCCESS: 'VERTICAL_FORM-REINSTATE_UNIT_SUCCESS',
    RE_ORDER_IMAGES: 'VERTICAL_FORM-RE_ORDER_IMAGES',
    RESET_CARD_SELECTION_STATE: 'VERTICAL_FORM-RESET_CARD_SELECTION_STATE',
    SAVE_DATA: 'VERTICAL_FORM-SAVE_DATA',
    SAVE_DATA_FAIL: 'VERTICAL_FORM-SAVE_DATA_FAIL',
    SAVE_DATA_SUCCESS: 'VERTICAL_FORM-SAVE_DATA_SUCCESS',
    SAVE_DATA_TO_REDUX: 'VERTICAL_FORM-SAVE_DATA_TO_REDUX',
    UPLOAD_IMAGE: 'VERTICAL_FORM-UPLOAD_IMAGE',
    UPLOAD_IMAGE_FAIL: 'VERTICAL_FORM-UPLOAD_IMAGE_FAIL',
    UPLOAD_IMAGE_SUCCESS: 'VERTICAL_FORM-UPLOAD_IMAGE_SUCCESS',
    CLEAR_CARD_SELECTION_SEARCH_LIST:
      'VERTICAL_FORM-CLEAR_CARD_SELECTION_SEARCH',
    DESELECT_CARD_LIST: 'VERTICAL_DISPLAY-DESELECT_CARD_LIST',
    GET_VERTICAL_CARD_SELECTION_CARDS: 'VERTICAL_FORM-GET_CARD_SELECTION_CARDS',
    GET_VERTICAL_CARD_SELECTION_CARDS_FAIL:
      'VERTICAL_FORM-GET_CARD_SELECTION_CARDS_FAIL',
    GET_VERTICAL_CARD_SELECTION_CARDS_SUCCESS:
      'VERTICAL_FORM-GET_CARD_SELECTION_CARDS_SUCCESS',
    POPULATE_COLLECTION_CARD_LIST:
      'VERTICAL_DISPLAY-POPULATE_COLLECTION_CARD_LIST',
    UPDATE_COLLECTION_CARD_LIST: 'VERTICAL_DISPLAY-UPDATE_COLLECTION_CARD_LIST',
    SELECT_CARD_LIST: 'VERTICAL_DISPLAY-SELECT_CARD_LIST',
  },

  CATEGORIES: {
    AJAX_FAIL: 'CATEGORY-AJAX_FAIL',
    CLEAR_LIST: 'CATEGORIES-CLEAR_LIST',
    CREATE_CATEGORY: 'CATEGORIES-CREATE_CATEGORY',
    CREATE_CATEGORY_SUCCESS: 'CATEGORIES-CREATE_CATEGORY_SUCCESS',
    DELETE_CATEGORY: 'CATEGORIES-DELETE_CATEGORY',
    DELETE_CATEGORY_SUCCESS: 'CATEGORIES-DELETE_CATEGORY_SUCCESS',
    EDIT_CATEGORY: 'CATEGORIES-EDIT_CATEGORY',
    EDIT_CATEGORY_SUCCESS: 'CATEGORIES-EDIT_CATEGORY_SUCCESS',
    GET_LIST: 'CATEGORIES-GET_LIST',
    GET_LIST_FAIL: 'CATEGORIES-GET_LIST_FAIL',
    GET_LIST_SUCCESS: 'CATEGORIES-GET_LIST_SUCCESS',
    QUERY_FILTER: 'CATEGORIES-QUERY_FILTER',
    PARENT_FILTER: 'CATEGORIES-PARENT_FILTER',
    SAVE_DATA_TO_REDUX: 'CATEGORY-SAVE_DATA_TO_REDUX',
    TOGGLE_ENABLE: 'CATEGORIES-TOGGLE_ENABLE',
    TOGGLE_ENABLE_SUCCESS: 'CATEGORIES-TOGGLE_ENABLE_SUCCESS',
    TOGGLE_FEATURE: 'CATEGORIES-TOGGLE_FEATURE',
    TOGGLE_FEATURE_SUCCESS: 'CATEGORIES-TOGGLE_FEATURE_SUCCESS',
  },

  CITIES: {
    AJAX_FAIL: 'CITIES-AJAX_FAIL',
    CLEAR_LIST: 'CITIES-CLEAR_LIST',
    CREATE_CITY: 'CITIES-CREATE_CITY',
    CREATE_CITY_SUCCESS: 'CITIES-CREATE_CITY_SUCCESS',
    DELETE_CITY: 'CITIES-DELETE_CITY',
    DELETE_CITY_SUCCESS: 'CITIES-DELETE_CITY_SUCCESS',
    EDIT_CITY: 'CITIES-EDIT_CITY',
    EDIT_CITY_SUCCESS: 'CITIES-EDIT_CITY_SUCCESS',
    GET_LIST: 'CITIES-GET_LIST',
    GET_LIST_FAIL: 'CITIES-GET_LIST_FAIL',
    GET_LIST_SUCCESS: 'CITIES-GET_LIST_SUCCESS',
    SAVE_DATA_TO_REDUX: 'CITY-SAVE_DATA_TO_REDUX',
    TOGGLE_ENABLE: 'CITIES-TOGGLE_ENABLE',
    TOGGLE_ENABLE_SUCCESS: 'CITIES-TOGGLE_ENABLE_SUCCESS',
    SET_CITY_TO_EDIT: 'CITIES-SET_CITY_TO_EDIT',
    CLEAR_CITY_TO_EDIT: 'CITIES-CLEAR_CITY_TO_EDIT',
  },

  CURATED_CARD: {
    BUTTON_DISBALING: 'CURATED_CARD-BUTTON_DISBALING',
    CLEAR_CARD_LIST: 'CURATED_CARD-CLEAR_CARD_LIST',
    CLEAR_DATA: 'CURATED_CARD-CLEAR_DATA',
    DELETE_IMAGE: 'CURATED_CARD-DELETE_IMAGE',
    DESELECT_CARD_LIST: 'CURATED_CARD-DESELECT_CARD_LIST',
    FETCH_CURATED_CARD: 'CURATED_CARDS-FETCH_CURATED_CARD',
    FETCH_CURATED_CARD_FAIL: 'CURATED_CARDS-FETCH_CURATED_CARD_FAIL',
    FETCH_CURATED_CARD_SUCCESS: 'CURATED_CARDS-FETCH_CURATED_CARD_SUCCESS',
    GET_CARD_LIST: 'CURATED_CARD-GET_CARD_LIST',
    GET_CARD_LIST_FAIL: 'CURATED_CARD-GET_CARD_LIST_FAIL',
    GET_CARD_LIST_SUCCESS: 'CURATED_CARD-GET_CARD_LIST_SUCCESS',
    POPULATE_IMAGES: 'CURATED_CARD-POPULATE_IMAGES',
    RE_ORDER_IMAGES: 'CURATED_CARD-RE_ORDER_IMAGES',
    SELECT_CARD_LIST: 'CURATED_CARD-SELECT_CARD_LIST',
    SAVE_DATA: 'CURATED_CARD-SAVE_DATA',
    SAVE_DATA_FAIL: 'CURATED_CARD-SAVE_DATA_FAIL',
    SAVE_DATA_SUCCESS: 'CURATED_CARD-SAVE_DATA_SUCCESS',
    SAVE_DATA_TO_REDUX: 'CURATED_CARD-SAVE_DATA_TO_REDUX',
    UPLOAD_IMAGE: 'CURATED_CARD-UPLOAD_IMAGE',
    UPLOAD_IMAGE_FAIL: 'CURATED_CARD-UPLOAD_IMAGE_FAIL',
    UPLOAD_IMAGE_SUCCESS: 'CURATED_CARD-UPLOAD_IMAGE_SUCCESS',
  },

  CURATED_CARDS: {
    BULK_ACTION: 'CURATED_CARDS-BULK_ACTION',
    BULK_ACTION_FAIL: 'CURATED_CARDS-BULK_ACTION_FAIL',
    BULK_ACTION_SUCCESS: 'CURATED_CARDS-BULK_ACTION_SUCCESS',
    CLEAR_LIST: 'CURATED_CARDS-CLEAR_LIST',
    DELETE_CURATED_CARDS: 'CURATED_CARDS-DELETE_CURATED_CARDS',
    DELETE_CURATED_CARDS_FAIL: 'CURATED_CARDS-DELETE_CURATED_CARDS_FAIL',
    DELETE_CURATED_CARDS_SUCCESS: 'CURATED_CARDS-DELETE_CURATED_CARDS_SUCCESS',
    FILTER_LIST: 'CURATED_CARDS-FILTER_LIST',
    GET_LIST: 'CURATED_CARDS-GET_LIST',
    GET_LIST_FAIL: 'CURATED_CARDS-GET_LIST_FAIL',
    GET_LIST_SUCCESS: 'CURATED_CARDS-GET_LIST_SUCCESS',
    MOVE_TO_TOP: 'CURATED_CARDS-MOVE_TO_TOP',
    REMOVE_CARD: 'CURATED_CARDS-REMOVE_CARD',
  },

  FILTERS: {
    AJAX_FAIL: 'FILTER-AJAX_FAIL',
    CLEAR_LIST: 'FILTERS-CLEAR_LIST',
    CREATE_FILTER: 'FILTERS-CREATE_FILTER',
    CREATE_FILTER_SUCCESS: 'FILTERS-CREATE_FILTER_SUCCESS',
    DELETE_FILTER: 'FILTERS-DELETE_FILTER',
    DELETE_FILTER_SUCCESS: 'FILTERS-DELETE_FILTER_SUCCESS',
    EDIT_FILTER: 'FILTERS-EDIT_FILTER',
    EDIT_FILTER_SUCCESS: 'FILTERS-EDIT_FILTER_SUCCESS',
    GET_LIST: 'FILTERS-GET_LIST',
    GET_LIST_FAIL: 'FILTERS-GET_LIST_FAIL',
    GET_LIST_SUCCESS: 'FILTERS-GET_LIST_SUCCESS',
    GET_RULE_LIST: 'FILTERS-GET_RULES_LIST',
    GET_RULES_LIST_FAIL: 'FILTERS-GET_RULES_LIST_FAIL',
    GET_RULES_LIST_SUCCESS: 'FILTERS-GET_RULES_LIST_SUCCESS',
    SAVE_DATA_TO_REDUX: 'FILTER-SAVE_DATA_TO_REDUX',
    TOGGLE_ENABLE: 'FILTERS-TOGGLE_ENABLE',
    TOGGLE_ENABLE_SUCCESS: 'FILTERS-TOGGLE_ENABLE_SUCCESS',
    TOGGLE_FEATURE: 'FILTERS-TOGGLE_FEATURE',
    TOGGLE_FEATURE_SUCCESS: 'FILTERS-TOGGLE_FEATURE_SUCCESS',
  },

  PLACE: {
    BUTTON_DISBALING: 'PLACE-BUTTON_DISBALING',
    CLEAR_IMAGES: 'PLACE-CLEAR_IMAGES',
    DELETE_IMAGE: 'PLACE-DELETE_IMAGE',
    FETCH_PLACE: 'PLACE-FETCH_PLACE',
    FETCH_PLACE_FAIL: 'PLACE-FETCH_PLACE_FAIL',
    FETCH_PLACE_SUCCESS: 'PLACE-FETCH_PLACE_SUCCESS',
    POPULATE_IMAGES: 'PLACE-POPULATE_IMAGES',
    RE_ORDER_IMAGES: 'PLACE-RE_ORDER_IMAGES',
    SAVE_DATA: 'PLACE-SAVE_DATA',
    SAVE_DATA_FAIL: 'PLACE-SAVE_DATA_FAIL',
    SAVE_DATA_SUCCESS: 'PLACE-SAVE_DATA_SUCCESS',
    SAVE_DATA_TO_REDUX: 'PLACE-SAVE_DATA_TO_REDUX',
    UPLOAD_IMAGE: 'PLACE-UPLOAD_IMAGE',
    UPLOAD_IMAGE_FAIL: 'PLACE-UPLOAD_IMAGE_FAIL',
    UPLOAD_IMAGE_SUCCESS: 'PLACE-UPLOAD_IMAGE_SUCCESS',
  },

  PLACES: {
    ADD_PLACES: 'PLACES-ADD_PLACES',
    ADD_PLACES_FAIL: 'PLACES-ADD_PLACES_FAIL',
    ADD_PLACES_SUCCESS: 'PLACES-ADD_PLACES_SUCCESS',
    BULK_ACTION: 'PLACES_BULK_ACTION',
    BULK_ACTION_SUCCESS: 'PLACES_BULK_ACTION_SUCCESS',
    BULK_ACTION_FAIL: 'PLACES_BULK_ACTION_FAIL',
    CLEAR_ADMIN_CATEGORIES: 'CLEAR_ADMIN_CATEGORIES',
    CLEAR_PLACES: 'PLACES-CLEAR_PLACES',
    CLEAR_SELECTED_PLACES: 'PlACES-CLEAR_SELECTED_PLACES',
    DELETE_PLACE: 'PLACES-DELETE_PLACE',
    DELETE_PLACE_FAIL: 'PLACES-DELETE_PLACE_FAIL',
    DELETE_PLACE_SUCCESS: 'PLACES-DELETE_PLACE_SUCCESS',
    FETCH_NEIGHBORHOOD_LOCATIONS: 'FETCH_NEIGHBORHOOD_LOCATIONS',
    FETCH_NEIGHBORHOOD_LOCATIONS_FAIL: 'FETCH_NEIGHBORHOOD_LOCATIONS_FAIL',
    FETCH_NEIGHBORHOOD_LOCATIONS_SUCCESS:
      'FETCH_NEIGHBORHOOD_LOCATIONS_SUCCESS',
    FETCH_ADMIN_CATEGORIES: 'FETCH_ADMIN_CATEGORIES',
    FETCH_ADMIN_CATEGORIES_FAIL: 'FETCH_ADMIN_CATEGORIES_FAIL',
    FETCH_ADMIN_CATEGORIES_SUCCESS: 'FETCH_ADMIN_CATEGORIES_SUCCESS',
    FILTER_LIST: 'PLACES-FILTER_LIST',
    GET_PLACES: 'PLACES-GET_PLACES',
    GET_PLACES_FAIL: 'PLACES-GET_PLACES_FAIL',
    GET_PLACES_SUCCESS: 'PLACES-GET_PLACES_SUCCESS',
    /* -------- Actions for CardSelector component --------- */
    DESELECT_PLACE: 'PLACES-DESELECT_PLACE',
    MOVE_TO_TOP: 'PLACES-MOVE_TO_TOP',
    REMOVE_PLACE: 'PLACES-REMOVE_PLACE',
    SELECT_PLACE: 'PLACES-SELECT_PLACE',
  },

  // *********************** DEPRECIATED ************************************ //

  ACTIVITIES: {
    ADD_ACTIVITIES: 'ACTIVITIES-ADD_ACTIVITIES',
    ADD_ACTIVITIES_FAIL: 'ACTIVITIES-ADD_ACTIVITIES_FAIL',
    ADD_ACTIVITIES_SUCCESS: 'ACTIVITIES-ADD_ACTIVITIES_SUCCESS',
    BULK_ACTION: 'ACTIVITIES-BULK_ACTION',
    BULK_ACTION_FAIL: 'ACTIVITIES-BULK_ACTION_FAIL',
    BULK_ACTION_SUCCESS: 'ACTIVITIES-BULK_ACTION_SUCCESS',
    CLEAR_ACTIVITIES: 'ACTIVITIES-CLEAR_ACTIVITIES',
    CLEAR_SELECTED_ACTIVITIES: 'ACTIVITIES-CLEAR_SELECTED_ACTIVITIES',
    DELETE_ACTIVITY: 'ACTIVITIES-DELETE_ACTIVITY',
    DELETE_ACTIVITY_FAIL: 'ACTIVITIES-DELETE_ACTIVITY_FAIL',
    DELETE_ACTIVITY_SUCCESS: 'ACTIVITIES-DELETE_ACTIVITY_SUCCESS',
    FILTER_LIST: 'ACTIVITIES-FILTER_LIST',
    GET_ACTIVITIES_FAIL: 'ACTIVITIES-GET_ACTIVITIES_FAIL',
    GET_ACTIVITIES_LIST: 'ACTIVITIES-GET_ACTIVITIES_LIST',
    // GET_ACTIVITIES_SEARCH: 'ACTIVITIES-GET_ACTIVITIES_SEARCH',
    GET_ACTIVITIES_SUCCESS: 'ACTIVITIES-GET_ACTIVITIES_SUCCESS',
    /* -------- Actions for CardSelector component --------- */
    DESELECT_ACTIVITY: 'ACTIVITIES-DESELECT_ACTIVITY',
    MOVE_TO_TOP: 'ACTIVITIES-MOVE_TO_TOP',
    REMOVE_ACTIVITY: 'ACTIVITIES-REMOVE_ACTIVITY',
    SELECT_ACTIVITY: 'ACTIVITIES-SELECT_ACTIVITY',
  },

  ACTIVITY: {
    BUTTON_DISBALING: 'ACTIVITY-BUTTON_DISBALING',
    CLEAR_IMAGES: 'ACTIVITY-CLEAR_IMAGES',
    DELETE_IMAGE: 'ACTIVITY-DELETE_IMAGE',
    FETCH_ACTIVITY: 'ACTIVITY-FETCH_ACTIVITY',
    FETCH_ACTIVITY_FAIL: 'ACTIVITY-FETCH_ACTIVITY_FAIL',
    FETCH_ACTIVITY_SUCCESS: 'ACTIVITY-FETCH_ACTIVITY_SUCCESS',
    POPULATE_IMAGES: 'ACTIVITY-POPULATE_IMAGES',
    RE_ORDER_IMAGES: 'ACTIVITY-RE_ORDER_IMAGES',
    SAVE_DATA: 'ACTIVITY-SAVE_DATA',
    SAVE_DATA_FAIL: 'ACTIVITY-SAVE_DATA_FAIL',
    SAVE_DATA_SUCCESS: 'ACTIVITY-SAVE_DATA_SUCCESS',
    SAVE_DATA_TO_REDUX: 'ACTIVITY-SAVE_DATA_TO_REDUX',
    UPLOAD_IMAGE: 'ACTIVITY-UPLOAD_IMAGE',
    UPLOAD_IMAGE_FAIL: 'ACTIVITY-UPLOAD_IMAGE_FAIL',
    UPLOAD_IMAGE_SUCCESS: 'ACTIVITY-UPLOAD_IMAGE_SUCCESS',
  },

  EVENT: {
    BUTTON_DISBALING: 'EVENT-BUTTON_DISBALING',
    CLEAR_IMAGES: 'EVENT-CLEAR_IMAGES',
    DELETE_IMAGE: 'EVENT-DELETE_IMAGE',
    POPULATE_IMAGES: 'EVENT-POPULATE_IMAGES',
    RE_ORDER_IMAGES: 'EVENT-RE_ORDER_IMAGES',
    SAVE_DATA: 'EVENT-SAVE_DATA',
    SAVE_DATA_FAIL: 'EVENT-SAVE_DATA_FAIL',
    SAVE_DATA_SUCCESS: 'EVENT-SAVE_DATA_SUCCESS',
    SAVE_DATA_TO_REDUX: 'EVENT-SAVE_DATA_TO_REDUX',
    UPLOAD_IMAGE: 'EVENT-UPLOAD_IMAGE',
    UPLOAD_IMAGE_FAIL: 'EVENT-UPLOAD_IMAGE_FAIL',
    UPLOAD_IMAGE_SUCCESS: 'EVENT-UPLOAD_IMAGE_SUCCESS',
  },

  EVENTS: {
    BULK_ACTION: 'EVENTS-BULK_ACTION',
    BULK_ACTION_FAIL: 'EVENTS-BULK_ACTION_FAIL',
    BULK_ACTION_SUCCESS: 'EVENTS-BULK_ACTION_SUCCESS',
    CLEAR_LIST: 'EVENTS-CLEAR_LIST',
    DELETE_EVENT: 'EVENTS-DELETE_EVENT',
    DELETE_EVENT_FAIL: 'EVENTS-DELETE_EVENT_FAIL',
    DELETE_EVENT_SUCCESS: 'EVENTS-DELETE_EVENT_SUCCESS',
    FILTER_LIST: 'EVENTS-FILTER_LIST',
    GET_LIST: 'EVENTS-GET_LIST',
    GET_LIST_FAIL: 'EVENTS-GET_LIST_FAIL',
    GET_LIST_SUCCESS: 'EVENTS-GET_LIST_SUCCESS',
    MOVE_TO_TOP: 'EVENTS-MOVE_TO_TOP',
    REMOVE_EVENT: 'EVENTS-REMOVE_EVENT',
  },

  MOVIE: {
    BUTTON_DISBALING: 'MOVIE-BUTTON_DISBALING',
    CLEAR_IMAGES: 'MOVIE-CLEAR_IMAGES',
    DELETE_IMAGE: 'MOVIE-DELETE_IMAGE',
    FETCH_MOVIE: 'MOVIE-FETCH_MOVIE',
    FETCH_MOVIE_FAIL: 'MOVIE-FETCH_MOVIE_FAIL',
    FETCH_MOVIE_SUCCESS: 'MOVIE-FETCH_MOVIE_SUCCESS',
    POPULATE_IMAGES: 'MOVIE-POPULATE_IMAGES',
    RE_ORDER_IMAGES: 'MOVIE-RE_ORDER_IMAGES',
    SAVE_DATA: 'MOVIE-SAVE_DATA',
    SAVE_DATA_FAIL: 'MOVIE-SAVE_DATA_FAIL',
    SAVE_DATA_SUCCESS: 'MOVIE-SAVE_DATA_SUCCESS',
    SAVE_DATA_TO_REDUX: 'MOVIE-SAVE_DATA_TO_REDUX',
    UPLOAD_IMAGE: 'MOVIE-UPLOAD_IMAGE',
    UPLOAD_IMAGE_FAIL: 'MOVIE-UPLOAD_IMAGE_FAIL',
    UPLOAD_IMAGE_SUCCESS: 'MOVIE-UPLOAD_IMAGE_SUCCESS',
  },

  MOVIES: {
    ADD_MOVIES: 'MOVIES-ADD_MOVIES',
    ADD_MOVIES_FAIL: 'MOVIES-ADD_MOVIES_FAIL',
    ADD_MOVIES_SUCCESS: 'MOVIES-ADD_MOVIES_SUCCESS',
    BULK_ACTION: 'MOVIES_BULK_ACTION',
    BULK_ACTION_FAIL: 'MOVIES_BULK_ACTION_FAIL',
    BULK_ACTION_SUCCESS: 'MOVIES_BULK_ACTION_SUCCESS',
    CLEAR_MOVIES: 'MOVIES-CLEAR_MOVIES',
    CLEAR_SELECTED_MOVIES: 'MOVIES-CLEAR_SELECTED_MOVIES',
    DELETE_MOVIE: 'MOVIES-DELETE_MOVIE',
    DELETE_MOVIE_FAIL: 'MOVIES-DELETE_MOVIE_FAIL',
    DELETE_MOVIE_SUCCESS: 'MOVIES-DELETE_MOVIE_SUCCESS',
    FILTER_LIST: 'MOVIES-FILTER_LIST',
    GET_MOVIES_FAIL: 'MOVIES-GET_MOVIES_FAIL',
    GET_MOVIES_LIST: 'MOVIES-GET_MOVIES_LIST',
    GET_MOVIES_SEARCH: 'MOVIES-GET_MOVIES_SEARCH',
    GET_MOVIES_SUCCESS: 'MOVIES-GET_MOVIES_SUCCESS',
    /* -------- Actions for CardSelector component --------- */
    DESELECT_MOVIE: 'MOVIES-DESELECT_MOVIE',
    MOVE_TO_TOP: 'MOVIES-MOVE_TO_TOP',
    REMOVE_MOVIE: 'MOVIES-REMOVE_MOVIE',
    SELECT_MOVIE: 'MOVIES-SELECT_MOVIE',
  },

  RECIPE: {
    BUTTON_DISBALING: 'RECIPE-BUTTON_DISBALING',
    CLEAR_IMAGES: 'RECIPE-CLEAR_IMAGES',
    DELETE_IMAGE: 'RECIPE-DELETE_IMAGE',
    FETCH_RECIPE: 'RECIPE-FETCH_RECIPE',
    FETCH_RECIPE_FAIL: 'RECIPE-FETCH_RECIPE_FAIL',
    FETCH_RECIPE_SUCCESS: 'RECIPE-FETCH_RECIPE_SUCCESS',
    POPULATE_IMAGES: 'RECIPE-POPULATE_IMAGES',
    RE_ORDER_IMAGES: 'RECIPE-RE_ORDER_IMAGES',
    SAVE_DATA: 'RECIPE-SAVE_DATA',
    SAVE_DATA_FAIL: 'RECIPE-SAVE_DATA_FAIL',
    SAVE_DATA_SUCCESS: 'RECIPE-SAVE_DATA_SUCCESS',
    SAVE_DATA_TO_REDUX: 'RECIPE-SAVE_DATA_TO_REDUX',
    UPLOAD_IMAGE: 'RECIPE-UPLOAD_IMAGE',
    UPLOAD_IMAGE_FAIL: 'RECIPE-UPLOAD_IMAGE_FAIL',
    UPLOAD_IMAGE_SUCCESS: 'RECIPE-UPLOAD_IMAGE_SUCCESS',
  },

  RECIPES: {
    ADD_RECIPES: 'RECIPES-ADD_RECIPES',
    ADD_RECIPES_FAIL: 'RECIPES-ADD_RECIPES_FAIL',
    ADD_RECIPES_SUCCESS: 'RECIPES-ADD_RECIPES_SUCCESS',
    BULK_ACTION: 'RECIPES-BULK_ACTION',
    BULK_ACTION_FAIL: 'RECIPES-BULK_ACTION_FAIL',
    BULK_ACTION_SUCCESS: 'RECIPES-BULK_ACTION_SUCCESS',
    CLEAR_RECIPES: 'RECIPES-CLEAR_RECIPES',
    CLEAR_SELECTED_RECIPES: 'RECIPES-CLEAR_SELECTED_RECIPES',
    DELETE_RECIPE: 'RECIPES-DELETE_RECIPE',
    DELETE_RECIPE_FAIL: 'RECIPES-DELETE_RECIPE_FAIL',
    DELETE_RECIPE_SUCCESS: 'RECIPES-DELETE_RECIPE_SUCCESS',
    FILTER_LIST: 'RECIPES-FILTER_LIST',
    GET_RECIPES_FAIL: 'RECIPES-GET_RECIPES_FAIL',
    GET_RECIPES_LIST: 'RECIPES-GET_RECIPES_LIST',
    // GET_RECIPES_SEARCH: 'RECIPES-GET_RECIPES_SEARCH',
    GET_RECIPES_SUCCESS: 'RECIPES-GET_RECIPES_SUCCESS',
    /* -------- Actions for CardSelector component --------- */
    DESELECT_RECIPE: 'RECIPES-DESELECT_RECIPE',
    MOVE_TO_TOP: 'RECIPES-MOVE_TO_TOP',
    REMOVE_RECIPE: 'RECIPES-REMOVE_RECIPE',
    SELECT_RECIPE: 'RECIPES-SELECT_RECIPE',
  },
};

// ----------------------------------------------------------------------------|
//                            actionTypes Export
// ----------------------------------------------------------------------------|
export default actionTypes;
