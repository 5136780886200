/**
 * ************************************
 *
 * @module  CardInfo.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Card Info component which includes card title and subtitle.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import 'components/CardInfo/CardInfo.scss';

// ----------------------------------------------------------------------------|
//                          React Function Component
// ----------------------------------------------------------------------------|
const CardInfo = ({ title, phrase }) => (
  <div className="card-info">
    <div className="card-title">{title}</div>
    <div className="card-subtitle">{phrase}</div>
  </div>
);

// ----------------------------------------------------------------------------|
//                          PropTypes Check - CardInfo
// ----------------------------------------------------------------------------|
CardInfo.propTypes = {
  title: PropTypes.string.isRequired,
  phrase: PropTypes.string,
};

CardInfo.defaultProps = {
  phrase: '',
};

// ----------------------------------------------------------------------------|
//                              CardInfo Export
// ----------------------------------------------------------------------------|
export default CardInfo;
