/**
 * ************************************
 *
 * @module  AxiosWrapper.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description HTTP class for requests
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                    Imports
// ----------------------------------------------------------------------------|
import axios, { CancelToken } from 'axios';
import { getSession, logout } from 'services/AuthService';

// ----------------------------------------------------------------------------|
//                            Axios Class Constructor
// ----------------------------------------------------------------------------|
class AxiosWrapper {
  constructor(sessionCookie) {
    this.sessionCookie = sessionCookie;

    // declare an ajax request's cancelToken. Cancels a text search call
    // if another is made before the last resolves
    this.textSearchRequest = null;

    axios.defaults.headers.common['Content-Type'] = 'application/json';

    axios.interceptors.response.use(
      (response) => response,
      this.handleAPIError
    );
  }

  // --------------------------------------------------------------------------|
  //                              Class Methods
  // --------------------------------------------------------------------------|
  /**
   * @description - gets the authorization header
   *
   * @returns {Object}
   */
  getAuthHeader = () => {
    const sessionData = getSession(this.sessionCookie);

    if (Object.keys(sessionData).length) {
      const { token } = sessionData;

      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      } else {
        delete axios.defaults.headers.common.Authorization;
      }
    }

    return {};
  };

  /**
   * @description - constructs an ajax request
   *
   * @returns {Promise}
   */
  _ajax = ({ method, url, data = {}, headers, options = {} }) => {
    const requestHeaders = { ...headers, ...this.getAuthHeader() };

    return axios({
      method,
      url,
      data,
      headers: requestHeaders,
      ...options,
    })
      .then((response) => response)
      .catch((error) => error.response);
  };

  /**
   * @description - special ajax request only for search fields to prevent too
   * many calls. Has the ability to cancel an API request if another is made
   * before response comes back
   *
   * @param {String} method - different verticals have different search http
   * requests, pass the correct one
   * @param {String} url
   * @param {Object} data
   * @param {String} headers
   *
   * @returns {Promise}
   */
  searchData = (method, url, data, headers) => {
    // cancel  previous ajax if exists
    if (this.textSearchRequest) {
      this.textSearchRequest.cancel();
    }
    // creates a new token for upcoming ajax (overwrite the previous one)
    this.textSearchRequest = CancelToken.source();

    return this._ajax({
      method,
      url,
      data,
      headers,
      options: {
        cancelToken: this.textSearchRequest.token,
      },
    });
  };

  /**
   * @description - constructs a POST ajax request
   *
   * @param {String} url
   * @param {Object} data
   * @param {String} headers
   *
   * @returns {Promise}
   */
  postData = (url, data, headers) =>
    this._ajax({ method: 'post', url, data, headers });

  /**
   * @description - constructs a GET ajax request
   *
   * @param {String} url
   * @param {Object} data
   * @param {String} headers
   *
   * @returns {Promise}
   */
  getData = (url, data, headers) =>
    this._ajax({ method: 'get', url, data, headers });

  /**
   * @description - constructs a PUT ajax request
   *
   * @param {String} url
   * @param {Object} data
   *
   * @returns {Promise}
   */
  putData = (url, data) => this._ajax({ method: 'put', url, data });

  /**
   * @description - constructs a DELETE ajax request
   *
   * @param {String} url
   * @param {Object} data
   *
   * @returns {Promise}
   */
  deleteData = (url, data) => this._ajax({ method: 'delete', url, data });

  /**
   * @description - constructs a PATCH ajax request
   *
   * @param {String} url
   * @param {Object} data
   *
   * @returns {Promise}
   */
  patchData = (url, data) => this._ajax({ method: 'patch', url, data });

  /**
   * @description - constructs a PATCH ajax request
   *
   * @param {Object} error
   *
   * @returns {Promise || false}
   */
  handleAPIError = (error) => {
    if (error.response && error.response.status === 401) {
      // logout user in this case
      logout(this.sessionCookie, this.rememberMeCookie);
    } else {
      return Promise.reject(error);
    }
    return false;
  };
}

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default AxiosWrapper;
