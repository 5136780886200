/**
 * ************************************
 *
 * @module  CardInput.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Component to add an additional card to Curated Cards.
 * Sits to the far right of the card list. Clicking brings up the selection
 * modal
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import addIcon from 'assets/images/add.svg';

import './CardInput.scss';

// ----------------------------------------------------------------------------|
//                      React Function Component - CardInput
// ----------------------------------------------------------------------------|
const CardInput = ({ clickHandler }) => (
  <div className="card-input" role="button" onClick={clickHandler}>
    <img className="add-icon" src={addIcon} alt="Add more" />
  </div>
);

// ----------------------------------------------------------------------------|
//                        PropTypes Check - CardInput
// ----------------------------------------------------------------------------|
CardInput.propTypes = {
  clickHandler: PropTypes.func.isRequired,
};

// ----------------------------------------------------------------------------|
//                            CardInput Export
// ----------------------------------------------------------------------------|
export default CardInput;
