/**
 * ************************************
 *
 * @module  Categories.reducer.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Categories reducer file
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import ACTIONS from 'store/actions/actionTypes';

import currentEnv from 'utils/EnvironmentSpecificValues';

import {
  filterCategories,
  filterCategoryParents,
  mapAndReplace,
} from 'containers/Categories/CategoriesUtils';

import {
  createCategoryMap,
  extractCategoryTypes,
  mapChildLinks,
} from 'utils/CategoryUtils';

// ----------------------------------------------------------------------------|
//                               Initial State
// ----------------------------------------------------------------------------|
const initialData = {
  data: [],
  parents: [],
  categories: [],
  filteredData: [],
  query: '',
  dropdownFilterParentSlug: 'ALL',
  initialDataLoaded: false,
  // vertical specific categories
  cardCategories: [],
  categoryMap: {},
  categoryTagOptions: [],
  adminCategories: [],
  placeCategories: [],
  movieCategories: [],
  movieRatings: [],
  tvRatings: [],
  vibes: [],
  recreationCategories: [],
  recipeCategories: [],
  mealTypes: [],
  diet: [],
  perfectFor: [],
  verticalDropdownMap: {},
};

// ----------------------------------------------------------------------------|
//                            Categories Reducer
// ----------------------------------------------------------------------------|
const Categories = (state = initialData, action = {}) => {
  switch (action.type) {
    /**
     * @description - on successful http request for categories list
     * Breaks out and classifies the category slugs based on the
     * extractCategoryTypes function
     */
    case ACTIONS.CATEGORIES.GET_LIST_SUCCESS: {
      const { categories, pairing_reasons_options, cities } = action.data;
      const filterLocationsDropdownOptions = [
        {
          label: 'Go Out Anywhere',
          value: {
            required_neighborhoods: [],
            excluded_neighborhoods: [currentEnv.neighborhoodSlugs.stayIn],
          },
        },
        {
          label: 'Stay In',
          value: {
            required_neighborhoods: [[currentEnv.neighborhoodSlugs.stayIn]],
            excluded_neighborhoods: [],
          },
        },
        {
          label: 'Everywhere (all cities, stay-in & going out)',
          value: {
            required_neighborhoods: [],
            excluded_neighborhoods: [],
          },
        },
      ];
      for (const city of cities) {
        filterLocationsDropdownOptions.push(
          {
            label: city.name,
            value: {
              required_neighborhoods: [[city.unique_slug]],
              excluded_neighborhoods: [],
            },
          }
        );
      }
      const {
        categoryTagOptions,
        child,
        places,
        movies,
        movieRatings,
        tvRatings,
        vibes,
        recreation,
        mealTypes,
        recipes,
        diet,
        perfectFor,
        noteworthy,
      } = extractCategoryTypes(categories.admin_categories);

      const categoryMap = createCategoryMap(categories.admin_categories);
      const verticalsChildTree = mapChildLinks(
        categories.verticals,
        categories.admin_categories,
        categoryMap
      );
      const data = categories.admin_categories;
      const updatedData = [...state.data, ...data];

      const parents = filterCategoryParents(data);

      return {
        ...state,
        data: updatedData,
        categories: [...data],
        // categoryVerticals: categoryVerticalsMap,
        filteredData: [...data],
        parents,
        initialDataLoaded: true,
        // vertical specific cats
        categoryTagOptions,
        adminCategories: child,
        categoryMap,
        placeCategories: places,
        movieCategories: movies,
        movieRatings,
        tvRatings,
        vibes,
        recreationCategories: recreation,
        recipeCategories: recipes,
        mealTypes,
        diet,
        perfectFor,
        noteworthy,
        verticalDropdownMap: verticalsChildTree,
        cardCategories: categories.fs_categories,
        // misc
        pairingReasonsOptions: pairing_reasons_options,
        filterLocationsDropdownOptions: filterLocationsDropdownOptions,
      };
    }

    /**
     * @description - on unsuccessful http request for categories list
     */
    case ACTIONS.CATEGORIES.GET_LIST_FAIL: {
      return { ...state, initialDataLoaded: true };
    }

    /**
     * @description - clears cat list
     */
    case ACTIONS.CATEGORIES.CLEAR_LIST: {
      return {
        ...state,
        data: [],
        initialDataLoaded: false,
      };
    }

    /**
     * @description - successful delete
     */
    case ACTIONS.CATEGORIES.DELETE_CATEGORY_SUCCESS: {
      const { query, dropdownFilterParentSlug } = state;

      const { data: category } = action;
      // filters the cat we just deleted from the list
      const categories = [...state.data].filter(
        (cat) => cat.unique_slug !== category.unique_slug
      );

      const filteredData = filterCategories(
        categories,
        dropdownFilterParentSlug,
        query
      );

      const parents = filterCategoryParents(categories);

      return { ...state, data: categories, categories, filteredData, parents };
    }

    /**
     * @description - successful create
     */
    case ACTIONS.CATEGORIES.CREATE_CATEGORY_SUCCESS: {
      const { query, dropdownFilterParentSlug } = state;
      const { responseData: category } = action;
      const categories = [...state.data, ...[category]].sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      const filteredData = filterCategories(
        categories,
        dropdownFilterParentSlug,
        query
      );

      const parents = filterCategoryParents(categories);

      return { ...state, data: categories, categories, filteredData, parents };
    }

    /**
     * @description - successful edit
     */
    case ACTIONS.CATEGORIES.EDIT_CATEGORY_SUCCESS: {
      const { data, query, dropdownFilterParentSlug } = state;
      const { data: category } = action;
      const categories = mapAndReplace(data, category);

      const filteredData = filterCategories(
        categories,
        dropdownFilterParentSlug,
        query
      );

      const parents = filterCategoryParents(categories);

      return { ...state, data: categories, categories, filteredData, parents };
    }

    /**
     * @description - toggles enabling/disabling a category
     */
    case ACTIONS.CATEGORIES.TOGGLE_ENABLE_SUCCESS: {
      const { data, query, dropdownFilterParentSlug } = state;
      const { data: category } = action;
      const categories = mapAndReplace(data, category);

      const filteredData = filterCategories(
        categories,
        dropdownFilterParentSlug,
        query
      );

      return { ...state, data: categories, categories, filteredData };
    }

    /**
     * @description - toggles featuring/un-featuring a category
     */
    case ACTIONS.CATEGORIES.TOGGLE_FEATURE_SUCCESS: {
      const { data, query, dropdownFilterParentSlug } = state;
      const { data: category } = action;
      const categories = mapAndReplace(data, category);

      const filteredData = filterCategories(
        categories,
        dropdownFilterParentSlug,
        query
      );

      return {
        ...state,
        data: categories,
        categories,
        filteredData,
      };
    }

    /**
     * @description
     * On search of categories, it's a local search.
     * To filter data based on query, generate a action on every query request
     * Take latest state data copy and filter it with query - filteredData
     */
    case ACTIONS.CATEGORIES.QUERY_FILTER: {
      const { query } = action;
      // const categories = { ...state.data };
      const { dropdownFilterParentSlug } = state;

      const categories = [...state.categories];
      const filteredData = filterCategories(
        categories,
        dropdownFilterParentSlug,
        query
      );

      return {
        ...state,
        query,
        filteredData,
      };
    }

    /**
     * @description
     * On search of categories, it's a local search.
     * To filter data based on query, generate a action on every query request
     * Take latest state data copy and filter it with query - filteredData
     */
    case ACTIONS.CATEGORIES.PARENT_FILTER: {
      const { data } = action;
      const { query } = state;
      const categories = [...state.categories];
      const filteredData = filterCategories(categories, data, query);

      return {
        ...state,
        dropdownFilterParentSlug: data,
        filteredData,
      };
    }

    /**
     * @description Any local state state saving
     */
    case ACTIONS.CATEGORIES.SAVE_DATA_TO_REDUX: {
      const { data } = action;
      const updatedData = { ...data };

      return {
        ...state,
        data: updatedData,
      };
    }

    case ACTIONS.CATEGORIES.AJAX_FAIL: {
      return state;
    }

    default: {
      return state;
    }
  }
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default Categories;
