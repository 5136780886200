/**
 * ************************************
 *
 * @module  Toast.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description container for the toast
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './Toast.scss';

// This component is to be used to show success or error messages as toasts
// To render a success toast, import the toast function from react-toastify
// and call toast.success("successMessage")
// To render an error toast, import the toast function from react-toastify
// and call toast.error("errorMessage")
// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const Toast = (props) => (
  <ToastContainer
    className="toast-container"
    hideProgressBar
    position={props.position}
    autoClose={4000}
    pauseOnHover={false}
  />
);

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
Toast.propTypes = {
  position: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
Toast.defaultProps = {
  position: 'top-right',
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default Toast;
