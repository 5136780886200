/**
 * ************************************
 *
 * @module  utils.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description home for the various utility functions used throughout the CMS
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import moment from 'moment';

import { parse, format } from 'date-fns';

import PlaceholderImage from 'assets/images/placeholder.png';

import { GENERIC, DATE_SELECTOR_POPUP } from 'constants.js';

// ----------------------------------------------------------------------------|
//                                 Utilities
// ----------------------------------------------------------------------------|
/**
 * @description
 * This function takes an object and returns a string representing the url with
 * query params
 *
 * Example:
 *  input: { key1: 'abc', key2: 'xyz', key3: 34 }
 *  result: "key1=abc&key2=xyz&key3=34"
 *
 * @param {Object} data - "key-value" pairs which should used to construct url
 *
 * @returns {String} which will be in URI encoded format.
 */
const constructQueryParams = (data) => {
  const ret = [];
  const keysList = Object.keys(data);
  keysList.forEach((key) => {
    if (data[key]) {
      if (Array.isArray(data[key])) {
        data[key].forEach((value) => {
          ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        });
      } else {
        ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`);
      }
    }
  });
  return ret.join('&');
};

/**
 * @description This function will capitalize first word and convert following
 * words to lowercase.
 *
 * @param {String} word
 *
 * @returns {String}
 */
const capitalize = (word = '') =>
  word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();

/**
 * @description This function will split the route by '/' and return the
 * first and second parts. Can be used when we want to set certain props
 * based on the current route
 *
 * @param {String} url
 *
 * @returns {Object}
 */
const getPartsFromUrl = (url) => {
  const [, firstPart, secondPart] = url.split('/');
  return { firstPart, secondPart };
};

/**
 * @description This function will convert an ISO date into human readable
 * string in the required format
 *
 * @param {String} date
 * @param {String} requiredFormat
 *
 * @returns {String}
 */
const formatISODate = (date, requiredFormat) =>
  date ? format(parse(date), requiredFormat) : '';

/**
 * @description Formats a time string
 *
 * @param {String} timeStr
 * @param {String} requiredFormat
 *
 * @returns {String}
 */
const formatTimeString = (timeStr, requiredFormat) => {
  const dateObj = new Date();
  const [hrs, mins] = timeStr.split(':');

  dateObj.setHours(hrs);
  dateObj.setMinutes(mins);
  dateObj.setSeconds(0);

  return format(dateObj, requiredFormat);
};

/**
 * @description This method is used to display number of $ for a given
 * place/event or curated cards.
 *
 * @param {Number} priceTier Number of $ to be represented.
 *
 * @returns {String}
 */
const getPrice = (priceTier) => {
  let price = '';
  for (let i = 0; i < priceTier; i += 1) {
    price += '$';
  }

  return price;
};

/**
 * @description This method is used to return a string stating the number
 * of cards selected.
 *
 * @param {Object[]} cardArray Array with IDs of all selected cards.
 *
 * @returns {String}
 */
const getSelectedCardCount = (cardArray) => {
  const count = cardArray.length;

  return count === 1 ? `${count} card selected` : `${count} cards selected`;
};

/**
 * @description Validates URL string
 *
 * @param {String} string
 *
 * @returns {Boolean}
 */
const isValidURL = (string) => {
  if (!string || string.length === 0) {
    return false;
  }
  let testString = string;

  if (Array.isArray(string)) {
    [testString] = string;
  }

  const res = testString.trim().match(
    // eslint-disable-next-line max-len
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,24}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );

  return res !== null;
};

/**
 * @description This method is used to check if there are more data to be
 * fetched from server, for infinite scroller.
 *
 * @param {Number} dataLength Number of data present in local system
 * @param {Number} totalCount Total number of data present(value from server)
 *
 * @returns {Boolean}
 */
const checkHasMore = (dataLength, totalCount) => dataLength < totalCount;

/**
 * @description This method is used to send first image thumbnail URL, if
 * it has images, else it will send return fallback image.
 *
 * @param {Object} images Image object which will contain details about
 * the images to be rendered.
 * @param {Boolean} thumbUrl
 *
 * @returns {String}
 */
const getImageURL = (images, thumbUrl = true) => {
  if (Array.isArray(images) && images.length) {
    const image = images[0];

    if (thumbUrl && image.thumb_url) {
      return image.thumb_url;
    }
    if (image && image.url) {
      return image.url;
    }
    return `${PlaceholderImage}`;
  }

  return `${PlaceholderImage}`;
};

/**
 * @description returns price range dropdown options
 *
 * @returns {Object[]}
 */
const getPriceRange = () => [
  { label: 'Not Applicable', value: -1 },
  { label: 'Free', value: 0 },
  { label: '$', value: 1 },
  { label: '$$', value: 2 },
  { label: '$$$', value: 3 },
  { label: '$$$$', value: 4 },
];

/**
 * @description returns price per dropdown options
 *
 * @returns {Object[]}
 */
const getForOptions = () => [
  { value: 'person', label: 'Per Person' },
  { value: 'couple', label: 'Per Couple' },
];

/**
 * @description price range label based on number
 *
 * @param {Number | undefined} range
 *
 * @returns {String}
 */
const getPriceRangeLabel = (range) => {
  let value;
  switch (range) {
    case undefined:
      value = '';
      break;
    case -1:
      value = 'Not Applicable';
      break;
    case 0:
      value = 'Free';
      break;
    case 1:
      value = '$';
      break;
    case 2:
      value = '$$';
      break;
    case 3:
      value = '$$$';
      break;
    case 4:
      value = '$$$$';
      break;
    default:
      value = 'Not Applicable';
      break;
  }
  return value;
};

/**
 * @description returns reservation dropdown options
 *
 * @returns {Object[]}
 */
const getReservationTiers = () => [
  { label: 'Not Applicable', value: '' },
  { label: 'Difficult Reservations', value: 'difficult_reservations' },
  { label: 'Tickets Required', value: 'tickets_required' },
  { label: 'Reservations Required', value: 'rsvp_required' },
  { label: 'Easy', value: 'easy' },
  { label: 'Walk-In Only', value: 'walk_in_only' },
];

/**
 * @description returns duration tiers
 *
 * @returns {Object[]}
 */
const getDurationTiers = (duration = 600) => {
  // undefined will set -1 as the time. This is for
  // publishing error handling
  if (duration === -1) return [{ label: '', value: -1 }];

  const tierArray = [{ label: 'None', value: 0 }];

  if (duration === 0) return tierArray;

  const durationTimeConvert = (n) => {
    const num = n;
    const hours = num / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);

    if (rhours === 0)
      return `${rminutes} ${rminutes === 1 ? 'minute' : 'minutes'}`;
    if (rhours !== 0 && rminutes === 0)
      return `${rhours} ${rhours === 1 ? 'hour' : 'hours'}`;

    return `${rhours} hours and ${rminutes} minutes`;
  };

  const increment = 5;
  for (let i = 1; i <= duration / increment; i += 1) {
    tierArray.push({
      label: `${durationTimeConvert(i * increment)}`,
      value: i * increment,
    });
  }

  return tierArray;
};

/**
 * @description returns neighborhood slugs indexed
 *
 * @returns {Object[]}
 */
const getProcessedNeighborhoods = (neighborhoods = []) => {
  if (Object(neighborhoods[0]) === neighborhoods[0]) {
    return neighborhoods.map((neighborhood) => neighborhood);
  }
  return neighborhoods.map((neighborhood, index) => ({
    id: index + 1,
    name: neighborhood.name,
  }));
};

/**
 * @description This method is used to partition an array
 * into two based on some condition. It returns two arrays,
 * one which passed the check and other which failed the check
 *
 * @param {Array} arr Array of items to be split into two types.
 * @param {Function} isValid A function to check if an element
 * passes or fails the condition
 *
 * @returns {Object} An object in the form {pass:[], fail:[]}
 * containing 2 arrays: array of passed elements and array of failed elements
 */
const splitArray = (arr, isValid) =>
  arr.reduce(
    ({ pass, fail }, elem) =>
      isValid(elem)
        ? { pass: [...pass, elem], fail }
        : { pass, fail: [...fail, elem] },
    { pass: [], fail: [] }
  );

/**
 * @description returns formatted array of image objects
 *
 * @param {Object[]} images
 *
 * @returns {Object[]}
 */
const getFormattedImages = (images = []) => {
  if (images === null) {
    return [];
  }

  return images.map((image) => ({
    url: image.url,
    source: image.source,
    thumb_url: image.thumb_url,
  }));
};

/**
 * @description returns string of unique_slugs
 *
 * @param {Object[]} selected
 * @param {Object[]} masterList
 *
 * @returns {String[]}
 */
const getUniqueSlug = (selected = [], masterList = []) =>
  masterList.reduce((acc, item) => {
    // for array of strings
    if (typeof selected[0] !== 'object' && selected.includes(item.name)) {
      return [...acc, item.unique_slug];
    }
    // for array of objects
    if (typeof selected[0] === 'object') {
      if (selected.filter((e) => e.unique_slug === item.unique_slug).length > 0) {
        return [...acc, item.unique_slug];
      }
    }

    return acc;
  }, []);

/**
 * @description returns date based on card status
 *
 * @param {String} status
 * @param {String} modifiedDate
 * @param {String} publishedDate
 *
 * @returns {String}
 */
const getDateBasedOnStatus = (status, modifiedDate, publishedDate) => {
  switch (status) {
    case 'published':
      return publishedDate;
    case 'draft':
      return modifiedDate;
    default:
      return modifiedDate;
  }
};

/**
 * @description lol this isn't even used anywhere but I keep it here
 * for fun thank you YML!
 */
const loadThirdPartyScript = (url, callback) => {
  if (window.loadedExternalResources.indexOf(url) > -1) {
    if (callback) callback();
    return;
  }
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.defer = true;
  script.async = true;
  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
      }
    };
  }
  script.onload = () => {
    if (callback) callback();
  };
  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
  window.loadedExternalResources.push(url);
};

/**
 * @description triggers a confirm browser popup. Fires callback
 *
 * @param {Function} successCB - function to fire on confirm
 */
const askForCloseConfirmation = (successCB) => {
  const { CLOSE_CARD_WARNING_MESSAGE } = GENERIC;
  // eslint-disable-next-line no-alert
  if (window.confirm(CLOSE_CARD_WARNING_MESSAGE)) {
    successCB(null);
  }
};

/**
 * @description applies drag for reOrder of images
 *
 * @param {Object[]} arr
 * @param {Object}
 *
 * @returns {Object[]}
 */
const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;

  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    [itemToAdd] = result.splice(removedIndex, 1);
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

/**
 * @description Higher order function, takes data as first param,
 * list of functions as remaining comma separated params.
    Process data across functions and return result
  @param: data
  @param: list of comma seperated functions
*/
const higherOrderFunction = (data, ...functions) =>
  functions.reduce((prevResult, curFn) => curFn(prevResult), data);

/**
 * This function converts the given dates into a readable string of this format:
 * "MMM D YYYY - MMM D YYYY"
 * @param {string | moment} startDate
 * @param {string | moment} endDate
 */
const formatDateRange = (startDate, endDate) => {
  const { DATE_FORMAT } = DATE_SELECTOR_POPUP;
  const start = moment(startDate).format(DATE_FORMAT);
  const end = moment(endDate).format(DATE_FORMAT);
  return `${start} - ${end}`;
};

/**
 * @description This function takes an HTML element and returns true if
 * it is the root HTML element.
 *
 * Usage: Dropdowns should not be closed when user tries to click on
 * scroll-bar/scroll-buttons
 *
 * @param {HTMLElement} element
 *
 * @returns {Boolean}
 */
const isDocumentElement = (element) =>
  [document.documentElement, document.body, window].indexOf(element) > -1;

/**
 * @description This function takes custom categories as input and returns
 * only child level categories
 *
 * @param {Object[]} categories
 *
 * @returns {Object[]} Extract Child Categories
 */
const extractChildCategories = (categories) =>
  categories.filter((cat) => cat.unique_slug !== cat.parent_slug);

/**
 * @description returns the week index of the month
 *
 * @param {Date} date
 *
 * @returns {Number}
 */
const weekIndexOfMonth = (date) => {
  let weekInYearIndex = date.week();

  if (date.year() !== date.weekYear()) {
    weekInYearIndex = date.clone().subtract(1, 'week').week() + 1;
  }
  const weekIndex = weekInYearIndex - moment(date).startOf('month').week() + 1;

  return weekIndex;
};

/**
 * @description returns the week number of the month
 *
 * @param {Date} date
 *
 * @returns {String}
 */
const weekOfMonth = (date) => {
  const numerics = ['First', 'Second', 'Third', 'Fourth', 'Fifth'];
  const weekIndex = weekIndexOfMonth(date);
  return numerics[weekIndex - 1];
};

/**
 * @description returns an object of key values assigned boolean
 * prop
 *
 * @param {Any[]} array
 * @param {Boolean} value
 *
 * @returns {String}
 */
const convertArrayToBooleanMap = (array, value = false) =>
  array.reduce((map, key) => ({ ...map, [key]: value }), {});

/**
 * @description returns movie production status dropdown select
 * options
 *
 * @returns {Object[]}
 */
const getProductionStatus = () => [
  { label: 'Rumored', value: 'rumored' },
  { label: 'Planned', value: 'planned' },
  { label: 'In Production', value: 'in_production' },
  { label: 'Post Production', value: 'post_production' },
  { label: 'Released', value: 'released' },
  { label: 'Canceled', value: 'canceled' },
  { label: 'Ended', value: 'ended' },
  { label: 'Returning Series', value: 'returning_series' },
];

/**
 * @description returns movie instance type status dropdown select
 * options
 *
 * @returns {Object[]}
 */
const getMovieInstanceType = () => [
  { label: 'Movie', value: 'movie' },
  { label: 'TV Show', value: 'show' },
];

/**
 * @description returns card review status dropdown select
 * options
 *
 * @returns {Object[]}
 */
const getCardReviewStatus = () => [
  { label: 'Pending Review', value: 'pending_review' },
  { label: 'Images Pending', value: 'pending_images_review' },
  { label: 'Content Pending', value: 'pending_content_review' },
  { label: 'Data Pending', value: 'pending_data_review' },
  { label: 'Data & Images Pending', value: 'pending_data_images_review' },
  { label: 'Content & Images Pending', value: 'pending_content_images_review' },
  { label: 'Content & Data Pending', value: 'pending_data_review' },
  { label: 'Reviewed', value: 'reviewed' },
];

/**
 * @description homemade debounce
 *
 * @param {Function} func - to debounce
 * @param {Number} wait - how long to wait milliseconds
 * @param {Boolean} immediate - fire immediately?
 *
 * @returns {Function}
 */
const debounce = (func, wait, immediate) => {
  let timeout;

  return (...args) => {
    const context = this;

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      timeout = null;

      if (!immediate) func.apply(context, args);
    }, wait);

    if (immediate && !timeout) func.apply(context, args);
  };
};

/**
 * @description stringifies and parses data to create fresh
 * object
 *
 * @param {Object} input - object to copy
 */
const deepCopy = (input) => JSON.parse(JSON.stringify(input));

/**
 * @description Angular frameworks build-in URL sanitizer
 * Instead of looking for known bad patterns, Angular approves known safe URLs.
 * Everything that does not match known-good values is blocked by default.
 *
 * @param {String} fullURL - object to copy
 *
 * @returns {String}
 */
const sanitizeUrl = (fullURL = 'about:blank') => {
  /* eslint-disable max-len */
  const SAFE_URL_PATTERN =
    /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;
  /** A pattern that matches safe data URLs. It only matches image, video, and audio types. */
  const DATA_URL_PATTERN =
    /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+\/]+=*$/i;

  // eslint-disable-next-line no-underscore-dangle
  const _sanitizeUrl = (url) => {
    const submittedUrl = String(url);

    if (
      submittedUrl === 'null' ||
      submittedUrl.length === 0 ||
      submittedUrl === 'about:blank'
    )
      return 'about:blank';
    if (
      submittedUrl.match(SAFE_URL_PATTERN) ||
      submittedUrl.match(DATA_URL_PATTERN)
    )
      return submittedUrl;

    return `unsafe:${submittedUrl}`;
  };

  return _sanitizeUrl(String(fullURL).trim());
};

/**
 * @description checks if a lat lng falls within the borders of the
 * neighborhood object
 *
 * @param {Number} lat
 * @param {Number} lon
 * @param {Object} neighborhoodObject
 *
 * @returns {String}
 */
const locationCoordinateCheck = (lat, lon, neighborhoodObject) => {
  if (!neighborhoodObject || !neighborhoodObject.boundaries_coordinates)
    return false;

  const { boundaries_coordinates } = neighborhoodObject;
  const { coordinates, type } = boundaries_coordinates;

  const PI = 3.14159265;
  const TWOPI = 2 * PI;

  const Angle2D = (y1, x1, y2, x2) => {
    const theta1 = Math.atan2(y1, x1);
    const theta2 = Math.atan2(y2, x2);

    let dtheta = theta2 - theta1;

    while (dtheta > PI) dtheta -= TWOPI;
    while (dtheta < -PI) dtheta += TWOPI;

    return dtheta;
  };

  const coordinate_is_inside_polygon = (
    latitude,
    longitude,
    lat_array,
    long_array
  ) => {
    let angle = 0;
    let point1_lat;
    let point1_long;
    let point2_lat;
    let point2_long;
    const n = lat_array.length;

    for (let i = 0; i < n; i += 1) {
      point1_lat = lat_array[i] - latitude;
      point1_long = long_array[i] - longitude;
      point2_lat = lat_array[(i + 1) % n] - latitude;
      // you should have paid more attention in high school geometry.
      point2_long = long_array[(i + 1) % n] - longitude;
      angle += Angle2D(point1_lat, point1_long, point2_lat, point2_long);
    }

    if (Math.abs(angle) < PI) return false;
    return true;
  };

  const radianToDegree = (coordinate) => (PI * coordinate) / 180;

  const formatLatLongCoordinates = (latLonArray) => {
    // current latLonArray nested arrays from backend
    // are formatted as [lon, lat]
    const lon_array = [];
    const lat_array = [];

    for (let i = 0; i < latLonArray.length; i += 1) {
      lon_array.push(latLonArray[i][0]);
      lat_array.push(latLonArray[i][1]);
    }

    return [lat_array, lon_array];
  };

  const isValidGPSCoordinate = (latitude, longitude) => {
    if (
      latitude > -90 &&
      latitude < 90 &&
      longitude > -180 &&
      longitude < 180
    ) {
      return true;
    }

    return false;
  };

  if (isValidGPSCoordinate(lat, lon)) {
    if (type === 'MultiPolygon') {
      for (let i = 0; i < coordinates.length; i += 1) {
        const [latitudeArray, longitudeArray] = formatLatLongCoordinates(
          coordinates[i][0]
        );

        if (
          coordinate_is_inside_polygon(lat, lon, latitudeArray, longitudeArray)
        )
          return true;
      }
    } else if (type === 'Polygon') {
      const [latitudeArray, longitudeArray] = formatLatLongCoordinates(
        coordinates[0]
      );

      return coordinate_is_inside_polygon(
        lat,
        lon,
        latitudeArray,
        longitudeArray
      );
    }
  }

  return false;
};

/**
 * @description - finds closest coordinate givin a lat lng data set
 *
 * @param {Number} lat
 * @param {Number} lon
 * @param {Object[]} locationData
 *
 * @returns {Object}
 */
const closestCoordinate = (lat, lng, locationData) => {
  const closestObj = {
    locationObj: undefined,
    distanceDiff: Infinity,
  };

  const distance = (lat1, lon1, lat2, lon2, unit) => {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;

    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

    if (dist > 1) {
      dist = 1;
    }

    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    // Unit of measurement: Kilometers
    if (unit === 'K') {
      dist *= 1.609344;
    }

    // Unit of measurement: Nautical Miles
    if (unit === 'N') {
      dist *= 0.8684;
    }

    return dist;
  };

  for (let i = 0; i < locationData.length; i += 1) {
    const { center_coordinates } = locationData[i];

    if (center_coordinates) {
      const { coordinates } = center_coordinates;
      const distanceBetween = distance(
        lat,
        lng,
        coordinates[1],
        coordinates[0]
      );

      if (closestObj.distanceDiff > distanceBetween) {
        closestObj.locationObj = locationData[i];
        closestObj.distanceDiff = distanceBetween;
      }
    }
  }

  return closestObj;
};

/**
 * @description - filters any neighborhood objects who's county and name match
 *
 * @param {Object[]} locations
 *
 * @returns {Object[]}
 */
const filterCountyName = (locations) =>
  locations.filter((location) => location.name !== location.county);

/**
 * @description - converts a moment object to an object with the dates I want
 *
 * @param {moment} dateObj
 *
 * @returns {Object}
 */
const momentObjectToStringObject = (dateObj) => {
  const monthNames = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  return {
    month: dateObj.month() + 1,
    monthName: monthNames[dateObj.month() + 1],
    day: dateObj.date(),
    year: dateObj.year(),
  };
};

/**
 * @description - converts underscore case to capital case
 *
 * @param {String} str
 *
 * @returns {String}
 */
const convertUnderscoreCaseToCapitalCase = (str) => {
  const splitString = str.split('_');

  return splitString.map((strArr) => capitalize(strArr)).join(' ');
};

/**
 * @description - extracts pretty_id
 *
 * @param {Object[]} cards
 *
 * @returns {String[]}
 */
const getCardPrettyIds = (cards = []) => cards.map((card) => card.pretty_id);

/**
 * @description - returns dropdown value that matches the option
 *
 * @param {Object[]} dropdownOptions
 * @param {Any} dropdownOptions
 *
 * @returns {Object}
 */
const returnDropdownValue = (dropdownOptions, dropdownValue) => {
  for (let i = 0; i < dropdownOptions.length; i += 1) {
    if (dropdownOptions[i].value === dropdownValue) return dropdownOptions[i];
  }
};

/**
 * @description creates dropdown options for tag selection
 *
 */
const createDropdownOptions = (optionsArray = []) =>
  optionsArray.map((optionsObj) => ({
    label: optionsObj.name,
    value: optionsObj,
  }));

/**
 * @description checks if an array of cats has a unique slug
 *
 * @param {Array} categories
 * @param {String} slug
 *
 * @returns {Boolean}
 */
const hasUniqueSlug = (categories = [], slug) => {
  for (let i = 0; i < categories.length; i += 1) {
    if (categories[i].unique_slug === slug) return true;
  }

  return false;
};

const findAndRemoveBySlug = (categories = [], slug) => {
  const categoryArrayCopy = deepCopy(categories);

  for (let i = 0; i < categoryArrayCopy.length; i += 1) {
    if (categoryArrayCopy[i].unique_slug === slug) {
      categoryArrayCopy.splice(i, 1);
    }
  }

  return categoryArrayCopy;
};

/**
 * @description filters initial tag options so no repeats end up selectable
 *
 * @param {Array} currTags
 * @param {Array} allOptions
 *
 * @returns {Array}
 */
const filterInitialTagOptions = (currTags = [], allOptions = []) =>
  allOptions.filter((options) => {
    for (let i = 0; i < currTags.length; i += 1) {
      if (currTags[i].unique_slug === options.unique_slug) return false;
    }

    return true;
  });

/**
 * @description chooses which itemized description toi display
 *
 * @param {Object[]} itemizedDesc
 * @param {String} description
 * @param {Object[]} defaultDescriptions
 *
 * @returns {Object[]}
 */
const chooseItemizedDescription = (
  itemizedDesc,
  description,
  defaultDescriptions = []
) => {
  const defaultDescriptionsCopy = deepCopy(defaultDescriptions);

  // existing card with desc and without itemizedDesc
  if (
    itemizedDesc &&
    !itemizedDesc.length &&
    description &&
    typeof description === 'string' &&
    description.length
  ) {
    return [
      {
        header: '',
        body: description,
      },
      ...defaultDescriptionsCopy,
    ];
  }

  // existing card with blank desc but with itemizedDesc
  if (
    itemizedDesc &&
    itemizedDesc.length &&
    typeof description === 'string' &&
    description.length === 0
  ) {
    return [...deepCopy(itemizedDesc)];
  }

  if (
    // existing card without itemDesc length or desc
    (itemizedDesc &&
      !itemizedDesc.length &&
      description &&
      !description.length) ||
    // brand new card, no itemDesc or desc
    !itemizedDesc ||
    !description
  ) {
    return defaultDescriptionsCopy;
  }

  // converted or new card with itemizedDesc
  return [...deepCopy(itemizedDesc)];
};

/**
 * @description confirms we can access at least the 0 index of an
 * array of objects
 *
 * @param {Any[]} objectProperty
 *
 * @returns {Boolean}
 */
const qualifyArrayRendering = (objectProperty) =>
  objectProperty && Array.isArray(objectProperty) && objectProperty.length;

/**
 * @description slices last chars off a string (default last 1) and returns
 * that string
 *
 * @param {String} str - to slice
 * @param {Number} count - number of chars from end
 *
 * @returns {String}
 */
const removeLastChars = (str, count = 1) => str.slice(0, str.length - count);

/**
 * @description maps category unique slug to an object for O(1) lookup
 *
 * @param {Object[]} catArray
 * @param {Object} categoryObject
 *
 * @returns {Object}
 */
const mapDetailedCategories = (catArray = [], categoryObject = {}) =>
  catArray.reduce((acc, curr) => {
    const { unique_slug } = curr;
    const currAcc = acc;

    if (categoryObject[unique_slug]) currAcc.push(categoryObject[unique_slug]);

    return currAcc;
  }, []);

/**
 * @description extracts a card data no matter what type/http call it is
 *
 * @param {Object} dataObj response data
 *
 * @returns {Object[]} of card data
 */
const extractCardTypeData = (dataObj = {}) => {
  const { activities, cards, collections, movies, places, recipes, results } =
    dataObj;

  return (
    activities ||
    cards ||
    collections ||
    movies ||
    places ||
    recipes ||
    results ||
    []
  );
};

/**
 * @description returns proper card string to access constants
 *
 * @param {String} verticalType
 *
 * @returns {String}
 */
const chooseCardType = (verticalType) => {
  switch (verticalType) {
    case 'curated':
      return 'CURATED_CARD';
    case 'place':
      return 'PLACE';
    case 'event':
      return 'EVENT';
    case 'movie':
      return 'MOVIE';
    case 'activity':
      return 'ACTIVITY';
    case 'recipe':
      return 'RECIPE';
    case 'category':
      return 'CATEGORY';
    case 'filter':
      return 'FILTER';
    default:
      return '';
  }
};

/**
 * @description option responses have multiple properties that may
 * not be populated depending on the type of option (PLACE/MOVIE/etc..)
 *
 * This function checks what each option represents and returns that
 * option data accordingly
 *
 * @param {Object} option
 *
 * @returns {Object}
 */
const findDataKey = (option) => {
  if (option.activity) return option.activity;
  if (option.activities && option.activities.length)
    return option.activities[0];
  if (option.card) return option.card;
  if (option.movie) return option.movie;
  if (option.movies && option.movies.length) return option.movies[0];
  if (option.place) return option.place;
  if (option.places && option.places.length) return option.places[0];
  if (option.recipe) return option.recipe;
  if (option.recipes && option.recipes.length) return option.recipes[0];
};

const packageFlaggedCard = (option) => {
  const { is_curated, reports } = option;
  if (is_curated) return option;

  return {
    reports,
    ...findDataKey(option),
  };
};
// -----------------------------------------------------------------------------------------------------------------|
//                                                UTILS EXPORTS
// -----------------------------------------------------------------------------------------------------------------|
export {
  applyDrag,
  constructQueryParams,
  capitalize,
  getPartsFromUrl,
  formatISODate,
  formatTimeString,
  getPrice,
  checkHasMore,
  getSelectedCardCount,
  isValidURL,
  getImageURL,
  getPriceRange,
  getForOptions,
  getPriceRangeLabel,
  getProcessedNeighborhoods,
  splitArray,
  getFormattedImages,
  getUniqueSlug,
  getDateBasedOnStatus,
  loadThirdPartyScript,
  askForCloseConfirmation,
  higherOrderFunction,
  filterInitialTagOptions,
  formatDateRange,
  isDocumentElement,
  extractChildCategories,
  weekOfMonth,
  weekIndexOfMonth,
  convertArrayToBooleanMap,
  getCardReviewStatus,
  getMovieInstanceType,
  getProductionStatus,
  getReservationTiers,
  getDurationTiers,
  debounce,
  deepCopy,
  sanitizeUrl,
  locationCoordinateCheck,
  closestCoordinate,
  filterCountyName,
  momentObjectToStringObject,
  convertUnderscoreCaseToCapitalCase,
  getCardPrettyIds,
  returnDropdownValue,
  createDropdownOptions,
  hasUniqueSlug,
  findAndRemoveBySlug,
  chooseItemizedDescription,
  qualifyArrayRendering,
  removeLastChars,
  mapDetailedCategories,
  extractCardTypeData,
  chooseCardType,
  findDataKey,
  packageFlaggedCard,
};
