/**
 * ************************************
 *
 * @module  Modal.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description reusable modal component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import cx from 'classnames';

import { Backdrop } from 'components';

import CloseIcon from 'assets/images/close.png';

import './Modal.scss';

// ----------------------------------------------------------------------------|
//                          React Class Component
// ----------------------------------------------------------------------------|
/**
 * @description Modal component that will be using backdrop component as
 * overlay.
 *
 * @argument show: Boolean value based on which component will be rendered
 * @argument title: String value which is used to render title of modal box.
 * @argument modalClosed: Function which will be triggered when clicked on
 * close icon. When modalClosed event is triggered, in parent component we
 * can reset show value to false
 * @argument children: React.Element any JSX event written between component
 * is rendered.
 */
class Modal extends Component {
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  Modal attributes
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  targetRef = React.createRef();

  targetElement = null;

  // --------------------------------------------------------------------------|
  //                              PropTypes Check
  // --------------------------------------------------------------------------|
  static propTypes = {
    children: PropTypes.any.isRequired,
    modalFooter: PropTypes.any,
    modalClosed: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    show: PropTypes.bool,
    customClasses: PropTypes.string,
  };

  // --------------------------------------------------------------------------|
  //                              Default Props
  // --------------------------------------------------------------------------|
  static defaultProps = {
    show: false,
    customClasses: '',
    modalFooter: null,
    customStyles: {},
  };

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  Modal lifecycle
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  componentDidMount() {
    this.targetElement = this.targetRef.current;
    if (this.props.show) {
      disableBodyScroll(this.targetElement);
    } else {
      enableBodyScroll(this.targetElement);
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
  // /\/\/\/\/\/\/\/\  Modal rendering
  // /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

  render() {
    const {
      show,
      modalClosed,
      children,
      title,
      modalFooter,
      customClasses,
      customStyles,
    } = this.props;

    const modalClasses = cx(['modal', { 'modal-show': show }, customClasses]);

    return (
      <Fragment>
        <Backdrop show={show} onClick={modalClosed} />
        <div className="popup-foreground-outer">
          <div className="popup-foreground-table">
            <div className="popup-foreground-wrapper">
              <div
                ref={this.targetRef}
                className={modalClasses}
                style={customStyles}
              >
                <div className="modal-innerWrapper">
                  <div className="modal-header">
                    <h1 className="modal-title">{title}</h1>
                    <span
                      className="modal-close"
                      role="button"
                      onClick={modalClosed}
                    >
                      <img src={CloseIcon} alt="close" />
                    </span>
                  </div>
                  <div className="modal-content">{children}</div>
                  <div className="modal-footer">{modalFooter}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default Modal;
