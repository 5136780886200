/**
 * ************************************
 *
 * @module  Place.action.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description redux action for Place component
 *
 * ** PLACES IS IS A DEPENDENCY FOR OTHER FILES (ie. Curated Card(s)) and
 * can not yet be depreciated
 *
 * ************************************
 */

// ----------------------------------------------------------------------------|
//                              Import ActionTypes
// ----------------------------------------------------------------------------|
import ACTIONS from './actionTypes';

// ----------------------------------------------------------------------------|
//                            Export - Place Actions
// ----------------------------------------------------------------------------|
export const populateAutofillImagesAction = (data) => ({
  type: ACTIONS.PLACE.POPULATE_IMAGES,
  data,
});

export const uploadImageAction = (data) => ({
  type: ACTIONS.PLACE.UPLOAD_IMAGE,
  data,
});

export const deleteImageAction = (data) => ({
  type: ACTIONS.PLACE.DELETE_IMAGE,
  data,
});

export const clearImagesAction = (data) => ({
  type: ACTIONS.PLACE.CLEAR_IMAGES,
  data,
});

export const saveDataToRedux = (data) => ({
  type: ACTIONS.PLACE.SAVE_DATA_TO_REDUX,
  data,
});
export const reOrderImagesAction = (data) => ({
  type: ACTIONS.PLACE.RE_ORDER_IMAGES,
  data,
});
export const saveData = (data, id, successCB) => ({
  type: ACTIONS.PLACE.SAVE_DATA,
  data,
  id,
  successCB,
});
export const fetchPlaceAction = (data, successCB) => ({
  type: ACTIONS.PLACE.FETCH_PLACE,
  data,
  successCB,
});
