/**
 * ************************************
 *
 * @module  LocationCount.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description shows location count as icon on some cards
 * (collection/curated/etc..)
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import pin from 'assets/images/pin.svg';

import './LocationCount.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const LocationCount = ({ count }) => (
  <div className="location-count">
    <img src={pin} alt="locations" />
    <span>{count}</span>
  </div>
);

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
LocationCount.propTypes = {
  count: PropTypes.number.isRequired,
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default LocationCount;
