/**
 * ************************************
 *
 * @module  PreviewHeader.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description component for card preview modal header
 *
 * ************************************
 */
// ------------------------------------------------------------------------------------------|
//                                          Imports
// ------------------------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import closeIcon from 'assets/images/close.png';

import './PreviewHeader.scss';

// ------------------------------------------------------------------------------------------|
//                             React Function Component - PreviewHeader
// ------------------------------------------------------------------------------------------|
const PreviewHeader = ({ modalClose }) => {
  return (
    <div className="preview-header">
      <h1 className="preview-title">Preview</h1>
      <img className="close-icon" src={closeIcon} alt="close" onClick={modalClose} />
    </div>
  );
};

// ------------------------------------------------------------------------------------------|
//                               PropTypes Check - PreviewHeader
// ------------------------------------------------------------------------------------------|
PreviewHeader.propTypes = {
  modalClose: PropTypes.func.isRequired,
};

// ------------------------------------------------------------------------------------------|
//                                 Export - PreviewHeader
// ------------------------------------------------------------------------------------------|
export default PreviewHeader;
