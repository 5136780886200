/**
 * ************************************
 *
 * @module  CardSelector.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description component for selecting and composing curated cards
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Card } from 'components';

import CardInput from 'components/CardSelector/CardInput/CardInput';
import CardList from 'components/CardSelector/CardList/CardList';

import { getImageURL } from 'utils/utils';

import './CardSelector.scss';

// ----------------------------------------------------------------------------|
//                        Component Helper Variables
// ----------------------------------------------------------------------------|
/**
 * @description - vertical tab data
 */
const tabData = {
  PLACES: {
    name: 'PLACES',
    displayName: 'places',
  },
  EVENTS: {
    name: 'EVENTS',
    displayName: 'events',
  },
  RECIPES: {
    name: 'RECIPES',
    displayName: 'recipes',
  },
  ACTIVITIES: {
    name: 'ACTIVITIES',
    displayName: 'activities',
  },
  MOVIES: {
    name: 'MOVIES',
    displayName: 'movies & television',
  },
};

// ----------------------------------------------------------------------------|
//                  React Function Component - CardSelector
// ----------------------------------------------------------------------------|
/**
 * @description This is a functional component that utilizes React-Hooks,
 * which is used to house and display curated card groups
 *
 * @param {Object} props
 *
 * @returns {JSX} HTML components to be rendered to the DOM
 */
const CardSelector = (props) => {
  const { selectedCards, onDelete, title, errors } = props;
  // useState Hook
  const [showModal, setShowModal] = useState(false);

  /**
   * @description function to toggle open modal window
   * leverages react hooks
   */
  const openModal = () => {
    setShowModal(true);
  };

  /**
   * @description function to toggle close modal window
   * leverages react hooks
   */
  const closeModal = () => {
    setShowModal(false);
  };

  /**
   * @description loops over selected cards and renders Card components
   *
   * @returns {Array} Array of Card components
   */
  const renderSelectedCards = () => {
    if (Array.isArray(selectedCards)) {
      return selectedCards.map((card) => {
        const { pretty_id } = card;

        // checks pretty ID for type of card to feed correct card
        // props;
        const isMovie = pretty_id.includes('movie_');
        const name = isMovie ? card.title : card.name;

        const {
          id,
          published_date: publishedDate,
          status,
          images,
          // name,
          phrase,
          is_expired: isExpired,
        } = card;

        const imageURL = getImageURL(images);

        return (
          <Card
            key={id}
            id={id}
            className="card"
            date={publishedDate}
            status={status}
            imageUrl={imageURL}
            isExpired={isExpired}
            title={name}
            phrase={phrase}
            onDelete={() => onDelete(card)}
            hideMenu
            showDeleteButton
          />
        );
      });
    }
  };

  return (
    <div className="card-selector-container">
      <div className="preview-heading">{title}</div>
      <div className="selected-card-list">
        {renderSelectedCards()}
        <CardInput clickHandler={openModal} />
      </div>
      <div className="error">{errors}</div>
      {showModal && (
        <CardList tabData={tabData} show={showModal} modalClosed={closeModal} />
      )}
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                       PropTypes Check - CardSelector
// ----------------------------------------------------------------------------|
CardSelector.propTypes = {
  title: PropTypes.string,
  selectedCards: PropTypes.array,
  onDelete: PropTypes.func.isRequired,
  errors: PropTypes.string,
};

CardSelector.defaultProps = {
  title: 'Add Places/Events',
  selectedCards: [],
  errors: '',
};

// ----------------------------------------------------------------------------|
//                          CardSelector Export
// ----------------------------------------------------------------------------|
export default CardSelector;
