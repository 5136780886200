/**
 * ************************************
 *
 * @module  EventHoursModal.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                  Imports
// ---------------------------------------------------------------------------|
import moment from 'moment';

import { DATE_SELECTOR_POPUP } from 'constants.js';
import { weekIndexOfMonth } from 'utils/utils';

import { AVAILABILITY_OPTIONS } from '../EventDateSelector.constants';

const {
  LONG_DATE_FORMAT,
  TIME_FORMAT,
  TIME_FORMAT_FOR_BACKEND,
} = DATE_SELECTOR_POPUP;
const { MONTHLY_ON_DATE, MONTHLY_WEEKDAY } = AVAILABILITY_OPTIONS;

const MAP_WEEKDAYS_TO_NUMBERS = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6,
};

export default class EventHoursModel {
  constructor(_dataFromUI = {}) {
    const { startDate, fromTime, toTime, isRecurring } = _dataFromUI;

    this.recurring_event = isRecurring;
    this.start_date = startDate.format(LONG_DATE_FORMAT);
    this.from_time = moment(fromTime, TIME_FORMAT).format(
      TIME_FORMAT_FOR_BACKEND
    );
    this.to_time = toTime
      ? moment(toTime, TIME_FORMAT).format(TIME_FORMAT_FOR_BACKEND)
      : '00:00:00';

    if (isRecurring) {
      this.initRecurrenceSpecificOptions(_dataFromUI);
    }
  }

  initRecurrenceSpecificOptions(_dataFromUI) {
    const {
      startDate,
      endDate,
      repeatMode,
      selectedWeekDays,
      availability,
    } = _dataFromUI;

    this.frequency_type = repeatMode.value.toLowerCase();
    this.end_date = endDate.format(LONG_DATE_FORMAT);
    this.frequency_interval = 1;

    switch (this.frequency_type) {
      case 'weekly':
        this.recurring_days = selectedWeekDays.map((day) => day.toLowerCase());
        break;

      case 'monthly':
        if (availability.value === MONTHLY_ON_DATE.value) {
          this.nth_day_in_month = Number(startDate.format('DD'));
        } else if (availability.value === MONTHLY_WEEKDAY.value) {
          const weekIndex = weekIndexOfMonth(startDate);
          this.frequency_interval = weekIndex;
          this.nth_weekday_in_month =
            MAP_WEEKDAYS_TO_NUMBERS[startDate.format('dddd').toLowerCase()];
        }
        break;

      default:
        break;
    }
  }
}
