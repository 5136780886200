/**
 * ************************************
 *
 * @module  apiMap.js
 * @author  Matt P
 * @date    07/02/2021
 * @description maps the correct endpoints and functionality depending
 * on vertical type
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import API from 'API';

// ----------------------------------------------------------------------------|
//                                  API Map
// ----------------------------------------------------------------------------|
const apiMap = {
  activity: {
    createCard: {
      endPoint: API.activityList,
      requestType: 'put',
    },
    deleteCard: {
      endPoint: API.activityDetail,
      requestType: 'delete',
    },
    editCard: {
      endPoint: API.activityDetail,
      requestType: 'put',
    },
    fetchCard: {
      endPoint: API.activityDetail,
      requestType: 'get',
    },
    retrieveList: {
      endPoint: API.activityList,
      requestType: 'post',
    },
    uploadImage: {
      endPoint: API.activityImage,
      requestType: 'post',
    },
  },
  collection: {
    createCard: {
      endPoint: API.collection,
      requestType: 'put',
    },
    deleteCard: {
      endPoint: API.collection,
      requestType: 'delete',
    },
    editCard: {
      endPoint: API.collection,
      requestType: 'patch',
    },
    fetchCard: {
      endPoint: API.collection,
      requestType: 'get',
    },
    retrieveList: {
      endPoint: API.collection,
      requestType: 'post',
    },
    uploadImage: {
      endPoint: API.collectionImage,
      requestType: 'post',
    },
  },
  curated: {
    // not implemented yet as may be depreciated
    deleteCard: {
      endPoint: API.curatedCard,
      requestType: 'delete',
    },
    fetchCard: {
      endPoint: API.curatedCard,
      requestType: 'get',
    },
    retrieveList: {
      endPoint: API.curatedCard,
      requestType: 'post',
    },
    // saveData: {
    //   endPoint: API.curatedCard,
    //   requestType: ['post', 'put'],
    // },
    uploadImage: {
      endPoint: API.curatedCardImage,
      requestType: 'post',
    },
  },
  event: {
    createCard: {
      endPoint: API.place,
      requestType: 'put',
    },
    deleteCard: {
      endPoint: API.place,
      requestType: 'delete',
    },
    editCard: {
      endPoint: API.place,
      requestType: 'put',
    },
    fetchCard: {
      endPoint: API.place,
      requestType: 'get',
    },
    retrieveList: {
      endPoint: API.place,
      requestType: 'post',
    },
    retrievePairings: {
      endPoint: API.pairings,
      requestType: 'get',
    },
    uploadImage: {
      endPoint: API.placeImage,
      requestType: 'post',
    },
  },
  flagged: {
    retrieveList: {
      endPoint: API.flagged,
      requestType: 'post',
    },
  },
  movie: {
    createCard: {
      endPoint: API.movieList,
      requestType: 'put',
    },
    deleteCard: {
      endPoint: API.movieDetail,
      requestType: 'delete',
    },
    editCard: {
      endPoint: API.movieDetail,
      requestType: 'put',
    },
    fetchCard: {
      endPoint: API.movieDetail,
      requestType: 'get',
    },
    retrieveList: {
      endPoint: API.movieList,
      requestType: 'post',
    },
    // uncuratedList: {
    //   endPoint: API.placeUncurated,
    //   requestType: 'post',
    // },
    uploadImage: {
      endPoint: API.movieImage,
      requestType: 'post',
    },
  },
  place: {
    createCard: {
      endPoint: API.place,
      requestType: 'put',
    },
    deleteCard: {
      endPoint: API.place,
      requestType: 'delete',
    },
    editCard: {
      endPoint: API.place,
      requestType: 'put',
    },
    fetchCard: {
      endPoint: API.place,
      requestType: 'get',
    },
    retrieveList: {
      endPoint: API.place,
      requestType: 'post',
    },
    retrievePairings: {
      endPoint: API.pairings,
      requestType: 'get',
    },
    uploadImage: {
      endPoint: API.placeImage,
      requestType: 'post',
    },
    uncuratedList: {
      endPoint: API.placeUncurated,
      requestType: 'post',
    },
  },
  recipe: {
    createCard: {
      endPoint: API.recipeList,
      requestType: 'put',
    },
    deleteCard: {
      endPoint: API.recipeDetail,
      requestType: 'delete',
    },
    editCard: {
      endPoint: API.recipeDetail,
      requestType: 'put',
    },
    fetchCard: {
      endPoint: API.recipeDetail,
      requestType: 'get',
    },
    retrieveList: {
      endPoint: API.recipeList,
      requestType: 'post',
    },
    uploadImage: {
      endPoint: API.recipeImage,
      requestType: 'post',
    },
  },
  universalSearch: {
    retrieveList: {
      endPoint: API.universalSearchList,
      requestType: 'post',
    },
  },
};

// ----------------------------------------------------------------------------|
//                                 Export
// ----------------------------------------------------------------------------|
export default apiMap;
