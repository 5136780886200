/**
 * ************************************
 *
 * @module FiltersFactory.js
 * @author  Matt P
 * @date    03/16/2021
 * @description Various functions used to generate the section filters
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';

import {
  CobReactSelect,
  CobReactMultiSelect,
  CobReactDateRangePicker,
  CobReactParentChildSelect,
} from 'components';

import { qualifyArrayRendering } from 'utils/utils';

import { filterSectionDropdownStyling } from 'components/CobReactSelect/SelectStyles';

// ----------------------------------------------------------------------------|
//                                 Utilities
// ----------------------------------------------------------------------------|
/**
 * @description - oder by dropdown options
 */
export const orderByOptions = [
  { label: 'Newest Modified', value: '-modified' },
  { label: 'Oldest Modified', value: 'modified' },
  { label: 'Newest Created', value: '-created' },
  { label: 'Oldest Created', value: 'created' },
];

export const flaggedReasonOptions = [
  { label: 'Internal', value: 'internal' },
  { label: 'Inaccurate', value: 'inaccurate' },
  { label: 'Inappropriate', value: 'inappropriate' },
];

// ----------------------------------------------------------------------------|
//                              Function Exports
// ----------------------------------------------------------------------------|
/**
 * @description - selects proper dropdown type by Key value
 */
export const FilterType = {
  SELECT: 'select-dropdown',
  MULTI_SELECT: 'multi-select-dropdown',
  DATE_RANGE_PICKER: 'date-range-picker',
};

/**
 * @description - preps location array of objects for drown down
 *
 * @param {Object[]} arr - of neighborhood objects
 *
 * @returns {Object[]}
 */
// Form options from array of objects returned from API
export const formLocationOptions = (arr) => {
  if (Array.isArray(arr)) {
    return arr.map((option) => ({
      value: option.unique_slug,
      label: option.name,
    }));
  }
  return [];
};

/**
 * @description Takes array of objects and prepares react select options
 *
 * @param {Array} arr list to alter
 * @param {String} value field for selected option value
 * @param {String} label field for option label
 *
 * @returns {Object[]}
 */
export const formTypeOptions = (arr, value, label) =>
  arr
    .reduce((resultArray, option) => {
      resultArray.push({ value: option[value], label: option[label] });
      return resultArray;
    }, [])
    .sort((a, b) => a.label.localeCompare(b.label));

/**
 * @description preps dropdown options that should be grouped by parent
 *
 * @param {Object[]} arrOfTags list to alter
 * @param {String} groupType what type of grouping (categories or neighborhoods)
 * @param {Any} value what you want the value of the grouping to be
 * @param {String} label field for option label
 *
 * @returns {Object[]}
 */
export const formGroupOptions = (arrOfTags, groupType, value, label) => {
  const groupedObj = {};

  // can add more groupings here to sort by 'parent' classification
  if (groupType === 'categories') {
    arrOfTags.forEach((arr) => {
      const { parent_categories } = arr;

      if (parent_categories.length === 0) {
        if (!groupedObj.Miscellaneous) {
          groupedObj.Miscellaneous = [arr];
        } else {
          groupedObj.Miscellaneous.push(arr);
        }
      } else {
        parent_categories.forEach((parents) => {
          if (!groupedObj[parents.name]) {
            groupedObj[parents.name] = [arr];
          } else {
            groupedObj[parents.name].push(arr);
          }
        });
      }
    });
  }

  if (groupType === 'neighborhoods') {
    arrOfTags.forEach((arr) => {
      if (arr.county) {
        if (!groupedObj[arr.county]) groupedObj[arr.county] = [arr];
        else groupedObj[arr.county].push(arr);
      } else if (!groupedObj.City) groupedObj.City = [arr];
      else groupedObj.City.push(arr);
    });
  }

  return Object.entries(groupedObj).map((group) => ({
    label: group[0],
    options:
      groupType === 'categories'
        ? formTypeOptions(group[1], value, label)
        : formLocationOptions(group[1]),
  }));
};

/**
 * @description Return object for ratings from 1-5 used for rating dropdown
 *
 * @returns {Object[]}
 */
// Return object for ratings from 1-5
export const formRatingOptions = () =>
  [...Array(5).keys()].map((i) => ({
    value: String(i + 1),
    label: String(i + 1),
  }));
export default class FiltersFactory {
  static createFilter(filterConfig) {
    const {
      name,
      options,
      returnType,
      value,
      onFilterSelect,
      filterType,
      onMenuClose,
      isSearchable,
      parentPlaceholder,
      childPlaceholder,
      isClearable,
      parentReturnType,
      childReturnType,
      parentValue,
      childValue,
    } = filterConfig;

    switch (filterType) {
      default:
      case FilterType.SELECT:
        return (
          <CobReactSelect
            className="filter-dropdown"
            styles={filterSectionDropdownStyling()}
            key={name}
            selectedItems={value}
            options={options}
            onSelect={(data) => {
              onFilterSelect(returnType, data, onMenuClose);
            }}
            placeholder={name}
            name={name}
            value={value}
            isSearchable={isSearchable}
          />
        );
      case FilterType.MULTI_SELECT:
        return (
          <CobReactMultiSelect
            className="filter-dropdown"
            key={name}
            selectedItems={value}
            options={options}
            onSelect={(data) => onFilterSelect(returnType, data)}
            placeholder={name}
            name={name}
            onMenuClose={onMenuClose}
            onSelectionCleared={onMenuClose}
            isSearchable={isSearchable}
          />
        );
      case FilterType.DATE_RANGE_PICKER:
        return (
          <CobReactDateRangePicker
            key={name}
            isClearable
            className="date-range-filter filter-dropdown"
            selectedDateRange={value}
            name={name}
            onSelect={(selectedDate) =>
              onFilterSelect(returnType, selectedDate)
            }
            onMenuClose={onMenuClose}
          />
        );
      case FilterType.PARENT_CHILD_SELECT:
        return (
          <CobReactParentChildSelect
            key={name}
            options={options}
            onSelect={(selectType, data) => {
              onFilterSelect(selectType, data, onMenuClose);
            }}
            parentPlaceholder={parentPlaceholder}
            childPlaceholder={childPlaceholder}
            isClearable={isClearable}
            isSearchable={isSearchable}
            parentReturnType={parentReturnType}
            childReturnType={childReturnType}
            parentValue={parentValue}
            childValue={childValue}
          />
        );
    }
  }
}
