/**
 * ************************************
 *
 * @module  Category.js
 * @author  Matt P
 * @date    03/03/2021
 * @description Container for the category modal component for creating
 * or editing a category
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import Switch from 'react-switch';

import { connect } from 'react-redux';

import FormInput from 'components/Form/FormInput/FormInput';

import {
  Button,
  Modal,
  Checkbox,
  DropDown,
  Tooltip,
  CobReactTagSelect,
} from 'components';

import infoIcon from 'assets/images/info.svg';
import closeIcon from 'assets/images/close.png';

import { CATEGORY } from 'constants.js';

import { setCookie, getCookie } from 'utils/CookieFunc';

import {
  createCategoryAction,
  editCategoryAction,
} from 'store/actions/Categories.actions';

import {
  categoryFormFields,
  switchStyling,
  createTagOptions,
  tagDropdownStyling,
  emojiStringErrorChecking,
  filterInitialTagOptions,
} from '../CategoriesUtils';

import './Category.scss';

// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  createCategory: (data) => dispatch(createCategoryAction(data)),
  editCategory: (data) => dispatch(editCategoryAction(data)),
});

const mapStateToProps = (state) => ({
  parents: state.categories.parents,
  categories: state.categories.categories,
});

// ----------------------------------------------------------------------------|
//                   React Function Component - Category
// ----------------------------------------------------------------------------|
const Category = ({
  showModal,
  parents,
  closeModal,
  createCategory,
  editCategory,
  isEditing,
  passedCategory,
  categories,
}) => {
  const initialState = {
    name: passedCategory ? passedCategory.name : '',
    nameErrorMsg: '',
    is_featured: passedCategory ? passedCategory.is_featured : false,
    is_enabled: passedCategory ? passedCategory.is_enabled : false,
    cta_title_long: passedCategory ? passedCategory.cta_title_long : '',
    cta_title_short: passedCategory ? passedCategory.cta_title_short : '',
    parents_unique_slugs: passedCategory
      ? createTagOptions(passedCategory.parent_categories)
      : [],
    parentErrorMsg: '',
    emoji_icon: passedCategory ? passedCategory.icon : '',
    emojiErrorMsg: '',
  };

  // checks cookie and will display parents tooltip if no cookie
  // is present
  const [hasSeenTooltip, setHasSeenTooltip] = useState(
    getCookie('categories-tooltip')
  );
  const [showTooltip, setShowTooltip] = useState(!hasSeenTooltip);

  const [formData, setFormData] = useState(initialState);

  /**
   * @description changes the form data based on key passed
   *
   * @param {String} field
   * @param {String} value
   */
  const eventHandler = (field, value) => {
    const formDataCopy = { ...formData };

    formDataCopy[field] = value;

    setFormData({ ...formDataCopy });
  };

  /**
   * @description validates submitted form data
   */
  const validFormData = () => {
    const errorObj = {};

    errorObj.nameErrorMsg =
      !formData.name || !formData.name.trim() ? CATEGORY.NAME_ERR_MSG : '';

    errorObj.emojiErrorMsg = !emojiStringErrorChecking(formData.emoji_icon)
      ? 'Please add a valid emoji icon'
      : '';

    setFormData({ ...formData, ...errorObj });

    return !errorObj.nameErrorMsg && !errorObj.emojiErrorMsg;
  };

  /**
   * @description submits form to redux, checks if this is a new cat or edit
   */
  const submitForm = () => {
    if (isEditing && validFormData()) {
      const {
        cta_title_short,
        emoji_icon,
        name,
        parents_unique_slugs,
        cta_title_long,
        is_featured,
        is_enabled,
      } = formData;

      const apiData = {
        name,
        emoji_icon,
        is_enabled,
        is_featured,
        cta_title_long,
        cta_title_short,
        parents_unique_slugs: parents_unique_slugs.map(
          (slugs) => slugs.value.unique_slug
        ),
        unique_slug: passedCategory.unique_slug,
      };

      editCategory({ passedCategory, apiData });
      closeModal();
    } else if (validFormData()) {
      const {
        cta_title_short,
        emoji_icon,
        name,
        parents_unique_slugs,
        cta_title_long,
        is_featured,
        is_enabled,
      } = formData;

      const apiData = {
        name,
        emoji_icon,
        is_enabled,
        is_featured,
        cta_title_long,
        cta_title_short,
        parents_unique_slugs: parents_unique_slugs.map(
          (slugs) => slugs.value.unique_slug
        ),
      };

      createCategory(apiData);
      closeModal();
    }
  };

  /**
   * @description returns footer jsx
   *
   * @returns {JSX}
   */
  const designFooter = () => (
    <div className="modal-actions">
      <Button
        name="SAVE"
        type="button"
        className="btn action-btn"
        onClick={submitForm}
      >
        SAVE
      </Button>
    </div>
  );

  /**
   * @description On change function passed to <Select>
   *
   * @returns {Array} Array of mapped objects
   */
  const onSelectChange = (selectedValue) => {
    // On clearing filter, empty object is sent to back end
    const option = selectedValue || [];

    eventHandler('parents_unique_slugs', option);
  };

  const title = isEditing
    ? CATEGORY.EDIT_CATEGORY_TITLE
    : CATEGORY.NEW_CATEGORY_TITLE;

  return (
    <Modal
      title={title}
      customClasses="categories-modal"
      show={showModal}
      modalClosed={closeModal}
      modalFooter={designFooter()}
    >
      <form name="new-category" className="category-form">
        {categoryFormFields.map((field, index) => {
          const { label, placeholder, type, value } = field;
          const options = {
            type,
            name: label,
            label,
            value: '',
          };

          if (type === 'checkbox') {
            const classNames = `new-category-checkbox ${
              value === 'is_child' ? 'child-of' : 'togglers'
            }`;
            return (
              <div className={classNames} key={label}>
                <div className="checkbox-container">
                  <Checkbox
                    key={`checkbox-${index}`}
                    className="square"
                    onClick={(result) => eventHandler(value, result)}
                    label={label}
                  />
                  <label htmlFor={label}>{label}</label>
                </div>
              </div>
            );
          }

          if (type === 'tag') {
            return (
              <div className="form-input" key={`react-select-${index}`}>
                <label className="form-input-parent-label">
                  {label}
                  <span>
                    <a data-tip data-for="parent-info-tooltip" data-event="">
                      <img
                        src={infoIcon}
                        alt="form-select-input-info-icon"
                        onClick={() => {
                          setShowTooltip(!showTooltip);

                          if (!hasSeenTooltip)
                            setCookie('categories-tooltip', 'true', 60);
                        }}
                        role="button"
                      />
                    </a>
                    <Tooltip
                      id="parent-info-tooltip"
                      className="parent-info-tooltip-container"
                      place="right"
                      type="info"
                      effect="solid"
                      backgroundColor="#ffffff"
                      clickable
                      show={showTooltip}
                    >
                      <div className="form-input-close-icon-container">
                        <img
                          role="button"
                          src={closeIcon}
                          className="form-select-input-close-icon"
                          alt="form-select-input-close-icon"
                          onClick={() => {
                            setShowTooltip(!showTooltip);

                            if (!hasSeenTooltip)
                              setCookie('categories-tooltip', 'true', 60);
                          }}
                        />
                      </div>
                      <div>
                        <p>
                          {`Parents are used to organize other categories. A child
                        category should never have conflicting parents (e.g.
                        Italian cuisine should not have parents of Restaurant
                        and Recipe).`}
                        </p>
                      </div>
                    </Tooltip>
                  </span>
                </label>
                <CobReactTagSelect
                  placeholder="Add parent category"
                  options={createTagOptions(
                    filterInitialTagOptions(
                      passedCategory ? passedCategory.parent_categories : [],
                      categories
                    )
                  )}
                  name="parent-tag"
                  value={formData.parents_unique_slugs}
                  styles={tagDropdownStyling()}
                  onChange={onSelectChange}
                  closeMenuOnSelect={false}
                />
              </div>
            );
          }

          if (type === 'switch') {
            const classNames = `new-category-checkbox ${
              value === 'is_child' ? 'child-of' : 'togglers'
            }`;

            return (
              <div className={classNames} key={label}>
                <div className="switch-container">
                  <Switch
                    onChange={(result) => eventHandler(value, result)}
                    checked={formData[value]}
                    {...switchStyling()}
                  />
                  <label htmlFor={label}>{label}</label>
                </div>
              </div>
            );
          }

          if (type === 'text') {
            if (label === 'Category Name') {
              options.errorMsg = formData.nameErrorMsg;
              options.maxLength = CATEGORY.NAME_MAX_LENGTH;
            }

            if (label === 'Emoji Icon') {
              options.errorMsg = formData.emojiErrorMsg;
            }

            if (isEditing && passedCategory) {
              if (value === 'emoji_icon') {
                options.value = passedCategory.icon;
              } else if (value === 'name') {
                options.value = passedCategory.name;
              }
            }

            return (
              <FormInput
                key={label}
                type={type}
                placeholder={placeholder}
                options={options}
                eventHandler={(result) =>
                  eventHandler(value, result.target.value)
                }
              />
            );
          }

          if (type === 'dropdown' && formData.is_child) {
            const { parent } = formData;

            return (
              <Fragment>
                <DropDown
                  key={value}
                  name="parent"
                  value={parent}
                  options={parents}
                  onSelect={(event) => eventHandler(value, event)}
                />
                <span className="error">{formData.parentErrorMsg}</span>
              </Fragment>
            );
          }

          return null;
        })}
      </form>
    </Modal>
  );
};

// --------------------------------------------------------------------------|
//                             PropTypes Check
// --------------------------------------------------------------------------|
Category.propTypes = {
  showModal: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  createCategory: PropTypes.func.isRequired,
  editCategory: PropTypes.func,
  isEditing: PropTypes.bool,
  passedCategory: PropTypes.any,
};

// --------------------------------------------------------------------------|
//                              Default Props
// --------------------------------------------------------------------------|
Category.defaultProps = {
  isEditing: false,
  passedCategory: null,
  editCategory: () => {},
};

// ----------------------------------------------------------------------------|
//                    Category Export with Redux Connect
// ----------------------------------------------------------------------------|
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Category)
);
