import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import { Card, InfiniteScroller } from 'components';

import { getImageURL, getDateBasedOnStatus } from 'utils/utils';

import { GENERIC } from 'constants.js';

const EventContent = (props) => {
  const {
    data,
    hasMore,
    onMoreData,
    onMenuClicked,
    hideMenu,
    onCheckBoxSelected,
    isChecked,
  } = props;

  const {
    SUB_MENU: { EDIT },
  } = GENERIC;

  const generateContent = () => {
    let res = null;
    if (Array.isArray(data)) {
      res = data.map((item) => {
        const {
          id,
          published_date: publishedDate,
          modified: modifiedDate,
          status,
          images,
          name,
          phrase,
          is_expired: isExpired,
          last_admin_review,
        } = item;
        const imageURL = getImageURL(images);
        return (
          <Card
            key={id}
            id={id}
            className="card"
            onMenuClick={(value) => onMenuClicked(value, item)}
            onClick={() => onMenuClicked(EDIT, item)}
            onCheck={onCheckBoxSelected}
            isChecked={isChecked}
            hideMenu={hideMenu}
            date={getDateBasedOnStatus(status, modifiedDate, publishedDate)}
            status={status}
            imageUrl={imageURL}
            title={name}
            phrase={phrase}
            isExpired={isExpired}
            adminReview={last_admin_review}
          />
        );
      });
    } else {
      return [];
    }
    return <div className="card-container">{res}</div>;
  };

  const content = generateContent();
  return (
    <InfiniteScroller
      className="event-content"
      loadMore={onMoreData}
      hasMore={hasMore}
      useWindow={!hideMenu}
    >
      {content}
    </InfiniteScroller>
  );
};

EventContent.propTypes = {
  data: PropTypes.array.isRequired,
  onMoreData: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  onMenuClicked: PropTypes.func,
  onCheckBoxSelected: PropTypes.func,
  hideMenu: PropTypes.bool,
  isChecked: PropTypes.any,
};

EventContent.defaultProps = {
  onMenuClicked: () => {},
  onCheckBoxSelected: () => {},
  hideMenu: false,
  isChecked: null,
};

export default EventContent;
