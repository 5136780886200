/**
 * ************************************
 *
 * @module  DropdownButton.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Toggles Calender dropdown for date range selection
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import { formatDateRange } from 'utils/utils';

import clearIcon from 'assets/images/react-select--clear.svg';
import arrowDownIcon from 'assets/images/react-select--arrowDown.svg';
import 'components/CobReactDateRangePicker/DropdownButton/DropdownButton.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
/**
 * @description
 * React UI Component - part of DateRangePickerDropdown.
 * This component is shown in the UI when `DateRangePickerDropdown`
 * is in closed state. It only shows the selected option's label.
 *
 * @param {Object} props - Contains two necessary properties
 * toggleHandler - a callback, invoked on click of this label which opens
 * the panel selectedOption - option to be displayed as selected.
 */
const DropdownButton = ({
  toggleHandler,
  onDropdownCleared,
  isClearable,
  selectedDateRange,
  placeholder,
}) => {
  /**
   * @description gets dropdown label and formats date range
   *
   * @param {Object} dateRange - New instance of Googlemaps Constructor
   *
   * @returns {String} Either placeholder string of date formatted string
   */
  const getDropdownLabel = (dateRange) => {
    if (dateRange && dateRange.start && dateRange.end) {
      return formatDateRange(dateRange.start, dateRange.end);
    }
    return placeholder;
  };

  return (
    <Fragment>
      <span
        role="button"
        className={cx([
          'display-text',
          { 'display-range': selectedDateRange && selectedDateRange.start },
        ])}
        onClick={toggleHandler}
      >
        {getDropdownLabel(selectedDateRange)}
      </span>
      <span className="dropdown-controls">
        {isClearable && selectedDateRange.start && selectedDateRange.end && (
          <span
            role="button"
            className="svg-wrapper"
            onClick={onDropdownCleared}
          >
            <img src={clearIcon} alt="reset date range" />
          </span>
        )}
        <span role="button" className="svg-wrapper" onClick={toggleHandler}>
          <img src={arrowDownIcon} alt="open date range picker" />
        </span>
      </span>
    </Fragment>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
DropdownButton.propTypes = {
  toggleHandler: PropTypes.func.isRequired,
  selectedDateRange: PropTypes.object,
  placeholder: PropTypes.string.isRequired,
  isClearable: PropTypes.bool,
  onDropdownCleared: PropTypes.func,
};

DropdownButton.defaultProps = {
  selectedDateRange: {
    start: null,
    end: null,
  },
  isClearable: false,
  onDropdownCleared: null,
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default DropdownButton;
