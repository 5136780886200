/**
 * ************************************
 *
 * @module  CardBanner.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description CardBanner functional component. Which is used to
 * render a cards banner located above the card section and below
 * the cobble Navbar component.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                    Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import { SearchBar, DropDown, Button } from 'components';

import { NEW_BUTTON_TEXT } from 'constants.js';

import 'components/CardBanner/CardBanner.scss';

// ----------------------------------------------------------------------------|
//                      React Function Component - CardBanner
// ----------------------------------------------------------------------------|
const CardBanner = (props) => {
  const {
    title,
    newBtnClick,
    query,
    isSearchEnabled,
    searchEvent,
    isDropdownEnabled,
    isNewButtonEnabled,
    dropdownValue,
    dropdownOptions,
    dropdownDefaultOption,
    onDropdownSelect,
  } = props;
  /**
   * @description labelConst decides create new button text based on constants
   * file entries
   */
  const labelConst = title.toLowerCase().replace(/\s/g, '_');

  return (
    <div className="card-banner">
      <div className="header-details">
        <h1 className="page-title">{title}</h1>
        {isDropdownEnabled && (
          <DropDown
            options={dropdownOptions}
            name="dropdown"
            onSelect={onDropdownSelect}
            className="card-banner-dropdown-search"
            controlClassName="card-banner-dropdown-control"
            placeholderClassName="card-banner-dropdown-placeholder"
            menuClassName="card-banner-dropdown-menu"
            arrowClassName="card-banner-dropdown-arrow"
            placeholder="placeholder"
            value={dropdownValue}
            defaultOption={dropdownDefaultOption}
          />
        )}
        {isSearchEnabled && (
          <SearchBar
            className="events-search"
            name="search"
            value={query}
            onSubmit={searchEvent}
          />
        )}
      </div>
      {isNewButtonEnabled && (
        <Button onClick={() => newBtnClick({})}>
          {NEW_BUTTON_TEXT[labelConst]}
        </Button>
      )}
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                       PropTypes Check - CardBanner
// ----------------------------------------------------------------------------|
CardBanner.propTypes = {
  title: PropTypes.string.isRequired,
  newBtnClick: PropTypes.func.isRequired,
  searchEvent: PropTypes.func,
  isSearchEnabled: PropTypes.bool,
  isDropdownEnabled: PropTypes.bool,
  isNewButtonEnabled: PropTypes.bool,
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  query: PropTypes.string,
  dropdownDefaultOption: PropTypes.string,
  onDropdownSelect: PropTypes.func,
};

CardBanner.defaultProps = {
  searchEvent: () => {},
  isDropdownEnabled: false,
  isNewButtonEnabled: true,
  isSearchEnabled: false,
  query: '',
  dropdownOptions: [],
  dropdownDefaultOption: '',
  onDropdownSelect: () => {},
};

// ----------------------------------------------------------------------------|
//                              CardBanner Export
// ----------------------------------------------------------------------------|
export default CardBanner;
