/**
 * ************************************
 *
 * @module  FlaggedCardNotice.tsx
 * @author  Matt P
 * @date    06/12/2022
 * @description Red banner component which notifies writers on the flagged
 * content
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import { closeFlagReport } from 'utils/FlagCardUtils';
import FlaggedCardBanner from './FlaggedCardBanner/FlaggedCardBanner';

import cx from 'classnames';

import './FlaggedCardNotice.scss';

// ----------------------------------------------------------------------------|
//                          React Function Component
// ----------------------------------------------------------------------------|
const FlaggedCardNotice = ({
  flaggedCardData = [],
  className,
  successCallback,
}: {
  className?: string;
  flaggedCardData: any;
  successCallback: () => void;
}): JSX.Element => {
  const containerClass = cx(['flagged-card-notice-container', `${className}`]);

  return (
    <div className={containerClass}>
      {flaggedCardData.map((flagged: any) => {
        const {
          created,
          additional_information,
          report_type,
          owner = {},
          id,
        } = flagged;

        return (
          <FlaggedCardBanner
            flaggedDate={created}
            flaggedDescription={additional_information}
            flaggedReason={report_type}
            flaggedBy={owner.name}
            onClick={() => {
              closeFlagReport(id, successCallback);
            }}
          />
        );
      })}
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                                PropTypes Check
// ----------------------------------------------------------------------------|
FlaggedCardNotice.propTypes = {
  className: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                                  Default Props
// ----------------------------------------------------------------------------|
FlaggedCardNotice.defaultProps = {
  className: '',
};

// ----------------------------------------------------------------------------|
//                                     Export
// ----------------------------------------------------------------------------|
export default FlaggedCardNotice;
