/**
 * ************************************
 *
 * @module  FetchedCard.js
 * @author  Matt P
 * @date    10/15/2021
 * @description FetchedCard component displays fetched 'cards' which extend
 * to the end of the container and are not in single 'Card' style.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import { Button, Views } from 'components';

import { REVIEW_AND_PUBLISH } from 'constants.js';

import { getPrice, sanitizeUrl } from 'utils/utils';

import LanguageCodes from 'utils/LanguageCodes';

import './FetchedCard.scss';

/**
 * @description generates neighborhood strings
 *
 * @param {Array} list - of neighborhoods
 *
 * @returns {String} - of neighborhoods
 */
const prepareNeighborhoodList = (list = []) => {
  // Checking if data is present and data is in array format
  if (list && Array.isArray(list)) {
    const res = list.reduce((acc, item) => `${acc + item.name}, `, '');
    // Remove last two characters
    return res.slice(0, -2);
  }
  return '-';
};

/**
 * @description generates fetched data rating string
 *
 * @param {Number} rating - of the location
 *
 * @returns {String}
 */
const generateRating = (rating) => (rating ? `${rating / 2}/5` : '-');

/**
 * @description generates fetched data categories string
 *
 * @param {Array} categories - of objects
 *
 * @returns {String}
 */
const generateCategories = (categories) => {
  if (categories && Array.isArray(categories)) {
    if (typeof categories[0] === 'string') {
      return categories.join(', ');
    }
    return categories.map((item) => item.name).join(', ');
  }
  return '-';
};

/**
 * @description generates fetched data main categories string
 *
 * @param {Array} categories - of strings
 *
 * @returns {String}
 */
const generatefsCategories = (categories) => {
  if (categories && categories.length) {
    return categories.join();
  }
  return '-';
};

/**
 * @description Formats address to be displayed in a <p> tag
 *
 * @param {String} address - of strings
 *
 * @returns {JSX[]}
 */
const formatAddress = (address) => {
  if (address) {
    address.split(', ').map((addr) => <p key={`address-p-${addr}`}>{addr}</p>);
  } else {
    return <p></p>;
  }
}

// ----------------------------------------------------------------------------|
//                          React Function Component
// ----------------------------------------------------------------------------|
const FetchedCard = ({
  address,
  className,
  categories,
  fsCategories,
  fourthLayer,
  imageURL,
  language,
  name,
  neighborhoods,
  onReviewClick,
  popularity,
  priceTier,
  rating,
  runtime,
  verticalType,
}) => {
  const containerClass = cx(['fetch-card-details', `${className}`]);

  const categoriesList = generateCategories(categories);
  // const neighborhoodList = prepareNeighborhoodList(neighborhoods);
  const price = getPrice(priceTier);
  const rate = generateRating(rating);
  const fscategories = generatefsCategories(fsCategories);

  const nativeLanguage =
    LanguageCodes[language] && LanguageCodes[language].name
      ? LanguageCodes[language].name
      : '-';

  /**
   * @description - Card attributes are slightly different based on
   * vertical type. This switch chooses the correct one.
   *
   * @returns {JSX}
   */
  const chooseVertFetchedCardContent = () => {
    switch (verticalType) {
      case 'place': {
        return (
          <Fragment>
            <div className="address-container">
              <p className="address">Address: </p>
              <p className="address-list">{formatAddress(address)}</p>
            </div>
            <div className="details">
              <p className="cost">Cost: {price}</p>
              <p className="ratings">Rating: {rate}</p>
            </div>
            <div className="fscategories">
              <p className="fscategories-label">Categories:</p>
              <p>{fscategories}</p>
            </div>
          </Fragment>
        );
      }
      case 'movie': {
        return (
          <Fragment>
            <div className="details">
              <p>Runtime: {runtime ? `${runtime} minutes` : '-'}</p>
            </div>
            <div className="details">
              <p>Language: {nativeLanguage}</p>
              <p className="ratings">Rating: {rate}</p>
            </div>
          </Fragment>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className={containerClass}>
      <div className="img-container">
        <img src={sanitizeUrl(imageURL)} alt="cover" className="cover-pic" />
      </div>
      <div className="content">
        <h5 className="categories">{categoriesList}</h5>
        <h1 className="name">{name}</h1>
        {chooseVertFetchedCardContent()}
        <Button className="review-btn" onClick={onReviewClick}>
          {REVIEW_AND_PUBLISH}
        </Button>
      </div>
      {fourthLayer ? <Views data={popularity} /> : ''}
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                                PropTypes Check
// ----------------------------------------------------------------------------|
FetchedCard.propTypes = {
  address: PropTypes.string,
  className: PropTypes.string,
  categories: PropTypes.array,
  fsCategories: PropTypes.array,
  fourthLayer: PropTypes.bool,
  imageURL: PropTypes.string,
  language: PropTypes.string,
  name: PropTypes.string.isRequired,
  neighborhoods: PropTypes.array,
  onReviewClick: PropTypes.func.isRequired,
  popularity: PropTypes.number,
  priceTier: PropTypes.string,
  rating: PropTypes.number,
  runtime: PropTypes.number,
  verticalType: PropTypes.string.isRequired,
};

// ----------------------------------------------------------------------------|
//                                  Default Props
// ----------------------------------------------------------------------------|
FetchedCard.defaultProps = {
  imageURL: '',
  categories: [],
  neighborhoods: [],
  priceTier: '',
  rating: '-',
  className: '',
  fsCategories: [],
  fourthLayer: false,
  address: '',
};

// ----------------------------------------------------------------------------|
//                                     Export
// ----------------------------------------------------------------------------|
export default FetchedCard;
