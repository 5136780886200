/**
 * ************************************
 *
 * @module  PlaceHours.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description Displays the Place hours on the Form component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { EditPopUpModal } from 'components';

import 'components/PlaceHours/PlaceHours.scss';

// ----------------------------------------------------------------------------|
//                  React Class PureComponent - PlaceHours
// ----------------------------------------------------------------------------|
class PlaceHours extends PureComponent {
  constructor(props) {
    super(props);

    this.handleStateChange = this.handleStateChange.bind(this);
    this.state = {
      flag: false,
    };
  }

  /**
   * @description Essentially a bool for any errors
   *
   * @param {String} value - Error message string. Empty is no error
   */
  handleStateChange(value) {
    let { flag } = this.state;

    value ? (flag = true) : (flag = false);

    this.setState({ flag });
  }

  /**
   * @description Renders child elements for timer
   *
   * @param {String} key - Weekday name and unique key for JSX elements
   * @param {Object} childElement - Object containing data for specific day
   *
   * @return {JSX} - Elements to be rendered to the DOM, will represent
   * a day and hours of operation
   */
  renderChildElement = (key, childElement) => {
    const timingElement = [];
    if (childElement.status === 'CLOSED') {
      timingElement.push('Closed');
    } else if (childElement.status === 'NA') {
      timingElement.push('Not Available');
    } else if (childElement.status === 'VARY') {
      timingElement.push('Hours may vary');
    } else if (
      childElement.slots &&
      childElement.status === 'OPEN' &&
      (childElement.slots[0].join(' ') === ['00:00:00', '23:59:59'].join(' ') || childElement.slots[0].join(' ') === ['00:00:00', '00:00:00'].join(' '))
    ) {
      timingElement.push('Open 24 hours');
    } else {
      childElement.slots.forEach((item, index) => {
        timingElement.push(
          <div className="time-seperator" key={index}>
            {`${moment(item[0], 'hh:mm:ss').format('hh:mm a')} - ${moment(
              item[1],
              'hh:mm:ss'
            ).format('hh:mm a')}`}
          </div>
        );
      });
    }

    return (
      <div className="weekday-timings-container" key={key}>
        <div className="weekday">{key}</div>
        <div className="weekday-timings">{timingElement}</div>
      </div>
    );
  };

  /**
   * @description Renders <div> with times
   *
   * @param {Object} inputObj - Object containing data for week
   *
   * @return {JSX} - <div> element to be rendered to the DOM, contains
   * all weekday hours
   */
  renderInput = (inputObj) => {
    const { from } = this.props;
    // props.from is an object with weekday hours and operation status
    const data = inputObj || from;
    const timingContainer = [];
    // Object to build/sort the weekday data in order
    const sorter = {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7,
    };

    const tmp = [];

    if (data) {
      Object.keys(data).forEach((key) => {
        const value = data[key];
        const index = sorter[key.toLowerCase()];
        tmp[index] = {
          key,
          value,
        };
      });

      const orderedData = {};

      tmp.forEach((obj) => {
        orderedData[obj.key] = obj.value;
      });

      for (const key in orderedData) {
        if (Object.prototype.hasOwnProperty.call(orderedData, key)) {
          timingContainer.push(this.renderChildElement(key, orderedData[key]));
        }
      }
    }

    return <div>{timingContainer}</div>;
  };

  render() {
    const {
      from,
      onAction,
      errors,
      editHoursError,
      onChange,
      showEditHours,
      saveClick,
    } = this.props;
    const { flag } = this.state;

    return (
      <div className="place-hours">
        <label className="hours-range-label">Hours</label>
        <div className="timer">{this.renderInput(from)}</div>
        <button
          name="preview"
          type="button"
          className="edit-btn"
          disabled={false}
          onClick={onAction}
        >
          Edit Hours
        </button>
        {showEditHours && (
          <EditPopUpModal
            error={errors}
            editHoursError={editHoursError}
            data={from}
            cancelClick={onAction}
            onChange={onChange}
            handleStateChange={this.handleStateChange}
            saveClick={(event, data) => {
              event.preventDefault();
              // prevents database update if flag is true,
              // flag is true when there is an error
              if (!flag) {
                // passes obj with updated weekly hours data
                saveClick(event, data);
              }
            }}
            renderInput={this.renderInput}
          />
        )}
        <div className="error">{errors}</div>
      </div>
    );
  }
}

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
PlaceHours.propTypes = {
  from: PropTypes.object,
  onChange: PropTypes.func,
  saveClick: PropTypes.func.isRequired,
  errors: PropTypes.string,
  onAction: PropTypes.func.isRequired,
  showEditHours: PropTypes.bool.isRequired,
  editHoursError: PropTypes.object.isRequired,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
PlaceHours.defaultProps = {
  from: {},
  errors: '',
  onChange: () => {},
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default PlaceHours;
