/**
 * ************************************
 *
 * @module  RecipeService.js
 * @author  Matt P
 * @date    02/02/2021
 * @description File to prepare Recipe form
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import CommonService from 'services/CommonService';

import { isValidURL } from 'utils/utils';

import { filterEmptyMultiLinks, trimItemizedDescription } from 'utils/FormFunc';

const commonService = CommonService();

// ----------------------------------------------------------------------------|
//                             Recipe Service
// ----------------------------------------------------------------------------|
const RecipeService = {
  getPreparedFormData(data, uploadedImages, placeStatus) {
    const {
      phrase,
      source_name,
      duration,
      prep_time,
      title,
      main_categories,
      cta_title_short,
      description,
      links_to_recipe,
      cta_title_long,
      categories,
      admin_review,
      itemized_description,
      ingredients,
      meal_type,
      diet,
    } = data;

    return {
      phrase: phrase ? phrase.trim() : '',
      title,
      cta_title_long,
      cta_title_short,
      duration,
      prep_time,
      main_categories,
      links_to_recipe:
        typeof links_to_recipe === 'string'
          ? [links_to_recipe]
          : links_to_recipe,
      categories: [...categories, ...meal_type, ...diet],
      images: uploadedImages,
      status: placeStatus,
      source_name,
      description: description ? description.trim() : '',
      admin_review,
      itemized_description: trimItemizedDescription(
        itemized_description,
        'recipe'
      ),
      // converts Ingredients from array of strings to array of
      // obj {name: '', quantity: ''}
      ingredients: filterEmptyMultiLinks(ingredients).map((ing) => ({
        name: ing,
        quantity: '',
      })),
    };
  },

  /**
   * @description error checking for 'publish' form submission
   *
   * @param {Object} data - formData
   *
   * @returns {Object} of errors
   */
  validate(data) {
    const errorsObj = {
      ...this.validateRecipeTitle(data.title),
      ...commonService.validateItemizedDescription(data.itemized_description),
    };

    // checks categories
    if (
      !commonService.validatePrimaryCategories(
        data.main_categories,
        data.categories
      )
    ) {
      errorsObj.categories =
        'Primary categories must be included in the selected categories';
    }

    if (
      data.categories &&
      Array.isArray(data.categories) &&
      data.categories.length === 0
    ) {
      errorsObj.categories = 'At least one category is required';
    }

    if (
      data.meal_type &&
      Array.isArray(data.meal_type) &&
      data.meal_type.length === 0
    ) {
      errorsObj.meal_type = 'At lease one meal type is required';
    }

    if (
      (typeof data.links_to_recipe === 'string' &&
        !isValidURL(data.links_to_recipe)) ||
      (Array.isArray(data.links_to_recipe) &&
        data.links_to_recipe.length &&
        !isValidURL(data.links_to_recipe[0]))
    ) {
      errorsObj.links_to_recipe = 'Please enter a valid URL';
    }

    if (data.phrase.length < 10 || data.phrase.length > 250) {
      errorsObj.phrase =
        'Recipe description should be min 10 and max 250 characters';
    }

    if (data.duration < 0) {
      errorsObj.duration = 'Duration is required';
    }

    if (data.prep_time < 0) {
      errorsObj.prep_time = 'Prep time is required';
    }

    return errorsObj;
  },

  validateRecipeTitle(titleString) {
    if (titleString.length < 1 || titleString > 100) {
      return { title: 'Recipe title should be min 1 and max 100 characters' };
    }

    return {};
  },

  /**
   * @description saves data to redux based on element name and
   * value passed in
   *
   * @param {Any} event - data being passed || element name
   * @param {String} elemName - name of that input
   *
   * @returns {Object}
   */
  getDataToBeSaved(event, elemName) {
    if (
      elemName === 'categories' ||
      elemName === 'main_categories' ||
      elemName === 'links_to_recipe' ||
      elemName === 'admin_review' ||
      elemName === 'ingredients' ||
      elemName === 'itemized_description' ||
      elemName === 'meal_type' ||
      elemName === 'diet'
    ) {
      return { [elemName]: event };
    }

    if (Array.isArray(event)) {
      return { [elemName]: event.map((each) => each.name) };
    }

    if (elemName === 'duration' || elemName === 'prep_time') {
      return { [elemName]: event.value };
    }

    return { [event.target.name]: event.target.value };
  },

  defaultDescriptions: [
    {
      header: 'What it is',
      body: '',
    },
    {
      header: 'Difficulty',
      body: '',
    },
    {
      header: 'Servings',
      body: '',
    },
    {
      header: 'Nutritional facts',
      body: '',
    },
    {
      header: 'Ingredients',
      body: '',
    },
  ],
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default RecipeService;
