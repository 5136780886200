/**
 * ************************************
 *
 * @module  City.js
 * @author  Matt P
 * @date    04/25/2022
 * @description Container for the city edit modal component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import Switch from 'react-switch';

import { connect } from 'react-redux';

import FormInput from 'components/Form/FormInput/FormInput';

import {
  Button,
  CardViewModal,
  CobTable,
  DeleteModal,
  ThirdPartySearchModal,
} from 'components';

import { CITIES } from 'constants.js';

import {
  createCitiesAction,
  deleteCityAction,
  editCitiesAction,
} from 'store/actions/Cities.action';

import { qualifyArrayRendering } from 'utils/utils';

import { citiesCols, cityFormFields, switchStyling } from '../CitiesUtils';

import './City.scss';

const VERTICAL_TYPE = 'neighborhood';

// ----------------------------------------------------------------------------|
//                        Redux - Property Mapping
// ----------------------------------------------------------------------------|
const mapDispatchToProps = (dispatch) => ({
  createNeighborhood: (data, parentCityId) =>
    dispatch(createCitiesAction(data, parentCityId)),
  editLocation: (data, parentCityId) =>
    dispatch(editCitiesAction(data, parentCityId)),
  deleteNeighborhood: (city, parentCityId) =>
    dispatch(deleteCityAction(city, parentCityId)),
});

const mapStateToProps = (state) => ({
  cityToEdit: state.cities.cityToEdit,
});

// ----------------------------------------------------------------------------|
//                   React Function Component - City
// ----------------------------------------------------------------------------|
const City = ({
  closeModal,
  deleteNeighborhood,
  cityToEdit,
  editLocation,
  createNeighborhood,
  toggleEnable,
}) => {
  const initialState = {
    name: cityToEdit ? cityToEdit.name : '',
    status: cityToEdit ? cityToEdit.status : CITIES.UNAVAILABLE,
    nameErrorMsg: '',
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [neighborhoodToDelete, setNeighborhoodToDelete] = useState(null);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [neighborhoodtoEdit, setNeighborhoodToEdit] = useState(null);

  const [formData, setFormData] = useState(initialState);

  const { id: cityToEditId } = cityToEdit;

  /**
   * @description changes the form data based on key passed
   *
   * @param {String} field
   * @param {Any} value
   */
  const eventHandler = (field, value) => {
    const formDataCopy = { ...formData };
    formDataCopy[field] = value;
    setFormData({ ...formDataCopy });
  };

  /**
   * @description validates submitted form data
   */
  const validFormData = () => {
    const errorObj = {};

    errorObj.nameErrorMsg =
      !formData.name || !formData.name.trim() ? CITIES.NAME_ERR_MSG : '';

    setFormData({ ...formData, ...errorObj });

    return !errorObj.nameErrorMsg;
  };

  /**
   * @description submits form to redux, checks if this is a new cat or edit
   */
  const submitForm = () => {
    if (validFormData()) {
      const { name, status } = formData;

      const apiData = {
        name,
        status,
        cityToEditId,
      };

      editLocation(apiData);
      closeModal();
    }
  };

  /**
   * @description returns footer jsx
   *
   * @returns {JSX}
   */
  const designFooter = () => (
    <div className="modal-actions">
      <Button
        name="SAVE"
        type="button"
        className="btn action-btn"
        onClick={submitForm}
      >
        SAVE
      </Button>
    </div>
  );

  /**
   * @description Renders content on load
   */
  const renderNeighborhoodTable = () => {
    const { subareas } = cityToEdit;
    const sortedSubareas = subareas.sort((a, b) => (a.name > b.name ? 1 : -1))

    if (!qualifyArrayRendering(sortedSubareas)) {
      return (
        <div className="no-neighborhoods-found">
          <span className="message">No Neighborhoods</span>
          <span className="sub_message">Please Add Some</span>
        </div>
      );
    }

    return (
      <CobTable
        rows={sortedSubareas}
        cols={citiesCols}
        showEditModal={(city) => {
          setNeighborhoodToEdit(city);
          setShowCreateModal(true);
        }}
        deleteCategory={(city) => {
          setNeighborhoodToDelete(city);
          setShowDeleteModal(true);
        }}
        toggleEnable={(city) => toggleEnable(city, cityToEditId)}
      />
    );
  };

  const chooseNeighborhoodModalData = () => {
    if (neighborhoodtoEdit) {
      const { name } = neighborhoodtoEdit;

      return {
        super_location_name: cityToEdit.name,
        related_gmaps_place_id: '',
        location_type: 'neighborhood',
        name,
        isEdit: true,
      };
    }

    return {
      super_location_name: cityToEdit.name,
      related_gmaps_place_id: '',
      location_type: 'neighborhood',
      name: '',
      isEdit: false,
    };
  };

  return (
    <CardViewModal className="cities-modal" modalClosed={() => closeModal()}>
      <div className="edit-cities-modal-container">
        <div className="edit-cities-modal">
          <h1 className="title">{CITIES.EDIT_CITY_TITLE}</h1>
          <form name="edit-city" className="city-form">
            {cityFormFields.map((field) => {
              const { label, placeholder, type, value } = field;

              const options = {
                type,
                name: label,
                label,
                value: '',
              };

              if (type === 'switch') {
                const classNames = `new-filter-checkbox ${
                  value === 'is_child' ? 'child-of' : 'togglers'
                }`;
                const switchToggled = formData[value] === CITIES.AVAILABLE;

                return (
                  <div className={classNames} key={label}>
                    <div className="switch-container">
                      <Switch
                        onChange={(result) =>
                          eventHandler(
                            value,
                            result ? CITIES.AVAILABLE : CITIES.UNAVAILABLE
                          )
                        }
                        checked={switchToggled}
                        {...switchStyling()}
                      />
                      <label htmlFor={label}>{label}</label>
                    </div>
                  </div>
                );
              }

              if (type === 'text') {
                if (label === 'Name of City') {
                  options.errorMsg = formData.nameErrorMsg;
                  options.maxLength = CITIES.NAME_MAX_LENGTH;
                  options.value = cityToEdit.name;
                }

                return (
                  <FormInput
                    key={label}
                    type={type}
                    placeholder={placeholder}
                    options={options}
                    eventHandler={(result) =>
                      eventHandler(value, result.target.value)
                    }
                  />
                );
              }

              return null;
            })}
          </form>
          <div className="neighborhoods-list-container">
            <h2>Neighborhoods</h2>
            <Button
              name="SAVE"
              type="button"
              className="btn action-btn"
              onClick={() => {
                setShowCreateModal(true);
              }}
            >
              {CITIES.NEW_NEIGHBORHOOD_TITLE}
            </Button>
          </div>
          {renderNeighborhoodTable()}
          {designFooter()}
        </div>
      </div>
      {showCreateModal && (
        <ThirdPartySearchModal
          showModal={showCreateModal}
          closeModal={() => {
            setNeighborhoodToEdit(null);
            setShowCreateModal(false);
          }}
          verticalType={VERTICAL_TYPE}
          onSubmit={(data) => {
            const { name, location_type, related_gmaps_place_id, isEdit } =
              data;

            if (isEdit)
              editLocation(
                {
                  id: neighborhoodtoEdit.id,
                  name,
                },
                cityToEditId
              );
            else
              createNeighborhood(
                {
                  name,
                  location_type,
                  related_gmaps_place_id,
                  super_location_name: cityToEdit.name,
                },
                cityToEditId
              );
          }}
          cardData={chooseNeighborhoodModalData()}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          cancelClick={() => setShowDeleteModal(false)}
          deleteClick={() => {
            deleteNeighborhood(
              {
                city: neighborhoodToDelete,
                apiData: {
                  id: neighborhoodToDelete.id,
                },
              },
              cityToEditId
            );
            setNeighborhoodToDelete(null);
            setShowDeleteModal(false);
          }}
          showHeader={false}
        />
      )}
    </CardViewModal>
  );
};

// --------------------------------------------------------------------------|
//                             PropTypes Check
// --------------------------------------------------------------------------|
City.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  deleteNeighborhood: PropTypes.func.isRequired,
  createNeighborhood: PropTypes.func.isRequired,
  editLocation: PropTypes.func.isRequired,
  cityToEdit: PropTypes.any.isRequired,
  toggleEnable: PropTypes.func.isRequired,
};

// --------------------------------------------------------------------------|
//                              Default Props
// --------------------------------------------------------------------------|
City.defaultProps = {};

// ----------------------------------------------------------------------------|
//                    City Export with Redux Connect
// ----------------------------------------------------------------------------|
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(City));
