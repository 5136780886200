/**
 * ************************************
 *
 * @module  PlaceService.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description functionality used for the Place
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
/* eslint-disable camelcase */
import CommonService from 'services/CommonService';

import currentEnv from 'utils/EnvironmentSpecificValues';

import { filterEmptyMultiLinks, trimItemizedDescription } from 'utils/FormFunc';

const commonService = CommonService();

const PlaceService = () => {
  const { getProcessedNeighborhoods } = commonService;

  const getPreparedFormData = (data, uploadedImages, placeStatus) => {
    const {
      name,
      address,
      one_liner_description,
      long_description,
      phone_number,
      neighborhoods,
      website_link,
      ordering_links,
      to_time,
      from_time,
      categories,
      price_tier,
      menu_links,
      place_hours,
      reservation_tier,
      admin_review,
      vibes,
      itemized_description,
      misc_options,
      related_gmaps_id,
      related_yelp_id,
      related_foursquare_id,
      should_refetch_3rd_parties_content,
    } = data;

    let { booking_links } = data;

    // checks if 'does not take reservations' is toggled. If so,
    // will pass an empty booking links array to the backend on save
    if (reservation_tier === 'walk_in_only') {
      booking_links = [];
    }

    return {
      name,
      address,
      phrase: one_liner_description ? one_liner_description.trim() : '',
      description: long_description ? long_description.trim() : '',
      // were separated, now combined for API call
      categories: [...categories, ...vibes, ...misc_options],
      set_neighborhoods:
        (neighborhoods && getProcessedNeighborhoods(neighborhoods)) || [],
      phone: phone_number || '',
      custom_images: uploadedImages,
      booking_links: booking_links ? filterEmptyMultiLinks(booking_links) : [],
      website: website_link || '',
      menu_links: menu_links ? filterEmptyMultiLinks(menu_links) : [],
      ordering_links: ordering_links
        ? filterEmptyMultiLinks(ordering_links)
        : [],
      to_time,
      from_time,
      lat: data.lat,
      lng: data.lng,
      status: placeStatus,
      price_tier:
        price_tier !== undefined && price_tier !== null
          ? price_tier
          : undefined,
      reservation_tier: reservation_tier || '',
      place_hours,
      admin_review,
      itemized_description: trimItemizedDescription(itemized_description),
      related_gmaps_id: related_gmaps_id || '',
      related_yelp_id: related_yelp_id || '',
      related_foursquare_id: related_foursquare_id || '',
      should_refetch_3rd_parties_content:
        should_refetch_3rd_parties_content || false,
    };
  };

  const validate = (data) => {
    const errorsObj = {
      ...commonService.validate(data),
      ...commonService.validateMultiLinks(data.booking_links, 'booking_links'),
      ...commonService.validateMultiLinks(
        data.ordering_links,
        'ordering_links'
      ),
      ...commonService.validateItemizedDescription(data.itemized_description),
    };

    let count = 0;
    const key = Object.keys(data.place_hours);

    for (let i = 0; i < key.length; i += 1) {
      if (!data.place_hours[key[i]].slots.length) {
        count += 1;
      }
    }

    if (count === 7) {
      errorsObj.place_hours = 'Place Hours are required';
    }

    if (data.vibes.length < 1) {
      errorsObj.vibes = 'At least one vibe is required';
    }

    if (data.price_tier === undefined) {
      errorsObj.price_tier = 'Price range is required';
    }

    if (typeof data.address === 'string' && data.address.length === 0) {
      errorsObj.address_input = 'Address is required';
    }

    if (
      !commonService.validatePrimaryCategories(
        data.main_categories,
        data.categories
      )
    ) {
      errorsObj.categories =
        'Primary categories must be included in the selected categories';
    }

    return errorsObj;
  };

  const defaultDescriptions = () => [
    {
      header: 'What it is',
      body: '',
    },
    {
      header: 'Why it\'s unique',
      body: '',
    },
    {
      header: 'Popular dishes',
      body: '',
    },
    {
      header: 'Popular drinks',
      body: '',
    },
  ];

  const placeOptions = [
    {
      displayText: 'Has indoor seating',
      uniqueSlug: currentEnv.uniqueSlugs.indoor,
      id: currentEnv.uniqueIds.indoor,
      type: 'category',
    },
    {
      displayText: 'Has outdoor seating',
      uniqueSlug: currentEnv.uniqueSlugs.outdoor,
      id: currentEnv.uniqueIds.outdoor,
      type: 'category',
    },
  ];

  return {
    getPreparedFormData,
    validate,
    defaultDescriptions,
    placeOptions,
  };
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default PlaceService;
