/**
 * ************************************
 *
 * @module  MovieService.js
 * @author  Matt P
 * @date    12/31/2020
 * @description File to prepare Movie form
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
// App imports
import CommonService from 'services/CommonService';
import { chooseItemizedDescription, mapDetailedCategories } from 'utils/utils';
import { isArrayEmpty, filterEmptyMultiLinks } from 'utils/FormFunc';

// ----------------------------------------------------------------------------|
//                               Utilities
// ----------------------------------------------------------------------------|
const commonService = CommonService();

// ----------------------------------------------------------------------------|
//                               Movie Service
// ----------------------------------------------------------------------------|
const MovieService = {
  /**
   * @description processes the vertical specific data that is incoming from
   * the backend to populate an edit form.
   *
   * @param {Object} dataObj - of date data
   * @param {Object} additionalOptions - any additional data that may be
   * required to process incoming data
   *
   * @returns {Object}
   */
  processVerticalSpecificData(dataObj = {}, additionalOptions = {}) {
    const { audience_ratings, description, instance_type, itemized_description, main_categories, phrase, related_tmdb_id, release_date, title, links_to_movie } = dataObj;
    const { categoryMap } = additionalOptions;
    return {
      instance_type: instance_type || '',
      itemized_description: chooseItemizedDescription(itemized_description, description, MovieService.defaultDescriptions),
      main_categories: main_categories ? mapDetailedCategories(main_categories, categoryMap) : [],
      phrase: phrase || '',
      rating: audience_ratings,
      related_tmdb_id: related_tmdb_id || '',
      release_date,
      title: title || '',
      links_to_movie: links_to_movie || [],
    };
  },

  /**
   * @description preps the vertical specific form data to be passed as in the
   * request body for a form update
   *
   * @param {Object} formData - formData
   * @param {Object} additionalOptions - any additional data that may be
   * required to process outgoing data
   *
   * @returns {Object} for requestBody
   */
  prepareVerticalSpecificFormData(formData = {}, additionalOptions = {}) {
    const { instance_type, phrase, release_date, related_tmdb_id, should_refetch_3rd_parties_content, links_to_movie } = formData;
    return {
      phrase: phrase ? phrase.trim() : '',
      instance_type,
      release_date: (release_date === undefined || release_date === null || release_date === '') ? undefined : commonService.formatDate(release_date),
      related_tmdb_id: related_tmdb_id ? related_tmdb_id.toString() : '',
      should_refetch_3rd_parties_content: should_refetch_3rd_parties_content || false,
      links_to_movie: links_to_movie ? filterEmptyMultiLinks(links_to_movie) : [],
    };
  },

  /**
   * @description error checking for 'publish' form submission
   *
   * @param {Object} data - formData
   *
   * @returns {Object} of errors
   */
  validate(data) {
    const { categories, main_categories } = data;
    console.log(data);
    const errorsObj = {
      ...commonService.validateMultiLinks(data.links_to_movie, 'links_to_movie'),
    };

    // CATEGORIES CHECKS
    if (isArrayEmpty(categories) && isArrayEmpty(main_categories)) {
      errorsObj.categories = 'One Category and One Primary Category are Required';
    }

    return errorsObj;
  },

  /**
   * @description adds unformatted_thumb_url to thumb_url field
   * and unformatted_url to url field in photo object for the backend.
   *
   * This is only for fetched images from TMDB
   *
   * @param {Object} data - of strings/links
   *
   * @returns {Object} of converted key/value pairs
   */
  convertImageLinks: (imageData) =>
    imageData.map((data) => {
      if (data.source === 'tmdb') {
        const { photo_credits, source, unformatted_thumb_url, unformatted_url } = data;
        return {photo_credits, source, url: unformatted_url, thumb_url: unformatted_thumb_url};
      }
      return data;
    }),

  /**
   * @description default descriptions for the itemized_descriptions
   * form field
   */
  defaultDescriptions: [
    {
      header: 'What it is',
      body: '',
    },
    {
      header: 'Rotten Tomatoes score',
      body: '',
    },
    {
      header: 'Big names',
      body: '',
    },
  ],
};

// ----------------------------------------------------------------------------|
//                          Export - MovieService
// ----------------------------------------------------------------------------|
export default MovieService;
