/**
 * ************************************
 *
 * @module  VerticalCardList.js
 * @author  Matt P
 * @date    07/02/2021
 * @description VerticalCardList component file which displays cards
 * based on verticalType (place/event/movie/etc..)
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  FetchedCard,
  InfiniteScroller,
  LoadingFetchedCard,
} from 'components';

import { getImageURL, getDateBasedOnStatus } from 'utils/utils';

import { GENERIC } from 'constants.js';

import { getCardTypeByPrettyId } from '../VerticalForm/VerticalFormUtils';

// ----------------------------------------------------------------------------|
//                   React Function Component - VerticalCardList
// ----------------------------------------------------------------------------|
const VerticalCardList = ({
  data,
  hasMore,
  onMoreData,
  onMenuClicked,
  onOpenClick,
  fromFetched,
  currentTab,
  hideMenu,
  verticalType,
  fetchingData,
  shouldDisplayImageInspector,
}) => {
  const {
    SUB_MENU: { EDIT },
  } = GENERIC;

  /**
   * @description limits the characters description to limit card height
   *
   * @param {String} str - description string
   * @param {Number} limit - number of characters
   *
   * @returns {String} - limited by char limit
   */
  const descriptionLimiter = (str = '', limit) => {
    if (str.length <= limit) return str;

    let slicedString = str.slice(0, limit);

    slicedString += '...';

    return slicedString;
  };

  /**
   * @description generates fetched data cards
   *
   * @param {Object} place - object of place data
   *
   * @returns {JSX} - of PlaceFetchedDetail
   */
  const generateFetchedData = (card, tab) => {
    const {
      address,
      id,
      images,
      categories,
      name,
      neighborhoods,
      original_language,
      price_tier: priceTier,
      rating,
      fs_categories: fsCategories,
      popularity_score,
      runtime,
      title,
    } = card;

    const imageURL = getImageURL(images);
    const fourthLayerQueue = tab === 'queue';

    return (
      <FetchedCard
        address={address}
        categories={categories}
        fsCategories={fsCategories}
        fourthLayer={fourthLayerQueue}
        imageURL={imageURL}
        key={id}
        language={original_language}
        name={name || title}
        neighborhoods={neighborhoods}
        onReviewClick={() => onMenuClicked(EDIT, card)}
        popularity={popularity_score}
        priceTier={priceTier}
        rating={rating}
        runtime={runtime}
        verticalType={verticalType}
      />
    );
  };

  /**
   * @description generates card component
   *
   * @param {Array} cardListData - Array of card objects
   *
   * @returns {JSX} - with Card components
   */
  const generateCards = (cardListData) => (
    <div
      className={`card-container ${
        shouldDisplayImageInspector ? 'image-inspector-container' : ''
      }`}
    >
      {Array.isArray(cardListData)
        ? cardListData.map((card) => {
            const {
              // ** common card props **
              id,
              is_expired: isExpired,

              name,
              title,
              images,
              phrase,
              description,
              last_admin_review,
              status,
              published_date: publishedDate,
              modified: modifiedDate,
              // ** collections specific **
              units_count: locationCount,
              is_pinned,
              // flagged specific
              reports,
            } = card;

            const imageURL = getImageURL(images);

            const cardType = getCardTypeByPrettyId(card);

            const choosePhrase = () => {
              if (
                verticalType === 'collection' &&
                typeof description === 'string'
              ) {
                return descriptionLimiter(description, 175);
              }
              if (verticalType !== 'collection' && typeof phrase === 'string') {
                return descriptionLimiter(phrase, 175);
              }

              return '';
            };

            return (
              <Card
                verticalType={verticalType}
                currentTab={currentTab}
                cardType={cardType}
                key={id}
                id={id}
                className="card"
                onMenuClick={(value) => onMenuClicked(value, card)}
                onClick={() => onOpenClick(card)}
                isPinned={is_pinned}
                hideMenu={hideMenu}
                date={getDateBasedOnStatus(status, modifiedDate, publishedDate)}
                status={status}
                imageUrl={imageURL}
                images={images}
                title={name || title}
                phrase={choosePhrase()}
                locationCount={locationCount}
                adminReview={last_admin_review}
                isExpired={isExpired}
                shouldDisplayImageInspector={shouldDisplayImageInspector}
                hideCheckBox
                reports={reports}
              />
            );
          })
        : null}
    </div>
  );
  // switch var for if data is fromFetched or another tab
  const content = fromFetched
    ? data.map((card) => generateFetchedData(card, currentTab))
    : generateCards(data);

  return (
    <section className="card-list">
      <InfiniteScroller loadMore={onMoreData} hasMore={hasMore} useWindow={false}>
        {content}
      </InfiniteScroller>
      {fetchingData ? <LoadingFetchedCard /> : ''}
    </section>
  );
};

// ----------------------------------------------------------------------------|
//                       PropTypes Check - VerticalCardList
// ----------------------------------------------------------------------------|
VerticalCardList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onMoreData: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  onMenuClicked: PropTypes.func,
  onOpenClick: PropTypes.func,
  fromFetched: PropTypes.bool,
  hideMenu: PropTypes.bool,
  verticalType: PropTypes.string.isRequired,
  currentTab: PropTypes.string,
  fetchingData: PropTypes.bool.isRequired,
};

// ----------------------------------------------------------------------------|
//                          Default Props - VerticalCardList
// ----------------------------------------------------------------------------|
VerticalCardList.defaultProps = {
  onMenuClicked: () => {},
  onOpenClick: () => {},
  fromFetched: true,
  hideMenu: false,
};

// ----------------------------------------------------------------------------|
//                              VerticalCardList Export
// ----------------------------------------------------------------------------|
export default VerticalCardList;
