/**
 * ************************************
 *
 * @module  EventService.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description File to prepare Event form
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import CommonService from 'services/CommonService';

import {
  formatISODate,
  getProcessedNeighborhoods,
  hasUniqueSlug,
} from 'utils/utils';

import currentEnv from 'utils/EnvironmentSpecificValues';

import {
  filterEmptyMultiLinks,
  isValidNumber,
  trimAllNonNumberCharacters,
  trimItemizedDescription,
} from 'utils/FormFunc';
// eslint-disable-next-line max-len
import EventDateSelectorService from 'components/EventDateSelector/EventDateSelectorService';

// ----------------------------------------------------------------------------|
//                                Utilities
// ----------------------------------------------------------------------------|
const dateFormat = 'YYYY-MM-DD';
const commonService = CommonService();

const constraints = {
  address: {},
  neighborhoods: {},
  event_venue_name: {},
  recurring_days: {},
  event_amount: {},
  event_hours: {},
};

// ----------------------------------------------------------------------------|
//                             Event Service
// ----------------------------------------------------------------------------|
const EventService = () => {
  const getPreparedFormData = (data, uploadedImages, placeStatus) => {
    const {
      name,
      address,
      one_liner_description,
      long_description,
      reservation_tier,
      website_link,
      cta_title_long,
      cta_title_short,
      event_amount,
      event_amount_max,
      event_amount_suffix,
      start_date,
      end_date,
      from_time,
      to_time,
      neighborhoods,
      categories,
      phone_number,
      price_tier,
      recurring_event,
      recurring_days,
      event_hours,
      admin_review,
      vibes,
      itemized_description,
      misc_options,
      duration,
    } = data;

    let { booking_links } = data;

    // checks if 'does not take reservations' is toggled. If so,
    // will pass an empty booking links array to the backend on save
    if (reservation_tier === 'walk_in_only') {
      booking_links = [];
    }

    return {
      is_event: true,
      name,
      address,
      cta_title_long,
      cta_title_short,
      phrase: one_liner_description ? one_liner_description.trim() : '',
      description: long_description ? long_description.trim() : '',
      categories: [...categories, ...vibes, ...misc_options],
      set_neighborhoods:
        (neighborhoods && getProcessedNeighborhoods(neighborhoods)) || [],
      custom_images: uploadedImages,
      event_amount: trimAllNonNumberCharacters(event_amount),
      event_amount_max: trimAllNonNumberCharacters(event_amount_max),
      event_amount_suffix: event_amount_suffix || 'person',
      start_date: formatISODate(start_date, dateFormat),
      end_date: formatISODate(end_date, dateFormat),
      to_time,
      from_time,
      booking_links: booking_links ? filterEmptyMultiLinks(booking_links) : [],
      website: website_link || '',
      phone: phone_number || '',
      status: placeStatus,
      price_tier:
        price_tier !== undefined && price_tier !== null
          ? price_tier
          : undefined,
      reservation_tier: reservation_tier || '',
      recurring_event: recurring_event || false,
      recurring_days,
      lat: data.lat,
      lng: data.lng,
      event_hours: EventDateSelectorService.prepareDataForBackend(event_hours),
      admin_review,
      itemized_description: trimItemizedDescription(itemized_description),
      duration,
    };
  };

  const validate = (data) => {
    // TBD
    const errorsObj = {
      ...commonService.validate(data),
      ...commonService.validateMultiLinks(data.booking_links, 'booking_links'),
      ...commonService.validateItemizedDescription(data.itemized_description),
    };
    // let errorsObj = {};
    const keys = Object.keys(constraints);

    delete errorsObj.date_range;
    delete errorsObj.hour_range;

    keys.forEach((elem) => {
      if (elem === 'event_amount') {
        delete errorsObj.event_amount;

        const trimmed = trimAllNonNumberCharacters(data.event_amount);

        if (
          data.price_tier > 0 &&
          (trimmed === '' || !isValidNumber(trimmed))
        ) {
          errorsObj.event_amount = 'Please enter a valid amount';
        }
      }

      if (elem === 'event_venue_name') {
        delete errorsObj.event_venue_name;
        if (!data.event_venue_name) {
          // Making field no longer required
          // errorsObj.event_venue_name = 'Event Location is required';
        }
      }

      if (
        elem === 'address' &&
        typeof data.address === 'string' &&
        data.address.length === 0 &&
        !hasUniqueSlug(data.misc_options, currentEnv.uniqueSlugs.virtualEvent)
      ) {
        errorsObj.address_input = 'Address or virtual event check is required';
      }

      // if (
      //   elem === 'neighborhoods' &&
      //   Array.isArray(data.neighborhoods) &&
      //   data.neighborhoods.length === 0 &&
      //   !hasUniqueSlug(data.misc_options, currentEnv.uniqueSlugs.virtualEvent)
      // ) {
      //   errorsObj.neighborhoods =
      //     'At least one neighborhood or virtual event check is required';
      // }

      if (elem === 'event_hours') {
        if (!data.event_hours || !data.event_hours.length) {
          // Making field no longer required
          errorsObj.event_hours = 'At least one date is required';
        }

        // eslint-disable-next-line max-len
        const overlappingError = EventDateSelectorService.checkForOverlappingDates(
          data.event_hours
        );
        if (overlappingError.value) {
          errorsObj.event_hours = overlappingError.value;
        }
      }

      if (data.vibes.length < 1) {
        errorsObj.vibes = 'At least one vibe is required';
      }

      if (data.price_tier === undefined) {
        errorsObj.price_tier = 'Price range is required';
      }
    });

    // errorsObj = commonService.validateLinks(data, errorsObj);

    if (
      !commonService.validatePrimaryCategories(
        data.main_categories,
        data.categories
      )
    ) {
      errorsObj.categories =
        'Primary categories must be included in the selected categories';
    }

    return commonService.validateLinks(data, errorsObj);
  };

  const defaultDescriptions = () => [
    {
      header: 'What it is',
      body: '',
    },
    {
      header: 'Why it’s unique',
      body: '',
    },
  ];

  return {
    getPreparedFormData,
    validate,
    defaultDescriptions,
  };
};

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default EventService;
