/**
 * ************************************
 *
 * @module  Layout.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description layout file for the FileUploader comp
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                Imports
// ----------------------------------------------------------------------------|
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import addIcon from 'assets/images/add.svg';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const Layout = ({
  input,
  dropzoneProps,
  failedUploads,
  isPreviewRequired,
  getMaxFileCount,
  largePreview,
}) => {
  const fileInput = (
    <Fragment>
      <img className="add-icon" src={addIcon} alt="Add more" />
      {input}
    </Fragment>
  );

  return (
    <Fragment>
      {isPreviewRequired && <div> Uploading...</div>}
      {!isPreviewRequired && (
        <div
          {...dropzoneProps}
          className={`dzu-dropzone ${largePreview ? 'largePreview' : ''}`}
        >
          {getMaxFileCount > 0 && fileInput}
        </div>
      )}
    </Fragment>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
Layout.propTypes = {
  input: PropTypes.element.isRequired,
  dropzoneProps: PropTypes.object.isRequired,
  files: PropTypes.array.isRequired,
  failedUploads: PropTypes.array.isRequired,
  extra: PropTypes.object.isRequired,
  isPreviewRequired: PropTypes.bool.isRequired,
  getMaxFileCount: PropTypes.number.isRequired,
  largePreview: PropTypes.bool,
};

// ----------------------------------------------------------------------------|
//                              Default Props
// ----------------------------------------------------------------------------|
Layout.defaultProps = {
  largePreview: false,
};

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export default Layout;
