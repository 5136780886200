/**
 * ************************************
 *
 * @module  EditPopUpModal.js
 * @author  Matt P
 * @date    12/03/2020
 * @description Component to edit hours for a 'Place'.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                            Imports
// ----------------------------------------------------------------------------|
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import {
  Backdrop,
  Button,
  EditHours,
  HourPicker,
  RecurringEvent,
} from 'components';

import removeIcon from 'assets/images/remove-black.svg';

import 'components/EditPopUpModal/EditPopUpModal.scss';

// ----------------------------------------------------------------------------|
//              React Class PureComponent - EditPopupModal
// ----------------------------------------------------------------------------|
class EditPopUpModal extends Component {
  constructor(props) {
    super(props);

    const { data } = this.props;

    this.state = {
      data,
      rows: { slots: [], status: 'NA' },
      selectedEvents: {
        events: [],
      },
      openCheck: false,
      closeCheck: false,
      varyCheck: false,
      errors: '',
      addMoreHourCheck: false,
      modified: false,
    };

    this.updateSelectedEvents = this.updateSelectedEvents.bind(this);
    this.updateSelectedHours = this.updateSelectedHours.bind(this);
    this.updateData = this.updateData.bind(this);
    this.updateTimeRows = this.updateTimeRows.bind(this);
    this.validate = this.validate.bind(this);
    this.addMoreHours = this.addMoreHours.bind(this);
  }

  /**
   * @description Updates the state of selected days of the week when you select
   * an event from the RecurringEvents
   *
   * @param {Object} data - selectedEvents object with events array
   */
  updateSelectedEvents(data) {
    this.setState({ selectedEvents: data });
  }

  /**
   * @description Updates the state of selected hours then updates state data
   *
   * @param {Object} data - selectedHours object with events array
   */
  updateSelectedHours(data) {
    this.setState({ ...data });
  }

  /**
   * @description Updates the state of the overall days and times data object
   * with new selection data by creating a new object and changing day key/value
   * data to be passed to setState()
   *
   * @param {Object} days - selectedEvents Object from state
   * @param {Object} updatedData - selectedHours object with events array
   */
  updateData(days, updatedData) {
    const { data } = this.state;
    const tempObj = { ...data };

    for (let i = 0; i < days.events.length; i += 1) {
      const buildObj = {
        slots: updatedData.slots,
        status: updatedData.status,
      };

      tempObj[days.events[i]] = buildObj;
    }

    this.setState({ data: tempObj, modified: true });
  }

  /**
   * @description Resets the state for days and hours selected
   * check boxes are reset as well
   */
  clearSelection() {
    this.setState({
      rows: { slots: [], status: 'NA' },
      selectedEvents: {
        events: [],
      },
      openCheck: false,
      closeCheck: false,
      varyCheck: false,
      errors: '',
      addMoreHourCheck: false,
    });
  }

  /**
   * @description Checks if day of operation is should be opened or closed
   * and toggles selections
   *
   * @param {Boolean} checked - Bool to check if opened or closed
   * @param {String} label -
   * @param {String} e - **Currently Unused**
   */
  checkOpenOrClose = (checked, label, e) => {
    if (checked) {
      // if open, sets state with new rows
      // else sets the row to closed
      label.includes('open')
        ? this.setState({
            closeCheck: false,
            openCheck: checked,
            varyCheck: false,
            rows: { slots: [['00:00:00', '23:59:59']], status: 'OPEN' },
            errors: '',
            addMoreHourCheck: false,
          })
        : label.includes('vary') ? this.setState({
            closeCheck: false,
            openCheck: false,
            varyCheck: checked,
            rows: { slots: [], status: 'VARY' },
            errors: '',
            addMoreHourCheck: false,
          })
        : this.setState({
            closeCheck: checked,
            openCheck: false,
            varyCheck: false,
            rows: { slots: [], status: 'CLOSED' },
            errors: '',
            addMoreHourCheck: false,
          });
    } else {
      this.setState({
        closeCheck: false,
        openCheck: false,
        varyCheck: false,
        rows: { slots: [], status: 'NA' },
        errors: '',
        addMoreHourCheck: false,
      });
    }
  };

  /**
   * @description Fires when times are selected and updates component state
   *
   * @param {String} elemName - String for the name of an element (ex: fromHour)
   * @param {Moment Object} value - Time value from moment.js
   * @param {String} className - String of 'to' or 'from'
   */
  updateTimeRows = (elemName, value, className) => {
    // Sets state on time picker change
    this.setState(
      // setState first arg is the updater
      (prevState) => {
        const tempObj = { ...prevState.rows };
        const tempSlots = JSON.parse(JSON.stringify(tempObj.slots));
        const index = className.split(' ');

        // checks and puts element in either 0 or 1 array index
        // index[1] will be the row number
        elemName === 'fromHour'
          ? (tempSlots[index[1]][0] = value.format('HH:mm:ss'))
          : (tempSlots[index[1]][1] = value.format('HH:mm:ss'));

        return {
          rows: { slots: [...tempSlots], status: tempObj.status },
          addMoreHourCheck: false,
        };
      },
      () => {
        const { rows } = this.state;
        const tempObj = { ...rows };
        const tempSlots = JSON.parse(JSON.stringify(tempObj.slots));
        const index = className.split(' ');

        if (
          tempSlots[index[1]][0] &&
          tempSlots[index[1]][0] &&
          tempSlots[index[1]][1] &&
          tempSlots[index[1]][0] > tempSlots[index[1]][1]
        ) {
          tempSlots.splice(Number(index[1]) + 1);

          this.setState(
            {
              rows: { slots: [...tempSlots], status: tempObj.status },
              addMoreHourCheck: true,
            },
            () => {
              this.validate(className, false);
            }
          );
        } else {
          this.validate(className, false);
        }
      }
    );
  };

  /**
   * @description Validates the date range input for edge cases
   * ex: Overlapping times/etc..
   *
   * @param {String} name - Day of the week name string
   * @param {Boolean} deleted - Passed bool on if row is deleted or not
   */
  validate = (name, deleted) => {
    const { rows } = this.state;
    // Spreads all current rows of date hours range data
    const tempObj = { ...rows };
    // Creates a shallow copy of tempObj.slots for manipulation
    const tempSlots = JSON.parse(JSON.stringify(tempObj.slots));
    // name is the classname passed in
    // first example ' 0 to'
    // splits to: ['', '1', 'to']
    const index = name.split(' ');
    // First check if slot is to be deleted and if so,
    // decrements the index we're on to keep track of the
    // table
    if (deleted && tempSlots.length) {
      if (Number(index[1]) === 0) {
        index[1] = Number(index[1]);
      } else {
        index[1] = Number(index[1]) - 1;
      }
    }
    // Checks if there are no slots and updates states error property
    // with empty string ''
    if (!tempSlots.length) {
      this.setState({ errors: '' }, () => {
        const { handleStateChange } = this.props;
        const { errors } = this.state;
        // optional callback to trigger handleStateChange flag on PlaceHours
        handleStateChange(errors);
      });
    }

    // If there are any rows in our table
    if (tempSlots.length) {
      let slotIndex = Number(index[1]);
      // Reset errors if any are set to state
      this.setState({ errors: '' }, () => {
        const { handleStateChange } = this.props;
        const { errors } = this.state;

        handleStateChange(errors);
      });

      // What is this code? Looks like a bad workaround to disable check when
      // place closes in the morning if (tempSlots[tempSlots.length - 1][0]
      // && tempSlots[tempSlots.length - 1][0] > '12:00:00' &&
      // tempSlots[tempSlots.length - 1][1] !== '' &&
      // tempSlots[tempSlots.length - 1][1] < '12:00:00') {
      //   tempSlots[tempSlots.length - 1][1] =
      // `${Number(tempSlots[tempSlots.length - 1][0].slice(0, 2)) +
      // 1 + tempSlots[tempSlots.length - 1][0].slice(-6)}`;
      // }

      // Loops through current array of array times checking for
      // errors in time overlap. Sets state error property based on inputs
      while (slotIndex < tempSlots.length) {
        if (
          slotIndex > 0 &&
          tempSlots[slotIndex][0] <= tempSlots[slotIndex - 1][1]
        ) {
          this.setState(
            {
              errors:
                'Each time slot must start later than the previous closing hour',
            },
            () => {
              const { handleStateChange } = this.props;
              const { errors } = this.state;

              handleStateChange(errors);
            }
          );
        }
        if (tempSlots[slotIndex][0] === tempSlots[slotIndex][1]) {
          this.setState(
            { errors: 'Opening and closing hours should be different' },
            () => {
              const { handleStateChange } = this.props;
              const { errors } = this.state;

              handleStateChange(errors);
            }
          );
        }
        slotIndex += 1;
      }
    }
  };

  /**
   * @description Deletes a row when X button is clicked
   *
   * @param {Object} event - Event object, used to grab classList string
   * @param {String} name -
   */
  deleteRow = (event, name) => {
    let index = '';
    // used in validation check in setState callback below
    let check;

    // initially sets index to event target's classList
    if (!index) {
      index = [...event.target.classList];
    }

    this.setState(
      (prevState) => {
        const tempSlots = JSON.parse(JSON.stringify(prevState.rows));

        // grabs correct row index for splice/delete
        tempSlots.slots.splice(Number(index[0]), 1);

        // Checks if slots array is empty. If so, set status back to 'NA'
        if (tempSlots.slots.length === 0) {
          tempSlots.status = 'NA';
        }

        return {
          rows: { slots: tempSlots.slots, status: tempSlots.status },
          addMoreHourCheck: check,
        };
      },
      // after first state set, fire callback which calls validate method
      () => {
        const { rows, errors } = this.state;

        const tempKeyData = { ...rows };
        // sets Bool for check status
        check = this.getAddMoreHourCheck(tempKeyData.slots) && !errors;

        this.setState({ addMoreHourCheck: check }, () => {
          this.validate(name, true);
        });
      }
    );
  };

  /**
   * @description Adds an additional row of time ranges by setting the
   * state
   *
   * @param {Object} event - Event object, used to preventDefault()
   * @param {Object} data - **UNUSED**
   */
  addMoreHours = (event, data) => {
    const { rows, errors } = this.state;

    event.preventDefault();

    const tempObj = JSON.parse(JSON.stringify(rows));

    // Checks if any errors are standing
    if (!errors) {
      // Error checking if time fields are empty
      if (
        tempObj.slots.length &&
        tempObj.slots[tempObj.slots.length - 1][0] === '' &&
        tempObj.slots[tempObj.slots.length - 1][0] === ''
      ) {
        this.setState({ errors: 'Time field cannot be empty' });
      }
      // Error checking if start and end time are the same
      if (
        tempObj.slots.length &&
        tempObj.slots[tempObj.slots.length - 1][0] ===
          tempObj.slots[tempObj.slots.length - 1][1]
      ) {
        this.setState({ errors: 'Start and end time cannot be same' });
        // Else if those both pass => sets state with additional row
        // passing in prior state rows
      } else {
        this.setState((prevState) => {
          const rowObj = JSON.parse(JSON.stringify(prevState.rows));
          rowObj.slots.push(['', '']);

          return { rows: { slots: rowObj.slots, status: 'OPEN' }, errors: '' };
        });
      }
    }
  };

  /**
   * @description Generates HourPicker range components
   *
   * @param {String} fromHour - String for default fromHour
   * @param {Boolean} toHour - String for default toHour
   * @param {Function} onChange - Function to fire on HourPicker change
   * @param {String} className - Class names for the HourPicker Range
   */
  getHourRange = (fromHour, toHour, onChange, className, index) => {
    const fromHourKey = `fromHour_${index}`;
    const toHourKey = `toHour_${index}`;

    return (
      <div key={index} className="time-selector">
        <HourPicker
          key={fromHourKey}
          defaultValue={fromHour}
          name="fromHour"
          onChange={this.updateTimeRows}
          className={`${className} from`}
        />
        <span className="timer-separator"> To </span>
        <HourPicker
          key={toHourKey}
          defaultValue={toHour}
          name="toHour"
          onChange={this.updateTimeRows}
          className={`${className} to`}
        />
        <img
          className={`${className} delete`}
          src={removeIcon}
          alt="delete"
          onClick={(event) => {
            this.deleteRow(event, className);
          }}
          role="button"
        />
      </div>
    );
  };

  /**
   * @description Checks if more hours can be added or we've hit our range max
   *
   * @param {Array} data - Array containing arrays of time ranges
   *
   * @return {Boolean}
   */
  getAddMoreHourCheck = (data) => {
    if (
      data.length &&
      data[data.length - 1][0] &&
      data[data.length - 1][1] &&
      data[data.length - 1][0] > data[data.length - 1][1]
    ) {
      // Closing hour is the next day, no additional time slot can be entered
      return true;
    }
    return false;
  };

  /**
   * @description Checks if there are any incomplete hour ranges
   *
   * @param {Object} rows - Rows data object
   *
   * @return {Boolean}
   */
  validRowsCheck(rows) {
    const rowValues = Object.values(rows);

    for (let i = 0; i < rowValues[0].length; i += 1) {
      if (rowValues[0][i].includes('')) return false;
    }

    return true;
  }

  /**
   * @description Checks if more hours can be added or if we've hit our
   * range max by looking at day status property
   *
   * @param {Object} data - Object with day of the week information
   *
   * @return {Boolean}
   */
  getclosecheck = (data) => {
    if (data.status === 'CLOSED') {
      return true;
    }
    return false;
  };

  /**
   * @description Checks if the Open 24 Hours button is checked
   *
   * @param {Object} data - Object with day of the week information
   *
   * @return {Boolean}
   */
  getopencheck = (data) => {
    if (
      data.slots[0] &&
      data.slots[0].join(' ') === ['00:00:00', '23:59:59'].join(' ')
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { cancelClick, editHoursError, saveClick, renderInput } = this.props;
    const {
      data,
      addMoreHourCheck,
      rows,
      selectedEvents,
      openCheck,
      closeCheck,
      varyCheck,
      errors,
      modified,
    } = this.state;

    return (
      <Fragment>
        <Backdrop />
        <div className="edit-modal-popup">
          <h1 className="modal-header">Edit Hours</h1>
          <div className="edit-modal-container">
            {editHoursError.place_hours && (
              <div className="error">{editHoursError.place_hours}</div>
            )}
            <div className="edit-days-and-hours-container">
              <RecurringEvent
                events={[
                  { name: 'sunday' },
                  { name: 'monday' },
                  { name: 'tuesday' },
                  { name: 'wednesday' },
                  { name: 'thursday' },
                  { name: 'friday' },
                  { name: 'saturday' },
                ]}
                className="edit-days-container"
                selectedEvents={selectedEvents.events}
                error=""
                onChange={this.updateSelectedEvents}
                isRecurring
              />
              <EditHours
                keyData={rows}
                onChange={this.updateSelectedHours}
                openCheck={openCheck}
                closeCheck={closeCheck}
                varyCheck={varyCheck}
                errors={errors}
                addMoreHourCheck={addMoreHourCheck}
                checkOpenOrClose={this.checkOpenOrClose}
                updateTimeRows={this.updateTimeRows}
                deleteRow={this.deleteRow}
                addMoreHours={this.addMoreHours}
                getHourRange={this.getHourRange}
              />
            </div>
            <div className="btn-container">
              <Button
                className="btn btn-inverse"
                name=""
                id="edit-popup-clear"
                onClick={(e) => {
                  e.preventDefault();
                  // Clears current state
                  this.clearSelection();
                }}
              >
                CLEAR
              </Button>
              <Button
                className="btn"
                name=""
                id="edit-popup-apply"
                disabled={selectedEvents.events.length === 0}
                onClick={(e) => {
                  e.preventDefault();
                  if (this.validRowsCheck(rows)) {
                    // Updates the days/hours state.data with the new values
                    this.updateData(selectedEvents, rows);
                    // then clears the selections
                    this.clearSelection();
                  } else {
                    // else sets an error that a time range is empty
                    this.setState({ errors: 'Time cannot be empty' });
                  }
                }}
              >
                APPLY
              </Button>
            </div>
            <div className="timer">{renderInput(data)}</div>
          </div>
          <div className="btn-container">
            <Button
              className="btn btn-inverse"
              name=""
              id="edit-popup-cancel"
              onClick={cancelClick}
            >
              CANCEL
            </Button>
            <Button
              className="btn"
              name=""
              id="edit-popup-save"
              disabled={!modified}
              onClick={(e) => {
                // fires to save obj with date and hours to DB
                saveClick(e, data);
              }}
            >
              SAVE
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}

// ----------------------------------------------------------------------------|
//                       PropTypes Check - EditPopUpModal
// ----------------------------------------------------------------------------|
EditPopUpModal.propTypes = {
  cancelClick: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  saveClick: PropTypes.func.isRequired,
  handleStateChange: PropTypes.func.isRequired,
  editHoursError: PropTypes.object.isRequired,
};

// ----------------------------------------------------------------------------|
//                              EditPopUpModal Export
// ----------------------------------------------------------------------------|
export default EditPopUpModal;
