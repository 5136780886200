/**
 * ************************************
 *
 * @module  FlaggedCardBanner.tsx
 * @author  Matt P
 * @date    06/12/2022
 * @description Individual red banner notice which notifies writers on the
 * flagged content
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import { Button } from 'components';

import CloseIcon from 'assets/images/remove-black.svg';

import moment from 'moment';

import './FlaggedCardBanner.scss';

// ----------------------------------------------------------------------------|
//                          React Function Component
// ----------------------------------------------------------------------------|
const FlaggedCardBanner = ({
  className,
  flaggedBy,
  flaggedDate,
  flaggedDescription,
  flaggedReason,
  onClick,
}: {
  className: string;
  flaggedBy: string;
  flaggedDate: string;
  flaggedDescription: string;
  flaggedReason: string;
  onClick: () => void;
}): JSX.Element => {
  const containerClass = cx(['flagged-card-banner-container', `${className}`]);

  const formattedFlagReason =
    flaggedReason === 'internal' ? 'internally' : flaggedReason;

  return (
    <div className={containerClass}>
      <h2>
        {`Flagged ${
          flaggedReason === 'internal' ? '' : 'as '
        }${formattedFlagReason.toUpperCase()} by ${flaggedBy} on ${moment(
          flaggedDate
        ).format('MMM DD, YYYY')}`}
      </h2>
      <p>{flaggedDescription}</p>
      <Button
        className="btn-mark-resolved"
        onClick={onClick}
        disabled={false}
        preventDefault // ???
      >
        <img
          className="flagged-card-close-button-icon"
          src={CloseIcon}
          alt="close"
        />
        Mark as Resolved
      </Button>
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                                PropTypes Check
// ----------------------------------------------------------------------------|
FlaggedCardBanner.propTypes = {
  className: PropTypes.string,
  flaggedBy: PropTypes.string.isRequired,
  flaggedDate: PropTypes.string.isRequired,
  flaggedDescription: PropTypes.string.isRequired,
  flaggedReason: PropTypes.string.isRequired,
};

// ----------------------------------------------------------------------------|
//                                  Default Props
// ----------------------------------------------------------------------------|
FlaggedCardBanner.defaultProps = {
  className: '',
};

// ----------------------------------------------------------------------------|
//                                     Export
// ----------------------------------------------------------------------------|
export default FlaggedCardBanner;
