/**
 * ************************************
 *
 * @module  Tabs.js
 * @author  Matt P
 * @date    03/02/2021
 * @description Tabs component for sidebar and section nav
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                  Imports
// ---------------------------------------------------------------------------|
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import { NavbarDropdown } from 'components';

import './Tabs.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const Tabs = ({
  data,
  active,
  classNames,
  listClassNames,
  linkClassNames,
  ...rest
}) => {
  /**
   * @description - toggles show or hide of dropdown
   */
  const [dropdownToggled, setDropdownToggled] = useState(false);

  /**
   * @description - dynamic classNames
   */
  const tabClasses = cx(['tabs', `${classNames}`]);

  /**
   * @description - handled the highlight of the currently
   * selected tab
   *
   * @param {Boolean} match
   * @param {Object} location
   * @param {String} name - of current tab or dropdown tab
   *
   * @returns {Boolean}
   */
  const relativeTabHighlight = (match, location, name) => {
    if (match) {
      return true;
    }

    if (
      listClassNames === 'side-bar-tabs' &&
      location.pathname.split('/')[1] === name.toLowerCase().split(' ')[0]
    ) {
      return true;
    }

    return false;
  };

  return (
    <ul className={tabClasses} {...rest}>
      {data.map(({ name, url, type, icon, options }) => {
        const listClasses = cx([
          'tabs-items',
          { 'active-tab': active === name },
          `${listClassNames}`,
        ]);

        const linkClasses = cx(['nav-item', `${linkClassNames}`]);

        if (type === 'dropdown') {
          return (
            <li key={`${name}-nav-dropdown-item`}>
              <NavbarDropdown
                header={name}
                parentUrl={url}
                dropdownOptions={options}
                toggleDropdown={setDropdownToggled}
                isExpanded={dropdownToggled}
              />
            </li>
          );
        }

        return (
          <li className={listClasses} key={`${name}-nav-link`}>
            <NavLink
              to={url}
              className={linkClasses}
              key={name}
              activeClassName="active"
              isActive={(match, location) =>
                relativeTabHighlight(match, location, name)
              }
              onClick={() => {
                setDropdownToggled(false);
              }}
            >
              {icon && <img src={icon} className="tab-icon" />}
              {name}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

// --------------------------------------------------------------------------|
//                            PropTypes Check
// --------------------------------------------------------------------------|
Tabs.propTypes = {
  data: PropTypes.array.isRequired,
  active: PropTypes.string,
  classNames: PropTypes.string,
  listClassNames: PropTypes.string,
  linkClassNames: PropTypes.string,
};

// --------------------------------------------------------------------------|
//                              Default Props
// --------------------------------------------------------------------------|
Tabs.defaultProps = {
  classNames: '',
  listClassNames: '',
  linkClassNames: '',
};

// --------------------------------------------------------------------------|
//                                  Export
// --------------------------------------------------------------------------|
export default Tabs;
