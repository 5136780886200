/**
 * ************************************
 *
 * @module  CollectionData.constants.js
 * @author  Matt P
 * @date    07/02/2021
 * @description Container for editing a 'Collection.' Currently renders within
 * the CardViewModal is shown renders when a card is clicked
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';

import { ReviewStatus } from 'components';

import { ONE_LINER_DESC_ROWS } from 'constants.js';

// ----------------------------------------------------------------------------|
//                        EventData - Array of Objects
// ----------------------------------------------------------------------------|
const CollectionData = [
  {
    type: 'text',
    name: 'title',
    label: 'Collection Title',
    value: '',
    errorMsg: 'Title should be min 1 and max 100 characters long',
  },
  {
    type: 'tag',
    label: 'Category',
    name: 'categories',
    tags: [],
    suggestions: [],
    errorMsg: 'Type cannot be empty',
  },
  {
    type: 'tag',
    label: 'Location',
    name: 'neighborhoods',
    tags: [],
    suggestions: [],
    errorMsg: 'Neighborhood cannot be empty',
  },

  {
    type: 'textarea',
    name: 'description',
    label: 'One-liner Description',
    value: '',
    errorMsg: 'Description should be min 5 and max 50 characters long',
    rows: ONE_LINER_DESC_ROWS,
  },
];

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export default CollectionData;
