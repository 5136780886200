/**
 * ************************************
 *
 * @module  LoginForm.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description CMS LoginForm component
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button, Input } from 'components';

import './LoginForm.scss';

// ----------------------------------------------------------------------------|
//                    React Function Component - LoginForm
// ----------------------------------------------------------------------------|
const LoginForm = (props) => {
  const {
    cardDetails,
    valueState,
    onChange,
    onSubmit,
    showWarning,
    isSubmitDisabled,
  } = props;

  const {
    TITLE: loginTitle,
    INPUTS,
    BUTTON_TEXT,
    WARNING_MESSAGE,
  } = cardDetails;

  const [effectiveShowWarning, setEffectiveShowWarning] = useState(showWarning);
  const [showLoading, setShowLoading] = useState(false);
  const warningClass = cx('warning-message', { 'show-warning': effectiveShowWarning });
  const loadingClass = cx('submit-btn', { 'loading': showLoading });

  useEffect(() => {
    setEffectiveShowWarning(showWarning);
    if (showWarning) {
      setShowLoading(false);
    }
  }, [showWarning]);

  /**
   * @description function for form submission
   *
   * @param {Object} event
   */
  const submitHandler = (event) => {
    event.preventDefault();
    if (!isSubmitDisabled) {
      setEffectiveShowWarning(false);
      setShowLoading(true);
      onSubmit();
    }
  };

  return (
    <div className="login-form">
      <h1 className="card-title">{loginTitle}</h1>
      <form onSubmit={submitHandler}>
        {INPUTS.map((inputData) => {
          const value = valueState[inputData.name];
          return (
            <Input
              key={inputData.id}
              type={inputData.type}
              className="input"
              name={inputData.name}
              placeholder={inputData.placeholder}
              value={value}
              onChange={onChange}
              required
            />
          );
        })}
        <p className={warningClass}>{WARNING_MESSAGE}</p>
        <Button
          type="submit"
          className={loadingClass}
          onClick={submitHandler}
          disabled={isSubmitDisabled}
        >
          {BUTTON_TEXT}
        </Button>
      </form>
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                              PropTypes Check
// ----------------------------------------------------------------------------|
LoginForm.propTypes = {
  cardDetails: PropTypes.object.isRequired,
  valueState: PropTypes.object.isRequired,
  showWarning: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
};

// ----------------------------------------------------------------------------|
//                            Component Exports
// ----------------------------------------------------------------------------|
export default LoginForm;
