/**
 * ************************************
 *
 * @module  VerticalForm.saga.js
 * @author  Matt P
 * @date    07/02/2021
 * @description redux saga file for the VerticalForm container,
 * which handles all the card detail ASYNC logic
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import ACTIONS from 'store/actions/actionTypes';

import apiMap from 'API/apiMap';
import API from 'API';

import { GENERIC } from 'constants.js';
import {
  chooseCardType,
  constructQueryParams,
  getPartsFromUrl,
} from 'utils/utils';

import { newCardDesignVerticalSet } from 'containers/VerticalDisplay/VerticalForm/VerticalFormUtils';

import { reportAPIError } from 'utils/ErrorHandlingUtils';

// ----------------------------------------------------------------------------|
//                                  Utilities
// ----------------------------------------------------------------------------|
/**
 * @description constructs url string based on vertical
 *
 * @param {String} endPoint
 * @param {String} queryParams
 *
 * @returns {String}
 */
const constructUrl = (endPoint, queryParams = '') =>
  // eslint-disable-next-line prefer-template
  `${endPoint}${queryParams ? '?' + queryParams : ''}`;

/**
 * @description returns correct request arguments as an array
 * to be spread to saga effect call()
 *
 * @param {String} type - of request
 * @param {String} url
 * @param {Object} data - optional but needed for request body calls
 *
 * @returns {new Axios}
 */
const chooseSagaCallArguments = (type, url, data = {}) => {
  const { page } = data;

  switch (type) {
    case 'get':
      return [type, constructUrl(url, constructQueryParams(data))];
    case 'post':
    case 'put':
      delete data.page;

      return [
        type,
        page ? constructUrl(url, constructQueryParams({ page })) : url,
        data,
      ];
    case 'delete':
      return [url];
    default:
      return [];
  }
};

/**
 * @description selects correct request type
 *
 * @param {String} endPoint
 * @param {String} queryParams
 *
 * @returns {new Axios}
 */
const chooseRequestType = (requestType) => {
  const { patchData, postData, putData } = window.axios;

  switch (requestType) {
    case 'patch': {
      return patchData;
    }
    case 'post': {
      return postData;
    }
    case 'put': {
      return putData;
    }
    default: {
      break;
    }
  }
};

/**
 * @description formats data
 *
 * @param {Array} data - Array of Objects
 *
 * @returns {Array}
 */
const formatData = (data = []) => {
  if (typeof data[0] === 'object') {
    return data.map((each) => each.name);
  }
  return data;
};

// ----------------------------------------------------------------------------|
//                           Sagas - VerticalForm
// ----------------------------------------------------------------------------|
/**
 * @description handles uploading an image
 *
 * @param {Object} action - object passed from the redux action
 */
function* uploadImage(action) {
  const { data, verticalType } = action;
  const { postData } = window.axios;
  const { file, fileId, photo_metadata } = data;
  const { IMAGE_UPLOAD_FAIL } = GENERIC;

  try {
    const response = yield call(
      postData,
      apiMap[verticalType].uploadImage.endPoint,
      file,
      {
        'Content-Type': 'multipart/form-data',
      }
    );

    if (response.status === 200) {
      yield put({
        type: ACTIONS.VERTICAL_FORM.UPLOAD_IMAGE_SUCCESS,
        data: { ...response.data, fileId, photo_metadata },
      });
    } else {
      toast.error(IMAGE_UPLOAD_FAIL);

      yield put({
        type: ACTIONS.VERTICAL_FORM.UPLOAD_IMAGE_FAIL,
        data: { fileId },
      });
    }
  } catch (error) {
    toast.error(IMAGE_UPLOAD_FAIL);

    yield put({
      type: ACTIONS.VERTICAL_FORM.UPLOAD_IMAGE_FAIL,
      data: { fileId },
    });
  }
}

/**
 * @description handles updating data
 *
 * @param {Object} action - object passed from the redux action
 * @param {String} id - id of action
 */
function* updatedData(data, id) {
  const { status: toBeStatus } = data;
  const { secondPart: currentTab } = getPartsFromUrl(window.location.pathname);

  const currentStatus = currentTab === 'drafts' ? 'draft' : currentTab;

  if (toBeStatus === currentStatus) {
    yield put({
      type: ACTIONS.VERTICAL_DISPLAY.MOVE_TO_TOP,
      data: { id, data },
    });
  } else {
    yield put({ type: ACTIONS.VERTICAL_DISPLAY.REMOVE_CARD, data: { id } });
  }
}

/**
 * @description Fetch Card object using ID
 *
 * @param {Object} action - object passed from the redux action
 */
function* fetchVerticalFormDetail(action) {
  const { data, verticalType } = action;
  const { getData } = window.axios;

  try {
    const { endPoint } = apiMap[verticalType].fetchCard;
    const URL = `${endPoint}${data.cardId}`;

    // places need to fetch pairings as well as details
    if (newCardDesignVerticalSet.has(verticalType)) {
      const placeCardDetailResponse = yield call(getData, URL);

      const { endPoint: pairingsEndpoint } =
        apiMap[verticalType].retrievePairings;
      const pairingsURL = `${pairingsEndpoint}?unit_pretty_id=place_${data.cardId}`;
      const placeUnitPairingsResponse = yield call(getData, pairingsURL);

      if (
        placeCardDetailResponse.status === 200 &&
        placeUnitPairingsResponse.status === 200
      ) {
        yield put({
          type: ACTIONS.VERTICAL_FORM.FETCH_VERTICAL_FORM_DETAIL_SUCCESS,
          data: {
            ...placeCardDetailResponse.data,
            pairings: placeUnitPairingsResponse.data.paired_units,
          },
        });
      } else {
        yield put({
          type: ACTIONS.VERTICAL_FORM.FETCH_VERTICAL_FORM_DETAIL_FAIL,
        });
      }
    } else {
      const cardDetailResponse = yield call(getData, URL);

      if (cardDetailResponse.status === 200) {
        yield put({
          type: ACTIONS.VERTICAL_FORM.FETCH_VERTICAL_FORM_DETAIL_SUCCESS,
          data: cardDetailResponse.data,
        });
      } else {
        yield put({
          type: ACTIONS.VERTICAL_FORM.FETCH_VERTICAL_FORM_DETAIL_FAIL,
        });
      }
    }
  } catch (error) {
    yield put({ type: ACTIONS.VERTICAL_FORM.FETCH_VERTICAL_FORM_DETAIL_FAIL });
  }
}

/**
 * @description reinstates a card that is currently archived
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* reinstateCard(action) {
  const { data } = action;
  const { postData } = window.axios;
  const { REINSTATE_SUCCESS_MESSAGE, REINSTATE_FAILURE_MESSAGE } = GENERIC;

  try {
    const URL = `${API.reinstateUnit}${data}`;

    const response = yield call(postData, URL);

    if (response.status === 200) {
      toast.success(REINSTATE_SUCCESS_MESSAGE);

      yield put({
        // same 200 action as delete
        type: ACTIONS.VERTICAL_DISPLAY.DELETE_CARD_SUCCESS,
        data: action.data,
      });
    } else {
      toast.error(REINSTATE_FAILURE_MESSAGE);
      // same error action as delete
      yield put({ type: ACTIONS.VERTICAL_DISPLAY.DELETE_CARD_FAIL });
    }
  } catch (error) {
    toast.error(REINSTATE_FAILURE_MESSAGE);
    // same error action as delete
    yield put({ type: ACTIONS.VERTICAL_DISPLAY.DELETE_CARD_FAIL });
  }
}

/**
 * @description saves data to DB
 *
 * @param {Object} action - object passed from the redux action
 */
function* saveData(action) {
  const { id, successCB, verticalType } = action;
  const data = { ...action.data };
  const cardType = chooseCardType(verticalType);

  if (data.neighborhood) {
    data.neighborhood = formatData(data.neighborhood);
  }

  yield put({
    type: ACTIONS.VERTICAL_FORM.BUTTON_DISABLING,
    data: { isBtnDisabled: true },
  });

  try {
    let response;

    if (id) {
      // update
      const { endPoint, requestType } = apiMap[verticalType].editCard;

      response = yield call(
        chooseRequestType(requestType),
        `${endPoint}${id}`,
        data
      );
    } else {
      // create
      const { endPoint, requestType } = apiMap[verticalType].createCard;

      response = yield call(chooseRequestType(requestType), endPoint, data);
    }

    if (response.status === 200) {
      yield put({
        type: ACTIONS.VERTICAL_FORM.SAVE_DATA_SUCCESS,
        data: { ...response.data },
      });

      yield updatedData(response.data, action.id);

      if (data.should_refetch_3rd_parties_content) {
        const currTab =
          data.status === 'fetched' ||
          data.status === 'queue' ||
          data.status === 'draft'
            ? 'drafts'
            : data.status;

        successCB(response.data.id, currTab);
      } else {
        toast.success('Card saved successfully');
        successCB();
      }
    } else if (
      // if duplicate FWD to that ID
      response.status === 400 &&
      (response.data.error_code === 'DUPLICATED_FOURSQUARE_ID' ||
      response.data.error_code === 'DUPLICATED_TMDB_ID' ||
      response.data.error_code === 'DUPLICATED_GMAPS_ID' ||
      response.data.error_code === 'DUPLICATED_YELP_ID')
    ) {
      toast.success('Card ID already exists, redirecting');

      const currTab = data.status === 'draft' ? 'drafts' : data.status;

      const duplicateId = response.data.message.split(' ').pop();

      action.successCB(duplicateId, currTab);
    } else {
      reportAPIError(`${verticalType} Save - HTTP`, response.data);
      toast.error('Save Fail');

      yield put({ type: ACTIONS.VERTICAL_FORM.SAVE_DATA_FAIL });
    }
  } catch (error) {
    reportAPIError(`Overall ${verticalType} Save Catch`, error);
    // toast.error(GENERIC[`${cardType}_SAVE_FAIL`]);
    toast.error('Card save fail');

    yield put({ type: ACTIONS.VERTICAL_FORM.SAVE_DATA_FAIL });
  } finally {
    yield put({
      type: ACTIONS.VERTICAL_FORM.BUTTON_DISABLING,
      data: { isBtnDisabled: false },
    });
  }
}

/**
 * @description fetches cards for the collection card selection modal
 *
 * @param {Object} action - object passed from the redux action
 *
 * @returns {void}
 */
function* getCardSelectionModalCards(action) {
  const { data, verticalType, subType } = action;
  const { searchData } = window.axios;

  try {
    const { endPoint, requestType } =
      apiMap[subType || verticalType].retrieveList;

    const response = yield call(
      searchData,
      ...chooseSagaCallArguments(requestType, endPoint, data)
    );

    if (response.status === 200) {
      // collections have a modal that can search all verticals. So a
      // sub_type of the search will be another verticalType
      // this is a verticalForm operation and will be classified as such
      yield put({
        type: ACTIONS.VERTICAL_FORM.GET_VERTICAL_CARD_SELECTION_CARDS_SUCCESS,
        data: response.data,
        subType,
      });
    } else {
      yield put({
        type: ACTIONS.VERTICAL_FORM.GET_VERTICAL_CARD_SELECTION_CARDS_FAIL,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.VERTICAL_FORM.GET_VERTICAL_CARD_SELECTION_CARDS_FAIL,
    });
  }
}

// ----------------------------------------------------------------------------|
//                                  Export
// ----------------------------------------------------------------------------|
export {
  fetchVerticalFormDetail,
  getCardSelectionModalCards,
  reinstateCard,
  saveData,
  uploadImage,
};
