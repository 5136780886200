/**
 * ************************************
 *
 * @module  FlagContentModal.tsx
 * @author  Matt P
 * @date    06/13/2022
 * @description Modal used for writers to add a flag to content=.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, TextArea } from 'components';

import { submitFlagReport } from 'utils/FlagCardUtils';

import cx from 'classnames';

import './FlagContentModal.scss';

// ----------------------------------------------------------------------------|
//                          React Function Component
// ----------------------------------------------------------------------------|
const FlagContentModal = ({
  className,
  contentPrettyId,
  modalClosed,
  show,
}: {
  className: string;
  contentPrettyId: string;
  modalClosed: () => void;
  show: boolean;
}): JSX.Element => {
  const [textAreaString, setTextAreaString] = useState('');
  const containerClass = cx([
    'flag-content-modal-container',
    'third-party-modal', /// it just works..
    `${className}`,
  ]);

  /**
   * @description - handles input change. updates form and local state
   *
   * @param {Object} e - DOM event object
   *
   * @returns {void}
   */
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTextAreaString(e.target.value);
  };

  return (
    <Modal
      title="Flag Card"
      show={show}
      modalClosed={modalClosed}
      customClasses={containerClass}
      modalFooter={
        <div className="modal-actions">
          <Button
            name="SAVE"
            type="button"
            className="btn action-btn"
            onClick={() => {
              if (textAreaString.length) {
                submitFlagReport({
                  prettyId: contentPrettyId,
                  flaggedReasonDescription: textAreaString,
                  modalClosed,
                });
              }
            }}
            disabled={!textAreaString.length}
            preventDefault
          >
            SAVE
          </Button>
        </div>
      }
      customStyles={{
        width: 450,
      }}
    >
      <div className="form-input">
        <label>Flag Description</label>
        <TextArea
          className="no-resize"
          value={textAreaString}
          name="flag-content-modal-text-area"
          placeholder="Describe reason for flagging"
          onChange={onChangeHandler}
          rows={9}
        />
      </div>
    </Modal>
  );
};

// ----------------------------------------------------------------------------|
//                                PropTypes Check
// ----------------------------------------------------------------------------|
FlagContentModal.propTypes = {
  className: PropTypes.string,
  contentPrettyId: PropTypes.string.isRequired,
  modalClosed: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

// ----------------------------------------------------------------------------|
//                                  Default Props
// ----------------------------------------------------------------------------|
FlagContentModal.defaultProps = {
  className: '',
};

// ----------------------------------------------------------------------------|
//                                     Export
// ----------------------------------------------------------------------------|
export default FlagContentModal;
