/**
 * ************************************
 *
 * @module  CobReactParentChildSelect.js
 * @author  Matt Peters
 * @date    7/16/2022
 * @description A side by side select dropdown which use a parent/child
 * relationship for streight forward navigation of parent child relationships
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import { CobReactMultiSelect } from 'components';
import cx from 'classnames';

import { qualifyArrayRendering } from 'utils/utils';

import { formGroupOptions } from 'components/FilterSection/FiltersFactory';

import 'react-dropdown/style.css';

import './CobReactParentChildSelect.scss';

// ----------------------------------------------------------------------------|
//                  React Function Component - CobReactSelect
// ----------------------------------------------------------------------------|
/**
 * @description This is a functional component. Which is renders a single
 * dropdown select input form. It will trigger onClick event which is connected
 * to it.
 *
 * @param {Object} props
 *
 * @returns {JSX} HTML <Select/>components to be rendered to the DOM
 */
const CobReactParentChildSelect = ({
  options,
  onSelect,
  className,
  parentValue = [],
  childValue = [],
  parentReturnType,
  childReturnType,
  parentPlaceholder,
  childPlaceholder,
  isClearable = true,
  isSearchable = true,
  styles,
}) => {
  const containerClass = cx([
    'cob-react-parent-child-select filter-drop-down',
    `${className}`,
  ]);

  const prepChildSelectionOptions = (selectedOption = {}) => {
    switch (parentReturnType) {
      case 'city': {
        return qualifyArrayRendering(selectedOption.subareas)
          ? formGroupOptions(selectedOption.subareas, 'neighborhoods')
          : [];
      }
      default: {
        return [];
      }
    }
  };

  /**
   * @description On change function passed to <Select>
   */
  const onParentChange = (selectedValue) => {
    if (selectedValue === null) {
      onSelect(childReturnType, []);
    }

    onSelect(parentReturnType, selectedValue !== null ? [selectedValue] : []);
  };
  /**
   * @description Maps new array of options objects with className: 'object'
   *
   * @returns {Array} Array of mapped objects
   */
  const prepareOptionWithClassName = (dropdownOptions = []) =>
    dropdownOptions.map((option) => ({ ...option, className: 'option' }));

  const childSelectionOptions =
    qualifyArrayRendering(parentValue) && parentValue[0] && parentValue[0].value
      ? prepChildSelectionOptions(parentValue[0].value)
      : [];

  const customStyles = {
    control: base => ({
      ...base,
      ...styles,
      height: 40,
      minHeight: 40
    })
  };

  return (
    <div className="cob-react-patent-child-select-container">
      <Select
        styles={customStyles}
        isMulti={false}
        className={containerClass}
        isSearchable={isSearchable}
        isClearable={isClearable}
        value={parentValue}
        options={prepareOptionWithClassName(options)}
        placeholder={parentPlaceholder}
        onChange={onParentChange}
      />
      <CobReactMultiSelect
        styles={customStyles}
        className={containerClass}
        isSearchable={isSearchable}
        isClearable={isClearable}
        selectedItems={childValue}
        options={prepareOptionWithClassName(childSelectionOptions)}
        placeholder={childPlaceholder}
        onSelect={(data) => {
          onSelect(childReturnType, data);
        }}
        isDisabled={!qualifyArrayRendering(childSelectionOptions)}
      />
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                     PropTypes Check - CobReactParentChildSelect
// ----------------------------------------------------------------------------|
CobReactParentChildSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  parentPlaceholder: PropTypes.string,
  childPlaceholder: PropTypes.string,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  parentValue: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  childValue: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  parentReturnType: PropTypes.string.isRequired,
  childReturnType: PropTypes.string.isRequired,
  styles: PropTypes.shape({}),
};

// ----------------------------------------------------------------------------|
//                        Default Props - CobReactSelect
// ----------------------------------------------------------------------------|
CobReactParentChildSelect.defaultProps = {
  className: '',
  parentPlaceholder: 'Select Parent',
  childPlaceholder: 'Select Child',
  isClearable: true,
  isSearchable: true,
};

// ----------------------------------------------------------------------------|
//                         CobReactParentChildSelect Export
// ----------------------------------------------------------------------------|
export default CobReactParentChildSelect;
