/**
 * ************************************
 *
 * @module  Movies.saga.js
 * @author  Matt P
 * @date    12/23/2020
 * @description redux saga file for the Movies container.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import { call, put, select } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import ACTIONS from 'store/actions/actionTypes';
import API from 'API';
import {
  clearMoviesAction,
  getMoviesListAction,
} from 'store/actions/~depreciated/Movies.action';

import { GENERIC } from 'constants.js';
import { constructQueryParams } from 'utils/utils';

// ----------------------------------------------------------------------------|
//                              Sagas - Movies
// ----------------------------------------------------------------------------|
/**
 * @description handles getting movie list data
 *
 * @param {Object} action - object passed from the redux action
 */
function* getMovieList(action) {
  const { searchData } = window.axios;

  try {
    const {
      status,
      title,
      loaded_ids,
      ratings,
      cat_unique_slug_list,
      tmdb_page,
      review_status,
    } = action.data;

    // will be on every call
    const washedData = {
      status,
      title,
      loaded_ids,
      cat_unique_slug_list,
      review_status,
    };

    // will be on some calls as the sever will error
    // if fields are blank
    if (ratings) washedData.ratings = ratings;
    if (tmdb_page > 0) washedData.tmdb_page = tmdb_page;

    const dbPage = `?page=${action.data.page}&page_size=20`;

    const URL = `${API.movieList}${tmdb_page === -1 ? dbPage : ''}`;

    const response = yield call(searchData, 'post', URL, washedData);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.MOVIES.GET_MOVIES_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: ACTIONS.MOVIES.GET_MOVIES_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.MOVIES.GET_MOVIES_FAIL });
  }
}

/**
 * @description handles getting movie search data from TMDB
 *
 * @param {Object} action - object passed from the redux action
 */
function* getMovieSearch(action) {
  const { getData } = window.axios;

  try {
    const URL = `${API.movieSearch}?${constructQueryParams(action.data)}`;
    const response = yield call(getData, URL);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.MOVIES.GET_MOVIES_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: ACTIONS.MOVIES.GET_MOVIES_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.MOVIES.GET_MOVIES_FAIL });
  }
}

/**
 * @description handles deleting a movie from the DB
 *
 * @param {Object} action - object passed from the redux action
 */
function* deleteMovie(action) {
  const { deleteData } = window.axios;
  const { DELETE_SUCCESS_MESSAGE } = GENERIC;

  try {
    const URL = `${API.movieDetail}${action.data}`;
    const response = yield call(deleteData, URL);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.MOVIES.DELETE_MOVIE_SUCCESS,
        data: action.data,
      });
      toast.success(DELETE_SUCCESS_MESSAGE);
    } else {
      yield put({ type: ACTIONS.MOVIES.DELETE_MOVIE_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.MOVIES.DELETE_MOVIE_FAIL });
  }
}

/**
 * @description handles bulk operation on movie
 *
 * @param {Object} action - object passed from the redux action
 */
function* performBulkOperationOnMovie(action) {
  const { patchData } = window.axios;
  const { data } = action;

  try {
    const response = yield call(patchData, API.movie, data);

    if (response.status === 200) {
      yield put({ type: ACTIONS.MOVIES.BULK_ACTION_SUCCESS, data });
    } else {
      yield put({ type: ACTIONS.MOVIES.BULK_ACTION_FAIL, data });
    }
  } catch (error) {
    yield put({ type: ACTIONS.MOVIES.BULK_ACTION_FAIL, data });
  }
}

/**
 * @description filters movie list
 *
 * @param {Object} action - object passed from the redux action
 */
function* filterMoviesList(action) {
  yield put(clearMoviesAction());

  const pageNum = yield select((state) => state.movies.pageNum);
  const data = { ...action.data, page: pageNum };

  yield put(getMoviesListAction(data));
}

// ----------------------------------------------------------------------------|
//                              Movies Saga Export
// ----------------------------------------------------------------------------|
export {
  getMovieList,
  getMovieSearch,
  deleteMovie,
  performBulkOperationOnMovie,
  filterMoviesList,
};
