/**
 * ************************************
 *
 * @module  MultiDropdownSelect.js
 * @author  Matt P
 * @date    03/22/2022
 * @description Component for rendering multiple dropdown. Additional
 * inputs are rendered via clicking on the +Add More
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                 Imports
// ----------------------------------------------------------------------------|
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DropDown } from 'components';

import { createDropdownOptions } from 'utils/utils';
import { createCategoryUniqueSlugSet } from 'utils/CategoryUtils';

import deleteIcon from 'assets/images/icn_delete.png';
import './MultiDropdownSelect.scss';

// ----------------------------------------------------------------------------|
//                        MultiDropdownSelect - Utils
// ----------------------------------------------------------------------------|
/**
 * @description Upon mount, remove the options already selected and present
 * to prevent double selection
 *
 * @param {Array} values of category objects
 * @param {Array} options of multiDropdownSelectOptions
 * @returns {Array} of filtered multiDropdownSelectOptions
 */
const filterAlreadySelectedOptions = (values = [], options = []) => {
  const valueUniqueSlugSet = createCategoryUniqueSlugSet(values);

  return options.map((option) => ({
    ...option,
    value: option.value.filter(
      (optionValue) => !valueUniqueSlugSet.has(optionValue.value.unique_slug)
    ),
  }));
};

/**
 * @description Upon mount, check the values and see if they are
 * present as a value of the parent categories. If so, add them as already
 * selected type data. If not, we'll add a notice they are a 'legacy' category
 *
 * @param {Array} values of category objects
 * @param {Array} options of multiDropdownSelectOptions
 * @returns {Array} of filtered multiDropdownSelectOptions
 */
const determineTypeFromCategory = (values = [], options = []) => {
  const returnedArray = [];

  values.forEach((val) => {
    const { unique_slug } = val;
    const foundOption = options.find((option) => {
      const { value } = option;

      for (let i = 0; i < value.length; i += 1) {
        if (value[i].value.unique_slug === unique_slug) return true;
      }

      return false;
    });

    returnedArray.push(
      foundOption !== undefined
        ? foundOption
        : {
            label: 'Legacy Category - Please Update',
            value: [],
          }
    );
  });

  return returnedArray;
};

// ----------------------------------------------------------------------------|
//                React Function Component - MultiDropdownSelect
// ----------------------------------------------------------------------------|
const MultiDropdownSelect = ({
  options,
  values,
  name,
  onChange,
  disabled,
  buttonTitle,
  error,
}) => {
  // keep options state local so we have a reference on what options have
  // already been selected to prevent
  const [optionsState, setOptionsState] = useState(
    filterAlreadySelectedOptions(values, options)
  );
  // Parent type dropdown selections
  const [typeDropdownSelections, setTypeDropdownSelections] = useState(
    determineTypeFromCategory(values, options)
  );
  // Keeps track of the rows and what values are associated with such
  const [multiInputState, setMultiInputState] = useState(
    !values.length ? [{}] : createDropdownOptions(values)
  );

  /**
   * @description The form only wants the category object, so only
   * return those to the form on any dropdown selection change
   *
   * @param {Array} multiInputData of dropdown option data
   * @returns {Array} of category objects
   */
  const prepDropdownValuesForForm = (multiInputData = []) =>
    multiInputData.map((arr) => arr.value).filter((arr) => arr !== undefined);

  /**
   * @description Removes the selected option from the parent
   * triggered on cat selection
   *
   * @param {String} uniqueSlug
   * @returns {void}
   */
  const removeSelectedCategoryFromOptions = (uniqueSlug) => {
    const optionsStateCopy = [...optionsState];

    for (let i = 0; i < optionsStateCopy.length; i += 1) {
      const childIndex = optionsStateCopy[i].value.findIndex(
        (option) => option.value.unique_slug === uniqueSlug
      );

      if (childIndex > -1) {
        optionsStateCopy[i].value.splice(childIndex, 1);
        break;
      }
    }

    setOptionsState(optionsStateCopy);
  };

  /**
   * @description Re-adds the selected option from the parent
   * triggers on row delete, cat change or parent type change
   *
   * @param {String} uniqueSlug
   * @returns {void}
   */
  const addDeselectedCategoryToOptions = (uniqueSlug) => {
    const optionsStateCopy = [...optionsState];

    for (let i = 0; i < options.length; i += 1) {
      let childIndex;
      const child = options[i].value.find((option, findIndex) => {
        childIndex = findIndex;
        return option.value.unique_slug === uniqueSlug;
      });

      if (child !== undefined) {
        optionsStateCopy[i].value.splice(childIndex, 0, child);
        break;
      }
    }

    setOptionsState(optionsStateCopy);
  };

  const resetCategorySelection = (index) => {
    const newMultiInputStateValues = [...multiInputState];

    if (
      newMultiInputStateValues[index] !== undefined &&
      newMultiInputStateValues[index].value !== undefined
    )
      addDeselectedCategoryToOptions(
        newMultiInputStateValues[index].value.unique_slug
      );

    newMultiInputStateValues[index] = {};

    // Sets new state via hooks
    setMultiInputState(newMultiInputStateValues);
    // fires the onChange function passed to comp
    onChange(prepDropdownValuesForForm(newMultiInputStateValues), name);
  };

  /**
   * @description Fires when type dropdown input fields change
   *
   * @param {Object} selectedOption
   * @param {Number} index
   */
  const onTypeDropdownSelection = (selectedOption, index) => {
    const newTypeDropdownSelections = [...typeDropdownSelections];

    // if user changes the type field when there was already a
    // category selection
    if (
      newTypeDropdownSelections[index] !== undefined &&
      multiInputState[index] !== undefined &&
      multiInputState[index].value !== undefined
    ) {
      resetCategorySelection(index);
    }

    newTypeDropdownSelections[index] = selectedOption;

    // Sets new state via hooks
    setTypeDropdownSelections(newTypeDropdownSelections);
  };

  /**
   * @description Fires when the category input dropdown field
   * changes updates state and fires onChange prop function
   *
   * @param {Object} selectedOption
   * @param {Number} index
   */
  const onCategorySelection = (selectedOption, index) => {
    const newMultiInputStateValues = [...multiInputState];

    // if user changes the category field when there was already a
    // category selection
    if (
      newMultiInputStateValues[index] !== undefined &&
      newMultiInputStateValues[index].value !== undefined
    ) {
      addDeselectedCategoryToOptions(
        newMultiInputStateValues[index].value.unique_slug
      );
    }

    newMultiInputStateValues[index] = selectedOption;

    removeSelectedCategoryFromOptions(selectedOption.value.unique_slug);
    // Sets new state via hooks
    setMultiInputState(newMultiInputStateValues);
    // fires the onChange function passed to comp
    onChange(prepDropdownValuesForForm(newMultiInputStateValues), name);
  };

  /**
   * @description Adds input field on add more button click
   *
   * @returns {void}
   */
  const addInput = () => {
    const newMultiInputState = [...multiInputState];

    newMultiInputState.push({});

    setMultiInputState(newMultiInputState);
  };

  /**
   * @description Deletes an input row then updates state and fires
   * the onChange prop function
   *
   * @argument {Object} index - of array to delete
   * @returns {void}
   */
  const deleteInputRow = (index) => {
    const newMultiInputState = [...multiInputState];
    const [deletedElement] = newMultiInputState.splice(index, 1);
    setMultiInputState(newMultiInputState);

    const newTypeDropdownSelections = [...typeDropdownSelections];
    newTypeDropdownSelections.splice(index, 1);
    setTypeDropdownSelections(newTypeDropdownSelections);

    if (deletedElement.value) {
      addDeselectedCategoryToOptions(deletedElement.value.unique_slug);
    }
    // fires the onChange function passed to comp
    onChange(prepDropdownValuesForForm(newMultiInputState), name);
  };

  /**
   * @description Renders input fields based on array length
   *
   * @returns {JSX} HTML components to be rendered to the DOM
   */
  const buildInputs = () =>
    multiInputState.map((selectedValue, index) => (
      <div
        className="multi-dropdown-select__input-wrapper"
        key={`multi-dropdown-select${index}`}
      >
        <div
          className="multi-dropdown-select__input dropdown-type-input"
          key={`multi-dropdown-select-parent-${index}`}
        >
          <label>Type</label>
          <DropDown
            name={`multi-dropdown-select-parent-${index}`}
            options={optionsState}
            onSelect={(e) => onTypeDropdownSelection(e, index)}
            value={typeDropdownSelections[index]}
          />
        </div>
        <div
          className="multi-dropdown-select__input"
          key={`multi-dropdown-select-child-${index}`}
        >
          <label>Category</label>
          <div className="dropdown-category-input">
            <DropDown
              name={`multi-dropdown-select-child-${index}`}
              options={
                typeDropdownSelections[index]
                  ? typeDropdownSelections[index].value
                  : []
              }
              onSelect={(e) => onCategorySelection(e, index)}
              value={selectedValue}
              disabled={typeDropdownSelections[index] === undefined}
            />
            <div className="multi-dropdown-select__input-delete">
              <button
                onClick={() => {
                  if (!disabled) deleteInputRow(index);
                }}
                type="button"
              >
                <img src={deleteIcon} alt="remove" />
              </button>
            </div>
          </div>
        </div>
      </div>
    ));

  return (
    <div className="multi-dropdown-select-wrapper">
      {buildInputs()}
      <button
        className="multi-dropdown-select__add-link"
        type="button"
        onClick={() => {
          addInput();
        }}
      >
        {`+ Add ${buttonTitle}`}
      </button>
      <div className="error">{error}</div>
    </div>
  );
};

// ----------------------------------------------------------------------------|
//                  PropTypes Check - MultiDropdownSelect
// ----------------------------------------------------------------------------|
MultiDropdownSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.shape({}),
        })
      ),
    })
  ).isRequired,
  values: PropTypes.array,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  buttonTitle: PropTypes.string,
  error: PropTypes.string,
};

// ----------------------------------------------------------------------------|
//                    Default Props - MultiDropdownSelect
// ----------------------------------------------------------------------------|
MultiDropdownSelect.defaultProps = {
  values: [],
  disabled: false,
  buttonTitle: 'Category',
};

// ----------------------------------------------------------------------------|
//                      Export - MultiDropdownSelect
// ----------------------------------------------------------------------------|
export default MultiDropdownSelect;
