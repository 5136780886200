/**
 * ************************************
 *
 * @module  Backdrop.js
 * @author  Vignesh D
 * @date    03/11/2020
 * @description functional component to render backdrop.
 *
 * ************************************
 */
// ----------------------------------------------------------------------------|
//                                  Imports
// ----------------------------------------------------------------------------|
import React from 'react';
import PropTypes from 'prop-types';

import 'components/Backdrop/Backdrop.scss';

// ----------------------------------------------------------------------------|
//                    React Functional Component - Backdrop
// ----------------------------------------------------------------------------|
const Backdrop = ({ show, onClick }) =>
  show ? <div className="backdrop" role="button" onClick={onClick} /> : null;

// ----------------------------------------------------------------------------|
//                        PropTypes Check - Backdrop
// ----------------------------------------------------------------------------|
Backdrop.propTypes = {
  show: PropTypes.bool,
  onClick: PropTypes.func,
};

Backdrop.defaultProps = {
  show: true,
  onClick: () => {},
};

// ----------------------------------------------------------------------------|
//                              Backdrop Export
// ----------------------------------------------------------------------------|
export default Backdrop;
