/**
 * ************************************
 *
 * @module  NavbarDropdown.js
 * @author  Matt P
 * @date    03/02/2021
 * @description Reusable dropdown component for the CMS Side Navbar
 *
 * ************************************
 */
// ---------------------------------------------------------------------------|
//                                  Imports
// ---------------------------------------------------------------------------|
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import { NavLink } from 'react-router-dom';

import chevronDark from 'assets/images/chevron-dark.svg';

import './NavbarDropdown.scss';

// ----------------------------------------------------------------------------|
//                        React Function Component
// ----------------------------------------------------------------------------|
const NavbarDropdown = ({
  header,
  dropdownOptions,
  className,
  parentUrl,
  isExpanded,
  toggleDropdown,
}) => {
  const dropdownClasses = cx(['navbar-dropdown-list__item', `${className}`]);

  return (
    <div className="navbar-dropdown-wrapper">
      <button
        type="button"
        className="navbar-dropdown-header"
        onClick={() => {
          toggleDropdown(!isExpanded);
        }}
      >
        <div className="navbar-dropdown-header__title">
          {header}
          <span>
            <img
              style={{ transform: isExpanded ? '' : 'rotate(270deg)' }}
              src={chevronDark}
              alt="btn-dropdown-arrow-icon"
            />
          </span>
        </div>
      </button>
      {isExpanded && (
        <div role="list" className="navbar-dropdown-list">
          {dropdownOptions.map(({ id, title, url }, index) => {
            const linkClasses = cx(['nav-item']);

            return (
              <li className={dropdownClasses} key={index}>
                <NavLink
                  to={`${parentUrl}${url}`}
                  className={dropdownClasses}
                  key={id}
                  activeClassName="active"
                >
                  {title}
                </NavLink>
              </li>
            );
          })}
        </div>
      )}
    </div>
  );
};

// --------------------------------------------------------------------------|
//                            PropTypes Check
// --------------------------------------------------------------------------|
NavbarDropdown.propTypes = {
  header: PropTypes.string.isRequired,
  parentUrl: PropTypes.string.isRequired,
  dropdownOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isExpanded: PropTypes.bool,
  className: PropTypes.string,
  toggleDropdown: PropTypes.func.isRequired,
};

// --------------------------------------------------------------------------|
//                              Default Props
// --------------------------------------------------------------------------|
NavbarDropdown.defaultProps = {
  isExpanded: false,
  className: '',
};

// --------------------------------------------------------------------------|
//                                  Export
// --------------------------------------------------------------------------|
export default NavbarDropdown;
