/**
 * ************************************
 *
 * @module  SelectStyles.js
 * @author  Matt P
 * @date    6/??/2021
 * @description Styles for the react select dropdown
 *
 * ************************************
 */
/**
 * @description tag dropdown styling for the <Select> component
 */
const filterSectionDropdownStyling = () => ({
  placeholder: (styles) => ({
    ...styles,
    fontFamily: 'Libre Franklin', // $libre-franklin
    fontSize: '14.5px',
    padding: '13px 4px',
    color: '#a8a4a0',
    fontWeight: '400',
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: '200px',
  }),
  indicatorSeparator: (styles) => ({ display: 'none' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    cursor: 'pointer',
    backgroundColor: 'white',
    color: isFocused ? '#f7bc25' : 'black',
    ':hover': {
      color: '#f7bc25',
      backgroundColor: 'white',
    },
    ':active': {
      color: '#f7bc25',
      backgroundColor: 'white',
    },
    ':selected': {
      color: '#f7bc25',
      backgroundColor: 'white',
      outline: 'none',
    },
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    display: 'flex',
    alignItems: 'center',
    minHeight: '25px',
    backgroundColor: '#f0f0f0', // $grey9
    borderRadius: '50px',
    color: '#7c8e9a',
    padding: '3px 8px',
    marginRight: '6px',
    marginTop: '2px',
    marginBottom: '2px',
    cursor: 'pointer',
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontFamily: 'Libre Franklin', // $libre-franklin
    fontWeight: '600',
    color: '#353029', // $text-color
    fontSize: '12px',
    lineHeight: '14.54px',
    margin: '0px',
    paddingRight: '0px',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: '#353029',
    ':hover': {
      color: '#716c67',
    },
  }),
});

// ----------------------------------------------------------------------------|
//                                Export
// ----------------------------------------------------------------------------|
export { filterSectionDropdownStyling };
